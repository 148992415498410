import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import './pagesWrapper.scss';
import PageTableSelection from './PageTableSelection/PageTableSelection';
import SqlCustomCommands from './tableCustomColumn/SqlCustomCommands';
import PageHeader from './PageHeader';
import { CircularProgress } from '@mui/material';
import { defaultDataByTableType } from './tableCustomColumn/data/sqlCustomCommandsData';
interface pageWrapperProps {
  stateProps: any;
}
const PageWrapper = ({ stateProps }: pageWrapperProps) => {
  const {
    appID,
    pagesList,
    setPagesList,
    pageCount,
    setPageCount,
    selectedPageNumber,
    setSelectedPageNumber,
    allTableHeadData,
    setAllTableHeadData,
    tableHeadLoading,
    setTableHeadLoading,
  } = stateProps;
  const appDatatable = {};
  const AllCustomCommandType = { customColumn: 'customColumn' };
  const updateSqlCustomCommands = false;
  const updateSelectedTable = async ({ key, value }: { key: string; value: any }) => {
    const pagesListCopy = Array.from(pagesList);
    const [selectedItem]: any = pagesListCopy.splice(selectedPageNumber, 1);
    const udpatedSelectedItem = {
      ...selectedItem,
      [key]: value,
    };
    pagesListCopy.splice(selectedPageNumber, 0, udpatedSelectedItem);
    setPagesList(pagesListCopy);
  };
  const setTableSchema = (tableSchema: any) => {
    updateSelectedTable({
      key: 'sqlQuery',
      value: tableSchema.sqlQuery ?? defaultDataByTableType.customColumn.defaultQuery,
    });
  };

  const useUpdateCustomTableSchema = () => {};
  const isAlreadyTable = true;
  const selectedTable = pagesList[selectedPageNumber];
  const selectedTableData =
    allTableHeadData?.[selectedTable?.tableID ?? ('' as keyof typeof allTableHeadData)];
  const tableSchema = selectedTableData?.tableSchema?.defaultTable ?? {};
  const tableDataArray = selectedTableData?.tableData ?? [];

  return (
    <div className='pagesOuterWrapper'>
      <div className='pagesWrapper'>
        <PageHeader
          pagesList={pagesList}
          setPagesList={setPagesList}
          pageCount={pageCount}
          setPageCount={setPageCount}
          selectedPageNumber={selectedPageNumber}
          setSelectedPageNumber={setSelectedPageNumber}
          selectedTable={selectedTable}
        />
        <div className='pageInnerContent'>
          <div className='pageLeftContainer'>
            <PageTableSelection
              stateProps={stateProps}
              selectedTable={selectedTable}
              selectedPageNumber={selectedPageNumber}
              pagesList={pagesList}
              setPagesList={setPagesList}
              allTableHeadData={allTableHeadData}
              setAllTableHeadData={setAllTableHeadData}
              tableHeadLoading={tableHeadLoading}
              setTableHeadLoading={setTableHeadLoading}
            />
          </div>
          {tableHeadLoading ? (
            <div className='pageRightContainer'>
              <div className='pageRightNoTableSelectDiv'>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              </div>
            </div>
          ) : (
            <>
              {selectedTable?.tableID ? (
                <div className='pageRightContainer'>
                  <SqlCustomCommands
                    appID={appID}
                    mainSqlQuery={selectedTable.sqlQuery}
                    tableData={tableSchema}
                    appDatatable={appDatatable}
                    tableSchema={tableSchema}
                    setTableSchema={setTableSchema}
                    useUpdateSchema={useUpdateCustomTableSchema}
                    customCommandType={AllCustomCommandType.customColumn}
                    defaultSqlArea={true}
                    displayOff={!isAlreadyTable}
                    updateSqlCustomCommands={updateSqlCustomCommands}
                    timeFrame={selectedTable.timeFrame}
                    dateTimeCol={selectedTable.dateTimeCol}
                    tableDataArray={tableDataArray}
                    displayOffMsg={mls('Click on done to this create custom Table.')}
                  />
                </div>
              ) : (
                <div className='pageRightContainer'>
                  <div className='pageRightNoTableSelectDiv'>
                    <div className='pageRightNoTableSelectTitleDiv'>
                      {mls('Please select a table to start your report!')}
                    </div>
                    <div className='pageRightNoTableSelectDiscriptionDiv'>
                      {mls(
                        'To start your report, first choose the tables you need. Then, select the columns from those tables that are relevant to your report.'
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
