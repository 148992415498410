/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { mls } from 'lib/multilanguagesupport';
import { KTSVG } from '_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { _supistaApiPost } from 'lib/server-connection/connections';

const UsersList = ({ appID, isNew, newTeamSchema, setTeamSchema }) => {
  const [clicked, setClick] = useState(false);
  const [usersList, setUsersList] = useState(newTeamSchema?.usersList || []);
  const [allUsersList, setAllUsersList] = useState([]);
  const endPoint = `auth/${appID}/fetchAllUsersOfApp`;
  useEffect(() => {
    if (clicked) {
      _supistaApiPost(endPoint, { data: {} })
        .then((res) => {
          const usersData = res?.response?.userData?.rows || [];
          const allList = [];
          usersData.forEach((obj) => {
            if (obj.userID && Array.isArray(obj.role)) {
              allList.push([obj?.userID || '', obj?.emailID || '', obj?.addedByEmailID || '']);
            }
          });
          allList.sort((a, b) => a[0] - b[0]);

          setAllUsersList(allList);
        })
        .catch(() => {});
    }
  }, [clicked, endPoint]);

  const handleCheckboxChange = (userArray, isChecked) => {
    const updatedUsersList = [...usersList];
    if (!isChecked) {
      usersList.forEach((user, ix) => {
        if (user[0] === userArray[0] && !isChecked) {
          updatedUsersList.splice(ix, 1);
          return;
        }
      });
    } else if (isChecked) {
      updatedUsersList.push(userArray);
    }
    updatedUsersList.sort((a, b) => a[0] - b[0]);
    setUsersList(updatedUsersList);
    setTeamSchema({ ...newTeamSchema, usersList: updatedUsersList });
  };
  return (
    <>
      <div className='mt-5'>
        <button className='btn btn-sm btn-light-primary mb-5' onClick={() => setClick(true)}>
          {isNew ? mls('Add Users') : mls('Update Users')}
        </button>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            className='table table-rounded table-row-bordered align-middle gs-0 gy-4'
            style={{ display: 'block', maxHeight: '400px', overflow: 'auto' }}
          >
            {/* begin::Table head */}
            <thead style={{ position: 'sticky', top: 0 }}>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>{mls('User ID')}</th>
                <th className='min-w-325px'>{mls('Email ID')}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody style={{ borderTop: '0px' }}>
              {usersList.map((user) => (
                <tr key={user[0]}>
                  <td>
                    <div className='d-flex ms-4 align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
                          {user[0] || ''}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                      {user[1] || ''}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
      </div>
      {clicked && (
        <Modal show={clicked} onHide={() => setClick(false)} centered size='lg' id='AllUsersList'>
          <div className='modal-content rounded'>
            <div className='modal-header border-0 pb-0'>
              <div className='modal-title'>
                <h2 className=''>{mls('All Users List')}</h2>
                <div className='text-muted fw-bold fs-5'>
                  {mls(`Select the users for this team whom you want to grant the permissions`)}
                </div>
              </div>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                onClick={() => setClick(false)}
                data-bs-dismiss='modal'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body pt-0 pb-5 px-10'>
              <div className='d-flex flex-column'>
                <div className='row mt-5'>
                  <div className='flex-row-fluid py-5 px-5'>
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-rounded table-row-bordered align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bolder text-muted bg-light'>
                            <th className='ps-4 min-w-100px rounded-start'>{mls('Select')}</th>

                            <th className='min-w-125px'>{mls('User ID')}</th>
                            <th className='min-w-250px'>{mls('Email ID')}</th>
                            <th className='min-w-25px'>{mls('Added By')}</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody style={{ borderTop: '0px' }}>
                          {allUsersList.map((user) => (
                            <tr key={user[0]}>
                              <td>
                                <div className='form-check form-check-custom  mx-5'>
                                  <input
                                    className='form-check-input'
                                    style={{ borderWidth: '2px' }}
                                    type='checkbox'
                                    checked={usersList.some((u) => u[0] === user[0])}
                                    onChange={(e) => handleCheckboxChange(user, e.target.checked)}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex ms-4 align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a
                                      href='#'
                                      className='text-dark fw-bold text-hover-primary mb-1 fs-7'
                                    >
                                      {user[0] || ''}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                                >
                                  {user[1] || ''}
                                </a>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                                >
                                  {user[2] || ''}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* end::Table body */}
                      </table>

                      {/* end::Table */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UsersList;
