import { scaleOrdinal } from '@visx/scale';
import { variantColors } from '../constants/constants';

export const ordinalColorScale = (domain, colors) => {
  return scaleOrdinal({
    domain: domain,
    range: colors ? colors : variantColors,
  });
};

function normalizeNumber(num, digits) {
  //uncommnet this for using less then 10 as a 0
  // if (num < 10) return 0;
  // const n = Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits);
  const n = Math.round(num);
  // const digits = n.toString().length - 2;
  return Math.ceil(n / Math.pow(10, digits)) * Math.pow(10, digits);
}
// this function takes 2 parameters
// arr is the array of data
// length is the number of parts you want to divide the range into
export function getPercentile(arr, length = 5) {
  const start = Math.round(Math.min(...arr) * 100) / 100;
  const end = Math.round(Math.max(...arr) * 100) / 100;

  const step = (end - start) / length;
  if (typeof start !== 'number' || typeof end !== 'number' || typeof step !== 'number') {
    return [];
  } else if (step <= 0) {
    return [normalizeNumber(start, step.toString().length - 3)];
  } else {
    const result = [];
    for (let i = start + step; i <= end; i += step) {
      if (step < 1) {
        const roundedValue = Math.round(i * 1000) / 1000;
        result.push(roundedValue);
      } else {
        result.push(normalizeNumber(i, Math.round(i).toString().length - 3));
      }
    }
    if (step === 0) {
      result.push(normalizeNumber(start, step.toString().length - 3));
    }
    return result;
  }
}

export function geoGroupsToSteps(groups) {
  if (!groups || groups.length === 0) return;

  const steps = [],
    colors = [];
  // steps.push(groups[0].from);
  for (let i = 0; i < groups.length; i++) {
    steps.push(groups[i].to);
    colors.push(groups[i].color);
  }
  return { steps, colors };
}
