import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import { Link } from 'react-router-dom';
import { mls } from 'lib/multilanguagesupport';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  className?: string;
};

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  className = '',
}) => {
  const { pathname } = useLocation();
  let isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  let introJSClass = '';
  if (title === mls('Dashboard')) {
    introJSClass = 'introjs-dashboard';
  } else if (title === mls('Sheets')) {
    introJSClass = 'introjs-sheets';
  } else if (title === mls('Data Lake')) {
    isActive = pathname.includes('datalake');
  } else if (title === mls('Users & Roles')) {
    isActive = pathname.includes('roleAccess');
  }
  return (
    <div
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion', introJSClass)}
      data-kt-menu-trigger='click'
    >
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </Link>
      <div
        className={clsx(`menu-sub menu-sub-accordion ${className}`, { 'menu-active-bg': isActive })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
