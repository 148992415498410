import React from 'react';
import ValueNumber from '../../../ValueNumber';
import { mls } from 'lib/multilanguagesupport';

interface scatterTypeDensityColumnProps {
  densityArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  denseStatsArray: { label: string; key: string }[];
  arrow: boolean;
  columnName1: string;
}
export const ScatterTypeDensityColumn = ({
  densityArray,
  finalElem,
  columnName,
  denseStatsArray,
  columnName1,
  arrow,
}: scatterTypeDensityColumnProps) => {
  return (
    <div className='container-fluid h-100 w-100 mb-2 statisticalDivContainer'>
      <div className='col-8 p-4 w-100  insight-table-column-header-left'>
        <b>{mls(columnName1 ?? '')}</b>
      </div>
      {Object.keys(finalElem?.[densityArray?.[0]?.key] ?? {}).map((elemKey: string) => {
        return (
          <div className='col p-4 w-100 insight-table-column-header-right'>
            <b>{columnName?.[elemKey] ?? ''}</b>
          </div>
        );
      })}
      {React.Children.toArray(
        densityArray.map((densityArrayElem: any) => {
          const columnData = finalElem?.[densityArrayElem?.key as keyof typeof finalElem] ?? {};
          return (
            <>
              <div className='col-8 p-4 g-0 w-100 insight-table-data-cell-left'>
                {densityArrayElem?.label ?? ''}
              </div>
              {React.Children.toArray(
                Object.keys(columnData).map((columnDataKey) => {
                  const data = columnData[columnDataKey as keyof typeof columnData];
                  return (
                    <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                      {React.Children.toArray(
                        denseStatsArray.map((denseStatsData) => {
                          return (
                            <div>
                              <b>{denseStatsData?.label}</b>
                              {
                                <ValueNumber
                                  number={data?.[denseStatsData?.key] ?? ''}
                                  arrow={arrow}
                                />
                              }
                            </div>
                          );
                        })
                      )}
                    </div>
                  );
                })
              )}
            </>
          );
        })
      )}
    </div>
  );
};
interface scatterTopBottomColumnProps {
  topBottomArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  minMaxArray: { label: string; key: string }[];
  arrow: boolean;
  columnName1: string;
}
export const ScatterTopBottomColumn = ({
  topBottomArray,
  finalElem,
  columnName,
  minMaxArray,
  columnName1,
  arrow,
}: scatterTopBottomColumnProps) => {
  return (
    <>
      <div className='w-100 twoEqualGrid'>
        {React.Children.toArray(
          topBottomArray.map((densityArrayElem: any) => {
            const columnData = finalElem[densityArrayElem?.key as keyof typeof finalElem] ?? {};
            return (
              <>
                <div className='col mt-2  gx-0 gy-0'>
                  <div className='col py-4 gx-0 gy-0 mb-2 insight-table-heading text-center'>
                    <b>{densityArrayElem?.label ?? ''}</b>
                  </div>
                  <div className='twoEqualGrid'>
                    <>
                      {React.Children.toArray(
                        minMaxArray.map((minMaxArrayElem) => {
                          return (
                            <>
                              <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                <b>{minMaxArrayElem.label ?? ''}</b>
                              </div>
                              <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>
                                {
                                  <ValueNumber
                                    number={columnData?.[minMaxArrayElem.key] ?? ''}
                                    arrow={arrow}
                                  />
                                }
                              </div>
                            </>
                          );
                        })
                      )}
                    </>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </>
  );
};
