import React, { useRef, useState } from 'react';
import { defaultDashboardSchema } from '../../../data/defaultSchema';
import './addSection.scss';
import { mls } from 'lib/multilanguagesupport';
const AddSection = ({ sections, setSections }: { setSections: Function; sections: any }) => {
  const addTargetPoints = () => {
    setSections((currentTargetPointData: any[]) => {
      return [...currentTargetPointData, defaultDashboardSchema.sections];
    });
  };

  return (
    <div className='addSectionContainer'>
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButtonSmall' onClick={() => addTargetPoints()}>
          + {mls('Add')}
        </button>
      </div>
    </div>
  );
};

export default AddSection;

interface AddSectionInputProps {
  index: number;
  sections: any;
  setSections: Function;
  isInputVisible: boolean;
  handleInputVisibility: Function;
}

export const AddSectionInput = ({
  index,
  sections,
  setSections,
  isInputVisible,
  handleInputVisibility,
}: AddSectionInputProps) => {
  const [name, setName] = useState(sections[index]?.title || '');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const addTargetPoints = () => {
    const newSections = [...sections];
    newSections[index] = {
      ...newSections[index],
      title: name,
    };
    handleInputVisibility();
    setSections(newSections);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addTargetPoints();
    }
  };
  // useEffect(() => {
  //   function handleClickOutside(event: any) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       addTargetPoints();
  //     }
  //   }
  //   if (isInputVisible) {
  //     // Bind the event listener
  //     document.addEventListener('mousedown', handleClickOutside);
  //   }
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [wrapperRef, isInputVisible]);
  return (
    <div className=' p-0 pb-2'>
      <div className='p-4' style={{ background: 'white' }} ref={wrapperRef}>
        <div className='titleDiv'>{mls('Change Name')}:</div>
        <input
          className='primaryInput'
          type='string'
          value={name}
          name='title'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e)}
          onKeyPress={handleEnter}
        />
        <div className='primaryButtonFlexDiv'>
          <button className='primaryButtonSmall' onClick={() => addTargetPoints()}>
            {mls('Done')}
          </button>
        </div>
      </div>
    </div>
  );
};
