import React from 'react';
import { defaultChartSetting } from '../../../data/defaultChartSchema';
import EditColors from '../../../components/StyleComponents/ChartDisplay/EditColors';
import RadialLineAxisStyle from 'lib/chart-settings-lib/components/SpacialCasesComponents/RadialLine/RadialLineAxisStyle';
import { allDropableID } from 'lib/reusable-components/reusableData/defaultChartSchema';
import SolidColorSelector from 'lib/chart-settings-lib/components/StyleComponents/SolidColorSelector';
import { mls } from 'lib/multilanguagesupport';

const RadialLineStyleCase = ({ stateProps }) => {

  const { yAxisData, setChartYAxisStyle, chartYAxisStyle, chartToolTip, setChartToolTip } = stateProps;
  const { xAxisId } = allDropableID;
  const handleXAxisValue = (updatedStyle) => {
    setChartToolTip((currentStyle) => {
      const newStyle = currentStyle?.style.map((styleElem) => {
        if (updatedStyle.uniqueColumnName === styleElem.uniqueColumnName) {
          return updatedStyle
        }
        return styleElem
      })
      return { ...currentStyle, style: newStyle }
    });
  };
  const xAxisValue = chartToolTip?.style.find((styleElem) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
  });
  return (
    <>
      <RadialLineAxisStyle
        axisStyle={chartYAxisStyle}
        setAxisStyle={setChartYAxisStyle}
        axisData={yAxisData}
        defaultLable={defaultChartSetting.xAxisStyle.label}
      />
      <EditColors stateProps={stateProps} />
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls("BackGround Color")}</div>

        <div className='grayContainers CardHeaderImage'>
          <div className='containerItems'>
            <SolidColorSelector
              color={xAxisValue}
              setColor={handleXAxisValue}
              isPalette={false}
              selectColorTitle={mls('Select Max Color')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RadialLineStyleCase;
