import { mls } from 'lib/multilanguagesupport';
import React, { useRef, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { checkForColumnLableDuplicacy } from '../utils/kpiTimeFrameUtils';

interface AddKpiCategorysColumnProps {
  kpiCategorys: { [key: string]: any };
  handleKpiCategory: Function;
  isInputState: boolean;
  setIsInputState: Function;
}
const AddKpiCategorysColumn = ({
  kpiCategorys,
  handleKpiCategory,
  isInputState,
  setIsInputState,
}: AddKpiCategorysColumnProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [tempLabel, setTempLabel] = useState('');

  const handleSwithToInput = (action: boolean) => {
    if (action) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 1);
    } else {
      if (tempLabel === '') {
        setIsInputState(action);
        return;
      }
      const isKeyPresent = checkForColumnLableDuplicacy({
        kpiCategorys: kpiCategorys,
        label: tempLabel,
      });
      if (isKeyPresent) {
        toast.error(mls("You're repeating the same column name again."));
        setTempLabel('');
        setIsInputState(action);
        return;
      }
      const columnKey = uuidv4();
      const sampleColumn = {
        key: columnKey,
        label: tempLabel,
        data: {},
      };
      handleKpiCategory({
        kpiData: sampleColumn,
        kpiCategoryKey: columnKey,
      });
      setTempLabel('');
    }
    setIsInputState(action);
  };

  return (
    <div
      className={`gridCell upperColumnGridCell addColumnGridCell kpiPeriodRangeTitleColumn ${
        isInputState ? 'isInputState' : ''
      }`}
      style={
        isInputState
          ? {
              width: '12rem',
            }
          : {}
      }
      onClick={() => {
        handleSwithToInput(true);
      }}
      onBlur={() => {
        handleSwithToInput(false);
      }}
      onAbort={() => {
        handleSwithToInput(false);
      }}
    >
      {isInputState ? (
        <div className='editCellInputTextDiv'>
          <input
            type='text'
            ref={inputRef}
            value={tempLabel}
            onChange={(e) => setTempLabel(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSwithToInput(false);
              }
            }}
          />
        </div>
      ) : (
        <>
          <IoMdAdd className='columnAddIcon' />
        </>
      )}
    </div>
  );
};

export default AddKpiCategorysColumn;
