import React from 'react';
import ChartStyle from '../../components/StyleComponents/ChartStyle';
import PlotChartBuildCase from './PlotChart/PlotChartBuildCase';

const PlotChart = ({ stateProps, buddleProps, toggleProps }) => {
  const { settingToggleState } = buddleProps;

  return (
    <>
      {settingToggleState === 'build' && <PlotChartBuildCase stateProps={stateProps} />}
      {settingToggleState === 'style' && <ChartStyle stateProps={stateProps} />}
    </>
  );
};

export default PlotChart;
