import { mls } from 'lib/multilanguagesupport';
import GridLinesDiv from './GridLinesDiv';

interface GridLinesProps {
  stateProps: any;
}
const GridLines = ({ stateProps }: GridLinesProps) => {
  const { gridLines, setGridLines } = stateProps;
  const updateHorizontalGridLines = (action: any) => {
    setGridLines({ ...gridLines, horizontal: action });
  };
  const updateVerticalGridLines = (action: any) => {
    setGridLines({ ...gridLines, vertical: action });
  };

  return (
    <div className={'outerDiv open'}>
      <div className='MainTitle inFlex'>{mls('Gridlines')}</div>
      <div className='grayContainers'>
        <GridLinesDiv
          gridLines={gridLines?.horizontal}
          setGridLines={updateHorizontalGridLines}
          title={mls('Horizontal')}
        />
        <GridLinesDiv
          gridLines={gridLines?.vertical}
          setGridLines={updateVerticalGridLines}
          title={mls('Vertical')}
        />
      </div>
    </div>
  );
};

export default GridLines;
