import React from 'react';

import {
  allDropableID,
  defaultChartSetting,
  defaultKpiTimeFrame,
} from '../../../data/defaultChartSchema';
import DragableAxis from '../../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
import KpiTargetPoints from '../../../components/SpacialCasesComponents/KpiMeaters/KpiTargetPoints';
import DragableToolTip from '../../../components/BuildComponents/DragableToolTip';

import { ObjectDropDown } from 'lib/chart-settings-lib/features/NewDropDown/DropDown';
import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const KpiMeterBuildCase = ({ stateProps }) => {
  const {
    yAxisData,
    setYAxisData,
    setChartYAxisStyle,
    kpiTargetPoints,
    setKpiTargetPoints,
    defaultDateTimeCol,
    isInsight,
    setIsInsight,
    chartType,
  } = stateProps;
  const { yAxisId } = allDropableID;
  const handleKpiTimeFrame = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, kpiTimeframe: e });
  };
  const handleFormatter = (e) => {
    const formatedTargets = (kpiTargetPoints?.targets ?? []).map((elem) => {
      return {
        ...elem,
        label: numberFormatter({ type: e, value: elem?.targetPoint, short: true }),
      };
    });
    setKpiTargetPoints({ ...kpiTargetPoints, formatter: e, targets: formatedTargets });
  };
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('KPI Target Points')} *</div>

        <KpiTargetPoints stateProps={stateProps} />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Formatter')}</div>

        <div className='grayContainers'>
          <div className='containerItems'>
            <NumberFormatterComp
              formatter={kpiTargetPoints?.formatter}
              setFormatter={handleFormatter}
              divBorder={false}
            />
          </div>
        </div>
      </div>
      {defaultDateTimeCol ? (
        <div className='outerDiv open'>
          <div className='MainTitle'>{mls('Time Frame')}</div>
          <div className='grayContainers'>
            <div className={`containerItems open`}>
              <div className='titleDiv'>{mls('Time Frame')}</div>
              <ObjectDropDown
                object={defaultKpiTimeFrame}
                dropDownValue={kpiTargetPoints.kpiTimeframe}
                setdropDownValue={handleKpiTimeFrame}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>
        <FilterIndex stateProps={stateProps} />
      </div>
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default KpiMeterBuildCase;
