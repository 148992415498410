export const timeFrameRangeOptions = {
  THISMONTH: {
    key: 'THISMONTH',
    lable: 'Monthly',
  },

  THISQUARTER: {
    key: 'THISQUARTER',
    lable: 'Quarterly',
  },
  THISYEAR: {
    key: 'THISYEAR',
    lable: 'Yearly',
  },
};
export const timeFrameMonthsOfYear = [
  { key: '01', label: 'Jan' },
  { key: '02', label: 'Feb' },
  { key: '03', label: 'Mar' },
  { key: '04', label: 'Apr' },
  { key: '05', label: 'May' },
  { key: '06', label: 'Jun' },
  { key: '07', label: 'Jul' },
  { key: '08', label: 'Aug' },
  { key: '09', label: 'Sep' },
  { key: '10', label: 'Oct' },
  { key: '11', label: 'Nov' },
  { key: '12', label: 'Dec' },
];
export const timeFrameQuaterOfYear = [
  { key: '01', label: 'Jan - Mar' },
  { key: '02', label: 'Apr - Jun' },
  { key: '03', label: 'Jul - Sep' },
  { key: '04', label: 'Oct - Dec' },
];
export const timeFramefYear = [{ key: '', label: '' }];
export const timeFrameColumnColor = ['#E8FFF3', '#ECF8FF', '#F8F5FF', '#FFF8DD'];
