import introJs from 'intro.js';
import { useHistory } from 'react-router-dom';
import getAppID from 'utils/getAppID';
import { clickOnElement, selectOption, selectQuery } from './stepTemplate';
import { useState } from 'react';
import useStep3 from './step3';

const useStep2 = () => {
  const step3 = useStep3();
  const intro = introJs();
  const history = useHistory();
  let appID = getAppID(history.location.pathname);

  return (handleCompleteTutorial) => {
    history.push(`/${appID}/dataLake`);

    intro.setOptions({
      steps: [
        {
          element: selectQuery('.introjs-connectionform', 0),
          title: 'Connect to your Data Source',
          intro:
            'You can connect to your Databases, Excel Sheets, Google Sheets, Software using plugins.',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-connections', 0),
          title: 'Manage your Connections here',
          intro: 'A Sample Google Sheet connection has been created to experiment with!',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-datatableTab', 0),
          title: 'Data Table is here',
          intro: 'All your Data Tables imported from your Data Connections will be listed here!',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-datatablePage', 0),
          title: 'Manage your Data Tables here',
          intro:
            '2 Sample Tables : Product List and Sales Table has been created for you to experiment with!',
          position: 'left',
        },

        {
          element: selectQuery('.introjs-jointableTab', 0),
          title: 'Join Table is here.',
          intro: 'You can join two or more tables to make a better sense in your data here!',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-jointablePage', 0),
          title: 'Manage your Data Tables here',
          intro: 'We have already joined Sales table and Product Table for you!',
          position: 'left',
        },

        {
          title: 'Finish',
        },
      ],
      nextLabel: 'Next',
      prevLabel: 'Previous',
      showStepNumbers: false,
      exitOnOverlayClick: false,
      showBullets: false,
      disableInteraction: true,
    });

    intro.onafterchange(async (targetElement) => {
      const step = intro._currentStep;

      if (step === 2) {
        document.querySelectorAll('.introjs-datatableTab')[0].click();
      }
      if (step === 4) {
        document.querySelectorAll('.introjs-jointableTab')[0].click();
      }
      if (step === 6) {
        intro.exit();
        step3(handleCompleteTutorial);
      }
    });

    intro.start();
  };
};

export default useStep2;
