import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const checkValidImage = (imageUrl, setIsValidImage) => {
  const img = new Image();
  img.onload = function () {
    setIsValidImage(true);
  };

  img.onerror = function () {
    setIsValidImage(false);
  };
  img.src = imageUrl;
};
function Card2({ title, text, percentage, targetPoints, data, isCompare, headerImage, isReport }) {
  const [isValidImage, setIsValidImage] = useState(false);
  useEffect(async () => {
    checkValidImage(headerImage, setIsValidImage);
  }, [headerImage]);

  const gridTemplate = `${isValidImage ? '2rem' : ''} auto ${isCompare ? '3rem' : ''}`;
  return (
    // <Layout title='Card2'>
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      flex={1}
    // sx={{
    //   height: '110px',
    // }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
            width: '100%',
            display: 'grid',
            // gridTemplateColumns: '2rem auto 3rem',
            gridTemplateColumns: gridTemplate,
          }}
        >
          {isValidImage ? (
            <Box
              component='img'
              sx={{
                height: 30,
                width: 30,
                overflowWrap: 'break-word',
              }}
              alt=''
              src={headerImage}
            />
          ) : null}
          <Typography
            variant='h4'
            sx={{
              fontWeight: 'bold',
              overflowWrap: 'break-word',
              // width: textWidth,
              display: '-webkit-box',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          {isCompare ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box
                sx={{
                  backgroundColor: percentage > 0 ? '#bbf7d0' : '#fecaca',
                  padding: '2px',
                  borderRadius: '5px',
                }}
              >
                <Typography fontWeight={'bold'} color={percentage > 0 ? '#166534' : '#991b1b'}>
                  {/* {Math.abs(percentage).toFixed(2)}% */}
                  {Math.round(percentage * 100) / 100}%
                </Typography>
              </Box>
            </div>
          ) : null}
        </Box>

        <Typography
          variant='h6'
          sx={{
            color: '#9ca3af',
            fontWeight: 500,
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </Typography>
      </Box>

      <Box display='flex' flexDirection={'column'} gap={1}>
        {(targetPoints || []).map((tp, i) => {
          const per = Math.round((data / tp.goal) * 100, 0);
          const color =
            (tp.isTargetPositive && data >= tp.goal) || (!tp.isTargetPositive && data <= tp.goal)
              ? tp.color.safe
              : tp.color.danger;
          return (
            <Box key={i} display='flex' flexDirection={'column'}>
              <Box
                display='flex'
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant='h6' fontWeight={'bold'}>
                  {tp.goal - data} to Goal
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    color: '#9ca3af',
                    fontWeight: 500,
                  }}
                >
                  {per}%
                </Typography>
              </Box>
              <BorderLinearProgress progresscolor={color} variant='determinate' value={per} />
            </Box>
          );
        })}
      </Box>
    </Box>
    // </Layout>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme, progresscolor }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: progresscolor,
  },
}));

export default Card2;
