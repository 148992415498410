import React from 'react';
import SolidColorSelector from '../../StyleComponents/SolidColorSelector';
import { mls } from 'lib/multilanguagesupport';

interface plotColorsProps {
  chartToolTip: any;
  yAxisData: any[];
  setChartToolTip: Function;
}
const PlotColors = ({ chartToolTip, yAxisData, setChartToolTip }: plotColorsProps) => {
  const yAxisStyle = chartToolTip?.style?.find((elem: any) => {
    return elem?.uniqueColumnName === yAxisData[0]?.uniqueColumnName;
  });
  const handleYAxisStyle = (action: any) => {
    const newStyle = chartToolTip?.style?.map((elem: any) => {
      if (elem?.uniqueColumnName === yAxisData[0]?.uniqueColumnName) {
        return action;
      }
      return elem;
    });
    setChartToolTip((currentToolTip: any) => ({
      ...currentToolTip,
      style: newStyle,
      automaticOpacity: action?.opacity ?? currentToolTip?.automaticOpacity,
    }));
  };
  return (
    <>
      {yAxisStyle ? (
        <div className='outerDiv open'>
          <div className='MainTitle'>{mls('Plot Color')}</div>
          <div className='grayContainers CardHeaderImage'>
            <div className='containerItems'>
              <SolidColorSelector
                color={yAxisStyle}
                setColor={handleYAxisStyle}
                isPalette={false}
                selectColorTitle={mls('Color')}
              />

              {/* <SolidColorSelector
                    color={heatMapColor.min}
                    setColor={handleMinColor}
                    isPalette={false}
                    selectColorTitle={'Select Min Color'}
                  /> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PlotColors;
