import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { _supistaAnalyticsApi, _supistaApiDelete } from 'lib/server-connection/connections';
// import { supistaAnalyticsApi, supistaApiDelete } from '../dependencies/connections';

import _ from 'lodash';
// This function deletes the sheets and charts of the table
export const deleteSheetandCharts = (appID, sheetIDs, chartIDs, sheetSchema, updateSchema) => {
  // Deleting sheets
  let allSheetSchema = { ...sheetSchema };

  sheetIDs.map(async (sheetID) => {
    const payloadData = { appID, sheetID };
    const res = await _supistaAnalyticsApi(`${appID}/SheetSettings/delete`, payloadData);
    if (!res?.__d3__error) {
      allSheetSchema = _.omit(allSheetSchema, sheetID);
      updateSchema(null, 'appSheets', allSheetSchema);
    }
  });

  chartIDs.map(async (chartID) => {
    const payloadData = { appID, chartID };
    await _supistaAnalyticsApi(`${appID}/ChartSettings/delete`, payloadData);
  });
};

// getting list of chartIDs and sheetIDs which is using the deleted tableID
export const getSheetChartsIDs = (tableID, sheetsSchema, sheetIDsTBD = [], chartIDSTBD = []) => {
  // TBD means To Be Deleted
  Object.keys(sheetsSchema).forEach((sheetID) => {
    if (sheetsSchema[sheetID]?.defaultTable?.tableID === tableID) {
      sheetIDsTBD.push(sheetID);
      const chartIDs = sheetsSchema[sheetID].charts || [];
      chartIDSTBD.push(...chartIDs);
    }
  });
  return { sheetIDsTBD, chartIDSTBD };
};

// get sheetIDs and chartIDs of join tables and table that is being deleted
export const getSheetChartsIDsTBD = (tableIDsTBD, joinTableIDsTBD, sheetsSchema) => {
  const sheetIDsTBD = [];
  const chartIDSTBD = [];
  tableIDsTBD.map((tableID) => getSheetChartsIDs(tableID, sheetsSchema, sheetIDsTBD, chartIDSTBD));
  joinTableIDsTBD.map((jtID) => getSheetChartsIDs(jtID, sheetsSchema, sheetIDsTBD, chartIDSTBD));
  return { sheetIDsTBD, chartIDSTBD };
};

//  get tableIds jointableIds while deleting a credential connection
export const getTableJoinTableIDs = (credentialID, appDataTable, appJoinTable) => {
  const tableIDsTBD = [];
  const joinTableIDsTBD = [];
  Object.keys(appDataTable).forEach((tableID) => {
    if (appDataTable[tableID].credentialID === credentialID) {
      tableIDsTBD.push(tableID);
      const newJTIDs = getJoinTableIDs(tableID, appJoinTable, joinTableIDsTBD);
      joinTableIDsTBD.push(...newJTIDs);
    }
  });
  return { tableIDsTBD, joinTableIDsTBD };
};

// getting jointableIDs while deleting table
export const getJoinTableIDs = (tableID, appJoinTable, originalList) => {
  const joinTableIDsTBD = [];
  Object.keys(appJoinTable).forEach((joinedTableID) => {
    if (
      appJoinTable[joinedTableID]?.tables?.includes(tableID) &&
      !originalList.includes(joinedTableID)
    ) {
      joinTableIDsTBD.push(joinedTableID);
    }
  });
  return joinTableIDsTBD;
};

// get reportDs and dashIDs from Chart ID that is being deleted
export const getReportDashIDsTBD = ({ chartIDs, dashSchema, reportSchema }) => {
  const dashIDsTBD = getDashIDsFromChartID({ dashSchema, chartIDs });
  const reportIDsTBD = getReportIDsFromChartID({ reportSchema, chartIDs });
  return { reportIDsTBD, dashIDsTBD };
};

// getting list of ReportIDs which is using the deleted chartID
export const getReportIDsFromChartID = ({ reportSchema, chartIDs }) => {
  let reportIDsTBD = {};
  // TBD means To Be Deleted
  Object.keys(reportSchema).forEach((reportID) => {
    const reportMainElem = reportSchema[reportID];
    (reportMainElem.reports ?? []).forEach((reportElem, repIx) => {
      const isCharIDSame = chartIDs.some((chartId) => {
        return chartId === reportElem?.chartID;
      });
      if (isCharIDSame) {
        const reportDataTBD = {
          repIx: repIx,
          chartID: reportElem?.chartID,
        };
        if (reportIDsTBD[reportID]) {
          reportIDsTBD = {
            ...reportIDsTBD,
            [reportID]: [...(reportIDsTBD[reportID] ?? {}), reportDataTBD],
          };
        } else {
          reportIDsTBD = { ...reportIDsTBD, [reportID]: [reportDataTBD] };
        }
      }
    });
  });
  return reportIDsTBD;
};
// getting list of DashIDs which is using the deleted chartID
export const getDashIDsFromChartID = ({ dashSchema, chartIDs }) => {
  // TBD means To Be Deleted
  let dashIDsTBD = {};
  Object.keys(dashSchema).forEach((dashID) => {
    const dashElem = dashSchema[dashID];
    (dashElem.sections ?? []).forEach((sectionsElem, secIx) => {
      (sectionsElem.charts ?? []).forEach((chartElem, chartsIx) => {
        const isCharIDSame = chartIDs.some((chartId) => {
          return chartId === chartElem.id;
        });
        if (isCharIDSame) {
          const dashDataTBD = {
            secIx: secIx,
            chartsIx: chartsIx,
            chartID: chartElem.id,
          };
          if (dashIDsTBD[dashID]) {
            dashIDsTBD = { ...dashIDsTBD, [dashID]: [...(dashIDsTBD[dashID] ?? {}), dashDataTBD] };
          } else {
            dashIDsTBD = { ...dashIDsTBD, [dashID]: [dashDataTBD] };
          }
        }
      });
    });
  });
  return dashIDsTBD;
};
// get tableIDs of CustomTable and joinTable from Table ID that is being deleted
export const getTableIDSTBD = ({ joinTableSchema, customTableSchema, tableIDs }) => {
  const customTableIDsTBD = getCustomTableIDs({ customTableSchema, tableIDs });
  const joinTableIDsTBD = getJoinTableIDsTBD({ joinTableSchema, tableIDs });
  return { customTableIDsTBD, joinTableIDsTBD };
};
// getting list of CustomTableIDs which is using the deleted tableID
export const getCustomTableIDs = ({ customTableSchema, tableIDs }) => {
  let customTableIDsTBD = [];
  // // TBD means To Be Deleted
  Object.keys(customTableSchema).forEach((customTableID) => {
    const customTableMainElem = customTableSchema[customTableID];
    const isCustomTableIDSame = (customTableMainElem.tables ?? []).some((customTablesElem) => {
      const isTableIDSame = tableIDs.some((tableId) => {
        return tableId === customTablesElem;
      });
      return isTableIDSame;
    });
    if (isCustomTableIDSame) {
      customTableIDsTBD.push(customTableID);
    }
  });
  return customTableIDsTBD;
};
// getting list of JoinTableIDs which is using the deleted tableID
export const getJoinTableIDsTBD = ({ joinTableSchema, tableIDs }) => {
  let joinTableIDsTBD = [];
  // // TBD means To Be Deleted
  Object.keys(joinTableSchema).forEach((joinTableID) => {
    const joinTableMainElem = joinTableSchema[joinTableID];
    const isTableIDSame = tableIDs.some((tableId) => {
      return tableId === joinTableMainElem.primaryTable;
    });
    if (isTableIDSame) {
      joinTableIDsTBD.push(joinTableID);
      return;
    }
    const isJoinTableIDSame = (joinTableMainElem.tables ?? []).some((customTablesElem) => {
      const isTableIDSame = tableIDs.some((tableId) => {
        return tableId === customTablesElem;
      });
      return isTableIDSame;
    });
    if (isJoinTableIDSame) {
      joinTableIDsTBD.push(joinTableID);
    }
  });
  return joinTableIDsTBD;
};
/**
 * Deleting jointables or the normal table
 * @param {string} appID
 * @param {Array} tableIDsTBD List of tableIDS
 * @param {Array} joinTableIDsTBD List of joined tabled IDs
 * @param {Function} updateTableSchema
 * @param {Boolean} updateJoinTableSchema
 */
export const deleteJoinTableOrNormalTable = (
  appID,
  tableIDsTBD,
  joinTableIDsTBD,
  updateTableSchema,
  updateJoinTableSchema
) => {
  tableIDsTBD.map(async (tableID) => {
    await _supistaApiDelete(`settings/${appID}/DataTable`, {
      data: {
        tableID: tableID,
        name: tableID,
      },
    });
    publishToProduction(appID);
    updateTableSchema(true, tableID);
  });
  joinTableIDsTBD.map(async (jTableID) => {
    await _supistaApiDelete(`settings/${appID}/JoinTable`, {
      data: {
        tableID: jTableID,
        name: jTableID,
      },
    });
    publishToProduction(appID);
    updateJoinTableSchema(true, jTableID);
  });
};
