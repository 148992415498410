import React, { useState } from 'react';
import { Drawer, IconButton } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import CloseIcon from '@mui/icons-material/Close';
import { _fetchDataToPlot } from 'lib/server-connection/connections';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';

function EditChart({ show, setShow, chartsSchema, sheetID, sheetSchema, analyticsSchema, appID }) {
  const toastMsg = useMessageHandling();
  const updateSchema = useUpdateSchema();
  const dispatch = useDispatch();
  const [chartLists, setChartLists] = useState(sheetSchema[sheetID].charts || []);
  const [deleteList, setDeleteList] = useState([]);
  const location = getSchemaLocation(schemaConstants.type.SHEET);
  const [newSheetSchema, setSheetSchema] = useState({});
  // a little function to help us with reordering the result
  const isReadyForQuery =
    useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.READY_FOR_QUERY) || 0;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(chartLists, result.source.index, result.destination.index);
    setChartLists(items);
    const newSchema = { ...sheetSchema[sheetID] };
    newSchema['charts'] = items;
    setSheetSchema(newSchema);

    // updating appProject in appSchema in redux store
    // updateSchema(location, sheetID, newSheetSchema);
  };

  const handleDelete = (id, ix) => {
    setDeleteList([...deleteList, id]);
    const newChartIDs = [...chartLists];
    newChartIDs.splice(ix, 1);
    setChartLists(newChartIDs);
    const newSchema = { ...sheetSchema[sheetID] };
    newSchema['charts'] = newChartIDs;
    setSheetSchema(newSchema);
  };

  const handleSave = async () => {
    if (deleteList.length > 0) {
      deleteList.forEach(async (id, ix) => {
        await _fetchDataToPlot({ appID, chartID: id }, `${appID}/ChartSettings/delete`);
      });
    }

    try {
      const res = await _fetchDataToPlot(newSheetSchema, `${appID}/SheetSettings/create`);
      if (!res.__d3__error) {
        updateSchema(location, sheetID, newSheetSchema);
      }
    } catch (err) {
      toastMsg(
        reduxConstants.config.APP_STATUS.ERROR_TOAST,
        mls('There was a problem while updating chart list, please try again later!'),
        5,
        true
      );
    }
    setDeleteList([]);
    dispatch(
      create({
        setPath: `${reduxConstants.config.READY_FOR_QUERY}`,
        value: isReadyForQuery + 1,
      })
    );
    setShow(false);
    toastMsg(
      reduxConstants.config.APP_STATUS.TOAST,
      `${mls('Chart list updated successfully')}`,
      5,
      true
    );
  };
  return (
    <>
      <Drawer anchor='right' open={show} onClose={() => setShow(false)}>
        {/* begin::Card  */}
        <div className='card shadow-none w-100' style={{ minWidth: '50vh' }}>
          {/* begin::Header */}
          <div className='card-header' id='kt_explore_header'>
            <h5 className='card-title fw-bold text-gray-600'>{mls('Edit Charts List')}</h5>
            <div className='d-flex'>
              <div className='d-flex align-items-stretch flex-shrink-0'>
                <div className='d-flex flex-stack'>
                  <button className='btn btn-sm btn-light-primary me-3' onClick={handleSave}>
                    {mls('Save')}
                  </button>

                  <IconButton aria-label='close' onClick={() => setShow(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>

            {/* <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_explore_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div> */}
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body' id='kt_explore_body'>
            {/* begin::Content */}
            <div
              className='scroll-y me-n5 pe-5 pt-4'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-offset='5px'
              style={{ height: '90vh' }}
            >
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {chartLists.map((chartID, index) => (
                        <>
                          {chartsSchema[chartID] && (
                            <Draggable key={chartID} draggableId={chartID} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style
                                  // )}
                                >
                                  <div key={chartID}>
                                    <div className='d-flex flex-stack mb-5'>
                                      <span className='d-flex align-items-center me-2'>
                                        <span
                                          className='symbol symbol-50px me-6'
                                          style={{ cursor: 'grab' }}
                                        >
                                          {/* <i className='bi bi-distribute-vertical fs-2x'></i> */}
                                          <img
                                            src='/media/icons/drag.svg'
                                            style={{ width: 32 }}
                                            alt='drag and rearrange charts'
                                          />
                                        </span>

                                        <span className='symbol symbol-50px me-6'>
                                          {/* <i className='bi bi-distribute-vertical fs-2x'></i> */}
                                          <img
                                            src={
                                              allChartTypesObj[chartsSchema[chartID]?.chartType]
                                                ?.icon
                                            }
                                            style={{ width: 32 }}
                                            alt=''
                                          />
                                        </span>

                                        <span className='d-flex flex-column me-6'>
                                          <span className='fw-bolder fs-6'>
                                            {mls(chartsSchema[chartID]?.name)}
                                          </span>

                                          <div>
                                            <span className='fs-7 me-2 text-muted'>
                                              {mls('Type of chart:')}{' '}
                                              <span className='fs-7 fw-bolder '>
                                                {mls(chartsSchema[chartID]?.chartType)}
                                              </span>
                                            </span>
                                            <span className='fs-7 text-muted'>
                                              {mls('Chart width:')}{' '}
                                              <span className='fs-7 fw-bolder '>
                                                {mls(chartsSchema[chartID]?.chartWidth)}
                                              </span>
                                            </span>
                                          </div>
                                        </span>
                                      </span>
                                      <IconButton onClick={() => handleDelete(chartID, index)}>
                                        {/* <span> */}
                                        <i
                                          className='bi bi-trash-fill symbol-50 text-hover-danger'
                                          style={{ fontSize: '1.1rem' }}
                                        ></i>
                                        {/* </span> */}
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )}
                        </>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {/* end::Content */}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Card */}
      </Drawer>
      {/* <div
        id='d3_chartOrder'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='chartOrder'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#d3_chartOrder_toggle'
        data-kt-drawer-close='#d3_chartOrder_close'
      >
        <div className='card shadow-none rounded-0'>
          <div className='card-header' id='d3_chartOrder_header'>
            <h3 className='card-title fw-bolder text-dark'>Activity Logs</h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='d3_chartOrder_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
          </div>
          <div className='card-body position-relative' id='d3_chartOrder_body'>
            <div
              id='d3_chartOrder_scroll'
              className='position-relative scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#d3_chartOrder_body'
              data-kt-scroll-dependencies='#d3_chartOrder_header, #d3_chartOrder_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='timeline'></div>
            </div>
          </div>
          <div className='card-footer py-5 text-center' id='d3_chartOrder_footer'></div>
        </div>
      </div> */}
    </>
  );
}

export default EditChart;
