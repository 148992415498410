import { getConvertedTableData } from 'lib/reusable-components/DisplayTable/tableFunction';
import { defaultChartSetting, defaultChartWidthTypes } from '../data/defaultSchema';
import { allChartTypes } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { toast } from 'react-toastify';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';

interface props {
  stateProps: any;
  chartSchema: any;
  populateState: boolean;
  setPopulateState: any;
}

interface ColumnDropNumberProps {
  xAxis: any[];
  yAxis: any[];
  group: any[];
}
const getColumnDropNumber = (ColumnDropNumberProps: ColumnDropNumberProps) => {
  const { xAxis, yAxis, group } = ColumnDropNumberProps;
  let columnDropNumber = 0;
  columnDropNumber = columnDropNumber + xAxis.length;
  columnDropNumber = columnDropNumber + yAxis.length;
  columnDropNumber = columnDropNumber + group.length;
  return columnDropNumber;
};
const chartType = (chartKey: string) => {
  if (chartKey) {
    const [chartType] = allChartTypes.filter((e) => e.chartKey === chartKey);
    return chartType;
  } else {
    return undefined;
  }
};
const kpiProjectedTargetPoints = async ({
  chartID,
  appID,
  setKpiProjectedTargetPoints,
  setKpiTimePeriodRange,
  setIsProjectedTargetPointCreated,
}: {
  chartID: string;
  appID: string;
  setKpiProjectedTargetPoints: Function;
  setKpiTimePeriodRange: Function;
  setIsProjectedTargetPointCreated: Function;
}) => {
  const endPoint = 'read';

  const res = await _supistaAnalyticsApi(`${appID}/kpiSettings/KpiTargetPoints/${endPoint}`, {
    query: { chartID },
  })
    .then(async (response) => {
      if (response.__d3__error) {
        return { skip: true, data: [], msg: response?.response?.msg };
      } else {
        return { skip: false, data: response?.response?.data ?? [], msg: undefined };
      }
    })
    .catch((error) => {
      return { skip: true, data: [], msg: undefined };
    });
  if (res.skip) {
  } else {
    const kpiProjectedTargePointData = res?.data?.[0];
    if (kpiProjectedTargePointData) {
      setKpiProjectedTargetPoints(kpiProjectedTargePointData.data);
      setKpiTimePeriodRange(kpiProjectedTargePointData.timePeriodRange);
      setIsProjectedTargetPointCreated(true);
    }
  }
};
interface getDefaultAxisStyleWithTitleProps {
  defaultStyle: any;
  axis: any;
}
const getDefaultAxisStyleWithTitle = ({
  defaultStyle,
  axis = [],
}: getDefaultAxisStyleWithTitleProps) => {
  const labelArray = axis.map((elem: any) => {
    return elem?.label;
  });
  const label = labelArray.join(',');

  return { ...defaultStyle, label };
};

const chartWidth = (chartWidthKey: string) => {
  if (chartWidthKey) {
    const [chartWidth] = defaultChartWidthTypes.filter((e: any) => e.key === chartWidthKey);
    return chartWidth;
  } else {
    return undefined;
  }
};

const handleTable = async ({
  appID,
  selectedTable,
  sheetID,
  setDTCols,
  setTableSchema,
  setFilterdTableSchema,
  setIsJoinedTable,
  setDefaultTable,
  setIsTableSelected,
}: {
  appID: string;
  selectedTable: string;
  sheetID: string;
  setDTCols: Function;
  setTableSchema: Function;
  setFilterdTableSchema: Function;
  setIsJoinedTable: Function;
  setDefaultTable: Function;
  setIsTableSelected: Function;
}) => {
  const tableData = await getConvertedTableData({
    appID: appID,
    selectedTable: selectedTable,
    sheetID: sheetID,
    newSheetID: false,
  });
  if (tableData?.skip) {
    toast.error('Some error occurred while fetching the table data.');
    return { skip: true, skipFrom: 'tableData' };
  }

  const newTableSchema = tableData?.data?.tableSchema?.data?.map((tableElem: any) => {
    return { ...tableElem, sheetID: sheetID };
  });
  const tableSchema = {
    ...tableData?.data?.tableSchema,
    data: newTableSchema,
    defaultTable: {
      ...tableData?.data?.tableSchema?.defaultTable,
      sheetID: sheetID,
    },
  };

  // setSheetID(sheetID ?? '');
  setTableSchema(tableSchema?.data ?? []);
  setFilterdTableSchema(tableSchema?.data ?? []);
  setIsJoinedTable(tableSchema?.isJoinedTable ?? false);
  setDefaultTable(tableSchema?.defaultTable ?? {});
  setIsTableSelected(true);
  // setYAxisData(defaultChartSetting.yAxis);
  // setIsTableLoading(false);

  let DTCols: any[] = [];
  (tableSchema?.data ?? []).forEach((col: { [key: string]: any }) => {
    if ((tableSchema?.defaultTable ?? {})?.datetimeCols?.includes(`${col.tableName}.${col.name}`)) {
      DTCols = [...DTCols, { ...col, uniqueColumnName: `${col.tableName}.${col.name}` }];
    }
  });

  setDTCols(DTCols);
};
export const populateFunction = (props: props) => {
  const { stateProps, chartSchema, populateState, setPopulateState } = props;
  if (!populateState) return;

  const CheckUpdateAndUpdateFunction = (setFunction: any, data: any, defaultData: any) => {
    if (data) {
      setFunction(data);
    } else {
      setFunction(defaultData);
    }
  };

  const {
    setPopulateCount,
    appID,
    setDTCols,
    setTableSchema,
    setFilterdTableSchema,
    setIsJoinedTable,
    setDefaultTable,
    setIsTableSelected,

    setAllTableData,

    setChatName,
    setChartDescription,
    setchartType,
    setXAxisData,
    setYAxisData,
    setChartToolTip,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setChartFilter,
    setChartWidth,
    setIsInsight,
    setIsForecast,
    setIsCrossReport,
    setIskeepAllFilters,
    setIsZoomBrush,
    setIsDateHierarchy,
    setPlotAreaBackground,
    setGridLines,
    setColumnDropNumber,
    defaultDateTimeCol,
    setTimeFrame,

    selectedTargetID,
    //Special Chart
    setKpiTargetPoints, //KPIMETER
    setSelectedDepartment,
    setKpiProjectedTargetPoints,
    setKpiTimePeriodRange,
    setIsProjectedTargetPointCreated,
    setSheetID,
    setSelectedTable,
  } = stateProps;

  const updateArray = [
    //  {
    //    setFunction: setAllTableData,
    //    data: allTableData,
    //    defaultData: [],
    //  },
    //  {
    //    setFunction: setFilterdTableSchema,
    //    data: allTableData,
    //    defaultData: [],
    //  },
    // set
    {
      setFunction: setChatName,
      data: chartSchema.name,
      defaultData: defaultChartSetting.name,
    },
    {
      setFunction: setChartDescription,
      data: chartSchema.desc,
      defaultData: defaultChartSetting.desc,
    },
    // {
    //   setFunction: setchartType,
    //   data: chartType(chartSchema.chartType),
    //   defaultData: defaultChartSetting.chartType,
    // },
    {
      setFunction: setXAxisData,
      data: chartSchema.xAxis,
      defaultData: defaultChartSetting.xAxis,
    },
    {
      setFunction: setYAxisData,
      data: chartSchema.yAxis,
      defaultData: defaultChartSetting.yAxis,
    },
    {
      setFunction: setChartToolTip,
      data: chartSchema.tooltip,
      defaultData: defaultChartSetting.tooltip,
    },
    {
      setFunction: setChartXAxisStyle,
      data: chartSchema.xAxisStyle,
      defaultData: getDefaultAxisStyleWithTitle({
        defaultStyle: defaultChartSetting.xAxisStyle,
        axis: chartSchema.xAxis,
      }),
    },
    {
      setFunction: setChartYAxisStyle,
      data: chartSchema.yAxisStyle,
      defaultData: getDefaultAxisStyleWithTitle({
        defaultStyle: defaultChartSetting.yAxisStyle,
        axis: chartSchema.yAxis,
      }),
    },
    {
      setFunction: setChartFilter,
      data: chartSchema.filters,
      defaultData: defaultChartSetting.filters,
    },
    {
      setFunction: setChartWidth,
      data: chartWidth(chartSchema.chartWidth),
      defaultData: defaultChartSetting.chartWidth,
    },
    {
      setFunction: setIsInsight,
      data: chartSchema.isInsight,
      defaultData: defaultChartSetting.isInsight,
    },
    {
      setFunction: setIsForecast,
      data: chartSchema.isForecast,
      defaultData: defaultChartSetting.isForecast,
    },
    {
      setFunction: setSheetID,
      data: chartSchema.sheetID,
      defaultData: defaultChartSetting.sheetID,
    },
    {
      setFunction: setSelectedTable,
      data: chartSchema.tableID,
      defaultData: defaultChartSetting.tableID,
    },
    // {
    //   setFunction: setIsCrossReport,
    //   data: chartSchema.isCrossReport,
    //   defaultData: defaultChartSetting.isCrossReport,
    // },
    // {
    //   setFunction: setIskeepAllFilters,
    //   data: chartSchema.iskeepAllFilters,
    //   defaultData: defaultChartSetting.iskeepAllFilters,
    // },
    // {
    //   setFunction: setIsZoomBrush,
    //   data: chartSchema.isZoomBrush,
    //   defaultData: defaultChartSetting.isZoomBrush,
    // },
    // {
    //   setFunction: setIsDateHierarchy,
    //   data: chartSchema.isDateHierarchy,
    //   defaultData: defaultChartSetting.isDateHierarchy,
    // },
    // {
    //   setFunction: setPlotAreaBackground,
    //   data: chartSchema.plotAreaBackground,
    //   defaultData: defaultChartSetting.plotAreaBackgroundSolid,
    // },
    // {
    //   setFunction: setGridLines,
    //   data: chartSchema.gridLines,
    //   defaultData: defaultChartSetting.gridLines,
    // },
    // {
    //   setFunction: setColumnDropNumber,
    //   data: chartSchema?.chartBuilderHelper?.columnDropNumber,
    //   defaultData: getColumnDropNumber({
    //     xAxis: chartSchema?.xAxis ?? defaultChartSetting.xAxis,
    //     yAxis: chartSchema?.yAxis ?? defaultChartSetting.yAxis,
    //     group: chartSchema?.group ?? defaultChartSetting.geoGroup,
    //   }),
    // },
    {
      setFunction: () => {},
      data: handleTable({
        appID: appID,
        selectedTable: chartSchema.tableID ?? defaultChartSetting.tableID,
        sheetID: chartSchema.sheetID ?? defaultChartSetting.sheetID,
        setDTCols: setDTCols,
        setTableSchema: setTableSchema,
        setFilterdTableSchema: setFilterdTableSchema,
        setIsJoinedTable: setIsJoinedTable,
        setDefaultTable: setDefaultTable,
        setIsTableSelected: setIsTableSelected,
      }),
      defaultData: {},
    },
    {
      setFunction: () => {},
      data: kpiProjectedTargetPoints({
        appID: chartSchema.appID,
        chartID: chartSchema.chartID,
        setKpiProjectedTargetPoints,
        setKpiTimePeriodRange,
        setIsProjectedTargetPointCreated,
      }),
      defaultData: {},
    },
    //Special Chart

    //KPIMETER
    {
      setFunction: setKpiTargetPoints,
      data: selectedTargetID
        ? {
            ...chartSchema.targetPoints,
            selectedTargetPointID: selectedTargetID,
          }
        : chartSchema.targetPoints,
      defaultData: defaultChartSetting.targetPoints,
    },
    {
      setFunction: setSelectedDepartment,
      data: chartSchema.departmentID,
      defaultData: defaultChartSetting.departmentID,
    },
  ];

  // populateState
  updateArray.forEach(({ setFunction, data, defaultData }) => {
    CheckUpdateAndUpdateFunction(setFunction, data, defaultData);
  });
  setPopulateCount((currentCount: number) => currentCount + 1);
  setPopulateState((preElem: boolean) => !preElem);
};
