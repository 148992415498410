import { mls } from 'lib/multilanguagesupport';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import React from 'react';
import ValueNumber from '../../ValueNumber';
import { GroupBubbleTypeDensityColumn } from './TypeColumn/GroupBubbleTypeColumn';
import { ScatterTypeDensityColumn } from './TypeColumn/ScatterTypeColumn';
import { GroupScatterTypeDensityColumn } from './TypeColumn/GroupScatterTypeColumn';
import { BubbleTypeDensityColumn } from './TypeColumn/BubbleTypeColumn';

interface densityColumnProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
  mainData: any;
}
const DensityColumn = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
  mainData,
}: densityColumnProps) => {
  const densityColumnObject = {
    bottom_5_least_dense: {
      label: 'Bottom 5 least dense',
      key: 'bottom_5_least_dense',
      minMax: false,
    },
    bottom_5_most_dense: {
      label: 'Bottom 5 most dense',
      key: 'bottom_5_most_dense',
      minMax: false,
    },
    least_dense_stats: { label: 'Least dense stats', key: 'least_dense_stats', minMax: true },
    most_dense_stats: { label: 'Most dense stats', key: 'most_dense_stats', minMax: true },
    top_5_least_dense: { label: 'Top 5 least dense', key: 'top_5_least_dense', minMax: false },
    top_5_most_dense: { label: 'Top 5 most dense', key: 'top_5_most_dense', minMax: false },
    least_dense_group: { label: 'Least dense group', key: 'least_dense_group', minMax: false },
    most_dense_group: { label: 'Most dense group', key: 'most_dense_group', minMax: false },
  };
  const denseStatsArray = [
    { label: 'Max', key: 'max' },
    { label: 'Mean', key: 'mean' },
    { label: 'Min', key: 'min' },
  ];
  const columnName = {
    ...(mainData?.xcolumnName ?? {}),
    ...(mainData?.ycolumnName ?? {}),
    ...(mainData?.zcolumnName ?? {}),
  };
  if (mainData.chartType === allChartKeys.BUBBLEPLOT) {
    const densityArray = [
      densityColumnObject.bottom_5_least_dense,
      densityColumnObject.bottom_5_most_dense,
      densityColumnObject.least_dense_stats,
      densityColumnObject.most_dense_stats,
      densityColumnObject.top_5_least_dense,
      densityColumnObject.top_5_most_dense,
    ];
    const finalElem = elem?.density ?? {};
    return (
      <BubbleTypeDensityColumn
        densityArray={densityArray}
        finalElem={finalElem}
        columnName={columnName}
        denseStatsArray={denseStatsArray}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.GROUPBUBBLEPLOT) {
    const densityArray = [
      densityColumnObject.bottom_5_least_dense,
      densityColumnObject.bottom_5_most_dense,
      densityColumnObject.least_dense_stats,
      densityColumnObject.most_dense_stats,
      densityColumnObject.top_5_least_dense,
      densityColumnObject.top_5_most_dense,
    ];
    const finalElem = elem?.density ?? {};
    return (
      <GroupBubbleTypeDensityColumn
        densityArray={densityArray}
        finalElem={finalElem}
        columnName={columnName}
        denseStatsArray={denseStatsArray}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.SCATTERPLOT) {
    const densityArray = [
      densityColumnObject.least_dense_stats,
      densityColumnObject.most_dense_stats,
    ];
    const denseStatsArray = [
      { label: 'Max', key: 'max' },
      { label: 'Mean', key: 'mean' },
      { label: 'Min', key: 'min' },
    ];
    const finalElem = elem?.density ?? {};
    if (!elem?.density) {
      return <></>;
    }
    return (
      <ScatterTypeDensityColumn
        densityArray={densityArray}
        finalElem={finalElem}
        columnName={columnName}
        columnName1={columnName1}
        denseStatsArray={denseStatsArray}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.GROUPSCATTERPLOT) {
    const densityArray = [
      densityColumnObject.least_dense_group,
      densityColumnObject.most_dense_group,
      densityColumnObject.least_dense_stats,
      densityColumnObject.most_dense_stats,
    ];
    const finalElem = elem?.density_insight ?? {};
    return (
      <GroupScatterTypeDensityColumn
        densityArray={densityArray}
        finalElem={finalElem}
        columnName={columnName}
        columnName1={columnName1}
        denseStatsArray={denseStatsArray}
        arrow={arrow}
      />
    );
  }
  return <></>;
};

export default DensityColumn;
