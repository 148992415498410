export const getPeriodRange = ({ currentYear }: { currentYear: number }) => {
  let endPeriodRangeObject = {};
  let startPeriodRangeObject = {};
  const from2020 = currentYear - 2020;

  //startPeriodRangeObject
  for (let index = 0; index <= from2020; index++) {
    const element = currentYear - index;
    startPeriodRangeObject = {
      ...startPeriodRangeObject,
      [element]: {
        key: element,
        lable: element,
      },
    };
  }

  //endPeriodRangeObject
  for (let index = 0; index < 10; index++) {
    const element = currentYear + 1 + index;
    endPeriodRangeObject = {
      ...endPeriodRangeObject,
      [element]: {
        key: element,
        lable: element,
      },
    };
  }
  return { startPeriodRangeObject, endPeriodRangeObject };
};
export const getTimeFrameYearLoop = (startYear: number, endYear: number) => {
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }
  return yearsArray;
};
export const checkForColumnLableDuplicacy = ({
  kpiCategorys,
  label,
}: {
  kpiCategorys: { [key: string]: any };
  label: string;
}) => {
  const isKeyPresent = kpiCategorys.some(
    (kpiCategoryObject: { [key: string]: any }) => kpiCategoryObject?.label === label
  );
  return isKeyPresent;
};
