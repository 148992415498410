import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';

import { AxisLeft, AxisBottom } from '@visx/axis';
import { Text } from '@visx/text';
import { GridColumns, GridRows } from '@visx/grid';

import { Brush } from '@visx/brush';
import { format } from 'd3-format';

import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';

import styles from '../../constants/styles.module.css';

import {
  defaultAxisColor,
  defaultAxisLabelFontSize,
  defaultBrushWidth,
  // defaultColors,
} from '../../constants/constants';
import { defaultDateTickFormat, categoryDateTickFormat } from '../../helpers/tickFormatHelpers';
// import ColorPicker from '../ColorPicker';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { KTSVG } from '../../_metronic/helpers';
// import DateRangeHelper from '../../helpers/components/DateRangeHelper';
import { widthHelperBarH } from '../BarType/helpers/dimensionHelper';
import BarHorizontalChart from '../BarType/BarHorizontalChart';
import scaleHelper from '../BarType/helpers/scaleHelper';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { sortValues } from '../BarType/helpers/sortValues';
import { mls } from 'lib/multilanguagesupport';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'rgba(53,71,125,0.8)',
  color: 'white',
  // width: 152,
  // height: 72,
  padding: 12,
};

function BarHorizontalType({
  data,
  optionData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  gradientColor,
}) {
  const { x, y } = data;
  const chartSettingsButton = useChartSettingsButton();
  const brushRef = useRef(null);

  const height = 600;
  const { width } = useDivDimensions(cardRef);
  const noOfBars = optionData?.noOfBars || 0;
  const actualHeight = widthHelperBarH(noOfBars, type, height, margin);
  // Check if chart requires brush
  const noBrush = actualHeight === height;
  // Adding height of brush to effective Height
  const effWidth = width - (!noBrush ? defaultBrushWidth : 0);

  // Calculating the number of data items to be plot in starting
  const dataNum = 15;
  const initialStartPos = noBrush ? 0 : x.length - dataNum;
  const initialEndPos = noBrush ? dataNum : x.length - 1;
  // Filtered data for initial rendering
  const [filteredX, setFilteredX] = useState(x.slice(initialStartPos, initialEndPos + 1));
  const [filteredY, setFilteredY] = useState(y.slice(initialStartPos, initialEndPos + 1));

  // Is the chart funnel chart
  const isFunnel = type === 'FUNNEL';

  // Extra margin for Horizontal Charts
  const extraLeftMargin = 90;

  // Maximum width and height available for x and y axis
  const xMax = width - (margin.left + extraLeftMargin) - margin.right;
  const yMax = height - margin.top - (isFunnel ? 0 : margin.bottom);

  const top = margin.top;
  const left = margin.left + extraLeftMargin;

  // Sorting values for use in Funnel Chart
  const { sortedX, sortedY } = sortValues(x, y);

  // Scales for Chart
  const xScale = useMemo(
    () => scaleHelper(isFunnel ? sortedX : x, xCategory, type, xMax, yMax, true, true),
    [isFunnel, sortedX, x, xCategory, type, xMax, yMax]
  );
  const yScale = useMemo(
    () => scaleHelper(isFunnel ? sortedY : filteredY, yCategory, type, xMax, yMax, false, true),
    [isFunnel, sortedY, filteredY, yCategory, type, xMax, yMax]
  );
  // Scale for brush
  const brushXScale = useMemo(
    () => scaleHelper(x, xCategory, type, defaultBrushWidth, yMax, true, false),
    [x, xCategory, type, yMax]
  );
  const brushYScale = useMemo(
    () => scaleHelper(y, yCategory, type, defaultBrushWidth, yMax, false, false),
    [y, yCategory, type, yMax]
  );

  // Event handle for change of brush
  const onBrushChange = (domain) => {
    if (!domain) return;

    const { y0, y1, yValues } = domain;
    const xCopy = [];
    const yCopy = y.filter((e, i) => {
      if (e > y0 && e < y1) {
        xCopy.push(x[i]);
        return true;
      }
      return false;
    });
    if (yCategory !== 'CAT') {
      setFilteredX(xCopy);
      setFilteredY(yCopy);
    } else {
      // filtering undefined values from xValues
      const filxValue = yValues.filter((e) => e !== undefined);

      const x0 = y.indexOf(filxValue[0]);
      const lastX = filxValue[filxValue.length - 1];
      const x1 = y.indexOf(lastX);
      setFilteredX(x.slice(x0, x1 + 1));
      setFilteredY(filxValue);
    }
  };
  // Initial Brush Position
  const initialBrushPosition = useMemo(
    () => ({
      start: { y: brushYScale(y[initialStartPos]) },
      end: { y: brushYScale(y[initialEndPos]) },
    }),
    [brushYScale, initialEndPos, initialStartPos, y]
  );

  // Creating tooltip
  // Creating a tooltip for the chart
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Function to handle mouse over change
  const handleMouseOver = useCallback(
    (event, datum) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
      // const coords = localPoint(event.target.ownerSVGElement, event);
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: datum,
      });
    },
    [containerBounds.left, containerBounds.top, showTooltip]
  );
  useEffect(() => {
    setFilteredX(x.slice(initialStartPos, initialEndPos + 1));
    setFilteredY(y.slice(initialStartPos, initialEndPos + 1));
  }, [initialEndPos, initialStartPos, x, y]);
  // Wrapper component for left axis using AxisLabelComponent
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        isHorizontal={true}
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
      />
    );
  };
  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
      />
    );
  };

  return (
    <>
      <div>
        <div className='card-header border-0 p-0 pb-3'>
          <div
            className={`${styles.titleDiv} w-100 d-flex justify-content-between align-items-center`}
          >
            {/* Append Title of the chart */}
            <h2 className='mb-0'>{mls(title)}</h2>
            <div>
              {/* {xCategory !== 'DATETIME' ? (
                <></>
              ) : (
                <DateRangeHelper chartId={chartID} defaultValue='DAY' cardWidth={cardWidth} />
              )} */}
              {/* <ColorPicker color={color} updateColor={updateColor} /> */}
              {isProd && (
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    onClick={() => chartSettingsButton(chartID)}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  {/* end::Menu */}
                </div>
              )}
            </div>
          </div>
        </div>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            <svg ref={containerRef} height={height + 16} width={effWidth}>
              {/* Append Label for x Axis */}
              {!isFunnel && (
                <Text
                  x={width / 2}
                  y={margin.top + yMax + margin.bottom - defaultAxisLabelFontSize}
                  className={styles.chartLabel}
                  verticalAnchor='middle'
                  textAnchor='middle'
                  fontSize={defaultAxisLabelFontSize}
                >
                  {mls(xAxisLabel)}
                </Text>
              )}
              {/* Append Label for y Axis */}
              <Text
                angle={-90}
                x={margin.left - 20}
                y={height / 2}
                className={styles.chartLabel}
                textAnchor='middle'
                verticalAnchor='middle'
                fontSize={defaultAxisLabelFontSize}
              >
                {mls(yAxisLabel)}
              </Text>
              {/* If yTicks is true append Grid Rows */}
              {yGrid && !isFunnel && (
                <GridRows
                  scale={yScale}
                  width={xMax}
                  top={margin.top}
                  left={margin.left + extraLeftMargin}
                />
              )}
              {/* If xTicks is true append Grid Columns */}
              {xGrid && !isFunnel && (
                <GridColumns
                  scale={xScale}
                  height={yMax}
                  top={margin.top}
                  left={margin.left + extraLeftMargin}
                />
              )}
              {/* Append chart */}
              <BarHorizontalChart
                xScale={xScale}
                yScale={yScale}
                xMax={xMax}
                yMax={yMax}
                x={filteredX}
                y={filteredY}
                colorType={colorType}
                gradientColor={gradientColor}
                chartColor={chartColor}
                onMouseOver={handleMouseOver}
                onMouseOut={hideTooltip}
                top={top}
                left={left}
                handleClick={handleClick}
              />
              {/* Append Axis Bottom */}
              {!isFunnel && (
                <AxisBottom
                  top={yMax + margin.top}
                  left={margin.left + extraLeftMargin}
                  scale={xScale}
                  stroke={defaultAxisColor}
                  // numTicks={1}
                  tickStroke={defaultAxisColor}
                  tickClassName={styles.bottomAxisTicks}
                  tickFormat={
                    xCategory === 'DATETIME'
                      ? defaultDateTickFormat
                      : xCategory === 'CAT'
                      ? categoryDateTickFormat
                      : null
                  }
                  tickComponent={AxisBottomLabelWrapper}
                />
              )}
              {/* Append Axis Left */}
              <AxisLeft
                top={margin.top}
                left={margin.left + extraLeftMargin}
                scale={yScale}
                stroke={defaultAxisColor}
                tickStroke={defaultAxisColor}
                tickFormat={yCategory === 'NUM' ? format('~s') : null}
                tickComponent={AxisleftLabelWrapper}
                tickClassName={`${styles.leftAxisTicks} ${styles.leftAxisHorizontal}`}
              />
              {/* Append Brush */}
              {!noBrush && (
                <BarHorizontalChart
                  x={x}
                  y={y}
                  height={height}
                  width={defaultBrushWidth}
                  xScale={brushXScale}
                  yScale={brushYScale}
                  yMax={yMax}
                  xMax={defaultBrushWidth}
                  colorType={colorType}
                  gradientColor={gradientColor}
                  chartColor={'pink'}
                  onMouseOver={() => {}}
                  onMouseOut={() => {}}
                  handleClick={() => {}}
                  top={margin.top}
                  left={10}
                >
                  <Brush
                    xScale={brushXScale}
                    yScale={brushYScale}
                    width={defaultBrushWidth}
                    height={yMax}
                    margin={{
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    resizeTriggerAreas={['top', 'bottom']}
                    innerRef={brushRef}
                    brushDirection='vertical'
                    initialBrushPosition={initialBrushPosition}
                    onChange={onBrushChange}
                    useWindowMoveEvents
                  />
                </BarHorizontalChart>
              )}
            </svg>
            {tooltipOpen && (
              <>
                <div
                  className='barHcrosshair horizontal'
                  style={{ transform: `translateY(${tooltipTop}px)` }}
                />

                <TooltipInPortal
                  key={Math.random()}
                  top={tooltipTop}
                  left={tooltipLeft}
                  style={tooltipStyles}
                >
                  {tooltipData.axis ? (
                    <>
                      <div>{tooltipData.x}</div>
                    </>
                  ) : (
                    <>
                      <div>
                        {mls(yAxisLabel)}:{'\t'}
                        <strong>{tooltipData.y}</strong>
                      </div>

                      <div>
                        {mls(xAxisLabel)}:{'\t'}
                        <strong>{tooltipData.x}</strong>
                      </div>
                    </>
                  )}
                </TooltipInPortal>
              </>
            )}
            <style>
              {`
                .barHcrosshair {
                  position: absolute;
                  top: 87px;
                  left: 190px;
                }
                .barHcrosshair.horizontal {
                  width: ${xMax}px;
                  height: 1px;
                  border-top: 2px dashed #35477d;
                }
              `}
            </style>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </div>
    </>
  );
}
export default BarHorizontalType;
