import { useEffect, useMemo, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { Card } from './components/Card';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Sorting, { allSortingObject } from '../../dependencies/Sorting';
import EditTable from './components/editTableList/EditTable';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DataTable = () => {
  const { appID } = useParams();

  const appDatatable = useSelector((state) => state.config_store.analyticsSchema.appDatatable);
  const appCredentials = useSelector((state) => state.config_store.analyticsSchema.appCreds);
  const [dataTableData, setDataTableData] = useState(appDatatable || {});
  const location = useLocation();

  const [dataTableSchema, setDataTableSchema] = useState(
    location?.state?.tableID ? appDatatable[location.state.tableID] : null
  );
  const [dataIndex, setDataIndex] = useState(0);
  const [openEditTable, setOpenEditTable] = useState(false);

  const appDataWithCN = useMemo(
    () => getAppDataTableWithConnectionName({ appDatatable, appCredentials }),
    [appDatatable, appCredentials]
  );
  const [searchKeyWord, setSearchKeyWord] = useState('');
  useEffect(() => {
    dataTableSchema && handleShow();
    // Clears the location.state on reload
    window.history.replaceState({}, document.title);
  }, [dataTableSchema]);

  const handleShow = () => {
    document.body.style.overflow = 'hidden';
    setOpenEditTable(true);
  };
  const handleClose = () => {
    document.body.style.overflow = 'visible';
    setOpenEditTable(false);
  };
  const updatedSortingObject = {
    [allSortingObject.updatedAt.key]: allSortingObject.updatedAt,
    [allSortingObject.alphabetically.key]: allSortingObject.alphabetically,
    [allSortingObject.connectionName.key]: allSortingObject.connectionName,
    [allSortingObject.createdAt.key]: allSortingObject.createdAt,
  };
  return (
    <div className='DataConnectors dataTable introjs-datatablePage'>
      <ToastContainer position={'bottom-left'} theme={'colored'} />
      {openEditTable ? (
        <PopUpDivChild
          className={'editTablePopup'}
          initialValue={openEditTable}
          setPopUp={handleClose}
          popupTitle={''}
          header={false}
        >
          <EditTable
            open={openEditTable}
            handleClose={handleClose}
            dataIndex={dataIndex}
            dataTableData={Object.values(dataTableData || {})}
            setDataTableData={setDataTableData}
            appID={appID}
            appDatatable={appDatatable}
          />
        </PopUpDivChild>
      ) : null}

      <div className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white rounded'>
        <Sorting
          setObjectOfObjects={setDataTableData}
          dataInRedux={appDataWithCN}
          sortingObject={updatedSortingObject}
          searchBy={'tableLable'}
          secondarySearchBy={'name'}
          keyProperty={'tableID'}
          updateSearchKey={setSearchKeyWord}
        />
      </div>
      <div className='row'>
        {!_.isEmpty(Object.values(appDatatable || {})) ? (
          _.isEmpty(Object.values(dataTableData || {})) ? (
            <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
              {mls(`No Data Tables Found for this "${searchKeyWord}" keyword.`)}
            </div>
          ) : (
            Object.values(dataTableData || {}).map((data, index) => {
              return (
                <div className='col-md-4 col-sm-6 mb-6' key={index}>
                  <Card
                    handleShow={handleShow}
                    index={index}
                    title={data.tableLable ?? data.name}
                    cardData={data}
                    setDataIndex={setDataIndex}
                    setDataTableSchema={setDataTableSchema}
                    numOfColumns={
                      data.columnSchema?.length + (data?.customColumnSchema?.length ?? 0)
                    }
                    description={data.description}
                    connectionName={appCredentials?.[data.credentialID]?.connectionName ?? ''}
                    connectorType={appCredentials?.[data.credentialID]?.connectorType ?? ''}
                    isSqlAdded={data.sqlQuery ? true : false}
                  />
                </div>
              );
            })
          )
        ) : (
          <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
            {mls(
              'No data tables imported. Please import them in the Connections Section to start analysing them.'
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTable;

const getAppDataTableWithConnectionName = ({ appDatatable = {}, appCredentials }) => {
  let newAppDataTable = {};
  Object.keys(appDatatable).forEach((appDataTableElemKey) => {
    const updatedAppDataTable = {
      ...appDatatable[appDataTableElemKey],
      connectionName:
        appCredentials?.[appDatatable[appDataTableElemKey]?.credentialID ?? '']?.connectionName ??
        '',
    };
    newAppDataTable = { ...newAppDataTable, [appDataTableElemKey]: updatedAppDataTable };
  });
  return newAppDataTable;
};
