import React, { useMemo } from 'react';
import './tableChart.scss';
import { compareBrightness, getStyling } from '../../helpers/colorHelper';
import {
  dateTimeFormatter,
  numberFormatter,
} from 'lib/reusable-components/reusableFunction/formatter';
import { mls } from 'lib/multilanguagesupport';

const defaultIndicatorTypes = { BAR: 'BAR', PIE: 'PIE', DONUT: 'DONUT', NONE: 'NONE' };
const indicatorTypeValue = defaultIndicatorTypes.BAR;
const defaultColors = {
  darker: '#00000017',
  lighter: '#00000017',
  // darker: '#000000ff',
  // lighter: '#00000017',
};
const PivotTable = (visxProps: any) => {
  const { toggle, chartHeight, theme, chartData, dataToPlot, plotOptions, isReport } = visxProps;
  const axisStyleData = useMemo(() => {
    const obj = theme?.tooltip?.style.reduce((accumulator: any, style: any) => {
      return { ...accumulator, [style.uniqueColumnName]: style };
    }, {});
    return obj;
  }, [theme?.tooltip?.style]);
  if (!dataToPlot[0]) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('No data to show with the current settings. Please use another settings!')}
      </div>
    );
  }
  const dataValue = dataToPlot[0]?.data?.[0]?.values || [];
  // return <ReactVertual />;
  const xAxisData = chartData?.xAxis ?? [];
  const yAxisData = chartData?.yAxis ?? [];

  const xAxisRepetitionLength = xAxisData?.length;

  const defaultColumnWidth = 'minmax(10rem, 1fr)';
  const gridTemplateColumns = `${defaultColumnWidth} `.repeat(
    xAxisRepetitionLength + yAxisData?.length
  );
  const minMax = plotOptions?.minMax;
  const textStyling = getStyling(chartData?.pivotTableTitleStyle);

  return (
    <>
      {/* {xCategory === 'DATETIME' && (
        <DateTimeFilter
          width={width ?? 0}
          toggle={toggle}
          handleToggle={handleToggle}
          isToggleClicked={isToggleClicked}
        />
      )} */}
      <div
        className='PivotTable'
        style={{
          width: '100%',
          maxHeight: isReport ? 'auto' : chartHeight,
          position: 'relative',
        }}
      >
        <div
          className='tableGrid'
          style={{
            gridTemplateColumns: gridTemplateColumns,

            width: '100%',
          }}
        >
          {React.Children.toArray(
            xAxisData.map((axisElem: any, index: number) => {
              return (
                <TableTitleCell
                  gridColumnStart={index}
                  gridColumnEnd={index}
                  gridRowStart={-1}
                  gridRowEnd={0}
                  value={axisStyleData[axisElem?.uniqueColumnName]?.label}
                  textStyling={textStyling}
                />
              );
            })
          )}
          {React.Children.toArray(
            yAxisData.map((axisElem: any, index: number) => {
              return (
                <TableTitleCell
                  gridColumnStart={index + xAxisRepetitionLength}
                  gridColumnEnd={index + xAxisRepetitionLength}
                  gridRowStart={-1}
                  gridRowEnd={0}
                  value={axisStyleData[axisElem?.uniqueColumnName]?.label}
                  textStyling={textStyling}
                />
              );
            })
          )}
          {/* <Grid
            cellRenderer={cellRenderer}
            columnCount={list[0].length}
            columnWidth={100}
            height={300}
            rowCount={list.length}
            rowHeight={40}
            width={500}
          /> */}
          <FirstAxisTable
            dataArray={dataValue}
            xAxisRepetitionLength={xAxisRepetitionLength}
            yAxisData={yAxisData}
            xAxisData={xAxisData}
            minMax={minMax}
            theme={theme}
            axisStyleData={axisStyleData}
            toggle={toggle}
          />
        </div>
      </div>
    </>
  );
};

export default PivotTable;

interface firstAxisTableProp {
  dataArray: any[];
  // recursionDepth: number;
  xAxisRepetitionLength: number;
  xAxisData: any[];
  yAxisData: any[];
  minMax: any;
  theme: any;
  axisStyleData: any;
  toggle: string;
}
const FirstAxisTable = ({
  dataArray,
  xAxisRepetitionLength,
  xAxisData,
  yAxisData,
  minMax,
  theme,
  axisStyleData,
  toggle,
}: firstAxisTableProp) => {
  const columnIndex = 0;
  if (xAxisRepetitionLength <= 1) {
    return (
      <LastXAxisData
        dataArray={dataArray}
        columnIndex={columnIndex}
        xAxisData={xAxisData}
        yAxisData={yAxisData}
        minMax={minMax}
        theme={theme}
        loopFromIndex={0}
        loopToIndex={dataArray.length}
        axisStyleData={axisStyleData}
        toggle={toggle}
      />
    );
  }
  return (
    <RecursionTable
      stopingIndex={xAxisRepetitionLength - 1}
      dataArray={dataArray}
      columnIndex={columnIndex}
      yAxisData={yAxisData}
      xAxisData={xAxisData}
      minMax={minMax}
      theme={theme}
      loopFromIndex={0}
      loopToIndex={dataArray.length}
      axisStyleData={axisStyleData}
      toggle={toggle}
    />
  );
};

interface recursionTableProp {
  // recursionDepth: number;
  // xAxisRepetition: number;
  dataArray: any[];
  columnIndex: number;
  stopingIndex: number;
  yAxisData: any[];
  xAxisData: any[];
  minMax: any;
  theme: any;
  loopFromIndex: number;
  loopToIndex: number;
  isRemoveline?: boolean;
  axisStyleData: any;
  toggle: string;
}
const RecursionTable = ({
  dataArray,

  stopingIndex,
  columnIndex,
  xAxisData,
  yAxisData,
  minMax,
  theme,
  loopFromIndex,
  loopToIndex,
  isRemoveline = false,
  axisStyleData,
  toggle,
}: recursionTableProp) => {
  let stopTill = 0;

  const lasXaxisArrayForLooping = [...Array(loopToIndex - loopFromIndex)];

  if (stopingIndex <= 1) {
    return (
      <>
        {React.Children.toArray(
          lasXaxisArrayForLooping.map((undifinedData, undifinedDataIndex) => {
            const index = undifinedDataIndex + loopFromIndex;
            const tableDataArray = dataArray[index];
            let found = true;
            let startingIndex = index;
            if (!(stopTill <= index)) {
              return <></>;
            }
            for (let loopIndex = startingIndex + 1; found; loopIndex++) {
              if (dataArray.length <= loopIndex) {
                stopTill = loopIndex;
                found = true;
                break;
              }
              if (loopToIndex <= loopIndex) {
                stopTill = loopIndex;
                found = true;
                break;
              }
              const element = dataArray[loopIndex];
              if (element[columnIndex] !== tableDataArray[columnIndex]) {
                found = false;
                stopTill = loopIndex;
              } else {
                found = true;
              }
            }
            const removeLine = isRemoveline || dataArray.length === stopTill;
            const isBottomLine = index === startingIndex;

            const styleData = axisStyleData?.[xAxisData[columnIndex]?.uniqueColumnName];
            const textStyling = getStyling({
              fontHeight: styleData.fontHeight,
              textStyle: styleData.textStyle,
            });

            const isDate = xAxisData[columnIndex]?.dataType === 'DATETIME';
            const operationsType = xAxisData[columnIndex]?.operations?.type;
            return (
              <>
                <TableCell
                  gridColumnStart={columnIndex}
                  gridColumnEnd={columnIndex}
                  gridRowStart={index}
                  gridRowEnd={stopTill}
                  value={tableDataArray[columnIndex]}
                  bottomLine={removeLine ? false : isBottomLine}
                  textStyling={textStyling}
                  styleData={styleData}
                  isDate={isDate}
                  operationsType={operationsType}
                />
                <LastXAxisData
                  dataArray={dataArray}
                  columnIndex={columnIndex + 1}
                  yAxisData={yAxisData}
                  minMax={minMax}
                  theme={theme}
                  loopFromIndex={startingIndex}
                  loopToIndex={stopTill}
                  isRemoveline={removeLine || false}
                  axisStyleData={axisStyleData}
                  xAxisData={xAxisData}
                  toggle={toggle}
                />
              </>
            );
          })
        )}
      </>
    );
  }
  return (
    <>
      {React.Children.toArray(
        lasXaxisArrayForLooping.map((undifinedData, undifinedDataIndex) => {
          const index = undifinedDataIndex + loopFromIndex;
          const tableDataArray = dataArray[index];
          let found = true;
          let startingIndex = index;
          if (!(stopTill <= index)) {
            return <></>;
          }
          for (let loopIndex = startingIndex + 1; found; loopIndex++) {
            if (dataArray.length <= loopIndex) {
              stopTill = loopIndex;
              found = true;
              break;
            }
            if (loopToIndex <= loopIndex) {
              stopTill = loopIndex;
              found = true;
              break;
            }
            const element = dataArray[loopIndex];
            if (element[columnIndex] !== tableDataArray[columnIndex]) {
              found = false;
              stopTill = loopIndex;
            } else {
              found = true;
            }
          }
          const isBottomLine = index === startingIndex;
          const removeLine = isRemoveline || dataArray.length === stopTill;

          const styleData = axisStyleData?.[xAxisData[columnIndex]?.uniqueColumnName];
          const textStyling = getStyling({
            fontHeight: styleData.fontHeight,
            textStyle: styleData.textStyle,
          });
          const isDate = xAxisData[columnIndex]?.dataType === 'DATETIME';
          const operationsType = xAxisData[columnIndex]?.operations?.type;
          return (
            <>
              <TableCell
                gridColumnStart={columnIndex}
                gridColumnEnd={columnIndex}
                gridRowStart={index}
                gridRowEnd={stopTill}
                value={tableDataArray[columnIndex]}
                bottomLine={removeLine ? false : isBottomLine}
                textStyling={textStyling}
                styleData={styleData}
                isDate={isDate}
                operationsType={operationsType}
              />
              <RecursionTable
                dataArray={dataArray}
                stopingIndex={stopingIndex - 1}
                columnIndex={columnIndex + 1}
                yAxisData={yAxisData}
                xAxisData={xAxisData}
                minMax={minMax}
                theme={theme}
                loopFromIndex={startingIndex}
                loopToIndex={stopTill}
                isRemoveline={false}
                axisStyleData={axisStyleData}
                toggle={toggle}
              />
            </>
          );
        })
      )}
    </>
  );
};

interface lastXAxisData {
  dataArray: any[];
  columnIndex: number;
  xAxisData: any[];
  yAxisData: any[];
  minMax: any;
  theme: any;
  loopFromIndex: number;
  loopToIndex: number;
  isRemoveline?: boolean;
  axisStyleData: any;
  toggle: string;
}
const LastXAxisData = ({
  dataArray,
  columnIndex,
  xAxisData,
  yAxisData,
  minMax,
  theme,
  loopFromIndex,
  loopToIndex,
  isRemoveline,
  axisStyleData,
  toggle,
}: lastXAxisData) => {
  const lastXaxisArrayForLooping = [...Array(loopToIndex - loopFromIndex)];
  return (
    <>
      {React.Children.toArray(
        lastXaxisArrayForLooping.map((undifinedData: any, index) => {
          const lastAxisIndex = index + loopFromIndex;
          const tableDataArray = dataArray[lastAxisIndex];
          const isBottomLine = lastAxisIndex === loopToIndex - 1;
          const removeLine = isRemoveline ?? true;
          const styleData = axisStyleData?.[xAxisData[columnIndex]?.uniqueColumnName];
          const isDate = xAxisData[columnIndex]?.dataType === 'DATETIME';
          const operationsType = xAxisData[columnIndex]?.operations?.type;
          const textStyling = getStyling({
            fontHeight: styleData.fontHeight,
            textStyle: styleData.textStyle,
          });

          return (
            <>
              <>
                <TableCell
                  gridColumnStart={columnIndex}
                  gridColumnEnd={columnIndex}
                  gridRowStart={lastAxisIndex}
                  gridRowEnd={lastAxisIndex}
                  value={tableDataArray[columnIndex]}
                  bottomLine={removeLine ? false : isBottomLine}
                  textStyling={textStyling}
                  styleData={styleData}
                  isDate={isDate}
                  operationsType={operationsType}
                />
                {React.Children.toArray(
                  yAxisData.map((yAxisObj: any, yAxisIndex: number) => {
                    const indexForCol = columnIndex + yAxisIndex + 1;
                    const AxisMinMax = minMax[yAxisObj.uniqueColumnName];
                    const styleData = axisStyleData?.[yAxisObj.uniqueColumnName];
                    const color = theme.colors[yAxisObj.uniqueColumnName];

                    const finaleIndicatorType = styleData.indicatorType ?? indicatorTypeValue;
                    const percentage = getPercentage({
                      min: AxisMinMax?.min ?? 0,
                      max: AxisMinMax?.max ?? 100,
                      value: tableDataArray[indexForCol],
                    });

                    const isPercentage =
                      yAxisObj?.operations?.type === 'PBG' || yAxisObj?.operations?.type === 'POGT';
                    return (
                      <TableValueCell
                        gridColumnStart={indexForCol}
                        gridColumnEnd={indexForCol}
                        gridRowStart={lastAxisIndex}
                        gridRowEnd={lastAxisIndex}
                        value={tableDataArray[indexForCol]}
                        percentage={percentage}
                        color={color}
                        isPercentage={isPercentage}
                        indicatorType={finaleIndicatorType}
                        bottomLine={removeLine ? false : isBottomLine}
                        styleData={styleData}
                      />
                    );
                  })
                )}
              </>
            </>
          );
        })
      )}
    </>
  );
};
interface TableTitleCellProps {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
  value: any;
  bottomLine?: boolean;
  textStyling: any;
  isDate?: boolean;
}
interface TableCellProps {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
  value: any;
  bottomLine?: boolean;
  textStyling: any;
  isDate?: boolean;
  operationsType: string;
  styleData: any;
}
// const dateFormatter = (date: string) => {
//   let numDate = new Date(date);

//   const yyyy = numDate.getFullYear();
//   let mm: string | number = numDate.getMonth() + 1; // Months start at 0!
//   let dd: string | number = numDate.getDate();

//   if (dd < 10) dd = '0' + dd;
//   if (mm < 10) mm = '0' + mm;

//   const formattedToday = dd + '/' + mm + '/' + yyyy;
//   return formattedToday;
// };
const TableCell = (TableCellProps: TableCellProps) => {
  const {
    gridColumnStart,
    gridColumnEnd,
    gridRowStart,
    gridRowEnd,
    value,
    bottomLine = false,
    textStyling,
    isDate = false,
    operationsType,
    styleData,
  } = TableCellProps;

  return (
    <div
      className='tableCell'
      style={{
        gridColumnStart: gridColumnStart + 1,
        gridColumnEnd: gridColumnEnd + 2,
        gridRowStart: gridRowStart + 2,
        gridRowEnd: gridRowEnd + 2,
        position: 'relative',
        borderBottom: bottomLine ? 'solid black 1px' : '',
        ...textStyling,
        fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
      }}
    >
      <div
        className='value'
        style={{
          position: 'sticky',
          top: '3rem',
        }}
      >
        {isDate
          ? dateTimeFormatter({
              type: { ...styleData?.formatter, format: operationsType },
              value: value,
            })
          : value}
      </div>
    </div>
  );
};
const TableTitleCell = (TableCellProps: TableTitleCellProps) => {
  const { gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd, value, textStyling } =
    TableCellProps;
  return (
    <div
      className='tableCell'
      style={{
        gridColumnStart: gridColumnStart + 1,
        gridColumnEnd: gridColumnEnd + 2,
        gridRowStart: gridRowStart + 2,
        gridRowEnd: gridRowEnd + 2,
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 1,
        ...textStyling,
        fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
      }}
      title={value}
    >
      {value}
    </div>
  );
};
interface TableValueCellProps {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number;
  value: any;
  percentage: number;
  color: string;
  isPercentage: boolean;
  indicatorType: string;
  bottomLine: boolean;
  styleData: any;
}

const TableValueCell = (TableValueCellProps: TableValueCellProps) => {
  const {
    gridColumnStart,
    gridColumnEnd,
    gridRowStart,
    gridRowEnd,
    value,
    percentage,
    color,
    isPercentage,
    indicatorType,
    bottomLine,
    styleData,
  } = TableValueCellProps;
  const textStyling = getStyling({
    fontHeight: styleData.fontHeight,
    textStyle: styleData.textStyle,
  });
  const formattedValue = numberFormatter({
    type: { ...styleData?.formatter },
    value: value,
  });

  return (
    <div
      className='tableCell'
      style={{
        gridColumnStart: gridColumnStart + 1,
        gridColumnEnd: gridColumnEnd + 2,
        gridRowStart: gridRowStart + 2,
        gridRowEnd: gridRowEnd + 2,
        borderBottom: bottomLine ? 'solid black 1px' : '',
      }}
    >
      {indicatorType === defaultIndicatorTypes.BAR ? (
        <BarType
          value={formattedValue}
          percentage={percentage}
          color={color}
          isPercentage={isPercentage}
          textStyling={textStyling}
        />
      ) : null}
      {indicatorType === defaultIndicatorTypes.PIE ? (
        <PieType
          value={formattedValue}
          percentage={percentage}
          color={color}
          isPercentage={isPercentage}
          textStyling={textStyling}
        />
      ) : null}
      {indicatorType === defaultIndicatorTypes.DONUT ? (
        <DonutType
          value={formattedValue}
          percentage={percentage}
          color={color}
          isPercentage={isPercentage}
          textStyling={textStyling}
        />
      ) : null}
      {indicatorType === defaultIndicatorTypes.NONE ? (
        <div
          className='valueDiv'
          style={{
            width: `100%`,
            ...textStyling,
            fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
          }}
        >
          {value}
          {isPercentage ? '%' : null}
        </div>
      ) : null}
    </div>
  );
};
interface BarTypeProps {
  value: string | number;
  percentage: number;
  color: string;
  isPercentage: boolean;
  textStyling: any;
}
const BarType = ({ value, percentage, color, isPercentage, textStyling }: BarTypeProps) => {
  const barWidth = percentage / 2;

  return (
    <div
      className='barTypeTable'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className='barDiv'
        style={{
          height: '.5rem',
          width: barWidth,
          borderRadius: '3px',
          backgroundColor: color,
          marginRight: '10px',
        }}
      ></div>

      <div
        className='valueDiv'
        style={{
          ...textStyling,
          fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
          width: `calc(100% - 2rem - ${barWidth}px)`,
        }}
      >
        {value}
        {isPercentage ? '%' : null}
      </div>
    </div>
  );
};
interface PieTypeProps {
  value: string | number;
  percentage: number;
  color: string;
  isPercentage: boolean;
  textStyling: any;
}
const PieType = ({ value, percentage, color, isPercentage, textStyling }: PieTypeProps) => {
  // const colors = createColorArray(5, color);
  const isDarker = compareBrightness(color);
  const secondColor = isDarker ? defaultColors.darker : defaultColors.lighter;
  // const secondColor = colors[4];
  return (
    <div
      className='pieTypeTable'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className='barDiv'
        style={{
          width: '1.5rem',
          height: '1.5rem',
          borderRadius: '50%',
          backgroundImage: `
          conic-gradient(${color} ${percentage}%, ${secondColor} 0%, ${secondColor})
          `,
          marginRight: '10px',
        }}
      ></div>

      <div
        className='valueDiv'
        style={{
          width: `calc(100% - 2rem - 1.5rem)`,
          ...textStyling,
          fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
        }}
      >
        {value}
        {isPercentage ? '%' : null}
      </div>
    </div>
  );
};
interface DonutTypeProps {
  value: string | number;
  percentage: number;
  color: string;
  isPercentage: boolean;
  textStyling: any;
}
const DonutType = ({ value, percentage, color, isPercentage, textStyling }: DonutTypeProps) => {
  // const colors = createColorArray(5, color);
  // const secondColor = colors[4];
  const isDarker = compareBrightness(color);
  const secondColor = isDarker ? defaultColors.darker : defaultColors.lighter;

  // Size of the enclosing square
  const sqSize = 20;
  const strokeWidth = 3;

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (20 - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  return (
    <div
      className='donutTypeTable'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: `calc(${sqSize}px )`,
          marginRight: '10px',
        }}
      >
        <svg width={sqSize} height={sqSize} viewBox={viewBox}>
          <circle
            className='circle-background'
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            style={{
              stroke: secondColor,
              fill: 'none',
            }}
          />
          <circle
            className='circle-progress'
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
              stroke: color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              fill: 'none',
            }}
          />
          {/* <text
            className='circle-text'
            x='50%'
            y='50%'
            dy='.3em'
            textAnchor='middle'
            style={{ fontSize: '3em', fontWeight: 'bold', fill: 'red' }}
          >
            {`${percentage}%`}
          </text> */}
        </svg>
      </div>
      <div
        className='valueDiv'
        style={{
          width: `calc(100% - ${sqSize}px - 1.5rem)`,

          ...textStyling,
          fontSize: `${(textStyling.fontSize ?? 12) * 1}px`,
        }}
      >
        {value}
        {isPercentage ? '%' : null}
      </div>
    </div>
  );
};

interface getPercentageProps {
  min: number;
  max: number;
  value: number;
}

const getPercentage = ({ min, max, value }: getPercentageProps) => {
  if (value === 0) {
    return 0;
  }
  if (min === max) {
    return 100;
  }
  const percentage = ((value - min) / (max - min)) * 90;
  const roundedPercentageValue = Math.round(percentage * 100) / 100;
  return roundedPercentageValue + 10;
};
