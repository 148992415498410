import { useState, useEffect } from 'react';

function getDivDimensions(cardRef) {
  const currentNode = cardRef.current;
  if (currentNode == null) return {};
  const { clientWidth: width, clientHeight: height } = currentNode;
  const computedStyle = getComputedStyle(currentNode);
  const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
  const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  return {
    width: width - paddingX,
    height: height - paddingY,
  };
}

export default function useDivDimensions(cardRef) {
  const [divDimensions, setDivDimensions] = useState(getDivDimensions(cardRef));

  useEffect(() => {
    const { current } = cardRef;
    function handleResize() {
      setDivDimensions(getDivDimensions(cardRef));
    }

    if (current != null) {
      const resizeObserver = new ResizeObserver((entries) => {
        const resizeObserveDebounceFunction = debounce(handleResize, 1);
        resizeObserveDebounceFunction();
      });
      if (resizeObserver) {
        if (resizeObserver?.observe) {
          resizeObserver?.observe(current);
        }
        if (resizeObserver?.unobserve) {
          return () => resizeObserver?.unobserve(current);
        }
      }
    }
  }, [cardRef.current]);

  // if (windowDimensions.width > 1200) {
  //   svgShape.width = 1200;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 1200 && windowDimensions.width > 900) {
  //   svgShape.width = 900;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 900 && windowDimensions.width > 600) {
  //   svgShape.width = 600;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 600) {
  //   svgShape.width = windowDimensions.width;
  //   svgShape.height = 300;
  // }
  // return divDimensions??{
  //   width: 0,
  //   height: 0,
  // };
  return divDimensions;
}

let timer;
const debounce = (func, timeout = 300) => {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
