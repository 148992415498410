import {defaultDashboardSchema} from "../data/defaultSchema"

interface props{
  stateProps:any,
  dashBoardSchema:any,
  populateState:boolean
  setPopulateState:any
}

export const populateFunction = (props:props) =>{
  const {stateProps,dashBoardSchema,populateState,setPopulateState,} = props
    if (!populateState) return

    const CheckUpdateAndUpdateFunction = (setFunction:any,data:any,defaultData:any) =>{
      if (data) {
        setFunction(data);
      }else{
        setFunction(defaultData);
      }
    }
    const {
        setPopulateCount,setDashboardName,setDashboardDescription ,setSections
      }= stateProps

      const updateArray = [
        {
          setFunction:setDashboardName,
          data:dashBoardSchema.name,
          defaultData:defaultDashboardSchema.dashboardName,
        },
        {
          setFunction:setDashboardDescription,
          data:dashBoardSchema.desc,
          defaultData:defaultDashboardSchema.dashboardDesc,
        },
        {
          setFunction:setSections,
          data:dashBoardSchema.sections,
          defaultData:defaultDashboardSchema.dashboardSections,
        },
      ]

      // populateState
      updateArray.forEach(({setFunction,data,defaultData})=>{
        CheckUpdateAndUpdateFunction(setFunction,data,defaultData );
      })
      setPopulateCount((currentCount:number) => currentCount+1)
      setPopulateState((preElem:boolean) =>!preElem)
}
