import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@visx/shape';
import { defaultColors, defaultMargin } from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { RadialGradient, LinearGradient } from '@visx/gradient';

// Component for building bars
const BarChart = ({
  xScale,
  yScale,
  x,
  y,
  yMax,
  onMouseOver,
  onMouseOut,
  handleClick,
  colorType,
  gradientColor,
  chartColor,
  height,
}) => {
  const displayChart = () =>
    x.map((elem, index) => {
      const barWidth = xScale.bandwidth();
      const barHeight = yMax - yScale(y[index]);
      const barX = xScale(elem);
      const barY = yMax - barHeight;
      const datum = {
        x: elem,
        y: y[index],
      };

      let color = defaultColors[0];
      if (colorType === 'Monochromatic') {
        color = chartColor;
      } else if (colorType === 'Gradient') {
        color = gradientColor.from;
      }
      const gradientID = `Gradient-${datum.x?.replace(/\s/g, '')}-${index}`;
      return (
        <React.Fragment key={`bar-${x[index]}`}>
          {colorType === 'Gradient' && gradientColor.type === 'linear' && (
            <LinearGradient
              id={gradientID}
              from={gradientColor.from}
              to={gradientColor.to}
              rotate={gradientColor.rotate || 0}
              // toOpacity={gradient.toOpacity || 1}
            />
          )}
          {colorType === 'Gradient' && gradientColor.type === 'radial' && (
            <RadialGradient
              id={gradientID}
              from={gradientColor.from}
              to={gradientColor.to}
              r={gradientColor.r || '100%'}
            />
          )}
          <Bar
            key={`tooltip-bar-${index}`}
            x={barX}
            rx={2}
            width={barWidth}
            height={Math.max(height - defaultMargin.top - defaultMargin.bottom, 150)}
            fill='transparent'
            onMouseMove={(e) => onMouseOver(e, datum)}
            // onMouseLeave={onMouseOut}
          />

          <Bar
            key={`bar-${index}`}
            x={barX}
            y={barY}
            rx={2}
            width={barWidth}
            height={barHeight}
            fill={colorType === 'Gradient' ? `url(#${gradientID})` : color}
            // onMouseMove={(e) => onMouseOver(e, datum)}
            // onMouseLeave={onMouseOut}
            onClick={() => {
              handleClick(datum.x);
            }}
            className={styles.cursorPointer}
          />
        </React.Fragment>
      );
    });

  return <>{displayChart()}</>;
};

BarChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  yMax: PropTypes.number.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
};

export default BarChart;
