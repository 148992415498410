import { mls } from 'lib/multilanguagesupport';
import arrowIcon from '../assets/arrowIcon.svg';
import { toggleColumnDetails } from '../data/defaultSchema';
// import SelectChart from '../../features/SelectChart/SelectChart';
// import DropDown from '../../features/DropDown/DropDown';
// import { defaultChartWidthTypes } from '../../data/defaultChartSchema';

const WithoutStyleToggle = ({ settingToggleState = '', toggleProps, children }) => {
  const { toggleMinimizeDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;

  return (
    <>
      <div className='staticDiv WithoutStyleToggle'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls(toggleKeyName)}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>
      </div>

      <div className={`scrollableDiv WithoutStyleToggle ${settingToggleState}`}>{children}</div>
    </>
  );
};

export default WithoutStyleToggle;
