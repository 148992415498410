import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from '@visx/shape';

import styles from '../../constants/styles.module.css';
import { RadialGradient, LinearGradient } from '@visx/gradient';
import { defaultColors } from '../../constants/constants';
import { Group } from '@visx/group';

// Component for building bars
const BarHorizontalChart = ({
  xScale,
  yScale,
  x,
  y,
  onMouseOver,
  onMouseOut,
  handleClick,
  colorType,
  gradientColor,
  chartColor,
  top,
  left,
  children,
}) => {
  const displayChart = () =>
    y.map((elem, index) => {
      const barWidth = yScale.bandwidth();
      const barHeight = xScale(x[index]);
      const barX = 0;
      const barY = yScale(elem);
      const datum = {
        x: x[index],
        y: elem,
      };

      let color = defaultColors[0];
      if (colorType === 'Monochromatic') {
        color = chartColor;
      } else if (colorType === 'Gradient') {
        color = gradientColor.from;
      }
      const gradientID = `Gradient-${datum.y.replace(/\s/g, '')}-${index}`;

      return (
        <React.Fragment key={`barH-${elem}-${index}`}>
          {colorType === 'Gradient' && gradientColor.type === 'linear' && (
            <LinearGradient
              id={gradientID}
              from={gradientColor.from}
              to={gradientColor.to}
              rotate={gradientColor.rotate || 0}
              // toOpacity={gradient.toOpacity || 1}
            />
          )}
          {colorType === 'Gradient' && gradientColor.type === 'radial' && (
            <RadialGradient
              id={gradientID}
              from={gradientColor.from}
              to={gradientColor.to}
              r={gradientColor.r || '100%'}
            />
          )}
          <Bar
            key={`bar-${index}`}
            x={barX}
            y={barY}
            width={barHeight}
            height={barWidth}
            fill={colorType === 'Gradient' ? `url(#${gradientID})` : color}
            onMouseMove={(e) => onMouseOver(e, datum)}
            // onMouseLeave={onMouseOut}
            onClick={() => {
              handleClick(datum.y);
            }}
            className={styles.cursorPointer}
          />
        </React.Fragment>
      );
    });

  return (
    <Group top={top} left={left}>
      {displayChart()}
      {children}
    </Group>
  );
};

BarHorizontalChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
};

export default BarHorizontalChart;
