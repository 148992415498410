interface columnToggleProps {
    minimizedDiv: string;
    toggleKeyName: string;
    setIsMainDivOn: Function;
    setIsToggle: Function;
}

export const columnToggleFunc = (props: columnToggleProps) => {
    const { minimizedDiv, toggleKeyName, setIsMainDivOn, setIsToggle } = props;
    if (minimizedDiv === toggleKeyName) {
      setIsMainDivOn(false);
      setTimeout(() => {
        setIsToggle(false);
      }, 300);
    } else {
      setIsMainDivOn(true);
      // setIsToggle(true)
      setTimeout(() => {
        setIsToggle(true);
      }, 300);
    }
  }
  