export const allDropableID = {
  chartId: 'chartId',
  sectionId: 'sectionId',
  dashboardSheetsId: 'dashboardSheetsId',
};
export const defaultSectionData = {
  title: 'Section',
};

export const defaultWidthTypes = {
  FULL: {
    key: 'FULL',
    name: 'Full',
    spaces: 4,
  },
  HALF: {
    key: 'HALF',
    name: 'Half',
    spaces: 2,
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    name: 'Two-Third',
    spaces: 3,
  },
  ONEFOURTH: {
    key: 'ONEFOURTH',
    name: 'One-Fourth',
    spaces: 1,
  },
};
export const defaultWidthTypesArray = Object.values(defaultWidthTypes);
export const toggleColumnDetails = {
  firstColumn: {
    toggleKeyName: 'SELECT CHARTS',
    toggleKeyId: 0,
  },
  secondColumn: {
    toggleKeyName: 'SECTIONS',
    toggleKeyId: 1,
  },
};
export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
};
const mainColors = {
  primaryBlue: '#00A3FF',
};
export const defaultLegendColorPalettes = [
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  '#FFA800',
  '#50CD89',
  '#00A3FF',
  '#8950FC',
  '#1BC5BD',
  '#FFE456',
  '#0A572D',
  '#394157',
  '#FCB6BD',
  '#5F5CF1',
  '#D0B9FF',
];

export const defaultSolidColorPalettes = [
  '#181823',
  '#3F4254',
  '#336D81',
  '#3C84AB',
  '#473C33',
  '#282A3A',
  '#443C68',
  '#635985',

  // '#00EBB8',
  // '#9CF68A',
  // '#F9F871',
  // '#fdb827',

  '#404258',
  '#474E68',
  '#50577A',
  '#6B728E',
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  // '#f58231',
  // '#911eb4',
  // '#46f0f0',
  // '#f032e6',
  // '#bcf60c',
  // '#fabebe',
  // '#008080',
  // '#e6beff',
  // '#fffac8',
  // '#800000',
  // '#aaffc3',
  // '#808000',
  // '#ffd8b1',
  // '#000075',
  // '#808080',
];
export const defaultOperation = {
  num: {
    sum: {
      name: 'Sum',
      key: 'sum',
    },
    average: {
      name: 'Average',
      key: 'mean',
    },
    minimum: {
      name: 'Minimum',
      key: 'min',
    },
    maximum: {
      name: 'Maximum',
      key: 'max',
    },
    standardDeviation: {
      name: 'Standard Deviation',
      key: 'std',
    },

    median: {
      name: 'Median',
      key: 'median',
    },
    mode: {
      name: 'Mode',
      key: 'mode',
    },
    var: {
      name: 'Variance',
      key: 'var',
    },
    uncalculated: {
      name: 'Value(Uncalculated)',
      key: 'UNCALCULATED',
    },
    percentOfGrandTotal: {
      name: 'Value(Percentage of grand total)',
      key: 'POGT',
    },
    PCT_BY_GROUP: {
      name: 'Percentage by group',
      key: 'PBG',
    },
  },
  cat: {
    count: {
      name: 'Count',
      key: 'count',
    },
    distinct: {
      name: 'Count(Distinct)',
      key: 'DISTINCT',
    },
  },
};
export const defaultFontDecoration = ['bold', 'italic', 'underlined'];
export const defaultFontFamily = ['Poppins', 'Times new roman'];
export const defaultFontSize = [
  {
    name: 'S',
    key: '10',
  },
  {
    name: 'M',
    key: '12',
  },
  {
    name: 'L',
    key: '14',
  },
  {
    name: 'XL',
    key: '16',
  },
];
export const defaultSelectedFontSize = defaultFontSize[1].key;
export const defaultNestedArrayDraggableID = {
  first: 'firstNestedDraggableArray',
  second: 'secondNestedDraggableArray',
  third: 'thirdNestedDraggableArray',
};
export const defaultDashboardSchema = {
  dashboardName: '',
  dashboardDesc: '',
  dashboardSections: [],
  sections: {
    title: 'Section',
    headings: [
      {
        text: '',
        color: '#ccc',
        font: 'Poppins',
        fontHeight: '12',
        opacity: '100',
        textStyle: [],
      },
    ],
    charts: [],
  },
};
