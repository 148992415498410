import { useEffect, useState } from 'react';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import DownloadData from './DownloadData';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import Select from 'react-select';

const timeframeList = [
  { value: 7, label: '1 Week' },
  { value: 30, label: '1 Month' },
  { value: 90, label: '3 Months' },
  { value: 180, label: '6 Months' },
  { value: 365, label: '1 Year' },
  { value: 1095, label: '3 Years' },
  { value: 'ALL', label: 'All Data' },
];

const createDefaultTimeframeObj = (tableSchema, defaultDatetimeCol) => {
  const defaultObj = tableSchema.filter(
    (obj) => obj.tableName + '.' + obj.columnName === defaultDatetimeCol
  );
  if (defaultObj.length === 0) return {};
  return {
    [defaultDatetimeCol]: {
      column: defaultObj[0]['columnName'],
      dataType: defaultObj[0]['dataType'],
      sheetID: defaultObj[0]['sheetID'],
      tableName: defaultObj[0]['tableName'],
    },
  };
};

const FilterStrip = ({
  appID,
  sheetID,
  tableSchema,
  filterSchema,
  setFilterSchema,
  isJoinedTable,
  sheetSchema,
  defaultDatetimeCol,
  timeframe,
  setTimeframe,
  timeFrameObj,
  setTimeFrameObj,
  payloadData,
  sheetName,
}) => {
  const [show, setShow] = useState(false);
  const [chartFilter, setChartFilter] = useState(filterSchema || {});
  const [download, setDownload] = useState(false);
  const filterLength = Object.keys(filterSchema || {}).length;
  const handleTimeframe = (e) => {
    let newTimeframeData = {};
    if (e.value !== 'CUSTOM' && defaultDatetimeCol !== '' && e.value !== 'ALL') {
      newTimeframeData = {
        ...timeFrameObj,
      };
      if (Object.keys(newTimeframeData).length === 0) {
        newTimeframeData = { ...createDefaultTimeframeObj(tableSchema, defaultDatetimeCol) };
      }
      newTimeframeData[defaultDatetimeCol] = {
        ...newTimeframeData[defaultDatetimeCol],
        value: {
          period: 'LASTXDAYS',
          xDay: Number(e.value),
        },
      };
    }
    // setFilterSchema({ ...filterSchema, [defaultDatetimeCol]: newTimeframeData });
    // setChartFilter({ ...chartFilter, [defaultDatetimeCol]: newTimeframeData });
    setTimeFrameObj(newTimeframeData);
    setTimeframe(e.value);
  };

  const handleClick = (event) => {
    if (timeframe === 'CUSTOM') {
      event.preventDefault();
      setShow(true);
    }
  };
  useEffect(() => {
    if (!show) {
      setFilterSchema(chartFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setChartFilter(filterSchema || {});
    const filtersHaveTimeframeCol = Object.keys(filterSchema || {}).includes(defaultDatetimeCol);
    if (filtersHaveTimeframeCol && timeframe !== 'CUSTOM') {
      setTimeFrameObj({});
      setTimeframe('CUSTOM');
    } else if (timeframe === 'CUSTOM' && !filtersHaveTimeframeCol) {
      const newTimeframeData = { ...createDefaultTimeframeObj(tableSchema, defaultDatetimeCol) };
      newTimeframeData[defaultDatetimeCol] = {
        ...newTimeframeData[defaultDatetimeCol],
        value: {
          period: 'LASTXDAYS',
          xDay: 180,
        },
      };
      setTimeFrameObj(newTimeframeData);
      setTimeframe(180);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetID, filterSchema]);

  const customStyles = {
    control: (base) => ({
      ...base,
      cursor: timeframe === 'CUSTOM' ? 'pointer' : 'default',
      backgroundColor: '#f5f8fa',
      borderColor: '#f5f8fa',
      boxShadow: 'none',
      border: 0,
      borderRadius: '6px',
      minWidth: '200px', // Set your desired minimum width here
    }),
    indicatorSeparator: (provided) => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      boxShadow: 'none',
      zIndex: 9999,
    }),
  };
  return (
    <>
      <div className='pb-4'>
        <div className='p-2 d-flex flex-column flex-column-fluid bg-white'>
          <div className='container mt-3'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6 mb-3 d-flex align-items-center'>
                {defaultDatetimeCol !== '' && (
                  <>
                    <h4 className='mb-0 me-4'>{mls('Timeframe')}:</h4>
                    <div onClick={handleClick}>
                      <Select
                        name='Set Timeframe'
                        // className='form-input form-input-solid'
                        onClick={handleClick}
                        // defaultValue={180}
                        // disabled={timeframe === 'CUSTOM'}

                        value={
                          timeframeList.find((opt) => opt.value === timeframe) || {
                            value: 'CUSTOM',
                            label: 'Custom Range',
                          }
                        }
                        onChange={handleTimeframe}
                        options={timeframeList}
                        styles={customStyles}
                        classNamePrefix='react-select'
                        isSearchable={false}
                        menuIsOpen={timeframe === 'CUSTOM' ? false : undefined}
                      >
                        {timeframe === 'CUSTOM' && (
                          <option value={'CUSTOM'}>{mls('Custom Range')}</option>
                        )}
                        {Object.keys(timeframeList).map((key) => (
                          <option key={key} value={key}>
                            {mls(timeframeList[key])}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-6 mb-3 d-flex align-items-center justify-content-end'>
                {/* <h4 className='mb-0 me-4' style={{ color: '#009ef7' }}>
                  Download Report
                </h4> */}
                <button
                  className='btn btn-sm fw-bold btn-primary me-4'
                  type='button'
                  onClick={() => setDownload(true)}
                >
                  {download ? (
                    <div className='d-flex align-items-center'>
                      {mls('Please Wait')}
                      <CircularProgress size='1rem' className='ms-2' sx={{ color: '#fff' }} />
                    </div>
                  ) : (
                    <>
                      {mls('Download Data')}
                      <i
                        className='bi bi-box-arrow-down ms-2 p-0'
                        style={{ fontSize: '1.4rem' }}
                      ></i>
                    </>
                  )}
                </button>

                {/* <h6 className='mb-0 me-2'>{`${filterLength} ${
                  filterLength > 1 ? 'filters' : 'filter'
                } applied`}</h6> */}

                <button
                  className='btn btn-sm fw-bold btn-primary'
                  type='button'
                  onClick={() => setShow(true)}
                >
                  {mls('Filters')}
                  <span
                    className='symbol-label fw-bolder text-success position-relative translate-middle'
                    style={{ top: '-16px', left: '28px' }}
                  >
                    {filterLength}
                  </span>
                </button>
              </div>
            </div>
          </div>

          {/* <div class='d-flex align-items-center gap-2 gap-lg-3'>
              <div
                data-kt-daterangepicker='true'
                data-kt-daterangepicker-opens='left'
                class='btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary d-flex align-items-center px-4'
                data-kt-initialized='1'
              >
                <div class='text-gray-600 fw-bold'>6 Jun 2023 - 5 Jul 2023</div>

                <i class='ki-duotone ki-calendar-8 fs-1 ms-2 me-0'>
                  <span class='path1'></span>
                  <span class='path2'></span>
                  <span class='path3'></span>
                  <span class='path4'></span>
                  <span class='path5'></span>
                  <span class='path6'></span>
                </i>
              </div>
              <button className='btn btn-sm fw-bold btn-primary'>More Filters</button>
            </div> */}
        </div>
      </div>
      {show && (
        <FilterDiv
          setChartFilter={setChartFilter}
          allTableData={tableSchema}
          chartFilter={chartFilter}
          appID={appID}
          isJoinedTable={isJoinedTable}
          sheetID={sheetID}
          isPopUp={show}
          setIsPopUp={setShow}
          isDashboard={false}
        />
      )}
      {download && (
        <DownloadData
          appID={appID}
          payloadData={payloadData}
          setModalOpen={setDownload}
          isModalOpen={download}
          reportName={sheetName}
          reportType='sheet'
        />
      )}
    </>
  );
};

export default FilterStrip;
