import React from 'react';
import './nonSupportedScreen.scss';
import { FiX } from 'react-icons/fi';
import { mls } from 'lib/multilanguagesupport';
interface nonSupportedScreen {
  closeButton: Function;
}
const NonSupportedScreen = ({ closeButton }: nonSupportedScreen) => {
  return (
    <div className='nonSupported'>
      <div className='header'>
        <div className='title'> {mls('Chart Settings')}</div>
        <div className='subTitle'>{mls('Edit Chart Settings here')}</div>

        <div className='goleftSide'></div>
        <div className='closeButton' onClick={() => closeButton()}>
          <FiX className='arrowIcon' />
        </div>
      </div>
      <div className='nonSupportedMessageSection'>
        {mls('Mobile and Tablet browsers are not supported')}
      </div>
    </div>
  );
};

export default NonSupportedScreen;
