import React, { useEffect } from 'react';
import { _fetchFile } from 'lib/server-connection/connections';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { mls } from 'lib/multilanguagesupport';

const DownloadData = ({
  appID,
  isModalOpen,
  setModalOpen,
  payloadData,
  reportName,
  reportType,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isModalOpen) {
      const newPayloadData = { ...payloadData, downloadType: reportType };
      _fetchFile(newPayloadData, `${appID}/downloadData`)
        .then((blob) => {
          if (blob.type === 'application/json') {
            dispatch(
              create({
                setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
                value: {
                  message: mls('There is some error pls try again!'),
                  time: 5,
                  isActive: true,
                },
              })
            );
            setModalOpen(false);
          } else {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `${reportName}.xlsx`; // Set the desired filename

            // Trigger the download directly
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setModalOpen(false);
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);
  return <></>;
};
export default DownloadData;
