import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import React, { useMemo } from 'react';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import { mls } from 'lib/multilanguagesupport';

const DragableToolTip = ({ stateProps, handleResetToolTip }) => {
  const { chartToolTip, barStackLineTargetPoints } = stateProps;
  return (
    <>
      <div className='grayContainers'>
        {React.Children.toArray(
          chartToolTip.style.map((elm, i) => (
            <ToolTipDiv elm={elm} index={i} show={false} stateProps={stateProps} />
          ))
        )}
        {React.Children.toArray(
          barStackLineTargetPoints.targetLines.map((elm, i) => (
            <ToolTipDiv
              elm={elm}
              index={i}
              show={false}
              stateProps={stateProps}
              isTargetLines={true}
            />
          ))
        )}
        {chartToolTip.style[0] !== undefined ||
          barStackLineTargetPoints.targetLines[0] !== undefined ? (
          <div className='resetDefault' onClick={() => handleResetToolTip()}>
            {mls("Reset to defaults")}
          </div>
        ) : null}
      </div>
    </>
  );
};
export default DragableToolTip;

const ToolTipDiv = (props) => {
  const { elm, show = true, stateProps, index, isTargetLines } = props;
  const {
    setChartToolTip,
    chartToolTip,
    chartType,
    xAxisData,
    yAxisData,
    setBarStackLineTargetPoints,
  } = stateProps;

  const udpateToolTip = (data) => {
    setChartToolTip((currentValue) => {
      const sourceitems = Array.from(currentValue.style);
      const [selectedItem] = sourceitems.splice(index, 1);
      const newSelectedItem = { ...selectedItem, ...data };
      sourceitems.splice(index, 0, newSelectedItem);
      const newSourceItem = {
        ...currentValue,
        style: sourceitems,
      };
      return newSourceItem;
    });
  };
  const udpateTargetLine = (data) => {
    setBarStackLineTargetPoints((currentValue) => {
      const sourceitems = Array.from(currentValue.targetLines);
      const [selectedItem] = sourceitems.splice(index, 1);
      const newSelectedItem = { ...selectedItem, ...data };
      sourceitems.splice(index, 0, newSelectedItem);
      const newSourceItem = {
        ...currentValue,
        targetLines: sourceitems,
      };
      return newSourceItem;
    });
  };

  const handleLabel = (e) => {
    udpateToolTip({ label: e.target.value });
    if (isTargetLines) {
      udpateTargetLine({ label: e.target.value });
    }
  };
  const handleShow = (action) => {
    if (isTargetLines) {
      udpateTargetLine({ show: action });
    }
    udpateToolTip({ show: action });
  };

  const opration = useMemo(
    () => returnOpration({ chartType, xAxisData, yAxisData, elm }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xAxisData, yAxisData, chartToolTip]
  );
  return (
    <ClosableWhiteDiv title={elm.originalLabel || elm.label} span={opration} show={show}>
      <div className='inputDivContainer'>
        <div className='toolTipInputDiv'>
          <input type='text' value={elm.label} onChange={(e) => handleLabel(e)} />
          <div className='visibilityToggleDiv'>
            {mls("Visibility")}:,
            <SlideSwitch isChecked={elm.show} setIsChecked={handleShow} />
          </div>
        </div>
      </div>
    </ClosableWhiteDiv>
  );
};

const returnOpration = ({ chartType, xAxisData, yAxisData, elm }) => {
  if (!chartType?.toolTip?.isMultipleValue) {
    const getOpration = () => {
      const findInxAxisData = xAxisData.find((e) => {
        return e.uniqueColumnName === elm.uniqueColumnName;
      });
      if (findInxAxisData) return findInxAxisData;

      const findInyAxisData = yAxisData.find((e) => {
        return e.uniqueColumnName === elm.uniqueColumnName;
      });

      if (findInyAxisData) return findInyAxisData;
      return false;
    };
    const data = getOpration();
    if (data?.operations?.type) {
      return `${data.operations.type}`;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
