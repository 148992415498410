import {
  bubbleCatType,
  getStatisticalData,
  groupBubbleCatType,
  groupScatterCatType,
  normalCatType,
  scatterCatType,
  wordcloudCatType,
} from '../../data/data';
// import { categorical } from '../../data/Extra/exportData';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import TableData from '../TableData/TableData';
import '../InsightStyle.css';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { changeNumFormat } from '../../utils/converterFunction';
import { mls } from 'lib/multilanguagesupport';

interface mainProps {
  mainData: any;
  axisIndex: number;
  catChartAxis: string[];
  chartType: string;
  isReport: boolean;
}
interface CategoricalByTypesProps {
  sectionObj: any;
  openStates: any;
  columnName: string;
  chartType: string;
  dataSectionType: any;
  mainData: any;
}
// isReport: boolean;

const CategoricalType = (props: mainProps) => {
  const { mainData, axisIndex, catChartAxis, chartType, isReport } = props;

  const [openIndexes, setOpenIndexes] = useState<number[]>([0]);

  const handleClick = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const columnName = Object.values(catChartAxis)[0] ?? '';
  const getCatType = ({ chartType }: { chartType: string }) => {
    if (chartType === allChartKeys.WORDCLOUD) {
      return wordcloudCatType;
    }
    if (chartType === allChartKeys.SCATTERPLOT) {
      return scatterCatType;
    }
    if (chartType === allChartKeys.GROUPSCATTERPLOT) {
      return groupScatterCatType;
    }
    if (chartType === allChartKeys.BUBBLEPLOT) {
      return bubbleCatType;
    }
    if (chartType === allChartKeys.GROUPBUBBLEPLOT) {
      return groupBubbleCatType;
    }
    return normalCatType;
  };
  const catType: any = getCatType({ chartType });

  return (
    <div
      className={
        isReport
          ? 'd-flex justify-content-between insight-accordion-container newPageauto'
          : 'container-fluid d-flex justify-content-between insight-accordion-container newPageauto'
      }
    >
      <div className='accordion w-100  accordion-flush' id='accordionFlushExample'>
        {React.Children.toArray(
          (mainData.dataSections[axisIndex] ?? []).map((sectionData: any, index: number) => {
            const dataSectionType = catType[sectionData?.type as keyof typeof catType];
            return (
              <div className='accordion-item'>
                {isReport ? (
                  <div className={`inisghtAccordionsHeaderTitle`}>
                    {mls(dataSectionType?.mainTitle)}
                  </div>
                ) : (
                  <h4 className='accordion-header'>
                    <button
                      className={`inisght-accordions accordion-button ${
                        openIndexes.includes(index) ? '' : 'collapsed'
                      }`}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#flush-collapse-${index}`}
                      aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
                      aria-controls={`flush-collapse-${index}`}
                      onClick={() => handleClick(index)}
                    >
                      {mls(dataSectionType?.mainTitle)}
                    </button>
                  </h4>
                )}
                <div
                  id={`flush-collapse-${index}`}
                  className={`accordion-collapse collapse ${
                    openIndexes.includes(index) || isReport ? 'show' : ''
                  }`}
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body p-2 inisght-accordions-body'>
                    <CategoricalByTypes
                      sectionObj={sectionData}
                      openStates={openIndexes}
                      columnName={columnName}
                      dataSectionType={dataSectionType}
                      chartType={chartType}
                      mainData={mainData}
                    />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default CategoricalType;

// CATEGORY
const CategoricalByTypes = ({
  sectionObj,
  openStates,
  columnName,
  dataSectionType,
  chartType,
  mainData,
}: CategoricalByTypesProps) => {
  const sectionType = sectionObj.type;
  if (sectionType === 'TOP5BOTTOM5') {
    return (
      <div className='container-fluid w-100 p-0 g-0 insight-side-by-side-table-container'>
        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col py-4 gx-0 gy-0 m-2 insight-side-by-side-table-heading text-center'>
            <b>{mls(dataSectionType?.topPerformers?.title)}</b>
          </div>
          <TableData
            elem={sectionObj.top5}
            type={'twoColumns'}
            columnName2={mls(dataSectionType?.topPerformers?.column1 ?? columnName)}
            columnName3={mls(dataSectionType?.topPerformers?.column2)}
            chartType={chartType}
            mainData={mainData}
          />
        </div>

        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col m-2 py-4 gx-0 gy-0 insight-side-by-side-table-heading text-center'>
            <b>{mls(dataSectionType?.bottomPerformers?.title)}</b>
          </div>
          <TableData
            elem={sectionObj.bottom5}
            type={'twoColumns'}
            columnName2={mls(dataSectionType?.topPerformers?.column1 ?? columnName)}
            columnName3={mls(dataSectionType?.topPerformers?.column2)}
            chartType={chartType}
            mainData={mainData}
          />
        </div>
      </div>
    );
  }
  if (sectionType === '80-20RULE') {
    const top20PercentImpact = changeNumFormat(sectionObj?.top20PercentImpact);
    const bottom20PercentImpact = changeNumFormat(sectionObj?.bottom20PercentImpact);
    return (
      <div className='container-fluid w-100 p-0 g-0 insight-side-by-side-table-container'>
        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col py-4 gx-0 gy-0 m-2 insight-side-by-side-table-heading text-center'>
            <b>{mls('Top 20% Performers')}</b>
          </div>
          <p className='text-center'>
            {mls('Impact')} :{' '}
            <b>
              {!top20PercentImpact.isNull
                ? `${top20PercentImpact.value}%`
                : top20PercentImpact.value}
            </b>
          </p>
          <TableData
            elem={sectionObj.top20PercentList}
            type={'twoColumns'}
            columnName2={columnName}
            columnName3={mls('Percentage')}
            chartType={chartType}
            mainData={mainData}
          />
        </div>

        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col py-4 gx-0 gy-0 m-2 insight-side-by-side-table-heading text-center'>
            <b>{mls('Bottom 20% Performers')}</b>
          </div>

          <p className='text-center'>
            {mls('Impact')} :{' '}
            <b>
              {!bottom20PercentImpact.isNull
                ? `${bottom20PercentImpact.value}%`
                : bottom20PercentImpact.value}
            </b>
          </p>
          <TableData
            elem={sectionObj.bottom20PercentList}
            type={'twoColumns'}
            columnName2={columnName}
            columnName3={mls('Percentage')}
            chartType={chartType}
            mainData={mainData}
            // type={''}
          />
        </div>
      </div>
    );
  }
  if (sectionType === 'STATISTICAL') {
    return (
      <TableData
        elem={getStatisticalData(sectionObj)}
        columnName1={mls('Types of Analysis')}
        columnName2={mls('Average Value')}
        columnName3={mls('Median Value')}
        type={'Statistical'}
        chartType={chartType}
        mainData={mainData}
      />
    );
  }

  if (sectionType === 'DESCRIBE') {
    return (
      <div className='container-fluid w-100 p-0 g-0 insight-side-by-side-table-container'>
        <TableData
          elem={sectionObj.describe}
          type={'DESCRIBE'}
          columnName1={mls(dataSectionType?.describe?.title ?? '')}
          columnName2={mls(dataSectionType?.topPerformers?.column2)}
          columnName3={mls(dataSectionType?.topPerformers?.column3)}
          columnName4={mls(dataSectionType?.topPerformers?.column4 ?? columnName)}
          chartType={chartType}
          mainData={mainData}
        />
      </div>
    );
  }
  if (sectionType === 'DENSITY') {
    return (
      <div className='container-fluid w-100 p-0 g-0 insight-side-by-side-table-container'>
        <TableData
          elem={sectionObj}
          type={'DENSITY'}
          columnName1={mls(dataSectionType?.density?.title ?? '')}
          columnName2={mls(dataSectionType?.topPerformers?.column2)}
          columnName3={mls(dataSectionType?.topPerformers?.column3)}
          columnName4={mls(dataSectionType?.topPerformers?.column4 ?? columnName)}
          chartType={chartType}
          mainData={mainData}
        />
      </div>
    );
  }
  if (
    sectionType === 'SCATTERTOPBOTTOM' ||
    sectionType === 'GRPSCATTERTOP5BOTTOM5' ||
    sectionType === 'BUBBLETOPBOTTOM' ||
    sectionType === 'GROUPBUBBLETOPBOTTOM'
  ) {
    return (
      <div className='container-fluid w-100 p-0 g-0 insight-side-by-side-table-container'>
        <TableData
          elem={sectionObj}
          type={'SCATTERTOPBOTTOM'}
          columnName1={mls(dataSectionType?.density?.title ?? '')}
          columnName2={mls(dataSectionType?.topPerformers?.column2)}
          columnName3={mls(dataSectionType?.topPerformers?.column3)}
          columnName4={mls(dataSectionType?.topPerformers?.column4 ?? columnName)}
          chartType={chartType}
          mainData={mainData}
        />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{sectionType && openStates}</TableBody>
      </Table>
    </TableContainer>
  );
};
