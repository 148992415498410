import clsx from 'clsx';
import CopyColumnToClipBoard from 'lib/data-connectors/dependencies/CopyColumnToClipBoard/CopyColumnToClipBoard';
import DataLakeSearch from 'lib/data-connectors/dependencies/DataLakeSearch/DataLakeSearch';
import { dataTableColumnTypes } from 'lib/data-connectors/dependencies/constant';
import { mls } from 'lib/multilanguagesupport';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

interface tableCardProps {
  columnSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;
  formik: any;
  handleTableChange: Function;
  appDatatable: any;
  selectColumn: Function;
  deselectColumn: Function;
  formikKey: string;
  selectionSchema: any[];
  children?: React.ReactNode;
}

export const TableCard = ({
  columnSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',
  formik,
  handleTableChange,
  appDatatable,
  selectColumn,
  deselectColumn,
  formikKey,
  selectionSchema,
  children,
}: tableCardProps) => {
  const [filteredcolumnSchema, setFilteredColumnSchema] = useState(columnSchema);
  const setSearch = (filteredObject: any) => {
    const finalObject = [...Object.values(filteredObject)];
    setFilteredColumnSchema(finalObject);
  };
  const getOrdinalSuffix = (number: number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;

    if (remainder >= 11 && remainder <= 13) {
      return number + suffixes[0];
    } else {
      switch (remainder % 10) {
        case 1:
          return number + suffixes[1];
        case 2:
          return number + suffixes[2];
        case 3:
          return number + suffixes[3];
        default:
          return number + suffixes[0];
      }
    }
  };
  return (
    <div className={`p-5 mt-5 border rounded ${className}`}>
      {mainTitle ? <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label> : null}
      {subTitle ? (
        <>
          <br />
          <label className='form-label  mt-5 '>{mls(subTitle)}</label>
        </>
      ) : null}
      <select
        {...formik.getFieldProps(formikKey)}
        className={clsx('form-select form-control form-control-solid')}
        onChange={handleTableChange}
      >
        <option value='' hidden>
          {tableIndex === 0
            ? mls('Select the primary table')
            : `${mls(`Select the`)} ${getOrdinalSuffix(tableIndex)} ${mls(`Secondary Table`)}`}
        </option>
        {!_.isEmpty(appDatatable) &&
          Object.keys(appDatatable).map((dataTable, index) => {
            return (
              <option
                key={index}
                value={dataTable}
                style={
                  formik.getFieldProps(formikKey).value === dataTable
                    ? { background: '#f1faff', color: '#009ef7' }
                    : {}
                }
              >
                {appDatatable[dataTable].name}
              </option>
            );
          })}
      </select>
      <br />
      <DataLakeSearch
        objectForSearch={columnSchema ?? []}
        setSearch={setSearch}
        fullWidth
        placeholder={mls('Search Columns')}
      />

      <div className='w-20 mx-auto'>
        {/* <div className='w-20 mx-auto'> */}
        <div className='row mt-5'>
          <label className='col-2 fw-bold'>{mls('Import')}</label>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-5 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        <div style={{ ...customColumnBoxStyle, overflowX: 'hidden' }}>
          {(filteredcolumnSchema || []).map((tableCol: any, ix: number) => {
            const isSelected = selectionSchema?.some(
              (col: any) => col.columnName === tableCol.columnName
            );
            return (
              <div className='row my-5' key={ix}>
                <div className='col-2 d-flex align-items-center justify-content-center'>
                  <input
                    type='checkbox'
                    className='form-check-input cursor-pointer'
                    checked={isSelected}
                    onChange={() => {
                      isSelected ? deselectColumn(tableCol) : selectColumn(tableCol);
                    }}
                  />
                </div>

                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <CopyColumnToClipBoard columnName={tableCol.columnName} tableIndex={tableIndex}>
                    {tableCol?.name ?? tableCol?.columnName}
                  </CopyColumnToClipBoard>
                </div>

                {/* <div className='col-5 d-flex align-items-center justify-content-left'>
                  <select
                    className='form-select form-control form-control-solid mb-1 mx-auto'
                    value={tableCol.dataType || 'text'}
                    onChange={(e) => {
                      handleCustomColumnDataTypeChange(ix, e.target.value);
                    }}
                  >
                    {Object.entries(dataTableColumnTypes).map((type) => {
                      return <option value={type[0]}>{type[1]}</option>;
                    })}
                  </select>
                </div> */}
                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <input
                    placeholder={mls('Enter Join table name')}
                    value={tableCol.dataType}
                    className={clsx('form-control form-control-solid')}
                    disabled
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div>{children}</div>
      {/* Display warning if no column is selected from this table */}
      {columnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};
interface tableCardDataTableProps {
  columnSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;

  handleDataTypeChange: Function;
  handleDisabledColumn: Function;
  children?: React.ReactNode;
}

export const TableCardDataTable = ({
  columnSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',

  handleDataTypeChange,
  handleDisabledColumn,
  children,
}: tableCardDataTableProps) => {
  const [filteredcolumnSchema, setFilteredColumnSchema] = useState(columnSchema);
  const setSearch = (filteredObject: any) => {
    const finalObject = [...Object.values(filteredObject)];
    setFilteredColumnSchema(finalObject);
  };
  return (
    <div className={`p-5 mt-5 border rounded ${className}`}>
      {mainTitle ? <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label> : null}
      {subTitle ? (
        <>
          <br />
          <label className='form-label  mt-5 '>{mls(subTitle)}</label>
        </>
      ) : null}

      <DataLakeSearch objectForSearch={columnSchema ?? []} setSearch={setSearch} fullWidth />
      {/* <SearchInputDiv value={searchKey} onChange={setSearchKey} border /> */}
      <div className='w-20 mx-auto'>
        <div className='row mt-5'>
          <label className='col-2 fw-bold'>{mls('Import')}</label>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-5 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        <div style={{ ...customColumnBoxStyle, overflowX: 'hidden' }}>
          {(filteredcolumnSchema || []).map((tableCol: any, ix: number) => {
            const isSelected = !(tableCol?.disabledColumn ?? false);
            return (
              <div className='row my-5' key={ix}>
                <div className='col-2 d-flex align-items-center justify-content-center'>
                  <input
                    type='checkbox'
                    className='form-check-input cursor-pointer'
                    checked={isSelected}
                    onChange={() => {
                      handleDisabledColumn(ix, isSelected);
                    }}
                  />
                </div>

                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <CopyColumnToClipBoard columnName={tableCol.columnName} tableIndex={tableIndex}>
                    {tableCol?.name ?? tableCol?.columnName}
                  </CopyColumnToClipBoard>
                </div>

                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <select
                    className='form-select form-control form-control-solid mb-1 mx-auto'
                    value={tableCol.dataType || 'text'}
                    onChange={(e) => {
                      handleDataTypeChange(ix, e.target.value);
                    }}
                  >
                    {Object.entries(dataTableColumnTypes).map((type) => {
                      return <option value={type[0]}>{type[1]}</option>;
                    })}
                  </select>
                </div>
                {/* <div className='col-5 d-flex align-items-center justify-content-left'>
                  <input
                    placeholder={mls('Enter Join table name')}
                    value={tableCol.dataType}
                    className={clsx('form-control form-control-solid')}
                    disabled
                  />
                </div> */}
              </div>
            );
          })}
        </div>
      </div>

      <div>{children}</div>
      {/* Display warning if no column is selected from this table */}
      {columnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};

const customColumnBoxStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
  paddingRight: '.5rem',
  overflowX: 'hidden',
};
