import React, { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { toAbsoluteUrl } from '_metronic/helpers';
import './displayTable.scss';
import { CircularProgress } from '@mui/material';
import { defaultDataType } from '../reusableData/defaultChartSchema';

const svgStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50vw',
};
const maxRow = 2;

interface displayTableProps {
  tableData: {
    tableName: string;
    items: {
      columnName: string;
      name: string;
      tableName: string;
      values: string | number[];
      dataType: string;
    }[];
  }[];
  tableLoading?: boolean;
}
const DisplayTable = (displayTableProps: displayTableProps) => {
  const { tableData, tableLoading = false } = displayTableProps;
  const [showMoreTableIndex, setShowMoreTableIndex] = useState(null);
  if (tableLoading) {
    return (
      <div className='DisplayTable'>
        <div className='mb-20'>
          <div style={svgStyle}>
            <div
              style={{
                minWidth: '250px',
                maxHeight: '45vh',
                height: '30vh',
                // width: '100px',
                // height: '100px',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          </div>
          <br />
          <h6 style={svgStyle} className='mb-3 text-center'>
            {mls('Table Loading...')}
          </h6>
        </div>
      </div>
    );
  }

  return (
    <div className='DisplayTable'>
      {tableData.length === 0 ? (
        <div>
          <div className='mb-20'>
            <div style={svgStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 100 90'
                width='40%'
                height='40%'
                style={{ minWidth: '250px', maxHeight: '45vh' }}
              >
                <image
                  href={toAbsoluteUrl('/media/errorScreens/NoChart.png')} // Replace with the actual path to your image
                  width='100'
                  height='100'
                />
              </svg>

              {/* <img src={toAbsoluteUrl('/media/chart-screen/Show_no_chart.svg')} alt='NoCharts'></img> */}
            </div>
            <>
              <br />
              <h5 style={svgStyle} className='mb-3 text-center'>
                {mls('No data available in the Table to show. Kindly check the connection.')}
              </h5>
            </>
          </div>
        </div>
      ) : (
        <div className='scrollingPart'>
          <div className='tableDivContainer'>
            {React.Children.toArray(
              tableData.map((tableElem: any, index: number) => {
                if (!tableData[1]) {
                  return <SingleTable tableElem={tableElem} />;
                }
                return (
                  <JoinTable
                    tableElem={tableElem}
                    index={index}
                    showMoreTableIndex={showMoreTableIndex}
                    setShowMoreTableIndex={setShowMoreTableIndex}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayTable;

const SingleTable = ({ tableElem }: any) => {
  return (
    <>
      <div className='tableOuterDiv'>
        <div className='tableName' title={tableElem?.nameOfTable}>
          {tableElem?.nameOfTable}
        </div>
        <div className='tableInnerDiv'>
          {React.Children.toArray(
            tableElem.items.map((columnElem: any) => {
              const isDate = columnElem?.dataType === defaultDataType.DATETIME;
              const isNum =
                columnElem.dataType === defaultDataType.NUM ||
                columnElem.dataType === defaultDataType.ID_NUM;
              return (
                <div className='columnDiv'>
                  <div
                    className={
                      columnElem?.isHighlighted ? 'columnName highlightedColumnName' : 'columnName'
                    }
                    title={columnElem.name}
                  >
                    {columnElem.name}
                  </div>
                  <div className='columnInnerDiv'>
                    {React.Children.toArray(
                      columnElem?.values?.map((elem: any) => {
                        return (
                          <div
                            className='columnValues'
                            title={elem}
                            style={isNum ? { textAlign: 'end' } : {}}
                          >
                            {isDate ? checkForDate(elem) : elem}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
const JoinTable = ({ tableElem, showMoreTableIndex, setShowMoreTableIndex, index }: any) => {
  const handleMore = () => {
    if (showMoreTableIndex === index) {
      setShowMoreTableIndex(null);
      return;
    }
    setShowMoreTableIndex(index);
  };
  const isShowMore = showMoreTableIndex !== index;

  return (
    <>
      <div className='tableOuterDiv'>
        <div
          className='tableName'
          //   title={allTableIDAndNamesMap[tableElem?.tableName] || tableElem?.tableName}
          title={tableElem?.nameOfTable}
        >
          {/* {allTableIDAndNamesMap[tableElem?.tableName] || tableElem?.tableName} */}
          {tableElem?.nameOfTable}
        </div>
        <div className='tableInnerDiv'>
          {React.Children.toArray(
            tableElem.items.map((columnElem: any) => {
              const isDate = columnElem?.dataType === 'DATETIME';
              const isNum =
                columnElem.dataType === defaultDataType.NUM ||
                columnElem.dataType === defaultDataType.ID_NUM;
              return (
                <div className='columnDiv'>
                  <div
                    className={
                      columnElem?.isHighlighted ? 'columnName highlightedColumnName' : 'columnName'
                    }
                    title={columnElem.columnName}
                  >
                    {columnElem.columnName}
                  </div>
                  <div className='columnInnerDiv'>
                    {React.Children.toArray(
                      columnElem.values.map((elem: any, index: number) => {
                        if (isShowMore) {
                          if (index >= maxRow) {
                            return <></>;
                          }
                        }

                        return (
                          <div
                            className='columnValues'
                            title={elem}
                            style={isNum ? { textAlign: 'end' } : {}}
                          >
                            {isDate ? checkForDate(elem) : elem}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className='moreButton'>
          <span onClick={() => handleMore()}>
            {isShowMore ? mls('Show more') : mls('Show less')}
          </span>
        </div>
      </div>
    </>
  );
};

const checkForDate = (elem: any) => {
  const timestamp: any = Date.parse(elem);
  const numberCheck = Number(elem);
  if (isNaN(timestamp) !== false) {
    return elem;
  }
  if (isNaN(numberCheck) === false) {
    return elem;
  }
  const nowDate: any = new Date(elem);

  const date =
    nowDate.getFullYear() +
    '/' +
    ('0' + (nowDate.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + nowDate.getDate()).slice(-2);
  return date;
};
