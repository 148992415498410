import useDebounce from '../../../hooks/useDebounce';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import React, { useEffect, useState } from 'react';
import barChart from '../../../assets/xBlack.svg';
import loadingImage from '../../../assets/loadingImage.gif';
import { supistaApiGet } from 'lib/chart-settings-lib/utils/connections';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';

const CardHeaderImage = ({ sourceImage, setSourceImage }) => {
  const [cardHeaderImage, setCardHeaderImage] = useState(sourceImage);
  const [isValidImage, setIsValidImage] = useState(true);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const cardHeaderImageDebounce = useDebounce(cardHeaderImage);

  const onLoad = () => {
    setIsValidImage(true);
    setIsLoadingImage(false);
  };
  const onError = () => {
    setIsValidImage(false);
    setIsLoadingImage(false);
  };
  useUpdateEffect(async () => {
    setIsLoadingImage(true);
  }, [cardHeaderImage]);
  useUpdateEffect(async () => {
    setSourceImage(cardHeaderImage);
  }, [cardHeaderImageDebounce]);

  useUpdateEffect(() => {
    setCardHeaderImage(sourceImage);
  }, [sourceImage]);

  return (
    <div className='grayContainers CardHeaderImage'>
      <div className='containerItems' style={{ gap: '.5rem' }}>
        <div className='titleDiv'> &nbsp;{mls("Card Image Url")}</div>
        <input
          type='text'
          value={cardHeaderImage}
          onChange={(e) => setCardHeaderImage(e.target.value)}
          placeholder='https://'
        />
        {cardHeaderImage ? (
          <div className='cardHeaderImageContainer'>
            <img src={cardHeaderImage} alt='' onLoad={() => onLoad()} onError={() => onError()} />
            {isLoadingImage ? (
              <div className='loadingImage'>
                <CircularProgress />
              </div>
            ) : null}
            {!isValidImage && !isLoadingImage && cardHeaderImageDebounce ? (
              <div className='invalidImage'>{mls("Invalid Image")}</div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardHeaderImage;
