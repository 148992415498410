import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { KTSVG /* toAbsoluteUrl */ } from '../../../helpers';
// import { /*HeaderNotificationsMenu, */ HeaderUserMenu /*QuickLinks*/ } from '../../../partials';
// import { useLayout } from '../../core';
import generateUUID from 'lib/reusable-components/reusableUtils/updateSchema/generateUUID';
import useChartSettingsButton, {
  useDashSettingsButton,
  useKpiSettingsButton,
  useReportSettingsButton,
} from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { HeaderUserMenu } from '_metronic/partials';
import { mls } from 'lib/multilanguagesupport';
import EditChart from 'app/charts/EditChart';
import SheetSettings from 'app/sheets/SheetSettings';

const // toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-35px h-md-35px';
// toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
// toolbarButtonIconSizeClass = 'svg-icon-1';

const Topbar: FC = () => {
  // const { config } = useLayout();
  const chartSettingsButton = useChartSettingsButton();
  const dashSettingsButton = useDashSettingsButton();
  const reportSettingsButton = useReportSettingsButton();
  const kpiSettingsButton = useKpiSettingsButton();

  const [uuid, setNewuuid] = useState('');
  const [isSheet, setSheet] = useState('');
  const [chartOder, setChartOder] = useState(false);
  const { pathname } = useLocation();

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];
  const isAccess =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');

  const sheetSchema = analyticsSchema.appSheets || {};
  const chartsSchema = analyticsSchema.appCharts || {};

  const appID = pathname.split('/')[1];
  const typeOfPage = pathname.split('/')[2];
  const id = pathname.split('/')[3];

  // useEffect(() => {
  //   if (typeOfPage === 'dashboard') {
  //   } else if (typeOfPage === 'sheets') {

  //   }
  // }, [pathname, typeOfPage]);

  const handleButton = (type: string, id = '') => {
    const newUuid = generateUUID();
    setNewuuid(newUuid);
    if (type === 'chart') chartSettingsButton(newUuid);
    if (type === 'sheet') setSheet(newUuid);
    if (type === 'editSheet') setSheet(id);
    if (type === 'dash') dashSettingsButton(newUuid);
    if (type === 'editDash') dashSettingsButton(id);
    if (type === 'report') reportSettingsButton(newUuid);
    if (type === 'KPIDash')
      kpiSettingsButton({
        isPopup: true,
        isNew: true,
        kpiSchema: { chartID: newUuid },
      });
  };

  useEffect(() => {}, [pathname]);
  // const handleNewChart = () => {
  //   const newUuid = generateUUID();
  //   setNewuuid(newUuid);
  //   chartSettingsButton(newUuid);
  // };

  // const handleClick = () => {
  //   setIndex(noOfProjects);
  //   const newID = generateUUID();
  //   setNewProjectID(newID);
  //   setClick(newID);
  // };

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='d-flex flex-stack'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
        {isAccess && typeOfPage === 'dashboard' && !id && (
          <div className={clsx('d-flex align-items-stretch flex-shrink-0')}>
            <div className='d-flex flex-stack'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('dash')}
              >
                <span className='indicator-label'>{mls('Add New Dashboard')}</span>
              </button>
            </div>
          </div>
        )}
        {isAccess && typeOfPage === 'dashboard' && !!id && (
          <div className={clsx('d-flex align-items-stretch flex-shrink-0')}>
            <div className='d-flex flex-stack'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('editDash', id)}
              >
                <span className='indicator-label'>{mls('Edit Dashboard')}</span>
              </button>
            </div>
          </div>
        )}
        {isAccess && typeOfPage === 'kpiDashboard' && (
          <div className={clsx('d-flex align-items-stretch flex-shrink-0')}>
            <div className='d-flex flex-stack'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('KPIDash')}
              >
                <span className='indicator-label'>{mls('Add KPI Chart')}</span>
              </button>
            </div>
          </div>
        )}
        <>
          {isAccess && typeOfPage === 'sheets' && !id && (
            <>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('sheet')}
              >
                <span className='indicator-label'>{mls('Add New Sheet')}</span>
              </button>
              {isSheet && (
                <SheetSettings
                  appID={appID}
                  sheetID={uuid}
                  isModalOpen={isSheet}
                  setModalOpen={setSheet}
                  isNew={true}
                  sheetSchema={{}}
                />
              )}
            </>
          )}
          {isAccess && typeOfPage === 'sheets' && !!id && (
            <>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('editSheet', id)}
              >
                <span className='indicator-label'>{mls('Edit Sheet')}</span>
              </button>
              {isSheet && (
                <SheetSettings
                  appID={appID}
                  sheetID={id}
                  isModalOpen={isSheet}
                  setModalOpen={setSheet}
                  isNew={false}
                  sheetSchema={sheetSchema[id]}
                />
              )}

              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => handleButton('chart')}
              >
                <span className='indicator-label'>{mls('Add New Chart')}</span>
              </button>
              <div className={clsx('d-flex align-items-center me-3')}>
                {/* begin::Drawer toggle */}
                <div
                  className={clsx(
                    'btn btn-icon btn-active-light-primary',
                    toolbarButtonHeightClass
                  )}
                  onClick={() => setChartOder(true)}
                >
                  <i
                    className='bi bi-grid-3x3-gap-fill'
                    style={{ fontSize: '1.5rem' }}
                    title={mls('Change order of charts')}
                  ></i>
                </div>
              </div>
              {chartOder && (
                <EditChart
                  chartsSchema={chartsSchema}
                  show={chartOder}
                  setShow={setChartOder}
                  sheetID={id}
                  sheetSchema={sheetSchema}
                  analyticsSchema={analyticsSchema}
                  appID={appID}
                />
              )}
            </>
          )}
        </>
        {isAccess && typeOfPage === 'reports' && !id && (
          <>
            <button
              type='button'
              className='btn btn-sm btn-light-primary me-3'
              onClick={() => handleButton('report')}
            >
              <span className='indicator-label'>{mls('Add New Report')}</span>
            </button>
          </>
        )}
        {/* Activities */}
        {/* end::Drawer toggle */}
        {/* </div> */}
        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
        {/* begin::Menu wrapper */}
        {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks /> */}
        {/* end::Menu wrapper */}
        {/* </div> */}

        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
        {/* begin::Menu wrapper */}
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}
        {/* end::Menu wrapper */}
        {/* </div> */}

        {/* NOTIFICATIONS */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
        {/* begin::Menu- wrapper */}
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu /> */}
        {/* end::Menu wrapper */}
        {/* </div> */}

        {/* begin::User */}
        {/* <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      > */}
        {/* begin::Toggle */}
        {/* <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/150-2.jpg')} alt='metronic' />
        </div> */}
        <div className='d-flex align-items-stretch flex-shrink-0'>
          <HeaderUserMenu />
        </div>
        {/* end::Toggle */}
        {/* </div> */}
        {/* end::User */}

        {/* begin::Aside Toggler */}
        {/* {config.header.left === 'menu' && (
          <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export { Topbar };
