// import { supistaApiGet, supistaApiPost } from "./connections";
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import {
  _fetchDataToPlot,
  // _supistaAnalyticsApi,
  _supistaApiGet,
  _supistaApiPost,
} from 'lib/server-connection/connections';

export const publishToProduction = (appID) => {
  _supistaApiPost(`auth/${appID}/publishToProduction`, {
    data: {},
  })
    .then(async (response) => {
      await fetchData(appID, true);
    })
    .catch((err) => {});
};

const fetchData = async (appID) => {
  if (!appID) {
    return;
  }

  try {
    const appSchema = await _supistaApiGet(`appSchema/${appID}`);
    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_SCHEMA,
        value: appSchema,
      })
    );
    const allSchemaRes = await _fetchDataToPlot({ appID }, `${appID}/allSchema`);
    if (Object.keys(allSchemaRes).length > 0 && allSchemaRes.__d3__error !== true) {
      store.dispatch(
        create({
          setPath: reduxConstants.config.ANALYTICS_SCHEMA,
          value: allSchemaRes.response,
        })
      );
    }
  } catch (error) {
    store.dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('There was a problem while fetching App Schema, please try again later!'),
          time: 5,
          isActive: true,
        },
      })
    );
  }
};
