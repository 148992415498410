import {
  defaultChartSetting,
  defaultChartWidthTypes,
  defaultFontFamily,
  defaultSelectedFontSize,
  defaultLegendColorPalettes,
  defaultGridLines,
  defaultOpacity,
} from '../data/defaultChartSchema';
import { allChartKeys, allChartTypes } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { getTimeFrame } from './chartHelperFunctions';

import { allDropableID } from 'lib/chart-settings-lib/data/defaultChartSchema';

interface props {
  stateProps: any;
  chartSchema: any;
  allTableData: any;
  populateState: boolean;
  setPopulateState: any;
}

export const chartPopulate = (props: props) => {
  const { stateProps, chartSchema, allTableData, populateState, setPopulateState } = props;

  if (!populateState) return;

  const CheckUpdateAndUpdateFunction = (setFunction: any, data: any, defaultData: any) => {
    if (data !== null && data !== undefined) {
      setFunction(data);
    } else {
      setFunction(defaultData);
    }
  };
  const {
    setPopulateCount,
    setAllTableData,
    setFilterdTableSchema,
    setChatName,
    setChartDescription,
    setchartType,
    setXAxisData,
    setYAxisData,
    setChartToolTip,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setChartFilter,
    setChartWidth,
    setIsInsight,
    setIsForecast,
    setIsCrossReport,
    setIskeepAllFilters,
    setIsZoomBrush,
    setIsDateHierarchy,
    setPlotAreaBackground,
    setGridLines,
    setColumnDropNumber,
    defaultDateTimeCol,
    setTimeFrame,

    //Special Chart
    setChartHeaderImage,
    setChartCardType, //Card
    setGeoGroup, //GEO
    setGeoMapColor, //GEOMAP
    setBarStackLineTargetPoints, //BARSTACKLINE
    setKpiTargetPoints, //KPIMETER
    //PLOT
    setPlotGroup, //PLOT
    setPlotGroupStyle, //PLOT
    setPivotTableTitleStyle, //PIVOTTABLE

    //HEATMAP
    setHeatMapGroup, //HEATMAP
    setHeatMapColor, //HEATMAP
    //TREEMAP
    setTreeMapColor, //TREEMAP
    // COLOR
    setColorAxis, //COLOR
    // DOUBLEAXIS
    setDoubleAxis, //DOUBLEAXIS
    // RADIALLINE
    setRadialLineBackGroundColor, //RADIALLINE
  } = stateProps;

  const chartType = (chartKey: string) => {
    if (chartKey) {
      const [chartType] = allChartTypes.filter((e) => e.chartKey === chartKey);
      return chartType;
    } else {
      return undefined;
    }
  };

  const chartWidth = (chartWidthKey: string) => {
    if (chartWidthKey) {
      const [chartWidth] = defaultChartWidthTypes.filter((e) => e.key === chartWidthKey);
      return chartWidth;
    } else {
      return undefined;
    }
  };
  const getChartTimeFrame = (timeFrameAction?: any) => {
    if (timeFrameAction) {
      const finalTimeFrame = getTimeFrame({
        action: timeFrameAction,
        tableSchema: allTableData,
        defaultDateTimeCol,
      });
      return finalTimeFrame;
    }

    const chartTimeFrame = chartSchema?.timeFrame;
    if (chartTimeFrame?.[defaultDateTimeCol]) {
      const action = `${chartTimeFrame?.[defaultDateTimeCol]?.value?.xDay}`;
      const finalTimeFrame = getTimeFrame({
        action: action,
        tableSchema: allTableData,
        defaultDateTimeCol,
      });
      return finalTimeFrame;
    }
    const finalTimeFrame = getTimeFrame({
      action: 'null',
      tableSchema: allTableData,
      defaultDateTimeCol,
    });
    return finalTimeFrame;
  };
  interface getDefaultAxisStyleWithTitleProps {
    defaultStyle: any;
    axis: any;
  }
  const getDefaultAxisStyleWithTitle = ({
    defaultStyle,
    axis = [],
  }: getDefaultAxisStyleWithTitleProps) => {
    const labelArray = axis.map((elem: any) => {
      return elem?.label;
    });
    const label = labelArray.join(',');

    return { ...defaultStyle, label };
  };
  interface ColumnDropNumberProps {
    xAxis: any[];
    yAxis: any[];
    group: any[];
  }
  const getColumnDropNumber = (ColumnDropNumberProps: ColumnDropNumberProps) => {
    const { xAxis, yAxis, group } = ColumnDropNumberProps;
    let columnDropNumber = 0;
    columnDropNumber = columnDropNumber + xAxis.length;
    columnDropNumber = columnDropNumber + yAxis.length;
    columnDropNumber = columnDropNumber + group.length;
    return columnDropNumber;
  };
  const updateArray = [
    {
      setFunction: setAllTableData,
      data: allTableData,
      defaultData: [],
    },
    {
      setFunction: setFilterdTableSchema,
      data: allTableData,
      defaultData: [],
    },
    // set
    {
      setFunction: setChatName,
      data: chartSchema.name,
      defaultData: defaultChartSetting.name,
    },
    {
      setFunction: setChartDescription,
      data: chartSchema.desc,
      defaultData: defaultChartSetting.desc,
    },
    {
      setFunction: setchartType,
      data: chartType(chartSchema.chartType),
      defaultData: defaultChartSetting.chartType,
    },
    {
      setFunction: setXAxisData,
      data: chartSchema.xAxis,
      defaultData: defaultChartSetting.xAxis,
    },
    {
      setFunction: setYAxisData,
      data: chartSchema.yAxis,
      defaultData: defaultChartSetting.yAxis,
    },
    {
      setFunction: setChartToolTip,
      data: chartSchema.tooltip,
      defaultData: defaultChartSetting.tooltip,
    },
    {
      setFunction: setChartXAxisStyle,
      data: chartSchema.xAxisStyle,
      defaultData: getDefaultAxisStyleWithTitle({
        defaultStyle: defaultChartSetting.xAxisStyle,
        axis: chartSchema.xAxis,
      }),
    },
    {
      setFunction: setChartYAxisStyle,
      data: chartSchema.yAxisStyle,
      defaultData: getDefaultAxisStyleWithTitle({
        defaultStyle: defaultChartSetting.yAxisStyle,
        axis: chartSchema.yAxis,
      }),
    },
    {
      setFunction: setChartFilter,
      data: chartSchema.filters,
      defaultData: defaultChartSetting.filters,
    },
    {
      setFunction: setChartWidth,
      data: chartWidth(chartSchema.chartWidth),
      defaultData: defaultChartSetting.chartWidth,
    },
    {
      setFunction: setIsInsight,
      data: chartSchema.isInsight,
      defaultData: defaultChartSetting.isInsight,
    },
    {
      setFunction: setIsForecast,
      data: chartSchema.isForecast,
      defaultData: defaultChartSetting.isForecast,
    },
    {
      setFunction: setIsCrossReport,
      data: chartSchema.isCrossReport,
      defaultData: defaultChartSetting.isCrossReport,
    },
    {
      setFunction: setIskeepAllFilters,
      data: chartSchema.iskeepAllFilters,
      defaultData: defaultChartSetting.iskeepAllFilters,
    },
    {
      setFunction: setIsZoomBrush,
      data: chartSchema.isZoomBrush,
      defaultData: defaultChartSetting.isZoomBrush,
    },
    {
      setFunction: setIsDateHierarchy,
      data: chartSchema.isDateHierarchy,
      defaultData: defaultChartSetting.isDateHierarchy,
    },
    {
      setFunction: setPlotAreaBackground,
      data: chartSchema.plotAreaBackground,
      defaultData: defaultChartSetting.plotAreaBackgroundSolid,
    },
    {
      setFunction: setGridLines,
      data: chartSchema.gridLines,
      defaultData: defaultChartSetting.gridLines,
    },
    {
      setFunction: setColumnDropNumber,
      data: chartSchema?.chartBuilderHelper?.columnDropNumber,
      defaultData: getColumnDropNumber({
        xAxis: chartSchema?.xAxis ?? defaultChartSetting.xAxis,
        yAxis: chartSchema?.yAxis ?? defaultChartSetting.yAxis,
        group: chartSchema?.group ?? defaultChartSetting.geoGroup,
      }),
    },
    // {
    //   setFunction:setTimeFrame,
    //   data:getChartTimeFrame(),
    //   defaultData:getChartTimeFrame(defaultChartSetting.timeFrame),
    // },
    //Special Chart
    //Card
    {
      setFunction: setChartCardType,
      data: chartSchema.chartCardType,
      defaultData: defaultChartSetting.chartCardType,
    },
    {
      setFunction: setChartHeaderImage,
      data: chartSchema?.headerImage,
      defaultData: defaultChartSetting.chartHeaderImage,
    },
    //GEO
    {
      setFunction: setGeoGroup,
      data: chartSchema.group,
      defaultData: defaultChartSetting.geoGroup,
    },
    //GEOMAP
    {
      setFunction: setGeoMapColor,
      data: chartSchema.geoMapColor,
      defaultData: defaultChartSetting.geoMapColor,
    },
    //BARSTACKLINE
    {
      setFunction: setBarStackLineTargetPoints,
      data: chartSchema.targetPoints,
      defaultData: defaultChartSetting.barStackLineTargetPoints,
    },

    //KPIMETER
    {
      setFunction: setKpiTargetPoints,
      data: chartSchema.targetPoints,
      defaultData: defaultChartSetting.targetPoints,
    },
    //PLOT
    {
      setFunction: setPlotGroup,
      data: chartSchema.plotGroup,
      defaultData: defaultChartSetting.plotGroup,
    },
    {
      setFunction: setPlotGroupStyle,
      data: chartSchema.plotGroupStyle,
      defaultData: defaultChartSetting.plotGroupStyle,
    },
    //PIVOTTABLE
    {
      setFunction: setPivotTableTitleStyle,
      data: chartSchema.pivotTableTitleStyle,
      defaultData: defaultChartSetting.pivotTableTitleStyle,
    },
    //HEATMAP
    {
      setFunction: setHeatMapGroup,
      data: chartSchema.magnitude,
      defaultData: defaultChartSetting.heatMapGroup,
    },
    //HEATMAP
    {
      setFunction: setHeatMapColor,
      data: chartSchema.heatMapColor,
      defaultData: defaultChartSetting.heatMapColor,
    },
    //TREEMAP
    {
      setFunction: setTreeMapColor,
      data: chartSchema.treeMapColor,
      defaultData: defaultChartSetting.treeMapColor,
    },
    //COLOR
    {
      setFunction: setColorAxis,
      data: chartSchema.colorAxis,
      defaultData: defaultChartSetting.colorAxis,
    },
    //DOUBLEAXIS
    {
      setFunction: setDoubleAxis,
      data: chartSchema.doubleAxis,
      defaultData: false,
    },
    //RADIALLINE
    {
      setFunction: setRadialLineBackGroundColor,
      data: chartSchema.radialLineBackGroundColor,
      defaultData: defaultChartSetting.radialLineBackGroundColor,
    },
  ];

  // populateState
  updateArray.forEach(({ setFunction, data, defaultData }) => {
    CheckUpdateAndUpdateFunction(setFunction, data, defaultData);
  });
  setPopulateCount((currentCount: number) => currentCount + 1);
  setPopulateState((preElem: boolean) => !preElem);
};

interface addDefaultDataInChartProps {
  chartData: any;
  tableSchema: any;
  table: string;
  sheetID: string;
}
export const addDefaultDataInChart = ({
  chartData,
  table,
  tableSchema,
  sheetID,
}: addDefaultDataInChartProps) => {
  const chartDataWithToolTip = updateDefaultSchema({
    schema: chartData.schema,
    table,
    tableSchema,
    sheetID,
  });
  const newChartData = { ...chartData, schema: chartDataWithToolTip };
  return newChartData;
};

interface updateDefaultSchemaProps {
  schema: any;
  tableSchema: any;
  table: string;
  sheetID: string;
}

export const updateDefaultSchema = ({
  schema,
  table,
  tableSchema,
  sheetID,
}: updateDefaultSchemaProps) => {
  let finalSchema: any = {};

  Object.keys(schema).forEach((chartID) => {
    const defaultShow = true;
    const getAxisData = ({ axis, axisID }: { axis: string; axisID: string }) => {
      return schema[chartID]?.[axis]?.map((axisElem: any) => {
        const selectedColumn = (tableSchema || []).filter((tableElem: any) => {
          return `${tableElem.tableName}.${tableElem.columnName}` === axisElem.column;
        });
        if (selectedColumn[0]) {
          return { ...axisElem, axis: axisID, filterData: selectedColumn[0]?.filterData };
        }
        return { ...axisElem, axis: axisID };
      });
    };
    const groupKey = getGroupDataByChart({
      stateProps: {},
      chartType: { chartKey: schema[chartID]?.chartType },
    })?.key;
    const xAxis = getAxisData({ axis: 'xAxis', axisID: allDropableID.xAxisId });
    const yAxis = getAxisData({ axis: 'yAxis', axisID: allDropableID.yAxisId });
    const group = getAxisData({ axis: groupKey, axisID: allDropableID.groupId }) ?? [];

    const AllAxis = [...xAxis, ...yAxis, ...group];
    // const AllAxis = [...xAxis, ...yAxis];

    const style = AllAxis.map((axis: any, columnDropNumber: number) => {
      const colorNumber = columnDropNumber % defaultLegendColorPalettes.length;
      const newChartToolTip = {
        label: axis?.label,
        originalLabel: axis?.label,
        origin: table,
        show: defaultShow,
        color: defaultLegendColorPalettes[colorNumber],
        opacity: defaultOpacity,
        column: axis.column,
        uniqueColumnName: axis.uniqueColumnName,
        axis: axis.axis,
      };
      return newChartToolTip;
    });

    const valueStyle = AllAxis.map((axis: any, columnDropNumber: number) => {
      if (axis?.filterData?.uniqueValues) {
        const axisWithValues = getUniueValuesFunction({
          uniqueValues: axis?.filterData?.uniqueValues,
          selectedItem: axis,
          defaultShow,
          axis: axis.axis,
        });
        const valuesWithColors = setAutomaticColor({
          target: axisWithValues,
          defaultOpacity,
        });
        return valuesWithColors;
      }
      return [];
    });
    // const isMultipleValue = allChartTypesObj[schema[chartID]?.chartType]?.toolTip?.isMultipleValue
    const tooltip = {
      textStyle: [],
      automaticOpacity: defaultOpacity,
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      type: 'automatic',
      style: style,
      valueStyle: valueStyle.flat(),
    };
    finalSchema = {
      ...finalSchema,
      [chartID]: { ...schema[chartID], tooltip, sheetID, gridLines: defaultGridLines },
    };
  });
  return finalSchema;
};

interface getGroupDataByChartProps {
  stateProps: any;
  chartType: any;
}
export const getGroupDataByChart = ({ stateProps, chartType }: getGroupDataByChartProps) => {
  const {
    //PLOT
    plotGroup,
    setPlotGroup,
    setPlotGroupStyle,

    //HEATMAP
    heatMapGroup,
    setHeatMapGroup,

    //COLOR
    colorAxis,
    setColorAxis,
    setColorAxisStyle,
  } = stateProps;

  //PLOT
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return {
      key: 'plotGroup',
      data: plotGroup,
      setData: setPlotGroup,
      setStyleData: setPlotGroupStyle,
    };
  }

  //HEATMAP
  if (chartType.chartKey === allChartKeys.HEATMAP) {
    return {
      key: 'heatMapGroup',
      data: heatMapGroup,
      setData: setHeatMapGroup,
      setStyleData: setPlotGroupStyle,
    };
  }

  //COLOR
  if (
    chartType.chartKey === allChartKeys.BARCOLOR ||
    chartType.chartKey === allChartKeys.LINECOLOR ||
    chartType.chartKey === allChartKeys.AREACOLOR
  ) {
    return {
      key: 'colorAxis',
      data: colorAxis,
      setData: setColorAxis,
      setStyleData: setColorAxisStyle,
    };
  }
  //Default
  return {
    key: 'colorAxis',
    data: colorAxis,
    setData: setColorAxis,
    setStyleData: setColorAxisStyle,
  };
};
export const getUniueValuesFunction = (props: any) => {
  const { uniqueValues, selectedItem, defaultShow = true, axis } = props;
  if (!uniqueValues) {
    return [];
  }
  const newData = uniqueValues.map((uniqueValues: any) => {
    const newUniqueValues = (elem: any) => {
      switch (elem) {
        case null:
          return 'null';
        case undefined:
          return 'undefined';

        case true:
          return 'true';

        case false:
          return 'false';

        default:
          return uniqueValues;
      }
    };
    const newElem = {
      label: newUniqueValues(uniqueValues),
      originalLabel: newUniqueValues(uniqueValues),
      origin: selectedItem.label,
      show: defaultShow,
      column: selectedItem.column,
      uniqueColumnName: selectedItem.uniqueColumnName,
      axis,
    };
    return newElem;
  });
  return newData;
};

export const setAutomaticColor = (props: any) => {
  const { target, defaultOpacity = 100 } = props;
  let lengthNumber = 0;

  const legendColorPalettesLength = defaultLegendColorPalettes.length;
  const newTargetWithColors = target.map((elem: any) => {
    if (lengthNumber >= legendColorPalettesLength - 1) {
      lengthNumber = 0;
    } else {
      lengthNumber++;
    }
    const newElem = {
      ...elem,
      color: defaultLegendColorPalettes[lengthNumber],
      opacity: defaultOpacity,
    };
    return newElem;
  });

  return newTargetWithColors;
};
