import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Charts from '../charts/Charts';
// import Filter from '../filter/Filter';
// import useDownloadTableSchema from '../../commons/useDownloadTableSchema';
// import useTableSchema from '../../commons/useTableSchema';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import FilterStrip from 'app/filter/FilterStrip';
import { _fetchDataToPlot } from 'lib/server-connection/connections';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { mls } from 'lib/multilanguagesupport';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import useAppState from 'lib/reusable-components/reusableUtils/commons/useAppState';
import ChartSettings from 'app/charts/ChartSettings';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh', // Make the container fill the entire viewport height
  },
};

const fetchData = async (appID, payloadData, endpoint) => {
  const res = await _fetchDataToPlot(payloadData, `${appID}/${endpoint}`);
  if (res.__d3__error !== true) return { response: res.response, error: false };
  else return { error: true };
};

const applyTimeframeFilter = (tableSchema, defaultDatetimeCol) => {
  const defaultDateTimeObj = (tableSchema || []).filter(
    (obj) => obj.tableName + '.' + obj.columnName === defaultDatetimeCol
  );
  if (!defaultDateTimeObj?.[0] || defaultDateTimeObj?.[0].dataType !== 'DATETIME') return {};
  else {
    const filterKey = defaultDateTimeObj[0].tableName + '.' + defaultDateTimeObj[0].columnName;
    return {
      [filterKey]: {
        column: defaultDateTimeObj[0].columnName,
        dataType: defaultDateTimeObj[0].dataType,
        sheetID: defaultDateTimeObj[0].sheetID,
        tableName: defaultDateTimeObj[0].tableName,
        value: {
          period: 'LASTXDAYS',
          xDay: 180,
        },
      },
    };
  }
};

function Sheets() {
  const dispatch = useDispatch();
  const chartSettingsState = useAppState('chartSettings');
  const { appID, projectID, sheetID } = useParams();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];
  const isAccess =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');

  const isReadyForQuery = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.READY_FOR_QUERY
  );
  const sheetSchema = analyticsSchema?.appSheets?.[sheetID] || {};
  const chartsSchema = analyticsSchema?.appCharts || {};
  const defaultDatetimeCol = sheetSchema?.defaultTable?.defaultDatetimeCol || '';
  const sheetName = sheetSchema?.name || '';
  const [filterSchema, setFilterSchema] = useState({});
  const [error, setError] = useState(false);
  const [plotData, setPlotData] = useState({});
  const [timeframe, setTimeframe] = useState(180);
  const [timeFrameObj, setTimeFrameObj] = useState({});
  const [chartUpdates, setChartUpdates] = useState({});
  const [isTableDataFetched, setTableDataFetch] = useState(false);
  const payloadDataRef = useRef({});
  const isNoChart = (sheetSchema?.charts || []).length === 0;
  // Fetching tableData
  useEffect(() => {
    const defaultTable = sheetSchema?.defaultTable || {};
    if (defaultTable._id != null) {
      const payloadData = { ...defaultTable, sheetID };
      fetchData(appID, { defaultTable: payloadData }, 'tableData')
        .then((res) => {
          if (res.error) {
            setError(true);
            setTableData({});
          } else {
            setError(false);
            setTableData(res?.response || {});
          }
        })
        .catch(() => {
          setTableData({});
          setError(true);
        });
    }
  }, [sheetSchema?.defaultTable, sheetID, appID]);

  // fetching Sheet Data
  useEffect(() => {
    if (isTableDataFetched && !isNoChart) {
      const payloadData = { appID, sheetID, filters: filterSchema, timeFrame: timeFrameObj };
      payloadDataRef.current = { ...payloadData, chartUpdates };
      setPlotData({});
      fetchData(appID, payloadData, 'sheets')
        .then((res) => {
          if (res.error) {
            setError(true);
            setPlotData({});
          } else {
            setError(false);
            setPlotData(res?.response?.charts || {});
          }
        })
        .catch(() => {
          setError(true);
          setPlotData({});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appID, filterSchema, timeFrameObj, sheetID, isReadyForQuery, isTableDataFetched]);

  useEffect(() => {
    if (tableData.data) {
      setTimeFrameObj(applyTimeframeFilter(tableData.data, defaultDatetimeCol));
      setTableDataFetch(true);
    }
  }, [tableData, sheetID, defaultDatetimeCol]);

  if (Object.keys(sheetSchema).length === 0) {
    history.push(`/${appID}/sheets`);
    return <></>;
  }

  if (error) {
    dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('There is some error pls try again!'),
          time: 5,
          isActive: true,
        },
      })
    );
    return <></>;
  }

  // if (tableData.data && chartSettingsState.chartID) {
  //   return (
  //     <div>
  //       {chartSettingsState.chartID && (
  //         <ChartSettings
  //           key={`${chartSettingsState.chartID}-settings`}
  //           chartsSchema={chartsSchema}
  //           chartID={chartSettingsState.chartID}
  //           chartData={chartsSchema[chartSettingsState.chartID]}
  //           sheetID={sheetID}
  //           appID={appID}
  //           tableSchema={tableData.data || []}
  //           isNewChart={!(chartSettingsState.chartID in chartsSchema)}
  //           defaultDatetimeCol={defaultDatetimeCol}
  //           tableID={sheetSchema?.defaultTable?.tableID || ''}
  //         />
  //       )}
  //     </div>
  //   );
  // }

  if (isNoChart) {
    dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('Please add a chart!'),
          time: 5,
          isActive: true,
        },
      })
    );

    if (tableData.data && chartSettingsState.chartID) {
      return (
        <ChartSettings
          key={`${chartSettingsState.chartID}-settings`}
          chartsSchema={chartsSchema}
          chartID={chartSettingsState.chartID}
          chartData={chartsSchema[chartSettingsState.chartID]}
          sheetID={sheetID}
          appID={appID}
          tableSchema={tableData.data || []}
          isNewChart={!(chartSettingsState.chartID in chartsSchema)}
          defaultDatetimeCol={defaultDatetimeCol}
          tableID={sheetSchema?.defaultTable?.tableID || ''}
        />
      );
    }

    return <NoAssetScreen assetType='Chart' />;
  }

  if (!tableData.data || Object.keys(plotData || {}).length === 0) {
    return (
      <div style={styles.container}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <FilterStrip
        appID={appID}
        sheetID={sheetID}
        tableSchema={tableData.data || []}
        sheetSchema={sheetSchema || {}}
        filterSchema={filterSchema}
        setFilterSchema={setFilterSchema}
        isJoinedTable={tableData.isJoinedTable}
        defaultDatetimeCol={defaultDatetimeCol}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        timeFrameObj={timeFrameObj}
        setTimeFrameObj={setTimeFrameObj}
        payloadData={payloadDataRef.current}
        sheetName={sheetName}
      />
      <Charts
        projectID={projectID}
        sheetID={sheetID}
        appID={appID}
        sheetSchema={sheetSchema || {}}
        chartsSchema={chartsSchema}
        projectsSchema={analyticsSchema?.appProject || {}}
        filterSchema={filterSchema}
        setFilterSchema={setFilterSchema}
        tableSchema={tableData.data || []}
        plotData={plotData}
        setPlotData={setPlotData}
        chartUpdates={chartUpdates}
        setChartUpdates={setChartUpdates}
        isAccess={isAccess}
        timeFrameObj={timeFrameObj}
      />
      {tableData.data && chartSettingsState.chartID && (
        <ChartSettings
          key={`${chartSettingsState.chartID}-settings`}
          chartsSchema={chartsSchema}
          chartID={chartSettingsState.chartID}
          chartData={chartsSchema[chartSettingsState.chartID]}
          sheetID={sheetID}
          appID={appID}
          tableSchema={tableData.data || []}
          isNewChart={!(chartSettingsState.chartID in chartsSchema)}
          defaultDatetimeCol={defaultDatetimeCol}
          tableID={sheetSchema?.defaultTable?.tableID || ''}
        />
      )}
    </>
  );
}

export default Sheets;
