import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ToolTipDiv from './ToolTipDiv';
import { allDropableID } from '../../data/defaultChartSchema';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const DragableToolTip = ({ dragableDivId, stateProps, handleResetToolTip }) => {
  const { chartToolTip, chartType } = stateProps;

  return (
    <>
      <Droppable droppableId={dragableDivId} key={dragableDivId}>
        {(provided) => (
          <div className='grayContainers' {...provided.droppableProps} ref={provided.innerRef}>
            {React.Children.toArray(
              chartToolTip?.style.map((elm, i) => {
                if (
                  chartType.chartKey === allChartKeys.RADAR
                ) {
                  if (elm.axis === allDropableID.xAxisId) {
                    return <></>;
                  }
                }
                return (
                  <Draggable key={`${i}`} draggableId={`${dragableDivId + i}`} index={i}>
                    {(provided) => (
                      <>
                        <ToolTipDiv
                          elm={elm}
                          index={i}
                          show={false}
                          provided={provided}
                          stateProps={stateProps}
                        />
                      </>
                    )}
                  </Draggable>
                );
              })
            )}
            {provided.placeholder}
            {chartToolTip?.style[0] !== undefined ? (
              <div className='resetDefault' onClick={() => handleResetToolTip()}>
                {mls("Reset to defaults")}
              </div>
            ) : null}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default DragableToolTip;
