export const dataTableColumnTypes = {
  text: 'Text',
  number: 'Number',
  decimal: 'Decimal',
  boolean: 'Boolean',
  check: 'Checkbox',
  select: 'Select',
  multiple: 'Multiple',
  // datePicker: 'DatePicker',
  id_text: 'ID Text',
  id_num: 'ID Num',
  date: 'Date',
};

export const connectionTypes = {
  __d3__postgres: 'PostgreSQL',
  __d3__mongoDB: 'MongoDB',
  __d3__mysql: 'MySQL',
  __d3__googleSheets: 'Google Sheets',
  __d3__mssql: 'Microsoft SQL (Azure DB)',
  __d3__excelOnedrive: 'Excel file on Onedrive',
};

export const intervals = {
  '1h': '1 hour',
  '2h': '2 hours',
  '4h': '4 hours',
  '6h': '6 hours',
  '8h': '8 hours',
  '12h': '12 hours',
  '18h': '18 hours',
  '1d': '1 day',
};

export const charactersNotAllowedInTableAndColumnName = [
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '.',
  '/',
  '@',
  '^',
  '=',
  ';',
  ':',
  '{',
  '}',
  '[',
  ']',
];
