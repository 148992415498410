import React from 'react';
import ChartBuild from '../../components/BuildComponents/ChartBuild';
import ChartStyle from '../../components/StyleComponents/ChartStyle';
import WithStyleToggle from './WithStyleToggle';

const NormalCase = ({ stateProps, buddleProps, toggleProps }) => {
  const { settingToggleState } = buddleProps;

  return (
    <>
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <ChartBuild stateProps={stateProps} />}
        {settingToggleState === 'style' && <ChartStyle stateProps={stateProps} />}
      </WithStyleToggle>
    </>
  );
};

export default NormalCase;
