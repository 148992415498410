import { DragDropContext } from 'react-beautiful-dnd';
import { allDropableID } from '../../data/defaultChartSchema';
import {
  changeDestinationFunction,
  changeDestinationTableFunction,
  sameDestinationFunction,
} from '../../utils/dragableFunctions';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { getGroupDataByChart } from 'lib/chart-settings-lib/utils/chartHelperFunctions';

const DragableWrapper = ({ children, stateProps, allTableData }) => {
  const {
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    tablesData,
    setTablesData,
    chartToolTip,
    setChartToolTip,
    toolTipAxisData,
    setToolTipAxisData,
    setChartXAxisStyle,
    setChartYAxisStyle,

    chartType,
  } = stateProps;

  const { xAxisId, yAxisId, tableId, toolTip, groupId, tooltipAxisId } = allDropableID;
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const { source, destination } = result;
    // if source and destination are not same
    if (source.droppableId !== destination.droppableId) {
      // if source is xAxisId
      if (source.droppableId === xAxisId) {
        const sourceData = xAxisData;
        const setSourceData = setXAxisData;
        // yAxisId
        if (destination.droppableId === yAxisId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: yAxisData,
            setDestinationData: setYAxisData,
            result,
            setSourceStyle: setChartXAxisStyle,
            setDestinationStyle: setChartYAxisStyle,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // groupId
        if (destination.droppableId === groupId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: getGroupDataByChart({ stateProps, chartType }).data,
            setDestinationData: getGroupDataByChart({ stateProps, chartType }).setData,
            result,
            setSourceStyle: setChartXAxisStyle,
            setDestinationStyle: getGroupDataByChart({ stateProps, chartType }).setStyleData,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
      }
      // if source is yAxisId
      if (source.droppableId === yAxisId) {
        const sourceData = yAxisData;
        const setSourceData = setYAxisData;
        // xAxisId
        if (destination.droppableId === xAxisId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: xAxisData,
            setDestinationData: setXAxisData,
            result,
            setSourceStyle: setChartYAxisStyle,
            setDestinationStyle: setChartXAxisStyle,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // groupId
        if (destination.droppableId === groupId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: getGroupDataByChart({ stateProps, chartType }).data,
            setDestinationData: getGroupDataByChart({ stateProps, chartType }).setData,
            result,
            setSourceStyle: setChartYAxisStyle,
            setDestinationStyle: getGroupDataByChart({ stateProps, chartType }).setStyleData,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
      }
      // if source is groupId
      if (source.droppableId === groupId) {
        const sourceData = getGroupDataByChart({ stateProps, chartType }).data;
        const setSourceData = getGroupDataByChart({ stateProps, chartType }).setData;
        // xAxisId
        if (destination.droppableId === xAxisId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: xAxisData,
            setDestinationData: setXAxisData,
            result,
            setSourceStyle: getGroupDataByChart({ stateProps, chartType }).setStyleData,
            setDestinationStyle: setChartXAxisStyle,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // yAxisId
        if (destination.droppableId === yAxisId) {
          changeDestinationFunction({
            sourceData,
            setSourceData,
            destinationData: yAxisData,
            setDestinationData: setYAxisData,
            result,
            setSourceStyle: getGroupDataByChart({ stateProps, chartType }).setStyleData,
            setDestinationStyle: setChartYAxisStyle,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
      }

      // if source is tableId
      if (source.droppableId.match(tableId)) {
        const sourceData = tablesData;
        const setSourceData = setTablesData;
        let tableIndex = Number(source.droppableId.replace(tableId, ''));
        // const sourceData = yAxisData
        // const setSourceData = setYAxisData

        // xAxisId
        if (destination.droppableId === xAxisId) {
          changeDestinationTableFunction({
            sourceData,
            setSourceData,
            destinationData: xAxisData,
            setDestinationData: setXAxisData,
            setDestinationStyle: setChartXAxisStyle,
            result,
            tableIndex,
            table: true,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // yAxisId
        if (destination.droppableId === yAxisId) {
          changeDestinationTableFunction({
            sourceData,
            setSourceData,
            destinationData: yAxisData,
            setDestinationData: setYAxisData,
            setDestinationStyle: setChartYAxisStyle,
            result,
            tableIndex,
            table: true,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // groupId
        if (destination.droppableId === groupId) {
          changeDestinationTableFunction({
            sourceData,
            setSourceData,
            destinationData: getGroupDataByChart({ stateProps, chartType }).data,
            setDestinationData: getGroupDataByChart({ stateProps, chartType }).setData,
            setDestinationStyle: getGroupDataByChart({ stateProps, chartType }).setStyleData,
            result,
            tableIndex,
            table: true,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
        // tooltipAxisId
        if (destination.droppableId === tooltipAxisId) {
          changeDestinationTableFunction({
            sourceData,
            setSourceData,
            destinationData: toolTipAxisData,
            setDestinationData: setToolTipAxisData,
            setDestinationStyle: () => {},
            // setDestinationStyle: setChartYAxisStyle,
            result,
            tableIndex,
            table: true,
            //New
            destinationID: destination.droppableId,
            sourceID: source.droppableId,
            stateProps,
          });
        }
      }
      // if source and destination are same
    } else {
      // if source is xAxisId
      if (source.droppableId === xAxisId) {
        sameDestinationFunction({
          sourceData: xAxisData,
          setSourceData: setXAxisData,
          result,
          destinationID: allDropableID.xAxisId,

          stateProps,
        });
      }
      // if source is yAxisId
      if (source.droppableId === yAxisId) {
        sameDestinationFunction({
          sourceData: yAxisData,
          setSourceData: setYAxisData,
          result,
          destinationID: allDropableID.yAxisId,

          stateProps,
        });
      }
      // if source is tableId
      if (source.droppableId.match(tableId)) {
        // let tableIndex = Number(source.droppableId.replace(tableId,''));
        //     sameDestinationFunction({
        //         sourceData:tablesData,
        //         setSourceData:setTablesData,
        //         result ,
        //         tableIndex,
        // destinationID:allDropableID.tableId,
        // })
      }

      // if source is toolTip
      if (source.droppableId === toolTip) {
        sameDestinationFunction({
          sourceData: chartToolTip,
          setSourceData: setChartToolTip,
          result,
          destinationID: allDropableID.toolTip,
          stateProps,
        });
      }
      // if source is toolTip
      if (source.droppableId === tooltipAxisId) {
        sameDestinationFunction({
          sourceData: toolTipAxisData,
          setSourceData: setToolTipAxisData,
          result,
          destinationID: allDropableID.tooltipAxisId,
          stateProps,
        });
      }
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>{children}</DragDropContext>
    </>
  );
};

export default DragableWrapper;
