// import generateUUID from './generateUUID';
import schemaConstants from './schemaConstants';

// function getProjectID(props) {
//   return props?.projectID || generateUUID();
// }

// function getSheetID(props) {
//   return props?.sheetID || generateUUID();
// }

// function getChartID(props) {
//   return props?.chartID || generateUUID();
// }

// function getPlotID(props) {
//   return props?.plotID || generateUUID();
// }

export default function getSchemaLocation(type, props) {
  switch (type) {
    case schemaConstants.type.SHEET:
      return `appSheets`;
    case schemaConstants.type.CHART:
      return `appCharts`;
    case schemaConstants.type.DASH:
      return 'appDash';
    case schemaConstants.type.REPORT:
      return 'appReports';
    case schemaConstants.type.TEAM:
      return 'appTeams'
    // case schemaConstants.type.PLOT:
    //   return (`projectsSchema.${getProjectID(props)}.sheets.${getSheetID(props)}.charts.${getChartID(props)}.plots.${getPlotID(props)}`);
    default:
      return null;
  }
}
