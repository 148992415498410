import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';

import React from 'react';
import './formatter.scss';
import {
  currencyList,
  defaultFormattingValue,
  defaultFormattingType,
} from 'lib/reusable-components/reusableData/formatterData';
import { mls } from 'lib/multilanguagesupport';
interface NumberFormatterCompProps {
  formatter: any;
  setFormatter: Function;
  short?: boolean;
  divBorder?: boolean;
}
const NumberFormatterComp = ({
  formatter,
  setFormatter,
  short = false,
  divBorder = true,
}: NumberFormatterCompProps) => {
  const handleFormat = (e: string) => {
    const formatData = defaultFormattingType.num[e as keyof typeof defaultFormattingType.num];
    const updatedFormatter = {
      ...formatter,
      format: e,
      currencyType: formatData?.currency,
      compact: formatData?.defaultCompact,
    };
    setFormatter(updatedFormatter);
  };
  const handleCompact = (e: boolean) => {
    const updatedFormatter = {
      ...formatter,
      compact: e,
    };

    setFormatter(updatedFormatter);
  };
  const handleCurrency = (e: boolean) => {
    const updatedFormatter = {
      ...formatter,
      currency: e,
      formatterType: 'number',
    };
    setFormatter(updatedFormatter);
  };
  const handleCurrencyType = (e: boolean) => {
    const updatedFormatter = {
      ...formatter,
      currencyType: e,
      formatterType: 'number',
    };

    setFormatter(updatedFormatter);
  };
  const handleDecimal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }
    const updatedFormatter = {
      ...formatter,
      maxDecimal: Number(e.target.value),
      formatterType: 'number',
    };
    setFormatter(updatedFormatter);
  };
  return (
    <div
      className={
        short || !divBorder
          ? 'formatterDivContainer'
          : 'formatterDivContainer formatterDivWithBorder'
      }
    >
      {!short && divBorder ? <div className='titleDiv'>&nbsp;{mls('Number Format')}</div> : null}
      <ObjectDropDown
        object={defaultFormattingType.num}
        dropDownValue={formatter?.format ?? defaultFormattingValue.num.key}
        setdropDownValue={handleFormat}
        ObjectInObject={true}
      />
      <div className='formatterToggleContainer'>
        {!short ? (
          <div className='formatterToggles'>
            <div className='titleDiv'>&nbsp;{mls('Compact')}:</div>
            <SlideSwitch
              isChecked={formatter?.compact ?? defaultFormattingValue.num.defaultCompact}
              setIsChecked={handleCompact}
              type={'small'}
            />
          </div>
        ) : null}
        <div className='formatterToggles'>
          <div className='titleDiv'>&nbsp;{mls('Currency')}:</div>
          <SlideSwitch
            isChecked={formatter?.currency ?? defaultFormattingValue.num.defaultCurrency}
            setIsChecked={handleCurrency}
            type={'small'}
          />
        </div>
        {formatter?.currency ?? defaultFormattingValue.num.defaultCurrency ? (
          <ObjectDropDown
            object={currencyList}
            dropDownValue={formatter?.currencyType ?? defaultFormattingValue.num.currencyType}
            setdropDownValue={handleCurrencyType}
            ObjectInObject={true}
            mainKey={'code'}
            search
          />
        ) : null}
      </div>
      {!short ? (
        <>
          <div className='titleDiv'>&nbsp;{mls('Trailing Places')}</div>
          <input
            type='number'
            className='primaryInput'
            max={4}
            min={0}
            value={formatter?.maxDecimal ?? defaultFormattingValue.num.maxDecimal}
            onChange={(e) => handleDecimal(e)}
          />
        </>
      ) : null}
    </div>
  );
};

export default NumberFormatterComp;
