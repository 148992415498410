import React, { useEffect } from 'react';
import { defaultOperation, allDropableID, defaultDataType } from '../../data/defaultSchema';

import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { useDispatch, useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { deleteDateTimeToggle } from 'lib/reusable-components/reusableFunction/formatter';
const actions = {
  // {
  //     name:"Rename",
  //     key:"RENAME",
  // },
  moveUp: {
    name: 'Move Up',
    key: 'MOVEUP',
  },
  moveDown: {
    name: 'Move Down',
    key: 'MOVEDOWN',
  },
  remove: {
    name: 'Remove',
    key: 'REMOVE',
  },
  // {
  //     name:"Move To",
  //     key:"MOVETO",
  // },
};
const AxisOpration = ({ props }) => {
  const {
    elem,
    removeData,
    index,
    axisId,
    stateProps,
    axisData,
    setAxisData,
    handleDropDown,
    isDropDownOpen,
    setIsPctByGroupPopUp,
    dragableItemRef,
  } = props;

  const { chartType, setChartToolTip, chartID } = stateProps;

  const handleSelection = (key, index) => {
    switch (key) {
      case actions.moveUp.key: {
        if (index !== 0) {
          const newIndex = index - 1;
          const items = Array.from(axisData);
          const [reorderedItem] = items.splice(index, 1);
          items.splice(newIndex, 0, reorderedItem);
          setAxisData(items);
        } else {
          return;
        }
        break;
      }

      case actions.moveDown.key:
        if (index !== axisData.length - 1) {
          const newIndex = index + 1;
          const items = Array.from(axisData);
          const [reorderedItem] = items.splice(index, 1);
          items.splice(newIndex, 0, reorderedItem);
          setAxisData(items);
        } else {
          return;
        }

        break;

      case actions.remove.key:
        removeData(index, axisId);

        break;

      case defaultOperation.num.PCT_BY_GROUP.key:
        setIsPctByGroupPopUp((currentData) => !currentData);
        setAxisData((currentData) => {
          const items = Array.from(currentData);
          const [reorderedItem] = items.splice(index, 1);
          const newSourceItem = {
            ...reorderedItem,
            operations: { ...reorderedItem.operations, type: key },
          };
          setChartToolTip((currentToolTip) => {
            const newStyle = currentToolTip.style.map((styleElem) => {
              if (styleElem.uniqueColumnName === reorderedItem.uniqueColumnName) {
                return { ...styleElem, label: `${styleElem.originalLabel} ( ${key} )` };
              }
              return styleElem;
            });
            return { ...currentToolTip, style: newStyle };
          });
          items.splice(index, 0, newSourceItem);
          return items;
        });
        break;

      default:
        setAxisData((currentData) => {
          const items = Array.from(currentData);
          const [reorderedItem] = items.splice(index, 1);
          const newSourceItem = {
            ...reorderedItem,
            operations: { type: key },
          };
          setChartToolTip((currentToolTip) => {
            const newStyle = currentToolTip.style.map((styleElem) => {
              if (styleElem.uniqueColumnName === reorderedItem.uniqueColumnName) {
                return { ...styleElem, label: `${styleElem.originalLabel} ( ${key} )` };
              }
              return styleElem;
            });
            return { ...currentToolTip, style: newStyle };
          });
          items.splice(index, 0, newSourceItem);
          return items;
        });
        break;
    }
    handleDropDown(false);
  };
  const chartKey = () => {
    if (axisId === allDropableID.xAxisId) {
      return chartType.xAxis[0];
    }
    if (axisId === allDropableID.yAxisId) {
      return chartType.yAxis[0];
    }
    if (axisId === allDropableID.groupId) {
      return chartType.group[0];
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dragableItemRef.current && !dragableItemRef.current.contains(event.target)) {
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragableItemRef, isDropDownOpen]);
  return (
    <>
      <div className={isDropDownOpen ? 'popUpOptions open' : 'popUpOptions'}>
        <ul>
          <li
            className={index === 0 ? 'disable' : undefined}
            onClick={() => {
              handleSelection(actions.moveUp.key, index);
            }}
          >
            {actions.moveUp.name}
          </li>
          <li
            className={index === axisData.length - 1 ? 'disable' : undefined}
            onClick={() => handleSelection(actions.moveDown.key, index)}
          >
            {actions.moveDown.name}
          </li>
          <li onClick={() => handleSelection(actions.remove.key, index)}>{actions.remove.name}</li>
          <OprationsArray
            elem={elem}
            handleSelection={handleSelection}
            chartKey={chartKey}
            index={index}
            chartType={chartType}
            chartID={chartID}
          />
        </ul>
      </div>
    </>
  );
};

export default AxisOpration;

const OprationsArray = ({ elem, handleSelection, chartKey, index, chartType, chartID }) => {
  const dispatch = useDispatch();
  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return <></>;
  }
  if (chartKey() === defaultDataType.CAT && defaultDataType.DATETIME === elem.originalDataType) {
    const handleDateTime = (key, index) => {
      handleSelection(key, index);
      deleteDateTimeToggle({
        toggle: timeFrameData?.toggle,
        chartID,
        dispatch,
      });
    };
    return (
      <>
        <hr />
        {React.Children.toArray(
          Object.entries(defaultOperation.dateTime).map(([key, operationElem]) => {
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleDateTime(operationElem?.key, index)}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
      </>
    );
  }

  return (
    <>
      {chartKey() === defaultDataType.NUM && <hr />}
      {chartKey() === defaultDataType.NUM &&
        defaultDataType.NUM === elem.originalDataType &&
        React.Children.toArray(
          Object.entries(defaultOperation.num).map(([key, operationElem]) => {
            if (
              (chartType.chartKey === allChartKeys.CARD ||
                chartType.chartKey === allChartKeys.KPIMETER ||
                chartType.chartKey === allChartKeys.KPITRACKING) &&
              (operationElem.key === defaultOperation.num.PCT_BY_GROUP.key ||
                operationElem.key === defaultOperation.num.percentOfGrandTotal.key)
            ) {
              return <></>;
            }
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleSelection(operationElem.key, index)}
                title={operationElem.name}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
      {chartKey() === defaultDataType.NUM &&
        defaultDataType.NUM !== elem.originalDataType &&
        React.Children.toArray(
          Object.entries(defaultOperation.cat).map(([key, operationElem]) => {
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleSelection(operationElem.key, index)}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
    </>
  );
};
