import { mls } from 'lib/multilanguagesupport';
import React, { useEffect, useRef, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { defaultDataByTableType } from './tableCustomColumn/data/sqlCustomCommandsData';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import WarningPopup from 'lib/reusable-components/Components/WarningPopup/WarningPopup';
interface pageHeaderProps {
  pagesList: any[];
  setPagesList: Function;
  pageCount: number;
  setPageCount: Function;
  selectedPageNumber: number;
  setSelectedPageNumber: Function;
  selectedTable: any;
}
const PageHeader = ({
  pagesList,
  setPagesList,
  pageCount,
  setPageCount,
  selectedPageNumber,
  setSelectedPageNumber,
  selectedTable,
}: pageHeaderProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [srollInfo, setSrollInfo] = useState({
    isOverflowing: false,
    canScrollLeft: false,
    canScrollRight: false,
  });

  const checkOverflow = () => {
    const div = scrollRef.current;
    if (div) {
      const hasOverflow = div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
      if (hasOverflow) {
        setSrollInfo({
          isOverflowing: hasOverflow,
          canScrollLeft: div.scrollLeft > 0,
          canScrollRight: div.scrollLeft < div.scrollWidth - div.clientWidth - 1,
        });
      } else {
        setSrollInfo({
          isOverflowing: hasOverflow,
          canScrollLeft: false,
          canScrollRight: false,
        });
      }
    }
  };

  useEffect(() => {
    const div = scrollRef.current;
    if (div) {
      checkOverflow();
      div.addEventListener('scroll', checkOverflow);
      window.addEventListener('resize', checkOverflow);

      return () => {
        div.removeEventListener('scroll', checkOverflow);
        window.removeEventListener('resize', checkOverflow);
      };
    }
  }, []);

  useEffect(() => {
    checkOverflow();
  }, [scrollRef, pagesList]);
  const scrollLeft = () => {
    const div = scrollRef.current;
    if (div) {
      div.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };
  const scrollRight = (defaultScroll = 100) => {
    const div = scrollRef.current;
    if (div) {
      div.scrollLeft += defaultScroll; // Adjust the scroll amount as needed
    }
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    const div = scrollRef.current;
    if (div) {
      // Prevent default vertical scroll
      // event.preventDefault();
      // Scroll horizontally
      div.scrollLeft += event.deltaY;
    }
  };

  const addPage = () => {
    // if (!selectedTable.tableID) {
    //   toast.warn(mls('Please select any table'));
    //   return;
    // }

    const newPage = {
      name: `Report ${pageCount + 1}`,
      id: uuidv4(),
      tableID: '',
      sqlQuery: defaultDataByTableType.customColumn.defaultQuery,
      timeFrame: 'THISMONTH',
      datetimeColumn: '',
    };
    setPagesList([...pagesList, newPage]);
    setPageCount(pageCount + 1);
    setSelectedPageNumber(pagesList.length);
    setTimeout(() => {
      scrollRight(500);
    }, 1);
  };
  const updatePage = ({ pageNumber }: { pageNumber: number }) => {
    setSelectedPageNumber(pageNumber);
  };
  const optionObject = {
    // edit: {
    //   key: 'edit',
    //   name: 'Edit',
    // },
    remove: {
      key: 'remove',
      name: 'Remove',
    },
  };
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const removePage = () => {
    const pagesListCopy = Array.from(pagesList);
    pagesListCopy.splice(selectedPageNumber, 1);
    setPagesList(pagesListCopy);
    if (selectedPageNumber > 0) {
      setSelectedPageNumber(selectedPageNumber - 1);
    }
  };
  const handleOptionDropdown = ({
    action,
    index,
  }: // data,
  {
    action: any;
    // data: { [key: string]: any };
    index: number;
  }) => {
    if (optionObject.remove.key === action) {
      if (selectedTable.tableID) {
        setIsRemovePopup(true);
        return;
      }
      removePage();
    }
  };
  const handleRemovePagePopup = (action: boolean) => {
    if (action) {
      removePage();
    }
    setIsRemovePopup(false);
  };
  return (
    <div className='pageHeader'>
      <WarningPopup
        initialValue={isRemovePopup}
        setConfirmation={handleRemovePagePopup}
        titleText={`${mls('Are you sure you want to remove this report.')} ?`}
        discText={mls(
          '*Removing this report will undo all the changes you have made, including the SQL query.'
        )}
      />
      <div className='pagesTitle'> {mls('Report')}:</div>

      <div className='addPages' onClick={() => addPage()}>
        <div className='addPagesButton'>
          <FiPlus />
        </div>
      </div>
      <div className='dividerDiv'>
        <hr />
      </div>
      <div className='pagesScrollOuter' ref={scrollRef} onWheel={handleWheel}>
        {React.Children.toArray(
          pagesList.map((pagesListElem, i) => {
            const isSelected = selectedPageNumber === i;
            const isLast = pagesList.length === 1;
            if (isSelected) {
              return (
                <>
                  <div
                    className={`pagesScrollElem ${isSelected ? 'selectedPage' : ''}`}
                    onClick={() => updatePage({ pageNumber: i })}
                  >
                    <b className='left-curve'></b>
                    {mls(pagesListElem.name)}
                    {!isLast ? (
                      <div
                        className='selectedPageOptions'
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ObjectDropDown
                          object={optionObject}
                          dropDownValue={''}
                          setdropDownValue={(action: any) =>
                            handleOptionDropdown({
                              action,
                              index: i,
                            })
                          }
                          ObjectInObject
                          className='selectedPageOptionsDiv'
                        ></ObjectDropDown>
                      </div>
                    ) : null}
                    <b className='right-curve'></b>
                  </div>
                </>
              );
            }
            return (
              <>
                <div
                  className={`pagesScrollElem ${isSelected ? 'selectedPage' : ''}`}
                  onClick={() => updatePage({ pageNumber: i })}
                >
                  <b className='left-curve'></b>
                  {mls(pagesListElem.name)}
                  <b className='right-curve'></b>
                </div>
                <div className='dividerDiv'>
                  <hr />
                </div>
              </>
            );
          })
        )}
      </div>
      {srollInfo.isOverflowing ? (
        <div className='scrollButoonDivContainer'>
          <div
            className={`scrollButoonDiv ${srollInfo.canScrollLeft ? '' : 'scrollButoonDivDisable'}`}
            onClick={() => srollInfo.canScrollLeft && scrollLeft()}
          >
            <FaAngleLeft />
          </div>
          <div
            className={`scrollButoonDiv ${
              srollInfo.canScrollRight ? '' : 'scrollButoonDivDisable'
            }`}
            onClick={() => srollInfo.canScrollRight && scrollRight()}
          >
            <FaAngleRight />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PageHeader;
