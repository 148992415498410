import { connectionTypes } from 'lib/data-connectors/dependencies/constant';
import { mls } from 'lib/multilanguagesupport';
import './connectionCard.scss';
import DataLakeCard from 'lib/data-connectors/dependencies/DataLakeCard/DataLakeCard';
import { useMemo } from 'react';
import { newConnectionFormsSchema } from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import { getfileExtensionData } from './EditConnections/GetFileds/filedsUtils';
const ConnectionCard = ({
  title,
  connType,
  appSchema,
  credentialID,
  openEditConnection,
  openConnection,
  connectionData,
  description,
}) => {
  const numberOfImport = useMemo(
    () =>
      getNumberOfImports({
        credentialID: credentialID,
        appDatatable: appSchema.appDatatable,
      }),
    [appSchema, credentialID]
  );

  const getDefaultConnectionGroup = (connType) => {
    let connectionGroup = newConnectionFormsSchema.upload.type.uploadFile.accept.csv;

    Object.keys(newConnectionFormsSchema).some((groupKey) => {
      const groupElem = newConnectionFormsSchema[groupKey];
      const isFind = Object.keys(groupElem.type).some((groupTypeKey) => {
        const groupTypeElem = groupElem.type[groupTypeKey];
        if (groupTypeElem.type === connType) {
          connectionGroup = groupTypeElem;
          return true;
        }
        return false;
      });
      return isFind;
    });

    return connectionGroup;
  };
  const group = getDefaultConnectionGroup(connType);

  const getGroup = (group) => {
    if (group.key === newConnectionFormsSchema.upload.type.uploadFile.key) {
      const exenstionData = getfileExtensionData({
        fileName: connectionData?.data?.fileLable ?? connectionData?.data?.fileName,
        extensionObject: group.accept,
      });
      return exenstionData;
    }
    return group;
  };

  const selectedGroup = getGroup(group);
  return (
    <DataLakeCard
      settingButtonFunction={openEditConnection}
      titleButtonFunction={openEditConnection}
      title={title}
      // index={index}
      backGroundImage={false}
      bottomButtonFunction={openConnection}
      bottomButtonTitle={'Import Tables'}
      description={description}
    >
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
        <strong>{mls('Connection Type')}: </strong>&nbsp;
        <span>
          <img src={selectedGroup.icon} alt='' style={{ width: '1.25rem' }} />
          &nbsp; {mls(connectionTypes?.[connType] ?? selectedGroup?.name ?? '')}{' '}
        </span>
      </p>
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'></p>
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
        <strong>{mls('Table Imported')}: </strong>
        {numberOfImport}
      </p>
    </DataLakeCard>
  );
};

export default ConnectionCard;

const getNumberOfImports = ({ credentialID, appDatatable }) => {
  let numberOfImport = 0;
  Object.values(appDatatable || {}).forEach((appDataTableElem) => {
    if (appDataTableElem.credentialID === credentialID) {
      numberOfImport = ++numberOfImport;
    }
  });
  return numberOfImport;
};
