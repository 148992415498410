import DataLakeSearch from 'lib/data-connectors/dependencies/DataLakeSearch/DataLakeSearch';
import React, { useState } from 'react';
import CopyColumnToClipBoard, {
  CopyToClipBoard,
} from '../../../CopyColumnToClipBoard/CopyColumnToClipBoard';
import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';
import { numberToChar } from 'lib/data-connectors/dependencies/utilFunctions';
import { IconButton } from '@mui/material';
import { RiTableFill } from 'react-icons/ri';

interface tableCardProps {
  columnSchema: any[];
  className?: string;
  mainTitle?: string;
  subTitle?: string;
  tableID: string;
  setTableHeadDisplayTableData: Function;
  tableData: any;
  tableIndex: number;
}
const TableDataCard = ({
  columnSchema,
  className,
  mainTitle,
  subTitle,
  tableID,
  setTableHeadDisplayTableData,
  tableData,
  tableIndex = 0,
}: tableCardProps) => {
  const [filteredcolumnSchema, setFilteredColumnSchema] = useState(columnSchema);
  const setSearch = (filteredObject: any) => {
    const finalObject = [...Object.values(filteredObject)];
    setFilteredColumnSchema(finalObject);
  };

  return (
    <div className={`p-5 mt-3 border rounded ${className}`}>
      {mainTitle ? (
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '.5rem' }}>
          <div style={{ width: 'calc( 100% - 4rem)' }}>
            {/* <CopyToClipBoard text={`__D3__TABLE__`}> */}
            <CopyToClipBoard text={`__D3__TABLE__${numberToChar({ number: tableIndex })}`}>
              <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
              &nbsp;
              <label className='form-label'>
                __D3__TABLE__{numberToChar({ number: tableIndex })}
              </label>
            </CopyToClipBoard>
          </div>
          <div style={{}}>
            <IconButton
              className='openTableButton'
              onClick={() => setTableHeadDisplayTableData({ status: true, tableID })}
            >
              <RiTableFill />
            </IconButton>
          </div>
        </div>
      ) : null}
      {subTitle ? (
        <>
          <br />
          <label className='form-label  mt-5 '>{mls(subTitle)}</label>
        </>
      ) : null}
      <DataLakeSearch
        objectForSearch={columnSchema ?? []}
        setSearch={setSearch}
        fullWidth
        placeholder={mls('Search Columns')}
      />
      <div className='w-90 mx-auto'>
        <div className='row mt-5'>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-7 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        {(filteredcolumnSchema || []).map((tableCol: any, ix: number) => {
          if (tableCol.disabledColumn) {
            return <></>;
          }
          return (
            <div className='row my-5' key={ix}>
              <div className='col-5 d-flex align-items-center justify-content-left'>
                <CopyColumnToClipBoard columnName={tableCol.columnName} tableIndex={tableIndex}>
                  {tableCol?.name ?? tableCol?.columnName}
                </CopyColumnToClipBoard>
              </div>
              <div className='col-7 d-flex align-items-center justify-content-left'>
                <input
                  placeholder={mls('Enter Join table name')}
                  value={tableCol.dataType}
                  className={clsx('form-control form-control-solid')}
                  disabled
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableDataCard;
