import React, { useState } from 'react';
import CustomTooltip, { ToolTipDivWrapper, ToolTipInitiator } from './CustomTooltip';
import { mls } from 'lib/multilanguagesupport';

const componentName = 'ChartTitleDiscriptionToolTip';

interface CustomTooltipProps {
  data: any;
}

export const ChartTitleDiscriptionToolTip = ({ data }: CustomTooltipProps) => {
  const [divPostion, setDivPostion] = useState({ x: 0, y: 0, show: false });

  return (
    <CustomTooltip
      divPostion={divPostion}
      setDivPostion={setDivPostion}
      componentName={componentName}
    >
      <TitleDescriptionToolTip data={data} divPostion={divPostion} />
    </CustomTooltip>
  );
};
export const ChartTitleDescriptionInitiator = () => {
  return <ToolTipInitiator componentName={componentName} />;
};

interface ToolTipComponentProps {
  data: any;
  divPostion: {
    x: number;
    y: number;
    show: boolean;
  };
  spacial?: boolean;
}

const TitleDescriptionToolTip = ({ data }: ToolTipComponentProps) => {
  return (
    <>
      <div style={title}>{data?.title}</div>
      {data?.warning === undefined ||
      data?.warning === null ||
      data?.warning?.trim() === '' ? null : (
        <div style={warning}>{data?.warning}</div>
      )}
      <div style={description}>{data?.description}</div>
    </>
  );
};

const title = {
  fontSize: '1rem',
  fontWeight: 500,
};
const description = {
  fontSize: '.8rem',
};
const warning = {
  fontSize: '.8rem',
  color: '#bfd400',
};
