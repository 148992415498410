import React, { useRef, useState } from 'react';
import { BsInfoLg } from 'react-icons/bs';
import CustomTooltip, { ToolTipInitiator } from './CustomTooltip';

type Timer = ReturnType<typeof setTimeout>;
const defaultData = {
  title: 'test',
  description: 'Description',
};
const componentName = 'InfoToolTipDivToolTip';

interface infoToolTipProps {
  className?: string;
  data?: {
    title: string;
    description: string;
  };
  icon?: boolean;
  children?: React.ReactNode;
}
const InfoToolTip = (infoToolTipProps: infoToolTipProps) => {
  const { className = '', data = defaultData, children, icon = false } = infoToolTipProps;
  const [isInfoToolTip, setIsInfoToolTip] = useState(false);
  const [isHover, setisHoverd] = useState(false);
  const [defaultToolTipPosition, setDefaultToolTipPosition] = useState({
    x: 0,
    y: 0,
    show: true,
  });
  const timer = useRef<Timer>();
  const defaultDelay = 1000;

  const debouncedFunctionMain = (func: Function, delay: any) => {
    const debouncedFunction = (...args: any) => {
      const newTimer = setTimeout(() => {
        func(...args);
      }, delay);
      if (!timer.current) {
        timer.current = newTimer;
        return;
      }
      clearTimeout(timer.current);
      timer.current = newTimer;
    };
    return debouncedFunction;
  };
  const handleMouseOver = (e: any) => {
    const handleInfoToolTip = (action: boolean) => {
      const toolTipPos = {
        x: e.clientX,
        y: e.clientY,
        show: true,
      };
      setDefaultToolTipPosition(toolTipPos);
      setIsInfoToolTip(action);
    };
    const setIsInfoToolTipDebounce = debouncedFunctionMain(handleInfoToolTip, defaultDelay);
    setIsInfoToolTipDebounce(true);
    setisHoverd(true);
  };

  const handleMouseLeave = (e: any) => {
    setIsInfoToolTip(false);
    setisHoverd(false);
    if (!timer.current) return;
    clearTimeout(timer.current);
  };
  return (
    <span
      className={className}
      style={{
        transition: `ease-in-out ${300}ms`,
        opacity: isHover ? 0.8 : 1,
        color: '',
        cursor: 'help',
      }}
      onMouseOver={(e) => handleMouseOver(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
    >
      {icon ? <BsInfoLg /> : null}
      {children}
      {isInfoToolTip ? (
        <InfoToolTipDiv data={data} defaultToolTipPosition={defaultToolTipPosition} />
      ) : null}
    </span>
  );
};

export default InfoToolTip;

interface InfoToolTipDivProps {
  data: any;
  defaultToolTipPosition: any;
}

export const InfoToolTipDiv = ({ data, defaultToolTipPosition }: InfoToolTipDivProps) => {
  const [divPostion, setDivPostion] = useState(defaultToolTipPosition);
  return (
    <CustomTooltip
      divPostion={divPostion}
      setDivPostion={setDivPostion}
      componentName={componentName}
    >
      {/* <div style={title}> {data?.title}</div> */}
      <div style={description}>{data?.description}</div>
    </CustomTooltip>
  );
};

export const InfoToolTipDivInitiator = () => {
  return <ToolTipInitiator componentName={componentName} />;
};

const description = {
  fontSize: '.8rem',
};
