import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './toolTip.scss';

const componentName = 'charts';
const ContainerID = `${componentName}ToolTipInitiatorID`;
const InnerWapperID = `${componentName}ToolTipInnerWapperID`;
const finalID = `${componentName}ToolTip`;

interface CustomTooltipProps {
  x: number;
  y: number;
  data: string;
  children?: React.ReactChild;
}

const CustomTooltip = ({ children, data }: CustomTooltipProps) => {
  const [divPostion, setDivPostion] = useState({ x: 0, y: 0, show: false });
  useEffect(() => {
    const handleCardMove = (event: any) => {
      setDivPostion({
        x: event.pageX + 20,
        y: event.pageY + 20,
        show: true,
      });
    };
    const checkForClassName = document.getElementById(ContainerID);
    if (checkForClassName) {
      const newDiv: any = document.createElement('div');
      newDiv.setAttribute('id', InnerWapperID);
      ReactDOM.render(
        <ToolTipComponent data={data} divPostion={divPostion} children={children} />,
        newDiv
      );
      checkForClassName.append(newDiv);
    }

    window.addEventListener('mousemove', handleCardMove);
    return () => {
      const checkForClassName: any = document.getElementById(InnerWapperID);
      window.removeEventListener('mousemove', handleCardMove);
      if (checkForClassName) {
        checkForClassName.remove();
      }
    };
  }, [divPostion]);
  return <></>;
};

export default CustomTooltip;

export const ToolTipInitiator = () => {
  useEffect(() => {
    const checkForClassName = document.getElementById(ContainerID);

    if (!checkForClassName) {
      const newDiv: any = document.createElement('div');
      newDiv.setAttribute('id', ContainerID);
      document.getElementById('root')?.append(newDiv);
    }

    return () => {
      const checkForClassName: any = document.getElementById(ContainerID);
      if (checkForClassName) {
        checkForClassName?.remove();
      }
    };
  }, []);

  return <></>;
};

interface ToolTipComponentProps {
  data: any;
  divPostion: {
    x: number;
    y: number;
    show: boolean;
  };
  spacial?: boolean;
  children?: React.ReactChild;
}
const ToolTipComponent = ({ data, divPostion, children }: ToolTipComponentProps) => {
  if (children) {
    return <ChildrenToolTip data={data} divPostion={divPostion} children={children} />;
  }
  if (data.spacial) {
    if (data.spacialType === 'titleDiscription') {
      return <TitleDiscriptionToolTip data={data} divPostion={divPostion} />;
    }
  }

  return (
    <div
      id={finalID}
      className={divPostion.show ? 'show' : ''}
      style={{ left: divPostion.x, top: divPostion.y }}
    >
      <div className='title'>{data.title}</div>
      {data?.warning === undefined ||
      data?.warning === null ||
      data?.warning?.trim() === '' ? null : (
        <div className='warning'>{data.warning}</div>
      )}
      <div className='discription'>{data.discription}</div>
    </div>
  );
};
const TitleDiscriptionToolTip = ({ data, divPostion }: ToolTipComponentProps) => {
  return (
    <div
      id={finalID}
      className={divPostion.show ? 'show' : ''}
      style={{ left: divPostion.x, top: divPostion.y }}
    >
      <div className='title'>{data.title}</div>
      {data?.warning === undefined ||
      data?.warning === null ||
      data?.warning?.trim() === '' ? null : (
        <div className='warning'>{data.warning}</div>
      )}
      <div className='discription'>{data.discription}</div>
    </div>
  );
};
const ChildrenToolTip = ({ data, divPostion, children }: ToolTipComponentProps) => {
  return (
    <div
      id={finalID}
      className={divPostion.show ? 'show' : ''}
      style={{ left: divPostion.x, top: divPostion.y }}
    >
      {children}
    </div>
  );
};
