import React from 'react';

import { getStyling } from '../../helpers/colorHelper';
import { Text } from '@visx/text';

const AxisLabelComponent = ({
  x,
  y,
  formattedValue,
  // isHorizontal,
  bottomAxis = false,
  valueStyle = {},
  xAxisTickWidth = 0,
  yAxisTickWidth = 0,
  wordLength = 12,
  spacialStyle = {},
  // yAxisTickWidth = 10
}) => {
  const shortenedData = (x, maxLength = 12) => {
    // const splitedFirstWord = `${x}`.split(' ')[0];
    // if (splitedFirstWord?.length > 8) {
    //   return x.substring(0, 8) + '...';
    // }
    if (x?.length > maxLength) {
      return x.substring(0, maxLength) + '...';
    }
    return x;
  };

  const shortLength = 8;
  if (yAxisTickWidth >= 80) {
    const isShortFunction = () => {
      const splitedValue = `${formattedValue}`?.split(' ');
      if (splitedValue[0]?.length >= shortLength) {
        return shortLength;
      }
      if (splitedValue[1]?.length >= shortLength) {
        return shortLength;
      }
      if (splitedValue[2]?.length >= shortLength) {
        return shortLength;
      }
      return 12;
    };
    const shortValue = isShortFunction();
    return (
      <>
        <Text
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y + 5}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          style={{
            ...getStyling(valueStyle),
            transform: 'translate(0) rotateZ(0)',
          }}
          // width={xAxisTickWidth / 2}
          width={yAxisTickWidth}
        >
          {shortenedData(formattedValue, shortValue).toString()}
        </Text>
        <text
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y + 5}
          // fill={valueStyle ? valueStyle.color : defaultAxisColor}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          // fontSize={valueStyle?.fontHeight || 10}
          // fontWeight={valueStyle?.textStyle?.includes('bold') ? 'bold' : 'normal'}
          // fontFamily={valueStyle?.fontFamily || 'sans-serif'}
          // opacity={valueStyle?.opacity / 100 || 1}
          // textDecoration={valueStyle?.textStyle?.includes('underlined') ? 'underline' : 'none'}
          style={{
            ...getStyling(valueStyle),
            transform: 'translate(0) rotateZ(0)',
            opacity: 0,
            userSelect: 'none',
          }}
        >
          <title>{formattedValue}</title>
          {shortenedData(formattedValue, 20).toString()}
        </text>
      </>
    );
  }
  if (xAxisTickWidth >= 100) {
    return (
      <>
        <Text
          // x={bottomAxis ? x : x - 5}
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y + 10}
          // textAnchor={bottomAxis ? 'middle' : 'end'}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          style={{
            ...getStyling(valueStyle),
            transform: 'translate(0) rotateZ(0)',
          }}
          width={xAxisTickWidth / 1.2}
        >
          {shortenedData(formattedValue, 18).toString()}
        </Text>
        <text
          // x={bottomAxis ? x : x - 5}
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y + 10}
          // textAnchor={bottomAxis ? 'middle' : 'end'}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          // fill={valueStyle ? valueStyle.color : defaultAxisColor}
          // fontSize={valueStyle?.fontHeight || 10}
          // fontWeight={valueStyle?.textStyle?.includes('bold') ? 'bold' : 'normal'}
          // fontFamily={valueStyle?.fontFamily || 'sans-serif'}
          // opacity={valueStyle?.opacity / 100 || 1}
          // textDecoration={valueStyle?.textStyle?.includes('underlined') ? 'underline' : 'none'}
          style={{
            ...getStyling(valueStyle),
            transform: 'translate(0) rotateZ(0)',
            opacity: 0,
            userSelect: 'none',
          }}
        >
          <title>{formattedValue}</title>
          {shortenedData(formattedValue, 20).toString()}
        </text>
      </>
    );
  }
  return (
    <>
      <g className='LableExportText'>
        <Text
          // x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          // y={spacialStyle?.y ? y + spacialStyle?.y : y}
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y + 5}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          style={{
            transform: 'translate(0) rotateZ(0)',
            ...getStyling(valueStyle),
          }}
          width={xAxisTickWidth / 10}
        >
          {shortenedData(formattedValue, wordLength)?.toString()}
          {/* {shortenedData(formattedValue, 10).toString()} */}
        </Text>
      </g>
      <g className='LableNormalText'>
        <text
          x={spacialStyle?.x ? x + spacialStyle?.x : bottomAxis ? x : x - 5}
          y={spacialStyle?.y ? y + spacialStyle?.y : y}
          textAnchor={spacialStyle?.textAnchor ?? (bottomAxis ? 'middle' : 'end')}
          style={{
            ...getStyling(valueStyle),
          }}
        >
          <title>{formattedValue}</title>
          {shortenedData(formattedValue, wordLength)?.toString()}
        </text>
      </g>
    </>
  );
};

// AxisLabelComponent.propTypes = {
//   x: PropTypes.number.isRequired,
//   y: PropTypes.number.isRequired,
//   formattedValue: PropTypes.string,
//   isHorizontal: PropTypes.bool,
//   bottomAxis: PropTypes.bool,
//   handleMouseOver: PropTypes.func.isRequired,
//   hideTooltip: PropTypes.func.isRequired,
// };
// AxisLabelComponent.defaultProps = {
//   isHorizontal: false,
//   bottomAxis: false,
// };

export default AxisLabelComponent;
