import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const GeoMapHeplerComponents = () => {
  return <div>GeoMapHeplerComponents</div>;
};

export default GeoMapHeplerComponents;

interface MarkerData {
  position: [number, number];
}
interface FitBoundsProps {
  markers: MarkerData[];
  isColor: boolean;
}

export const GeoMapFitBounds: React.FC<FitBoundsProps> = ({ markers, isColor }) => {
  const map = useMap();

  React.useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((marker: any) => (isColor ? [marker[1], marker[2]] : [marker[0], marker[1]]))
      );
      map.fitBounds(bounds);
    }
  }, [markers, map, isColor]);

  return null;
};

export const GeoMapWithResize = ({ chartWidth }: { chartWidth: string }) => {
  const map = useMap();
  useEffect(() => {
    const handleResize = () => {
      map.invalidateSize();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [map]);
  useEffect(() => {
    const handleResize = () => {
      map.invalidateSize();
    };
    handleResize();
  }, [map, chartWidth]);

  return null;
};
