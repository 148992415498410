import React, { useEffect, useState } from 'react';
import xBlack from '../../../assets/xBlack.svg';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import './sections.scss';
import { allDropableID, defaultDashboardSchema } from '../../../data/defaultSchema';
import { AddSectionInput } from '../addSection/AddSection';
import { mls } from 'lib/multilanguagesupport';
const { sectionId } = allDropableID;
const Sections = ({ sections, setSections }: mainPorps) => {
  useEffect(() => {
    if (sections.length === 0) {
      setSections([defaultDashboardSchema.sections]);
    }
  }, [sections, setSections]);
  return (
    <Droppable droppableId={sectionId} key={sectionId}>
      {(provided, snapshot) => (
        <div className='sectionContainer' ref={provided.innerRef}>
          {/* {sections.length === 0 && <div className='sectionItem noSection'>No Sections</div>} */}
          {React.Children.toArray(
            sections.map((elm: { title: string }, index: number) => (
              <SectionItem
                targetElem={elm}
                sections={sections}
                setSections={setSections}
                index={index}
              />
            ))
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Sections;

const SectionItem = ({ targetElem, setSections, index, sections }: sectionItemPorps) => {
  const [isInputVisible, setInputVisibility] = useState(false);
  const handleInputVisibility = () => {
    setInputVisibility((current) => !current);
  };
  const removeData = (index: number) => {
    setSections((currentData: any) => {
      const newCurrentData = [...currentData];
      newCurrentData.splice(index, 1);
      return newCurrentData;
    });
  };
  return (
    <>
      <Draggable
        key={`${index}`}
        draggableId={`${sectionId.toString() + index.toString()}`}
        index={index}
        //   isDragDisabled={checked}
      >
        {(provided, snapshot) => (
          <>
            <div
              className='sectionItem'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div onClick={handleInputVisibility} style={{ cursor: 'pointer' }}>
                {mls(targetElem.title)}&nbsp;( {index + 1} )
              </div>
              <img
                src={xBlack}
                alt=''
                className='removeaxisItem'
                onClick={() => removeData(index)}
              />
            </div>
          </>
        )}
      </Draggable>
      {isInputVisible && (
        <AddSectionInput
          index={index}
          sections={sections}
          setSections={setSections}
          isInputVisible={isInputVisible}
          handleInputVisibility={handleInputVisibility}
        />
      )}
    </>
  );
};

interface mainPorps {
  sections: any[];
  setSections: Function;
}

interface sectionItemPorps {
  targetElem: {
    title: string;
  };
  sections: any[];
  setSections: Function;
  index: number;
}
