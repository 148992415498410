const selectQuery = (query, index) => {
  return  document.querySelectorAll(query)[index];
}

const clickOnElement = (query, index) => {
  const element = document.querySelector(query)[index];

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });

  element && element.dispatchEvent(clickEvent);
}
const selectOption = (selectElement, optionIndex) => {
  const option = selectElement.querySelector(`option:nth-child(${optionIndex + 1})`);
  option.selected = true;

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });

  option.dispatchEvent(clickEvent);
};

module.exports = {
  selectQuery, selectOption, clickOnElement,
}