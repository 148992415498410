import { updateChartSchemaInServer } from '../utils/connectionFunction';
import { supistaApiPost } from '../utils/connections';
import { finalSchemaByChart } from '../utils/finalSchemaFunction';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';
import { FaTimes } from 'react-icons/fa';

const errorMsg = '<b>Please complete the required fields to save the chart.</b>';
const stringToBold = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />;

const Header = ({
  handleChartPopUp,
  chartSchemaMemo,
  stateProps,
  updateProjectSchema,
  isNew,
  isChatBot,
  onSave,
}) => {
  const { chartType } = stateProps;
  const saveFunction = async () => {
    if (isChatBot) {
      const chartSchema = finalSchemaByChart({ chartSchemaMemo, chartType, normal: true });
      if (chartSchema?.skip) {
        toast.error(stringToBold(errorMsg), {
          position: 'bottom-left',
        });
        return;
      }
      onSave({
        chartSchema,
      });
      return;
    }
    const finalCheck = await updateChartSchemaInServer({
      chartSchemaMemo,
      chartType,
      endPoint: 'ChartSettings/create',
      normal: true,
      updateProjectSchema,
      showToast: true,
    });
    if (finalCheck.skip) {
      return;
    }
    handleChartPopUp(false, 'save');
  };
  const removeFunction = async () => {
    const endPoint = 'ChartSettings/delete';
    const dataForRemove = {
      appID: chartSchemaMemo.appID || '',
      chartID: chartSchemaMemo.chartID || '',
    };
    const res = await supistaApiPost(endPoint, dataForRemove, chartSchemaMemo.appID);
    if (!res.__d3__error) {
      // handleChartPopUp(false);
      updateProjectSchema({}, true, chartSchemaMemo.chartID);
    }
  };
  return (
    <div className='chartPopUpHeader'>
      <div className='title'> {mls('Chart Settings')}</div>
      <div className='subTitle'>{mls('Edit Chart Settings here')}</div>
      <div className='goleftSide'></div>
      {!isNew ? (
        <div className='deleteButtonDiv'>
          <button className='btn btn-sm btn-danger' onClick={() => removeFunction()}>
            <span className='indicator-label'>{mls('Delete')}</span>
          </button>
        </div>
      ) : null}
      <div className='saveButtonDiv'>
        <button className='btn btn-sm btn-primary' onClick={() => saveFunction()}>
          <span className='indicator-label'>{mls('Save')}</span>
        </button>
      </div>

      <div className='closeButton' onClick={() => handleChartPopUp(false, 'cancel')}>
        <FaTimes />
      </div>
    </div>
  );
};

export default Header;
