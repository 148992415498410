import React, { useState, useMemo, useEffect, useCallback } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { Axis } from '@visx/xychart';
import styles from '../../constants/styles.module.css';
import { getStyling } from '../../helpers/colorHelper';
import {
  dateTimeFormatter,
  numberFormatter,
} from 'lib/reusable-components/reusableFunction/formatter';

import {
  // STARTING_DATA_LENGTH,
  defaultToolTipStyling,
} from '../../constants/constants';
import { mls } from 'lib/multilanguagesupport';
import { XYChart, Grid, lightTheme, LineSeries } from '@visx/xychart';
import { scaleBand } from '@visx/scale';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { hexWithOpacity } from '../../helpers/colorHelper';
import GetXYChart from '../XYType/GetXYChart';
import useD3Brush from '../../helpers/hooks/useD3Brush';
import XYToolTip, { defaultToolTipBelongTo } from '../ChartToolTip/ChartToolTip';
import DateTimeFilter from '../common/DateTimeFilter';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import AxisLabelComponent from '../common/AxisLabelComponent';

export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen width
  const lineAreaWidth = 40;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

const accessors = {
  xAccessor: (d) => d?.x,
  yAccessor: (d) => d?.y,
};
const brushMargin = {
  top: 5,
  bottom: 0,
  left: 70,
  right: 20,
};
const chartSeparation = 40;

const margin = { left: 60, top: 15, bottom: 60, right: 60 };
function getRequiredFormat(xValues, yValues) {
  const reqData = [];
  for (let i = 0; i < xValues.length; i++) {
    reqData.push({ x: xValues[i], y: yValues[i] });
  }
  return reqData;
}

const DoubleYAxisChart = ({ xyProps }) => {
  const {
    data,
    targetData,
    xAxisLabel,
    yAxisLabel,
    type,
    yCategory,
    xCategory,
    title,
    xGrid,
    yGrid,
    margin: marginProp,
    cardRef,
    chartID,
    useChartSettingsButton,
    isProd,
    chartColor,
    cardWidth,
    colorType,
    handleClick,
    labels,
    toggle,
    handleToggle,
    isToggleClicked,
    chartHeight,
    theme,
    desc,
    isInsight,
    chartData,
    isReport,
  } = xyProps;
  const x = useMemo(() => data.x, [data]);
  const y = useMemo(() => data.y, [data]);
  const { y0, y1 } = useMemo(() => {
    const Y0 = {};
    const Y1 = {};
    Object.keys(data.y).forEach((key, i) => {
      const finalSum = data.y[key].data.length;
      if (i == 0) {
        Y0[key] = {
          data: data.y[key].data,
        };
        Y1[key] = {
          // data: Array(finalSum ?? 1).fill(0),
          data: Array(finalSum ?? 1).fill(null),
        };
      }
      if (i == 1) {
        Y0[key] = {
          // data: Array(finalSum ?? 1).fill(0),
          data: Array(finalSum ?? 1).fill(null),
        };
        Y1[key] = {
          data: data.y[key].data,
        };
      }
    });
    return { y0: Y0, y1: Y1 };
  }, [data]);
  const isBrush = chartData.isZoomBrush === undefined ? true : chartData.isZoomBrush;
  // const x = useMemo(() => (isBrush ? allX.slice(-STARTING_DATA_LENGTH) : allX), [allX]);
  // const y = useMemo(() => (isBrush ? sliceYData(allY, -STARTING_DATA_LENGTH) : allY), [allY]);
  const { width } = useDivDimensions(cardRef);
  const [filteredXData, setFilteredXData] = useState(x);
  const [filteredYData, setFilteredYData] = useState(y);
  const [filteredY0Data, setFilteredY0Data] = useState(y0);
  const [filteredY1Data, setFilteredY1Data] = useState(y1);

  const [filteredTargetYData, setFilteredTargetYData] = useState(targetData?.y);
  const height = chartHeight + margin.top + margin.bottom;

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = isBrush ? chartSeparation + 10 : 0;
  const topChartHeight = isBrush ? 0.92 * innerHeight - topChartBottomMargin : innerHeight;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;
  const xBrushMax = Math.max(width - margin.left - margin.right, 0);
  const brushXScale = useMemo(() => {
    return scaleBand({
      domain: x,
      range: [0, xBrushMax],
      padding: 0,
    });
  }, [x, xBrushMax]);

  const actualWidth = widthHelper(x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * x.length);
  // const [brushPosition, setBrushPosition] = useState([x[x.length - dataNum], x[x.length - 1]]);
  useEffect(() => {
    setFilteredXData(x);
    setFilteredYData(y);
    setFilteredY0Data(y0);
    setFilteredY1Data(y1);
  }, [data, x, y]);
  const isColor =
    chartData.chartType === allChartKeys.BARCOLOR ||
    chartData.chartType === allChartKeys.LINECOLOR ||
    chartData.chartType === allChartKeys.AREACOLOR ||
    chartData.chartType === allChartKeys.BARHORIZONTALCOLOR;
  const getLegendObject = () => {
    if (isColor) {
      const legendColorScale = {};
      (theme?.tooltip?.valueStyle || []).forEach((vs) => {
        if (Object.keys(data?.y)?.includes(vs?.label)) {
          const color = vs?.color;
          const opacity = (vs?.opacity ?? 100) / 100;
          legendColorScale[vs.label || 'Null'] = hexWithOpacity(color, opacity);
        }
      });
      return {
        legendGlyphSize: 20,
        // colorScale: theme.colors,
        // labels: labels,
        colorScale: legendColorScale,
        isReport,
      };
    }
    let newLable = {};
    Object.keys(labels).forEach((labelKey) => {
      const findLabel = theme?.tooltip?.style.find((elm) => {
        return elm?.uniqueColumnName === labelKey;
      });
      newLable = { ...newLable, [labelKey]: findLabel.label ?? labels[labelKey] };
    });
    return {
      legendGlyphSize: 20,
      colorScale: theme.colors,
      // labels: labels,
      labels: newLable,
      isReport,
    };
  };
  const { Legend, colorScale } = useOrdinalLegend(getLegendObject());
  const brushed = useCallback(
    ({ selection }) => {
      if (selection) {
        const [x0, x1] = selection;
        let start = -1,
          end = y.length - 1;
        setFilteredXData(
          x.filter((d, i) => {
            if (x0 <= brushXScale(d) && brushXScale(d) <= x1) {
              if (start === -1) start = i;
              end = i;
              return true;
            }
            return false;
          })
        );
        // if (start <= x.length * 0.1 && x.length !== allX.length) {
        //   const n = Math.min(STARTING_DATA_LENGTH, allX.length - x.length);
        //   const newX = allX.slice(-x.length - n);
        //   // setX(newX);
        //   // setY(sliceYData(allY, -x.length - n));
        //   setBrushPosition([x[start], x[end]]);
        // }

        const filteredY = {};
        const filteredY0 = {};
        const filteredY1 = {};
        Object.keys(y).forEach((key, i) => {
          filteredY[key] = {
            data: y[key]?.data?.slice(start, end + 1) ?? [],
          };
          filteredY0[key] = {
            data: y0[key]?.data?.slice(start, end + 1) ?? [],
          };
          filteredY1[key] = {
            data: y1[key]?.data?.slice(start, end + 1) ?? [],
          };
        });
        setFilteredYData(filteredY);
        setFilteredY0Data(filteredY0);
        setFilteredY1Data(filteredY1);
        if (!targetData) {
          return;
        }
        const targetfilteredY = {};
        Object.keys(targetData.y).forEach((key) => {
          targetfilteredY[key] = {
            data: targetData.y[key].data.slice(start, end + 1),
          };
        });
        setFilteredTargetYData(targetfilteredY);
      }
    },
    [x, y, brushXScale, targetData]
  );
  /// TooolTip
  const capitalizeOnlyFirst = (s) => {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const xAxis = useMemo(() => chartData.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData.yAxis, [chartData.yAxis]);
  const xAxisLabels = useMemo(() => {
    const axisLabels = xAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, xAxis]);
  const yAxisLabels = useMemo(() => {
    const axisLabels = yAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, yAxis]);

  const { ref: miniRef } = useD3Brush({
    brushed,
    height: bottomChartHeight,
    data: x,
    brushScale: brushXScale,
    dataNum,
    width: xBrushMax,
    isBrush,
  });
  const horizontalGridLines = chartData?.gridLines?.horizontal || {};
  const verticalGridLines = chartData?.gridLines?.vertical || {};
  const getStrokDashArray = (type) => {
    if (type === 'dashed') {
      return '10,10';
    }
    if (type === 'dotted') {
      return '2,4';
    }
    return '';
  };
  const xAxisTickWidth = (width ?? 0) / filteredXData?.length;
  const isDateTime = xCategory === 'DATETIME';
  const xStyling = theme.xAxisStyle;
  const yStyling = theme.yAxisStyle;
  const yAxisFormatter = theme?.yAxisStyle?.formatter;
  const y0Styling = chartData?.doubleAxis?.y0AxisStyle;
  const y1Styling = chartData?.doubleAxis?.y1AxisStyle;
  const y0AxisFormatter = y0Styling?.formatter;
  const y1AxisFormatter = y1Styling?.formatter;

  const ydateTimeFormat = theme.xAxisStyle.formatter;
  useEffect(() => {
    if (!isBrush) {
      setFilteredXData(x);
      setFilteredYData(y);
    }
  }, [isBrush]);
  return (
    <>
      <>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            {xCategory === 'DATETIME' && (
              <DateTimeFilter
                width={width}
                toggle={toggle}
                handleToggle={handleToggle}
                isToggleClicked={isToggleClicked}
                margin={margin}
              />
            )}
            <div
              style={{
                position: 'relative',
              }}
            >
              {/* Y0Data */}
              <XYChart
                theme={{ ...lightTheme, colors: colorScale.range() }}
                height={topChartHeight}
                width={width ?? 0}
                xScale={{
                  type: 'band',
                  paddingInner:
                    type === 'BARSTACK' ||
                    type === 'BAR' ||
                    type === 'BARGROUP' ||
                    type === 'BARSTACKLINE' ||
                    type === 'BARCOLOR'
                      ? 0.2
                      : 1,
                  paddingOuter: 0.1,
                }}
                yScale={{ type: 'linear' }}
                margin={margin}
              >
                {horizontalGridLines?.show && (
                  <Grid
                    columns={false}
                    numTicks={horizontalGridLines?.count ?? 5}
                    strokeWidth={horizontalGridLines?.width ?? 1}
                    stroke={hexWithOpacity(
                      horizontalGridLines?.color,
                      horizontalGridLines?.opacity / 100
                    )}
                    strokeDasharray={getStrokDashArray(horizontalGridLines?.type)}
                    lineStyle={{}}
                  />
                )}
                {verticalGridLines?.show && (
                  <Grid
                    rows={false}
                    numTicks={verticalGridLines?.count ?? 5}
                    strokeWidth={verticalGridLines?.width ?? 1}
                    stroke={hexWithOpacity(
                      verticalGridLines?.color,
                      verticalGridLines?.opacity / 100
                    )}
                    strokeDasharray={getStrokDashArray(verticalGridLines?.type)}
                    lineStyle={{}}
                  />
                )}

                {xStyling?.show ? (
                  <Axis
                    labelClassName={`${styles.chartLabel} `}
                    labelProps={{
                      ...getStyling(xStyling?.style),
                      textAnchor: 'middle',
                    }}
                    tickLabelProps={() => ({
                      ...getStyling(xStyling?.valueStyle),
                      textAnchor: 'middle',
                      xAxisTickWidth,
                      xStyling,
                    })}
                    // tickFormat={shortenedData}
                    hideTicks
                    tickLength={10}
                    tickLineProps={{
                      color: 'red',
                    }}
                    label={xAxisLabel}
                    orientation='bottom'
                    tickComponent={AxisBottomLabelWrapper}
                    tickClassName={styles.bottomAxisTicks}
                    tickFormat={(e) => {
                      if (isDateTime) {
                        return dateTimeFormatter({
                          type: { ...ydateTimeFormat, format: toggle },
                          value: e,
                        });
                      }
                      return e;
                    }}
                    labelOffset={35}
                    numTicks={dataNum}
                    stroke={xStyling?.style.color}
                  />
                ) : null}
                {y0Styling?.show ? (
                  <Axis
                    tickClassName={`${styles.leftAxisTicks} `}
                    labelProps={{
                      ...getStyling(y0Styling?.style),
                      textAnchor: 'middle',
                    }}
                    tickLabelProps={() => ({
                      ...getStyling(y0Styling?.valueStyle),
                      textAnchor: 'middle',
                      yStyling: y0Styling,
                    })}
                    labelClassName={styles.chartLabel}
                    label={y0Styling?.label}
                    labels={false}
                    orientation='left'
                    labelOffset={45}
                    tickFormat={(e) =>
                      numberFormatter({ type: { ...y0AxisFormatter, compact: true }, value: e })
                    }
                    tickComponent={AxisleftLabelWrapper}
                    stroke={y0Styling?.style.color}
                  />
                ) : null}
                <>
                  <GetXYChart
                    xData={filteredXData}
                    yData={filteredY0Data}
                    getRequiredFormat={getRequiredFormat}
                    type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
                    accessors={accessors}
                    colorScale={colorScale}
                    handleClick={handleClick}
                  />

                  {filteredTargetYData &&
                    Object.keys(filteredTargetYData).map((key) => {
                      return (
                        <LineSeries
                          key={key}
                          dataKey={key}
                          data={getRequiredFormat(filteredXData, filteredTargetYData[key].data)}
                          {...accessors}
                          colorAccessor={(d, i) => colorScale(key)}
                        />
                      );
                    })}
                </>
              </XYChart>
              {/* Y1Data */}
              {yAxis[1] ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                  }}
                >
                  <XYChart
                    theme={{ ...lightTheme, colors: colorScale.range() }}
                    height={topChartHeight}
                    width={width ?? 0}
                    xScale={{
                      type: 'band',
                      paddingInner:
                        type === 'BARSTACK' ||
                        type === 'BAR' ||
                        type === 'BARGROUP' ||
                        type === 'BARSTACKLINE' ||
                        type === 'BARCOLOR'
                          ? 0.2
                          : 1,
                      paddingOuter: 0.1,
                    }}
                    yScale={{ type: 'linear' }}
                    margin={margin}
                  >
                    <>
                      {y1Styling?.show ? (
                        <Axis
                          tickClassName={`${styles.leftAxisTicks} `}
                          labelProps={{
                            ...getStyling(y1Styling?.style),
                            textAnchor: 'middle',
                          }}
                          tickLabelProps={() => ({
                            ...getStyling(y1Styling?.valueStyle),
                            textAnchor: 'middle',
                            yStyling: y1Styling,
                            isSpacialStyle: true,
                          })}
                          labelClassName={styles.chartLabel}
                          label={y1Styling?.label}
                          labels={false}
                          orientation='right'
                          labelOffset={45}
                          tickFormat={(e) =>
                            numberFormatter({
                              type: { ...y1AxisFormatter, compact: true },
                              value: e,
                            })
                          }
                          tickComponent={AxisleftLabelWrapper}
                          stroke={y1Styling?.style.color}
                        />
                      ) : null}
                      <GetXYChart
                        xData={filteredXData}
                        yData={filteredY1Data}
                        getRequiredFormat={getRequiredFormat}
                        type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
                        accessors={accessors}
                        colorScale={colorScale}
                        handleClick={handleClick}
                      />
                      {filteredTargetYData &&
                        Object.keys(filteredTargetYData).map((key) => {
                          return (
                            <LineSeries
                              key={key}
                              dataKey={key}
                              data={getRequiredFormat(filteredXData, filteredTargetYData[key].data)}
                              {...accessors}
                              colorAccessor={(d, i) => colorScale(key)}
                            />
                          );
                        })}
                    </>
                  </XYChart>
                </div>
              ) : null}
              {/* ToolTip */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  opacity: 0,
                }}
              >
                <XYChart
                  theme={{ ...lightTheme, colors: colorScale.range() }}
                  height={topChartHeight}
                  width={width ?? 0}
                  xScale={{
                    type: 'band',
                    paddingInner:
                      type === 'BARSTACK' ||
                      type === 'BAR' ||
                      type === 'BARGROUP' ||
                      type === 'BARSTACKLINE' ||
                      type === 'BARCOLOR'
                        ? 0.2
                        : 1,
                    paddingOuter: 0.1,
                  }}
                  yScale={{ type: 'linear' }}
                  margin={margin}
                >
                  <>
                    <GetXYChart
                      xData={filteredXData}
                      yData={filteredYData}
                      getRequiredFormat={getRequiredFormat}
                      type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
                      accessors={accessors}
                      colorScale={colorScale}
                      handleClick={handleClick}
                    />

                    {filteredTargetYData &&
                      Object.keys(filteredTargetYData).map((key) => {
                        return (
                          <LineSeries
                            key={key}
                            dataKey={key}
                            data={getRequiredFormat(filteredXData, filteredTargetYData[key].data)}
                            {...accessors}
                            colorAccessor={(d, i) => colorScale(key)}
                          />
                        );
                      })}
                  </>

                  <XYToolTip
                    defaultToolTipStyling={defaultToolTipStyling}
                    accessors={accessors}
                    theme={theme}
                    colorScale={colorScale}
                    xAxisLabels={xAxisLabels}
                    yAxisLabels={yAxisLabels}
                    labels={labels}
                    belongsTo={defaultToolTipBelongTo.XY}
                    backgroundColor={defaultToolTipStyling.backgroundColor}
                    isColor={isColor}
                    toggle={toggle}
                    showSeriesGlyphs={false}
                    isDateTime={isDateTime}
                  />
                </XYChart>
              </div>
            </div>
            {isBrush && (
              <svg
                height={bottomChartHeight}
                width={width - margin.left - margin.right}
                style={{
                  marginLeft: margin.left,
                  marginRight: margin.right,
                  // marginTop: topChartHeight + topChartBottomMargin,
                }}
                ref={miniRef}
              >
                <MiniXYChart
                  bottomChartHeight={bottomChartHeight}
                  width={width}
                  type={type}
                  x={x}
                  y={y0}
                  targetYData={targetData?.y}
                  colorScale={colorScale}
                  accessors={accessors}
                />
                <MiniXYChart
                  bottomChartHeight={bottomChartHeight}
                  width={width}
                  type={type}
                  x={x}
                  y={y1}
                  targetYData={targetData?.y}
                  colorScale={colorScale}
                  accessors={accessors}
                />
              </svg>
            )}

            <div style={{ marginTop: 20 }}>
              <Legend />
            </div>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </>
    </>
  );
};

const MiniXYChart = React.memo(
  ({ bottomChartHeight, width, type, x, y, targetYData, colorScale }) => {
    return (
      <>
        <XYChart
          theme={{ ...lightTheme, colors: colorScale.range() }}
          // theme={{ ...lightTheme, colors: variantColors }}
          margin={{ ...brushMargin, left: 0, right: 0 }}
          width={width - margin.left - margin.right}
          height={bottomChartHeight}
          xScale={{
            type: 'band',
            paddingInner:
              type === 'BARSTACK' ||
              type === 'BAR' ||
              type === 'BARGROUP' ||
              type === 'BARSTACKLINE' ||
              type === 'BARCOLOR'
                ? 0.2
                : 1,
            paddingOuter: 0.1,
          }}
          yScale={{ type: 'linear' }}
        >
          <GetXYChart
            xData={x}
            yData={y}
            getRequiredFormat={getRequiredFormat}
            type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
            colorScale={colorScale}
            accessors={accessors}
          />
          {targetYData &&
            Object.keys(targetYData).map((key) => {
              return (
                <LineSeries
                  key={key}
                  dataKey={key}
                  data={getRequiredFormat(x, targetYData[key].data)}
                  {...accessors}
                  colorAccessor={(d, i) => colorScale(key)}
                />
              );
            })}
        </XYChart>
      </>
    );
  }
);
export default DoubleYAxisChart;

const AxisleftLabelWrapper = ({ x, y, formattedValue, yStyling, isSpacialStyle }) => {
  const spacialStyle = {
    textAnchor: 'start',
    x: 5,
    y: -5,
  };
  return (
    <AxisLabelComponent
      valueStyle={yStyling?.valueStyle}
      x={x}
      y={y}
      formattedValue={formattedValue}
      spacialStyle={isSpacialStyle ? spacialStyle : {}}
    />
  );
};
// Wrapper component for bottom axis using AxisLabelComponent
const AxisBottomLabelWrapper = ({ x, y, formattedValue, xStyling, xAxisTickWidth }) => {
  return (
    <AxisLabelComponent
      valueStyle={xStyling?.valueStyle}
      x={x}
      y={y}
      formattedValue={formattedValue}
      xAxisTickWidth={xAxisTickWidth}
      bottomAxis
    />
  );
};
