import { scaleBand, scaleLinear, scaleTime } from "@visx/scale";

const getScale = (category) => {
  switch (category) {
    case 'NUM':
      return scaleLinear;

    case 'DATETIME':
      return scaleTime;

    case 'PERCENTAGE':
      return scaleLinear;

    case 'CAT':
      return scaleBand;

    // For default considering categorical data
    default:
      return scaleBand;
  }
};

 const visxScaleHelper = ({ domain, range, padding, category, clamp }) => {
  return getScale(category)({
    domain,
    range,
    clamp: clamp,
    padding: padding,
  });
};

export default visxScaleHelper;