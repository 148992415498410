import React from 'react';
import { defaultChartSetting } from '../../../data/defaultChartSchema';
import XAxisStyle from '../../../components/StyleComponents/XAxisStyle';
import YAxisStyle from '../../../components/StyleComponents/YAxisStyle';
import SolidColorSelector from '../../../components/StyleComponents/SolidColorSelector';
import { mls } from 'lib/multilanguagesupport';

interface HeatMapStyleCaseProps {
  stateProps: any;
}
const HeatMapStyleCase = ({ stateProps }: HeatMapStyleCaseProps) => {
  const {
    chartXAxisStyle,
    setChartXAxisStyle,
    chartYAxisStyle,
    setChartYAxisStyle,
    xAxisData,
    yAxisData,
    heatMapColor,
    setHeatMapColor,
    chartID,
    chartType,
  } = stateProps;
  const handleMaxColor = (action: any) => {
    setHeatMapColor((currentColor: any) => ({ ...currentColor, max: action }));
  };
  const handleMinColor = (action: any) => {
    setHeatMapColor((currentColor: any) => ({ ...currentColor, min: action }));
  };
  return (
    <>
      <XAxisStyle
        axisStyle={chartXAxisStyle}
        setAxisStyle={setChartXAxisStyle}
        axisData={xAxisData}
        defaultLable={defaultChartSetting.xAxisStyle.label}
        chartID={chartID}
        chartType={chartType}
      />
      <YAxisStyle
        axisStyle={chartYAxisStyle}
        setAxisStyle={setChartYAxisStyle}
        axisData={yAxisData}
        defaultLable={defaultChartSetting.xAxisStyle.label}
        chartID={chartID}
        chartType={chartType}
      />
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Color')}</div>
        <div className='grayContainers CardHeaderImage'>
          <div className='containerItems'>
            <SolidColorSelector
              color={heatMapColor.max}
              setColor={handleMaxColor}
              isPalette={false}
              selectColorTitle={mls('Select Max Color')}
            />
            <SolidColorSelector
              color={heatMapColor.min}
              setColor={handleMinColor}
              isPalette={false}
              selectColorTitle={mls('Select Min Color')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeatMapStyleCase;
