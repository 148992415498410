import { mls } from 'lib/multilanguagesupport';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import React, { useMemo } from 'react';
interface dataTimeColumnFieldProps {
  updateSelectedTable: Function;
  tableSchema: any;
  dateTimeCol: string;
}

const DataTimeColumnField = ({
  updateSelectedTable,
  tableSchema,
  dateTimeCol,
}: dataTimeColumnFieldProps) => {
  const fieldsElem = {
    label: 'Default Datetime Column',
    defaultValue: '',
    required: true,
    name: 'datetimeColumn',
  };
  const handleDateTime = (action: any) => {
    updateSelectedTable({ key: fieldsElem.name, value: action });
  };

  const DTCols = useMemo(() => {
    const getDateTimeColumnsObject = ({
      dateTimeColsArray,
      columns,
    }: {
      dateTimeColsArray: string[];
      columns: { [key: string]: any }[];
    }) => {
      let DTCols = {};
      columns.forEach((col, colIndex: number) => {
        const uniqueColumnNames = `${col.tableName}.${col.columnName}`;
        if (dateTimeColsArray.includes(uniqueColumnNames)) {
          DTCols = { ...DTCols, [uniqueColumnNames]: { ...col, uniqueColumnNames } };
        }
      });
      return DTCols;
    };
    const datetimeCols = tableSchema?.defaultTable?.datetimeCols ?? [];
    const columns = tableSchema?.data ?? [];
    const finalDTCols = getDateTimeColumnsObject({
      dateTimeColsArray: datetimeCols,
      columns: columns,
    });

    return finalDTCols;
  }, [tableSchema]);

  if (Object.keys(DTCols).length <= 0) {
    return <></>;
  }

  return (
    <div className='connectionFormOptions connectionFormOptionsInRow'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <ObjectDropDown
        object={DTCols}
        dropDownValue={dateTimeCol ?? fieldsElem.defaultValue}
        setdropDownValue={handleDateTime}
        key={'uniqueColumnNames'}
        ObjectInObject
        inputPlaceHolder={mls('Select Date')}
      />
    </div>
  );
};

export default DataTimeColumnField;
