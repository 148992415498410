import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  automaticCustomArray,
  defaultColorfulColorPalettes,
  defaultKpiTargetData,
} from '../../data/defaultSchema';
import ClosableWhiteDiv from '../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdDoneOutline } from 'react-icons/md';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { mls } from 'lib/multilanguagesupport';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import xBlack from '../../assets/xBlack.svg';

import CustomColor from './CustomColor';
import { KpiColorFunction } from '../../utils/colorFunction';
import { kpiPointsTitleFunction } from '../../utils/helperFunction';
import { toast } from 'react-toastify';

const defaultStartingPoint = 0;
const KpiTargetPoints = ({ stateProps }) => {
  const { kpiTargetPoints, setKpiTargetPoints, chartType } = stateProps;
  const [isTargetPointInputVisible, setIsTargetPointInputVisible] = useState(false);
  const [defaultColorfulColorPalettesState, setdefaultColorfulColorPalettesState] = useState(
    defaultColorfulColorPalettes
  );

  const handleTargetPointInputVisibility = () =>
    setIsTargetPointInputVisible((currentData) => !currentData);
  useUpdateEffect(() => {
    if (kpiTargetPoints.sectionColors[0]) {
      if (kpiTargetPoints.sectionColors[0].targetPoint <= 0) {
        setKpiTargetPoints({
          ...kpiTargetPoints,
          startPoint: kpiTargetPoints.sectionColors[0].targetPoint,
        });
        return;
      }
    }
    setKpiTargetPoints({ ...kpiTargetPoints, startPoint: defaultStartingPoint });
  }, [kpiTargetPoints.sectionColors]);
  const targetKey = useMemo(
    () =>
      chartType?.toolTip?.isMultipleValue
        ? { isTrue: true, key: 'valueStyle' }
        : { isTrue: false, key: 'style' },
    [chartType]
  );
  const [isCustomColor, setIsCustomColor] = useState(false);
  const handleIsCustomColor = () => {
    setKpiTargetPoints({ ...kpiTargetPoints, type: automaticCustomArray[1] });
    setIsCustomColor(true);
  };
  return (
    <>
      {isCustomColor && kpiTargetPoints.type === automaticCustomArray[1] && (
        <>
          <CustomColor
            chartType={chartType}
            stateProps={stateProps}
            targetKey={targetKey}
            kpiTargetPoints={kpiTargetPoints}
            setKpiTargetPoints={setKpiTargetPoints}
            isPopUpOn={isCustomColor}
            setIsPopUpOn={setIsCustomColor}
          />
          <br />
        </>
      )}
      <div className='grayContainers noOverflowGrayContainer'>
        {kpiTargetPoints?.sectionColors?.length >= 1 ? (
          <div className='containerItems open kpiTargetPointContainer'>
            {React.Children.toArray(
              kpiTargetPoints.sectionColors.map((elm, i) => (
                <TargetPoints
                  elm={elm}
                  index={i}
                  targetSetState={setKpiTargetPoints}
                  kpiTargetPoints={kpiTargetPoints}
                  handleIsCustomColor={handleIsCustomColor}
                />
              ))
            )}
          </div>
        ) : null}
        {isTargetPointInputVisible && (
          <KpiTargetInput
            setKpiTargetPoints={setKpiTargetPoints}
            isTargetPointInputVisible={isTargetPointInputVisible}
            handleTargetPointInputVisibility={handleTargetPointInputVisibility}
            defaultColorfulColorPalettesState={defaultColorfulColorPalettesState}
            setdefaultColorfulColorPalettesState={setdefaultColorfulColorPalettesState}
          />
        )}
        <div className='primaryButtonFlexDiv'>
          <button
            className='primaryButtonSmall secondaryButtonColor'
            onClick={() => handleTargetPointInputVisibility()}
          >
            + {mls('Add Section Colors')}
          </button>
        </div>
      </div>
    </>
  );
};

export default KpiTargetPoints;

const KpiTargetInput = ({
  setKpiTargetPoints,
  isTargetPointInputVisible,
  handleTargetPointInputVisibility,
  defaultColorfulColorPalettesState,
  setdefaultColorfulColorPalettesState,
}) => {
  const [targetPointInput, setTargetPointInput] = useState('');
  const wrapperRef = useRef(null);

  const addTargetPoints = () => {
    setTargetPointInput((currenGoalData) => {
      if (!currenGoalData) {
        handleTargetPointInputVisibility();
        return currenGoalData;
      }
      setKpiTargetPoints((currentTargetPointData) => {
        const sourceitems = Array.from(currentTargetPointData.sectionColors);
        const targetAlreadyPresent = sourceitems.some(
          (targetElem) => targetElem?.targetPoint === currenGoalData
        );
        if (targetAlreadyPresent) {
          toast.error('Target already present.');
          return currentTargetPointData;
        }
        const randomColorIndex = Math.floor(
          Math.random() * defaultColorfulColorPalettesState.length
        );
        const randomColor = defaultColorfulColorPalettesState[randomColorIndex];
        if (defaultColorfulColorPalettesState.length <= 1) {
          setdefaultColorfulColorPalettesState(defaultColorfulColorPalettes);
        } else {
          const copyColorPallettes = Array.from(defaultColorfulColorPalettesState);
          copyColorPallettes.splice(randomColorIndex, 1);
          setdefaultColorfulColorPalettesState(copyColorPallettes);
        }

        let newItem = {
          ...defaultKpiTargetData,
          targetPoint: currenGoalData,
          color: randomColor,
          // label: numberTickFormat(currenGoalData),
          label: numberFormatter({
            type: currentTargetPointData?.formatter,
            value: currenGoalData,
            short: true,
          }),
        };
        const index = sourceitems.findIndex((data) => {
          const goalInNumber = Number(data.targetPoint);
          const currenGoalDataInNumber = Number(currenGoalData);
          if (typeof goalInNumber !== 'number' || typeof currenGoalDataInNumber !== 'number') {
            return -1;
          }
          return goalInNumber >= currenGoalDataInNumber;
        });

        if (index === -1) {
          const targets = [...sourceitems, newItem];
          if (currentTargetPointData.type === automaticCustomArray[0]) {
            const kpiColors = KpiColorFunction({
              colorLength: targets.length,
              isReverseTarget: currentTargetPointData?.reverseTargetAchieve,
            });
            const updatedAutomaticKpiColors = targets.map((sourceElem, sourceIndex) => {
              return { ...sourceElem, color: kpiColors[sourceIndex] };
            });
            return { ...currentTargetPointData, sectionColors: updatedAutomaticKpiColors };
          }
          return {
            ...currentTargetPointData,
            sectionColors: targets,
          };
        }
        sourceitems.splice(index, 0, newItem);
        if (currentTargetPointData.type === automaticCustomArray[0]) {
          const kpiColors = KpiColorFunction({
            colorLength: sourceitems.length,
            isReverseTarget: currentTargetPointData?.reverseTargetAchieve,
          });
          const updatedAutomaticKpiColors = sourceitems.map((sourceElem, sourceIndex) => {
            return { ...sourceElem, color: kpiColors[sourceIndex] };
          });
          return { ...currentTargetPointData, sectionColors: updatedAutomaticKpiColors };
        }
        return { ...currentTargetPointData, sectionColors: sourceitems };
      });
      handleTargetPointInputVisibility();
      return '';
    });
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      addTargetPoints();
    }
  };
  const handleTargetInput = (value) => {
    if (value === '') {
      setTargetPointInput(null);
      return;
    }
    // if (Number(value) < 0) {
    //   return;
    // }
    // if (Number(value) >= 100) {
    //   return;
    // }
    setTargetPointInput(Number(value));
  };
  useUpdateEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        addTargetPoints();
      }
    }
    if (isTargetPointInputVisible) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isTargetPointInputVisible]);
  return (
    <>
      <input
        className='primaryInput'
        type='number'
        // min={0}
        // max={100}
        placeholder={mls('Group Input')}
        value={targetPointInput}
        style={{ margin: '.5rem .5rem', width: 'calc(100% - 1rem)' }}
        onBlur={() => addTargetPoints()}
        onKeyPress={(e) => handleEnterKey(e)}
        onChange={(e) => handleTargetInput(e.target.value)}
        ref={wrapperRef}
      />
    </>
  );
};

const TargetPoints = (props) => {
  const { elm, index, targetSetState, kpiTargetPoints, handleIsCustomColor } = props;
  const [targetPointInput, setTargetPointInput] = useState(elm.targetPoint);
  const [labelInput, setLabelInput] = useState(elm.label);
  const [toggleClose, setToggleClose] = useState(false);
  const handleChange = (e, target) => {
    targetSetState((current) => {
      const sourceitems = Array.from(current.sectionColors);
      const [selectedItem] = sourceitems.splice(index, 1);
      let newSelectedItem = {};
      switch (target) {
        case 'targetPoint':
          const index = sourceitems.findIndex((data) => {
            const goalInNumber = Number(data.targetPoint);
            const currenGoalDataInNumber = Number(e);
            if (typeof goalInNumber !== 'number' || typeof currenGoalDataInNumber !== 'number') {
              return -1;
            }
            return goalInNumber >= currenGoalDataInNumber;
          });
          const newItem = { ...selectedItem, targetPoint: e, label: labelInput };
          setToggleClose(!toggleClose);
          if (index === -1) {
            return {
              ...current,
              sectionColors: [...sourceitems, newItem],
            };
          }
          sourceitems.splice(index, 0, newItem);
          return { ...current, sectionColors: sourceitems };
        case 'label':
          newSelectedItem = { ...selectedItem, label: e.target.value };
          break;
        case 'danger':
          newSelectedItem = { ...selectedItem, color: { ...selectedItem.color, danger: e } };
          break;
        case 'safe':
          newSelectedItem = { ...selectedItem, color: { ...selectedItem.color, safe: e } };
          break;

        default:
          return current;
      }
      sourceitems.splice(index, 0, newSelectedItem);
      return { ...current, sectionColors: sourceitems };
    });
  };

  const deleteTarget = () => {
    targetSetState((current) => {
      const sourceitems = Array.from(current.sectionColors);
      sourceitems.splice(index, 1);
      if (current.type === automaticCustomArray[0]) {
        const kpiColors = KpiColorFunction({
          colorLength: sourceitems.length,
          isReverseTarget: current?.reverseTargetAchieve,
        });
        const updatedAutomaticKpiColors = sourceitems.map((sourceElem, sourceIndex) => {
          return { ...sourceElem, color: kpiColors[sourceIndex] };
        });
        return { ...current, sectionColors: updatedAutomaticKpiColors };
      }
      return { ...current, sectionColors: sourceitems };
    });
  };
  const handleTargetPoint = (e) => {
    setTargetPointInput(e.target.value);
    setLabelInput(
      numberFormatter({ type: kpiTargetPoints?.formatter, value: e.target.value, short: true })
    );
  };
  const handleLabel = (e) => {
    setLabelInput(e.target.value);
  };
  useEffect(() => {
    setTargetPointInput(elm.targetPoint);
    setLabelInput(elm.label);
  }, [elm]);
  // const isLast = (kpiTargetPoints.targets ?? []).length <= index + 1;
  const is100Percent = elm.targetPoint === 100;
  return (
    <div className='kpiTargetPointDiv'>
      <div className='targetPointLable'>
        {kpiPointsTitleFunction({
          kpiStartingPoint: 0,
          targetPoint: elm.targetPoint,
          targets: kpiTargetPoints.sectionColors ?? [],
          targetStartPoint: kpiTargetPoints.startPoint,
          index,
        })}
      </div>
      <div
        className='targetPointColor'
        style={{ opacity: (elm?.opacity ?? 1) / 100 }}
        onClick={() => handleIsCustomColor()}
      >
        <svg
          viewBox='0 0 24 24'
          xmlns='<http://www.w3.org/2000/svg>'
          fill={elm?.color}
          className='targetPointColorDiv'
        >
          <rect width='30' height='30' fill='inherit' />
        </svg>
      </div>
      {is100Percent ? (
        <div className='targetPointRemove targetPointRemoveDisable'>
          <img
            src={xBlack}
            alt=''
            className='removeaxisItem'
            // onClick={() => removeData(index, axisId)}
          />
        </div>
      ) : (
        <div className='targetPointRemove' onClick={() => deleteTarget()}>
          {/* <FaTimes /> */}
          <img
            src={xBlack}
            alt=''
            className='removeaxisItem'
            // onClick={() => removeData(index, axisId)}
          />
        </div>
      )}
    </div>
  );

  return (
    <ClosableWhiteDiv title={elm.targetPoint} show={false} toggleClose={toggleClose}>
      <div>
        <div className='titleDiv'>{mls('Target Point')}</div>

        <input
          // type='text'
          type='number'
          className='primaryInput'
          min={0}
          max={100}
          value={targetPointInput}
          onChange={(e) => handleTargetPoint(e)}
        />
      </div>
      <div className='titleDiv'>{mls('Label')}</div>

      <input
        type='text'
        className='primaryInput'
        value={labelInput}
        // value={elm.label}
        // onChange={(e) => handleChange(e, 'label')}
        onChange={(e) => handleLabel(e)}
      />
      <div className='primaryButtonFlexDiv'>
        <button
          onClick={() => handleChange(targetPointInput, 'targetPoint')}
          className='kpiTargetPointsRemove primaryButtonSmall saveButtonDiv'
        >
          <span>
            <MdDoneOutline />
          </span>
          &nbsp;{mls('Done')}
        </button>
        <button
          onClick={() => deleteTarget()}
          className='kpiTargetPointsRemove primaryButtonSmall removeButton'
        >
          <span>
            <RiDeleteBin5Line />
          </span>
          &nbsp;{mls('Remove')}
        </button>
      </div>
    </ClosableWhiteDiv>
  );
};
