import { mls } from 'lib/multilanguagesupport';
import React from 'react';

interface StatisticalProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
}
const Statistical = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
}: StatisticalProps) => {
  return (
    <div className='container-fluid h-100 w-100 mb-2 statisticalDivContainer'>
      <div className='col-8 p-4 w-100  insight-table-column-header-left'>
        <b>{mls(columnName1)}</b>
      </div>
      <div className='col p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName2)}</b>
      </div>
      <div className='col p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName3)}</b>
      </div>
      {Array.isArray(elem) &&
        elem.map((data: [any, any, any], i: number) => (
          <>
            <div className='col-8 p-4 g-0 w-100 insight-table-data-cell-left'>{data[0]}</div>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>{data[1]}</div>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>{data[2]}</div>
          </>
        ))}
    </div>
  );
};
export default Statistical;
