import { DragDropContext } from 'react-beautiful-dnd';
import { allDropableID } from '../../data/defaultSchema';
import { changeDestinationFunction, sameDestinationFunction } from '../../utils/dragableFunctions';

interface mainPorps {
  stateProps: any;
  children: JSX.Element;
}
const { chartId, sectionId, dashboardSheetsId } = allDropableID;

const DragableWrapper = (mainPorps: mainPorps) => {
  const { sections, setSections, projectSheets, chartsData } = mainPorps.stateProps;

  const conditionFuctionWithData = (
    source: conditionFuctionWithDataProps,
    destination: conditionFuctionWithDataProps
  ) => {
    const dataByDroppableId = (Id: string) => {
      if (Id.match(sectionId)) {
        return { data: sections, setData: setSections };
      }
      if (Id.match(chartId)) {
        return { data: projectSheets, setData: () => {} };
      }
      if (Id.match(dashboardSheetsId)) {
        return { data: sections, setData: setSections };
      }
      return { data: [], setData: () => {} };
    };
    const sourceData = dataByDroppableId(source.droppableId);
    const destinationData = dataByDroppableId(destination.droppableId);
    if (source.droppableId === destination.droppableId) {
      return {
        sameDestionation: true,
        sourceData: sourceData.data,
        setSourceData: sourceData.setData,
        destinationData: destinationData.data,
        setDestinationData: destinationData.setData,
      };
    }
    return {
      sameDestionation: false,
      sourceData: sourceData.data,
      setSourceData: sourceData.setData,
      destinationData: destinationData.data,
      setDestinationData: destinationData.setData,
    };
  };
  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    const { source, destination } = result;

    const { sameDestionation, sourceData, setSourceData, destinationData, setDestinationData } =
      conditionFuctionWithData(source, destination);

    // if source and destination are same
    if (sameDestionation) {
      const sameDestinationProps = {
        setSourceData,
        result,
      };
      sameDestinationFunction(sameDestinationProps);
      return;
    }

    // if source and destination are not same
    const changeDestinationProps = {
      sourceData,
      setSourceData,
      result,
      destinationData,
      setDestinationData,
      chartsData,
    };
    changeDestinationFunction(changeDestinationProps);
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>{mainPorps.children}</DragDropContext>
    </>
  );
};

export default DragableWrapper;

interface conditionFuctionWithDataProps {
  index: number;
  droppableId: string;
}
