import DateTimeFormatterComp from 'lib/chart-settings-lib/features/Formatter/DateTimeFormatterComp';
import ClosableWhiteDiv from '../../features/ClosableWhiteDiv/ClosableWhiteDiv';

import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { defaultDataType } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { defaultFormattingType } from 'lib/reusable-components/reusableData/formatterData';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const Formatter = (formatterProps) => {
  const { axisStyle, setAxisStyle, axisData, chartID, show = false, chartType } = formatterProps;

  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  const handleFormatter = (action) => {
    setAxisStyle((currentAxisStyle) => {
      return { ...currentAxisStyle, formatter: action };
    });
  };
  if (axisData) {
    if (axisData[0]?.dataType === defaultDataType.NUM) {
      return (
        <ClosableWhiteDiv title={mls(`Number Format`)} show={show} autoCloseOff={true}>
          <div className='TheInputDiv'>
            <NumberFormatterComp
              formatter={axisStyle?.formatter}
              setFormatter={handleFormatter}
              short
            />
          </div>
        </ClosableWhiteDiv>
      );
    }
    const format = timeFrameData?.toggle?.[chartID] ?? axisData[0]?.operations?.type;
    if (axisData[0]?.dataType === defaultDataType.DATETIME) {
      const formatDateTime = defaultFormattingType.dateTime[format];
      if (!formatDateTime?.isOptions || chartType?.chartKey === allChartKeys.HEATMAP) {
        return <></>;
      }
      return (
        <ClosableWhiteDiv title={mls(`Date Format`)} show={show} autoCloseOff={true}>
          <div className='TheInputDiv'>
            <DateTimeFormatterComp
              formatter={axisStyle?.formatter}
              setFormatter={handleFormatter}
              format={format}
              short
            />
          </div>
        </ClosableWhiteDiv>
      );
    }
  }

  return <></>;
};

export default Formatter;
