import React, { useState } from 'react';
import _ from 'lodash';

import ConnectionCard from './components/ConnectionCard';
import DeleteModal from './components/DeleteModal';

// import sampleConnections from './sampleConnections';
import ImportTableModal from './components/ImportTableModal';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import '../../dataConnector.scss';
import Sorting from 'lib/data-connectors/dependencies/Sorting';
import EditConnections from './components/EditConnections/EditConnections';
import { ToastContainer } from 'react-toastify';

const DataSource = () => {
  const [deletingConnection, setDeletingConnection] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [openedConnectionID, setOpenedConnectionID] = useState(null);
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );

  const [appCredentials, setAppCredentials] = useState(analyticsSchema?.appCreds || {});
  const [openEditConnections, setOpenEditConnections] = useState(false);
  const [isNewConnection, setIsNewConnection] = useState(false);
  const [selectedConnectionID, setSelectedConnectionID] = useState('');
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const handleClose = () => {
    setSelectedConnectionID('');
    setIsNewConnection(false);
    setOpenEditConnections(false);
  };

  return (
    <>
      <div className='DataConnectors'>
        <ToastContainer position={'bottom-left'} theme={'colored'} />
        {isConnectionModalOpen && (
          <ImportTableModal
            open={isConnectionModalOpen}
            setOpen={setIsConnectionModalOpen}
            openedConnectionID={openedConnectionID}
            setOpenedConnectionID={setOpenedConnectionID}
            credSchema={appCredentials[openedConnectionID]}
          />
        )}
        {openEditConnections ? (
          <EditConnections
            selectedConnectionID={selectedConnectionID}
            appCredentials={appCredentials}
            isNewConnection={isNewConnection}
            setIsNewConnection={setIsNewConnection}
            openEditConnections={openEditConnections}
            handleClose={handleClose}
          />
        ) : null}
        {/* Connection form - for both new and edit existing connection */}
        <div className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white rounded'>
          <div className='p-2 fw-bold' style={{ marginRight: 'auto' }}>
            <h3 style={{ margin: 0 }}>{mls('Connections')}:</h3>
          </div>
          <Sorting
            setObjectOfObjects={setAppCredentials}
            dataInRedux={analyticsSchema?.appCreds}
            keyProperty={'credentialID'}
            searchBy={'connectionName'}
            updateSearchKey={setSearchKeyWord}
          />
          <button
            className='btn btn-sm btn-primary ms-1'
            onClick={() => {
              setIsNewConnection(true);
              setOpenEditConnections(true);
            }}
          >
            {mls('Add New Connection')}
          </button>
        </div>
        <div className='introjs-connections'>
          {!_.isEmpty(analyticsSchema?.appCreds || {}) ? (
            _.isEmpty(Object.values(appCredentials || {})) ? (
              <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
                {mls(`No Data Source Connections Found for this "${searchKeyWord}" keyword.`)}
              </div>
            ) : (
              <div className='row'>
                {Object.entries(appCredentials || {}).map((connection, index) => {
                  return (
                    <div className='col-md-4 col-sm-6 mb-6' key={index}>
                      <ConnectionCard
                        title={connection[1]?.connectionName || connection[0]}
                        description={connection[1]?.description}
                        connType={connection[1]?.connectorType}
                        dbName={connection[1]?.data?.db_name}
                        index={index}
                        connectionData={connection[1]}
                        credentialID={connection[1]?.credentialID}
                        appSchema={analyticsSchema}
                        setDelete={() => {
                          setDeletingConnection(connection);
                          setIsDeleteModalOpen(true);
                        }}
                        openConnection={() => {
                          setSelectedConnectionID(connection[0]);
                          setIsNewConnection(false);
                          setOpenEditConnections(true);
                        }}
                        openEditConnection={() => {
                          // setIsConnectionModalOpen(true);
                          // setOpenedConnectionID(connection[0]);
                          setSelectedConnectionID(connection[0]);
                          setIsNewConnection(false);
                          setOpenEditConnections(true);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )
          ) : (
            <div class='alert alert-warning m-auto mt-3' role='alert' style={{ width: '100%' }}>
              {mls(
                'No Data Source Connections. Please Connect with your Spreadsheets, Databases or Apps to start importing tables.'
              )}
            </div>
          )}
        </div>
        {isDeleteModalOpen && (
          <DeleteModal
            connection={deletingConnection}
            isOpen={isDeleteModalOpen}
            closeModal={() => {
              setIsDeleteModalOpen(false);
              setDeletingConnection(null);
            }}
          />
        )}
      </div>
    </>
  );
};

export default DataSource;
