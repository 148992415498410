// import { fetchDataToPlot } from 'app/network/apiConnections';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import useDownloadTableSchema from 'lib/reusable-components/reusableUtils/commons/useDownloadTableSchema';
import { _fetchDataToPlot } from 'lib/server-connection/connections';

const useAutoGenerateNewCharts = () => {
  const updateSchema = useUpdateSchema();
  const downloadTableSchema = useDownloadTableSchema();

  // default Table is for Join Table
  return async ({ appID, defaultTable, sheetID }) => {
    try {
      const tableSchema = await downloadTableSchema(appID, defaultTable);
      if (!tableSchema.__d3__error) {
        // checking if tableSchema has same table name as that of this current schema to ensure that the table has been created in the backend.
        const autoGenerateSheetsRes = await _fetchDataToPlot(
          {
            appID: appID,
            sheetID,
          },
          `${appID}/generateSheetCharts`
        );
        if (!autoGenerateSheetsRes.__d3__error) {
          // adding newly created charts in redux
          const chartSchemaLocation = getSchemaLocation(schemaConstants.type.CHART);
          (autoGenerateSheetsRes?.response?.charts || []).forEach((chart) => {
            updateSchema(chartSchemaLocation, chart.chartID, chart);
          });

          // updating sheet ID
          if (autoGenerateSheetsRes?.response?.sheetSchema?.sheetID) {
            const sheetSchemaLocation = getSchemaLocation(schemaConstants.type.SHEET);
            updateSchema(
              sheetSchemaLocation,
              autoGenerateSheetsRes.response.sheetSchema.sheetID,
              autoGenerateSheetsRes.response.sheetSchema
            );
          }
        }
      }
    } catch (err) {}
  };
};
export default useAutoGenerateNewCharts;
