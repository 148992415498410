import React, { useEffect, useState } from 'react';
import WithoutStyleToggle from '../components/WithoutStyleToggle';
import { toggleColumnDetails } from '../data/defaultSchema';
import { columnToggleFunc } from '../utils/coloumnToggleHelper';
import Sections from '../components/secondColumn/sections/Sections';
import AddSection from '../components/secondColumn/addSection/AddSection';

// import NormalCase from './ChartCases/NormalCase';
// import SpecialCase from './ChartCases/SpecialCase';

interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
  };
  mainPorps: any;
}
const SecondColumn = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;
  const { minimizedDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName,
      setIsMainDivOn,
      setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }
  const { sections, setSections } = mainPorps.stateProps;

  return (
    <>
      <div className={isMainDivOn ? 'secondColumn' : 'secondColumn close'}>
        <WithoutStyleToggle
          // stateProps={stateProps}
          toggleProps={toggleProps}
          // buddleProps={buddleProps}
        >
          <Sections sections={sections} setSections={setSections} />
          <AddSection setSections={setSections} sections={sections} />
        </WithoutStyleToggle>
      </div>
    </>
  );
};

export default SecondColumn;
