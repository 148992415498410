import { organizationStructureOptions } from '../OrganizationStructure/data/organizationData';

export const getCircleAndEmployeesGroupedData = ({
  circlesData,
  employeesData,
}: {
  circlesData: any[];
  employeesData: any[];
}) => {
  let circleDataOption = {};
  circlesData.forEach((circlesDataElem: { [key: string]: any }) => {
    circleDataOption = {
      ...circleDataOption,
      [circlesDataElem.circleID]: { ...circlesDataElem, key: circlesDataElem.circleID },
    };
  });
  let employeeDataOption = {};
  employeesData.forEach((employeesDataElem: { [key: string]: any }) => {
    employeeDataOption = {
      ...employeeDataOption,
      [employeesDataElem.employeeID]: {
        ...employeesDataElem,
        key: employeesDataElem.employeeID,
        name: employeesDataElem.email,
      },
    };
  });

  let defaultData = {};
  if (Object.keys(circleDataOption)[0]) {
    defaultData = {
      ...defaultData,
      [organizationStructureOptions.circles.key]: {
        key: organizationStructureOptions.circles.key,
        title: organizationStructureOptions.circles.lable,
        options: circleDataOption,
      },
    };
  }
  if (Object.keys(employeeDataOption)[0]) {
    defaultData = {
      ...defaultData,
      [organizationStructureOptions.employees.key]: {
        key: organizationStructureOptions.employees.key,
        title: organizationStructureOptions.employees.lable,
        options: employeeDataOption,
      },
    };
  }
  return defaultData;
};
