import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import './warningPopup.scss';
import { CircularProgress } from '@mui/material';

interface WarningPopupProps {
  initialValue: boolean;
  setConfirmation: Function;
  titleText?: string;
  discText?: string;
  loading?: boolean;
}
const WarningPopup = ({
  initialValue,
  setConfirmation,
  titleText = `${mls('Are you sure')} ?`,
  discText = '',
  loading = false,
}: WarningPopupProps) => {
  const clickOutSide = () => {
    setConfirmation(false);
  };
  return (
    <PopUpDivChild
      className='WarningPopupContainer'
      initialValue={initialValue}
      setPopUp={clickOutSide}
      header={false}
    >
      <div className='warningIcon'>
        <RiErrorWarningLine />
      </div>

      {/* <div className='titleDiv'>{mls('Are you sure! you want to reset chart')} ?</div> */}
      <div className='warningTitleDiv'>{titleText}</div>
      {discText ? <div className='discDiv'>{discText}</div> : null}
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButton cancleButtonColor' onClick={() => setConfirmation(false)}>
          {mls('Cancel')}
        </button>
        <button className='primaryButton' onClick={() => setConfirmation(true)}>
          {mls('Yes')}
          {loading ? (
            <>
              &nbsp;&nbsp;
              <CircularProgress color='inherit' size={10} />
            </>
          ) : null}
        </button>
      </div>
    </PopUpDivChild>
  );
};

export default WarningPopup;
