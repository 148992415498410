import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';

export const changeNumFormat = (labelValue: number | null,type= {}) => {
  if (labelValue === null || labelValue === undefined) {
    return { value: `--`, isNull: true, isZero: 0 === labelValue };
  }

  return {
    value: numberFormatter({ type, value: labelValue, short: true }),
    isNull: false,
    isNegative: 0 > labelValue,
    isZero: 0 === labelValue,
  };
};
