import { organizationStructureOptions } from '../OrganizationStructure/data/organizationData';
import { defaultTargetID } from './kpiDashboardData';

export const UpdateCircleAndEmployeeArray = ({
  departmentID,
  KpiEmployees,
  KpiCircles,
  KpiCharts,
}: {
  departmentID: string;
  KpiEmployees: { [key: string]: any };
  KpiCircles: { [key: string]: any };
  KpiCharts: { [key: string]: any };
}) => {
  // const KpiCircles = kpiAllSchema?.KpiCircles ?? {};
  // const KpiEmployees = kpiAllSchema?.KpiEmployees ?? {};
  // const KpiCharts = kpiAllSchema?.KpiCharts ?? {};
  let filteredCircle = {};
  let filteredEmployees = {};
  Object.values(KpiCharts ?? {}).forEach((kpiChartElem: any) => {
    let KpiCircleKeys = Object.keys(KpiCircles ?? {});
    let KpiEmployeesKeys = Object.keys(KpiEmployees ?? {});

    if (kpiChartElem.departmentID === departmentID) {
      const circleWiseTargetPoint = kpiChartElem?.targetPoints?.allTargetPoints ?? [];
      circleWiseTargetPoint.forEach((circleWiseTargetPointElem: any) => {
        const targetPointID = circleWiseTargetPointElem?.targetPointID?.id;

        const isCirclePresent = KpiCircleKeys.some(
          (KpiCircleKeyElem) => KpiCircleKeyElem === targetPointID
        );
        if (isCirclePresent) {
          filteredCircle = {
            ...filteredCircle,
            [targetPointID]: KpiCircles?.[targetPointID],
          };
        }

        const isEmployeePresent = KpiEmployeesKeys.some(
          (KpiCircleKeyElem) => KpiCircleKeyElem === targetPointID
        );
        if (isEmployeePresent) {
          filteredEmployees = {
            ...filteredEmployees,
            [targetPointID]: KpiEmployees?.[targetPointID],
          };
        }
      });
    }
  });
  const kpiCirclesArray: any = Object.values(filteredCircle ?? {});
  const kpiEmployeesArray: any = Object.values(filteredEmployees ?? {});
  const KpiCirclesDefault = {
    id: kpiCirclesArray[0]?.circleID,
    type: organizationStructureOptions.circles.key,
  };
  const kpiEmployeessDefault = {
    id: kpiEmployeesArray[0]?.employeeID,
    type: organizationStructureOptions.employees.key,
  };
  const isAnyCircle = kpiCirclesArray[0] ? true : false;
  const isAnyEmployees = kpiEmployeesArray[0] ? true : false;
  let firstPositionTargetID = defaultTargetID;
  if (isAnyCircle) {
    firstPositionTargetID = KpiCirclesDefault;
  } else {
    if (isAnyEmployees) {
      firstPositionTargetID = kpiEmployeessDefault;
    }
  }
  return {
    firstPositionTargetID: firstPositionTargetID,
    defaultCircleID: KpiCirclesDefault.id,
    defaultEmployeesID: kpiEmployeessDefault.id,
    isAnyCircle,
    isAnyEmployees,
    kpiCirclesArray,
    kpiEmployeesArray,
  };
};
