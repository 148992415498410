import { toast } from 'react-toastify';
import {
  allDropableID,
  automaticCustomArray,
  defaultDataType,
  defaultNestedArrayDraggableID,
  defaultOperation,
} from '../data/defaultSchema';
import { getAutomaticKpiTargetData } from './chartHelperFunctions';
import { extractIndexFromDraggableID } from './helperFunction';
import { mls } from 'lib/multilanguagesupport';
import { KpiColorFunction } from './colorFunction';

const { sectionId, dashboardSheetsId, tableId, yAxisId } = allDropableID;
export const changeDestinationFunction = async (props: changeDestinationProps) => {
  const { sourceData, setDestinationData, result, chartsData, setSourceData, stateProps } = props;
  const {
    // setGeoGroup,
    // toast,
    // setchartType,
    chartType,
    // setChartToolTip,
    // chartToolTip,
    // columnDropNumber,
    // setColumnDropNumber,
    // setBarStackLineTargetPoints,
    // chartSchemaMemo,
    setKpiTargetPoints,
    // kpiTargetPoints,
    // setDoubleAxis,
    columnDropNumber,
    setColumnDropNumber,
    chartSchemaMemo,
  } = stateProps;
  const { source, destination } = result;
  if (!sourceData || !setDestinationData) {
    console.log('Error: changeDestinationFunction');
  }
  const destinationID = destination.droppableId;
  let tableIndex = Number(source.droppableId.replace(tableId, ''));
  if (tableIndex === null) {
    console.log('Index is null');
    return;
  }

  const tables = Array.from(sourceData);
  const sourceitems = Array.from(sourceData[tableIndex].items);
  // // Remove Table from tables
  tables.splice(tableIndex, 1);
  const [selectedItem]: any = sourceitems.splice(result.source.index, 1);

  setDestinationData((currentDestinationData: any) => {
    const duplicateDestinationArray: any[] = Array.from(currentDestinationData);
    // const destitems = Array.from(destinationData);

    const selectedItemWithOriginalDataType = {
      ...selectedItem,
      originalDataType: selectedItem.dataType,
    };
    //Opration
    const dataTypeUpdatedItem = oprationAndDataTypeUpdate(
      { chartType, destinationID },
      selectedItemWithOriginalDataType
    );
    const dataTypeUpdatedItemData = dataTypeUpdatedItem.data;

    const finalData = normalAxisSchema({ ...dataTypeUpdatedItemData, columnDropNumber });
    // IF ChartType is KPIMETER
    if (destinationID === yAxisId) {
      if (duplicateDestinationArray?.length >= 1) {
        toast?.error('More Column is not placeable', {
          position: 'bottom-left',
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });
        return duplicateDestinationArray;
      }

      const kpiColors = KpiColorFunction({ colorLength: 2 });
      const defaultKpiTarget = [
        {
          color: kpiColors[0],
          opacity: 70,
          targetPoint: 80,
          label: '80',
        },
        {
          color: kpiColors[1],
          opacity: 70,
          targetPoint: 100,
          label: '100',
        },
      ];

      setKpiTargetPoints((currentTargetPointData: any) => {
        return {
          ...currentTargetPointData,
          // targets: defaultKpiTarget,
          sectionColors: defaultKpiTarget,
          type: automaticCustomArray[0],
        };
      });
    }
    duplicateDestinationArray.splice(result.destination.index, 0, finalData);

    setColumnDropNumber((currentValue: number) => currentValue + 1);

    return duplicateDestinationArray;
  });
  return;

  // const sourcetargetIndex = extractIndexFromDraggableID(
  //   source.droppableId,
  //   defaultNestedArrayDraggableID.first
  // );
  // const destinationtargetIndex = extractIndexFromDraggableID(
  //   destination.droppableId,
  //   defaultNestedArrayDraggableID.first
  // );

  // const sourcetargetSecondIndex = extractIndexFromDraggableID(
  //   source.droppableId,
  //   defaultNestedArrayDraggableID.second
  // );
  // const destinationtargetSecondIndex = extractIndexFromDraggableID(
  //   destination.droppableId,
  //   defaultNestedArrayDraggableID.second
  // );

  // if (sourcetargetIndex === null || destinationtargetIndex === null) {
  //   console.log('Index is null');
  //   return;
  // }

  // if (sourcetargetSecondIndex !== null) {
  //   sameDestinationFunction({ setSourceData, result });
  //   return;
  // }

  // const targetedSource: any =
  //   sourceData[Object.keys(sourceData)[sourcetargetIndex]].charts[source.index];
  // setDestinationData((currentDestinationData: any) => {
  //   const duplicateDestinationArray: any[] = Array.from(currentDestinationData);

  //   const duplicateDestinationChartArray: any[] = Array.from(
  //     currentDestinationData[destinationtargetIndex].charts
  //   );

  //   // remove Targeted
  //   duplicateDestinationArray.splice(destinationtargetIndex, 1);
  //   const newItem = {
  //     id: targetedSource,
  //     width: chartsData[targetedSource]?.chartWidth || 'ONEFOURTH',
  //     sheetID: chartsData[targetedSource]?.sheetID || '',
  //   };

  //   // update Targeted ChartsArray
  //   duplicateDestinationChartArray.splice(destination.index, 0, newItem);
  //   const newWithChartArray = {
  //     ...currentDestinationData[destinationtargetIndex],
  //     charts: duplicateDestinationChartArray,
  //   };

  //   // update Targeted Array
  //   duplicateDestinationArray.splice(destinationtargetIndex, 0, newWithChartArray);

  //   return duplicateDestinationArray;
  // });
};

export const sameDestinationFunction = (props: sameDestinationProps) => {
  const { setSourceData, result } = props;

  const { source, destination } = result;

  if (source.droppableId.match(sectionId)) {
    setSourceData((currentData: any[]) => {
      const items = Array.from(currentData);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      return items;
    });
    return;
  }

  if (source.droppableId.match(dashboardSheetsId)) {
    // const sourcetargetIndex = Number(source.droppableId.replace(dashboardSheetsId, ''));
    const sourcetargetIndex = extractIndexFromDraggableID(
      source.droppableId,
      defaultNestedArrayDraggableID.first
    );
    if (sourcetargetIndex === null) {
      console.log('sourcetargetIndex is null');
      return;
    }
    setSourceData((currentData: any[]) => {
      const duplicateDestinationArray: any[] = Array.from(currentData);
      const duplicateDestinationChartArray: any[] = Array.from(
        currentData[sourcetargetIndex].charts
      );

      // remove Targeted
      const [removedDuplicateDestinationArray] = duplicateDestinationArray.splice(
        sourcetargetIndex,
        1
      );
      const [reorderedItem] = duplicateDestinationChartArray.splice(source.index, 1);

      if (reorderedItem !== undefined && reorderedItem !== null) {
        duplicateDestinationChartArray.splice(destination.index, 0, reorderedItem);
      }

      const newItem = {
        ...removedDuplicateDestinationArray,
        charts: duplicateDestinationChartArray,
      };
      // update Targeted ChartsArray
      duplicateDestinationArray.splice(sourcetargetIndex, 0, newItem);

      return duplicateDestinationArray;
    });
    return;
  }
  console.log('No Match');
};

interface changeDestinationProps {
  sourceData: any;
  setSourceData: Function;
  destinationData: any;
  setDestinationData: Function;
  result: any;
  chartsData: any;
  stateProps: any;
}
interface sameDestinationProps {
  setSourceData: Function;
  result: any;
}

const oprationAndDataTypeUpdate = (props: any, sourceitems: any) => {
  const { chartType, destinationID } = props;

  const chartAxis = () => {
    if (destinationID === yAxisId) {
      return chartType.yAxis[0];
    }
  };

  //  IF SAME  TYPE
  if (chartAxis() === sourceitems.originalDataType) {
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      return {
        isSkip: false,
        data: {
          ...sourceitems,
          operations: { type: defaultOperation.num.sum.key },
          dataType: sourceitems.originalDataType,
        },
      };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartAxis NUM
  if (chartAxis() === defaultDataType.NUM) {
    // if Item is CAT
    if (sourceitems.originalDataType === defaultDataType.CAT) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is DATETIME
    if (sourceitems.originalDataType === defaultDataType.DATETIME) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is GEO
    if (sourceitems.originalDataType === defaultDataType.GEO) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is ID
    if (
      sourceitems.originalDataType === defaultDataType.ID_NUM ||
      sourceitems.originalDataType === defaultDataType.ID_TEXT
    ) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType CAT
  if (chartAxis() === defaultDataType.CAT) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    // if Item is DATETIME
    if (sourceitems.originalDataType === defaultDataType.DATETIME) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.DATETIME,
        operations: { type: defaultOperation.dateTime.month.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType DATETIME
  if (chartAxis() === defaultDataType.DATETIME) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType GEO
  if (chartAxis() === defaultDataType.GEO) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType ID
  if (chartAxis() === defaultDataType.ID) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }
  return sourceitems;
};
const normalAxisSchema = (object: any) => {
  const { originalDataType, name, tableName, columnName, operations, dataType, columnDropNumber } =
    object;
  const newObj = {
    label: name,
    originalDataType: originalDataType,
    dataType: dataType,
    operations: operations || {},
    column: `${tableName}.${columnName}`,
    uniqueColumnName: `${tableName}.${columnName}-${columnDropNumber}`,
  };
  return newObj;
};
