import { mls } from 'lib/multilanguagesupport';
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import DataLakeCard from 'lib/data-connectors/dependencies/DataLakeCard/DataLakeCard';

const JoinTableCard = ({
  index,
  joinedTableData,
  allTableIDAndNamesMap,
  openEditTableInEditableMode,
}) => {
  const numberOfColumnsInPrimaryTable = joinedTableData.columnSchema?.length;
  let numberOfColumnsInJoinedTables = 0;
  for (let i = 0; i < joinedTableData?.joinTableSchema?.length; i++) {
    numberOfColumnsInJoinedTables += joinedTableData.joinTableSchema[i]?.columnSchema?.length || 0;
  }
  let numberOfTablesExcludingPrimaryAndSecondaryTables =
    joinedTableData?.joinTableSchema?.length - 1;
  const handleShow = () => {
    openEditTableInEditableMode({ editableMode: false, data: joinedTableData });
  };
  const isSqlAdded = joinedTableData.sqlQuery ? true : false;
  return (
    <DataLakeCard
      index={index}
      settingButtonFunction={handleShow}
      titleButtonFunction={handleShow}
      title={joinedTableData.name}
      backGroundImage
      bottomButtonFunction={handleShow}
      bottomButtonTitle={mls('Go To Table')}
      description={joinedTableData.description}
    >
      {/* <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
            <strong>{mls('Connection Name')}: </strong>
            {mls(connectionName)}
          </p>
          <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
            <strong>{mls('Connection Type')}: </strong>
            {mls(connectionTypes?.[connectorType])}
          </p> */}
      <p className=' fw-bold fs-5 m-0 text-muted mt-1 mb-2'>
        <strong>{mls('Primary Table')}: </strong>
        {allTableIDAndNamesMap[joinedTableData.primaryTable] || joinedTableData.primaryTable}
      </p>
      <p className=' fw-bold fs-5 m-0 text-muted mt-0 mb-2'>
        <strong>{mls('Secondary Table')}: </strong>
        {allTableIDAndNamesMap[joinedTableData?.joinTableSchema?.[0]?.joinTableName] ||
          joinedTableData?.joinTableSchema?.[0]?.joinTableName}
      </p>
      <p className=' fw-bold fs-5 m-0 text-muted mt-0 mb-0'>
        {numberOfTablesExcludingPrimaryAndSecondaryTables ? (
          <>
            + {numberOfTablesExcludingPrimaryAndSecondaryTables} table
            {numberOfTablesExcludingPrimaryAndSecondaryTables > 1 && 's'}
          </>
        ) : null}
      </p>
      {isSqlAdded ? (
        <p className=' fw-bold fs-5 m-0 mt-4 mb-3'>
          <strong
            style={{
              color: '#155724',
              background: '#d4edda',
              padding: '.25rem .5rem',
              borderRadius: '6px',
            }}
          >
            {mls('SQL Added')}
          </strong>
        </p>
      ) : null}
    </DataLakeCard>
  );
  return (
    <div
      className={`card bgi-no-repeat h-100 `}
      style={{
        // cursor: 'pointer',
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        minHeight: 188,
        backgroundImage: `url(${toAbsoluteUrl(
          `/media/svg/shapes/abstract-${(index + 1) % 5}.svg`
        )})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body joinTableCard'>
        <div
          style={{
            position: 'absolute',
            // top: '.25rem',
            // right: 5,
            top: '1.5rem',
            right: 5,
            // display: 'flex',
            // justifyContent: 'flex-end',
          }}
        >
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary '
            onClick={() => {
              openEditTableInEditableMode({ editableMode: false, data: joinedTableData });
            }}
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
        </div>
        <span
          className='card-title fw-bolder text-dark-75 text-primary fs-4 cursor-pointer'
          onClick={() => {
            openEditTableInEditableMode({ editableMode: false, data: joinedTableData });
          }}
        >
          {mls(joinedTableData.name)}
        </span>
        <p className=' fw-bold fs-5 m-0 text-muted mt-1 mb-2'>
          <strong>{mls('Primary Table')}: </strong>
          {allTableIDAndNamesMap[joinedTableData.primaryTable] || joinedTableData.primaryTable}
        </p>
        <p className=' fw-bold fs-5 m-0 text-muted mt-0 mb-2'>
          <strong>{mls('Secondary Table')}: </strong>
          {allTableIDAndNamesMap[joinedTableData?.joinTableSchema?.[0]?.joinTableName] ||
            joinedTableData?.joinTableSchema?.[0]?.joinTableName}
        </p>
        <p className=' fw-bold fs-5 m-0 text-muted mt-0 mb-0'>
          {numberOfTablesExcludingPrimaryAndSecondaryTables ? (
            <>
              + {numberOfTablesExcludingPrimaryAndSecondaryTables} table
              {numberOfTablesExcludingPrimaryAndSecondaryTables > 1 && 's'}
            </>
          ) : null}
        </p>
        <div className='noOfColumnsDiv'>
          <p
            className='fw-bold fs-5 m-0 text-muted p-1 px-4'
            style={{
              // textAlign: 'right',
              bottom: 15,
              // position: 'absolute',
              right: 25,
              border: '1px dashed #E4E6EF',
              borderRadius: 6,
            }}
          >
            {`${mls('No. of Columns')}: ${
              numberOfColumnsInPrimaryTable +
              numberOfColumnsInJoinedTables +
              (joinedTableData?.customColumnSchema?.length ?? 0)
            }`}
          </p>
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export default JoinTableCard;
