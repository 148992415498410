import React, { useEffect, useState } from 'react';

import NormalCase from './ChartCases/NormalCase';
import SpecialCase from './ChartCases/SpecialCase';

const SecondColumn = ({ stateProps, toggleProps }) => {
  const [settingToggleState, setSettingToggleState] = useState('build');

  const { chartType, setChartToolTip, xAxisData, yAxisData } = stateProps;
  const { toggleColumnDetails, minimizedDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  const buddleProps = { isMainDivOn, settingToggleState, setSettingToggleState };

  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName,
      setIsMainDivOn,
      setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }
  return (
    <>
      <div className={isMainDivOn ? 'secondColumn' : 'secondColumn close'}>
        {chartType.specialCase ? (
          <SpecialCase
            stateProps={stateProps}
            toggleProps={toggleProps}
            buddleProps={buddleProps}
          />
        ) : (
          <NormalCase stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps} />
        )}
      </div>
    </>
  );
};

export default SecondColumn;

function columnToggleFunc(props) {
  const { minimizedDiv, toggleKeyName, setIsMainDivOn, setIsToggle } = props;
  if (minimizedDiv === toggleKeyName) {
    setIsMainDivOn(false);
    setTimeout(() => {
      setIsToggle(false);
    }, 300);
  } else {
    setIsMainDivOn(true);
    // setIsToggle(true)
    setTimeout(() => {
      setIsToggle(true);
    }, 300);
  }
}
