import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { defaultNeededFormattingValue } from 'lib/reusable-components/reusableData/formatterData';

export const allDropableID = {
  tableId: 'tableId',
  yAxisId: 'yAxisId',
  chartId: 'chartId',
  sectionId: 'sectionId',
  dashboardSheetsId: 'dashboardSheetsId',
};
export const defaultSectionData = {
  title: 'Section',
};
export const defaultDateKey = {
  CUSTOM: 'CUSTOM',
  LAST7DAYS: 'LAST7DAYS',
  LASTXDAYS: 'LASTXDAYS',
  defalutLastDay: 30,
  mainKey: 'period',
};

export const defaultDateArray = [
  {
    name: 'Today',
    [defaultDateKey.mainKey]: 'TODAY',
  },
  {
    name: 'Yesterday',
    [defaultDateKey.mainKey]: 'YESTERDAY',
  },
  {
    name: 'This week',
    [defaultDateKey.mainKey]: 'THISWEEK',
  },
  {
    name: 'Last 7 days',
    [defaultDateKey.mainKey]: 'LAST7DAYS',
  },
  {
    name: 'This month',
    [defaultDateKey.mainKey]: 'THISMONTH',
  },
  {
    name: 'Last 30 days',
    [defaultDateKey.mainKey]: 'LAST30Days',
  },
  {
    name: 'This quarter',
    [defaultDateKey.mainKey]: 'THISQUARTER',
  },
  {
    name: 'Last X day',
    [defaultDateKey.mainKey]: defaultDateKey.LASTXDAYS,
  },
  {
    name: 'This year',
    [defaultDateKey.mainKey]: 'THISYEAR',
  },
  {
    name: 'Custom Date',
    [defaultDateKey.mainKey]: defaultDateKey.CUSTOM,
  },
];
export const defaultWidthTypes = {
  FULL: {
    key: 'FULL',
    name: 'Full',
    spaces: 4,
  },
  HALF: {
    key: 'HALF',
    name: 'Half',
    spaces: 2,
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    name: 'Two-Third',
    spaces: 3,
  },
  ONEFOURTH: {
    key: 'ONEFOURTH',
    name: 'One-Fourth',
    spaces: 1,
  },
};
export const defaultKpiTimeFrame = {
  // THISWEEK: 'Weekly',
  THISMONTH: 'Monthly',
  THISQUARTER: 'Quarterly',
  THISYEAR: 'Yearly',
};
export const defaultWidthTypesArray = Object.values(defaultWidthTypes);
export const toggleColumnDetails = {
  firstColumn: {
    toggleKeyName: 'Data Section',
    toggleKeyId: 0,
  },
  secondColumn: {
    toggleKeyName: 'Chart Section',
    toggleKeyId: 1,
  },
};

export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
  ID_TEXT: 'ID_TEXT',
  ID_NUM: 'ID_NUM',
};
export const defaultDataTypeStyles = {
  [defaultDataType.ALL]: {
    key: defaultDataType.ALL,
    name: 'All',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.NUM]: {
    key: defaultDataType.NUM,
    name: 'Numerical',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.CAT]: {
    key: defaultDataType.CAT,
    name: 'Categorical',
    icon: 'ABC',
    color: '#00629a',
  },
  [defaultDataType.DATETIME]: {
    key: defaultDataType.DATETIME,
    name: 'Date Time',
    icon: 'DATE',
    color: 'orange',
  },
  [defaultDataType.GEO]: {
    key: defaultDataType.GEO,
    name: 'Geographical',
    icon: 'GEO',
    color: 'black',
  },
  // [defaultDataType.ID]: {
  //   key: defaultDataType.ID,
  //   name: 'Identifier',
  //   icon: 'ID',
  //   color: 'black',
  // },
  [defaultDataType.ID_TEXT]: {
    key: defaultDataType.ID_TEXT,
    name: 'Text Identifier',
    icon: 'ID',
    color: 'black',
  },
  [defaultDataType.ID_NUM]: {
    key: defaultDataType.ID_NUM,
    name: 'Num Identifier',
    icon: 'ID',
    color: 'black',
  },
};
export const defaultColorfulColorPalettes = [
  // '#FFFF00',
  '#c4f119', //new
  '#FF0000',
  '#0000FF',
  '#008000',
  '#00FFFF',
  '#008080',
  '#3F00FF',
  '#000080',
  '#800020',
  '#800080',
  '#FF00FF',
  // '#FFA500',
  '#0d76cf', //new
  '#E0B0FF',
  '#7F00FF',
  // '#FFD700',
  '#cf30c2', //new
];
export const chartErrors = {
  kpiMeters: {
    moreThenOne: 'More then one column not possible in <b>KPIMETER<b>',
  },
  pieCharts: {
    numColumnNotAcceptable: '<b>Numerical column</b> not acceptable',
  },
  geoCharts: {
    moreThenOne: 'More then one column not possible',
    numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',
  },
  numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',

  barStackLine: {
    moreThenOne: 'More then one column not possible',
    numColumnAcceptable: 'Only <b>Numerical column</b> acceptable',
  },
  catColumnAcceptable: 'Only <b>Categorical column</b> acceptable',
  catDateColumnAcceptable: 'Only <b>Categorical and DateTime column</b> acceptable',
  [defaultDataType.NUM]: {
    acceptable: 'Only <b>Numerical column</b> acceptable',
    notAcceptable: '<b>Numerical column</b> is not acceptable',
  },
  [defaultDataType.CAT]: {
    acceptable: 'Only <b>Categorical column</b> acceptable',
    notAcceptable: '<b>Categorical column</b> is not acceptable',
  },
  [defaultDataType.DATETIME]: {
    acceptable: 'Only <b>DateTime column</b> acceptable',
    notAcceptable: '<b>DateTime column</b> is not acceptable',
  },
  [defaultDataType.ID_TEXT]: {
    acceptable: 'Only <b>Text ID column</b> acceptable',
    notAcceptable: '<b>Text ID column</b> is not acceptable',
  },
  [defaultDataType.ID_NUM]: {
    acceptable: 'Only <b>Numerical ID column</b> acceptable',
    notAcceptable: '<b>Numerical ID column</b> is not acceptable',
  },
};
const mainColors = {
  primaryBlue: '#00A3FF',
};
export const defaultLegendColorPalettes = [
  mainColors.primaryBlue,
  '#00C1FF',
  '#00D9E6',
  '#46f0f0',
  '#FFA800',
  '#50CD89',
  '#00A3FF',
  '#8950FC',
  '#1BC5BD',
  '#FFE456',
  '#0A572D',
  '#394157',
  '#FCB6BD',
  '#5F5CF1',
  '#D0B9FF',
];
export const defaultSolidColorPalettes = [
  '#181823',
  '#3F4254',
  '#404258',
  '#474E68',
  '#50577A',
  '#6B728E',
  '#FAF0E6',
  '#f8f9f5',
  // '#3C84AB',
  // '#473C33',
  // '#282A3A',
  // '#443C68',
  // '#635985',

  // '#00EBB8',
  // '#9CF68A',
  // '#F9F871',
  // '#fdb827',

  // '#404258',
  // '#474E68',
  // '#50577A',
  // '#6B728E',
  // mainColors.primaryBlue,
  // '#00C1FF',
  // '#00D9E6',
  // '#46f0f0',
  // '#f58231',
  // '#911eb4',
  // '#46f0f0',
  // '#f032e6',
  // '#bcf60c',
  // '#fabebe',
  // '#008080',
  // '#e6beff',
  // '#fffac8',
  // '#800000',
  // '#aaffc3',
  // '#808000',
  // '#ffd8b1',
  // '#000075',
  // '#808080',
];
export const automaticCustomArray = ['automatic', 'custom'];

export const defaultOperation = {
  num: {
    sum: {
      name: 'Sum',
      key: 'sum',
    },
    // average: {
    //   name: 'Average',
    //   key: 'mean',
    // },
    avg: {
      name: 'Average',
      key: 'avg',
    },
    minimum: {
      name: 'Minimum',
      key: 'min',
    },
    maximum: {
      name: 'Maximum',
      key: 'max',
    },
    standardDeviation: {
      name: 'Standard Deviation',
      key: 'std',
    },
    median: {
      name: 'Median',
      key: 'median',
    },
    mode: {
      name: 'Mode',
      key: 'mode',
    },
    var: {
      name: 'Variance',
      key: 'var',
    },
    // uncalculated: {
    //   name: 'Value(Uncalculated)',
    //   key: 'UNCALCULATED',
    // },
    percentOfGrandTotal: {
      name: 'Value(Percentage of grand total)',
      key: 'POGT',
    },
    PCT_BY_GROUP: {
      name: 'Percentage by group',
      key: 'PBG',
    },
  },
  cat: {
    count: {
      name: 'Count',
      key: 'count',
    },
    distinct: {
      name: 'Count(Distinct)',
      key: 'DISTINCT',
    },
  },
  dateTime: {
    day: {
      name: 'Day',
      key: 'DAY',
    },
    week: {
      name: 'Week',
      key: 'WEEK',
    },
    month: {
      name: 'Month',
      key: 'MONTH',
    },
    quarter: {
      name: 'Quarter',
      key: 'QUARTER',
    },
    year: {
      name: 'Year',
      key: 'YEAR',
    },
  },
};
export const defaultFontDecoration = ['bold', 'italic', 'underlined'];
export const defaultFontFamily = ['Poppins', 'Times new roman'];
export const defaultFontSize = [
  {
    name: 'S',
    key: '10',
  },
  {
    name: 'M',
    key: '12',
  },
  {
    name: 'L',
    key: '14',
  },
  {
    name: 'XL',
    key: '16',
  },
];
export const defaultSelectedFontSize = defaultFontSize[1].key;
export const defaultNestedArrayDraggableID = {
  first: 'firstNestedDraggableArray',
  second: 'secondNestedDraggableArray',
  third: 'thirdNestedDraggableArray',
};
export const defaultKpiChartSchema = {
  kpiChartName: '',
  kpiChartDesc: '',
};
export const defaultChartWidthTypesObj = {
  FULL: {
    key: 'FULL',
    name: 'Full',
  },
  HALF: {
    key: 'HALF',
    name: 'Half',
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    name: 'Two-Third',
  },
  ONEFOURTH: {
    key: 'ONEFOURTH',
    name: 'One-Fourth',
  },
};
export const defaultChartWidthTypes = [
  defaultChartWidthTypesObj.FULL,
  defaultChartWidthTypesObj.HALF,
  defaultChartWidthTypesObj.TWOTHIRD,
  defaultChartWidthTypesObj.ONEFOURTH,
];
export const defaultTextColor = defaultSolidColorPalettes[0];
export const defaultOpacity = 70;
export const defaultTextOpacity = 100;
export const defaultPlotAreaBackgroundSolidOpacity = 30;
export const defaultColorType = ['Solid', 'Gradient'];
export const defaultGradientColorPalettes = [
  {
    from: '#F64E60',
    to: '#FFA800',
    type: 'radial',
  },
  {
    from: '#FFA800',
    to: '#50CD89',
    type: 'linear',
  },
  {
    from: '#50CD89',
    to: '#00A3FF',
    type: 'conic',
  },
  {
    from: '#00A3FF',
    to: '#8950FC',
    type: 'linear',
  },
  {
    from: '#8950FC',
    to: '#1BC5BD',
    type: 'linear',
  },
  {
    from: '#1BC5BD',
    to: '#FFE456',
    type: 'linear',
  },
  {
    from: '#FFE456',
    to: '#47BE7D',
    type: 'linear',
  },
  {
    from: '#47BE7D',
    to: '#394157',
    type: 'linear',
  },
  {
    from: '#394157',
    to: '#181C32',
    type: 'linear',
  },
  {
    from: '#FCB6BD',
    to: '#5F5CF1',
    type: 'linear',
  },
  {
    from: '#5F5CF1',
    to: '#2FC4FF',
    type: 'linear',
  },
  {
    from: '#D0B9FF',
    to: '#5F5CF1',
    type: 'linear',
  },
];
export const defaultCardTypes = [
  {
    key: 'CARD1',
    name: 'CARD 1',
  },
  {
    key: 'CARD2',
    name: 'CARD 2',
  },
  // {
  //   key: 'TYPE2',
  //   name: 'Type 2',
  // },
  // {
  //   key: 'TYPE3',
  //   name: 'Type 3',
  // },
];
export const defaultGridLineTypes = ['dashed', 'solid', 'dotted'];

export const defaultKpiTargetData = {
  color: defaultTextColor,
  opacity: defaultOpacity,
};
export const maxColorHeatMap = mainColors.primaryBlue;
export const minColorHeatMap = '#d8f3ff';

export const defaultChartSetting = {
  chartType: allChartTypesObj.KPITRACKING,
  name: '',
  desc: '',
  xAxis: [],
  yAxis: [],
  xAxisStyle: {
    label: 'X-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },
  yAxisStyle: {
    label: 'Y-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },
  doubleAxis: {
    isDoubleAxis: true,
    y0AxisStyle: {
      label: 'Y-Axis (Left)',
      isLabelEdited: false,
      show: true,
      style: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
      },
      valueStyle: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
        rotate: 0,
      },
    },
    y1AxisStyle: {
      label: 'Y-Axis (Right)',
      isLabelEdited: false,
      show: true,
      style: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
      },
      valueStyle: {
        color: defaultTextColor,
        textStyle: [],
        font: defaultFontFamily[0],
        fontHeight: defaultSelectedFontSize,
        opacity: defaultTextOpacity,
        rotate: 0,
      },
    },
  },
  tooltip: {
    textStyle: [],
    font: defaultFontFamily[0],
    fontHeight: defaultSelectedFontSize,
    type: 'automatic',
    automaticOpacity: defaultOpacity,
    style: [],
    valueStyle: [],
  },
  chartWidth: defaultChartWidthTypes[3],
  filters: {},
  plotAreaBackgroundSolid: {
    type: defaultColorType[0],
    opacity: defaultPlotAreaBackgroundSolidOpacity,
    color: defaultTextColor,
  },
  plotAreaBackgroundGradient: {
    type: defaultColorType[1],
    opacity: defaultPlotAreaBackgroundSolidOpacity,
    gradientColor: defaultGradientColorPalettes[0],
  },
  gridLines: {
    vertical: {
      show: true,
      type: defaultGridLineTypes[1],
      width: 1,
      color: '#bcbec8',
      opacity: defaultOpacity,
      count: 5,
    },
    horizontal: {
      show: true,
      type: defaultGridLineTypes[1],
      width: 1,
      color: '#bcbec8',
      opacity: defaultOpacity,
      count: 5,
    },
  },
  isZoomBrush: true,
  isDateHierarchy: false,
  isInsight: true,
  isForecast: false,
  isCrossReport: true,
  iskeepAllFilters: true,
  chartBuilderHelper: {
    columnDropNumber: 0,
  },
  timeFrame: 180,

  tableID: '',
  sheetID: '',
  // Special Case

  //CARD
  chartHeaderImage: '', //CARD
  chartCardType: defaultCardTypes[0], //CARD
  //KPIMETER
  // targetPoints: [], //KPIMETER
  targetPoints: {
    maxGoalAchievePercentage: 9,
    startPoint: 0,
    kpiStartingPoint: 0,
    title: {
      valuePrefix: '',
      valuePostfix: '',
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    allTargetPoints: [],
    sectionColors: [],
    reverseTargetAchieve: false,
    selectedTargetPointID: {
      key: '',
      type: '',
    },
    type: 'automatic',
    targetFontStyle: {
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      color: defaultTextColor,
      opacity: defaultTextOpacity,
    },
    targets: [],
    kpiTimeframe: 'THISMONTH',
    timeframeColumn: '',
    formatter: { ...defaultNeededFormattingValue.num, maxDecimal: 1 },
  },
  defaultKpiTargets: [],
  //KPITRACKING
  departmentID: '',
  //GEO
  geoGroup: [], //GEO
  //BARSTACKLINE
  barStackLineTargetPoints: { targetLines: [] }, //BARSTACKLINE
  //PLOT
  plotGroup: [], //PLOT

  plotGroupStyle: {
    label: 'Plot Group',
    isLabelEdited: false,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },

  //PIVOTTABLE
  pivotTableTitleStyle: {
    fontHeight: defaultSelectedFontSize,
    textStyle: [defaultFontDecoration[0]],
  },
  //HEATMAP
  heatMapGroup: [], //HEATMAP
  heatMapColor: {
    max: {
      opacity: defaultTextOpacity,
      color: maxColorHeatMap,
    },
    min: {
      opacity: defaultOpacity,
      color: minColorHeatMap,
    },
  },

  //TREEMAP
  treeMapColor: {
    max: {
      opacity: defaultOpacity,
      color: maxColorHeatMap,
    },
    min: {
      opacity: defaultOpacity,
      color: minColorHeatMap,
    },
  },

  //COLOR
  colorAxis: [], //COLOR
  colorAxisStyle: {
    label: 'Color Axis',
    isLabelEdited: false,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: defaultFontFamily[0],
      fontHeight: defaultSelectedFontSize,
      opacity: defaultTextOpacity,
      rotate: 0,
    },
  },

  //RADIALLINE
  radialLineBackGroundColor: {
    opacity: defaultTextOpacity,
    color: maxColorHeatMap,
  },
};

export const defaultPopulatedData = {
  departmentsData: [
    {
      id: 'ff6e6a86-a4c0-4ff8-ad57-201cc68355d8',
      name: 'Management',
      description: 'Management Department',
    },
    {
      id: 'dce52be7-3249-4c8d-93ef-b38c0f73bfa0',
      name: 'Social Media',
      description: 'Social Media Department',
    },
    {
      id: 'a21825da-d78e-48f8-ab5f-f14032d46bf8',
      name: 'IT',
      description: 'Infromation Technology Department',
    },
    {
      id: '32d7f63d-0610-4706-a597-ad5df667f7b3',
      name: 'Sales',
      description: 'Sales Department',
    },
  ],
  circlesData: [
    {
      id: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
      name: 'Faridabad',
      containingCircles: [
        '8f3e0630-3b4f-491c-8181-82eba3ba2a2d',
        '16ca596a-16e7-470d-96f4-b6e41222092d',
      ],
    },
    {
      id: '16ca596a-16e7-470d-96f4-b6e41222092d',
      name: 'Haryana',
      containingCircles: ['8f3e0630-3b4f-491c-8181-82eba3ba2a2d'],
    },
    {
      id: '4116a445-0d32-4f23-b109-925d5b15cb8c',
      name: 'Delhi',
      containingCircles: ['8f3e0630-3b4f-491c-8181-82eba3ba2a2d'],
    },
    {
      id: '8f3e0630-3b4f-491c-8181-82eba3ba2a2d',
      name: 'India',
    },
  ],
  employeesData: [
    {
      id: '37a90d70-053c-4e66-85ba-7b0cac4f6ff3',
      email: 'tarun@emailioTemp.com',
      name: 'Tarun',
      department: ['dce52be7-3249-4c8d-93ef-b38c0f73bfa0'],
      circle: ['4116a445-0d32-4f23-b109-925d5b15cb8c'],
    },
    {
      id: '744a6a2a-1d97-4466-8a2c-9caa1e954663',
      name: 'Ajay Kumar',
      email: 'ajay@emailioTemp.com',
      department: ['32d7f63d-0610-4706-a597-ad5df667f7b3'],
      circle: ['915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a'],
    },
    {
      id: '73bef586-d556-4eb1-963d-79e394fc2a7f',
      email: 'rohit@emailioTemp.com',
      department: ['32d7f63d-0610-4706-a597-ad5df667f7b3'],
      circle: ['4116a445-0d32-4f23-b109-925d5b15cb8c'],
      name: 'Rohit',
    },
    {
      id: 'f2082942-a91d-4c21-bfbb-68b7c0284f26',
      name: 'Sourav',
      email: 'sourav@emailioTemp.com',
      department: ['ff6e6a86-a4c0-4ff8-ad57-201cc68355d8'],
      circle: ['915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a'],
    },
  ],
  kpiManagerData: [
    {
      id: '3dca7de7-b879-4f99-b334-3586adec6651',
      manager: 268,
      department: 'dce52be7-3249-4c8d-93ef-b38c0f73bfa0',
      circle: '16ca596a-16e7-470d-96f4-b6e41222092d',
    },
    {
      id: 'f3f3946d-9279-46ec-90f5-d0cf37f141dd',
      manager: 334,
      department: 'a21825da-d78e-48f8-ab5f-f14032d46bf8',
      circle: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
    },
    {
      id: '8608449b-07b9-4266-9e7c-6e161f6c03fd',
      manager: 269,
      department: 'ff6e6a86-a4c0-4ff8-ad57-201cc68355d8',
      circle: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
    },
  ],
};
export const targetPointData = {
  timePeriodRange: {
    from: 2024,
    to: 2025,
  },
  data: {
    THISMONTH: [
      {
        key: '55ab723d-5976-4e46-a9ec-d3737046037b',
        label: 'Test 3',
        data: {
          '202401': 123,
          '202403': 456,
          '202404': 455,
          '202405': 34,
          '202406': 34534,
          '202407': 5345,
          '202409': 8987,
        },
      },
      {
        key: '68adf99e-8b98-4804-b2c6-7ea09f736ec9',
        label: 'Test 2',
        data: {
          '202401': 2345,
          '202402': 222,
          '202404': 535,
          '202405': 56,
          '202406': 345,
          '202407': 7878,
          '202408': 657657,
          '202409': 89,
        },
      },
      {
        key: 'effd411a-6476-4d97-b406-6e2fcd3379f5',
        label: 'Test 1',
        data: {
          '202402': 65,
          '202404': 3453,
          '202405': 53,
          '202406': 34534,
          '202407': 78,
          '202408': 8,
          '202409': 78978,
        },
      },
    ],
    THISQUARTER: [
      {
        key: '55ab723d-5976-4e46-a9ec-d3737046037b',
        label: 'Test 3',
        data: {},
      },
      {
        key: '68adf99e-8b98-4804-b2c6-7ea09f736ec9',
        label: 'Test 2',
        data: {},
      },
      {
        key: 'effd411a-6476-4d97-b406-6e2fcd3379f5',
        label: 'Test 1',
        data: {},
      },
    ],
    THISYEAR: [
      {
        key: '55ab723d-5976-4e46-a9ec-d3737046037b',
        label: 'Test 3',
        data: {},
      },
      {
        key: '68adf99e-8b98-4804-b2c6-7ea09f736ec9',
        label: 'Test 2',
        data: {},
      },
      {
        key: 'effd411a-6476-4d97-b406-6e2fcd3379f5',
        label: 'Test 1',
        data: {},
      },
    ],
  },
};
