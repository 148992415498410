import { defaultWidthTypes } from '../data/defaultSchema';
export const splitedCharts = (sectionsCharts: any) => {
    const defaults = sectionsCharts || [];
    const newArray: any = Array.from(defaults);
    const gridSize = 4;
    let targetLength = 0;
    const finalArray: any = [];
    let blockArray: any = [];
    for (let index = 0; index < newArray.length; index++) {
      type widthTypes = 'TWOTHIRD' | 'FULL' | 'HALF' | 'ONEFOURTH';
      const width: widthTypes = newArray[index]?.width;
      const widthNumber = defaultWidthTypes[width]?.spaces;
      targetLength = targetLength + widthNumber;
      const newItem = {
        data: newArray[index],
        dataIndex: index,
      };
      if (targetLength > gridSize) {
        targetLength = widthNumber;
        finalArray.push(blockArray);
        blockArray = [];
        blockArray.push(newItem);
      } else {
        blockArray.push(newItem);
      }
    }
    finalArray.push(blockArray);
    // finalArray.push([]);
    
    return finalArray;
  };
  
export const extractIndexFromDraggableID = (str: any, targetString: string) => {
  const startIndex = str.indexOf(`<${targetString}>`);
  const endIndex = str.indexOf(`</${targetString}>`);
  if (startIndex !== -1 && endIndex !== -1) {
    const value = str.substring(startIndex + `<${targetString}>`.length, endIndex);
    if (/^\d+$/.test(value)) {
      return parseInt(value);
    } else {
      return null; // Return null if value is not a valid integer
    }
  } else {
    return null; // Return null if opening or closing tag is not found
  }
}

let timeoutId: ReturnType<typeof setTimeout>;
export const debounceFunction = (fn:Function, ms= 300) =>{

  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}


let isQueued: boolean = false
export function throttle(func: Function, waitTime: number) {
  return () => {
    if (!isQueued) {
      isQueued = true
      setTimeout(() => {
        isQueued = false
        func()
      }, waitTime)
    }
  }
}