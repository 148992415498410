import { defaultTargetLines } from '../../../data/defaultChartSchema';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import { mls } from 'lib/multilanguagesupport';
const blankArray = (length) => {
  return length ? Array(length).join('.').split('.') : [];
};

const BarStackLineTargetPoints = ({ stateProps }) => {
  const {
    barStackLineTargetPoints,
    setBarStackLineTargetPoints,
    xAxisData,
    columnDropNumber,
    setColumnDropNumber,
  } = stateProps;
  const [isGoalInputVisible, setIsGoalInputVisible] = useState(false);
  const handleGoalInputVisibility = () => {
    setIsGoalInputVisible((currentData) => !currentData);
  };
  const targetPointsArrray = useMemo(() => {
    if (!xAxisData[0]?.column) {
      return [];
    }
    return barStackLineTargetPoints[xAxisData[0].column];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxisData]);
  return (
    <div className='grayContainers'>
      {React.Children.toArray(
        barStackLineTargetPoints.targetLines.map((elm, i) => (
          <LineTargetPoints
            elm={elm}
            index={i}
            target={barStackLineTargetPoints}
            targetSetState={setBarStackLineTargetPoints}
            targetPointsArrray={targetPointsArrray}
            setIsGoalInputVisible={setIsGoalInputVisible}
          />
        ))
      )}
      {isGoalInputVisible && (
        <AddLineInput
          target={barStackLineTargetPoints}
          targetPointsArrray={targetPointsArrray}
          targetSetState={setBarStackLineTargetPoints}
          isGoalInputVisible={isGoalInputVisible}
          handleGoalInputVisibility={handleGoalInputVisibility}
          columnDropNumber={columnDropNumber}
          setColumnDropNumber={setColumnDropNumber}
        />
      )}
      <div className='primaryButtonFlexDiv'>

        <button
          className='primaryButtonSmall'
          onClick={() => handleGoalInputVisibility()}
          disabled={xAxisData[0]?.column && !isGoalInputVisible ? false : true}
        >
          {mls("+ Add Line")}
        </button>

      </div>
    </div>
  );
};

export default BarStackLineTargetPoints;

const AddLineInput = ({
  targetSetState,
  targetPointsArrray,
  isGoalInputVisible,
  handleGoalInputVisibility,
  columnDropNumber,
  setColumnDropNumber,
}) => {
  const [inputState, setInputState] = useState(() => {
    return { label: '', targetPoints: blankArray(targetPointsArrray?.length) };
  });

  const wrapperRef = useRef(null);
  const addTargetPoints = () => {
    setInputState((currenInput) => {
      if (!currenInput.label) {
        handleGoalInputVisibility();
        return currenInput;
      }

      targetSetState((currentTargetPointData) => {
        setColumnDropNumber((currentValue) => currentValue + 1);
        const newTargetLine = {
          label: currenInput.label,
          uniqueColumnName: `${currenInput.label}-${columnDropNumber}`,
          show: defaultTargetLines.show,
        };
        const newData = {
          ...currentTargetPointData,
          targetLines: [...currentTargetPointData.targetLines, newTargetLine],
          [newTargetLine.uniqueColumnName]: currenInput.targetPoints,
        };
        return newData;
      });
      handleGoalInputVisibility();

      return { label: '', targetPoints: blankArray(targetPointsArrray?.length) };
    });
  };

  const handleInput = (e) => {
    setInputState((currentData) => {
      return { ...currentData, [e.target.name]: e.target.value };
    });
  };
  const handleTargetPoints = (e, i) => {
    setInputState((currentData) => {
      const newTargetPoints = Array.from(currentData.targetPoints);
      newTargetPoints[i] = e.target.value;
      return { ...currentData, targetPoints: newTargetPoints };
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        addTargetPoints();
      }
    }
    if (isGoalInputVisible) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isGoalInputVisible]);

  return (
    <div className='containerItems open' ref={wrapperRef}>
      <div className='titleDiv'>{mls("Label")}</div>
      <input
        className='primaryInput'
        type='text'
        value={inputState.label}
        name='label'
        onChange={(e) => handleInput(e)}
      />
      {React.Children.toArray(
        targetPointsArrray.map((targetPointsElm, i) => {
          return (
            <div className='barStackLineTargetPointsDiv'>
              <div className='titleDiv barStackLineTitleDiv'>
                <div className='nestedTitleDiv' title={targetPointsElm}>
                  {targetPointsElm}
                </div>
              </div>
              <input
                className='primaryInput'
                type='number'
                value={inputState.targetPoints[i]}
                onChange={(e) => handleTargetPoints(e, i)}
              />
            </div>
          );
        })
      )}
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButtonSmall' onClick={() => addTargetPoints()}>
          {mls("Done")}
        </button>
      </div>
    </div>
  );
};

const LineTargetPoints = (props) => {
  const { elm, index, target, targetSetState, targetPointsArrray } = props;
  const [inputState, setInputState] = useState(() => {
    const targetPoints = target[elm.uniqueColumnName] || blankArray(targetPointsArrray?.length);
    return { label: elm.label || '', targetPoints };
  });
  const [isDivShow, setIsDivShow] = useState(false);

  const deleteTarget = () => {
    targetSetState((current) => {
      const sourceObject = Object(current);
      const sourceitems = Array.from(current.targetLines);
      const [targetedItem] = sourceitems.splice(index, 1);
      delete sourceObject[targetedItem.uniqueColumnName];
      return { ...sourceObject, targetLines: sourceitems };
    });
    setIsDivShow((currentValue) => !currentValue);
  };

  const updateFuntion = () => {
    setInputState((currenInput) => {
      if (!currenInput.label) {
        return { ...currenInput, label: elm.label };
      }
      targetSetState((currentTargetPointData) => {
        const newTargetLines = Array.from(currentTargetPointData.targetLines);
        newTargetLines[index] = { ...newTargetLines[index], label: currenInput.label };
        const newData = {
          ...currentTargetPointData,
          targetLines: newTargetLines,
          [newTargetLines[index].uniqueColumnName]: currenInput.targetPoints,
        };
        return newData;
      });
      return currenInput;
    });
  };

  const updateGroup = () => {
    updateFuntion();
    setIsDivShow((currentValue) => !currentValue);
  };
  const handleInput = (e) => {
    setInputState((currentData) => {
      return { ...currentData, [e.target.name]: e.target.value };
    });
  };
  const handleTargetPoints = (e, i) => {
    setInputState((currentData) => {
      const newTargetPoints = Array.from(currentData.targetPoints);
      newTargetPoints[i] = e.target.value;
      return { ...currentData, targetPoints: newTargetPoints };
    });
  };

  useEffect(() => {
    setInputState(() => {
      const targetPoints = target[elm.uniqueColumnName] || blankArray(targetPointsArrray?.length);
      return { label: elm.label || '', targetPoints };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elm]);

  return (
    <ClosableWhiteDiv title={inputState.label} show={isDivShow} onClose={updateFuntion}>
      <div className='titleDiv'>
        {mls("Label")}
      </div>
      <input
        className='primaryInput'
        type='text'
        value={inputState.label}
        name='label'
        onChange={(e) => handleInput(e)}
      />
      {React.Children.toArray(
        targetPointsArrray.map((targetPointsElm, i) => {
          return (
            <div className='barStackLineTargetPointsDiv'>
              <div className='titleDiv barStackLineTitleDiv'>
                <div className='nestedTitleDiv' title={targetPointsElm}>
                  {targetPointsElm}
                </div>
              </div>
              <input
                className='primaryInput'
                type='number'
                value={inputState.targetPoints[i]}
                onChange={(e) => handleTargetPoints(e, i)}
              />
            </div>
          );
        })
      )}
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButtonSmall' onClick={() => updateGroup()}>
          {mls("Done")}
        </button>
        <button className='primaryButtonSmall kpiTargetPointsRemove' onClick={() => deleteTarget()}>
          {mls("Remove")}
        </button>
      </div>
    </ClosableWhiteDiv>
  );
};
