import React from 'react';
import { BsArrowDownShort } from 'react-icons/bs';
import { changeNumFormat } from '../../../utils/converterFunction';

interface ValueNumberProps {
  number: number | null;
  arrow?: boolean;
}
const ValueNumber = ({ number, arrow }: ValueNumberProps) => {
  const numberData = changeNumFormat(number);
  return (
    <>
      {!numberData?.isNull && arrow ? (
        <>
          {numberData?.isNegative ? (
            <BsArrowDownShort style={{ color: 'red' }} />
          ) : (
            <BsArrowDownShort style={{ rotate: '180deg', color: 'green' }} />
          )}
        </>
      ) : null}
      {numberData.value}
    </>
  );
};

export default ValueNumber;
