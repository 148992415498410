import { divIcon } from 'leaflet';

export const iconWithColor = ({ iconColor = '#583470' }: { iconColor?: string }) => {
  const markerHtmlStyles = `
      background-color: ${iconColor};
      width: 2rem;
      height: 2rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 1px solid #FFFFFF`;
  const innerColor = '#FFFFFF';
  const innerCircle = `
      background-color: ${innerColor};
      width: .75rem;
      height: .75rem;
      display: block;
      left: .5rem;
      top: .5rem;
      position: relative;
      border-radius: 3rem;
      border: 1px solid #FFFFFF`;
  const icon = divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    // labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyles}" > <span style="${innerCircle}"></span></span>`,
  });
  return icon;
};
