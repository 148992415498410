import { allDropableID } from '../../../data/defaultChartSchema';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import React, { useMemo, useState } from 'react';
import FontSelector from '../../StyleComponents/FontSelector';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import SolidColorSelector from '../../StyleComponents/SolidColorSelector';
import { defaultIndicatorTypes, defaultSelectedFontSize } from '../../../data/defaultChartSchema';
import DropDown from 'lib/visx-lib/helpers/components/DropDown/ObjectDropDown';
import { mls } from 'lib/multilanguagesupport';
const defautText = {
  textStyle: [],
  fontHeight: defaultSelectedFontSize,
};
interface PivotTableTitleStyleProps {
  stateProps: any;
}

const PivotTableTitleStyle = ({ stateProps }: PivotTableTitleStyleProps) => {
  const [isDivOpen, setisDivOpen] = useState(true);
  const [isContent, setisContent] = useState(true);

  const { pivotTableTitleStyle, setChartToolTip, setPivotTableTitleStyle } = stateProps;

  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      <div className='MainTitle inFlex'>
        {mls('Title')}
        {/* <SlideSwitch isChecked={isDivOpen} setIsChecked={setisDivOpen} /> */}
      </div>
      <div className='grayContainers'>
        {isContent && (
          <>
            <div className={`containerItems open`}>
              <FontSelector
                fontStyle={pivotTableTitleStyle}
                setFontStyle={setPivotTableTitleStyle}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PivotTableTitleStyle;
