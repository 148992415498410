import React, { useEffect, useState } from 'react';
import './toggleColumn.scss';
import { mls } from 'lib/multilanguagesupport';

const ToggleColumn = ({ toggleProps }) => {
  const { minimizedDiv, toggleMinimizeDiv } = toggleProps;
  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);
  useEffect(() => {
    if (minimizedDiv[0] === '' && minimizedDiv[1] === '') {
      setIsMainDivOn(false);
      setTimeout(() => {
        setIsToggle(false);
      }, 300);
    } else {
      setIsMainDivOn(true);
      setIsToggle(true);
      // setTimeout(() => {
      //   setIsToggle(true);
      // }, 300);
    }
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }
  return (
    <div className={isMainDivOn ? 'toggleColumn' : 'toggleColumn close'}>
      <div className='toogleRotatedDiv'>
        {React.Children.toArray(
          minimizedDiv.map((elm, i) => {
            if (elm) {
              return (
                <>
                  <button
                    onClick={() => toggleMinimizeDiv('', i)}
                    className='toggleColumnButton toggleColumnButtonFirst'
                  >
                    <span>{mls(elm)}</span>
                  </button>
                </>
              );
            }
            return <></>;
          })
        )}
      </div>
    </div>
  );
};

export default ToggleColumn;
