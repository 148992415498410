import { AreaSeries, AreaStack, BarGroup, BarSeries, BarStack, LineSeries } from '@visx/xychart';
import { memo } from 'react';

const MainXYChart = ({ data, type }) => {
  const selectChart = () => {
    if (type === 'MULTIAXISMULTILINE' || type === 'MULTIAXISLINE') {
      return (
        <>
          {Array.apply(null, Array(data[0]?.y?.length)).map((_, i) => {
            return (
              <LineSeries
                key={i}
                dataKey={i + 1}
                data={data}
                xAccessor={(d) => d.x[0]}
                yAccessor={(d) => {
                  const key = Object.keys(d.y[i])[0];
                  const data = d.y[i][key].data[0];
                  return data;
                }}
              />
            );
          })}
        </>
      );
    }

    if (type === 'MULTIAXISBARSTACK') {
      return (
        <BarStack fillOpacity={0.5} offset='none'>
          {Array.apply(null, Array(data[0]?.y?.length)).map((_, i) => {
            return (
              <BarSeries
                key={i}
                dataKey={i + 1}
                data={data}
                xAccessor={(d) => d.x[0]}
                yAccessor={(d) => {
                  const key = Object.keys(d.y[i])[0];
                  const data = d.y[i][key].data[0];
                  return data;
                }}
              />
            );
          })}
        </BarStack>
      );
    }
    if (type === 'MULTIAXISBAR') {
      return (
        <BarGroup>
          {Array.apply(null, Array(data[0]?.y?.length)).map((_, i) => {
            // const key = Math.random();

            return (
              <BarSeries
                key={i}
                dataKey={i + 1}
                data={data}
                xAccessor={(d) => d.x[0]}
                yAccessor={(d) => {
                  const key = Object.keys(d.y[i])[0];
                  const data = d.y[i][key].data[0];
                  return data;
                }}
              />
            );
          })}
        </BarGroup>
      );
    }
    if (type === 'MULTIAXISAREA') {
      return (
        <>
          {Array.apply(null, Array(data[0]?.y?.length)).map((_, i) => {
            return (
              <AreaSeries
                key={i}
                dataKey={i + 1}
                data={data}
                xAccessor={(d) => d.x[0]}
                yAccessor={(d) => {
                  const key = Object.keys(d.y[i])[0];
                  const data = d.y[i][key].data[0];
                  return data;
                }}
                fillOpacity={0.3}
              />
            );
          })}
        </>
      );
    }
    if (type === 'MULTIAXISAREASTACK') {
      return (
        <AreaStack renderLine={true}>
          {Array.apply(null, Array(data[0]?.y?.length)).map((_, i) => {
            return (
              <AreaSeries
                key={i + 1}
                dataKey={i + 1}
                data={data}
                xAccessor={(d) => d.x[0]}
                yAccessor={(d) => {
                  const key = Object.keys(d?.y[i])[0];
                  const data = d?.y[i][key].data[0];
                  return data;
                }}
                fillOpacity={0.4}
              />
            );
          })}
        </AreaStack>
      );
    }

    throw Error('Invalid Chart Type'); // Invalid XYType Type
  };

  return <>{selectChart()}</>;
};

export default memo(MainXYChart);
