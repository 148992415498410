import { dataTableColumnTypes } from 'lib/data-connectors/dependencies/constant';
import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap-v5';
import { DeleteTable } from '../../modal/add-database/DeleteTable';
import {
  _supistaAnalyticsApi,
  _supistaApiDelete,
  _supistaApiPost,
} from 'lib/server-connection/connections';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import CopyColumnToClipBoard from 'lib/data-connectors/dependencies/CopyColumnToClipBoard/CopyColumnToClipBoard';
import { tableUpdateWarning } from 'lib/data-connectors/data/dataContainersData';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { TableCardDataTable } from 'lib/data-connectors/screens/join-tables/components/editTableList/TableListAssests';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import {
  getJoinTableIDs,
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
  getTableIDSTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import clsx from 'clsx';
const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];

const removeSysVariable = (str: string) => {
  const spl = str.split('.');
  const last = spl.pop();
  if (last === '__d3__updatedAt') return spl.join('.') + '.Last Update Date';
  else if (last === '__d3__createdAt') return spl.join('.') + '.Create Date';
  else return spl.join('.') + '.' + last;
};

const getDateTimeCols = (tableSchema: any, dbType: any, tableName: any, isJoined: any) => {
  let columns = tableSchema?.columnSchema || [];
  if (isJoined) {
    columns = columns.map((col: any) => ({ ...col, tableName: tableSchema.primaryTable }));
    (tableSchema.joinTableSchema || []).map((sch: any) => {
      const joinCols = (sch.columnSchema || []).map((schCol: any) => ({
        ...schCol,
        tableName: sch.joinTableName,
      }));
      columns = [...columns, ...joinCols];
      return null;
    });
  } else {
    columns = columns.map((col: any) => ({ ...col, tableName: tableName }));
  }

  if (columns.length === 0) return [];
  const DTCols = columns.filter((col: any) => dateTimeTypes.includes(col.dataType));
  if (dbType === '__d3__supista' && isJoined !== true) {
    const createData = {
      name: 'Create Date',
      dataType: 'DATETIME',
      tableName: tableName,
      columnName: '__d3__createdAt',
    };
    const updateData = {
      name: 'Last Update Date',
      dataType: 'DATETIME',
      columnName: '__d3__updatedAt',
      tableName: tableName,
    };
    DTCols.push(createData, updateData);
  }

  const colNames = DTCols.map((obj: any) => obj.tableName + '.' + obj.columnName);

  return { DTCols, colNames };
};

interface editTableListProps {
  appID: string;
  closeModal: Function;
  appDatatable: { [key: string]: any };
  setTableSchema: Function;
  useUpdateSchema: Function;
  setUpdateSqlCustomCommands: Function;
  tableSchema: any;
  setIsAnyChanges: Function;
}
const EditTableList = ({
  appID,
  closeModal,
  appDatatable,
  setTableSchema,
  tableSchema,
  useUpdateSchema,
  setUpdateSqlCustomCommands,
  setIsAnyChanges,
}: editTableListProps) => {
  const updateTableSchema = useUpdateSchema();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const dbType = tableSchema?.dbType || '__d3__supista';
  const tableID = tableSchema?.tableID || '';
  const handleTableSchema = (key: string, value: any) => {
    setTableSchema({ ...tableSchema, [key]: value });
    setIsAnyChanges(true);
  };
  const datetime: any = getDateTimeCols(tableSchema, dbType, tableID, false);
  const datetimeCols = datetime?.colNames || [];

  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap: { [key: string]: any } = {};
  Object.keys(appDatatable || {}).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = appDatatable[tableID].name;
  });

  const applyTableName = (columnName: string) => {
    const columnNameArray = columnName.split('.');
    const last = columnNameArray.pop();
    const tableName = columnNameArray.join('.');
    return (allTableIDAndNamesMap[tableName] || tableName) + '.' + last;
  };

  const handleDataTypeChange = (index: number, newType: any) => {
    toast.warn(mls(tableUpdateWarning));
    setTableSchema((old: { [key: string]: any }) => {
      const newColumnSchema: { [key: string]: any }[] = Array.from(old.columnSchema);
      const [selectedItem] = newColumnSchema.splice(index, 1);
      const newSelectedItem = { ...selectedItem, dataType: newType };
      newColumnSchema.splice(index, 0, newSelectedItem);
      return {
        ...old,
        columnSchema: newColumnSchema,
      };
    });
    setIsAnyChanges(true);
  };
  const handleDisabledColumn = (index: number, action: boolean) => {
    setTableSchema((old: { [key: string]: any }) => {
      const newColumnSchema: { [key: string]: any }[] = Array.from(old.columnSchema);
      const [selectedItem] = newColumnSchema.splice(index, 1);
      const newSelectedItem = { ...selectedItem, disabledColumn: action };
      newColumnSchema.splice(index, 0, newSelectedItem);
      return {
        ...old,
        columnSchema: newColumnSchema,
      };
    });
    setIsAnyChanges(true);
  };

  const saveTableData = async ({ finalTable }: { finalTable: any }) => {
    const saveRes = await _supistaAnalyticsApi(
      `${appID}/opType/saveDataTable/tableOperations`,
      finalTable
    )
      .then(async (response) => {
        return { skip: false, msg: response };
      })
      .catch((error) => {
        toast.error(
          mls("Some issues encountered. Couldn't able to Save Table. Please try again later.")
        );
        return { skip: true, msg: error };
      });

    return saveRes;
  };

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const sheetsSchema = analyticsSchema?.appSheets || {};
  const reportSchema = analyticsSchema.appReports || {};
  const dashSchema = analyticsSchema.appDash || {};
  const customTableSchema = analyticsSchema.appCustomtable || {};
  const joinTableSchema = analyticsSchema.appJointable || {};
  const handleSave = async () => {
    if (submitting) return;
    setSubmitting(true);
    const currentTimestamp = Date.now();
    const sheetsSchema = analyticsSchema?.appSheets || {};

    const getSheetIDList = (tableID: string) => {
      const { sheetIDsTBD } = getSheetChartsIDsTBD([tableID], [], sheetsSchema);
      return sheetIDsTBD ?? [];
    };
    const isNew = !tableSchema.tableID;
    const tableID = tableSchema?.tableID || uuid();
    const sendToBackend = {
      appID,
      tableID,
      isNew: isNew,
      tableSchema: {
        ...tableSchema,
        updatedAt: currentTimestamp,
      },
      sheetIDLists: isNew ? [] : getSheetIDList(tableID),
      sqlPresent: tableSchema?.sqlQuery ? true : false,
    };

    const saveData = await saveTableData({ finalTable: sendToBackend });
    if (saveData?.skip || saveData.msg.__d3__error || !saveData?.msg?.response?.isSuccess) {
      toast.error(
        mls(
          saveData?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Save Table. Please try again later."
        )
      );
      setSubmitting(false);
      return;
    }
    publishToProduction(appID);
    updateTableSchema(false, null, sendToBackend.tableSchema, sendToBackend.tableSchema?.tableID);
    setSubmitting(false);
    toast.success(mls('Tables Saved successfully'));
    setIsAnyChanges(false);
  };
  const deleteTableData = async ({ finalTable }: { finalTable: any }) => {
    const deleteRes = await _supistaAnalyticsApi(
      `${appID}/opType/deleteDataTable/tableOperations`,
      finalTable
    )
      .then(async (response) => {
        return { skip: false, msg: response };
      })
      .catch((error) => {
        toast.error(
          mls("Some issues encountered. Couldn't able to Delete Table. Please try again later.")
        );
        return { skip: true, msg: error };
      });

    return deleteRes;
  };
  const handleDeleteTable = async (tableToBeDeleted: any) => {
    if (submitting) return;
    const joinTableIDsTBD = getJoinTableIDs(tableID, joinTableSchema, []);
    // setSubmitting(true);
    const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
      [tableID],
      joinTableIDsTBD,
      sheetsSchema
    );
    const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
      chartIDs: chartIDSTBD ?? [],
      dashSchema: dashSchema,
      reportSchema: reportSchema,
    });

    const { customTableIDsTBD } = getTableIDSTBD({
      tableIDs: [tableToBeDeleted.tableID],
      joinTableSchema: joinTableSchema,
      customTableSchema: customTableSchema,
    });
    const sendToBackend = {
      appID,
      name: tableToBeDeleted.name,
      tableID: tableToBeDeleted.tableID,
      joinTableIDList: joinTableIDsTBD,
      customTableIDList: customTableIDsTBD,
      sheetIDList: sheetIDsTBD,
      dashIDObj: dashIDsTBD,
      reportIDObj: reportIDsTBD,
    };

    const deleteDataRes = await deleteTableData({ finalTable: sendToBackend });
    if (deleteDataRes?.skip || deleteDataRes.msg.__d3__error) {
      toast.error(
        mls(
          deleteDataRes?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Delete Table. Please try again later."
        )
      );
      setSubmitting(false);
      return;
    }
    updateTableSchema(true, tableID);
    publishToProduction(appID);
    setDeleteOpen(false);
    setSubmitting(false);
    closeModal();
  };

  const clearCustomColumn = () => {
    const currentTimestamp = Date.now();
    const finalTable = {
      ...tableSchema,
      sqlQuery: '',
      customColumnSchema: [],
      updatedAt: currentTimestamp,
    };
    setUpdateSqlCustomCommands((currentValue: number) => currentValue + 1);
    setTableSchema(finalTable);
    setIsAnyChanges(true);
  };
  return (
    <>
      <div className='editTableList'>
        <div className='modal-body pt-0 pb-0 px-xl-20 editTableTitle'>
          <div className='text-center'>
            <h1 className='mb-3'>{mls('Table Settings')}</h1>
            <div className='text-muted fw-bold fs-5'>{mls('Edit Table settings here')}.</div>
          </div>
        </div>
        <div className='edittableName'>
          <label className='form-label mt-5'>{mls('Table name')}:</label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={mls('Enter table name')}
            value={tableSchema?.tableLable ?? (tableSchema?.name || '')}
            onChange={(e) => {
              handleTableSchema('tableLable', e.target.value);
            }}
          />
        </div>

        <div className='editTableOptions'>
          <label className='form-label '>{mls('Description')}:</label>
          <textarea
            className='form-control form-control-solid'
            style={{ resize: 'none', height: 80 }}
            placeholder={mls('Table description')}
            value={tableSchema?.description || ''}
            maxLength={100}
            onChange={(e) => {
              handleTableSchema('description', e.target.value);
            }}
          />
          {(datetimeCols || []).length > 0 && (
            <>
              <br />
              <label className='form-label'>{mls('Default Datetime Column')}:</label>
              <Form.Select
                aria-label='Default Datetime Column'
                className='form-control form-control-solid'
                value={tableSchema?.defaultDatetimeCol || ''}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                  handleTableSchema('defaultDatetimeCol', evt.target.value)
                }
              >
                <option value=''>None</option>
                {(datetimeCols || []).map((ele: any) => (
                  <option key={ele} value={ele}>
                    {applyTableName(removeSysVariable(ele))}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
          {tableSchema?.customColumnSchema?.length > 0 ? (
            <ShowTable
              columnSchema={tableSchema?.customColumnSchema}
              tableIndex={0}
              mainTitle={mls('Custom Columns')}
              className={'CustomColumnDiv'}
              tableType={allTableType.customColumnType}
              onClear={clearCustomColumn}
            />
          ) : null}

          {/* 'Primary Table' field */}
          {tableSchema?.columnSchema?.length >= 0 ? (
            <TableCardDataTable
              columnSchema={tableSchema?.columnSchema}
              tableIndex={0}
              mainTitle={mls('Columns')}
              // selectColumn={selectColumn}
              // deselectColumn={deselectColumn}
              handleDataTypeChange={handleDataTypeChange}
              handleDisabledColumn={handleDisabledColumn}
              // selectionSchema={formik.values.columnSchema ?? []}
            />
          ) : null}
        </div>
        <div className='w-100 d-flex justify-content-end bg-white editTableButtons'>
          <button
            name='Table'
            className={'btn btn-sm btn-hover-danger'}
            // style={{ width: 120 }}
            onClick={() => setDeleteOpen(true)}
          >
            {mls('Delete Table')}
          </button>

          <button name='Save Table' className={'btn btn-sm ms-5 btn-primary'} onClick={handleSave}>
            <span className='loader-container'>
              {mls('Save Table')}
              {submitting && <span className='loader' />}
            </span>
          </button>
        </div>
      </div>
      {deleteOpen && (
        <DeleteTable
          appID={appID}
          setDeleteOpen={setDeleteOpen}
          deleteOpen={deleteOpen}
          handleDeleteTable={handleDeleteTable}
          tableID={tableID}
          tableSchema={tableSchema}
        />
      )}
    </>
  );
};

export default EditTableList;
const customColumnBoxStyle = {
  maxHeight: '60vh',
  overflow: 'auto',
};
interface showTableProps {
  columnSchema: any[];
  tableIndex?: number;
  mainTitle?: string;
  subTitle?: string;
  className?: string;
  tableType?: string;
  onClear?: Function;
}
const allTableType = {
  normalType: 'normalType',
  customColumnType: 'customColumnType',
};
const ShowTable = ({
  columnSchema,
  tableIndex = 0,
  mainTitle = '',
  subTitle = '',
  className = '',
  tableType = allTableType.normalType,
  onClear = () => {},
}: showTableProps) => {
  return (
    <div className={`p-5 mt-5 border rounded ${className}`}>
      {tableType === allTableType.customColumnType ? (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ width: 'calc( 100% - 4rem)' }}>
            <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
          </div>
          {/* <label className='btn btn-sm btn-light-primary btn-primary  btn-hover-danger'> */}
          <label className='btn btn-sm btn-hover-danger' onClick={() => onClear()}>
            Clear
          </label>
        </div>
      ) : mainTitle ? (
        <label className='form-label  fw-bolder fs-3 '>{mls(mainTitle)}</label>
      ) : null}
      {subTitle ? (
        <input
          placeholder={mls('Enter Join table name')}
          value={subTitle}
          className={clsx('form-control form-control-solid')}
          disabled
        />
      ) : null}
      <div className='w-90 mx-auto'>
        <div className='row mt-5'>
          <label className='col-5 fw-bold'>{mls('Column Name')}</label>
          <label className='col-7 fw-bold'>{mls('Column Type')}</label>
        </div>
        <hr />
        <div style={{ ...customColumnBoxStyle, overflowX: 'hidden' }}>
          {(columnSchema || []).map((tableCol: any, ix: number) => {
            return (
              <div className='row my-5' key={ix}>
                <div className='col-5 d-flex align-items-center justify-content-left'>
                  <CopyColumnToClipBoard columnName={tableCol.columnName} tableIndex={tableIndex}>
                    {tableCol?.name ?? tableCol?.columnName}
                  </CopyColumnToClipBoard>
                </div>
                <div className='col-7 d-flex align-items-center justify-content-left'>
                  <input
                    placeholder={mls('Enter Join table name')}
                    value={tableCol.dataType}
                    className={clsx('form-control form-control-solid')}
                    disabled
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Display warning if no column is selected from this table */}
      {columnSchema?.length === 0 && (
        <div className='alert alert-warning mt-2 text-center' role='alert'>
          {mls('No column is selected from this table!')}
        </div>
      )}
    </div>
  );
};
