import React, { useState } from 'react';
import { Slider } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';

const OpacitySlider = ({ color, setColor, dispalyLable = false, addWithTitle = '' }) => {
  const [selectedOpacity, setSelectedOpacity] = useState(color?.opacity ?? 100);
  const debounceSelectedOpacity = useDebounce(selectedOpacity);

  const handleOpacity = (e) => {
    if (e.target.value > 100) {
      return;
    }
    setSelectedOpacity(e.target.value);
  };

  useUpdateEffect(() => {
    if (!color) return;
    setColor({ ...color, opacity: selectedOpacity });
  }, [debounceSelectedOpacity]);

  useUpdateEffect(() => {
    if (!color) return;
    setSelectedOpacity(color?.opacity);
  }, [color?.opacity]);

  return (
    <>
      <div className='titleDiv'>
        {addWithTitle} {mls('Opacity')}
        <span>{selectedOpacity}%</span>
      </div>
      <Slider
        size='small'
        className='opacityRange'
        value={selectedOpacity}
        onChange={(e) => handleOpacity(e)}
        defaultValue={selectedOpacity}
        aria-label='Small'
        valueLabelDisplay={dispalyLable ? 'auto' : 'off'}
      />
    </>
  );
};

export default OpacitySlider;
