import FontSelector from './FontSelector';
import SolidColorSelector from './SolidColorSelector';
import ClosableWhiteDiv from '../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import { mls } from 'lib/multilanguagesupport';

const TitleDiv = (props, { show = false }) => {
  const { axisStyle, setAxisStyle, showLable = true } = props;

  const handleAxisLabel = (e) => {
    setAxisStyle((preElem) => {
      return { ...preElem, label: e.target.value };
      // if (preElem.isLabelEdited) {
      //   return { ...preElem, label: e.target.value };
      // } else {
      //   return { ...preElem, label: e.target.value, isLabelEdited: true };
      // }
    });
  };

  const handleColor = (elem) => {
    setAxisStyle((preElem) => {
      return { ...preElem, style: elem };
    });
  };
  const handleFontStyle = (elem) => {
    setAxisStyle((preElem) => {
      return { ...preElem, style: elem };
    });
  };
  const handleRotation = (elem) => {
    if (-360 <= elem.target.value) {
      if (elem.target.value <= 360) {
        setAxisStyle((preElem) => {
          return { ...preElem, valueStyle: { ...preElem.valueStyle, rotate: elem.target.value } };
        });
      }
    }
  };

  return (
    <ClosableWhiteDiv title={mls(`Title`)} show={show} autoCloseOff={true}>
      {showLable ? (
        <div className='TheInputDiv'>
          <div className='titleDiv'>{mls("Label")}</div>
          <input type='text' value={axisStyle?.label} onChange={(e) => handleAxisLabel(e)} />
        </div>
      ) : null}
      <FontSelector fontStyle={axisStyle?.style} setFontStyle={handleFontStyle} />
      {/* <div className='TheInputDiv'>
        <div className='titleDiv'>Rotate (deg)</div>
        <input
          type='number'
          value={axisStyle?.valueStyle?.rotate}
          onChange={(e) => handleRotation(e)}
        />
      </div> */}
      <SolidColorSelector color={axisStyle?.style} setColor={handleColor} />
    </ClosableWhiteDiv>
  );
};

export default TitleDiv;
