import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import {
  // STARTING_DATA_LENGTH,
  toggleObj,
} from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
const defaultMargin = { left: 0, top: 0, bottom: 0, right: 0 };

interface DateTimeFilterProps {
  width: number;
  toggle: string;
  handleToggle: Function;
  isToggleClicked: boolean;
  margin?: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  };
}
const DateTimeFilter = (dateTimeFilterProps: DateTimeFilterProps) => {
  const {
    width,
    toggle,
    // toggle: toggleValue,
    // handleToggle: handleToggleValue,
    handleToggle,
    isToggleClicked,
    margin = defaultMargin,
  } = dateTimeFilterProps;
  // const [toggle, setToggle] = useState(toggleValue);
  // const handleToggle = (action: string) => {
  //   setToggle(action);
  //   handleToggleValue(action);
  // };
  return (
    <>
      {width > 250 ? (
        <div
          style={{
            marginLeft: width < 500 ? 0 : margin.left,
          }}
          className='d-flex mb-4 ml-sm-0'
        >
          <div style={{ zIndex: 0 }} className={styles.toogleBtnContianer} data-toggle='buttons'>
            {Object.keys(toggleObj).map((item, index) => {
              return (
                <label
                  key={index}
                  className={` ${styles.toogleBtn} ${toggle === item ? styles.active : ''} `}
                >
                  <input
                    type='radio'
                    name='options'
                    id={item}
                    autoComplete='off'
                    defaultChecked={index === 0}
                    hidden
                    onClick={() => handleToggle(item)}
                  />{' '}
                  {toggleObj[item as keyof typeof toggleObj]}
                </label>
              );
            })}
          </div>
          {isToggleClicked && (
            <Box
              sx={{
                display: 'flex',
                marginLeft: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
        </div>
      ) : (
        <div>
          <ObjectDropDown
            object={toggleObj}
            dropDownValue={toggle}
            setdropDownValue={handleToggle}
          />
        </div>
      )}
    </>
  );
};
export default DateTimeFilter;
