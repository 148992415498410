import { defaultDateObject } from '../reusableData/formatterData';
import { dateTimeFormatter } from './formatter';

export const getDatesByPeriod = ({
  period = '',
  xDay = 0,
  startDate,
  endDate,
}: {
  period?: string;
  xDay?: number;
  startDate?: string;
  endDate?: string;
}) => {
  const TODAY = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Today</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: getDays({}),
          type: {
            format: 'DAY',
          },
        })}
      </div>
    </>
  );
  const YESTERDAY = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Yesterday</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: getDays({ minus: 1 }),
          type: {
            format: 'DAY',
          },
        })}
      </div>
    </>
  );
  const WEEK = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Week</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: getWeek({ minus: 7 }),
          type: {
            format: 'WEEK',
          },
        })}
        &nbsp;to&nbsp;
        {dateTimeFormatter({
          value: getWeek({}),
          type: {
            format: 'WEEK',
          },
        })}
      </div>
    </>
  );
  const MONTH = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Month</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: `${getMonth({ minus: 1 })}`,
          type: {
            format: 'MONTH',
          },
        })}
        &nbsp;to&nbsp;
        {dateTimeFormatter({
          value: `${getMonth({})}`,
          type: {
            format: 'MONTH',
          },
        })}
      </div>
    </>
  );
  const Quarter = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Quarter</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: `${getQuater({ minus: 1 })}`,
          type: {
            format: 'QUARTER',
          },
        })}
        &nbsp;to&nbsp;
        {dateTimeFormatter({
          value: `${getQuater({})}`,
          type: {
            format: 'QUARTER',
          },
        })}
      </div>
    </>
  );
  const Year = (
    <>
      <div className='dateFormatedTimeFrameTitle'>Year</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: `${getYear({ minus: 1 })}`,
          type: {
            format: 'YEAR',
          },
        })}
        &nbsp;to&nbsp;
        {dateTimeFormatter({
          value: `${getYear({})}`,
          type: {
            format: 'YEAR',
          },
        })}
      </div>
    </>
  );

  if (period === defaultDateObject.TODAY.period) {
    return TODAY;
  }
  if (period === defaultDateObject.YESTERDAY.period) {
    return YESTERDAY;
  }
  if (period === defaultDateObject.THISWEEK.period) {
    return WEEK;
  }
  if (period === defaultDateObject.THISMONTH.period) {
    return MONTH;
  }
  if (period === defaultDateObject.THISQUARTER.period) {
    return Quarter;
  }
  if (period === defaultDateObject.THISYEAR.period) {
    return Year;
  }
  if (period === defaultDateObject.LAST7DAYS.period) {
    return LastDays({ days: 7 });
  }
  if (period === defaultDateObject.LAST30DAYS.period) {
    return LastDays({ days: 30 });
  }
  if (period === defaultDateObject.LASTXDAYS.period) {
    return LastDays({ days: xDay });
  }
  if (period === defaultDateObject.CUSTOM.period) {
    return CustomDate({ startDate, endDate });
  }
};

interface getMonthProps {
  minus?: number;
  plus?: number;
}

const getDays = ({ minus = 0, plus = 0 }: getMonthProps) => {
  const now = new Date();
  const date: any = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
  const currentYear = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  const monthNumberWithZero = monthNumber < 10 ? '0' + monthNumber : monthNumber;
  const dayNumber = date.getDate();

  const dayNumberWithZero = dayNumber < 10 ? '0' + dayNumber : dayNumber;
  return `${currentYear}-${monthNumberWithZero}-${dayNumberWithZero}`;
};
const getWeek = ({ minus = 0, plus = 0 }: getMonthProps) => {
  const now = new Date();
  const date: any = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
  const currentYear = date.getFullYear();
  const startDate: any = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));

  const weekNumber = Math.ceil(days / 7);
  const weekNumberZero = weekNumber < 10 ? '0' + weekNumber : weekNumber;
  return `${currentYear}${weekNumberZero}`;
};
const getMonth = ({ minus = 0, plus = 0 }: getMonthProps) => {
  const now = new Date();
  const date: any = new Date(now.getFullYear(), now.getMonth() - minus + plus, now.getDate());
  const currentYear = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  const monthNumberWithZero = monthNumber < 10 ? '0' + monthNumber : monthNumber;
  return `${currentYear}${monthNumberWithZero}`;
};
const getQuater = ({ minus = 0, plus = 0 }: getMonthProps) => {
  const now = new Date();
  const date: any = new Date(
    now.getFullYear(),
    now.getMonth() - minus * 4 + plus * 4,
    now.getDate()
  );
  const currentYear = date.getFullYear();
  const quarterNumber = Math.floor(date.getMonth() / 3 + 1);
  const quarterNumberWithZero = quarterNumber < 10 ? '0' + quarterNumber : quarterNumber;
  return `${currentYear}${quarterNumberWithZero}`;
};
const getYear = ({ minus = 0, plus = 0 }: getMonthProps) => {
  const now = new Date();
  const date: any = new Date(now.getFullYear() - minus + plus, now.getMonth(), now.getDate());
  const currentYear = date.getFullYear();
  return `${currentYear}`;
};
const LastDays = ({ days = 0 }) => {
  return (
    <>
      <div className='dateFormatedTimeFrameTitle'>Last {days} Days</div>
      <div className='dateFormatedTimeFrameDetails'>
        {dateTimeFormatter({
          value: `${getDays({ minus: days })}`,
          type: {
            format: 'DAY',
          },
        })}
        &nbsp;to&nbsp;
        {dateTimeFormatter({
          value: `${getDays({})}`,
          type: {
            format: 'DAY',
          },
        })}
      </div>
    </>
  );
};

const CustomDate = (dateData: any) => {
  if (!dateData?.startDate) {
    return <></>;
  }
  const startDateFull = new Date(dateData?.startDate || 0);
  const endDateFull = new Date(dateData?.endDate || 0);
  const startDate = `${startDateFull.getDate()}-${
    startDateFull.getMonth() + 1
  }-${startDateFull.getFullYear()}`;
  const endDate = `${endDateFull.getDate()}-${
    endDateFull.getMonth() + 1
  }-${endDateFull.getFullYear()}`;
  return (
    <>
      <div className='dateFormatedTimeFrameDetails'>
        {startDate}
        &nbsp;to&nbsp;
        {endDate}
      </div>
    </>
  );
};
