import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
// Redux
// https://github.com/rt2zz/redux-persist
// import {PersistGate} from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
// import * as _redux from './setup'
// import store, {persistor} from './setup/redux/Store'
// Axios
// import axios from 'axios'
// import {Chart, registerables} from 'chart.js'

// Apps
import { App } from './app/App';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import store from './lib/reusable-components/reusableUtils/redux/store';
// import * as Sentry from '@sentry/react';
// const Example = require('./Example').default;

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store)

// Chart.register(...registerables)

// Sentry.init({
//   dsn: 'https://10848b540e514ef5935033c1e0d812db@o448268.ingest.sentry.io/4505398184771585',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         'https://test.supista.com/api/',
//         'https://analytics-api.supista.com',
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
      <App basename={PUBLIC_URL} />
      {/* </PersistGate> */}
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
);
