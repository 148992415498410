import React from 'react';
import { allDropableID, defaultChartSetting } from '../../../data/defaultChartSchema';
import DragableAxis from '../../../components/BuildComponents/DragableAxis';
import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

interface pivotTableBuildCaseProps {
  stateProps: any;
}
const PivotTableBuildCase = ({ stateProps }: pivotTableBuildCaseProps) => {
  const {
    yAxisData,
    setYAxisData,

    setChartYAxisStyle,
    xAxisData,
    setXAxisData,
    setChartXAxisStyle,

    handleResetToolTip,
    isInsight,
    setIsInsight,
    chartType,
  } = stateProps;

  const { xAxisId, yAxisId, toolTip } = allDropableID;

  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('X-Axis')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>

      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
      </div>
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default PivotTableBuildCase;
