import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { getCircleAndEmployeesGroupedData } from 'lib/supista-kpi/utils/supistaKpiHelperFunction';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SelectTargetPoint from './SelectTargetPoint';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import { FaPlus } from 'react-icons/fa';
import { defaultCircleWiseTargetPoint } from './addTargetPointData';
import { mls } from 'lib/multilanguagesupport';

interface addAllTargetPointProps {
  selectedKpiTimeFrameRange: string;
  stateProps: { [key: string]: any };
  disableCirclesDataArray: string[];
}
const AddAllTargetPoint = ({
  selectedKpiTimeFrameRange,
  stateProps,
  disableCirclesDataArray,
}: addAllTargetPointProps) => {
  const [tempCircleWiseTargetPoint, setTempCircleWiseTargetPoint] = useState(
    defaultCircleWiseTargetPoint
  );

  const [isFilterPopUp, setIsFilterPopUp] = useState(false);
  const { allTableData, appID, isJoinedTable, sheetID } = stateProps;
  const {
    circlesData,
    employeesData,
    kpiProjectedTargetPoints,
    setKpiTargetPoints,
    kpiTargetPoints,
  } = stateProps;

  const [tempDataUpdated, setTempDataUpdated] = useState(false);
  const disableAddButton = !tempCircleWiseTargetPoint?.targetPointID?.id;
  const handleAdd = () => {
    // if (!tempCircleWiseTargetPoint.circleID) {
    //   toast.warn('Select Any Circle');
    //   return;
    // }
    if (disableAddButton) {
      return;
    }
    if (!tempCircleWiseTargetPoint.targetValue.value) {
      if (tempCircleWiseTargetPoint?.targetValue?.value === '') {
        toast.warn('Select Any Target Point');
        return;
      }
      if (tempCircleWiseTargetPoint?.targetValue?.value ?? null !== 0) {
        toast.warn('Select Any Target Point');
        return;
      }
    }
    const finalElem = {
      // id: uuidv4(),
      ...tempCircleWiseTargetPoint,
    };
    setTempCircleWiseTargetPoint(defaultCircleWiseTargetPoint);
    setKpiTargetPoints({
      ...kpiTargetPoints,
      allTargetPoints: [finalElem, ...(kpiTargetPoints?.allTargetPoints ?? [])],
      selectedTargetPointID: finalElem.targetPointID,
    });

    setTempDataUpdated(!tempDataUpdated);
  };

  const circleAndEmployeesGroupedData = getCircleAndEmployeesGroupedData({
    circlesData,
    employeesData,
  });
  const filterNumber = Object.keys(tempCircleWiseTargetPoint.targetFilter ?? {}).length;
  return (
    <>
      {isFilterPopUp ? (
        <FilterDiv
          isPopUp={isFilterPopUp}
          setIsPopUp={setIsFilterPopUp}
          chartFilter={tempCircleWiseTargetPoint.targetFilter}
          setChartFilter={(action: { [key: string]: any }) =>
            setTempCircleWiseTargetPoint({
              ...tempCircleWiseTargetPoint,
              targetFilter: action,
            })
          }
          allTableData={allTableData}
          appID={appID}
          isJoinedTable={isJoinedTable}
          sheetID={sheetID}
        />
      ) : null}
      <div className='circleWiseTargetPointTableElem addAllTargetPoint'>
        <div className='selectCellDiv'></div>
        <div className='circleCellDiv'>
          <ObjectDropDown
            object={circleAndEmployeesGroupedData}
            dropDownValue={tempCircleWiseTargetPoint?.targetPointID?.id ?? ''}
            disableArray={disableCirclesDataArray}
            setdropDownValue={(action: { [key: string]: any }) => {
              setTempCircleWiseTargetPoint({
                ...tempCircleWiseTargetPoint,
                targetPointID: {
                  id: action.data,
                  type: action.groupKey,
                },
              });
            }}
            inputPlaceHolder={mls('Select')}
            groupDropDown
            returnGroupKeyWithValue
          />
        </div>
        <div className='targetPoinCellDiv'>
          <SelectTargetPoint
            kpiProjectedTargetPoints={kpiProjectedTargetPoints}
            selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
            targetPoint={tempCircleWiseTargetPoint.targetValue}
            setTargetPoint={(action: any) => {
              setTempCircleWiseTargetPoint({
                ...tempCircleWiseTargetPoint,
                targetValue: action,
              });
            }}
            allTargetPointsElem={tempCircleWiseTargetPoint}
            tempDataUpdated={tempDataUpdated}
          />
        </div>
        <div className='filterCellDiv'>
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => {
              setIsFilterPopUp((currentData: boolean) => !currentData);
            }}
          >
            {mls('Filters')}
            {filterNumber >= 1 ? (
              <div className='filterButtonNumber'>{filterNumber >= 100 ? '99+' : filterNumber}</div>
            ) : null}
          </button>
        </div>
        <div
          className={`${disableAddButton ? 'disableAddButton' : ''} removeCellDiv rightCellDiv`}
          onClick={() => handleAdd()}
        >
          <FaPlus />
        </div>
      </div>
    </>
  );
};

export default AddAllTargetPoint;
