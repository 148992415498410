import { defaultNeededFormattingValue } from 'lib/reusable-components/reusableData/formatterData';
import {
  defaultDateKey,
  defaultCardDisplayType,
  defaultCardCompare,
  defaultCardDataPrefix,
  defaultCardTextSuffix,
  defaultCardDataSuffix,
} from './defaultChartSchema';

export const normalAxisSchema = (object) => {
  const { originalDataType, name, tableName, columnName, operations, dataType, columnDropNumber } =
    object;
  const newObj = {
    label: name,
    originalDataType: originalDataType,
    dataType: dataType,
    operations: operations || {},
    column: `${tableName}.${columnName}`,
    uniqueColumnName: `${tableName}.${columnName}-${columnDropNumber}`,
  };
  return newObj;
};

export const cardAxisSchema = (object) => {
  const { originalDataType, name, tableName, columnName, operations, dataType, columnDropNumber } =
    object;
  const newObj = {
    label: name,
    originalDataType: originalDataType,
    dataType: dataType,
    operations: operations || {},
    column: `${tableName}.${columnName}`,
    uniqueColumnName: `${tableName}.${columnName}-${columnDropNumber}`,
    // defaultCompare: defaultCardCompare[0],
    isCompare: false,
    dataPrefix: defaultCardDataPrefix,
    textSuffix: defaultCardTextSuffix,
    dataSuffix: defaultCardDataSuffix,
    formatter: defaultNeededFormattingValue?.num,
  };
  return newObj;
};
