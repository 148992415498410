import React, { useRef, useState } from 'react';
import { timeFrameColumnColor } from '../kpiTimeFrameData';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';
import { checkForColumnLableDuplicacy } from '../utils/kpiTimeFrameUtils';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
interface KpiCategorysColumnProps {
  selectecKpiCategorys: { [key: string]: any };
  kpiCategorysIndex: number;
  kpiCategorys: { [key: string]: any };
  handleKpiCategory: Function;
  handleKpiCategoryRemove: Function;
}
const optionObject = {
  remove: {
    key: 'remove',
    name: 'Remove',
  },
};
const KpiCategorysColumn = ({
  selectecKpiCategorys,
  kpiCategorysIndex,
  kpiCategorys,
  handleKpiCategory,
  handleKpiCategoryRemove,
}: KpiCategorysColumnProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isInputState, setIsInputState] = useState(false);
  const [tempLabel, setTempLabel] = useState(selectecKpiCategorys?.label ?? '');

  const handleSwithToInput = (action: boolean) => {
    if (action) {
      setTempLabel(selectecKpiCategorys?.label ?? '');
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 1);
    } else {
      if (tempLabel === selectecKpiCategorys?.label) {
        setIsInputState(action);
        return;
      }
      if (tempLabel === '') {
        setIsInputState(action);
        return;
      }
      const isKeyPresent = checkForColumnLableDuplicacy({
        kpiCategorys: kpiCategorys,
        label: tempLabel,
      });

      if (isKeyPresent) {
        toast.error(mls("You're repeating the same column name again."));
        setTempLabel(selectecKpiCategorys?.label);
      }
      handleKpiCategory({
        kpiData: { ...selectecKpiCategorys, label: tempLabel },
        kpiCategorysIndex: kpiCategorysIndex,
      });
    }
    setIsInputState(action);
  };
  const handleOptionDropdown = (action: any) => {
    if (optionObject.remove.key === action) {
      handleKpiCategoryRemove({ kpiCategorysIndex: kpiCategorysIndex });
    }
  };

  return (
    <div
      className='gridCell upperColumnGridCell'
      style={{
        backgroundColor: timeFrameColumnColor[kpiCategorysIndex % timeFrameColumnColor.length],
      }}
    >
      {isInputState ? (
        <div
          className='editCellInputTextDiv'
          onBlur={() => {
            handleSwithToInput(false);
          }}
          onAbort={() => {
            handleSwithToInput(false);
          }}
        >
          <input
            type='text'
            ref={inputRef}
            value={tempLabel}
            onChange={(e) => setTempLabel(e.target.value)}
          />
        </div>
      ) : (
        <>
          <div
            className='titleGridCellLableDiv'
            onClick={() => {
              handleSwithToInput(true);
            }}
          >
            {selectecKpiCategorys?.label ?? ''}{' '}
          </div>
          <ObjectDropDown
            object={optionObject}
            dropDownValue={''}
            setdropDownValue={handleOptionDropdown}
            ObjectInObject
            isChildernInsert
            className='titleGridCellOptionDiv'
          >
            <HiOutlineDotsVertical />
          </ObjectDropDown>
        </>
      )}
    </div>
  );
};

export default KpiCategorysColumn;

interface KpiPeriodRangeEditCellDivProps {
  dataKey: any;
  selectecKpiCategorysData: { [key: string]: any };
  kpiDataUpdate: Function;
  kpiCategorysIndex: number;
  currentyear: number;
  timeFrameTypeKey: number;
  handlePasteUpdate: Function;
  timeFrameType: any;
}
export const KpiPeriodRangeEditCellDiv = ({
  dataKey,
  selectecKpiCategorysData,
  kpiDataUpdate,
  kpiCategorysIndex,
  currentyear,
  timeFrameTypeKey,
  handlePasteUpdate,
  timeFrameType,
}: KpiPeriodRangeEditCellDivProps) => {
  const [isInputState, setIsInputState] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSwithToInput = (action: boolean) => {
    if (action) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 1);
    }
    setIsInputState(action);
  };
  const handleCellUpdate = (value: string) => {
    let finalValue: number | null = Number(value);
    if (value === '') {
      finalValue = null;
    }
    kpiDataUpdate({ kpiData: { ...selectecKpiCategorysData, [dataKey]: finalValue } });
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const windowData: any = window;
    const clipboardData = event?.clipboardData || windowData?.clipboardData;
    let pastedData = clipboardData.getData('text');

    // Remove \r characters
    pastedData = pastedData.replace(/\r/g, '');

    // Split pasted data into rows and columns
    const rows = pastedData.split('\n').map((row) => row.split('\t'));

    // Transpose rows and columns
    const cellsData: number[][] | null[][] = [];
    let isNullError = false;
    rows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        let numberCell: number | null = Number(cell);
        if (isNaN(numberCell)) {
          numberCell = null;
          isNullError = true;
        }
        if (cell === '') {
          numberCell = null;
        }
        cellsData[cellIndex] = cellsData[cellIndex] || [];
        cellsData[cellIndex][rowIndex] = numberCell;
      });
    });
    if (isNullError) {
      toast.warning('Treating Non Numarical value as a NULL');
    }
    handlePasteUpdate({
      currentyear,
      timeFrameTypeKey,
      kpiCategorysIndex,
      cellsData,
    });
  };

  const handleOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      let yearIncrementer = currentyear;
      let typeIncrementer = Number(timeFrameTypeKey);
      const isFind = timeFrameType.length >= typeIncrementer + 1;
      typeIncrementer++;
      if (!isFind) {
        typeIncrementer = 1;
        yearIncrementer++;
      }
      const convertToTwoDigitFormat = (number: number | string) => {
        // Convert number to string
        let numString = number.toString();

        // If number has only one digit, prepend '0' to it
        if (numString.length === 1) {
          return '0' + numString;
        } else {
          // If number has two or more digits, return the original number as string
          return numString;
        }
      };
      const final = `${yearIncrementer}${convertToTwoDigitFormat(typeIncrementer)}`;
      setTimeout(() => {
        const nextSibiling = document.getElementById(`editCellInputTextDivTempID-${final}`);
        if (nextSibiling !== null) {
          nextSibiling.focus();
          // nextSibiling.select();
        }
      }, 1);
    }
  };
  return (
    <div
      className='gridCell valueColumnGridCell'
      onClick={() => {
        handleSwithToInput(true);
      }}
      onBlur={() => {
        handleSwithToInput(false);
      }}
      onAbort={() => {
        handleSwithToInput(false);
      }}
      onKeyDown={handleOnEnter}
      id={`editCellInputTextDivTempID-${dataKey}`}
    >
      {/* <input
        type='number'
        ref={inputRef}
        value={selectecKpiCategorysData?.[dataKey] ?? null}
        onChange={(e) => handleCellUpdate(e.target.value)}
        onPaste={(e) => handlePaste(e)}
        id={`editCellInputTextDivTempID-${dataKey}`}
      /> */}
      {isInputState ? (
        <div className='editCellInputTextDiv'>
          <input
            type='number'
            ref={inputRef}
            value={selectecKpiCategorysData?.[dataKey] ?? null}
            onChange={(e) => handleCellUpdate(e.target.value)}
            onPaste={(e) => handlePaste(e)}
            // id={`editCellInputTextDivTempID-${dataKey}`}
          />
        </div>
      ) : (
        <>
          <div className='editCellInputTextDiv'>{selectecKpiCategorysData?.[dataKey]}</div>
        </>
      )}
    </div>
  );
};
