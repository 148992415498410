import { useEffect, useState } from 'react';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import DownloadData from './DownloadData';
import { mls } from 'lib/multilanguagesupport';
import Select from 'react-select';

const timeframeList = [
  { value: 7, label: '1 Week' },
  { value: 30, label: '1 Month' },
  { value: 90, label: '3 Months' },
  { value: 180, label: '6 Months' },
  { value: 365, label: '1 Year' },
  { value: 1095, label: '3 Years' },
  { value: 'ALL', label: 'All Data' },
];

const DashFilterStrip = ({
  appID,
  filterSchema,
  setFilterSchema,
  dashTableSchema,
  dashID,
  timeframe,
  timeFrameObj,
  setTimeFrameObj,
  defaultTimeframes,
  payloadData,
  dashName,
}) => {
  const [show, setShow] = useState(false);
  const [chartFilter, setChartFilter] = useState(filterSchema || {});
  const [download, setDownload] = useState(false);

  const handleShow = () => setShow((currentData) => !currentData);
  const filterLength = Object.keys(filterSchema || {}).length;

  const handleTimeframe = (event) => {
    let newTimeframeData = { ...(timeFrameObj || {}) };
    const filterKeys = Object.keys(newTimeframeData);
    const timeframeValue = event.value;

    if (timeframeValue !== 'CUSTOM' && filterKeys.length !== 0 && timeframeValue !== 'ALL') {
      filterKeys.forEach((key) => {
        const tempFilter = {
          ...newTimeframeData[key],
          value: {
            period: 'LASTXDAYS',
            xDay: Number(timeframeValue),
          },
        };
        newTimeframeData = { ...newTimeframeData, [key]: tempFilter };
      });
    } else newTimeframeData = {};
    setTimeFrameObj(newTimeframeData);
    timeframe.current = timeframeValue;
  };
  const handleClick = (event) => {
    if (timeframe.current === 'CUSTOM') {
      event.preventDefault();
      setShow(true);
    }
  };

  useEffect(() => {
    if (show === false) {
      setFilterSchema(chartFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setChartFilter(filterSchema || {});
    let filtersHaveTimeframeCol = false;
    Object.keys(chartFilter).forEach((key) => {
      if (Object.keys(defaultTimeframes).includes(key)) {
        filtersHaveTimeframeCol = true;
      }
    });
    if (filtersHaveTimeframeCol && timeframe.current !== 'CUSTOM') {
      setTimeFrameObj({});
      timeframe.current = 'CUSTOM';
    } else if (timeframe.current === 'CUSTOM' && !filtersHaveTimeframeCol) {
      timeframe.current = 180;
      setTimeFrameObj(defaultTimeframes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashID, filterSchema]);

  const customStyles = {
    control: (base) => ({
      ...base,
      cursor: timeframe.current === 'CUSTOM' ? 'pointer' : 'default',
      backgroundColor: '#f5f8fa',
      borderColor: '#f5f8fa',
      boxShadow: 'none',
      border: 0,
      borderRadius: '6px',
      minWidth: '200px', // Set your desired minimum width here
    }),
    indicatorSeparator: (provided) => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      boxShadow: 'none',
      zIndex: 9999,
    }),
  };

  return (
    <>
      <div className='pb-6 '>
        <div className='p-3  d-flex flex-column flex-column-fluid bg-white'>
          {/* <div className='d-flex'>
              <h4 className='d-flex align-items-center mb-0 me-4'>Filters: </h4>
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>{renderInputFields()}</div>
                <button className='btn btn-sm fw-bold btn-primary' type='button'>
                  More Filters
                </button>
              </div>
            </div> */}
          <div className='container mt-3'>
            <div className='row '>
              <div className='col-lg-6 col-md-6 col-sm-6 mb-3 d-flex align-items-center'>
                {Object.keys(defaultTimeframes).length > 0 && (
                  <>
                    <h4 className='mb-0 me-4'>{mls('Timeframe')}: </h4>
                    <div onClick={handleClick}>
                      <Select
                        name='Set Timeframe'
                        value={
                          timeframeList.find((opt) => opt.value === timeframe.current) || {
                            value: 'CUSTOM',
                            label: 'Custom Range',
                          }
                        }
                        onChange={handleTimeframe}
                        options={timeframeList}
                        styles={customStyles}
                        onClick={handleClick}
                        isSearchable={false}
                        menuIsOpen={timeframe.current === 'CUSTOM' ? false : undefined}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className='col-lg-6 col-md-6 col-sm-6 mb-3 d-flex align-items-center justify-content-end'>
                {/* <h6 className='mb-0 me-2 text-success'>{`${filterLength} ${
                  filterLength > 1 ? 'filters' : 'filter'
                } applied`}</h6> */}
                <button
                  className='btn btn-sm fw-bold btn-primary me-4'
                  type='button'
                  onClick={() => setDownload(true)}
                >
                  {mls('Download Data')}
                  <i className='bi bi-box-arrow-down ms-2 p-0' style={{ fontSize: '1.4rem' }}></i>
                </button>

                <button
                  className='btn btn-sm fw-bold btn-primary'
                  type='button'
                  onClick={handleShow}
                >
                  {mls('Filters')}
                  <span
                    className='symbol-label fw-bolder text-success position-relative translate-middle'
                    style={{ top: '-16px', left: '28px' }}
                  >
                    {filterLength}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <FilterDiv
          isPopUp={show}
          setIsPopUp={setShow}
          setChartFilter={setChartFilter}
          allTableData={dashTableSchema}
          chartFilter={chartFilter}
          appID={appID}
          isDashboard={true}
        />
      )}
      {download && (
        <DownloadData
          appID={appID}
          payloadData={payloadData}
          setModalOpen={setDownload}
          isModalOpen={download}
          reportName={dashName}
          reportType='dash'
        />
      )}
    </>
  );
};

export default DashFilterStrip;
