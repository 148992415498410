/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useEffect, useRef, useState } from 'react';
import { Redirect, Switch, Route, useHistory, withRouter } from 'react-router-dom';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import getAppID from 'utils/getAppID';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
// import { fetchDataToPlot, supistaApiGet } from '../network/apiConnections';
import getDeviceLanguage from 'lib/multilanguagesupport/getDeviceLanguage';
import { mls } from 'lib/multilanguagesupport';
import useAuthenticate from 'lib/reusable-components/reusableUtils/commons/authenticate';
import { useUpdateKpiSchema } from 'lib/reusable-components/reusableFunction/KpiAllSchema';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage } from 'lib/auth-ui/AuthPage';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import { _fetchDataToPlot, _supistaApiGet } from 'lib/server-connection/connections';
import { ErrorBoundary } from 'lib/reusable-components/Components/ErrorBoundary/ErrorBoundary';

const Routes: FC = () => {
  const history = useHistory();
  let appID = getAppID(history.location.pathname);
  const dispatch = useDispatch();

  const fetchTryNumber = useRef(0);
  const [tryNumber, setTryNumber] = useState(0);
  // const [connectionError, setConnectionError] = useState(false);

  const loginData = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.LOGIN);
  // const analyticsSchema = useStoreSchema(
  //   reduxConstants.STORE_NAME,
  //   reduxConstants.config.ANALYTICS_SCHEMA
  // );
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const updateKpiSchema = useUpdateKpiSchema();

  const isAuthenticated = useAuthenticate(appID);
  const appConnected = useRef(loginData?.appsConnected?.[0]);

  const fetchMLS = async (lang: string | null | undefined) => {
    try {
      const currentLanguage = lang || localStorage.getItem('__d3__language') || getDeviceLanguage();
      if (currentLanguage) {
        const appMLS = await _supistaApiGet(`appMLS/${appConnected.current}/${currentLanguage}`);
        dispatch(
          create({
            setPath: reduxConstants.config.APP_MLS,
            value: appMLS,
          })
        );
        localStorage.setItem('__d3__languageUpdate', 'true');
      }
    } catch (error) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls(
              'There was a problem while fetching App Current Language, please try again later!'
            ),
            time: 5,
            isActive: true,
          },
        })
      );
    }
  };

  const fetchData = async () => {
    fetchTryNumber.current += 1;
    setTryNumber((num) => (num += 1));
    const appID = appConnected.current;
    if (!appID) {
      return;
    }
    try {
      updateKpiSchema({ appID });

      const res = await _fetchDataToPlot({ appID }, `${appID}/allSchema`);
      if (Object.keys(res).length > 0 && res.__d3__error !== true) {
        dispatch(
          create({
            setPath: reduxConstants.config.ANALYTICS_SCHEMA,
            value: res.response,
          })
        );

        const appSchema = await _supistaApiGet(`appSchema/${appID}`);

        dispatch(
          create({
            setPath: reduxConstants.config.ALL_TABLES,
            value: Object.keys(res?.response?.appDatatable || []),
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.JOIN_TABLES,
            value: Object.keys(res?.response?.appJointable || []),
          })
        );
        dispatch(
          create({
            setPath: reduxConstants.config.CUSTOM_TABLES,
            value: Object.keys(res?.response?.appCustomtable || []),
          })
        );

        if (appSchema?.app) {
          dispatch(
            create({
              setPath: reduxConstants.config.APP_SCHEMA,
              value: appSchema,
            })
          );

          localStorage.setItem(
            '__d3__allLanguages',
            JSON.stringify(appSchema.app?.activeLanguages || ['English'])
          );
          localStorage.setItem(
            '__d3__defaultLanguage',
            appSchema.app?.defaultLanguage || 'English'
          );
          if (!localStorage.getItem('__d3__language')) {
            localStorage.setItem(
              '__d3__language',
              appSchema.app?.defaultLanguage || getDeviceLanguage()
            );
            fetchMLS(appSchema.app?.defaultLanguage || getDeviceLanguage());
          }
        }
        setTryNumber(-10);
      } else if (fetchTryNumber.current < 5) {
        setTimeout(() => {
          fetchData();
        }, 1000);
      } else {
        setTryNumber(10);
      }
    } catch (error) {
      if (fetchTryNumber.current < 5) {
        setTimeout(() => {
          fetchData();
        }, 1000);
      } else {
        setTryNumber(10);
      }

      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while fetching App Schema, please try again later!'),
            time: 5,
            isActive: true,
          },
        })
      );
    }
  };
  // useState(() => fetchData());
  // useState(() => fetchMLS(null));

  useEffect(() => {
    appConnected.current = loginData?.appsConnected?.[0];
    // calling appSchema onlywhen the apps are connected properly and user loggedIn.
    if (appConnected.current) {
      fetchData();
      fetchMLS(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    if (!isAuthenticated) {
      setTryNumber(0);
      fetchTryNumber.current = 0;
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Route component={AuthPage} />;
  }

  if (!appID || appID !== appConnected.current) {
    appID = appConnected.current;
    return <Redirect to={`/${appID}`} />;
  }

  if (tryNumber >= 0 && tryNumber <= 5) {
    return <NoAssetScreen assetType='AppSchemaLoading' />;
  }
  if (tryNumber > 5) {
    return <NoAssetScreen assetType='ConnectionFailed' />;
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route path='/error' component={ErrorsPage} />
        {!isAuthenticated ? (
          <Route component={AuthPage} />
        ) : (
          <PrivateRoutes appName={appSchema?.app?.name} />
        )}
        <Redirect to={`/${appID}`} />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(Routes);
