import React, { useEffect, useMemo, useState } from 'react';
import {
  defaultOperation,
  allDropableID,
  defaultDataType,
  defaultPCT_BY_GROUP,
} from '../../data/defaultChartSchema';
// import PopUpDiv from '../../features/PopUpDiv/PopUpDivChild';
import DropDown from '../../features/DropDown/DropDown';
import { checkForNumber } from '../../utils/dataFunctions';
import { AiOutlineSelect } from 'react-icons/ai';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { useDispatch, useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { deleteDateTimeToggle } from 'lib/reusable-components/reusableFunction/formatter';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { chartAxisKey } from 'lib/chart-settings-lib/utils/dragableFunctions';
const actions = {
  // {
  //     name:"Rename",
  //     key:"RENAME",
  // },
  moveUp: {
    name: 'Move Up',
    key: 'MOVEUP',
  },
  moveDown: {
    name: 'Move Down',
    key: 'MOVEDOWN',
  },
  remove: {
    name: 'Remove',
    key: 'REMOVE',
  },
  // {
  //     name:"Move To",
  //     key:"MOVETO",
  // },
};
const AxisOpration = ({ props }) => {
  const {
    elem,
    removeData,
    index,
    axisId,
    stateProps,
    axisData,
    setAxisData,
    handleDropDown,
    isDropDownOpen,
    setIsPctByGroupPopUp,
    dragableItemRef,
  } = props;

  const { chartType, setChartToolTip, chartID } = stateProps;

  const handleSelection = (key, index) => {
    switch (key) {
      case actions.moveUp.key: {
        if (index !== 0) {
          const newIndex = index - 1;
          const items = Array.from(axisData);
          const [reorderedItem] = items.splice(index, 1);
          items.splice(newIndex, 0, reorderedItem);
          setAxisData(items);
        } else {
          return;
        }
        break;
      }

      case actions.moveDown.key:
        if (index !== axisData.length - 1) {
          const newIndex = index + 1;
          const items = Array.from(axisData);
          const [reorderedItem] = items.splice(index, 1);
          items.splice(newIndex, 0, reorderedItem);
          setAxisData(items);
        } else {
          return;
        }

        break;

      case actions.remove.key:
        removeData(index, axisId);

        break;

      case defaultOperation.num.PCT_BY_GROUP.key:
        setIsPctByGroupPopUp((currentData) => !currentData);
        setAxisData((currentData) => {
          const items = Array.from(currentData);
          const [reorderedItem] = items.splice(index, 1);
          const newSourceItem = {
            ...reorderedItem,
            operations: { ...reorderedItem.operations, type: key },
          };
          setChartToolTip((currentToolTip) => {
            const newStyle = currentToolTip.style.map((styleElem) => {
              if (styleElem.uniqueColumnName === reorderedItem.uniqueColumnName) {
                return { ...styleElem, label: `${styleElem.originalLabel} ( ${key} )` };
              }
              return styleElem;
            });
            return { ...currentToolTip, style: newStyle };
          });
          items.splice(index, 0, newSourceItem);
          return items;
        });
        break;

      default:
        setAxisData((currentData) => {
          const items = Array.from(currentData);
          const [reorderedItem] = items.splice(index, 1);
          const newSourceItem = {
            ...reorderedItem,
            operations: { type: key },
          };
          setChartToolTip((currentToolTip) => {
            const newStyle = currentToolTip.style.map((styleElem) => {
              if (styleElem.uniqueColumnName === reorderedItem.uniqueColumnName) {
                return { ...styleElem, label: `${styleElem.originalLabel} ( ${key} )` };
              }
              return styleElem;
            });
            return { ...currentToolTip, style: newStyle };
          });
          items.splice(index, 0, newSourceItem);
          return items;
        });
        break;
    }
    handleDropDown(false);
  };
  const chartKey = () => {
    if (axisId === allDropableID.xAxisId) {
      return chartType?.axis?.axisType?.xAxis;
    }
    if (axisId === allDropableID.yAxisId) {
      return chartType?.axis?.axisType?.yAxis;
    }
    if (axisId === allDropableID.groupId) {
      return chartType?.axis?.axisType?.group;
    }
    if (axisId === allDropableID.groupId) {
      return chartType?.axis?.axisType?.group;
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (dragableItemRef.current && !dragableItemRef.current.contains(event.target)) {
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragableItemRef, isDropDownOpen]);
  return (
    <>
      <div className={isDropDownOpen ? 'popUpOptions open' : 'popUpOptions'}>
        <ul>
          <li
            className={index === 0 ? 'disable' : undefined}
            onClick={() => {
              handleSelection(actions.moveUp.key, index);
            }}
          >
            {actions.moveUp.name}
          </li>
          <li
            className={index === axisData.length - 1 ? 'disable' : undefined}
            onClick={() => handleSelection(actions.moveDown.key, index)}
          >
            {actions.moveDown.name}
          </li>
          <li onClick={() => handleSelection(actions.remove.key, index)}>{actions.remove.name}</li>
          <OprationsArray
            elem={elem}
            handleSelection={handleSelection}
            chartAxisKey={chartAxisKey}
            index={index}
            chartType={chartType}
            chartID={chartID}
            axisId={axisId}
          />
        </ul>
      </div>
    </>
  );
};

export default AxisOpration;

const OprationsArray = ({
  elem,
  handleSelection,
  chartAxisKey,
  index,
  chartType,
  chartID,
  axisId,
}) => {
  const dispatch = useDispatch();
  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return <></>;
  }
  if (
    chartAxisKey({ axisId, chartType }) === defaultDataType.CAT &&
    defaultDataType.DATETIME === elem.originalDataType
  ) {
    const handleDateTime = (key, index) => {
      handleSelection(key, index);
      deleteDateTimeToggle({
        toggle: timeFrameData?.toggle,
        chartID,
        dispatch,
      });
    };
    return (
      <>
        <hr />
        {React.Children.toArray(
          Object.entries(defaultOperation.dateTime).map(([key, operationElem]) => {
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleDateTime(operationElem?.key, index)}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
      </>
    );
  }

  return (
    <>
      {chartAxisKey({ axisId, chartType }) === defaultDataType.NUM && <hr />}
      {chartAxisKey({ axisId, chartType }) === defaultDataType.NUM &&
        defaultDataType.NUM === elem.originalDataType &&
        React.Children.toArray(
          Object.entries(defaultOperation.num).map(([key, operationElem]) => {
            if (
              (chartType.chartKey === allChartKeys.CARD ||
                chartType.chartKey === allChartKeys.KPIMETER) &&
              (operationElem.key === defaultOperation.num.PCT_BY_GROUP.key ||
                operationElem.key === defaultOperation.num.percentOfGrandTotal.key)
            ) {
              return <></>;
            }
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleSelection(operationElem.key, index)}
                title={operationElem.name}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
      {chartAxisKey({ axisId, chartType }) === defaultDataType.NUM &&
        defaultDataType.NUM !== elem.originalDataType &&
        React.Children.toArray(
          Object.entries(defaultOperation.cat).map(([key, operationElem]) => {
            return (
              <li
                className={elem.operations.type === operationElem.key ? 'selected' : undefined}
                onClick={() => handleSelection(operationElem.key, index)}
              >
                {operationElem.name}
              </li>
            );
          })
        )}
    </>
  );
};

export const PCT_BY_GROUP = ({
  isPopUp,
  setIsPopUp,
  allTableData,
  operations,
  setAxisData,
  populateCount,
  index,
}) => {
  const [firstColumnSelect, setFirstColumnSelect] = useState(() => {
    const target = operations?.calc?.params[0] || '';
    const [filteredArray] = allTableData.filter(
      (itm) => `${itm.tableName}.${itm.columnName}` === target
    );
    return filteredArray || { name: '' };
  });
  const [operation, setOperation] = useState(() => {
    const target = operations?.calc?.op || '';
    const [filteredArray] = defaultPCT_BY_GROUP.NUM.filter((itm) => itm.key === target);
    return filteredArray || { name: '' };
  });
  const [lastColumnSelect, setLastColumnSelect] = useState(() => {
    const target = operations?.calc?.params[1] || '';
    const [filteredArray] = allTableData.filter(
      (itm) => `${itm.tableName}.${itm.columnName}` === target
    );
    if (operations?.calc?.isConstent) {
      return filteredArray || { name: '', columnName: operations?.calc?.params[1] };
    }
    return filteredArray || { name: '', columnName: '' };
  });
  const [resetDataState, setResetDataState] = useState(false);
  const [isConstent, setIsConstent] = useState(() => {
    if (operations?.calc === null || undefined) {
      return false;
    }
    return operations?.calc;
  });

  const handleFirstColumn = (e) => {
    setIsConstent(false);
    setFirstColumnSelect(e);
  };

  const handleConditions = (e) => {
    setOperation(e);
  };
  const handleSecondColumn = (e) => {
    setLastColumnSelect(e);
  };

  const handleConstantInput = (e) => {
    const newValue = checkForNumber(e.target.value);
    setLastColumnSelect((currentData) => ({
      ...currentData,
      columnName: newValue.data,
    }));
  };
  const checkNum = useMemo(() => {
    setOperation({ name: '' });
    setLastColumnSelect({ name: '', columnName: '' });
    if (firstColumnSelect.dataType === defaultDataType.NUM) {
      const array = allTableData.map((elem) => {
        if (elem.dataType === defaultDataType.NUM) {
          return elem;
        }
        return undefined;
      });
      const result = array.filter(Boolean);
      return { isNum: true, table: result };
    }
    return { isNum: false, table: allTableData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstColumnSelect]);

  const resetData = () => {
    setResetDataState((currentData) => !currentData);
    setFirstColumnSelect({ name: '' });
    setOperation({ name: '' });
    setLastColumnSelect({ name: '', columnName: '' });
    setIsConstent(false);

    setAxisData((currentData) => {
      const items = Array.from(currentData);
      const [reorderedItem] = items.splice(index, 1);
      const newSourceItem = {
        ...reorderedItem,
        operations: { type: defaultOperation.num.PCT_BY_GROUP.key },
      };
      items.splice(index, 0, newSourceItem);
      return items;
    });
  };
  // isConstent

  const saveData = () => {
    const data = {
      operations: {
        calc: {
          op: operation.key,
          params: [
            `${firstColumnSelect.tableName}.${firstColumnSelect.columnName}`,
            isConstent
              ? lastColumnSelect.columnName
              : `${lastColumnSelect.tableName}.${lastColumnSelect.columnName}`,
          ],
          isConstent,
        },
        type: defaultOperation.num.PCT_BY_GROUP.key,
      },
    };
    setAxisData((currentData) => {
      const items = Array.from(currentData);
      const [reorderedItem] = items.splice(index, 1);
      const newSourceItem = { ...reorderedItem, operations: data.operations };
      items.splice(index, 0, newSourceItem);
      return items;
    });
  };
  const handleConstants = (action) => {
    setLastColumnSelect({ name: '', columnName: '' });
    setIsConstent(action);
  };
  const checkAbility = (target) => {
    const newData = checkForNumber(target);
    if (newData.isNum) {
      return false;
    }
    if (newData.data.trim() !== '') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setFirstColumnSelect(() => {
      const target = operations?.calc?.params[0] || '';
      const [filteredArray] = allTableData.filter((itm) => {
        return `${itm.tableName}.${itm.columnName}` === target;
      });
      return filteredArray || { name: '' };
    });
    setOperation(() => {
      const target = operations?.calc?.op || '';
      const [filteredArray] = defaultPCT_BY_GROUP.NUM.filter((itm) => itm.key === target);
      return filteredArray || { name: '' };
    });
    setLastColumnSelect(() => {
      const target = operations?.calc?.params[1] || '';
      const [filteredArray] = allTableData.filter(
        (itm) => `${itm.tableName}.${itm.columnName}` === target
      );
      if (operations?.calc?.isConstent) {
        return filteredArray || { name: '', columnName: operations?.calc?.params[1] };
      }
      return filteredArray || { name: '', columnName: '' };
    });
    setIsConstent(() => {
      if (operations?.calc === null || undefined) {
        return false;
      }
      return operations?.calc;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateCount]);

  return (
    <PopUpDivChild initialValue={isPopUp} setPopUp={setIsPopUp} className='PCT_BY_GROUP'>
      <div className='mainTitleDiv'>
        <div className='mainTitle'>Percentage By Group</div>
      </div>
      <div className='titleDiv'>
        <span>
          <AiOutlineSelect />
        </span>
        SelectColumn
      </div>
      <DropDown
        array={allTableData}
        dropDownValue={firstColumnSelect}
        setdropDownValue={handleFirstColumn}
        mainKey={'columnName'}
        changeToReset={resetDataState}
        setAxisData={setAxisData}
        search
      />
      {firstColumnSelect.name !== '' && (
        <>
          <div className='titleDiv'>
            <span>
              <AiOutlineSelect />
            </span>
            Condition
          </div>
          <DropDown
            array={checkNum.isNum ? defaultPCT_BY_GROUP.NUM : defaultPCT_BY_GROUP.CAT}
            dropDownValue={operation}
            setdropDownValue={handleConditions}
          />
        </>
      )}
      {operation.name !== '' && (
        <>
          <div className='toggleTitleDiv '>
            <span className={!isConstent ? 'selected' : ''} onClick={() => handleConstants(false)}>
              Column
            </span>
            <span className={isConstent ? 'selected' : ''} onClick={() => handleConstants(true)}>
              Constant
            </span>
          </div>
          {!isConstent ? (
            <>
              <DropDown
                array={checkNum.table}
                dropDownValue={lastColumnSelect}
                setdropDownValue={handleSecondColumn}
                changeToReset={resetDataState}
                mainKey={'columnName'}
                search
              />
            </>
          ) : (
            <>
              <input
                type='text'
                value={lastColumnSelect.columnName}
                className='primaryInput'
                onChange={(e) => handleConstantInput(e)}
              />
            </>
          )}
        </>
      )}
      <div className='saveButton'>
        <button className='primaryButton' onClick={() => resetData()}>
          Reset
        </button>
        <button
          className='primaryButton'
          onClick={() => saveData()}
          disabled={checkAbility(lastColumnSelect.columnName)}
        >
          Save
        </button>
      </div>
    </PopUpDivChild>
  );
};
