const rgbToHex = (r, g, b) => {
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

function generateShadesRedToYellow(numShades) {
  const shades = [];
  // Define starting and ending RGB values for red and yellow
  const startColor = [255, 0, 0]; // Red
  const endColor = [255, 255, 0]; // Yellow

  // Calculate the step size for each color channel
  const stepR = (endColor[0] - startColor[0]) / (numShades - 1);
  const stepG = (endColor[1] - startColor[1]) / (numShades - 1);
  const stepB = (endColor[2] - startColor[2]) / (numShades - 1);

  // Generate shades by linearly interpolating between the starting and ending colors
  for (let i = 0; i < numShades; i++) {
    const r = Math.round(startColor[0] + stepR * i);
    const g = Math.round(startColor[1] + stepG * i);
    const b = Math.round(startColor[2] + stepB * i);
    const hex = rgbToHex(r, g, b);
    shades.push(hex);
    // shades.push(`rgb(${r}, ${g}, ${b})`);
  }

  return shades;
}

function generateShadesYellowToGreen(numShades) {
  const shades = [];
  // Define starting and ending RGB values for yellow and green
  const startColor = [255, 255, 0]; // Yellow
  const endColor = [0, 128, 0]; // Green

  // Calculate the step size for each color channel
  const stepR = (endColor[0] - startColor[0]) / numShades;
  const stepG = (endColor[1] - startColor[1]) / numShades;
  const stepB = (endColor[2] - startColor[2]) / numShades;

  // Generate shades by linearly interpolating between the starting and ending colors
  for (let i = 0; i < numShades; i++) {
    const r = Math.round(startColor[0] + stepR * i);
    const g = Math.round(startColor[1] + stepG * i);
    const b = Math.round(startColor[2] + stepB * i);
    const hex = rgbToHex(r, g, b);
    shades.push(hex);
    // shades.push(`rgb(${r}, ${g}, ${b})`);
  }

  return shades;
}

export const KpiColorFunction = ({ colorLength, isReverseTarget = false }) => {
  if (colorLength === 1) {
    const shadesYellowToGreen = generateShadesYellowToGreen(2);
    const shadesRedToYellow = generateShadesRedToYellow(2);
    const finalShades = [shadesYellowToGreen[1], shadesRedToYellow[0]];
    return isReverseTarget ? finalShades.reverse() : finalShades;
  }
  if (colorLength === 2) {
    const shadesRedToYellow = generateShadesRedToYellow(2);
    const shadesYellowToGreen = generateShadesYellowToGreen(2);
    const finalShades = [shadesRedToYellow[0], shadesYellowToGreen[1]];
    return isReverseTarget ? finalShades.reverse() : finalShades;
  }
  if (colorLength === 3) {
    const shadesRedToYellow = generateShadesRedToYellow(2);
    const shadesYellowToGreen = generateShadesYellowToGreen(2);
    const finalShades = [shadesRedToYellow[0], ...shadesYellowToGreen];
    return isReverseTarget ? finalShades.reverse() : finalShades;
  }
  const isOddFunc = (x) => {
    return x % 2 !== 0;
  };
  const isOdd = isOddFunc(colorLength);
  const shadesRedToYellow = generateShadesRedToYellow(
    Math.floor(colorLength / 2) + (isOdd ? 1 : 0)
  );
  shadesRedToYellow.pop();
  const shadesYellowToGreen = generateShadesYellowToGreen(Math.floor(colorLength / 2) + 1);
  const finalShades = [...shadesRedToYellow, ...shadesYellowToGreen];
  return isReverseTarget ? finalShades.reverse() : finalShades;
};
