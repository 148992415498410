import React from 'react';
import ValueNumber from '../../../ValueNumber';

interface groupBubbleTypeDensityColumnProps {
  densityArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  denseStatsArray: { label: string; key: string }[];
  arrow: boolean;
}
export const GroupBubbleTypeDensityColumn = ({
  densityArray,
  finalElem,
  columnName,
  denseStatsArray,
  arrow,
}: groupBubbleTypeDensityColumnProps) => {
  return (
    <div className='container-fluid h-100 mb-2 p-2'>
      <div>
        {React.Children.toArray(
          densityArray.map((densityArrayElem: any) => {
            const columnData = finalElem[densityArrayElem?.key as keyof typeof finalElem] ?? {};
            const nonMinMaxColumnLengthArray: any = Object.values(columnData ?? {})?.[0] ?? [];
            return (
              <>
                <div className=''>
                  <div className='col py-4 gx-0 gy-0 mb-2 insight-table-heading text-center'>
                    <b>{densityArrayElem?.label ?? ''}</b>
                  </div>
                  <div
                    className={`container-fluid h-100 mb-2 p-2 ${
                      densityArrayElem.minMax ? 'threeEqualGrid' : 'fourEqualGrid'
                    }`}
                  >
                    <>
                      {React.Children.toArray(
                        Object.keys(columnData).map((columnKey) => {
                          return (
                            <div className='col p-4 g-0 w-100 insight-table-column-header-left'>
                              <b>{columnName?.[columnKey] ?? ''}</b>
                            </div>
                          );
                        })
                      )}
                      {densityArrayElem.minMax ? (
                        <>
                          {React.Children.toArray(
                            Object.keys(columnData).map((columnKey) => {
                              const columnInnerData = columnData[columnKey];
                              return (
                                <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                  {React.Children.toArray(
                                    denseStatsArray.map((denseStatsData) => {
                                      return (
                                        <div>
                                          <b>{denseStatsData?.label} : </b>
                                          {
                                            <ValueNumber
                                              number={columnInnerData?.[denseStatsData?.key] ?? ''}
                                              arrow={arrow}
                                            />
                                          }
                                        </div>
                                      );
                                    })
                                  )}
                                </div>
                              );
                            })
                          )}
                        </>
                      ) : (
                        <>
                          {React.Children.toArray(
                            nonMinMaxColumnLengthArray.map((elem: number, index: number) => {
                              return (
                                <>
                                  {React.Children.toArray(
                                    Object.keys(columnData).map((columnKey) => {
                                      const columnInnerData = columnData[columnKey];
                                      return (
                                        <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                          {
                                            <ValueNumber
                                              number={columnInnerData?.[index] ?? ''}
                                              arrow={arrow}
                                            />
                                          }
                                        </div>
                                      );
                                    })
                                  )}
                                </>
                              );
                            })
                          )}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

interface groupBubbleTopBottomColumnProps {
  topBottomArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  minMaxArray: { label: string; key: string }[];
  arrow: boolean;
  columnName1: string;
}
export const GroupBubbleTopBottomColumn = ({
  topBottomArray,
  finalElem,
  columnName,
  minMaxArray,
  columnName1,
  arrow,
}: groupBubbleTopBottomColumnProps) => {
  return (
    <div className='container-fluid h-100 mb-2 p-2'>
      <div>
        {React.Children.toArray(
          topBottomArray.map((densityArrayElem: any) => {
            const columnData = finalElem[densityArrayElem?.key as keyof typeof finalElem] ?? {};
            const nonMinMaxColumnLengthArray: any = Object.values(columnData ?? {})?.[0] ?? [];
            return (
              <>
                <div className=''>
                  <div className='col py-4 gx-0 gy-0 mb-2 insight-table-heading text-center'>
                    <b>{densityArrayElem?.label ?? ''}</b>
                  </div>
                  <div className={`container-fluid h-100 mb-2 p-2 fourEqualGrid`}>
                    <>
                      {React.Children.toArray(
                        Object.keys(columnData).map((columnKey) => {
                          return (
                            <div className='col p-4 g-0 w-100 insight-table-column-header-left'>
                              <b>{columnName?.[columnKey] ?? ''}</b>
                            </div>
                          );
                        })
                      )}
                      {React.Children.toArray(
                        nonMinMaxColumnLengthArray.map((elem: number, index: number) => {
                          return (
                            <>
                              {React.Children.toArray(
                                Object.keys(columnData).map((columnKey) => {
                                  const columnInnerData = columnData[columnKey];
                                  return (
                                    <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                      {
                                        <ValueNumber
                                          number={columnInnerData?.[index] ?? ''}
                                          arrow={arrow}
                                        />
                                      }
                                    </div>
                                  );
                                })
                              )}
                            </>
                          );
                        })
                      )}
                    </>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
