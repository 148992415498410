/* eslint import/prefer-default-export: 0 */
import { getPadding } from './scaleHelper';

// Constant for width of bar
// Value in pixels (px)
const barWidth = 20;

// Constant for width of each data node in Line or Area Chart
const lineAreaWidth = 14;

/**
 * This function calculates the width required for
 * chart based on the input
 * @param {Array} x Data to be plotted on x Axis
 * @param {string} type Type of chart
 * @param {number} chartWidth Width of chart available for plotting
 * @param {object} margin Object containing margin around the chart
 * @return {number} Width of the chart
 */
export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen width
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine
  if (type === 'BARHORIZONTAL') {
    return effectiveWidth;
  }

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = getPadding(type);

  // Find the width of each node based on chart type
  const nodeWidth = type === 'BAR' ? barWidth : lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

export const widthHelperBarH = (noOfBars, type, chartHeight, margin) => {
  // Find the width of each node based on chart type
  const nodeWidth = 20;
  // Padding between each plot
  const padding = getPadding(type);

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * noOfBars + margin.left + margin.right;
  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, chartHeight);
};
