const schemaConstants = {
  projectsSchema: 'projectsSchema',
  type: {
    PROJECT: 'projectsSchema',
    SHEET: 'sheet',
    CHART: 'chart',
    PLOT: 'plot',
    DASH: 'dash',
    REPORT: 'report',
    TEAM: 'team'
  },
};

export default schemaConstants;
