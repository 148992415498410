export function scaleFunction({ range, domain }) {
    const [minInput, maxInput] = range;
    const numValues = domain.length;

    return function (inputValue) {
        const inputRange = (maxInput - minInput) / (numValues - 1);
        const index = Math.floor((inputValue - minInput) / inputRange);

        // Ensure the index is within the bounds
        const clampedIndex = Math.max(0, Math.min(index, numValues - 1));

        return domain[clampedIndex];
    };
}