export const targetPointType = {
  column: 'column',
  constant: 'constant',
};
export const defaultCircleWiseTargetPoint = {
  targetPointID: {
    id: '',
    type: '',
  },
  targetValue: { value: '', type: targetPointType.column },
  targetFilter: {},
};
