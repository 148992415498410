import React from 'react';
import buildIcon from '../assets/buildIcon.svg';
import styleIcon from '../assets/styleIcon.svg';
import { mls } from 'lib/multilanguagesupport';

const ToggleButtonDiv = ({ toggleState, setToggleState }) => {
  const handleToggle = (action) => {
    setToggleState(action);
  };
  return (
    <div className='toggleButtonDiv'>
      <div
        className={
          toggleState === 'build' ? 'toggleButton build active' : 'toggleButton build disable'
        }
        onClick={() => handleToggle('build')}
      >
        <img src={buildIcon} alt='' />
        {mls('Build')}
      </div>
      <div
        className={
          toggleState === 'style' ? 'toggleButton style active' : 'toggleButton style disable'
        }
        onClick={() => handleToggle('style')}
      >
        <img src={styleIcon} alt='' />
        {mls('Style')}
      </div>
    </div>
  );
};

export default ToggleButtonDiv;
