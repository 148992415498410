import React, { useMemo } from 'react';
import { Group } from '@visx/group';
import { Bin } from '@visx/mock-data/lib/generators/genBins';
import { scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { HeatmapRect } from '@visx/heatmap';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import AxisLabelComponent from '../common/AxisLabelComponent';
import scaleHelper from '../BarType/helpers/scaleHelper';
import { getStyling, hexWithOpacity } from '../../helpers/colorHelper';
import styles from '../../constants/styles.module.css';
import { mls } from 'lib/multilanguagesupport';
import { defaultToolTipStyling } from 'lib/visx-lib/constants/constants';
import { HeatMapToolTip } from '../ChartToolTip/ChartToolTip';

export const background = 'white';
// export const background = '#28272c';

// const binData = genBins(
//   /* length = */ 16,
//   /* height = */ 16,
//   /** binFunc */ (idx) => 150 * idx,
//   /** countFunc */ (i, number) => 25 * (number - i) * seededRandom()
// );
// const binData = defaultData2;

export type heatMapTypeProps = {
  width: number;
  chartHeight: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  separation?: number;
  events?: boolean;
  chartData: any;
  theme: any;
  dataToPlot: any;
  xCategory: any;
  toggle: any;
  handleToggle: any;
  isToggleClicked: any;
};

const defaultMargin = { top: 10, left: 20, right: 20, bottom: 40 };

function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.max(...data?.map(value));
}

function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.min(...data?.map(value));
}
const HeatMapType = (heatMapTypeProps: heatMapTypeProps) => {
  const {
    width = 500,
    chartHeight: height,
    events = false,
    margin = defaultMargin,
    separation = 20,
    theme,
    chartData,
    dataToPlot,
    toggle,
  } = heatMapTypeProps;
  const heatMapColorMax = chartData?.heatMapColor?.max;
  const heatMapColorMin = chartData?.heatMapColor?.min;
  // const binData = defaultData3;

  const binData = dataToPlot;

  const xAxisData = chartData?.xAxis ?? [];
  const yAxisData = chartData?.yAxis ?? [];

  // const { xAxisKey, yAxisKey, groupKey } = axisKeysData;
  const xAxisKey = xAxisData[0]?.uniqueColumnName;
  const yAxisKey = yAxisData[0]?.uniqueColumnName;
  // const groupKey = magnitudeData[0]?.column;
  // const xAxisKey = xAxisData[0]?.column;
  // const yAxisKey = yAxisData[0]?.column;

  // accessors
  // const bins = (d: Bins) => d.bins;
  const bins = (d: any) => {
    return d.bins;
  };
  const count = (d: Bin) => {
    return d.count;
  };

  const colorMax = max(binData, (d: any) => {
    return max(bins(d), count);
  });
  const colorMin = min(binData, (d: any) => {
    return min(bins(d), count);
  });

  const bucketSizeMax = max(binData, (d: any) => {
    return bins(d)?.length;
  });

  // scales
  const xScale = scaleLinear<number>({
    domain: [0, binData.length],
  });
  const xAxisScale = scaleLinear<number>({
    domain: [0, binData.length],
  });

  const yScale = scaleLinear<number>({
    domain: [0, bucketSizeMax],
  });

  const opacityScale = scaleLinear<number>({
    range: [0.1, 1],
    domain: [colorMin, colorMax],
  });

  // Scales for Chart
  const xGroup = binData.map((elem: any) => {
    // const isDate = xAxisData[0]?.dataType === 'DATETIME';
    const value = elem?.[xAxisKey as keyof typeof elem];
    // if (isDate) {
    //   const dateFormattedValue = singleDateFormatter(value, xAxisData[0]?.operations?.type);
    //   return dateFormattedValue;
    // }
    return value;
  });

  const yGroup = getGroups({
    data: binData,
    axisKey: yAxisKey,
    isDate: yAxisData[0]?.dataType === 'DATETIME',
    toggle: yAxisData[0]?.operations?.type ?? toggle,
  });

  // const cool1 = '#b4fbde';
  // const cool2 = theme.colors[yAxisData[0]?.uniqueColumnName];
  // const cool1 = '#04ff00';
  const cool1 = hexWithOpacity(heatMapColorMin?.color, (heatMapColorMin?.opacity ?? 100) / 100);
  const cool2 = hexWithOpacity(heatMapColorMax?.color, (heatMapColorMax?.opacity ?? 100) / 100);

  const rectColorScale = scaleLinear<string>({
    range: [cool1, cool2],
    domain: [colorMin, colorMax],
  });

  return (
    <>
      {binData.length !== 0 ? (
        <>
          {/* {xCategory === 'DATETIME' && (
            <DateTimeFilter
              width={width ?? 0}
              toggle={toggle}
              handleToggle={handleToggle}
              isToggleClicked={isToggleClicked}
            />
          )} */}
          <ParentSize>
            {({ width }) => (
              <HeatMapComp
                width={width}
                height={height}
                xGroup={xGroup}
                yGroup={yGroup}
                toggle={toggle}
                margin={margin}
                separation={separation}
                events={events}
                theme={theme}
                rectColorScale={rectColorScale}
                binData={binData}
                bucketSizeMax={bucketSizeMax}
                xScale={xScale}
                yScale={yScale}
                xAxisScale={xAxisScale}
                opacityScale={opacityScale}
                chartData={chartData}
              />
            )}
          </ParentSize>
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default HeatMapType;

export type HeatMapCompProps = {
  width: number;
  height: number;
  xGroup: any[];
  yGroup: any[];
  toggle: any;
  margin?: { top: number; right: number; bottom: number; left: number };
  separation?: number;
  events?: boolean;
  theme: any;
  rectColorScale: any;
  binData: any;
  bucketSizeMax: any;
  xScale: any;
  yScale: any;
  xAxisScale: any;
  opacityScale: any;
  chartData: any;
};

const HeatMapComp = (heatMapCompProps: HeatMapCompProps) => {
  const {
    width,
    height,
    xGroup,
    yGroup,
    toggle,
    events,
    separation = 20,
    theme,
    rectColorScale,
    binData,
    bucketSizeMax,
    xScale,
    yScale,
    xAxisScale,
    opacityScale,
    chartData,
  } = heatMapCompProps;

  const yAxisSpace = useMemo(() => {
    const maxWordForSpace = 4;
    const gettingAverageOf = yGroup?.length <= 5 ? yGroup?.length : 5;
    let avgValue = 0;
    for (let index = 0; index < gettingAverageOf; index++) {
      const elementLength = yGroup[index]?.toString()?.length ?? 0;
      avgValue = avgValue + elementLength;
    }
    const avarageLetter = avgValue / gettingAverageOf;

    if (avarageLetter >= maxWordForSpace) {
      return -maxWordForSpace * 7;
    }
    return avarageLetter * 5;
  }, [yGroup]);

  // bounds
  // const margin = { top: 10, left: 20 - yAxisSpace, right: 20, bottom: 40 };
  const margin = { left: 80 - yAxisSpace, top: 15, bottom: 60, right: 15 };

  // Creating a tooltip for the chart
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();
  const { containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleMouse = (event: any, d: any) => {
    const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
    const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
    showTooltip({
      tooltipLeft: containerX,
      tooltipTop: containerY,
      tooltipData: d,
    });
  };

  const size =
    width > margin.left + margin.right ? width - margin.left - margin.right - separation : width;
  const xMax = size;
  const yMax = height - margin.bottom - margin.top;

  const binWidth = xMax / binData.length;
  const binHeight = yMax / bucketSizeMax;
  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);

  xAxisScale.range([0, xMax]);

  const xExtraScale: any = useMemo(
    () => scaleHelper(xGroup, 'CAT', 'LINE', xMax, yMax, true, false),
    [xMax, yMax, toggle]
  );
  const yExtraScale: any = useMemo(
    () => scaleHelper(yGroup, 'CAT', 'LINE', xMax, yMax, false, true),

    [xMax, yMax, toggle]
  );

  const yStyling = theme?.yAxisStyle;
  const xStyling = theme?.xAxisStyle;
  // const xAxisTickWidth = (width ?? 0) / filteredXData?.length;
  const xAxisTickWidth = binWidth;
  const yAxisTickWidth = binHeight;

  const actualWidth = widthHelper(xGroup, 'LINE', width, margin);

  const dataNum = Math.floor((width / actualWidth) * xGroup.length);

  return (
    <>
      {tooltipOpen && (
        <HeatMapToolTip
          defaultToolTipStyling={defaultToolTipStyling}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          TooltipInPortal={TooltipInPortal}
          tooltipData={tooltipData}
          theme={theme}
          chartData={chartData}
          binData={binData}
          backgroundColor={defaultToolTipStyling.backgroundColor}
        />
      )}
      {width < 10 ? null : (
        <svg
          width={width}
          // height={yMax}
          // height={yMax + binHeight - 40}
          height={height}
        >
          <rect x={0} y={0} width={width} height={height} rx={0} fill={background} />
          {/* Append Axis Left */}
          {yStyling?.show ? (
            <AxisLeft
              top={margin.top}
              left={margin.left}
              scale={yExtraScale}
              label={yStyling.label}
              tickClassName={`${styles.leftAxisTicks} ${styles.leftAxisHorizontal} `}
              labelClassName={styles.chartLabel}
              tickComponent={AxisleftLabelWrapper}
              tickLabelProps={() => ({
                textAnchor: 'middle',
                valueStyle: yStyling?.valueStyle,
                yAxisTickWidth,
                yAxisSpace,
                yAxisTextWidth: margin.left - 50,
              })}
              tickLength={6}
              tickLineProps={{
                color: 'gray',
              }}
              labelProps={{
                textAnchor: 'middle',
                ...getStyling(yStyling?.style),
              }}
              labelOffset={50 - yAxisSpace}
              stroke={yStyling?.style.color}
            />
          ) : null}

          {/* Append Axis Bottom */}
          {xStyling?.show ? (
            <AxisBottom
              top={yMax + margin.top}
              // top={yMax + binHeight - 100}
              labelClassName={`${styles.chartLabel} `}
              left={margin.left - 1}
              scale={xExtraScale}
              label={xStyling.label}
              // scale={xExtraScale()}
              // stroke={defaultAxisColor}
              // tickStroke={defaultAxisColor}
              hideTicks
              tickLength={10}
              tickLineProps={{
                color: 'red',
              }}
              // tickFormat={yCategory === 'NUM' ? format('~s') : null}
              tickLabelProps={() => ({
                fontFamily: 'sans-serif',
                valueStyle: xStyling?.valueStyle,
                xAxisTickWidth,
              })}
              labelProps={{
                ...getStyling(xStyling?.style),
                textAnchor: 'middle',
              }}
              tickComponent={AxisBottomLabelWrapper}
              tickClassName={styles.bottomAxisTicks}
              numTicks={dataNum}
              labelOffset={35}
              // {...xAxisProps}
              // tickLabelProps={() => ({
              //   ...getStyling(xStyling?.valueStyle),
              //   textAnchor: 'middle',
              // })}
            />
          ) : null}

          <Group top={margin.top - binHeight - 1} left={margin.left}>
            <HeatmapRect
              data={binData}
              xScale={(d) => xScale(d) ?? 0}
              yScale={(d) => yScale(d) ?? 0}
              colorScale={rectColorScale}
              opacityScale={opacityScale}
              binWidth={binWidth}
              binHeight={binHeight}
              gap={2}
            >
              {(heatmap) =>
                heatmap.map((heatmapBins) => {
                  return (
                    <>
                      {heatmapBins.map((bin: any) => {
                        return (
                          <rect
                            key={`heatmap-rect-${bin.row}-${bin.column}`}
                            className='visx-heatmap-rect'
                            width={bin.width}
                            height={bin.height}
                            x={bin.x}
                            y={bin.y}
                            fill={bin.color}
                            fillOpacity={bin.opacity ?? 0}
                            onClick={() => {
                              if (!events) return;
                              const { row, column } = bin;
                              alert(
                                JSON.stringify({
                                  xAxisValue: xGroup[column],
                                  yAxisValue: yGroup[row],
                                  bin: bin.bin,
                                })
                              );
                            }}
                            onMouseOver={(event) => {
                              const { row, column } = bin;
                              handleMouse(event, {
                                xAxisValue: xGroup[column],
                                yAxisValue: yGroup[row],
                                count: bin?.count,
                              });
                            }}
                            onMouseLeave={() => hideTooltip()}
                            onTouchStart={(event) => {
                              const { row, column } = bin;
                              handleMouse(event, {
                                xAxisValue: xGroup[column],
                                yAxisValue: yGroup[row],
                                count: bin?.count,
                              });
                            }}
                            onTouchEnd={() => hideTooltip()}
                          />
                        );
                      })}
                    </>
                  );
                })
              }
            </HeatmapRect>
          </Group>
        </svg>
      )}
    </>
  );
};

// Wrapper component for left axis using AxisLabelComponent
const AxisleftLabelWrapper = (AxisleftLabelWrapperProps: any) => {
  const { x, y, formattedValue, valueStyle, yAxisTickWidth, yAxisTextWidth } =
    AxisleftLabelWrapperProps;

  return (
    <AxisLabelComponent
      x={x}
      y={y}
      formattedValue={formattedValue}
      valueStyle={valueStyle}
      yAxisTickWidth={yAxisTickWidth}
      // yAxisTextWidth={yAxisTextWidth}
      wordLength={8}
    />
  );
};
// Wrapper component for bottom axis using AxisLabelComponent
const AxisBottomLabelWrapper = ({ x, y, formattedValue, valueStyle, xAxisTickWidth }: any) => {
  return (
    <AxisLabelComponent
      x={x}
      y={y}
      formattedValue={formattedValue}
      valueStyle={valueStyle}
      xAxisTickWidth={xAxisTickWidth}
      bottomAxis

      // handleMouseOver={handleMouseOver}
      // hideTooltip={hideTooltip}
    />
  );
};
interface getGroupData {
  data: any[];
  axisKey: string;
  isDate: boolean;
  toggle: string;
}
const getGroups = (getGroupData: getGroupData) => {
  const { data, axisKey } = getGroupData;
  if (data[0]?.bins) {
    const groups = data[0]?.bins.map((elem: any) => {
      const value = elem?.[axisKey];
      // if (isDate) {
      //   const dateFormattedValue = singleDateFormatter(value, toggle);
      //   return dateFormattedValue;
      // }
      return value;
    });
    return groups;
  }
  return [];
};

export const widthHelper = (x: any, type: any, chartWidth: any, margin: any) => {
  // Effective screen width
  const lineAreaWidth = 40;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};
