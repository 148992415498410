import localStorage from './localStorage';
import { getDefaultAppID } from './getAppID';
export const baseAPI =
  process.env.REACT_APP_IS_LOCALHOST === 'true'
    ? 'http://localhost:8000'
    : 'https://analytics-api.supista.com';
const localStorageData = localStorage.retrieveData('config_store');
const appID = getDefaultAppID(localStorageData?.login);

export const supistaApiGet = (endpoint) => {
  return fetch(`${baseAPI}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => response.json());
};

export const supistaApiPost = async (endpoint, data, newAppID) => {
  return fetch(`${baseAPI}/${newAppID || appID}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};
