import { useKpiSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import Chart from 'lib/visx-lib';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
import React, { useRef } from 'react';

interface kpiDashBoardChartAreaProps {
  dashBoardChartsPlotData: any[];
  KpiCharts: { [key: string]: any };
  kpiSettingsPopUpData: { [key: string]: any };
  // setKpiSettingsPopUpData: Function;
  isAccessChartSettings: boolean;
}

const KpiDashBoardChartArea = ({
  dashBoardChartsPlotData,
  KpiCharts,
  kpiSettingsPopUpData,
  // setKpiSettingsPopUpData,
  isAccessChartSettings,
}: kpiDashBoardChartAreaProps) => {
  const chartsContainerRef = useRef(null);
  const kpiSettingsButton = useKpiSettingsButton();

  const { width: mainContainerWidth } = useDivDimensions(chartsContainerRef);

  const chartHeight =
    mainContainerWidth ?? 0 <= 576
      ? mainContainerWidth ?? 0 // sm
      : mainContainerWidth ?? 0 <= 992
      ? mainContainerWidth ?? 0 / 2 // md
      : mainContainerWidth ?? 0 / 3; // lg
  const chartSettingsButton = ({ chartSchema }: { chartSchema: { [key: string]: any } }) => {
    kpiSettingsButton({
      ...kpiSettingsPopUpData,
      isPopup: true,
      isNew: false,
      kpiSchema: chartSchema,
    });
  };

  const isAccess: any = isAccessChartSettings ? 'sheet' : false;

  return (
    <div className='kpiDashboardChartContainer row' ref={chartsContainerRef}>
      {React.Children.toArray(
        dashBoardChartsPlotData.map((chartsData: { [key: string]: any }) => {
          const chartID = chartsData.chartID;
          const chartSchema = KpiCharts?.[chartID as keyof typeof KpiCharts];
          if (!chartSchema) {
            return <></>;
          }
          return (
            <>
              <Chart
                key={`${chartID}-charts`}
                chartKey={chartID}
                chartData={chartSchema}
                isProd={isAccess}
                dataToPlot={chartsData || {}}
                cardWidth={chartSchema?.chartWidth || 'HALF'}
                useChartSettingsButton={() => {
                  return () => {
                    chartSettingsButton({ chartSchema });
                  };
                }}
                updateFilter={updateFilter}
                handleChartUpdates={handleChartUpdates}
                isUpdateLoading={isUpdateLoading}
                filterSchema={filterSchema}
                chartHeight={chartHeight}
                timeFrameObj={timeFrameObj}
                filterObj={filterSchema}
              />
            </>
          );
        })
      )}
    </div>
  );
};

export default KpiDashBoardChartArea;

const isUpdateLoading = false;
const timeFrameObj = {};
const filterSchema: any[] = [];
const updateFilter = () => {
  return () => {};
};
const handleChartUpdates = () => {
  return () => {};
};
