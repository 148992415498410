import React, { useEffect, useState } from 'react';
import DashboardArea from '../../containers/DashboardArea';
import FirstColumn from '../../containers/FirstColumn';
import SecondColumn from '../../containers/SecondColumn';
import './toggleColumn.scss';
import { mls } from 'lib/multilanguagesupport';

// import DragableWrapper from './features/DragableThings/DragableWrapper';

const ColumnToggleWapper = (mainPorps: any) => {
  // Handle Column Blocks
  const [minimizedDiv, setMinimizedDiv] = useState(['', '']);
  const toggleMinimizeDiv = (toggleKeyName: string, toggleKeyId: number) => {
    const newArray = new Array(...minimizedDiv);
    newArray[toggleKeyId] = toggleKeyName;
    setMinimizedDiv(newArray);
  };
  const toggleProps = { minimizedDiv, toggleMinimizeDiv };

  return (
    <div className='mainContainer'>
      <ToggleBar toggleProps={toggleProps} />
      <SecondColumn toggleProps={toggleProps} mainPorps={mainPorps} />
      <FirstColumn toggleProps={toggleProps} mainPorps={mainPorps} />
      <DashboardArea toggleProps={toggleProps} mainPorps={mainPorps} />
    </div>
  );
};

export default ColumnToggleWapper;

interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
    toggleMinimizeDiv: Function;
  };
}
const ToggleBar = ({ toggleProps }: mainPorps) => {
  const { minimizedDiv, toggleMinimizeDiv } = toggleProps;
  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  useEffect(() => {
    minimizeFuntion({ minimizedDiv, setIsMainDivOn, setIsToggle });
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }
  return (
    <div className={isMainDivOn ? 'toggleColumn' : 'toggleColumn close'}>
      <div className='toogleRotatedDiv'>
        {React.Children.toArray(
          minimizedDiv.map((elm, i) => {
            if (elm) {
              return (
                <>
                  <button
                    onClick={() => toggleMinimizeDiv('', i)}
                    className='toggleColumnButton toggleColumnButtonFirst'
                  >
                    <span>{mls(elm)}</span>
                  </button>
                </>
              );
            }
            return <></>;
          })
        )}
      </div>
    </div>
  );
};

interface minimizeFuntionProps {
  minimizedDiv: string[];
  setIsMainDivOn: Function;
  setIsToggle: Function;
}

const minimizeFuntion = (props: minimizeFuntionProps) => {
  const { minimizedDiv, setIsMainDivOn, setIsToggle } = props;
  if (minimizedDiv[0] === '' && minimizedDiv[1] === '') {
    setIsMainDivOn(false);
    setTimeout(() => {
      setIsToggle(false);
    }, 300);
    return;
  }
  setIsMainDivOn(true);
  setTimeout(() => {
    setIsToggle(true);
  }, 300);
};
