import React, { useState } from 'react';
import {
  defaultFontDecoration,
  defaultFontFamily,
  defaultFontSize,
} from '../../data/defaultChartSchema';
import DropDown from 'lib/chart-settings-lib/features/NewDropDown/DropDown';
import { mls } from 'lib/multilanguagesupport';
const FontSelector = ({ fontStyle, setFontStyle }) => {
  const handleTextStyle = (elem) => {
    setFontStyle({ ...fontStyle, textStyle: elem });
  };
  const handleFontFamily = (elem) => {
    setFontStyle({ ...fontStyle, font: elem });
  };
  const handleFontSize = (elem) => {
    setFontStyle({ ...fontStyle, fontHeight: elem });
  };

  const handleFontStyle = (elem) => {
    const itemIndex = fontStyle.textStyle.indexOf(elem);
    if (itemIndex === -1) {
      handleTextStyle([...fontStyle.textStyle, elem]);
    } else {
      const newTextStyle = new Array(...fontStyle.textStyle);
      newTextStyle.splice(itemIndex, 1);
      handleTextStyle(newTextStyle);
    }
  };
  return (
    <div>
      <div className='titleDiv'>{mls("Font")}</div>

      {/* <DropDown
        array={defaultFontFamily}
        dropDownValue={fontStyle.font}
        setdropDownValue={handleFontFamily}
      /> */}
      <div className='fontStyleDiv'>
        <div
          className='fontStyleSquare fontStyleBold'
          onClick={() => handleFontStyle(defaultFontDecoration[0])}
        >
          {fontStyle.textStyle.indexOf(defaultFontDecoration[0]) === -1 ? (
            <>B</>
          ) : (
            <>
              <b>B</b>
            </>
          )}
        </div>
        <div
          className='fontStyleSquare fontStyleItalic'
          onClick={() => handleFontStyle(defaultFontDecoration[1])}
        >
          {fontStyle.textStyle.indexOf(defaultFontDecoration[1]) === -1 ? (
            <>I</>
          ) : (
            <>
              <b>I</b>
            </>
          )}
        </div>
        <div
          className='fontStyleSquare fontStyleUnderLine'
          onClick={() => handleFontStyle(defaultFontDecoration[2])}
        >
          {fontStyle.textStyle.indexOf(defaultFontDecoration[2]) === -1 ? (
            <>U</>
          ) : (
            <>
              {' '}
              <b>U</b>{' '}
            </>
          )}
        </div>
        <DropDown
          array={defaultFontSize}
          dropDownValue={fontStyle.fontHeight}
          setdropDownValue={handleFontSize}
          ObjectInArray={true}
        />
      </div>
    </div>
  );
};

export default FontSelector;
