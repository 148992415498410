/* eslint import/prefer-default-export: 0 */
// import { localPoint } from '@visx/event';
// import { bisector } from 'd3-array';
// import { defaultMargin } from '../../../constants/constants';
import { isValidDate } from '../../../helpers/isValidDate';

// Find closest x index of mouse pointer
// const bisectData = bisector((d) => d.x).left;

/**
 * This function is used for displaying tooltips
 * in Line and Area charts
 * @param {Event} event Event object
 * @param {Function} xScale Scale used for x Axis
 * @param {Function} yScale Scale used for y Axis
 * @param {String} xCategory Category of data on xAxis
 * @param {Array} x Data to be plotted on x Axis
 * @param {Array} y Data to be plotted on y Axis
 * @param {Function} onMouseOver Function to display toolip
 */
export const handleMouseStartLineAndArea = (
  event,
  // xScale,
  // yScale,
  xCategory,
  // x,
  // y,
  onMouseOver,
  data
) => {
  // Convert data to required format
  // [{x: x, y: y}, {x: x, y: y},...]
  // const data = x.map((e, i) => ({ x: e, y: y[i] }));
  // // Get the coordinates of the mouse pointer
  // const datum = localPoint(event) || { x: 0, y: 0 };
  // datum.x -= defaultMargin.left;
  // Create Date object as per x coordinate
  // let x0;
  // if (xCategory === 'CAT') {
  //   const eachBand = xScale.step();
  //   const xIndex = Math.round(datum.x / eachBand);
  //   x0 = xScale.domain()[xIndex];
  // } else {
  //   x0 = xScale.invert(datum.x);
  //   if (xCategory === 'DATETIME') x0 = new Date(x0);
  // }
  const datum = { x: 0, y: 0 }
  // // Find the closest x to mouse pointer
  // const index = bisectData(data, x0, 1);

  if (xCategory === 'DATETIME') {
    datum.x = new Date(data.x).toDateString();
  } else if (xCategory === 'CAT') {
    const tempX = new Date(data.x);
    if (isValidDate(tempX)) {
      datum.x = tempX.toDateString();
    } else {
      datum.x = data.x;
    }
  } else {
    datum.x = data.x;
  }
  datum.y = data.y;

  onMouseOver(event, datum);
};
