import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { mls } from 'lib/multilanguagesupport';
import { useLocation } from 'react-router-dom';
import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import { dataTableColumnTypes } from 'lib/data-connectors/dependencies/constant';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { Form, Modal } from 'react-bootstrap-v5';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import { useDispatch } from 'react-redux';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import useAddNewSheets from './useAddNewSheets';
import { _supistaAnalyticsApi, _supistaApiPost } from 'lib/server-connection/connections';
import { getTableIDSTBD } from 'lib/reusable-components/reusableFunction/deleteDataDependencies';

const removeSysVariable = (str) => {
  const spl = str.split('.');
  const last = spl.pop();
  if (last === '__d3__updatedAt') return spl.join('.') + '.Last Update Date';
  else if (last === '__d3__createdAt') return spl.join('.') + '.Create Date';
  else return spl.join('.') + '.' + last;
};

const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];

const getDateTimeCols = (tableSchema, dbType, tableName, isJoined) => {
  let columns = tableSchema?.columnSchema || [];
  if (isJoined) {
    columns = columns.map((col) => ({ ...col, tableName: tableSchema.primaryTable }));
    (tableSchema.joinTableSchema || []).map((sch) => {
      const joinCols = (sch.columnSchema || []).map((schCol) => ({
        ...schCol,
        tableName: sch.joinTableName,
      }));
      columns = [...columns, ...joinCols];
      return null;
    });
  } else {
    columns = columns.map((col) => ({ ...col, tableName: tableName }));
  }

  if (columns.length === 0) return [];
  const DTCols = columns.filter((col) => dateTimeTypes.includes(col.dataType));
  if (dbType === '__d3__supista' && isJoined !== true) {
    const createData = {
      name: 'Create Date',
      dataType: 'DATETIME',
      tableName: tableName,
      columnName: '__d3__createdAt',
    };
    const updateData = {
      name: 'Last Update Date',
      dataType: 'DATETIME',
      columnName: '__d3__updatedAt',
      tableName: tableName,
    };
    DTCols.push(createData, updateData);
  }

  const colNames = DTCols.map((obj) => obj.tableName + '.' + obj.columnName);

  return { DTCols, colNames };
};

const TableSettings = ({
  selectedTable, // Selected table name
  setOpen,
  openedConnectionID,
  dbType,
  credentialID,
}) => {
  const dispatch = useDispatch();
  const updateSchema = useUpdateSchema();
  const addNewSheets = useAddNewSheets();
  const [tableName, setTableName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTableSchema, setSelectedTableSchema] = useState(null);
  const [selectedColIndices, setSelectedColIndices] = useState([]);
  const [datetimeCols, setDatetimeCols] = useState([]);
  const [defaultDatetimeColumnName, setDefaultDatetimeColumnName] = useState('');
  const [apiCallStatus, setApiCallStatus] = useState('loading'); // loading | success | error
  const [confirmTableCreateOpen, setConfirmTableCreateOpen] = useState(false);
  const [tableImported, setTableImported] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const joinTableSchema = analyticsSchema?.appJointable || {};
  const customTableSchema = analyticsSchema?.appCustomtable || {};

  const currentTableID = useRef();

  const applyTableName = (columnName) => {
    const columnNameArray = columnName.split('.');
    const last = columnNameArray.pop();
    const tableID = columnNameArray.join('.');
    return (tableName || tableID) + '.' + last;
  };

  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  const updateTableSchema = useUpdateTableSchema();

  const loadTablesSchema = async () => {
    try {
      setApiCallStatus('loading');
      setTableImported(false);
      const response = await _supistaAnalyticsApi(`${appID}/api/createTableSchema/fetch`, {
        appID,
        credentialID: openedConnectionID,
        tableID: selectedTable,
      });
      if (!response?.__d3__error && response?.response?.tableID === selectedTable) {
        setApiCallStatus('success');
        setSelectedTableSchema(response?.response);
        setTableName(response?.response?.name);
        currentTableID.current = uuid();
        Object.keys(analyticsSchema.appDatatable || {}).forEach((tableID) => {
          if (
            analyticsSchema.appDatatable[tableID]?.name === response?.response?.name &&
            analyticsSchema.appDatatable[tableID]?.credentialID === openedConnectionID
          ) {
            setConfirmTableCreateOpen(true);
            currentTableID.current = tableID;
          }
        });
        const dtColumns =
          getDateTimeCols(response?.response, dbType, currentTableID.current, false).colNames || [];
        setDatetimeCols(dtColumns);
        setDefaultDatetimeColumnName(dtColumns[0] || '');
        setSelectedColIndices(Array.from(Array(response?.response?.columnSchema?.length).keys()));
      }
    } catch (err) {
      setApiCallStatus('error');
    }
  };
  useEffect(() => {
    loadTablesSchema();
  }, [appID, openedConnectionID, selectedTable]);

  const selectCol = (index) => {
    setSelectedColIndices((old) => {
      const newArray = [...old];
      newArray.push(index);
      return newArray;
    });
  };
  const deselectCol = (index) => {
    setSelectedColIndices((old) => {
      const newArray = [...old];
      const target = newArray.indexOf(index);
      newArray.splice(target, 1);
      return newArray;
    });
  };

  const importAndSaveTable = async () => {
    if (submitting) return;
    setSubmitting(true);
    // Building the final column schema by including ONLY the selected columns
    const finalColumnSchema = [];
    selectedTableSchema?.columnSchema?.forEach((col, index) => {
      if (selectedColIndices.includes(index)) {
        finalColumnSchema.push(col);
      }
    });

    const currentTimestamp = Date.now();
    const tableID = currentTableID.current;
    const tableData = {
      name: tableName,
      columnSchema: finalColumnSchema,
      description: description,
      tableID,
      ConnecteionTableID: `${credentialID}.${tableName}`,
      appID: appID,
      dbType,
      credentialID: openedConnectionID,
      defaultDatetimeCol: defaultDatetimeColumnName,
      datetimeCols,
      updates: {
        addColumns: [],
        updateColumns: [],
        deleteColumns: [],
        renameColumns: {},
        dropTable: false,
        createTable: true,
      },
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };

    _supistaApiPost(`settings/${appID}/DataTable`, {
      data: tableData,
    })
      .then(async (response) => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: 'Table Imported and saved successfully',
              time: 5,
              isActive: true,
            },
          })
        );
        // Update the local redux
        updateTableSchema(false, null, tableData, tableName);

        // reset the table cache from backend if the tableID is being reimported
        if (confirmTableCreateOpen) {
          const { customTableIDsTBD, joinTableIDsTBD } = getTableIDSTBD({
            joinTableSchema: joinTableSchema,
            customTableSchema: customTableSchema,
            tableIDs: [tableID],
          });
          await _supistaAnalyticsApi(`${appID}/cacheType/tableCache/resetCache`, {
            appID,
            tableID,
            cacheType: 'tableCache',
            relatedIDs: {
              customTableIDs: customTableIDsTBD,
              joinTableIDs: joinTableIDsTBD,
            },
          });
        }

        // adding new sheet only when the table does not exit already.
        !confirmTableCreateOpen &&
          (await addNewSheets({
            appID,
            credentialID,
            tableName,
            tableID,
            dbType,
            defaultDatetimeColumnName,
            datetimeCols,
            description,
            successMesage:
              'Charts Auto generated by Supista AI and added to the Sheet successfully!',
          }));
        await publishToProduction(appID);
        setSubmitting(false);
        setConfirmTableCreateOpen(true);
        setTableImported(true);
        // Close the modal after successfully importing table
        // setOpen(false);
      })
      .catch((err) => {
        store.dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
            value: {
              message: "Issues encountered. Couldn't import and save the table.",
              time: 5,
              isActive: true,
            },
          })
        );
        setSubmitting(false);
      });
  };

  const handleDataTypeChange = (index, newType) => {
    setSelectedTableSchema((old) => {
      let newColumnSchema = global.structuredClone(old.columnSchema);
      newColumnSchema[index].dataType = newType;
      return {
        ...old,
        columnSchema: newColumnSchema,
      };
    });
  };
  return (
    <>
      {apiCallStatus === 'loading' && (
        <>
          <div
            className='d-flex align-items-center justify-content-center pe-0 justify-content-center'
            style={{ height: '80%' }}
          >
            <div class='spinner-border text-primary' role='status'></div>
          </div>
        </>
      )}
      {apiCallStatus === 'error' && (
        <>
          <div
            className='d-flex align-items-center justify-content-center pe-0 justify-content-center'
            style={{ height: '80%' }}
          >
            <div className='alert alert-danger d-flex flex-column flex-sm-row p-5 text-center w-100'>
              <span className='w-100'>{mls("Some error occured. Couldn't load table schema")}</span>
            </div>
          </div>
        </>
      )}

      {apiCallStatus === 'success' && (
        <>
          <label className='form-label'>{mls('Table name')}</label>
          <input
            type='text'
            className='form-control form-control-solid'
            placeholder={mls('Enter table name')}
            value={tableName}
            disabled
            onChange={(e) => {
              setTableName(e.target.value);
            }}
            defaultValue={selectedTable}
          />
          {/* <div className='ml-4 text-warning'>
            {mls(
              'Kindly make sure that the Table Name is same name (case sensitive) as that of your Database Table Name or Sheet Name in your Spreadsheet.'
            )}
          </div> */}
          <label className='form-label mt-5'>{mls('Description')}</label>
          <textarea
            className='form-control form-control-solid'
            placeholder={mls('Table description')}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {(datetimeCols || []).length > 0 && (
            <>
              <br />
              <label className='form-label'>{mls('Default Datetime Column')}</label>
              <Form.Select
                aria-label='Default Datetime Column'
                className='form-control form-control-solid'
                value={defaultDatetimeColumnName}
                defaultValue={datetimeCols[0]}
                onChange={(evt) => setDefaultDatetimeColumnName(evt.target.value)}
              >
                <option value=''>None</option>
                {(datetimeCols || []).map((ele) => (
                  <option key={ele} value={ele}>
                    {applyTableName(removeSysVariable(ele))}
                  </option>
                ))}
              </Form.Select>
            </>
          )}

          <label className='form-label mt-10 fw-bolder fs-3'>
            {mls('Select columns to import')}
          </label>
          <br />

          <div className='w-90 mx-auto'>
            <div className='row mt-5'>
              <label className='col-2 fw-bold'>{mls('Import')}</label>
              <label className='col-5 fw-bold'>{mls('Column Name')}</label>
              <label className='col-5 fw-bold'>{mls('Column Type')}</label>
            </div>
            <hr />
            {selectedTableSchema?.columnSchema?.map((col, index) => {
              const isSelected = selectedColIndices.includes(index);
              return (
                <div className='row my-5' key={index} style={{ color: !isSelected && 'grey' }}>
                  <div className='col-2 d-flex align-items-center justify-content-left'>
                    <input
                      type='checkbox'
                      className='form-check-input cursor-pointer'
                      checked={isSelected}
                      onChange={
                        isSelected ? deselectCol.bind(null, index) : selectCol.bind(null, index)
                      }
                    />
                  </div>
                  <div className='col-5 d-flex align-items-center justify-content-left'>
                    {col.name}
                  </div>
                  <div className='col-5 d-flex align-items-center justify-content-left'>
                    <select
                      className='form-select form-control form-control-solid mb-1 mx-auto'
                      style={{ color: !isSelected && 'grey' }}
                      value={col.dataType || 'text'}
                      onChange={(e) => {
                        handleDataTypeChange(index, e.target.value);
                      }}
                      disabled={!isSelected}
                    >
                      {Object.entries(dataTableColumnTypes).map((type) => {
                        return <option value={type[0]}>{type[1]}</option>;
                      })}
                    </select>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className='w-100 d-flex align-items-end bg-white py-2 border-top'
            style={{ position: 'sticky', bottom: -1 }}
          >
            {tableImported ? (
              <div className='col-12 text-success'>
                {mls('The table: ') +
                  tableName +
                  mls(
                    ' has been successfully imported and Charts have been added in its sheet. You can now use the charts from Sheets and import them in Dashboard.'
                  )}
              </div>
            ) : confirmTableCreateOpen ? (
              <div className='d-flex justify-content-between flex-row'>
                <div className='col-8 text-danger'>
                  {mls('The table: ')} <b>{tableName}</b>
                  {mls(
                    ' already exist in our database! Importing table may delete the older data related charts may not work.'
                  )}
                </div>
                <div>
                  <button
                    onClick={importAndSaveTable}
                    className={'btn btn-sm ms-auto btn-danger'}
                    // style={{ width: 120 }}
                  >
                    <span class='loader-container'>
                      {mls('Re-Import table')}
                      {submitting && <span className='loader-red' />}
                    </span>
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={importAndSaveTable}
                className={'btn btn-sm ms-auto btn-primary'}
                // style={{ width: 120 }}
              >
                <span class='loader-container'>
                  {mls('Import and save table')}
                  {submitting && <span className='loader' />}
                </span>
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TableSettings;
