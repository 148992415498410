import React from 'react';
import './popupCardGlobal.scss';
import './scroll.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import AnalysisIndex from './AnalysisIndex';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';

interface mainProps {
  mainData: any;
  mainPopUp: boolean;
  isAnaysisLoading?: boolean;
  handleMainPopUp: Function;
  platform: string;
  chartName: string;
  handlePlatform: Function;
}
const AnalysisPopUp = (props: mainProps) => {
  const { mainData, mainPopUp, handleMainPopUp, isAnaysisLoading = false, chartName } = props;

  return (
    <div style={divStyle} className='AnalysisPopUp'>
      <div style={divStyle} className='AnalysisPopUp'>
        <PopUpDivChild
          initialValue={mainPopUp}
          setPopUp={handleMainPopUp}
          popupTitle={`${chartName} ${mls('( AI Insights )')}`}
          className='AnalysisPopUpDivContainer'
        >
          {isAnaysisLoading ? (
            <div
              className='loadingScreeen'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <AnalysisIndex mainData={mainData} />
          )}
        </PopUpDivChild>
      </div>
    </div>
  );
};

export default AnalysisPopUp;
const divStyle = {
  margin: '0rem',
};
