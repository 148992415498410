import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import { useDashSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import DashboardSettingsWrapper from 'lib/dash-settings/DashboardSettingsWrapper';
import { mls } from 'lib/multilanguagesupport';
import React from 'react';
import { useDispatch } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';

function DashSettings({ dashID, appID, isNew, dashSchema }) {
  const dispatch = useDispatch();
  const dashSettingsButton = useDashSettingsButton();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const updateSchema = useUpdateSchema();
  const location = getSchemaLocation(schemaConstants.type.DASH);
  const handlePopup = (action) => {
    if (!action) dashSettingsButton(false);
  };
  const handleSave = (schema, dashID, isRemoved = false) => {
    if (!isRemoved) {
      updateSchema(location, dashID, schema);
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: `${mls(
              `${isNew ? 'Added new dashboard ' : 'Updated dashboard '}successfully!`
            )}`,
            time: 5,
            isActive: true,
          },
        })
      );
    } else {
      const prevDashSchema = { ...(analyticsSchema?.appDash || {}) };
      const isDeleted = delete prevDashSchema[dashID];
      if (isDeleted) {
        updateSchema(null, location, prevDashSchema);
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: `${mls('Deleted dashboard successfully!')}`,
              time: 5,
              isActive: true,
            },
          })
        );
      }
    }
    dashSettingsButton(false);
  };
  return (
    <DashboardSettingsWrapper
      appID={appID}
      dashID={dashID}
      isNewChart={isNew}
      dashBoardSchema={dashSchema}
      analyticsSchema={analyticsSchema}
      handleDashSettingsPopUp={handlePopup}
      handleSave={handleSave}
    />
  );
}
export default DashSettings;
