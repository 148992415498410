import React, { useEffect, useRef, useState } from 'react';
import './dropDown.scss';
import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const NormalDropDown = ({
  array,
  dropDownValue,
  setdropDownValue,
  // isKey,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  mainKey = 'key',
}) => {
  const isObject = typeof dropDownValue === 'object' ? true : false;
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(array);

  const [searchInput, setSearchInput] = useState(() =>
    isObject ? dropDownValue?.[nameKey] : dropDownValue
  );
  const wrapperRef = useRef(null);

  const handleDropDown = () => {
    setisDropDownOpen((currentData) => !currentData);
  };
  const stopPropgation = (e) => {
    e.stopPropagation();
    setisDropDownOpen(true);
  };
  const handleSelection = (elem) => {
    setSearchInput(elem?.[nameKey] || elem);
    setdropDownValue(elem);
    handleDropDown();
  };

  const handleFilter = (e) => {
    const filtered = array.filter(function (str) {
      var keyword = e.target.value.toLowerCase();
      if (str?.[nameKey] !== null) {
        return str?.[nameKey].toLowerCase().includes(keyword);
      }
      return str.toLowerCase().includes(keyword);
    });
    setFilteredData(filtered);
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchInput(isObject ? dropDownValue?.[nameKey] : dropDownValue);
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isDropDownOpen]);

  const isSelected = (element, dropdown) => {
    if (isObject) {
      return element?.[mainKey] === dropdown?.[mainKey]
        ? 'dropDownItems selected'
        : 'dropDownItems';
    }
    return element === dropdown ? 'dropDownItems selected' : 'dropDownItems';
  };

  useUpdateEffect(() => {
    setSearchInput(isObject ? dropDownValue?.[nameKey] : dropDownValue);
  }, [changeToReset]);
  useUpdateEffect(() => {
    setFilteredData(array);
  }, [array]);

  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      <div className='mainTitle' onClick={() => handleDropDown()}>
        {search ? (
          <input
            type='text'
            placeholder={inputPlaceHolder}
            value={searchInput}
            onClick={(e) => stopPropgation(e)}
            onChange={(e) => handleFilter(e)}
          />
        ) : (
          <>{isObject ? dropDownValue?.[nameKey] : dropDownValue}</>
        )}
        <img className={isDropDownOpen ? ' open' : ''} src={arrowDropDownIcon} alt='' />
      </div>
      <div className={isDropDownOpen ? 'dropDownArray open' : 'dropDownArray'}>
        {React.Children.toArray(
          filteredData.map((elem) => {
            return (
              <div
                className={isSelected(elem, dropDownValue)}
                onClick={() => handleSelection(elem)}
                title={isObject ? elem?.[nameKey] : elem}
              >
                {isObject ? elem?.[nameKey] : elem}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default NormalDropDown;
