import { AreaSeries, AreaStack, BarGroup, BarSeries, BarStack, LineSeries } from '@visx/xychart';
import { getDefaultColorByIndex, hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';
import React from 'react';

const GetYXChart = ({
  xData,
  yData,
  accessors,
  getRequiredFormat,
  type,
  curve,
  colorScale,
  automaticOpacity = 0.7,
}) => {
  const selectChart = () => {
    if (type === 'MULTILINEHORIZONTAL') {
      return (
        <>
          {Object.keys(xData).map((key) => {
            return (
              <LineSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
              />
            );
          })}
        </>
      );
    }

    if (type === 'BARSTACKHORIZONTAL') {
      return (
        <BarStack
          // offset='none'
          offset={undefined}
        >
          {Object.keys(xData).map((key) => {
            return (
              <BarSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
              />
            );
          })}
        </BarStack>
      );
    }
    if (type === 'BARHORIZONTALCOLOR') {
      return (
        <>
          <BarStack
            // offset='none'
            offset={undefined}
          >
            {Object.keys(xData).map((key, index) => {
              return (
                <BarSeries
                  key={key}
                  dataKey={key}
                  data={getRequiredFormat(xData[key].data, yData)}
                  {...accessors}
                  colorAccessor={(d) => {
                    return (
                      colorScale?.(key) ??
                      hexWithOpacity(getDefaultColorByIndex(index), automaticOpacity)
                    );
                  }}
                />
              );
            })}
          </BarStack>
        </>
      );
    }

    if (type === 'AREASTACKHORIZONTAL') {
      return (
        <AreaStack curve={curve} renderLine={true}>
          {Object.keys(xData).map((key) => {
            return (
              <AreaSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
                fillOpacity={0.4}
              />
            );
          })}
        </AreaStack>
      );
    }

    if (type === 'BARHORIZONTAL') {
      return (
        <>
          {Object.keys(xData).map((key) => {
            return (
              <BarSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
                fillOpacity={0.7}
              />
            );
          })}
        </>
      );
    }
    if (type === 'HORIZONTALLINE') {
      return (
        <>
          {Object.keys(yData).map((key) => {
            return (
              <LineSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
              />
            );
          })}
        </>
      );
    }

    if (type === 'BARGROUPHORIZONTAL') {
      return (
        <BarGroup>
          {Object.keys(xData).map((key) => {
            return (
              <BarSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData[key].data, yData)}
                {...accessors}
              />
            );
          })}
        </BarGroup>
      );
    }
    if (type === 'AREA') {
      return (
        <>
          {Object.keys(yData).map((key) => {
            return (
              <AreaSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(xData, yData[key].data)}
                {...accessors}
                fillOpacity={0.3}
              />
            );
          })}
        </>
      );
    }

    throw Error('Invalid Chart Type'); // Invalid XYType Type
  };

  return <>{selectChart()}</>;
};

export default GetYXChart;
