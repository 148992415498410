import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

interface CustomTooltipProps {
  divPostion: any;
  componentName: string;
  setDivPostion: Function;
  children?: React.ReactNode;
}
export const defaultToolTipMargin = {
  x: 15,
  y: 20,
};
const CustomTooltip = ({
  divPostion,
  setDivPostion,
  componentName,
  children = <></>,
}: CustomTooltipProps) => {
  const ContainerID = `${componentName}ToolTipInitiatorID`;
  const InnerWapperID = `${componentName}ToolTipInnerWapperID`;

  useEffect(() => {
    const handleCardMove = (event: any) => {
      setDivPostion({
        x: event.clientX + defaultToolTipMargin.x,
        y: event.clientY + defaultToolTipMargin.y,
        show: true,
      });
    };

    const checkForClassName = document.getElementById(ContainerID);

    if (checkForClassName) {
      const newDiv: any = document.createElement('div');
      newDiv.setAttribute('id', InnerWapperID);
      ReactDOM.render(
        <ToolTipDivWrapper divPostion={divPostion} componentName={componentName}>
          {children}
        </ToolTipDivWrapper>,
        newDiv
      );
      checkForClassName.append(newDiv);
    }

    window.addEventListener('mousemove', handleCardMove);
    return () => {
      const checkForClassName: any = document.getElementById(InnerWapperID);
      if (checkForClassName) {
        checkForClassName.remove();
      }
      window.removeEventListener('mousemove', handleCardMove);
    };
  }, [divPostion]);
  return <></>;
};

export default CustomTooltip;

interface ToolTipInitiator {
  componentName: string;
}

export const ToolTipInitiator = ({ componentName }: ToolTipInitiator) => {
  const ContainerID = `${componentName}ToolTipInitiatorID`;
  useEffect(() => {
    const checkForClassName = document.getElementById(ContainerID);

    if (!checkForClassName) {
      const newDiv: any = document.createElement('div');
      newDiv.setAttribute('id', ContainerID);
      document.getElementById('root')?.append(newDiv);
    }

    return () => {
      const checkForClassName: any = document.getElementById(ContainerID);
      if (checkForClassName) {
        checkForClassName.remove();
      }
    };
  }, []);

  return <></>;
};

interface ToolTipDivWrapperProps {
  divPostion: {
    x: number;
    y: number;
    show: boolean;
  };
  children: React.ReactNode;
  componentName: string;
}

export const ToolTipDivWrapper = ({
  divPostion,
  children,
  componentName,
}: ToolTipDivWrapperProps) => {
  const outerDivStyle = divPostion?.show
    ? { ...outerDiv, ...show, left: divPostion.x, top: divPostion.y }
    : { ...outerDiv, left: divPostion.x, top: divPostion.y };

  return (
    <div id={`${componentName}ToolTip`} style={{ ...outerDivStyle, position: 'fixed' }}>
      {children}
    </div>
  );
};

const outerDiv = {
  top: '0',
  left: '0',
  padding: '.5rem .75rem',
  borderRadius: '6px',
  zIndex: 99999,
  backgroundColor: 'white',
  boxShadow: '3px 3px 5px #00000025',
  opacity: 0,
  transition: '300ms ease-in-out',
  scale: 0,
  maxWidth: '20rem',
};
const show = {
  scale: 1,
  opacity: 1,
};
