import React from 'react';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import DragableAxis from '../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../components/BuildComponents/FilterDiv/FilterIndex';
import ChartInsight from '../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const WordCloudCase = ({ stateProps }) => {
  const { isInsight, setIsInsight, xAxisData, setXAxisData, setChartXAxisStyle, chartType } = stateProps;
  const { xAxisId } = allDropableID;
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls("Axis")} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls("Default Filters")}</div>
        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>

      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default WordCloudCase;
