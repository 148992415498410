import { mls } from 'lib/multilanguagesupport';

import React from 'react';
import ScatterPlotChart from './ScatterPlotChart';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

const ScatterPlotType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  gradientColor,
  theme,
  chartHeight,
  desc,
  chartData,
}) => {
  return (
    <>
      {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
        <div style={{ height: chartHeight }}>
          <ParentSize>
            {({ width, height }) => (
              <ScatterPlotChart
                data={data}
                width={width}
                height={height || 400}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                xCategory={xCategory}
                yCategory={yCategory}
                theme={theme}
                chartData={chartData}
              />
            )}
          </ParentSize>
        </div>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default ScatterPlotType;
