import React, { useEffect, useRef, useState } from 'react';
// import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';

import useUpdateEffect from '../../hooks/useUpdateEffect';
import { IoIosArrowDown } from 'react-icons/io';

interface DropDownProps {
  array: any;
  dropDownValue: any;
  setdropDownValue: any;
  changeToReset?: boolean;
  className?: string;
  search?: boolean;
  inputPlaceHolder?: string;
  nameKey?: string;
  mainKey?: string;
  isChildernInsert?: boolean;
  ObjectInArray?: boolean;
  children: any;
}
const DropDown = ({
  array,
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  mainKey = 'key',
  isChildernInsert = false,
  ObjectInArray = false,
  children,
}: DropDownProps) => {
  const isObject = typeof dropDownValue === 'object' ? true : false;
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(array);

  const [selectedValue, setSelectedValue] = useState(() => {
    if (isObject) {
      return dropDownValue?.[nameKey];
    }
    if (ObjectInArray) {
      const filterValue = array.filter((elem: any) => elem[mainKey] === dropDownValue);
      if (filterValue[0]) {
        return filterValue[0];
      }
    }
    return dropDownValue;
  });
  const [searchInput, setSearchInput] = useState(() => {
    if (isObject) {
      return dropDownValue?.[nameKey];
    }
    if (ObjectInArray) {
      const filterValue = array.filter((elem: any) => elem[mainKey] === dropDownValue);
      if (filterValue[0]) {
        return filterValue[0]?.[nameKey];
      }
    }
    return dropDownValue;
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDropDown = () => {
    setisDropDownOpen((currentData) => !currentData);
  };
  const stopPropgation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setisDropDownOpen(true);
  };
  const handleSelection = (elem: any) => {
    setSearchInput(elem?.[nameKey] ?? elem);
    setSelectedValue(elem);
    setdropDownValue(isObject || ObjectInArray ? elem?.[mainKey] : elem);
    handleDropDown();
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = array.filter(function (str: any) {
      let keyword = e.target.value.toLowerCase();
      if (str?.[nameKey] !== null) {
        return str?.[nameKey].toLowerCase().includes(keyword);
      }
      return str.toLowerCase().includes(keyword);
    });
    setFilteredData(filtered);
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchInput(isObject || ObjectInArray ? dropDownValue?.[nameKey] : dropDownValue);
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isDropDownOpen]);

  const isSelected = (element: any, dropdown: any) => {
    if (isObject || ObjectInArray) {
      return element?.[mainKey] === (dropdown?.[mainKey] ?? dropdown)
        ? 'dropDownItems selected'
        : 'dropDownItems';
    }
    return element === dropdown ? 'dropDownItems selected' : 'dropDownItems';
  };

  useUpdateEffect(() => {
    setSearchInput(isObject || ObjectInArray ? dropDownValue?.[nameKey] : dropDownValue);
  }, [changeToReset]);
  useUpdateEffect(() => {
    setFilteredData(array);
  }, [array]);

  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      <div className='mainTitle' onClick={() => handleDropDown()}>
        {isChildernInsert ? (
          <>{children}</>
        ) : (
          <>
            {search ? (
              <input
                type='text'
                placeholder={inputPlaceHolder}
                value={searchInput}
                onClick={(e) => stopPropgation(e)}
                onChange={(e) => handleFilter(e)}
              />
            ) : (
              <>{isObject || ObjectInArray ? selectedValue?.[nameKey] : selectedValue}</>
            )}
            <div className={isDropDownOpen ? 'dropDownarrow open' : 'dropDownarrow'}>
              <IoIosArrowDown />
            </div>
            {/* <img className={isDropDownOpen ? ' open' : ''} src={arrowDropDownIcon} alt='' /> */}
          </>
        )}
      </div>
      {/* <div className="fullDiv">s</div> */}
      <div className={isDropDownOpen ? 'dropDownArray open' : 'dropDownArray'}>
        {React.Children.toArray(
          filteredData.map((elem: any) => {
            return (
              <div
                className={isSelected(elem, dropDownValue)}
                onClick={() => handleSelection(elem)}
                title={isObject || ObjectInArray ? elem?.[nameKey] : elem}
              >
                {isObject || ObjectInArray ? elem?.[nameKey] : elem}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default DropDown;
