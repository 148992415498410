import React, { useEffect, useMemo, useState } from 'react';
import './editConnections.scss';
import './editConnectionsForm.scss';
import UploadConnection from './ConnectionGroups/UploadConnection/UploadConnection';
import {
  newConnectionFormsSchema,
  uploadFileTableConfigFields,
} from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import { useLocation } from 'react-router-dom';
import DatabaseConnection from './ConnectionGroups/DatabaseConnection/DatabaseConnection';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { FaTimes } from 'react-icons/fa';
import DeleteModal from '../DeleteModal';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';
import { _uploadFileToServer } from 'lib/server-connection/connections';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@mui/material';

const defaultConnectionGroupOption = {
  connectionGroup: newConnectionFormsSchema.upload.key,
  connectionType: {},
  selectedConnection: { data: {} },
};
interface editConnectionsProps {
  selectedConnectionID: string;
  appCredentials: any;
  isNewConnection: boolean;
  setIsNewConnection: Function;
  openEditConnections: boolean;
  handleClose: Function;
}
const getConnectionTypeDataUsingConnectionType = (connectorTypeKey: string) => {
  let connectorType: { [key: string]: any } = {};
  Object.keys(newConnectionFormsSchema).some((groupKey: string) => {
    const groupElem = newConnectionFormsSchema[groupKey as keyof typeof newConnectionFormsSchema];
    const isFind = Object.keys(groupElem.type).some((groupTypeKey: string) => {
      const groupTypeElem: any = groupElem.type[groupTypeKey as keyof typeof groupElem.type];
      if (groupTypeElem.type === connectorTypeKey) {
        connectorType = groupTypeElem;
        return true;
      }
      return false;
    });
    return isFind;
  });
  return connectorType;
};
const getDefaultConnectionGroup = ({
  isNewConnection,
  appCredentials,
  selectedConnectionID,
}: {
  isNewConnection: boolean;
  appCredentials: any;
  selectedConnectionID: string;
}) => {
  let finalData = defaultConnectionGroupOption;

  if (!isNewConnection) {
    const selectedConnection =
      appCredentials[selectedConnectionID] ?? defaultConnectionGroupOption.selectedConnection;
    Object.keys(newConnectionFormsSchema).some((groupKey: string) => {
      const groupElem = newConnectionFormsSchema[groupKey as keyof typeof newConnectionFormsSchema];
      const isFind = Object.keys(groupElem.type).some((groupTypeKey: string) => {
        const groupTypeElem: any = groupElem.type[groupTypeKey as keyof typeof groupElem.type];

        if (groupTypeElem.type === selectedConnection.connectorType) {
          finalData = {
            ...finalData,
            connectionGroup: groupKey,
            connectionType: groupTypeElem,
            selectedConnection: selectedConnection,
          };

          return true;
        }
        return false;
      });
      return isFind;
    });
  }

  return finalData;
};
const EditConnections = ({
  selectedConnectionID,
  appCredentials,
  isNewConnection,
  setIsNewConnection,
  openEditConnections,
  handleClose,
}: editConnectionsProps) => {
  const [editConnectionMode, setIsEditConnectionMode] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const connectionData = useMemo(
    () => getDefaultConnectionGroup({ isNewConnection, appCredentials, selectedConnectionID }),
    [isNewConnection, appCredentials, selectedConnectionID]
  );

  const [selectedConnectionType, setSelectedConnectionType] = useState<{ [key: string]: any }>(
    connectionData?.connectionType
  );

  const [formData, setFormData] = useState<{ [key: string]: any }>({
    ...(connectionData?.selectedConnection ?? {}),
    // ...(connectionData?.selectedConnection?.data ?? {}),
  });

  const [tableConfig, setTableConfig] = useState<{ [key: string]: any }>({});

  const handleBackButton = () => {
    setSelectedConnectionType({});
    setFormData({});
  };

  return (
    <>
      {openEditConnections ? (
        <PopUpDivChild
          className={'editConnectionsPopup'}
          initialValue={openEditConnections}
          setPopUp={handleClose}
          header={false}
          popupTitle={isNewConnection ? 'Create a Connection' : 'Edit Connection'}
        >
          <div className='popupTitleDiv'>
            <div className='popupTitle'>
              {isNewConnection ? (
                <div className='editConnectionsActionButtons'>
                  {selectedConnectionType?.type ? (
                    <div
                      className='actionButton'
                      onClick={() => {
                        handleBackButton();
                      }}
                    >
                      <MdOutlineArrowBackIos />
                    </div>
                  ) : null}
                  {mls('Create a Connection')}
                </div>
              ) : (
                <>
                  <div className='editConnectionsActionButtons'>
                    {editConnectionMode ? (
                      <div
                        className='actionButton'
                        onClick={() => {
                          setIsEditConnectionMode(!editConnectionMode);
                        }}
                      >
                        <MdOutlineArrowBackIos />
                      </div>
                    ) : null}
                    {mls('Edit Connection')}
                  </div>
                </>
              )}
            </div>
            {!isNewConnection && editConnectionMode ? (
              <button
                className='primaryButton removeButton'
                onClick={() => setIsDeleteModalOpen(true)}
              >
                {mls('Delete Connection')}
              </button>
            ) : null}
            <div className='closeButton' onClick={() => handleClose()}>
              <FaTimes />
            </div>
          </div>
          {isDeleteModalOpen && (
            <DeleteModal
              connection={formData}
              isOpen={isDeleteModalOpen}
              closeModal={() => {
                setIsDeleteModalOpen(false);
                handleClose();
                // setDeletingConnection(null);
              }}
              handlePopupClose={() => setIsDeleteModalOpen(false)}
            />
          )}
          <EditConnectionsInner
            selectedConnectionID={selectedConnectionID}
            appCredentials={appCredentials}
            isNewConnection={isNewConnection}
            setIsNewConnection={setIsNewConnection}
            editConnectionMode={editConnectionMode}
            setIsEditConnectionMode={setIsEditConnectionMode}
            connectionData={connectionData}
            selectedConnection={connectionData.selectedConnection}
            selectedConnectionType={selectedConnectionType}
            setSelectedConnectionType={setSelectedConnectionType}
            formData={formData}
            setFormData={setFormData}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
          />
        </PopUpDivChild>
      ) : null}
    </>
  );
};
interface editConnectionsInnerProps {
  selectedConnectionID: string;
  appCredentials: any;
  isNewConnection: boolean;
  setIsNewConnection: Function;
  editConnectionMode: boolean;
  setIsEditConnectionMode: Function;
  connectionData: any;
  selectedConnectionType: any;
  setSelectedConnectionType: Function;
  formData: any;
  selectedConnection: any;
  setFormData: Function;
  tableConfig: any;
  setTableConfig: Function;
}
const EditConnectionsInner = ({
  selectedConnectionID,
  appCredentials,
  selectedConnection,
  isNewConnection,
  setIsNewConnection,
  editConnectionMode,
  setIsEditConnectionMode,
  connectionData,
  selectedConnectionType,
  setSelectedConnectionType,
  formData,
  setFormData,
  tableConfig,
  setTableConfig,
}: editConnectionsInnerProps) => {
  const [connectionGroup, setConnectionGroup] = useState(connectionData.connectionGroup);
  const [tableData, setTableData] = useState({});
  const [fileToUploadObject, setFileToUploadObject] = useState<{ [key: string]: any }>({});
  const [dataSourceLoading, setDataSourceLoading] = useState(!isNewConnection);

  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const handleGroupSwitch = ({ connectionGroupKey }: { connectionGroupKey: string }) => {
    setConnectionGroup(connectionGroupKey);
    setIsNewConnection(true);
    setSelectedConnectionType({});
    setFormData({});
    setFileToUploadObject({});
  };
  const handleBackButton = () => {
    setSelectedConnectionType({});
    setFormData({});
  };
  const credentialID = formData?.credentialID ? formData?.credentialID : uuidv4();

  const saveConnection = async ({ connectionType }: any) => {
    let defaultFormData: { [key: string]: any } = {};
    connectionType.fields.forEach((fieldData: any) => {
      if (fieldData.required) {
        defaultFormData = { ...defaultFormData, [fieldData.name]: fieldData.defaultValue };
      }
    });

    const finalFormData = {
      ...formData,
      data: {
        ...defaultFormData,
        ...formData.data,
      },
      appID,
      connectorType: connectionType.type,
      description: formData.description ?? '',
      connectionName: formData.connectionName ?? '',
      credentialID,
      refreshInterval: 3,
    };
    const reqFormData = new FormData();
    reqFormData.append('connectorType', finalFormData.connectorType);
    reqFormData.append('appID', finalFormData.appID);
    reqFormData.append('credentialID', finalFormData.credentialID);
    reqFormData.append('data', JSON.stringify(finalFormData.data));
    reqFormData.append('description', finalFormData.description);
    reqFormData.append('refreshInterval', finalFormData.refreshInterval);
    reqFormData.append('connectionName', finalFormData.connectionName);
    Object.keys(fileToUploadObject).forEach((fileToUploadKey) => {
      reqFormData.append(fileToUploadKey, fileToUploadObject[fileToUploadKey]);
    });
    const connectionAPI = isNewConnection ? 'createCredentials' : 'updateCredential';
    const apiEndPoint = `${appID}/api/${connectionAPI}/credentials`;

    const allTableConfigFields = getConnectionTypeDataUsingConnectionType(
      finalFormData.connectorType
    ).tableConfig.tableConfigFields;

    const tableData: any = await getTableHead({
      reqFormData,
      apiEndPoint,
      allTableConfigFields: allTableConfigFields,
    });
    if (tableData.skip) {
      toast.error(
        tableData?.message ??
          mls("Some issues encountered. Couldn't able to Save. Please try again later.")
      );
      return { skip: true };
    }
    setFormData(finalFormData);
    publishToProduction(appID);
    setTableData(tableData?.data ?? {});
    setTableConfig(tableData?.tableConfig ?? {});
    setSelectedConnectionType(connectionType ?? {});
    setIsNewConnection(false);
    setIsEditConnectionMode(false);
    if (!isNewConnection) {
      toast.success(`${mls('Connection is updated successfully')}.`);
    }
    return { skip: false };
  };

  const getTableDataFirstTime = async () => {
    const reqFormData = new FormData();
    reqFormData.append('connectorType', formData.connectorType ?? selectedConnection.connectorType);
    reqFormData.append('appID', formData.appID ?? appID);
    reqFormData.append('credentialID', formData.credentialID ?? selectedConnection.credentialID);
    reqFormData.append('data', JSON.stringify(formData));
    const apiEndPoint = `${appID}/api/getConnectorTable/credentials`;

    const tableData: any = await getTableHead({
      reqFormData,
      apiEndPoint,
      allTableConfigFields:
        connectionData?.connectionType?.tableConfig?.tableConfigFields ??
        uploadFileTableConfigFields,
    });
    setTableData(tableData?.data);
    setTableConfig(tableData?.tableConfig ?? {});
    setDataSourceLoading(false);
  };
  useEffect(() => {
    if (!isNewConnection) {
      getTableDataFirstTime();
    }
  }, []);

  return (
    <div
      className={`${
        isNewConnection && !selectedConnectionType?.type
          ? 'editConnectionsOuterContainer'
          : 'editConnectionsOuterContainer noConnectionToggleStrip'
      }`}
    >
      {isNewConnection && !selectedConnectionType?.type ? (
        <div className='connectionGroupSelectionContainer'>
          {selectedConnectionType?.type ? (
            <div className='editConnectionsActionButtons'>
              <div
                className='actionButton'
                onClick={() => {
                  handleBackButton();
                }}
              >
                <MdOutlineArrowBackIos />
              </div>
            </div>
          ) : (
            <div className='connectionGroupToggleContainer'>
              {React.Children.toArray(
                Object.keys(newConnectionFormsSchema).map((connectionGroupKey: string) => {
                  return (
                    <div
                      className={`connectionGroupOption ${
                        connectionGroup === connectionGroupKey ? 'selectedConnectionGroup' : ''
                      }`}
                      onClick={() => handleGroupSwitch({ connectionGroupKey })}
                    >
                      <b className='left-curve'></b>
                      {mls(
                        newConnectionFormsSchema[
                          connectionGroupKey as keyof typeof newConnectionFormsSchema
                        ].name
                      )}
                      <b className='right-curve'></b>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='connectionGroupSelectionContainer'></div>
      )}
      {dataSourceLoading ? (
        <div className='connectionMainContainer'>
          <div
            className='uploadConnection'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : (
        <div className='connectionMainContainer'>
          {connectionGroup === newConnectionFormsSchema.upload.key ? (
            <UploadConnection
              connectionType={newConnectionFormsSchema.upload.type.uploadFile}
              appID={appID}
              formData={formData}
              selectedConnection={selectedConnection}
              isNewConnection={isNewConnection}
              setFormData={setFormData}
              editConnectionMode={editConnectionMode}
              setIsEditConnectionMode={setIsEditConnectionMode}
              tableData={tableData}
              setTableData={setTableData}
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              fileToUploadObject={fileToUploadObject}
              setFileToUploadObject={setFileToUploadObject}
              saveConnection={saveConnection}
              credentialID={credentialID}
            />
          ) : null}
          {connectionGroup === newConnectionFormsSchema.dataBase.key ? (
            <DatabaseConnection
              connectionTypes={
                newConnectionFormsSchema[connectionGroup as keyof typeof newConnectionFormsSchema]
                  .type ?? {}
              }
              appID={appID}
              formData={formData}
              setFormData={setFormData}
              selectedConnection={selectedConnection}
              isNewConnection={isNewConnection}
              setIsNewConnection={setIsNewConnection}
              selectedConnectionType={selectedConnectionType}
              setSelectedConnectionType={setSelectedConnectionType}
              editConnectionMode={editConnectionMode}
              setIsEditConnectionMode={setIsEditConnectionMode}
              tableData={tableData}
              setTableData={setTableData}
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              saveConnection={saveConnection}
              fileToUploadObject={fileToUploadObject}
              setFileToUploadObject={setFileToUploadObject}
              credentialID={credentialID}
            />
          ) : null}
          {connectionGroup === newConnectionFormsSchema.apps.key ? (
            <DatabaseConnection
              connectionTypes={
                newConnectionFormsSchema[connectionGroup as keyof typeof newConnectionFormsSchema]
                  .type ?? {}
              }
              appID={appID}
              formData={formData}
              isNewConnection={isNewConnection}
              selectedConnection={selectedConnection}
              setIsNewConnection={setIsNewConnection}
              setFormData={setFormData}
              selectedConnectionType={selectedConnectionType}
              setSelectedConnectionType={setSelectedConnectionType}
              editConnectionMode={editConnectionMode}
              setIsEditConnectionMode={setIsEditConnectionMode}
              tableData={tableData}
              setTableData={setTableData}
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              saveConnection={saveConnection}
              fileToUploadObject={fileToUploadObject}
              setFileToUploadObject={setFileToUploadObject}
              credentialID={credentialID}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default EditConnections;

const getTableHead = async ({
  reqFormData,
  apiEndPoint,
  allTableConfigFields,
}: {
  reqFormData: any;
  apiEndPoint: string;
  allTableConfigFields: any;
}) => {
  const saveRes = await _uploadFileToServer(apiEndPoint, reqFormData)
    .then(async (response) => {
      if (!response.__d3__error) {
        // tableConfigFor
        let tableConfig: { [key: string]: any } = {};
        response.response.tableNames.forEach((sheetID: string) => {
          let tableConfigFields: { [key: string]: any } = {};
          allTableConfigFields.forEach((connectionTableConfigFieldsElem: any) => {
            tableConfigFields = {
              ...tableConfigFields,
              [connectionTableConfigFieldsElem.name]: connectionTableConfigFieldsElem.defaultValue,
            };
          });
          tableConfig = { ...tableConfig, [sheetID]: tableConfigFields };
        });
        return {
          skip: false,
          tableConfig,
          data: response?.response?.tableSchema,
        };
      } else {
        return {
          skip: true,
          message:
            response?.response?.errorMsg ??
            mls("Some issues encountered. Couldn't able to Save. Please try again later."),
        };
      }
    })
    .catch((error) => {
      return {
        skip: true,
        message: mls("Some issues encountered. Couldn't able to Save. Please try again later."),
      };
    });
  return saveRes;
};
