import React from 'react';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import ChatBotIndex from 'lib/Supista-AI/chatBot';
import { useParams } from 'react-router-dom';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';

const ChatAI = () => {
  const { appID } = useParams();

  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const allTables = Object.keys(appSchema.appDatatable || []);
  const joinTables = Object.keys(appSchema.appJointable || []);
  const customTables = Object.keys(appSchema.appCustomtable || []);

  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap = {};
  Object.keys(appSchema.appDatatable || {}).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = appSchema.appDatatable[tableID].name;
  });
  Object.keys(appSchema.appJointable || []).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = appSchema.appJointable[tableID].name;
  });
  Object.keys(appSchema.appCustomtable || []).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = appSchema.appCustomtable[tableID].name;
  });

  const tableNames = [...(allTables || []), ...(joinTables || []), ...(customTables || [])];

  const updateSchema = useUpdateSchema();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];
  const isAccess =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');

  const appSheets = analyticsSchema?.appSheets || {};
  const appDash = analyticsSchema?.appDash || {};
  const chartLocation = getSchemaLocation(schemaConstants.type.CHART);
  const sheetLocation = getSchemaLocation(schemaConstants.type.SHEET);
  const dashLocation = getSchemaLocation(schemaConstants.type.DASH);

  const updateReduxSchema = (chartSchema, sheetSchema, dashSchema, chartID, sheetID, dashID) => {
    updateSchema(chartLocation, chartID, chartSchema);
    updateSchema(sheetLocation, sheetID, sheetSchema);
    updateSchema(dashLocation, dashID, dashSchema);
  };

  const updateReduxSheetSchema = (sheetID, sheetSchema) => {
    updateSchema(sheetLocation, sheetID, sheetSchema);
  };
  if (Object.keys(appSchema || {}).length === 0) {
    return <></>;
  }
  return (
    <ChatBotIndex
      tables={tableNames}
      projectID=''
      appID={appID}
      appSheets={appSheets}
      appDash={appDash}
      updateReduxSchema={updateReduxSchema}
      allTableIDAndNamesMap={allTableIDAndNamesMap}
      updateReduxSheetSchema={updateReduxSheetSchema}
      isPinningAllowed={isAccess}
    />
  );
};

export default ChatAI;
