import React, { useMemo, useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/themes/prism-dark.css'; //Example style, you can use another
import { _supistaAnalyticsApi, _supistaApiPost } from 'lib/server-connection/connections';
import { v4 as uuid } from 'uuid';

import { mls } from 'lib/multilanguagesupport';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { toast } from 'react-toastify';
import { convertTableData } from 'lib/reusable-components/DisplayTable/tableFunction';
import { AllCustomCommandType, allOutputTypes } from './data/sqlCustomCommandsData';

interface SqlQueryAreaProps {
  tableColumnProps: any;
}
const SqlQueryArea = ({ tableColumnProps }: SqlQueryAreaProps) => {
  // const [code, setSqlQuery] = useState(`function add(a, b) {\n  return a + b;\n}`);
  const {
    appID,
    tableSchema,
    setIsTablePopUp,
    setSelectedTableData,
    setTableLoading,
    setIsSelectedTableDisabled,
    setTableSchema,
    apiProps,
    useUpdateSchema,
    customCommandType,
    lastQuery,
    setLastQuery,
    outputData,
    setOutputData,
    setIsAnyChangesSqlCommands,
    setIsAnyChangesSchema,
    setCustomColumnSchema,
    sqlQuery,
    setSqlQuery,
    disableSqlStatements,
    setTableHeadLoading,
    timeFrame,
    dateTimeCol,
  } = tableColumnProps;

  const tableID = tableSchema?.tableID || uuid();
  const tableList = tableSchema?.tables ?? [tableID];

  const testStatement = async () => {
    if (disableSqlStatements) {
      toast.warn(mls('Please save to continue'));
      return;
    }
    setTableLoading(true);
    setIsTablePopUp(false);
    setTableHeadLoading(true);
    const testData = {
      appID: appID,
      tableID: tableID,
      sqlQuery: sqlQuery,
      reportTimeframe: {
        period: timeFrame,
      },
      // tableSchema: { ...tableSchema, sqlQuery, tableID },
      // tableList: tableList,
      datetimeColumn: dateTimeCol,
    };

    const finalEndPoint = `${appID}/apiType/dataReport/reports`;
    const responseData = await _supistaAnalyticsApi(finalEndPoint, testData);

    if (responseData?.__d3__error) {
      setOutputData({
        outputMsg: responseData?.response?.errorMsg ?? responseData?.message,
        outputType: allOutputTypes.error,
      });
      setSelectedTableData([]);
      setCustomColumnSchema([]);
      setIsSelectedTableDisabled(true);
    } else {
      setOutputData({
        outputMsg: 'Success',
        outputType: allOutputTypes.success,
      });
      const finalTableData = convertTableData({
        tableHead: responseData?.response?.dataHead,
        tableSchema: { data: responseData?.response?.tableSchema },
        highlightTableSchema:
          customCommandType === AllCustomCommandType.customTable
            ? responseData?.response?.tableSchema
            : responseData?.response?.customColumnSchema,
      });
      if (customCommandType === AllCustomCommandType.customTable) {
        setCustomColumnSchema(responseData?.response?.columnSchema);
      } else {
        setCustomColumnSchema(responseData?.response?.customColumnSchema);
      }
      setSelectedTableData(finalTableData);
      setTableLoading(false);
      setTableHeadLoading(true);
      setIsTablePopUp(true);
      setIsSelectedTableDisabled(false);
    }
    const newTableSchema = {
      ...tableSchema,
      sqlQuery: sqlQuery,
      defaultDatetimeCol: '',
      columnSchema: responseData?.response?.columnSchema ?? tableSchema?.columnSchema ?? [],
      customColumnSchema:
        responseData?.response?.customColumnSchema ?? tableSchema?.customColumnSchema ?? [],
    };

    setTableSchema(newTableSchema);
    setTableHeadLoading(false);
    setTableLoading(false);
    setLastQuery(sqlQuery);
    setIsAnyChangesSqlCommands(false);
    setIsAnyChangesSchema(true);
  };
  // const testStatement = async () => {
  //   if (disableSqlStatements) {
  //     toast.warn(mls('Please save to continue'));
  //     return;
  //   }
  //   setTableLoading(true);
  //   setIsTablePopUp(false);
  //   setTableHeadLoading(true);
  //   const testSqlColumn = apiProps.testStatementApi;
  //   const testData = {
  //     appID: appID,
  //     tableID: tableID,
  //     tableList: tableList,
  //     sqlQuery: sqlQuery,
  //     tableSchema: { ...tableSchema, sqlQuery, tableID },
  //   };

  //   const finalEndPoint = `${appID}/customType/${testSqlColumn}/customSql`;
  //   const responseData = await _supistaAnalyticsApi(finalEndPoint, testData);

  //   if (responseData?.__d3__error) {
  //     setOutputData({
  //       outputMsg: responseData?.response?.errorMsg ?? responseData?.message,
  //       outputType: allOutputTypes.error,
  //     });
  //     setSelectedTableData([]);
  //     setCustomColumnSchema([]);
  //     setIsSelectedTableDisabled(true);
  //   } else {
  //     setOutputData({
  //       outputMsg: 'Success',
  //       outputType: allOutputTypes.success,
  //     });
  //     const finalTableData = convertTableData({
  //       tableHead: responseData?.response?.dataHead,
  //       tableSchema: { data: responseData?.response?.tableSchema },
  //       highlightTableSchema:
  //         customCommandType === AllCustomCommandType.customTable
  //           ? responseData?.response?.tableSchema
  //           : responseData?.response?.customColumnSchema,
  //     });
  //     if (customCommandType === AllCustomCommandType.customTable) {
  //       setCustomColumnSchema(responseData?.response?.columnSchema);
  //     } else {
  //       setCustomColumnSchema(responseData?.response?.customColumnSchema);
  //     }
  //     setSelectedTableData(finalTableData);
  //     setTableLoading(false);
  //     setTableHeadLoading(true);
  //     setIsTablePopUp(true);
  //     setIsSelectedTableDisabled(false);
  //   }
  //   const newTableSchema = {
  //     ...tableSchema,
  //     sqlQuery: sqlQuery,
  //     defaultDatetimeCol: '',
  //     columnSchema: responseData?.response?.columnSchema ?? tableSchema?.columnSchema ?? [],
  //     customColumnSchema:
  //       responseData?.response?.customColumnSchema ?? tableSchema?.customColumnSchema ?? [],
  //   };

  //   setTableSchema(newTableSchema);
  //   setTableHeadLoading(false);
  //   setTableLoading(false);
  //   setLastQuery(sqlQuery);
  //   setIsAnyChangesSqlCommands(false);
  //   setIsAnyChangesSchema(true);
  // };
  const isQuerySame = useMemo(() => {
    return lastQuery === sqlQuery;
  }, [lastQuery, sqlQuery]);

  return (
    <div className='sqlInnerQueryArea'>
      <div className='sqlStatementTitle'>
        <div className='sqlStatementMainTitle'>{mls('SQL Commands')}</div>
        <div className='editorButtonArea'>
          <button
            className={`primaryButton ${disableSqlStatements ? 'disabledButton' : ''}`}
            // disabled={disableSqlStatements}
            onClick={() => testStatement()}
          >
            {mls('Run & Add')}
          </button>
        </div>
      </div>
      <div className='editorArea statementEditor'>
        <div className='editorTitle'>{mls('Commands')}</div>
        <div className='mainEditorArea'>
          <Editor
            value={sqlQuery}
            onValueChange={(sqlQuery) => {
              setSqlQuery(sqlQuery);
              setIsAnyChangesSqlCommands(true);
            }}
            highlight={(code) => highlight(code, languages.sql)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              minHeight: '100%',
            }}
            placeholder='Write your Sql Query here'
          />
        </div>
      </div>

      <div
        className={`editorArea outputEditor ${
          outputData.outputType === allOutputTypes.error
            ? 'outputError'
            : outputData.outputType === allOutputTypes.success
            ? 'outputSuccess'
            : 'outputNormal'
        } ${isQuerySame ? 'querySame' : ''}`}
      >
        <div className='editorTitle'>
          {mls('Output')}
          {!isQuerySame ? '* ( Statement changed )' : null}
        </div>
        <div className='mainEditorArea'>
          <Editor
            value={outputData.outputMsg}
            disabled={true}
            onValueChange={() => {}}
            highlight={(code) => highlight(code ?? '', languages.sql)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              minHeight: '100%',
            }}
            placeholder={`${mls('Please click on test to check output')}.`}
          />
        </div>
      </div>
    </div>
  );
};

export default SqlQueryArea;
