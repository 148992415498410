/* eslint import/prefer-default-export: 0 */
import { defaultStyles } from '@visx/tooltip';

export const defaultMargin = {
  top: 10,
  bottom: 70,
  left: 70,
  right: 0,
};

export const defaultMarginMulti = {
  top: 0,
  bottom: 40,
  left: 70,
  right: 0,
};

// Height of navbar on top
export const navHeight = 65;
// Top and bottom padding for full screen card
export const paddingTopAndBottom = 25;

// Monochromatic color scheme
export const defaultColors = ['#009ef7', '#589cc4', '#70b5d2', '#8ecee0', '#aee7ef', '#d1ffff'];

// Variant color scheme
export const variantColors = [
  '#276bfd',
  '#fdb827',
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
];

// Axis Color
export const defaultAxisColor = '#000000';

// Font Sizes
export const defaultAxisLabelFontSize = 14;

// Brush height and width constants
export const defaultBrushHeight = 30;
export const defaultBrushWidth = 30;

// Enum for helping in date Range
export const dateRangeEnum = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  QUARTER: 3,
  YEAR: 4,
};

// Helps toggling between day, week, month, ... for datetime type data
export const toggleObj = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
};

export const defaultToolTipStyling = {
  ...defaultStyles,
  backgroundColor: '#ffffffee',
  // border: '1px solid #727272',
  width: '20rem',
  // backgroundColor: 'rgba(53,71,125,0.8)',
  borderRadius: '6px',
  color: '#333',
  padding: 12,
  zIndex: 999,
};

export const STARTING_DATA_LENGTH = 100;

export const geoColors = ['#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'];
