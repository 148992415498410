//  For charts server
// const api = 'https://analytics-api.supista.com/';
const api = process.env.REACT_APP_IS_LOCALHOST === 'true' ? 'http://localhost:8000/' : 'https://analytics-api.supista.com/';

export async function fetchDataToPlot(params, url) {
  const serverURL = `${api}${url}`;
  try {
    const response = await fetch(serverURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(params),
      credentials: 'include',
    }).then((response) => response.json());
    return new Promise((resolve, reject) => resolve(response));
  }
  catch (error) {
    return new Promise((resolve, reject) => resolve({}));
  }
}

//  For Supista server

const API = 'https://test.supista.com/api';

export const supistaApiGet = (endpoint) => {
  return fetch(`${API}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => response.json());
};

export const supistaApiPost = (endpoint, data) => {
  return fetch(`${API}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};
