import { v5 as uuidv5 } from 'uuid';
import { defaultDataType, defaultDateArray, defaultDateKey } from '../data/defaultFilterSchema';
import { dataTableColumnObjectTypes } from 'lib/data-connectors/dependencies/constant';

const uuidKey = '1b671a64-40d5-491e-99b0-da01ff1f3341';

export const filterByTableName = (data: any, isDashboard: boolean) => {
  if (!data) return [];
  const normalFilter = () => {
    const tableData = data;
    // Adding unique ID
    const tableSchemaWithID = tableData.map((o: any) => {
      return {
        ...o,
        id: uuidv5(`${o.tableName}.${o.columnName}`, uuidKey),
      };
    });
    // o.data = o.data.map(d => ({ ...d,
    //   id: uuidv4()
    // }));
    //   return o;
    // });
    // Grouped By TableName
    let o: any = {};
    let groupedArray = tableSchemaWithID.reduce(function (r: any, el: any) {
      let e = el.tableName;
      if (!o[e as keyof typeof o]) {
        o[e as keyof typeof o] = {
          tableName: el?.nameOfTable || el?.tableName,
          items: [],
        };
        r.push(o[e as keyof typeof o]);
      }
      o[e as keyof typeof o]?.items.push(el);
      return r;
    }, []);
    return groupedArray;
  };
  // const tableData = data;
  // // Adding unique ID
  // const tableSchemaWithID = tableData.map((o:any) => {
  //   return {
  //     ...o,
  //     id: uuidv5(`${o.tableName}.${o.columnName}`, uuidKey),
  //   };
  // });
  // // o.data = o.data.map(d => ({ ...d,
  // //   id: uuidv4()
  // // }));
  // //   return o;
  // // });
  // // Grouped By TableName
  // let o:any = {};
  // let groupedArray = tableSchemaWithID.reduce(function (r:any, el:any) {
  //   let e = el.tableName;
  //   if (!o[e as keyof typeof o]) {
  //     o[e as keyof typeof o] = {
  //       tableName: el.tableName,
  //       items: [],
  //     };
  //     r.push(o[e as keyof typeof o]);
  //   }
  //   o[e as keyof typeof o]?.items.push(el);
  //   return r;
  // }, []);
  const dashBoardFilter = () => {
    const FilterByGroup = (data: any, groupedBy = 'tableName') => {
      if (!data) return [];
      let o: any = {};
      let groupedArray = data.reduce(function (r: any, el: any) {
        let e = el?.[groupedBy as keyof typeof data];
        let elName;
        if (groupedBy === 'dashboardName') {
          elName = el?.[groupedBy as keyof typeof data];
        } else elName = el?.['nameOfTable' as keyof typeof data] || el?.[groupedBy as keyof typeof data];
        if (!o[e as keyof typeof o]) {
          o[e as keyof typeof o] = {
            [groupedBy]: elName,
            items: [],
          };
          r.push(o[e as keyof typeof o]);
        }
        o[e as keyof typeof o]?.items.push(el);
        return r;
      }, []);
      return groupedArray;
    };
    const groupByDashboard = FilterByGroup(data, 'dashboardName');
    const finalGrouping = groupByDashboard.map((elm: any) => {
      const groupedArray = FilterByGroup(elm.items);
      const finalObject = {
        dashboardName: elm.dashboardName,
        items: groupedArray,
      };
      return finalObject;
    });
    return finalGrouping;
  };
  if (isDashboard) {
    return dashBoardFilter();
  }
  return normalFilter();
};
export const tableFilterFuntion = (props: any) => {
  const { search, type, allTableData, setFilterdTableSchema } = props;
  setFilterdTableSchema(() => {
    const newArray = allTableData.filter((el: any) => {
      const lowerCaseElemName = el.name.toLowerCase();
      const lowerCaseElemType = el.dataType;
      const lowerCaseValue = search.toLowerCase();
      if (type === defaultDataType.ALL) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      if (lowerCaseElemType === type) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      return false;
    });

    return newArray;
  });
};
export const addFilter = (selectedItems: any) => {
  const newSelection = {
    [`${selectedItems.tableName}.${selectedItems.columnName}`]: {
      column: selectedItems.columnName,
      dataType: selectedItems.dataType,
      sheetID: selectedItems.sheetID,
      tableName: selectedItems.tableName,
      value: [],
      filterData: selectedItems.filterData,
    },
  };
  return newSelection;
};

export const mergeDashboardSchema = (dashSchema: any) => {
  const addingExtraValue = (dashSchema: any) => {
    const newDashFilter = Object.keys(dashSchema).map((dashboradID: string) => {
      const newData = dashSchema[dashboradID as keyof typeof dashSchema].data.map((elem: any) => {
        const uniqueDashboardID = `${dashboradID}.${elem.tableName}.${elem.columnName}`;
        const dashboardName = dashSchema[dashboradID as keyof typeof dashSchema].name;
        const isJoinedTable =
          dashSchema[dashboradID as keyof typeof dashSchema].isJoinedTable || false;
        return { ...elem, uniqueDashboardID, dashboardName, isJoinedTable };
      });
      return newData;
    });
    return newDashFilter;
  };
  const extraValueArray: any[] = addingExtraValue(dashSchema);
  return extraValueArray.flat();
};
interface chartFilterPopulationProps {
  chartWidthObject: object;
  allTableData: any[];
  isDashboard: boolean;
}
export const chartFilterPopulation = ({
  chartWidthObject,
  allTableData,
  isDashboard,
}: chartFilterPopulationProps) => {
  if (chartWidthObject) {
    const Array = Object.entries(chartWidthObject).map(([key, subject]) => {
      if (isDashboard) {
        const [colomnData] = allTableData.filter(
          (e: any) =>
            e.sheetID + '.' + e.tableName + '.' + e.columnName === key ||
            e.tableName + '.' + e.columnName === key
        );
        const newObject = {
          ...colomnData,
          value: subject.value,
        };
        return newObject;
      }
      const [colomnData] = allTableData.filter(
        (e: any) => e.tableName + '.' + e.columnName === key
      );
      const newObject = {
        ...colomnData,
        value: subject.value,
      };
      return newObject;
    });

    return Array;
  } else {
    return [];
  }
};
interface filtersSchemaProps {
  filterArray: any;
  sheetID: string;
  isDashboard: boolean;
  isDataSource: boolean;
}
export const filtersSchema = ({
  filterArray,
  sheetID,
  isDashboard,
  isDataSource,
}: filtersSchemaProps) => {
  const defaultValue = {
    valueCAT: [],
    valueNUMmin: 0,
    valueNUMmax: Infinity,
    valueDATE: defaultDateArray[4],
  };
  const newFilterObject: any = {};
  filterArray.forEach((filterItem: any, i: number) => {
    const { dataType, tableName, columnName, value, nameOfTable, name } = filterItem;
    const updateValue = () => {
      if (dataType === defaultDataType?.CAT || dataType === defaultDataType.ID_TEXT) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.NUM || dataType === defaultDataType.ID_NUM) {
        return {
          min: value?.min ?? filterItem?.filterData?.range?.min ?? defaultValue.valueNUMmin,
          max: value?.max ?? filterItem?.filterData?.range?.max ?? defaultValue.valueNUMmax,
        };
      }
      if (dataType === defaultDataType.DATETIME) {
        if (value?.key === defaultDateKey.CUSTOM) {
          const newValue = { ...value };
          delete newValue.dateInISO;
          return newValue;
        }
        return value ? value : defaultValue.valueDATE;
      }
      if (dataType === defaultDataType.ID) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.GEO) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      return {};
    };
    const updateValueByDataSource = () => {
      if (
        dataType === dataTableColumnObjectTypes.text.key ||
        dataType === dataTableColumnObjectTypes.select.key ||
        dataType === dataTableColumnObjectTypes.id_text.key ||
        dataType === dataTableColumnObjectTypes.check.key ||
        dataType === dataTableColumnObjectTypes.boolean.key
      ) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (
        dataType === dataTableColumnObjectTypes.number.key ||
        dataType === dataTableColumnObjectTypes.id_num.key ||
        dataType === dataTableColumnObjectTypes.decimal.key
      ) {
        return {
          min: value?.min ?? filterItem?.filterData?.range?.min ?? defaultValue.valueNUMmin,
          max: value?.max ?? filterItem?.filterData?.range?.max ?? defaultValue.valueNUMmax,
        };
      }
      if (dataType === dataTableColumnObjectTypes.date.key) {
        if (value?.key === defaultDateKey.CUSTOM) {
          const newValue = { ...value };
          delete newValue.dateInISO;
          return newValue;
        }
        return value ? value : defaultValue.valueDATE;
      }
      if (dataType === defaultDataType.ID) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.GEO) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      return {};
    };
    // remove empty catagorical filter
    if (dataType === defaultDataType?.CAT || dataType === defaultDataType.ID_TEXT) {
      if ((value ?? []).length <= 0) {
        return;
      }
    }
    if (isDashboard) {
      const newObj = {
        uniqueDashboardID: filterItem?.uniqueDashboardID,
        column: columnName,
        dataType: dataType,
        tableName: tableName,
        // nameOfTable: filterItem?.nameOfTable,
        // name: filterItem?.name,
        sheetID: filterItem?.sheetID ?? sheetID,
        value: updateValue(),
      };
      newFilterObject[filterItem?.uniqueDashboardID] = newObj;
      return;
    }

    const newObj = {
      column: columnName,
      dataType: dataType,
      tableName: tableName,
      // nameOfTable: nameOfTable,
      name: filterItem?.name,
      sheetID: sheetID,
      value: isDataSource ? updateValueByDataSource() : updateValue(),
    };

    // Add object with Keynames
    const keyName = `${tableName}.${columnName}`;
    newFilterObject[keyName] = newObj;
    return;
  });

  return newFilterObject;
};
