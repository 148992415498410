import { CircularProgress, IconButton } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import { RiTableFill } from 'react-icons/ri';
import SendIcon from '@mui/icons-material/Send';
import TablePopUp from './TablePopUp/TablePopUp';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import { toast } from 'react-toastify';

interface chatBoxAreaProps {
  tableColumnProps: any;
}
const sqlQueryState = {
  loading: 'loading',
  close: 'close',
  open: 'open',
};
const ChatBoxArea = ({ tableColumnProps }: chatBoxAreaProps) => {
  const [message, setMessage] = useState('');
  const {
    tableLoading,
    isTablePopUp,
    setIsTablePopUp,
    selectedTableData,
    isSelectedTableDisabled,
    appID,
    tableSchema,
    sqlQueryData,
    setsqlQueryData,
    tableHeadLoading,
    apiProps,
  } = tableColumnProps;

  const handleTablePopUp = () => {
    setIsTablePopUp(!isTablePopUp);
  };
  const handleSend = async (e: any) => {
    e.preventDefault();
    setsqlQueryData((currentData: any) => ({ ...currentData, queryState: sqlQueryState.loading }));
    const AIToQueryColumn = apiProps.AIToQueryApi;
    const tableList = tableSchema?.tables ?? [tableSchema.tableID];

    const AIToQueryColumnData = {
      appID: appID,
      tableID: tableSchema.tableID,
      tableList: tableList,
      userQuery: message,
    };
    const finalEndPoint = `${appID}/customType/${AIToQueryColumn}/customSql`;
    const responseData = await _supistaAnalyticsApi(finalEndPoint, AIToQueryColumnData);
    if (responseData.__d3__error) {
      toast.error(mls('Unable to Generate Query'), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setsqlQueryData((currentData: any) => ({
        ...currentData,
        queryState: sqlQueryState.close,
        query: '',
      }));
    } else {
      setsqlQueryData((currentData: any) => ({
        ...currentData,
        queryState: sqlQueryState.open,
        query: responseData?.response?.AIQuery,
      }));
    }
  };

  return (
    <div className='chatBoxArea'>
      <form onSubmit={handleSend} className='ChatInput'>
        <div className='ChatInnerInput'>
          {isTablePopUp ? (
            <TablePopUp
              isTablePopUp={isTablePopUp}
              setIsTablePopUp={setIsTablePopUp}
              tableData={selectedTableData}
              tableLoading={tableLoading || tableHeadLoading}
            />
          ) : null}
          <div className='leftOptionFormOption'>
            <IconButton
              className={isSelectedTableDisabled ? 'openTableButton disable' : 'openTableButton'}
              onClick={() => handleTablePopUp()}
              disabled={isSelectedTableDisabled}
            >
              <RiTableFill />
            </IconButton>
          </div>
          <div className='centerOptionFormOption introjs-chatbot'>
            <div className='chatBotInputDiv'>
              <input
                type='text'
                className='chatBotInput introjs-chatBotInput'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={mls('Type your query to generate SQL...')}
              />
            </div>
          </div>
          <div className='rightOptionFormOption'>
            <IconButton
              className={'surpriseButton submitEnable'}
              onClick={(e) => {
                handleSend(e);
              }}
              type='button'
              title={mls('Send')}
              disabled={sqlQueryData.queryState === sqlQueryState.loading || !message}
            >
              <div className='surpriseMeicon'>
                {sqlQueryData.queryState === sqlQueryState.loading ? (
                  <div className='loadingSpinner'>
                    <CircularProgress
                      style={{ height: '1.25rem', width: '1.25rem', color: 'white' }}
                    />
                  </div>
                ) : (
                  <SendIcon fontSize={'small'} />
                )}
              </div>
              <div className='surpriseMeText'>{mls('Send')}</div>
            </IconButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatBoxArea;
