import React, { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { defaultNestedArrayDraggableID } from '../../data/defaultSchema';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const DraggableWithTitleItem = (mainProps: mainProps) => {
  const { dragableDivId, stateProps, itemNumber, sheetKey } = mainProps;
  const { projectSheets } = stateProps;

  const chartLists = projectSheets[sheetKey]?.charts || [];
  const [showAll, setShowAll] = useState(false);

  const limitedChartLists = showAll ? chartLists : chartLists.slice(0, 5);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const newDragableDivId = `${dragableDivId}-<${
    defaultNestedArrayDraggableID.first
  }>${itemNumber.toString()}</${defaultNestedArrayDraggableID.first}>`;

  return (
    <>
      <Droppable droppableId={newDragableDivId} key={newDragableDivId} isDropDisabled={true}>
        {(provided, snapshot) => (
          <div className='dragableContainer' ref={provided.innerRef}>
            {limitedChartLists[0] ? null : (
              <div className='dragableItems' style={{ justifyContent: 'center' }}>
                {mls('No charts found')}
              </div>
            )}
            {React.Children.toArray(
              limitedChartLists?.map((dragableElem: any, i: number) => {
                return (
                  <DraggableWithTitleMainItem
                    i={i}
                    dragableElem={dragableElem}
                    newDragableDivId={newDragableDivId}
                    stateProps={stateProps}
                  />
                );
              })
            )}
            {!showAll && chartLists.length > 5 && (
              <div className='viewMore' onClick={() => handleShowAll()}>
                {mls('View More')}
              </div>
            )}
            {showAll && (
              <div className='viewMore' onClick={() => handleShowAll()}>
                {mls('Hide')}
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};
export default DraggableWithTitleItem;

const DraggableWithTitleMainItem = ({
  i,
  dragableElem,
  newDragableDivId,
  stateProps,
}: draggableWithTitleMainItemProps) => {
  const { chartsData, sections } = stateProps;
  const isChecked = (mainId: string) => {
    return sections.some((sectionElem: any) => {
      return sectionElem.charts.some((chartsElem: any) => {
        if (chartsElem.id === mainId) {
          return true;
        }
        return false;
      });
    });
  };
  const [checked, setChecked] = useState(() => {
    return isChecked(dragableElem);
  });

  useEffect(() => {
    setChecked(isChecked(dragableElem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);
  const chartIcon = allChartTypesObj[chartsData[dragableElem]?.chartType ?? '']?.icon;

  return (
    <>
      <Draggable
        key={dragableElem}
        draggableId={`${newDragableDivId + i.toString()}`}
        index={i}
        //   isDragDisabled={checked}
      >
        {(provided, snapshot) => (
          <>
            <div
              className='dragableItems'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
              title={chartsData[dragableElem]?.name}
            >
              {checked ? (
                <input
                  type='checkbox'
                  className='form-check-input'
                  name={chartsData[dragableElem]?.name}
                  value={chartsData[dragableElem]?.name}
                  checked={checked}
                  // onClick={() => isChecked()}
                  // onChange={(e) => removeAxisItem(e)}
                />
              ) : (
                <div className='draggingSvg'>
                  <RxDragHandleDots2 />
                </div>
              )}
              <div className='dragableItemsTitle'>{mls(chartsData[dragableElem]?.name)}</div>
              <div className='dragableItemsTitleIcon'>
                <img src={chartIcon} alt='' />
              </div>
            </div>
            {snapshot.isDragging && (
              <>
                <div className={'dragableItems'}>
                  {checked ? (
                    <input
                      type='checkbox'
                      className='form-check-input'
                      name={chartsData[dragableElem]?.name}
                      value={chartsData[dragableElem]?.name}
                      checked={checked}
                      // onClick={() => isChecked()}
                      // onChange={(e) => removeAxisItem(e)}
                    />
                  ) : (
                    <div className='draggingSvg'>
                      <RxDragHandleDots2 />
                    </div>
                  )}
                  {chartsData[dragableElem]?.name}
                </div>
              </>
            )}
          </>
        )}
      </Draggable>
    </>
  );
};

interface mainProps {
  dragableDivId: string | number;
  stateProps: any;
  itemNumber: number;
  sheetKey: string;
}
interface draggableWithTitleMainItemProps {
  i: number;
  dragableElem: any;
  newDragableDivId: string | number;
  stateProps: any;
}
