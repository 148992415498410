import React, { useRef, useState } from 'react';
import Chart from 'lib/visx-lib';
import PinningOptions from '../PinningOptions/PinningOptions';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import { getTableData, saveToDashboard } from '../../utils/chatBotHelperFunction';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import { supistaApiPost } from '../../utils/connections';
import { FiFilter } from 'react-icons/fi';
import { MdAddChart } from 'react-icons/md';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ChartOptions from './ChartOptions';
import { mls } from 'lib/multilanguagesupport';
import { finalFilterStringFunction } from 'lib/reusable-components/reusableFunction/chartHealperReusableFunction';

interface ChartMessageProps {
  messageIndex: number;
  msg: any;
  tableSchema: any;
  handleChartPopUp: Function;
  mainContainerWidth: number;
  chartHeight: number;
  appID: string;
  sheetID: string;
  appDash: any[];
  allTables: any;
  setDashboards: Function;
  handleChartFilter: Function;
  setMessages: Function;
  updateReduxSchema: Function;
  stateProps: any;
  allTableIDAndNamesMap: any;
}
const ChartMessage = ({
  msg,
  messageIndex,
  handleChartPopUp,
  mainContainerWidth,
  chartHeight,
  tableSchema,
  appDash,
  appID,
  sheetID,
  setDashboards,
  handleChartFilter,
  allTables,
  setMessages,
  updateReduxSchema,
  stateProps,
  allTableIDAndNamesMap,
}: ChartMessageProps) => {
  const { sheetSchema, selectedTable, updateSheet } = stateProps;
  const schema: any = !msg.error && msg.response ? Object.values(msg.response.schema)[0] : {};
  const plotData: any = !msg.error && msg.response ? Object.values(msg.response.plotData)[0] : {};
  const [isPiningOptionOpen, setisPiningOptionOpen] = useState(false);
  const [isFilterOpen, setisFilterOpen] = useState(false);
  const [chartSchema, setChartSchema]: any[] = useState({});
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [initialFilter, setInitialFilter]: any[] = useState(
    JSON.stringify(chartSchema?.filters ?? {})
  );
  const handlePin = () => {
    setisPiningOptionOpen((currentData: boolean) => !currentData);
  };
  const handleTopFilter = () => {
    const filterInString = JSON.stringify(chartFilter);
    setInitialFilter(filterInString);
    setisFilterOpen((currentData: boolean) => !currentData);
  };
  const finalTableSchema = getTableData({
    allTables,
    defaultData: tableSchema,
    tableID: msg?.table,
    target: 'tableSchema',
  });
  const newMainContainerWidth =
    mainContainerWidth >= 951 ? mainContainerWidth / 2 : mainContainerWidth;
  const mobileMode = newMainContainerWidth <= 450;

  const getContainerWidth = () => {
    const widthQuery = {
      web: 1000,
      tablet: 600,
      mobile: 450,
    };
    const containerWidthAttributes = {
      ONEFOURTH: {
        key: 'ONEFOURTH',
        width:
          mainContainerWidth >= widthQuery.web
            ? 1
            : mainContainerWidth >= widthQuery.tablet
            ? 2
            : 4,
      },
      HALF: {
        key: 'HALF',
        width: mainContainerWidth >= widthQuery.tablet ? 2 : 4,
      },
      TWOTHIRD: {
        key: 'TWOTHIRD',
        width: mainContainerWidth >= widthQuery.tablet ? 3 : 4,
      },
      FULL: {
        key: 'FULL',
        width: 4,
      },
    };
    const defaultWidth = containerWidthAttributes.HALF;
    const getWidth =
      containerWidthAttributes[schema?.chartWidth as keyof typeof containerWidthAttributes] ??
      defaultWidth;

    return (mainContainerWidth / 4) * getWidth.width;
  };
  const chartSettingsButton = () => {
    handleChartPopUp({
      isNewChart: false,
      chartData: schema,
      index: messageIndex,
      message: msg,
    });
  };
  const handleDashBoard = (dashID: string, isNewDashBoard: boolean) => {
    const finalTableSchema = getTableData({
      allTables,
      defaultData: tableSchema,
      tableID: msg?.table,
      target: 'tableSchema',
    });

    if (isNewDashBoard) {
      const randomDashID = `AIDash${uuidv4()}`;
      setSelectedDashboard(randomDashID);
      saveToDashboard({
        appDash,
        setDashboards,
        schema,
        dashID: randomDashID,
        appID,
        sheetID: schema?.sheetID,
        isNewDashBoard,
        dashName: dashID,
        updateReduxSchema,
        selectedTable,
        sheetSchema,
        updateSheet,
        tableSchema: finalTableSchema ?? tableSchema ?? {},
        allTableIDAndNamesMap,
      });
      return;
    }

    setSelectedDashboard(dashID);
    saveToDashboard({
      appDash,
      dashID,
      schema,
      appID,
      sheetID: schema?.sheetID,
      isNewDashBoard,
      setDashboards,
      updateReduxSchema,
      selectedTable,
      sheetSchema,
      updateSheet,
      tableSchema: allTables[msg.table]?.tableSchema ?? tableSchema ?? {},
      allTableIDAndNamesMap,
    });
  };
  const handleFilter = (filters: any) => {
    const newSchema = {
      ...schema,
      filters,
    };
    setChartSchema(newSchema);
  };
  const handleisFilterOpen = (action: boolean) => {
    const filterInString = JSON.stringify(chartSchema?.filters ?? {});

    if (action) {
      setisFilterOpen(action);
      return;
    }
    if (chartSchema?.filters && filterInString !== initialFilter) {
      const chartName = schema?.name ?? '';
      const filterString = finalFilterStringFunction({
        filtersSchema: chartSchema?.filters ?? {},
        chartName,
      });

      setMessages((prev: any) => [...prev, { message: filterString, sender: 'user' }]);

      handleChartFilter({
        response: chartSchema,
        index: messageIndex,
        isSkip: false,
        message: msg,
      });
    }

    handleChartFilter({
      response: chartSchema,
      index: messageIndex,
      isSkip: true,
      message: msg,
    });
    setisFilterOpen(action);
  };
  const chartFilter = schema?.filters ?? {};
  const filterCount = Object.keys(chartFilter).length;

  const handleChartUpdates: any = async (updatedValue: any) => {
    const endPoint = 'createCharts';
    const newData = {
      charts: msg.response.schema,
      projectID: schema.projectID,
      sheetID: schema.sheetID,
      appID: schema.appID,
      chartUpdates: updatedValue,
    };
    const res = await supistaApiPost(endPoint, newData, schema.appID);
    if (!res.__d3__error) {
      const chartID = Object.keys(msg.response.schema)[0];
      const schemaData = {
        skip: false,
        chartSchema: msg.response.schema,
        plotData: { [chartID]: res?.response?.charts[chartID] },
      };
      if (schemaData.skip) {
        return;
      }
      setMessages((currentMessages: any) => {
        const copyArray = Array.from(currentMessages);

        const [selectedItem]: any = copyArray.splice(messageIndex, 1);
        copyArray.splice(messageIndex, 0, {
          ...selectedItem,
          response: {
            plotData: schemaData.plotData,
            schema: schemaData.chartSchema,
          },
        });
        return copyArray;
      });
      //  setChartSchema(schemaData.chartSchema);
      //  setPlotData(schemaData.plotData);
    }
  };
  const ChartOptionWidth = 50;
  const isInsight = schema?.isInsight ?? true;
  const insightRef: any = useRef(null);
  const chartExportButtonRef: any = useRef(null);

  const defaultDateTimeCol = finalTableSchema?.defaultTable?.defaultDatetimeCol || '';

  return (
    <>
      {!msg.error && msg.response ? (
        <div className='chartMessageContainer'>
          <div
            className='chartMessage'
            style={{
              width: getContainerWidth(),
            }}
          >
            <div className='logoDiv'>
              <img alt='Logo' className='logo' src='https://www.supista.com/assets/img/logo.png' />
            </div>
            {isFilterOpen ? (
              <FilterDiv
                isPopUp={isFilterOpen}
                setIsPopUp={handleisFilterOpen}
                setChartFilter={handleFilter}
                chartFilter={schema?.filters ?? {}}
                appID={schema?.appID}
                sheetID={getTableData({
                  allTables,
                  defaultData: sheetID,
                  tableID: msg?.table,
                  target: 'sheetID',
                })}
                allTableData={finalTableSchema?.data}
                isJoinedTable={
                  getTableData({
                    allTables,
                    defaultData: tableSchema,
                    tableID: msg?.table,
                    target: 'tableSchema',
                  })?.isJoinedTable ?? false
                }
              />
            ) : null}
            <div className='chartContainer'>
              <Chart
                chartKey={schema?.chartID}
                tableID={finalTableSchema?.defaultTable?.tableID}
                chartData={schema ?? {}}
                dataToPlot={plotData ?? {}}
                // cardWidth={schema?.chartWidth || 'HALF'}
                // cardWidth={
                //   schema?.chartType !== 'CARD' && schema?.chartWidth ? schema?.chartWidth : 'HALF'
                // }
                cardWidth={'FULL'}
                useChartSettingsButton={() => {
                  return () => chartSettingsButton();
                }}
                isInsightChartSchema={true}
                handleChartUpdates={handleChartUpdates}
                updateFilter={() => {}}
                isUpdateLoading={false}
                filterSchema={[]}
                chartHeight={chartHeight}
                // isProd={'sheet'}
                // PINING
                filterProps={{}}
                insightRef={insightRef}
                chartExportButtonRef={chartExportButtonRef}
                isInsightShow={false}
                isMenuButtonShow={false}
                timeFrameObj={msg?.timeFrame ?? {}}
                filterObj={chartFilter}
                // filterProps={{ filterCount, handleTopFilter, isTopFilter: true }}
                // isPin={true}
                // handlePin={handlePin}
                // isTopFilter={true}
                // handleTopFilter={handleTopFilter}
              />
            </div>
          </div>
          <ChartOptions
            chartSettingsButton={chartSettingsButton}
            filterCount={filterCount}
            handleTopFilter={handleTopFilter}
            handlePin={handlePin}
            width={ChartOptionWidth}
            mobileMode={mobileMode}
            isInsight={isInsight}
            insightRef={insightRef}
            chartExportButtonRef={chartExportButtonRef}
            appDash={appDash}
            selectedDashboard={selectedDashboard}
            isPiningOptionOpen={isPiningOptionOpen}
            setisPiningOptionOpen={setisPiningOptionOpen}
            handleDashBoard={handleDashBoard}
            defaultDateTimeCol={defaultDateTimeCol}
            chartSchema={schema}
            stateProps={stateProps}
            tableSchema={finalTableSchema}
            chartTimeFrame={msg?.timeFrame ?? {}}
            message={msg}
            setMessages={setMessages}
            appID={appID}
          />
        </div>
      ) : (
        <Box className='aiMessages'>
          <div className='logoDiv'>
            <img alt='Logo' className='logo' src='https://www.supista.com/assets/img/logo.png' />
          </div>
          {msg.message}
        </Box>
      )}
    </>
  );
};
export default ChartMessage;

const allFiltersString = (chartSchemaFilters: any) => {
  if (!chartSchemaFilters) {
    return [];
  }
  const filterString = Object.keys(chartSchemaFilters).map((filterKey: string) => {
    const filter = chartSchemaFilters[filterKey];
    if (filter.dataType === 'CAT') {
      return `<b>${filter.name}:</b> ${mls('values are')} <b>[${filter.value.join(',')}]</b>`;
    }
    if (filter.dataType === 'NUM') {
      return `<b>${filter.name}:</b> ${mls('range are')} <b>${filter.value.min} to ${
        filter.value.max
      }</b>`;
    }
    if (filter.dataType === 'DATETIME') {
      if (filter.value.period === 'LASTXDAYS') {
        return `<b>${filter.name}:</b> ${mls('selected last')} <b>${filter.value.xDay} ${mls(
          'days'
        )}</b>`;
      }
      if (filter.value.period === 'CUSTOM') {
        return `<b>${filter.name}:</b> ${mls('selected')} <b>${checkForDate(
          filter.value.startDate
        )} to ${checkForDate(filter.value.endDate)}</b>`;
      }
      return `<b>${filter.name}</b> ${mls('selected date is')} <b>${filter.value.period}</b>`;
    }
    return '';
  });
  return filterString;
};

const checkForDate = (elem: any) => {
  const timestamp: any = Date.parse(elem);
  if (isNaN(timestamp) !== false) {
    return elem;
  }
  const nowDate: any = new Date(elem);
  const date =
    nowDate.getFullYear() +
    '/' +
    ('0' + (nowDate.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + nowDate.getDate()).slice(-2);
  return date;
};
