import React, { useRef, useState } from 'react';
import './Multiselect.scss';
// import x from '../../assets/x.svg';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import useUpdateEffect from '../../../helpers/hooks/useUpdateEffect';
import { getDefaultColorByIndex } from '../../../helpers/colorHelper';
interface props {
  options: any[];
  onSelect: any;
  onRemove: any;
  theme: any;
  selectedValues: any;
  showCheckbox: any;
  isObject: any;
  show: boolean;
  targetElem: any;
  appID: string;
  sheetID: string;
  startingPage?: number;
  isJoinedTable: string | boolean;
}

const Multiselect = (props: props) => {
  const { options, onSelect, onRemove, selectedValues, show = false, targetElem, theme } = props;
  const allValues = targetElem?.filterData?.allValues;

  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const [searchInput, setsearchInput] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const textInput = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const handleDiv = () => {
    setisDivOpen(!isDivOpen);
  };
  const handleFocus = () => {
    setisDivOpen(true);
    textInput.current.focus();
  };
  const handleSelect = (target: string, ischecked: boolean) => {
    if (ischecked) {
      const removedElem = selectedValues.filter((elem: string) => elem !== target);
      onRemove(removedElem);
      return;
    }
    onSelect([...selectedValues, target]);
  };

  const handleSearchInput = (e: any) => {
    setsearchInput(e.target.value);
  };
  const handleSearch = (target: string) => {
    if (allValues) {
      const filtered = options.filter((elem: string) => {
        return elem.toLowerCase().includes(target.toLowerCase());
      });
      setFilteredOptions(filtered);
      return;
    }
    setFilteredOptions([]);
  };
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSelect(searchInput, false);
      setsearchInput('');
    }
  };
  useUpdateEffect(() => {
    if (!isDivOpen) {
      const timer = setTimeout(() => setisContent(isDivOpen), 300);
      return () => {
        clearTimeout(timer);
      };
    }
    setisContent(isDivOpen);
  }, [isDivOpen]);

  useUpdateEffect(() => {
    handleSearch(searchInput);
  }, [searchInput]);

  useUpdateEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => handleDiv(), 10);
      }
    }

    if (isDivOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isDivOpen]);

  return (
    <div className='MultiselectWithApi' ref={wrapperRef}>
      <div className='inputDiv' onClick={() => handleFocus()}>
        {React.Children.toArray(
          selectedValues.map((elem: string, i: number) => {
            const color = theme?.colors[elem as keyof typeof elem] ?? getDefaultColorByIndex(i);
            return (
              <div className='selectedItems' style={{ backgroundColor: hexToRgbA(color, 0.1) }}>
                <div className='title' style={{ color: color, fontWeight: 600 }}>
                  {elem}
                </div>
                <div
                  className='remove'
                  onClick={() => {
                    handleSelect(elem, true);
                  }}
                  style={{ background: '#00000042' }}
                >
                  {/* <img src={x} alt='' /> */}
                  <IoMdClose />
                </div>
              </div>
            );
          })
        )}
        <input
          type='text'
          placeholder='Select'
          ref={textInput}
          value={searchInput}
          onKeyPress={(e) => handleEnter(e)}
          onChange={(e) => handleSearchInput(e)}
        />
      </div>

      <div className='optionOuterContainer'>
        {/* {isDivOpen && ( */}
        <div
          className={isDivOpen ? 'optionsContainer open' : 'optionsContainer'}
          style={{ bottom: '4.5rem' }}
        >
          {/* {filteredOptions[0] === undefined && !loading && !apiLock && (
            <div className='options'>
              <div className='title'>No data Found</div>
            </div>
          )} */}
          {isContent &&
            React.Children.toArray(
              filteredOptions.map((elem: string, i: number) => {
                const isChecked = selectedValues.some((selectedElem: string) => {
                  return selectedElem === elem;
                });
                return (
                  <Options elem={elem} checked={isChecked} index={i} handleSelect={handleSelect} />
                );
              })
            )}
        </div>
      </div>
    </div>
  );
};

export default Multiselect;

const Options = ({
  elem,
  checked,
  index,
  handleSelect,
}: {
  elem: string;
  checked: boolean;
  index: number;
  handleSelect: any;
}) => {
  return (
    <div className='options' onClick={() => handleSelect(elem, checked)}>
      <div className={checked ? 'optionCheckBox checked' : 'optionCheckBox'}>
        {checked && <IoMdCheckmark />}
      </div>
      <div className='title'>{elem}</div>
    </div>
  );
};

let timer: any;

const debounce = (func: Function, timeout = 300) => {
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
function hexToRgbA(hex: any, opacity: any) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  } else return 'rgba(0,0,0,' + opacity + ')';
}
