import React, { useEffect, useState } from 'react';
// import WithoutStyleToggle from '../components/WithoutStyleToggle';
import { toggleColumnDetails } from '../data/defaultSchema';
import { columnToggleFunc } from '../utils/coloumnToggleHelper';
import WithStyleToggle from '../components/WithStyleToggle';
import KpiMeterBuildCase from '../components/KpiMeter/KpiMeterBuildCase';
import KpiMeterStyleCase from '../components/KpiMeter/KpiMeterStyleCase';
// import Sections from '../components/secondColumn/sections/Sections';
// import AddSection from '../components/secondColumn/addSection/AddSection';

// import NormalCase from './ChartCases/NormalCase';
// import SpecialCase from './ChartCases/SpecialCase';
import './chartBuild.scss';
interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
  };
  mainPorps: any;
}
const SecondColumn = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;
  const { minimizedDiv } = toggleProps;
  const { stateProps } = mainPorps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  const [settingToggleState, setSettingToggleState] = useState('build');
  const buddleProps = { isMainDivOn, settingToggleState, setSettingToggleState };
  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName,
      setIsMainDivOn,
      setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }

  return (
    <>
      <div className={isMainDivOn ? 'secondColumn' : 'secondColumn close'}>
        <WithStyleToggle
          // stateProps={stateProps}
          toggleProps={toggleProps}
          buddleProps={buddleProps}
        >
          {settingToggleState === 'build' && <KpiMeterBuildCase stateProps={stateProps} />}
          {settingToggleState === 'style' && <KpiMeterStyleCase stateProps={stateProps} />}
        </WithStyleToggle>
      </div>
    </>
  );
};

export default SecondColumn;
