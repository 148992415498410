import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';

import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import {
  deleteJoinTableOrNormalTable,
  deleteSheetandCharts,
  getJoinTableIDs,
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
  getTableIDSTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import useUpdateJoinTableSchema from 'lib/data-connectors/dependencies/useUpdateJoinTablesSchema';
import TBDPreview from 'lib/data-connectors/dependencies/TBDPreview/TBDPreview';

const DeleteTable = ({
  appID,
  setDeleteOpen,
  deleteOpen,
  handleDeleteTable,
  tableID,
  tableSchema,
}) => {
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );

  const sheetsSchema = analyticsSchema?.appSheets || {};
  const reportSchema = analyticsSchema.appReports || {};
  const dashSchema = analyticsSchema.appDash || {};
  const customTableSchema = analyticsSchema.appCustomtable || {};
  const joinTableSchema = analyticsSchema.appJointable || {};
  const joinTableIDsTBD = getJoinTableIDs(tableID, joinTableSchema, []);

  const updateJoinTableSchema = useUpdateJoinTableSchema();
  const updateSchema = useUpdateSchema();

  const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
    [tableID],
    joinTableIDsTBD,
    sheetsSchema
  );
  const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
    chartIDs: chartIDSTBD ?? [],
    dashSchema: dashSchema,
    reportSchema: reportSchema,
  });
  const { customTableIDsTBD } = getTableIDSTBD({
    tableIDs: [tableID],
    joinTableSchema: joinTableSchema,
    customTableSchema: customTableSchema,
  });
  const reportIDsKeys = Object.keys(reportIDsTBD);
  const dashIDsKeys = Object.keys(dashIDsTBD);

  return (
    <Modal open={deleteOpen} onClose={() => setDeleteOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div
          className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto'
          style={{ maxWidth: '90vw' }}
        >
          <p className='p-3 fs-4 fw-bold'>{mls('Are you sure you want to delete this table')}?</p>
          <TBDPreview
            sheetIDsTBD={sheetIDsTBD}
            reportIDsKeys={reportIDsKeys}
            dashIDsKeys={dashIDsKeys}
            joinTableIDsTBD={joinTableIDsTBD}
            customTableIDsTBD={customTableIDsTBD}
            reportSchema={reportSchema}
            dashSchema={dashSchema}
            sheetsSchema={sheetsSchema}
            joinTableSchema={joinTableSchema}
            customTableSchema={customTableSchema}
          />
          <div className='d-flex pt-10'>
            <button
              data-bs-dismiss='modal'
              onClick={() => handleDeleteTable(tableSchema)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Delete table')}
            </button>
            <button
              onClick={() => setDeleteOpen(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { DeleteTable };
