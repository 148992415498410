import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { iconWithColor } from './geoMapFunction';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';

interface geoMapMarkerProps {
  geoMarkerElem: any;
  isColor: boolean;
  theme: any;
  colorScale: Function;
  columnNames: any;
  allAxisData: any;
  geoMapColor: any;
  yAxis: any[];
}
const GeoMapMarkers = ({
  geoMarkerElem,
  isColor,
  theme,
  colorScale,
  columnNames,
  allAxisData,
  geoMapColor = {
    color: '#e13b2f',
    opacity: 70,
  },
  yAxis,
}: geoMapMarkerProps) => {
  if (isColor) {
    const position: any = [geoMarkerElem[1], geoMarkerElem[2]];
    return (
      <Marker
        position={position}
        icon={iconWithColor({
          iconColor: colorScale(geoMarkerElem[0]) ?? theme.colors[geoMarkerElem[0]],
        })}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup(),
        }}
      >
        <Popup>
          <TooltipElem
            columnNames={columnNames}
            index={0}
            geoMarkerElem={geoMarkerElem}
            allAxisData={allAxisData}
          />
          <TooltipElem
            columnNames={columnNames}
            index={1}
            geoMarkerElem={geoMarkerElem}
            allAxisData={allAxisData}
          />
          <TooltipElem
            columnNames={columnNames}
            index={2}
            geoMarkerElem={geoMarkerElem}
            allAxisData={allAxisData}
          />
          {React.Children.toArray(
            yAxis.map((elem, i) => {
              return (
                <TooltipElem
                  columnNames={columnNames}
                  index={3 + i}
                  geoMarkerElem={geoMarkerElem}
                  allAxisData={allAxisData}
                />
              );
            })
          )}
        </Popup>
      </Marker>
    );
  }
  const position: any = [geoMarkerElem[0], geoMarkerElem[1]];

  return (
    <Marker
      position={position}
      icon={iconWithColor({
        iconColor: hexWithOpacity(geoMapColor.color, geoMapColor.opacity / 100),
      })}
      eventHandlers={{
        mouseover: (event) => event.target.openPopup(),
        mouseout: (event) => event.target.closePopup(),
      }}
    >
      <Popup>
        <TooltipElem
          columnNames={columnNames}
          index={0}
          geoMarkerElem={geoMarkerElem}
          allAxisData={allAxisData}
        />
        <TooltipElem
          columnNames={columnNames}
          index={1}
          geoMarkerElem={geoMarkerElem}
          allAxisData={allAxisData}
        />
        {React.Children.toArray(
          yAxis.map((elem, i) => {
            return (
              <TooltipElem
                columnNames={columnNames}
                index={2 + i}
                geoMarkerElem={geoMarkerElem}
                allAxisData={allAxisData}
              />
            );
          })
        )}
      </Popup>
    </Marker>
  );
};

export default GeoMapMarkers;

const getAxisData = ({ allAxisData, columnName }: { allAxisData: any[]; columnName: string }) => {
  const data = allAxisData.find((elem) => elem?.uniqueColumnName === columnName);
  return data;
};
const TooltipElem = ({
  columnNames,
  index,
  geoMarkerElem,
  allAxisData,
}: {
  columnNames: string[];
  index: number;
  geoMarkerElem: any[];
  allAxisData: any[];
}) => {
  const axisData = getAxisData({
    allAxisData,
    columnName: columnNames[index],
  });
  const isNum = axisData.axis === 'yAxisId';

  if (axisData?.show) {
    return (
      <div style={{ fontSize: '1rem' }}>
        {axisData?.label}:&nbsp;
        {isNum ? (
          <b>
            {numberFormatter({
              type: axisData?.formatter,
              value: geoMarkerElem[index],
            })}
          </b>
        ) : (
          <b>{geoMarkerElem[index]}</b>
        )}
      </div>
    );
  }
  return null;
};
