import React, { useRef, useState } from 'react';
import xBlack from '../../assets/xBlack.svg';
import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';
import boxDropDownIcon from '../../assets/boxDropDownIcon.svg';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import {
  updateAxisStyleLable,
  removeToolTip,
  removeValueToolTip,
  updateChartType,
  getAutomaticKpiTargetData,
} from '../../utils/chartHelperFunctions';
import AxisOpration, { PCT_BY_GROUP } from './AxisOpration';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';

const DragableAxis = (props) => {
  const { dragableDivId, stateProps, axisData, setAxisData, setStyle, defaultLable, disable = false } = props;
  const {
    setGeoGroup,
    setChartToolTip,
    chartType,
    setchartType,
    setBarStackLineTargetPoints,
    setKpiTargetPoints,
    chartSchemaMemo,
    kpiTargetPoints,
    setDoubleAxis
  } = stateProps;
  const removeData = (i) => {
    const removeAxisItem = ({ sourceData, setSourceData }) => {
      const items = Array.from(sourceData);
      const [removedItem] = items.splice(i, 1);

      setSourceData(items);
      if (defaultLable !== undefined) {
        // Removing from axis Style
        updateAxisStyleLable(setStyle, items, defaultLable);
      }

      // update ChartType
      updateChartType({
        axisData: items,
        destinationID: dragableDivId,
        chartType,
        setchartType,
        toast,
        isAdd: false,
        stateProps,
      });

      //Remove ToolTip
      setChartToolTip((preElem) => removeToolTip(preElem, removedItem.uniqueColumnName));
      //Remove removeValueToolTip
      if (chartType?.toolTip?.isMultipleValue) {
        setChartToolTip((preElem) => removeValueToolTip(preElem, removedItem.uniqueColumnName));
      }

      //Special Case
      //GEO
      if (
        chartType.chartKey === allChartKeys.GEOINDIA ||
        chartType.chartKey === allChartKeys.GEOUSA ||
        chartType.chartKey === allChartKeys.GEOCANADA ||
        chartType.chartKey === allChartKeys.GEOWORLD
      ) {
        if (dragableDivId === allDropableID.yAxisId) {
          setGeoGroup([]);
        }
      }
      //BARSTACKLINE
      if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
        if (dragableDivId === allDropableID.xAxisId) {
          setBarStackLineTargetPoints(defaultChartSetting.barStackLineTargetPoints);
        }
      }
      //KPIMETER
      if (chartType.chartKey === allChartKeys.KPIMETER) {
        if (dragableDivId === allDropableID.yAxisId) {
          setKpiTargetPoints((currentTargetPointData) => {
            return {
              ...currentTargetPointData,
              targets: [],
            };
          });
        }
      }
      //DOUBLEAXIS
      setDoubleAxis(currentData => {
        return { ...currentData, isDoubleAxis: defaultChartSetting.doubleAxis.isDoubleAxis }
      })
    };

    removeAxisItem({
      sourceData: axisData,
      setSourceData: setAxisData,
    });
  };

  const newSetAxisData = async (callback) => {
    //KPIMETER
    const finalData = typeof callback === "function" ? await callback(axisData) : callback;
    //KPIMETER
    if (chartType.chartKey === allChartKeys.KPIMETER) {
      const targets = await getAutomaticKpiTargetData({
        column: finalData[0],
        chartSchema: { ...chartSchemaMemo, yAxis: finalData },
        chartType: chartType,
        kpiTargetPoints,
      });
      if (targets?.skip === false) {
        setKpiTargetPoints((currentTargetPointData) => {
          return {
            ...currentTargetPointData,
            targets: targets?.finalTargets,
          };
        });
      }
    }

    setAxisData(finalData);
  };
  return (
    <>
      <Droppable droppableId={dragableDivId}>
        {(provided) => (
          <div className={disable ? "dragableContainer disable" : 'dragableContainer'} {...provided.droppableProps} ref={provided.innerRef}>
            {React.Children.toArray(
              axisData?.map((elem, i) => {
                return (
                  <Draggable key={`${i}`} draggableId={`${dragableDivId + i}`} index={i}>
                    {(provided) => (
                      <>
                        <DragableItem
                          provided={provided}
                          elem={elem}
                          removeData={removeData}
                          index={i}
                          axisId={dragableDivId}
                          chartType={chartType}
                          axisData={axisData}
                          setAxisData={newSetAxisData}
                          // setAxisData={setAxisData}
                          stateProps={stateProps}
                        />
                      </>
                    )}
                  </Draggable>
                );
              })
            )}
            {provided.placeholder}
            <div className='dropPlaceholder'>
              <span>{mls("Drop columns here")}</span>
            </div>
            {/* <div className="axisInnerDivAdd"><button onClick={()=>addItem()}>+</button></div> */}
          </div>
        )}
      </Droppable>
    </>
  );
};

export default DragableAxis;

function DragableItem(props) {
  const { provided, elem, removeData, index, axisId, stateProps, setAxisData } = props;
  const { allTableData, populateCount } = stateProps;
  const dragableItemRef = useRef(null);

  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [isPctByGroupPopUp, setIsPctByGroupPopUp] = useState(false);

  const handleDropDown = (action) => {
    if (action === false) {
      setisDropDownOpen(action);
      return;
    }
    setisDropDownOpen((currentData) => !currentData);
  };
  return (
    <>
      <PCT_BY_GROUP
        isPopUp={isPctByGroupPopUp}
        setIsPopUp={setIsPctByGroupPopUp}
        allTableData={allTableData}
        operations={elem.operations}
        setAxisData={setAxisData}
        populateCount={populateCount}
        index={index}
      />
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div
          className='dragableItem'
          ref={dragableItemRef}

        >

          <div
            title={`${elem?.operations?.type ? `(${elem.operations.type})` : ' '} ${elem.label}`}
            className='elemTitle'
          >
            {elem.operations.type && (
              <>
                <span>({elem.operations.type})</span>&nbsp;
              </>
            )}
            {elem.label}
          </div>
          {isDropDownOpen ? (
            <img src={arrowDropDownIcon} alt='' className='boxDropDownIcon' onClick={() => handleDropDown()} />
          ) : (
            <img
              src={boxDropDownIcon}
              alt=''
              className='boxDropDownIcon'
              onClick={() => handleDropDown()}
            />
          )}
          <img
            src={xBlack}
            alt=''
            className='removeaxisItem'
            onClick={() => removeData(index, axisId)}
          />
          <AxisOpration
            props={{
              ...props,
              handleDropDown,
              isDropDownOpen,
              setIsPctByGroupPopUp,
              setAxisData,
              dragableItemRef
            }}
          />
        </div>

      </div>
    </>
  );
}
