import React, { useState } from 'react';

import { KTSVG } from '../../_metronic/helpers';
import { Modal } from 'react-bootstrap-v5';
import { mls } from 'lib/multilanguagesupport';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import * as Yup from 'yup';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import useAutoGenerateNewCharts from 'lib/data-connectors/screens/data-source/components/useAutoGenerateNewCharts';
import { _fetchDataToPlot } from 'lib/server-connection/connections';
import { CircularProgress } from '@mui/material';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { getReportDashIDsTBD } from 'lib/reusable-components/reusableFunction/deleteDataDependencies';

const validationSchema = [
  Yup.object({
    name: Yup.string()
      .required()
      .min(5, 'Sheet Name must be greater than 5 characters')
      .max(30, 'Sheet Name must be fewer than 30 characters')
      .matches(/\w+/, 'Sheet Name must be any word character')
      .label('Sheet Name'),
    // dbType: Yup.string().required().label('Database Type'),
    tableID: Yup.string().required().label('Table Name'),
    defaultDatetimeCol: Yup.string().label('Default Datetime Column'),
  }),
];

const dateTimeTypes = [
  '__d3__CreatedAtDate',
  '__d3__CreatedAtTime',
  '__d3__UpdatedAtDate',
  '__d3__UpdatedAtTime',
  'date',
  'datePicker',
  'dateRangePicker',
  'dateTimePicker',
  'timePicker',
];

const getDateTimeCols = (tableSchema, dbType, tableName, isJoined) => {
  let columns = tableSchema?.columnSchema || [];
  if (isJoined) {
    columns = columns.map((col) => ({ ...col, tableName: tableSchema.primaryTable }));
    (tableSchema.joinTableSchema || []).map((sch) => {
      const joinCols = (sch.columnSchema || []).map((schCol) => ({
        ...schCol,
        tableName: sch.joinTableName,
      }));
      columns = [...columns, ...joinCols];
      return null;
    });
  } else {
    columns = columns.map((col) => ({ ...col, tableName: tableName }));
  }

  if (columns.length === 0) return [];
  const DTCols = columns.filter((col) => dateTimeTypes.includes(col.dataType));
  if (dbType === '__d3__supista' && isJoined !== true) {
    const createData = {
      name: 'Create Date',
      dataType: 'DATETIME',
      tableName: tableName,
      columnName: '__d3__createdAt',
    };
    const updateData = {
      name: 'Last Update Date',
      dataType: 'DATETIME',
      columnName: '__d3__updatedAt',
      tableName: tableName,
    };
    DTCols.push(createData, updateData);
  }

  const colNames = DTCols.map((obj) => obj.tableName + '.' + obj.columnName);

  return { DTCols, colNames };
};

const removeSysVariable = (str) => {
  const spl = str.split('.');
  const last = spl.pop();
  if (last === '__d3__updatedAt') return spl.join('.') + '.Last Update Date';
  else if (last === '__d3__createdAt') return spl.join('.') + '.Create Date';
  else return spl.join('.') + '.' + last;
};

function SheetSettings({ appID, sheetID, isModalOpen, setModalOpen, isNew, sheetSchema }) {
  const dispatch = useDispatch();
  const updateSchema = useUpdateSchema();
  const toastMsg = useMessageHandling();
  const autoGenerateCharts = useAutoGenerateNewCharts();
  const [isSubmitted, setSubmit] = useState(false);
  const [isDeleted, setDelete] = useState(false);
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  // const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const JTSchema = analyticsSchema.appJointable || [];
  const TSchema = analyticsSchema.appDatatable || [];
  const CTSchema = analyticsSchema.appCustomtable || [];
  const allTables = Object.keys(TSchema || []);
  const joinTables = Object.keys(JTSchema || []);
  const customTables = Object.keys(CTSchema || []);

  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap = {};
  Object.keys(analyticsSchema.appDatatable || {}).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = analyticsSchema.appDatatable[tableID].name;
  });
  Object.keys(analyticsSchema.appJointable || []).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = analyticsSchema.appJointable[tableID].name;
  });
  Object.keys(analyticsSchema.appCustomtable || []).forEach((tableID) => {
    allTableIDAndNamesMap[tableID] = analyticsSchema.appCustomtable[tableID].name;
  });

  const applyTableName = (columnName) => {
    const columnNameArray = columnName.split('.');
    const last = columnNameArray.pop();
    const tableName = columnNameArray.join('.');
    return (allTableIDAndNamesMap?.[tableName] || tableName) + '.' + last;
  };
  const [resultData, setResultData] = useState(isNew ? {} : sheetSchema);

  const [initsValue] = useState({
    name: sheetSchema?.name || '',
    description: sheetSchema?.description || '',
    tableID: sheetSchema?.defaultTable?.tableID || '',
    // dbType: sheetSchema?.defaultTable?.dbType || '',
    // datetimeCols: sheetSchema?.[sheetIndex]?.defaultTable?.datetimeCols || [],
    defaultDatetimeCol: sheetSchema?.defaultTable?.defaultDatetimeCol || '',
  });
  const [datetimeCol, setDatetimeCol] = useState(sheetSchema?.defaultTable?.datetimeCols || []);

  const location = getSchemaLocation(schemaConstants.type.SHEET);

  const submitStep = async (values, actions) => {
    setSubmit(true);
    try {
      const currentTimestamp = Date.now();
      const newResultData = {
        ...resultData,
        appID,
        sheetID,
        createdAt: resultData?.createdAt || currentTimestamp,
        updatedAt: currentTimestamp,
      };
      setResultData(newResultData);

      const res = _fetchDataToPlot(newResultData, `${appID}/SheetSettings/create`);
      if (!res.__d3__error) {
        updateSchema(location, sheetID, newResultData);
        if (isNew) {
          await autoGenerateCharts({ appID, defaultTable: resultData.defaultTable, sheetID });
        }
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: `${
                isNew
                  ? mls('Charts Auto generated by Supista AI and added to the Sheet successfully!')
                  : mls('Sheet Edited Successfully')
              }`,
              time: 5,
              isActive: true,
            },
          })
        );
        setModalOpen(null);
      }
    } catch (err) {
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
          value: {
            message: mls('There was a problem while adding Sheet, please try again later!'),
            time: 5,
            isActive: true,
          },
        })
      );
    }
  };
  const handleBlur = (key, value, setFieldValue) => {
    setFieldValue(key, value);
    setResultData({ ...resultData, [key]: value });
  };

  const onKeyPress = (event) => {
    event.key === 'Enter' && event.preventDefault();
  };

  const handleChange = (key, value, setFieldValue, values) => {
    setFieldValue(key, value);
    if (key === 'tableID') {
      const isJoinedTable = joinTables.includes(value);
      const isCustomTable = customTables.includes(value);
      let tableSchema;
      if (isJoinedTable) {
        tableSchema = JTSchema[value];
      } else if (isCustomTable) {
        tableSchema = CTSchema[value];
      } else tableSchema = TSchema[value];

      const { colNames } = getDateTimeCols(tableSchema, values.dbType, value, isJoinedTable);
      setDatetimeCol(colNames);
      const defaultTable = {
        _id: appID,
        tableID: value,
        sheetID,
        tableType: isJoinedTable ? 'JOINED_TABLE' : isCustomTable ? 'CUSTOM_TABLE' : 'TABLE',
        tables: isJoinedTable
          ? JTSchema[value].tables || []
          : isCustomTable
          ? CTSchema[value].tables || []
          : [],
        datetimeCols: colNames,
        defaultDatetimeCol: colNames?.[0] || '',
      };
      setFieldValue('defaultDatetimeCol', colNames?.[0] || '');
      setResultData({ ...resultData, defaultTable });
    } else {
      const newDefaultTable = { ...resultData.defaultTable, [key]: value };
      setResultData({ ...resultData, defaultTable: newDefaultTable });
    }
  };

  const handleDelete = async () => {
    setDelete(true);
    const allSheetSchema = { ...(analyticsSchema?.appSheets || {}) };
    const dashSchema = { ...(analyticsSchema?.appDash || {}) };
    const reportSchema = { ...(analyticsSchema?.appReports || {}) };
    // const allChartsSchema = { ...(analyticsSchema?.appCharts || {}) };
    // const chartLists = allSheetSchema[sheetID]?.charts || [];
    // for (let index = 0; index < chartLists.length; index++) {
    //   const chartID = chartLists[index];
    //   const chartPayload = { appID, chartID };
    //   const chartRes = await _fetchDataToPlot(chartPayload, `${appID}/ChartSettings/delete`);
    //   if (!chartRes.__d3__error) {
    //     delete allChartsSchema[chartID];
    //   }
    // }
    // updateSchema(null, 'appCharts', allChartsSchema);

    const chartIDs = allSheetSchema[sheetID]?.charts || [];
    const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
      chartIDs,
      dashSchema,
      reportSchema,
    });
    const payloadData = { appID, sheetID, reportIDObj: reportIDsTBD, dashIDObj: dashIDsTBD };
    const res = await _fetchDataToPlot(payloadData, `${appID}/SheetSettings/delete`);
    if (!res.__d3__error) {
      const isDeleted = delete allSheetSchema[sheetID];
      if (isDeleted) {
        publishToProduction(appID);
        // updateSchema(null, 'appSheets', allSheetSchema);
        toastMsg(
          reduxConstants.config.APP_STATUS.TOAST,
          `${mls('Sheet deleted successfully')}`,
          5,
          true
        );
      }
    }
  };
  return (
    <Modal show={!!isModalOpen} onHide={() => setModalOpen(null)} size='md' id={sheetID}>
      <div className='modal-content rounded'>
        <div className='modal-header justify-content-end border-0 pb-0'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setModalOpen(null)}
            data-bs-dismiss='modal'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body pt-0 pb-5 px-10'>
          <div className='text-center'>
            <h2 className='mb-3'>{mls('Sheet Settings')}</h2>
            <div className='text-muted fw-bold fs-5'>
              {mls(
                `You can ${
                  isNew ? 'add' : 'edit'
                } sheet to connect with a table and add charts related to that table. You can then import these charts in your Dashboard from your Dashboard Settings`
              )}
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='row mt-5'>
              <div className='flex-row-fluid py-5 px-5'>
                <Formik
                  initialValues={initsValue}
                  validationSchema={validationSchema[0]}
                  onSubmit={submitStep}
                >
                  {({ values, setFieldValue }) => (
                    <Form className='form' noValidate id='sheet_detail_form'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <label className='form-label'>
                            <span className='required'>{mls('Sheet Name')}</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Specify your Sheet name'
                            ></i>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='name'
                            placeholder=''
                            onBlur={(event) =>
                              handleBlur('name', event?.target.value, setFieldValue)
                            }
                            onKeyPress={onKeyPress}
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>

                        <div className='fv-row mb-10'>
                          <label className='form-label'>
                            <span>{mls('Sheet Description')}</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Specify your sheet description'
                            ></i>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='description'
                            placeholder=''
                            onBlur={(event) =>
                              handleBlur('description', event?.target.value, setFieldValue)
                            }
                            onKeyPress={onKeyPress}
                          />
                        </div>
                        {/* <div className='fv-row mb-10'>
                          <label className='form-label required'>{mls('Database Type')}</label>

                          <Field
                            name='dbType'
                            className='form-select form-select-solid'
                            onChange={(event) =>
                              handleChange('dbType', event.target.value, setFieldValue, values)
                            }
                            as='select'
                          >
                            <option value=''>None</option>
                            <option value='__d3__supista'>Supista</option>
                            <option value='__d3__mongo'>MongoDB</option>
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='dbType' />
                          </div>
                        </div> */}

                        <div className='fv-row mb-10'>
                          <label className='form-label required'>{mls('Table Name')}</label>

                          <Field
                            name='tableID'
                            className='form-select form-select-solid'
                            onChange={(event) =>
                              handleChange('tableID', event.target.value, setFieldValue, values)
                            }
                            as='select'
                            disabled={!isNew}
                          >
                            <option value=''>None</option>
                            <optgroup label='Tables List'>
                              {(allTables || []).map((key) => (
                                <option key={key} value={key}>
                                  {(allTableIDAndNamesMap[key] || key || '').replace(
                                    /(.{40})..+/,
                                    '$1…'
                                  )}
                                </option>
                              ))}
                            </optgroup>

                            <optgroup label='Joined Tables List'>
                              {(joinTables || []).map((key) => (
                                <option key={key} value={key}>
                                  {(allTableIDAndNamesMap[key] || key || '').replace(
                                    /(.{40})..+/,
                                    '$1…'
                                  )}
                                </option>
                              ))}
                            </optgroup>

                            {(customTables || []).length > 0 && (
                              <optgroup label='Custom Tables List'>
                                {(customTables || []).map((key) => (
                                  <option key={key} value={key}>
                                    {(allTableIDAndNamesMap[key] || key || '').replace(
                                      /(.{40})..+/,
                                      '$1…'
                                    )}
                                  </option>
                                ))}
                              </optgroup>
                            )}
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='tableID' />
                          </div>
                        </div>

                        <div className='fv-row mb-10'>
                          <label className='form-label'>{mls('Default Datetime Column')}</label>
                          <Field
                            name='defaultDatetimeCol'
                            className='form-select form-select-solid'
                            onChange={(event) =>
                              handleChange(
                                'defaultDatetimeCol',
                                event.target.value,
                                setFieldValue,
                                values
                              )
                            }
                            as='select'
                          >
                            <option value=''>None</option>

                            {(datetimeCol || []).map((ele) => (
                              <option key={ele} value={ele}>
                                {applyTableName(removeSysVariable(ele))}
                              </option>
                            ))}
                          </Field>
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='defaultDatetimeCol' />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-stack'>
                        {!isNew && (
                          <button
                            type='button'
                            onClick={handleDelete}
                            className='btn btn-sm btn-light-danger'
                          >
                            {isDeleted ? (
                              <div className='d-flex align-items-center'>
                                {mls('Please Wait')}
                                <CircularProgress
                                  size='1rem'
                                  className='ms-2'
                                  sx={{ color: '#fff' }}
                                />
                              </div>
                            ) : (
                              <span className='indicator-label'>{mls('Delete Sheet')}</span>
                            )}
                          </button>
                        )}
                        <button type='submit' className='btn btn-sm btn-primary'>
                          <span className='indicator-label'>
                            {isNew && isSubmitted ? (
                              <div className='d-flex align-items-center'>
                                {mls('Please Wait')}
                                <CircularProgress
                                  size='1rem'
                                  className='ms-2'
                                  sx={{ color: '#fff' }}
                                />
                              </div>
                            ) : isNew ? (
                              mls('Add New Sheet')
                            ) : (
                              mls('Save')
                            )}
                            {/* <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            /> */}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default SheetSettings;
