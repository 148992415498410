import { allDropableID, defaultNestedArrayDraggableID } from '../data/defaultSchema';
import { extractIndexFromDraggableID } from './helperFunction';
const {sectionId,dashboardSheetsId} =allDropableID
export const changeDestinationFunction = (props:changeDestinationProps) => {
  const { sourceData, setDestinationData, result,chartsData,setSourceData } = props;
      const {source,destination}=result
      
      const sourcetargetIndex = extractIndexFromDraggableID(source.droppableId, defaultNestedArrayDraggableID.first);
      const destinationtargetIndex = extractIndexFromDraggableID(destination.droppableId, defaultNestedArrayDraggableID.first);

      const sourcetargetSecondIndex = extractIndexFromDraggableID(source.droppableId, defaultNestedArrayDraggableID.second);
      const destinationtargetSecondIndex = extractIndexFromDraggableID(destination.droppableId, defaultNestedArrayDraggableID.second);

      
      if (sourcetargetIndex === null || destinationtargetIndex === null) {
        console.log("Index is null");
        return
      }
          
      if (sourcetargetSecondIndex !== null) {
        sameDestinationFunction({setSourceData,result})
        return
      }
      
      const targetedSource:any = sourceData[Object.keys(sourceData)[sourcetargetIndex]].charts[source.index]
      setDestinationData((currentDestinationData:any) => {
        const duplicateDestinationArray:any[] = Array.from(currentDestinationData);

        const duplicateDestinationChartArray:any[] = Array.from(currentDestinationData[destinationtargetIndex].charts);
        
        // remove Targeted
        duplicateDestinationArray.splice(destinationtargetIndex,1)
        const newItem = {
            id: targetedSource,
            width: chartsData[targetedSource]?.chartWidth || 'ONEFOURTH',
            sheetID:chartsData[targetedSource]?.sheetID || "",
        }
                
        // update Targeted ChartsArray
        duplicateDestinationChartArray.splice(destination.index, 0, newItem)
        const newWithChartArray ={
          ...currentDestinationData[destinationtargetIndex],
          charts:duplicateDestinationChartArray
        }
        
        // update Targeted Array
        duplicateDestinationArray.splice(destinationtargetIndex, 0, newWithChartArray)

        return duplicateDestinationArray;
      });
};

export const sameDestinationFunction = (props:sameDestinationProps) => {
  const { setSourceData, result } = props;

  const {source,destination}=result
  
  if (source.droppableId.match(sectionId)) {
    setSourceData((currentData:any[]) => {
      const items = Array.from(currentData);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
      return items;
    });
    return
  }
  
  if (source.droppableId.match(dashboardSheetsId)) {
    // const sourcetargetIndex = Number(source.droppableId.replace(dashboardSheetsId, ''));
    const sourcetargetIndex = extractIndexFromDraggableID(source.droppableId, defaultNestedArrayDraggableID.first);
    if (sourcetargetIndex === null) {
      console.log("sourcetargetIndex is null");
      return
    }
    setSourceData((currentData:any[]) => {
    const duplicateDestinationArray:any[] = Array.from(currentData);
    const duplicateDestinationChartArray:any[] = Array.from(currentData[sourcetargetIndex].charts);
        
    // remove Targeted
    const [removedDuplicateDestinationArray] = duplicateDestinationArray.splice(sourcetargetIndex,1)
    const [reorderedItem] = duplicateDestinationChartArray.splice(source.index, 1);

    if (reorderedItem !==undefined && reorderedItem !==null) {
      duplicateDestinationChartArray.splice(destination.index, 0, reorderedItem);
    }
    
    
    const newItem = {
      ...removedDuplicateDestinationArray,
      charts:duplicateDestinationChartArray
  }
    // update Targeted ChartsArray
    duplicateDestinationArray.splice(sourcetargetIndex, 0, newItem);
    
    return duplicateDestinationArray;
  });
  return
  }
  console.log("No Match");
  
};

interface changeDestinationProps {
  sourceData:any
  setSourceData:Function
  destinationData:any
  setDestinationData:Function
  result:any
  chartsData:any
}
interface sameDestinationProps {
  setSourceData:Function
  result:any
}