// import { fetchDataToPlot } from 'app/network/apiConnections';
import { useDispatch } from 'react-redux';
import getAppID from 'lib/server-connection/getAppID';
import reduxConstants from '../redux/reduxConstants';
import { create } from '../redux/stateSlice';
import { _fetchDataToPlot } from 'lib/server-connection/connections';

export default function useDownloadTableSchema() {
  const dispatch = useDispatch();

  return async (appID, defaultTable) => {
    if (getAppID()) {
      try {
        const res = await _fetchDataToPlot({ defaultTable }, `${appID}/tableData`);
        if (res.__d3__error !== true) {
          dispatch(
            create({
              setPath: reduxConstants.config.TABLE_SCHEMA,
              value: res.response,
            })
          );
        } else {
          dispatch(
            create({
              setPath: reduxConstants.config.TABLE_SCHEMA,
              value: [],
            })
          );
        }
        return res;
      } catch {
        dispatch(
          create({
            setPath: reduxConstants.config.TABLE_SCHEMA,
            value: [],
          })
        );
      }
    }
  };
}

export const useDownloadDashTables = () => {
  const dispatch = useDispatch();

  return async (appID, dashID) => {
    if (getAppID()) {
      const res = await _fetchDataToPlot({ appID, dashID }, `${appID}/dashTableData`);
      try {
        if (res.__d3__error !== true) {
          dispatch(
            create({
              setPath: reduxConstants.config.DASH_TABLE_SCHEMA,
              value: res.response,
            })
          );
        } else {
          dispatch(
            create({
              setPath: reduxConstants.config.DASH_TABLE_SCHEMA,
              value: [],
            })
          );
        }
      } catch {
        dispatch(
          create({
            setPath: reduxConstants.config.DASH_TABLE_SCHEMA,
            value: [],
          })
        );
      }
    }
  };
};
