import React from 'react';
import ValueNumber from '../../../ValueNumber';

interface groupScatterTypeDensityColumnProps {
  densityArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  denseStatsArray: { label: string; key: string }[];
  arrow: boolean;
  columnName1: string;
}
export const GroupScatterTypeDensityColumn = ({
  densityArray,
  finalElem,
  columnName,
  denseStatsArray,
  columnName1,
  arrow,
}: groupScatterTypeDensityColumnProps) => {
  return (
    <div className='container-fluid h-100 mb-2 p-2'>
      <div>
        {React.Children.toArray(
          densityArray.map((densityArrayElem: any) => {
            const columnData = finalElem[densityArrayElem?.key as keyof typeof finalElem] ?? {};
            return (
              <>
                <div className=''>
                  <div className='col py-4 gx-0 gy-0 mb-2 insight-table-heading text-center'>
                    <b>{densityArrayElem?.label ?? ''}</b>
                  </div>
                  <div
                    className={`container-fluid h-100 mb-2 p-2 ${
                      densityArrayElem.minMax ? 'twoEqualGrid' : 'twoEqualGrid'
                    }`}
                  >
                    <>
                      {densityArrayElem.minMax ? (
                        <>
                          {React.Children.toArray(
                            Object.keys(columnData).map((columnKey) => {
                              return (
                                <div className='col p-4 g-0 w-100 insight-table-column-header-left'>
                                  <b>{columnName?.[columnKey] ?? ''}</b>
                                </div>
                              );
                            })
                          )}
                          {React.Children.toArray(
                            Object.keys(columnData).map((columnKey) => {
                              const columnInnerData = columnData[columnKey];
                              return (
                                <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                  {React.Children.toArray(
                                    denseStatsArray.map((denseStatsData) => {
                                      return (
                                        <div>
                                          <b>{denseStatsData?.label} : </b>
                                          {
                                            <ValueNumber
                                              number={columnInnerData?.[denseStatsData?.key] ?? ''}
                                              arrow={arrow}
                                            />
                                          }
                                        </div>
                                      );
                                    })
                                  )}
                                </div>
                              );
                            })
                          )}
                        </>
                      ) : (
                        <>
                          {React.Children.toArray(
                            Object.keys(columnData).map((columnKey) => {
                              const columnInnerData = columnData[columnKey];
                              return (
                                <>
                                  <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                    <b>{columnKey}</b>
                                  </div>
                                  <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                    {<ValueNumber number={columnInnerData ?? ''} arrow={arrow} />}
                                  </div>
                                </>
                              );
                            })
                          )}
                        </>
                      )}
                    </>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
interface groupScatterTopBottomColumnProps {
  topBottomArray: { [key: string]: any };
  finalElem: { [key: string]: any };
  columnName: { [key: string]: any };
  minMaxArray: { label: string; key: string }[];
  arrow: boolean;
  columnName1: string;
}
export const GroupScatterTopBottomColumn = ({
  topBottomArray,
  finalElem,
  columnName,
  minMaxArray,
  columnName1,
  arrow,
}: groupScatterTopBottomColumnProps) => {
  return (
    <>
      <div className='container-fluid  w-100 twoColumnDivContainer'>
        {React.Children.toArray(
          topBottomArray.map((densityArrayElem: any) => {
            const columnData = finalElem[densityArrayElem?.key as keyof typeof finalElem] ?? {};
            return (
              <>
                <div className='col mt-2  gx-0 gy-0'>
                  <div className='col py-4 gx-0 gy-0 mb-2 insight-table-heading text-center'>
                    <b>{densityArrayElem?.label ?? ''}</b>
                  </div>
                  <div className='twoEqualGrid'>
                    <>
                      {React.Children.toArray(
                        columnData.map((columnDataElem: any[]) => {
                          return (
                            <>
                              <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                                <b>{columnDataElem?.[0] ?? ''}</b>
                              </div>
                              <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>
                                {<ValueNumber number={columnDataElem?.[1] ?? ''} arrow={arrow} />}
                              </div>
                            </>
                          );
                        })
                      )}
                    </>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </>
  );
};
