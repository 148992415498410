import React, { useEffect, useState } from 'react';
import './organizationStructure.scss';
import DepartmentsContainer from './components/DepartmentsContainer/DepartmentsContainer';
import { organizationStructureOptions } from './data/organizationData';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { mls } from 'lib/multilanguagesupport';

const OrganizationStructure = () => {
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const [selectedorganizationStructure, setSelectedOrganizationStructure] = useState(
    organizationStructureOptions.departments.key
  );
  const kpiAllSchema = useSelector(
    (state: { [key: string]: any }) => state.config_store.kpiAllSchema
  );
  const KpiDepartments: any[] = Object.values(kpiAllSchema?.KpiDepartments ?? {});
  const KpiCircles: any[] = Object.values(kpiAllSchema?.KpiCircles ?? {});
  const KpiEmployees: any[] = Object.values(kpiAllSchema?.KpiEmployees ?? {});
  const KpiManagers: any[] = Object.values(kpiAllSchema?.KpiManagers ?? {});
  const [departmentsData, setDepartmentsData] = useState(KpiDepartments);
  const [circlesData, setCirclesData] = useState(KpiCircles);
  const [employeesData, setEmployeesData] = useState(KpiEmployees);
  const [kpiManagerData, setKpiManagerData] = useState(KpiManagers);
  const [supistaUsers, setSupistaUsers] = useState([]);

  const organizationStructureProps = {
    departmentsData,
    setDepartmentsData,
    circlesData,
    setCirclesData,
    employeesData,
    setEmployeesData,
    kpiManagerData,
    setKpiManagerData,
    supistaUsers,
    setSupistaUsers,

    selectedorganizationStructure,
    appID,
  };

  useEffect(() => {
    (async () => {
      await _supistaApiPost(`auth/${appID}/fetchAllUsersOfApp/`, { data: {} })
        .then(async (response) => {
          setSupistaUsers(response?.response?.userData?.rows ?? []);
        })
        .catch((error) => {
          console.log(error);
          // toast.error(mls('Some issues encountered.'));
        });
    })();
  }, []);

  const optionObject = {
    edit: {
      key: 'edit',
      name: 'Edit',
    },
    remove: {
      key: 'remove',
      name: 'Remove',
    },
  };
  return (
    <div className='OrganizationStructure DefaultScroll'>
      <ToastContainer position='bottom-left' theme='colored' />
      <div className='OrganizationStructureToggleMenu'>
        {React.Children.toArray(
          Object.keys(organizationStructureOptions).map((timeFrameRangeKey) => {
            return (
              <div
                className={`timeframeRangeOption ${
                  timeFrameRangeKey === selectedorganizationStructure
                    ? 'activeTimeframeRangeOption'
                    : ''
                }`}
                onClick={() => setSelectedOrganizationStructure(timeFrameRangeKey)}
              >
                {mls(
                  organizationStructureOptions[
                    timeFrameRangeKey as keyof typeof organizationStructureOptions
                  ]?.lable
                )}
              </div>
            );
          })
        )}
      </div>
      <div className='OrganizationStructureContainer'>
        {selectedorganizationStructure === organizationStructureOptions.departments.key ? (
          <DepartmentsContainer
            selectedOrganisationField={organizationStructureOptions.departments.fields}
            selectedOrganisationText={organizationStructureOptions.departments.lable}
            organizationStructureProps={organizationStructureProps}
            selectedOrganisationOptionData={departmentsData}
            setSelectedOrganisationOptionData={setDepartmentsData}
            organizationOptionObject={{ edit: optionObject.edit }}
            // organizationOptionObject={optionObject}
          />
        ) : null}
        {selectedorganizationStructure === organizationStructureOptions.circles.key ? (
          <DepartmentsContainer
            selectedOrganisationField={organizationStructureOptions.circles.fields}
            selectedOrganisationText={organizationStructureOptions.circles.lable}
            organizationStructureProps={organizationStructureProps}
            selectedOrganisationOptionData={circlesData}
            setSelectedOrganisationOptionData={setCirclesData}
            organizationOptionObject={{ edit: optionObject.edit }}
            // organizationOptionObject={optionObject}
          />
        ) : null}
        {selectedorganizationStructure === organizationStructureOptions.employees.key ? (
          <DepartmentsContainer
            selectedOrganisationField={organizationStructureOptions.employees.fields}
            selectedOrganisationText={organizationStructureOptions.employees.lable}
            organizationStructureProps={organizationStructureProps}
            selectedOrganisationOptionData={employeesData}
            setSelectedOrganisationOptionData={setEmployeesData}
            organizationOptionObject={optionObject}
          />
        ) : null}
        {selectedorganizationStructure === organizationStructureOptions.kpiManager.key ? (
          <DepartmentsContainer
            selectedOrganisationField={organizationStructureOptions.kpiManager.fields}
            selectedOrganisationText={organizationStructureOptions.kpiManager.lable}
            organizationStructureProps={organizationStructureProps}
            selectedOrganisationOptionData={kpiManagerData}
            setSelectedOrganisationOptionData={setKpiManagerData}
            organizationOptionObject={optionObject}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OrganizationStructure;
