import { v5 as uuidv5 } from 'uuid';
import {
  defaultLegendColorPalettes,
  defaultColorfulColorPalettes,
  defaultChartSetting,
  // defaultDataType,
  defaultDateArray,
  defaultDateKey,
  allDropableID,
  selectedStyleFormater,
  chartErrors,
  defaultKpiTargetData,
  defaultOperation,
  defaultDataTypeStyles,
} from '../data/defaultChartSchema';
import {
  allChartKeys,
  allChartTypes,
  allChartTypesObj,
} from 'lib/reusable-components/reusableData/chartTypesSchema';

import { format } from 'd3';
import { finalSchemaByChart } from './finalSchemaFunction';
import { supistaApiPost } from './connections';
import { defaultNeededFormattingValue } from 'lib/reusable-components/reusableData/formatterData';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import {
  defaultDataType,
  defaultOpacity,
} from 'lib/reusable-components/reusableData/defaultChartSchema';
import { getGroupDataByChart } from 'lib/reusable-components/reusableFunction/reusableFunction';
import { mls } from 'lib/multilanguagesupport';

const uuidKey = '1b671a64-40d5-491e-99b0-da01ff1f3341';
const { ALL, NUM, CAT, DATETIME, GEO, ID, ID_TEXT, ID_NUM } = defaultDataType;
const { xAxisId, yAxisId, tableId, tooltipAxisId, groupId } = allDropableID;
const sortDataList = [DATETIME, NUM, CAT, GEO, ID, ID_TEXT, ID_NUM];

export const filterByTableName = ({ data, axisDataSupport }) => {
  if (!data) return [];
  const tableData = data;
  // Adding unique ID
  const tableSchemaWithID = tableData.map((o) => {
    return {
      ...o,
      id: uuidv5(`${o.tableName}.${o.columnName}`, uuidKey),
    };
  });
  // o.data = o.data.map(d => ({ ...d,
  //   id: uuidv4()
  // }));
  //   return o;
  // });
  // Grouped By TableName
  let tableObject = {};
  let groupedArray = tableSchemaWithID.reduce(function (r, el) {
    let e = el.tableName;
    if (!tableObject[e]) {
      tableObject[e] = {
        tableName: el.tableName,
        items: {},
        name: el?.nameOfTable ?? el.tableName,
      };
      r.push(tableObject[e]);
    }
    if (tableObject[e].items[el?.dataType]) {
      tableObject[e].items[el?.dataType].push(el);
    } else {
      tableObject[e].items[el?.dataType] = [el];
    }
    return r;
  }, []);
  let sortedArray = groupedArray.map((groupedElem) => {
    let enableArray = [];
    let disableArray = [];
    const supportedDataArray = !axisDataSupport ? [ALL] : Object.values(axisDataSupport).flat();
    const isALL = supportedDataArray.some((supportedElem) => {
      return supportedElem === ALL;
    });
    sortDataList.forEach((dataType) => {
      if (isALL) {
        if (groupedElem?.items[dataType]) {
          enableArray.push(...groupedElem?.items[dataType]);
        }
        return;
      }

      if (groupedElem?.items[dataType]) {
        const isSupported = supportedDataArray.some((supportedElem) => {
          return supportedElem === dataType;
        });
        if (isSupported) {
          enableArray.push(...groupedElem?.items[dataType]);
        } else {
          disableArray.push(...groupedElem?.items[dataType]);
        }
      }
    });

    return { ...groupedElem, items: { enableArray, disableArray } };
  });
  return sortedArray;
};
export const filterByTableNameOld = (data) => {
  if (!data) return [];
  const tableData = data;
  // Adding unique ID
  const tableSchemaWithID = tableData.map((o) => {
    return {
      ...o,
      id: uuidv5(`${o.tableName}.${o.columnName}`, uuidKey),
    };
  });
  // o.data = o.data.map(d => ({ ...d,
  //   id: uuidv4()
  // }));
  //   return o;
  // });
  // Grouped By TableName
  let tableObject = {};
  let groupedArray = tableSchemaWithID.reduce(function (r, el) {
    let e = el.tableName;
    if (!tableObject[e]) {
      tableObject[e] = {
        tableName: el.tableName,
        items: [],
        name: el?.nameOfTable ?? el.tableName,
      };
      r.push(tableObject[e]);
    }
    tableObject[e].items.push(el);
    return r;
  }, []);
  return groupedArray;
};

export const axisSchema = (axisArray) => {
  const newAxisArray = axisArray.map(
    ({ dataType, name, tableName, columnName, operations, modifiedDataType }, i) => {
      const newObj = {
        label: name,
        column: `${tableName}.${columnName}`,
        dataType: modifiedDataType || dataType,
        uniqueColumnName: `${tableName}.${columnName}-${i}`,
        operations: operations || {},
      };
      return newObj;
    }
  );
  return newAxisArray;
};

export const toolTipSchema = (toolTipArray) => {
  const defaultValue = {
    color: '#ccc',
    textStyle: ['bold', 'italic', 'underlined'],
    font: 'Times New Roman',
    fontHeight: '12',
    opacity: 100,
  };
  const newAxisArray = toolTipArray.map((elem, i) => {
    const { label, show, name, tableName, columnName, style } = elem;
    const newObj = {
      label: label || name,
      show: show,
      style: {
        color: elem?.color || defaultValue.color,
        opacity: elem?.opacity || defaultValue.opacity,
        textStyle: style?.textStyle || defaultValue.textStyle,
        font: style?.font || defaultValue.font,
        fontHeight: style?.fontHeight || defaultValue.fontHeight,
      },
      column: `${tableName}.${columnName}`,
      uniqueColumnName: `${tableName}.${columnName}-${i}`,
    };
    return newObj;
  });
  return newAxisArray;
};

export const filtersSchema = (filterArray, sheetID) => {
  const defaultValue = {
    valueCAT: [],
    valueNUMmin: 0,
    valueNUMmax: Infinity,
    valueDATE: defaultDateArray[0],
  };
  const newFilterObject = {};
  filterArray.map(({ dataType, tableName, columnName, value }, i) => {
    const newValue = () => {
      if (dataType === defaultDataType.CAT) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.NUM) {
        return {
          min: value?.min || defaultValue.valueNUMmin,
          max: value?.max || defaultValue.valueNUMmax,
        };
      }
      if (dataType === defaultDataType.DATETIME) {
        if (value?.key === defaultDateKey.CUSTOM) {
          const newValue = { ...value };
          delete newValue.dateInISO;
          return newValue;
        }
        return value ? value : defaultValue.valueDATE;
      }
      if (dataType === defaultDataType.ID) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.GEO) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      return {};
    };
    const newObj = {
      column: columnName,
      dataType: dataType,
      tableName: tableName,
      sheetID: sheetID,
      value: newValue(),
    };

    // Add object with Keynames
    const keyName = `${tableName}.${columnName}`;
    newFilterObject[keyName] = newObj;
    return newObj;
  });

  return newFilterObject;
};

export const getUniueValuesFunction = (props) => {
  const { uniqueValues, selectedItem, defaultShow = true, axis } = props;
  if (!uniqueValues) {
    return [];
  }
  const newData = uniqueValues.map((uniqueValues) => {
    const newUniqueValues = (elem) => {
      switch (elem) {
        case null:
          return 'null';
        case undefined:
          return 'undefined';

        case true:
          return 'true';

        case false:
          return 'false';

        default:
          return uniqueValues;
      }
    };
    const newElem = {
      label: newUniqueValues(uniqueValues),
      originalLabel: newUniqueValues(uniqueValues),
      origin: selectedItem.label,
      show: defaultShow,
      column: selectedItem.column,
      uniqueColumnName: selectedItem.uniqueColumnName,
      axis,
    };
    return newElem;
  });
  return newData;
};

export const getUniueValuesFunctionByColumns = (data) => {
  //Extract uniqueValues
  const uniqueValues = data.map((elem) => {
    return {
      uniqueValues: elem.name,
      origin: elem.tableName,
      id: elem.id,
    };
  });

  return uniqueValues;
};

export const setAutomaticColor = (props) => {
  const { target, chartToolTip, targetStyle, defaultOpacity = 70, isColorfulColor = false } = props;
  const findLengthNumber = (chartStyles, chartLength) => {
    if (chartStyles[chartLength]) {
      const indexOfColor = defaultLegendColorPalettes.findIndex((color) => {
        return color === chartStyles[chartLength].color;
      });
      if (indexOfColor === -1) {
        return 0;
      }
      return indexOfColor;
    } else {
      return 0;
    }
  };
  const chartStyles = targetStyle && chartToolTip?.style;
  const chartLength = chartStyles?.length - 1;

  let lengthNumber = findLengthNumber(chartStyles, chartLength);
  const legendColorPalettesLength = isColorfulColor
    ? defaultColorfulColorPalettes.length
    : defaultLegendColorPalettes.length;
  const newTargetWithColors = target.map((elem) => {
    if (lengthNumber >= legendColorPalettesLength - 1) {
      lengthNumber = 0;
    } else {
      lengthNumber++;
    }
    const newElem = {
      ...elem,
      color: isColorfulColor
        ? defaultColorfulColorPalettes[lengthNumber]
        : defaultLegendColorPalettes[lengthNumber],
      opacity: defaultOpacity,
    };
    return newElem;
  });

  return newTargetWithColors;
};

export const resetLegendColor = (props) => {
  const { chartToolTip, allRest, defaultOpacity, isColorfulColor = false } = props;
  if (allRest) {
    if (chartToolTip.style.length === 0) {
      return defaultChartSetting.tooltip;
    } else {
      const legendColorPalettesLength = defaultLegendColorPalettes.length;
      const valueColorPalettesLength = isColorfulColor
        ? defaultColorfulColorPalettes.length
        : defaultLegendColorPalettes.length;
      let lengthNumber = 0;
      const newChartToolTip = chartToolTip.style.map((elem) => {
        if (lengthNumber >= legendColorPalettesLength - 1) {
          lengthNumber = 0;
        } else {
          lengthNumber++;
        }

        const newElem = {
          ...elem,
          color: defaultLegendColorPalettes[lengthNumber],
          opacity: defaultOpacity,
        };

        return newElem;
      });
      const newvalueStyle = chartToolTip.valueStyle.map((elem) => {
        if (lengthNumber >= valueColorPalettesLength - 1) {
          lengthNumber = 0;
        } else {
          lengthNumber++;
        }

        const newElem = {
          ...elem,
          color: isColorfulColor
            ? defaultColorfulColorPalettes[lengthNumber]
            : defaultLegendColorPalettes[lengthNumber],
          opacity: defaultOpacity,
        };

        return newElem;
      });
      return {
        ...chartToolTip,
        ...defaultChartSetting.tooltip,
        style: newChartToolTip,
        valueStyle: newvalueStyle,
      };
    }
  }
  if (chartToolTip.style.length === 0) {
    return chartToolTip;
  } else {
    const legendColorPalettesLength = defaultLegendColorPalettes.length;
    const valueColorPalettesLength = isColorfulColor
      ? defaultColorfulColorPalettes.length
      : defaultLegendColorPalettes.length;
    let lengthNumber = 0;
    const newChartToolTip = chartToolTip.style.map((elem) => {
      if (lengthNumber >= legendColorPalettesLength - 1) {
        lengthNumber = 0;
      } else {
        lengthNumber++;
      }

      const newElem = {
        ...elem,
        color: defaultLegendColorPalettes[lengthNumber],
        opacity: defaultOpacity,
      };

      return newElem;
    });
    const newvalueStyle = chartToolTip.valueStyle.map((elem) => {
      if (lengthNumber >= valueColorPalettesLength - 1) {
        lengthNumber = 0;
      } else {
        lengthNumber++;
      }

      const newElem = {
        ...elem,
        color: isColorfulColor
          ? defaultColorfulColorPalettes[lengthNumber]
          : defaultLegendColorPalettes[lengthNumber],
        opacity: defaultOpacity,
      };

      return newElem;
    });
    return { ...chartToolTip, style: newChartToolTip, valueStyle: newvalueStyle };
  }
};

export const updateAxisStyleLable = (setStyle, arrayOfAxis, defaultLable = '') => {
  setStyle((preElem) => {
    if (!preElem.isLabelEdited) {
      if (arrayOfAxis[1]) {
        const newName = arrayOfAxis.reduce((accum, elem, i) => {
          if (i <= 1) {
            return `${accum.label},${elem.label}`;
          }
          return `${accum},${elem.label}`;
        });
        return { ...preElem, label: newName };
      }
      return { ...preElem, label: arrayOfAxis[0]?.label || defaultLable };
    }
    return preElem;
  });
};

export const updateChartType = (props) => {
  const {
    axisData,
    destinationID,
    sourceID,
    chartType,
    setchartType,
    toast,
    isAdd = true,
    secondAxisData = false,
    currentItem,
    stateProps,
  } = props;
  const { setChartWidth } = stateProps;
  let timer;
  const debounce = (func, timeout = 300) => {
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const triggerCondition = ({ isAdd, axisData, target, secondAxis }) => {
    if (target?.triggerColoumnValue === null) {
      return false;
    }
    if (isAdd) {
      if (secondAxis) {
        if (axisData?.length >= target?.triggerColoumnValue) {
          return true;
        }
      } else {
        if (axisData?.length >= target?.triggerColoumnValue - 1) {
          return true;
        }
      }
    } else {
      if (axisData?.length <= target?.triggerColoumnValue) {
        return true;
      }
    }
    return false;
  };

  const checkError = ({ isAdd, axisData, toast, target, secondAxis }) => {
    if (triggerCondition({ isAdd, axisData, target, secondAxis })) {
      const { isError, showMsg, changeTo, msg } = target;
      const normalMsg = mls(msg) || `${mls('Changed to')} <b>${mls(changeTo)}</b>`;
      const errorMsg = mls(msg) || mls(`More Column is not placeable`);
      const stringToBold = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />;
      if (showMsg) {
        const processChange = debounce(() => {
          toast?.[isError ? 'error' : 'warn'](stringToBold(isError ? errorMsg : normalMsg), {
            position: 'bottom-left',
            // autoClose: 5000,
            // hideProgressBar: false,
            // closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: undefined,
            theme: 'light',
          });
        });
        processChange();
        // saveInput();
      }
      if (isError) {
        return { change: false, skip: true };
      }
      return { change: true, skip: false };
    }
    return { change: false, skip: false };
  };
  const chartInitialKey = chartType?.chartKey;
  const newRealUpdateFunction = ({ chartType, secondAxisData, mainAxisId, secondAxisId }) => {
    let currentChart = chartType;
    let secondChart = null;
    // Axis to another axis
    if (secondAxisData) {
      const secondTarget = chartType?.multipleAxis?.[secondAxisId]?.[!isAdd ? 'add' : 'remove'];

      if (secondTarget) {
        const errorResolve = checkError({
          isAdd: !isAdd,
          axisData: secondAxisData,
          secondAxis: secondAxisData,
          toast,
          target: secondTarget,
        });
        if (errorResolve.skip) {
          return { skip: true };
        }
        if (errorResolve.change) {
          const selectedChart = allChartTypes.find((x) => x.chartKey === secondTarget?.changeTo);
          if (selectedChart) {
            currentChart = selectedChart;
            secondChart = { ...selectedChart };
          }
        }
      }
    }
    if (axisData) {
      const mainTarget = currentChart?.multipleAxis?.[mainAxisId]?.[isAdd ? 'add' : 'remove'];
      if (mainTarget) {
        const errorResolve = checkError({
          isAdd: isAdd,
          axisData: axisData,
          secondAxis: secondAxisData,
          toast,
          target: mainTarget,
        });
        if (errorResolve.skip) {
          return { skip: true };
        }
        if (errorResolve.change) {
          const selectedChart = allChartTypes.find((x) => x.chartKey === mainTarget?.changeTo);
          if (selectedChart) {
            currentChart = selectedChart;
          } else {
            currentChart = secondChart;
          }
        }
      }
    }
    if (chartInitialKey !== currentChart?.chartKey) {
      if (currentChart) {
        setchartType(currentChart);
        setChartWidth(currentChart.defaultChartWidth);
      }
      return { skip: false, currentChart, isChartUpdated: true };
    }
    return { skip: false, currentChart, isChartUpdated: false };
  };
  let resolve = { skip: false };
  switch (destinationID) {
    case allDropableID.xAxisId:
      resolve = newRealUpdateFunction({
        chartType,
        mainAxisId: axisIDToKey(destinationID),
        secondAxisId: axisIDToKey(sourceID) ?? 'yAxis',
        secondAxisData,
      });
      break;
    case allDropableID.yAxisId:
      resolve = newRealUpdateFunction({
        chartType,
        mainAxisId: axisIDToKey(destinationID),
        secondAxisId: axisIDToKey(sourceID) ?? 'xAxis',
        secondAxisData,
      });
      break;
    case allDropableID.groupId:
      resolve = newRealUpdateFunction({
        chartType,
        mainAxisId: axisIDToKey(destinationID),
        secondAxisId: axisIDToKey(sourceID) ?? 'xAxis',
        secondAxisData,
      });
      break;
    case allDropableID.tooltipAxisId:
      resolve = newRealUpdateFunction({
        chartType,
        mainAxisId: axisIDToKey(destinationID),
        secondAxisId: axisIDToKey(sourceID) ?? 'xAxis',
        secondAxisData,
      });
      break;
    default:
      break;
  }
  if (resolve?.skip === false && isAdd) {
    const checkDropability = checkForDropabilityByData({
      chartKey: resolve?.currentChart?.chartKey,
      dataType: currentItem?.originalDataType,
      axisId: destinationID,
    });
    if (!checkDropability.isDropable) {
      let htmlText = stringToHtml(checkDropability?.errorText);
      toast.error(htmlText, { position: 'bottom-left', pauseOnHover: true, theme: 'light' });
      resolve.skip = true;

      if (resolve?.currentChart?.chartKey !== chartInitialKey) {
        setchartType(chartType);
      }
    }
  }
  return resolve;
};

export const removeToolTip = (target, removedItem) => {
  const filteredToolTip = target.style.filter((item) => item.uniqueColumnName !== removedItem);
  const finalToolTip = { ...target, style: filteredToolTip };
  return finalToolTip;
};

//Remove removeValueToolTip
export const removeValueToolTip = (target, removedItem) => {
  const filteredToolTip = target.valueStyle.filter((item) => item.uniqueColumnName !== removedItem);
  const finalToolTip = { ...target, valueStyle: filteredToolTip };
  return finalToolTip;
};
export const tableFilterFuntion = (props) => {
  const { search, type, allTableData, setFilterdTableSchema } = props;
  setFilterdTableSchema(() => {
    const newArray = allTableData.filter((el) => {
      const lowerCaseElemName = el.name.toLowerCase();
      const lowerCaseElemType = el.dataType;
      const lowerCaseValue = search.toLowerCase();
      if (type === defaultDataType.ALL) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      if (lowerCaseElemType === type) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      return false;
    });

    return newArray;
  });
};
export const resetChartFuntion = (props) => {
  const { stateProps, chart } = props;
  const {
    setXAxisData,
    setYAxisData,
    setToolTipAxisData,
    setChartToolTip,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setChartFilter,
    setChartWidth,
    setIsInsight,
    setIsForecast,
    setIsCrossReport,
    setIskeepAllFilters,
    setIsZoomBrush,
    setIsDateHierarchy,
    setPlotAreaBackground,
    setGridLines,

    //CARD
    setChartHeaderImage,
    setChartCardType,
    //KPIMETER
    setKpiTargetPoints,
    //GEO
    setGeoGroup,
    //BARSTACKLINE
    setBarStackLineTargetPoints,
    //PLOT
    setPlotGroup,
    setPlotGroupStyle,
    //PIVOTTABLE
    setPivotTableTitleStyle, //PIVOTTABLE

    //HEATMAP
    setHeatMapGroup, //HEATMAP
    setHeatMapColor, //HEATMAP
    //TREEMAP
    setTreeMapColor, //TREEMAP
    // COLOR
    setColorAxis, //COLOR
    // DOUBLEAXIS
    setDoubleAxis, //DOUBLEAXIS
  } = stateProps;
  setXAxisData(defaultChartSetting.xAxis);
  setYAxisData(defaultChartSetting.yAxis);
  setToolTipAxisData(defaultChartSetting.toolTipAxis);
  setChartToolTip(defaultChartSetting.tooltip);
  // restStyles
  setDefaultStyle({ chart, setChartXAxisStyle, setChartYAxisStyle });
  setChartFilter(defaultChartSetting.filters);
  setChartWidth(chart.defaultChartWidth);

  setIsInsight(defaultChartSetting.isInsight);
  setIsForecast(defaultChartSetting.isForecast);
  setIsCrossReport(defaultChartSetting.isCrossReport);
  setIskeepAllFilters(defaultChartSetting.iskeepAllFilters);
  setIsZoomBrush(defaultChartSetting.isZoomBrush);
  setIsDateHierarchy(defaultChartSetting.isDateHierarchy);
  setPlotAreaBackground(defaultChartSetting.plotAreaBackgroundSolid);
  setGridLines(defaultChartSetting.gridLines);
  //CARD
  setChartHeaderImage(defaultChartSetting.chartHeaderImage); //CARD
  setChartCardType(defaultChartSetting.chartCardType); //CARD
  //KPIMETER
  setKpiTargetPoints(defaultChartSetting.targetPoints); //KPIMETER
  //GEO
  setGeoGroup(defaultChartSetting.geoGroup); //GEO
  //BARSTACKLINE
  setBarStackLineTargetPoints(defaultChartSetting.barStackLineTargetPoints); //PLOT
  //PLOT
  setPlotGroup(defaultChartSetting.plotGroup); //PLOT
  setPlotGroupStyle(defaultChartSetting.plotGroupStyle); //PLOT
  //PIVOTTABLE
  setPivotTableTitleStyle(defaultChartSetting.pivotTableTitleStyle); //PIVOTTABLE
  //HEATMAP
  setHeatMapGroup(defaultChartSetting.heatMapGroup); //HEATMAP
  setHeatMapColor(defaultChartSetting.heatMapColor); //HEATMAP
  //TREEMAP
  setTreeMapColor(defaultChartSetting.treeMapColor); //TREEMAP
  //COLOR
  setColorAxis(defaultChartSetting.colorAxis); //COLOR
  //DOUBLEAXIS
  setDoubleAxis(defaultChartSetting.doubleAxis); //DOUBLEAXIS
};
export const setDefaultStyle = ({ chart, setChartXAxisStyle, setChartYAxisStyle }) => {
  const addFormatter = ({ axis, data }) => {
    if (axis === defaultDataType.NUM) {
      const updatedData = {
        ...data,
        formatter: defaultNeededFormattingValue.num,
      };
      return updatedData;
    }
    return data;
  };
  setChartXAxisStyle(
    addFormatter({
      axis: chart?.axis?.axisType?.xAxis,
      data: defaultChartSetting.xAxisStyle,
    })
  );
  setChartYAxisStyle(
    addFormatter({
      axis: chart?.axis?.axisType?.yAxis,
      data: defaultChartSetting.yAxisStyle,
    })
  );
};
export const numberTickFormat = (e) => {
  if (e >= 1000000) {
    return e < 1 ? e : `${format('~s')(e).replace(/G/, 'B')}n`;
  }
  return e < 1 ? e : `${format('~s')(e).replace(/G/, 'B')}`;
};
export const getTimeFrame = ({ action, tableSchema, defaultDateTimeCol }) => {
  if (defaultDateTimeCol) {
    const selectedTable = tableSchema?.find((elem) => {
      return `${elem.tableName}.${elem.columnName}` === defaultDateTimeCol;
    });
    const timeFrameObject = {
      [defaultDateTimeCol]: {
        column: selectedTable?.columnName,
        dataType: selectedTable?.dataType,
        tableName: selectedTable?.tableName,
        name: selectedTable?.name,
        sheetID: selectedTable?.sheetID,
        value: {
          period: 'LASTXDAYS',
          xDay: Number(action),
        },
      },
    };
    const finalTimeFrame = action === 'null' ? {} : timeFrameObject;
    return finalTimeFrame;
  }
  return {};
};

export const getAutomaticKpiTargetData = async (props) => {
  const { column, chartSchema, chartType, kpiTargetPoints, endPoint = 'createCharts' } = props;
  const uniqueColumnName = column?.uniqueColumnName;
  const dataByChart = finalSchemaByChart({
    chartSchemaMemo: chartSchema,
    chartType: chartType,
  });
  const res = await supistaApiPost(endPoint, dataByChart?.data, chartSchema.appID);
  if (!res.__d3__error) {
    const chartID = Object.keys(dataByChart?.data?.charts)[0];
    const targets = res?.response?.charts[chartID]?.options?.[uniqueColumnName]?.targets ?? [];
    let lengthNumber = 0;
    const customSort = (arr = []) => {
      return arr.sort((a, b) => a - b);
    };
    const finalTargets = customSort(targets).map((targetElem) => {
      if (lengthNumber >= defaultColorfulColorPalettes.length - 1) {
        lengthNumber = 0;
      } else {
        lengthNumber++;
      }
      let newItem = {
        targetPoint: targetElem,
        color: defaultColorfulColorPalettes[lengthNumber],
        opacity: defaultOpacity,
        label: numberFormatter({
          type: kpiTargetPoints?.formatter,
          value: targetElem,
          short: true,
        }),
      };
      return newItem;
    });
    return { skip: false, finalTargets };
  }
  return { skip: true };
};
const setDefaultDateTimeOpration = (array) => {
  const arrayWithdateTimeOperation = array.map((elem) => {
    if (elem.originalDataType === defaultDataType.DATETIME) {
      return {
        ...elem,
        operations: { type: defaultOperation.dateTime.month.key },
        dataType: defaultDataType.DATETIME,
      };
    }
    return elem;
  });
  return arrayWithdateTimeOperation;
};
const setDoubleAxisLabel = ({
  setDoubleAxis,
  yAxisData,
  reset = true,
  isDoubleAxis = defaultChartSetting.doubleAxis.isDoubleAxis,
}) => {
  setDoubleAxis((currentData) => {
    const doubleAxisDataReseted = {
      isDoubleAxis: isDoubleAxis,
      y0AxisStyle: {
        ...defaultChartSetting.doubleAxis.y0AxisStyle,
        label: yAxisData[0]?.label ?? defaultChartSetting.doubleAxis.y0AxisStyle.label,
      },
      y1AxisStyle: {
        ...defaultChartSetting.doubleAxis.y1AxisStyle,
        label: yAxisData[1]?.label ?? defaultChartSetting.doubleAxis.y1AxisStyle.label,
      },
    };
    const doubleAxisData = {
      isDoubleAxis: isDoubleAxis,
      y0AxisStyle: {
        ...currentData.y0AxisStyle,
        label: yAxisData[0]?.label ?? defaultChartSetting.doubleAxis.y0AxisStyle.label,
      },
      y1AxisStyle: {
        ...currentData.y1AxisStyle,
        label: yAxisData[1]?.label ?? defaultChartSetting.doubleAxis.y1AxisStyle.label,
      },
    };
    if (reset) {
      return { ...defaultChartSetting.doubleAxis, ...doubleAxisDataReseted };
    }
    return { ...currentData, ...doubleAxisData };
  });
};
export { setDefaultDateTimeOpration, getGroupDataByChart, setDoubleAxisLabel };
const axisIDToKey = (axisId) => {
  const axisKeys = {
    xAxisId: {
      axis: xAxisId,
      key: 'xAxis',
    },
    yAxisId: {
      axis: yAxisId,
      key: 'yAxis',
    },
    groupId: {
      axis: groupId,
      key: 'group',
    },
    tooltipAxisId: {
      axis: tooltipAxisId,
      key: 'tooltipAxis',
    },
  };
  return axisKeys[axisId]?.key;
};
const chartAxis = ({ axisId, chart }) => {
  return {
    axisType: chart?.axis?.axisType?.[axisIDToKey(axisId)],
    axisDataSupport: chart?.axis?.axisDataSupport?.[axisIDToKey(axisId)],
  };
};
export const checkForDropabilityByData = ({
  chartKey,
  isDirectAxisArray = false,
  axisId,
  dataType,
}) => {
  const selectedChart = allChartTypesObj[chartKey];
  const axisDropData = chartAxis({ axisId: axisId, chart: selectedChart });
  const isDropable = axisDropData.axisDataSupport.some((elem) => {
    if (elem === defaultDataType.ALL) {
      return true;
    }
    return elem === dataType;
  });

  const getNotSupportErrorMsg = (supportedDataType) => {
    const errorDataWrapper = ({ dataKey }) => {
      return `${defaultDataTypeStyles[dataKey]?.name}`;
    };
    if (supportedDataType?.length <= 0) {
      return '';
    }
    if (supportedDataType?.length === 1) {
      return `Only <b>${errorDataWrapper({ dataKey: supportedDataType[0] })} column</b> acceptable`;
    }
    const error = supportedDataType.reduce((accum, dataKey, index) => {
      const perviousValue = index === 1 ? errorDataWrapper({ dataKey: accum }) : accum;
      if (index === supportedDataType?.length - 1) {
        return `${perviousValue} and ${errorDataWrapper({ dataKey })}`;
      }
      if (index === 1) {
        return `${perviousValue}, ${errorDataWrapper({ dataKey })}`;
      }
      return `${accum}, ${errorDataWrapper({ dataKey })}`;
    });
    return `Only <b>${error} columns</b> are acceptable`;
  };
  if (axisDropData?.axisDataSupport?.length <= 2) {
    const errorMsg = getNotSupportErrorMsg(axisDropData?.axisDataSupport ?? []);
    return { isDropable, errorText: errorMsg };
  }
  return { isDropable, errorText: chartErrors[dataType]?.notAcceptable };
};
export const stringToHtml = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />;
