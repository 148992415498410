import DropDown from '../../../features/DropDown/DropDown';
import React, { useState } from 'react';
import { defaultCardCompare, CardAxesKey, defaultDataType } from '../../../data/defaultChartSchema';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import { toast } from 'react-toastify';
import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { mls } from 'lib/multilanguagesupport';

const CardAdvanceOptions = ({ stateProps }) => {
  const { yAxisData, setYAxisData, allTableData } = stateProps;
  return (
    <div className='grayContainers'>
      {React.Children.toArray(
        yAxisData.map((elm, i) => {
          return (
            <>
              <CardOptionsDiv
                elm={elm}
                index={i}
                targetSetState={setYAxisData}
                allTableData={allTableData}
              />
            </>
          );
        })
      )}
    </div>
  );
};

export default CardAdvanceOptions;

const CardOptionsDiv = (props) => {
  const { elm, index, targetSetState, allTableData } = props;

  const [dateTimeColumnArray, setdateTimeColumnArray] = useState(() => {
    return allTableData?.filter((tableElem) => {
      if (tableElem.dataType === defaultDataType.DATETIME) {
        return true;
      }
    });
  });
  const [selectedDateTime, setSelectedDateTime] = useState(() => {
    if (elm.isCompare) {
      const tableName = elm?.uniqueColumnName?.split('.');
      const checkFilterItem = allTableData.filter((tableElem) => {
        if (tableElem.tableName === tableName[0]) {
          if (tableElem.dataType === defaultDataType.DATETIME) {
            const newSelectedDateTime = `${tableElem.tableName}.${tableElem.columnName}`;
            if (newSelectedDateTime === elm.defaultDatetimeCol) {
              return true;
            }
          }
        }
        if (tableElem.dataType === defaultDataType.DATETIME) {
          return true;
        }
      });
      if (checkFilterItem[0]) {
        return checkFilterItem[0];
      }
      // return elm.defaultDatetimeCol;
    }
    return {};
  });
  const handleChange = (e, target) => {
    targetSetState((current) => {
      const sourceitems = Array.from(current);
      const [selectedItem] = sourceitems.splice(index, 1);
      let newSelectedItem = {};

      switch (target) {
        case CardAxesKey.dataPrefix:
          newSelectedItem = { ...selectedItem, dataPrefix: e.target.value };
          break;
        case CardAxesKey.textSuffix:
          newSelectedItem = { ...selectedItem, textSuffix: e.target.value };
          break;
        case CardAxesKey.dataSuffix:
          newSelectedItem = { ...selectedItem, dataSuffix: e.target.value };
          break;
        case CardAxesKey.defaultTimeframe:
          newSelectedItem = { ...selectedItem, defaultTimeframe: e };
          break;
        case CardAxesKey.displayType:
          newSelectedItem = { ...selectedItem, displayType: e };
          break;
        case CardAxesKey.defaultCompare:
          newSelectedItem = { ...selectedItem, defaultCompare: e };
          break;
        case CardAxesKey.formatter:
          newSelectedItem = { ...selectedItem, formatter: e };
          break;
        case CardAxesKey.isCompare:
          newSelectedItem = { ...selectedItem, isCompare: e };
          if (!e) {
            delete newSelectedItem.defaultCompare;
            delete newSelectedItem.defaultDatetimeCol;
          }
          break;
        case CardAxesKey.defaultDatetimeCol:
          newSelectedItem = { ...selectedItem, defaultDatetimeCol: e };
          break;

        default:
          return current;
      }
      sourceitems.splice(index, 0, newSelectedItem);
      return sourceitems;
    });
  };
  const handleDefaultCompare = (e) => {
    handleChange(e, CardAxesKey.defaultCompare);
  };

  const handleComparePeriodToggle = (action) => {
    if (action) {
      const checkFilterItem = allTableData.find((tableElem) => {
        if (tableElem.dataType === defaultDataType.DATETIME) {
          return true;
        }
      });
      if (!checkFilterItem) {
        toast.error(mls('No DateTime Column Present'), {
          position: 'bottom-left',
          // autoClose: 5000,
          // hideProgressBar: false,
          // closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: 'light',
        });
        return;
      }
      //handleSchema
      handleDefaultCompare(defaultCardCompare[0]);
      const newSelectedDateTime = `${checkFilterItem?.tableName}.${checkFilterItem?.columnName}`;
      handleChange(newSelectedDateTime, CardAxesKey.defaultDatetimeCol);
      // setdateTimeColumnArray(checkFilterItem);
      setSelectedDateTime(checkFilterItem);
    }
    handleChange(action, CardAxesKey.isCompare);
  };
  const handleSelectedDateTime = (action) => {
    setSelectedDateTime(action);
  };
  const handleFormatter = (e) => {
    handleChange(e, CardAxesKey.formatter);
  };
  return (
    <ClosableWhiteDiv
      title={elm.label}
      span={elm?.operations?.type}
      show={false}
      autoCloseOff={true}
    >
      <div>
        <div className='titleDiv'>
          {mls('Compare')}
          <SlideSwitch isChecked={elm.isCompare} setIsChecked={handleComparePeriodToggle} />
        </div>
        {elm.isCompare ? (
          <>
            <DropDown
              array={defaultCardCompare}
              dropDownValue={elm.defaultCompare}
              setdropDownValue={handleDefaultCompare}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {elm.isCompare ? (
        <div>
          <div className='titleDiv'>{mls('Default Date Time Column')}</div>
          <DropDown
            array={dateTimeColumnArray}
            dropDownValue={selectedDateTime}
            setdropDownValue={handleSelectedDateTime}
          />
        </div>
      ) : null}

      <div>
        <div className='titleDiv'>{mls('Data Prefix')}</div>
        <input
          className='primaryInput'
          type='text'
          value={elm.dataPrefix}
          onChange={(e) => handleChange(e, CardAxesKey.dataPrefix)}
        />
      </div>
      <div>
        <div className='titleDiv'>{mls('Data Suffix')}</div>
        <input
          className='primaryInput'
          type='text'
          value={elm.dataSuffix}
          onChange={(e) => handleChange(e, CardAxesKey.dataSuffix)}
        />
      </div>
      <div>
        <div className='titleDiv'>{mls('Text Suffix')}</div>

        <input
          className='primaryInput'
          type='text'
          value={elm.textSuffix}
          onChange={(e) => handleChange(e, CardAxesKey.textSuffix)}
        />
      </div>

      <NumberFormatterComp formatter={elm?.formatter} setFormatter={handleFormatter} />
    </ClosableWhiteDiv>
  );
};
