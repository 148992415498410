import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Chart from 'lib/visx-lib';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
import { getStyling } from 'lib/reusable-components/reusableUtils/commons/stylinghelper';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { useHistory, useParams } from 'react-router-dom';
// import { useQuery } from 'react-query';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
// import DashFilter from 'app/filter/DashFilter';
import { useUpdateDashFilter } from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateFilter';
import DashFilterStrip from 'app/filter/DashFilterStrip';
import { _fetchDataToPlot } from 'lib/server-connection/connections';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import DashSettings from './DashSettings';
import useAppState from 'lib/reusable-components/reusableUtils/commons/useAppState';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh', // Make the container fill the entire viewport height
  },
};

const fetchData = async (payload, endpoint) => {
  const appID = payload.appID;
  const res = await _fetchDataToPlot(payload, `${appID}/${endpoint}`);
  if (res.__d3__error !== true) return { response: res.response, error: false };
  else return { error: true };
};

const applyTimeframDashFilter = (dashTableSchema) => {
  const timeframeFilters = {};
  const sheetIDKeys = Object.keys(dashTableSchema || {});
  if (sheetIDKeys.length === 0) return null;
  sheetIDKeys.forEach((sheetID) => {
    const tableSchema = dashTableSchema[sheetID]?.data || [];
    const defaultDatetimeCol = dashTableSchema[sheetID]?.defaultTable?.defaultDatetimeCol || '';
    const defaultDateTimeObj = tableSchema.filter(
      (obj) => obj.tableName + '.' + obj.columnName === defaultDatetimeCol
    );
    const filterKey = sheetID + '.' + defaultDatetimeCol;
    if (defaultDateTimeObj?.[0] && defaultDateTimeObj?.[0].dataType === 'DATETIME') {
      timeframeFilters[filterKey] = {
        column: defaultDateTimeObj[0].columnName,
        dataType: defaultDateTimeObj[0].dataType,
        sheetID: defaultDateTimeObj[0].sheetID,
        tableName: defaultDateTimeObj[0].tableName,
        value: {
          period: 'LASTXDAYS',
          xDay: 180,
        },
      };
    }
  });
  return timeframeFilters;
};

function Dashboard() {
  const dashSettingsState = useAppState('dashSettings');

  const { appID, dashID } = useParams();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];
  const isAccess =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');

  const history = useHistory();
  const dispatch = useDispatch();
  const updateReduxFilter = useUpdateDashFilter();
  const dashsSchema = analyticsSchema?.appDash || {};
  const chartsSchema = analyticsSchema?.appCharts || {};
  const dashboard = dashsSchema[dashID] || {};
  const dashName = dashboard.name || {};
  const chartsContainerRef = useRef(null);
  const { width: mainContainerWidth } = useDivDimensions(chartsContainerRef);
  const isNoChart = (dashboard.sections || [])
    .map((sec) => (sec.charts || []).length === 0)
    .every((val) => val === true);

  const [chartUpdates, setChartUpdates] = useState({});
  const [isUpdateLoading, setUpdatedLoader] = useState(false);
  const [plotData, setPlotData] = useState(null);
  const [error, setError] = useState(false);
  const [dashTableData, setDashTableData] = useState({});
  const [filterSchema, setFilterSchema] = useState({});
  const [isdashTableDataFetched, setDashTableDataFetch] = useState(false);
  const timeframe = useRef(180);
  const [timeFrameObj, setTimeFrameObj] = useState({});
  const defaultTimeframes = useRef({});
  const payloadDataRef = useRef({});
  useEffect(() => {
    const dashSch = analyticsSchema?.appDash || {};
    if (dashSch[dashID]) {
      fetchData({ appID, dashID }, 'dashTableData')
        .then((res) => {
          if (res.error || Object.keys(res.response || {}).length === 0) {
            setError(true);
            setDashTableData({});
          } else {
            setDashTableData(res?.response || {});
            setError(false);
          }
        })
        .catch(() => {
          setDashTableData({});
          setError(true);
        });
    }
  }, [analyticsSchema, appID, dashID]);

  useEffect(() => {
    const payloadData = { appID, dashID, filters: filterSchema, timeFrame: timeFrameObj };
    payloadDataRef.current = { ...payloadData, chartUpdates };
    if (isdashTableDataFetched) {
      setPlotData({});
      fetchData(payloadData, 'dash')
        .then((res) => {
          if (res.error) {
            setError(true);
            setPlotData({});
          } else {
            setPlotData(res?.response?.charts || {});
            setError(false);
          }
        })
        .catch(() => {
          setPlotData({});
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appID, dashID, filterSchema, isdashTableDataFetched, timeFrameObj]);

  const dashTableSchArr = useMemo(() => {
    let list = [];
    Object.keys(dashTableData).forEach((sheetID) => {
      const newList = dashTableData[sheetID].data;
      list = [...list, ...newList];
    });
    return list;
  }, [dashTableData]);

  const updateFilter = (newFilters) => {
    const newFilterSchema = { ...filterSchema, ...newFilters };
    setFilterSchema(newFilterSchema);
    updateReduxFilter(newFilterSchema);
  };

  useEffect(() => {
    const filterData = applyTimeframDashFilter(dashTableData);
    setTimeFrameObj(filterData);
    setDashTableDataFetch(filterData !== null);
    defaultTimeframes.current = filterData;
  }, [dashTableData, dashID]);

  if (dashSettingsState.dashID) {
    return (
      <>
        {isAccess && dashSettingsState.dashID && (
          <DashSettings
            appID={appID}
            dashID={dashSettingsState.dashID}
            isNew={dashboard == null}
            dashSchema={dashboard || {}}
          />
        )}
      </>
    );
  }

  if (!dashsSchema[dashID]) {
    history.push('/');
    return <div ref={chartsContainerRef}></div>;
  }

  const chartHeight =
    mainContainerWidth <= 576
      ? mainContainerWidth // sm
      : mainContainerWidth <= 992
      ? mainContainerWidth / 2 // md
      : mainContainerWidth / 3; // lg
  if (isNoChart) {
    dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('Please add a chart!'),
          time: 5,
          isActive: true,
        },
      })
    );
    return <NoAssetScreen assetType='ChartInDash' />;
  }

  if (error) {
    dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
        value: {
          message: mls('There is some error pls try again!'),
          time: 5,
          isActive: true,
        },
      })
    );
    return <div ref={chartsContainerRef}></div>;
  }

  const handleChartUpdates = async (updates) => {
    const newUpdates = { ...chartUpdates, ...updates };
    const chartsArr = Object.keys(newUpdates);
    setChartUpdates(newUpdates);
    if (chartsArr.length > 0) {
      const chartsApiPD = {
        charts: chartsArr,
        chartUpdates: newUpdates,
      };
      await _fetchDataToPlot(chartsApiPD, `${appID}/charts`).then((res) => {
        if (!res.__d3__error) {
          const newData = res.response;
          const newPlotData = { ...(plotData || {}) };
          Object.keys(newData).map((key) => {
            newPlotData[key] = newData[key];
            return true;
          });
          setPlotData(newPlotData);
          setUpdatedLoader(true);
        }
      });
    }
    setUpdatedLoader(false);
  };

  if (Object.keys(dashTableData).length === 0 || Object.keys(plotData || {}).length === 0) {
    return (
      <div style={styles.container}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div ref={chartsContainerRef} className='row'>
        <DashFilterStrip
          dashTableSchema={dashTableData}
          filterSchema={filterSchema}
          setFilterSchema={setFilterSchema}
          appID={appID}
          dashID={dashID}
          timeframe={timeframe}
          timeFrameObj={timeFrameObj}
          setTimeFrameObj={setTimeFrameObj}
          defaultTimeframes={defaultTimeframes.current}
          payloadData={payloadDataRef.current}
          dashName={dashName}
        />
        {dashboard.sections.map((section, i) => (
          <Fragment key={i}>
            {section.headings.map((heading, j) => (
              <h1
                key={j}
                style={{
                  ...getStyling(heading),
                }}
              >
                {heading.text}
              </h1>
            ))}
            <div className='row'>
              {section.charts.map((chart, ix) => (
                <Fragment key={ix}>
                  {chartsSchema[chart.id] != null && (
                    <Chart
                      key={chart.id}
                      chartKey={chart.id}
                      chartData={chartsSchema[chart.id]}
                      dataToPlot={plotData[chart.id] || {}}
                      cardWidth={chart.width}
                      useChartSettingsButton={() => {}}
                      handleChartUpdates={handleChartUpdates}
                      isUpdateLoading={isUpdateLoading}
                      isProd={'dash'}
                      updateFilter={updateFilter}
                      chartHeight={chartHeight}
                      filterSchema={dashTableSchArr}
                      timeFrameObj={timeFrameObj}
                      filterObj={filterSchema}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}

export default Dashboard;
