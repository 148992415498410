/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '_metronic/helpers';
import { mls } from 'lib/multilanguagesupport';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UpdateUser from './UpdateUser';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

const applyTeamName = (appTeams, roles) => {
  const teamNames = (roles || []).map((id) => appTeams[id]?.name || '');
  return '[' + (teamNames || []).join(', ').replace(/,/g, ', ') + ']';
};

const Users = () => {
  const { appID } = useParams();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const appTeams = analyticsSchema?.appTeams || {};

  const userSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.USER_SCHEMA);
  const loggedInEmail = userSchema?.emailID || '';
  const [allUsersList, setAllUsersList] = useState([]);
  const [isEdit, setEdit] = useState(null);

  const endPoint = `auth/${appID}/fetchAllUsersOfApp`;
  const handleEdit = (value) => {
    setEdit(value);
  };

  useEffect(() => {
    _supistaApiPost(endPoint, { data: {} })
      .then((res) => {
        const usersData = res?.response?.userData?.rows || [];
        const allList = [];
        usersData.forEach((obj) => {
          if (obj.userID && Array.isArray(obj.role)) {
            allList.push(obj);
          }
        });
        allList.sort((a, b) => a.userID - b.userID);

        setAllUsersList(allList);
      })
      .catch(() => {});
  }, [endPoint]);

  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-4 mb-1'>{mls('Manage Users')}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {mls('Add and update users and their teams to give permissions')}
            </span>
          </h3>
          <div className='card-toolbar'>
            <button
              onClick={() =>
                handleEdit({
                  userSchema: {},
                  isNew: true,
                  userIndex: allUsersList.length,
                })
              }
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              {mls('New User')}
            </button>
          </div>
        </div>
        <hr />
        <div className='table-responsive ms-10 mb-10 mt-5 me-10'>
          {/* begin::Table */}
          <table
            className='table table-rounded table-row-bordered align-middle gs-0 gy-4'
            style={{ display: 'block', maxHeight: '400px', overflow: 'auto' }}
          >
            {/* begin::Table head */}
            <thead style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>{mls('User ID')}</th>
                <th className='min-w-300px'>{mls('Email ID')}</th>
                <th className='min-w-300px'>{mls('Added to Team')}</th>
                <th className='min-w-200'>{mls('Added By')}</th>
                <th className='min-w-125px pe-4 text-end rounded-end'>{mls('Update')}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody style={{ borderTop: '0px' }}>
              {allUsersList.map((user, index) => (
                <tr key={user?.userID || 'id'}>
                  <td>
                    <div className='d-flex ms-4 align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold mb-1 fs-7'>{user.userID || ''}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-7'>
                      {user.emailID || ''}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold  d-block mb-1 fs-7'>
                      {applyTeamName(appTeams, user.role)}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-7'>
                      {user.addedByEmailID || ''}
                    </span>
                  </td>

                  <td className='text-end'>
                    <button
                      disabled={loggedInEmail === user?.emailID}
                      onClick={() =>
                        handleEdit({
                          userSchema: user,
                          isNew: false,
                          userIndex: index,
                        })
                      }
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
      </div>
      {isEdit && (
        <UpdateUser
          appID={appID}
          isNew={isEdit.isNew}
          userSchema={isEdit.userSchema}
          isEdit={isEdit}
          setEdit={setEdit}
          setAllUsersList={setAllUsersList}
          allUsersList={allUsersList}
          userIndex={isEdit.userIndex}
        />
      )}
    </>
  );
};

export default Users;
