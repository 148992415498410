import introJs from 'intro.js';
import { selectQuery } from './stepTemplate';
import useStep2 from './step2.jsx';

const useStep1 = () => {
  const step2 = useStep2();

  return (handleCompleteTutorial) => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: selectQuery('.introjs-asideMenu', 0),
          title: 'Data Lake',
          intro: 'Allows you to Create database connections from multiple instances.',
          position: 'right',
        },
        {
          title: 'Finish',
        },
      ],
      nextLabel: 'Next',
      prevLabel: 'Previous',
      showStepNumbers: false,
      exitOnOverlayClick: false,
      showBullets: false,
      disableInteraction: true,
    });
    intro.onafterchange(async (targetElement) => {
      const step = intro._currentStep;
      if (step === 1) {
        intro.exit();
        step2(handleCompleteTutorial);
      }
    });

    intro.start();
  };
};

export default useStep1;
