import { toAbsoluteUrl } from '_metronic/helpers';
import generateUUID from 'lib/reusable-components/reusableUtils/updateSchema/generateUUID';
import useChartSettingsButton, {
  useDashSettingsButton,
  useReportSettingsButton,
} from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import { mls } from 'lib/multilanguagesupport';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const svgStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh',
};

const fullPageAssets = ['ConnectionFailed', 'AppSchemaLoading'];

const screenImage = {
  Chart: '/media/errorScreens/NoChart.png',
  Dashboard: '/media/errorScreens/NoDashboard.png',
  Sheet: '/media/errorScreens/NoSheet.png',
  SupistaAI: '/media/errorScreens/NoDashboard.png',
  ConnectionFailed: '/media/errorScreens/NoConnection.png',
  AppSchemaLoading: '/media/errorScreens/LoadingData.png',
  KpiDashboard: '/media/errorScreens/selectDepartment&Circle.svg',
};

const NoAssetScreen = ({ assetType }) => {
  const chartSettingsButton = useChartSettingsButton();
  const dashSettingsButton = useDashSettingsButton();
  const reportSettingsButton = useReportSettingsButton();
  const { pathname } = useLocation();
  const appID = pathname.split('/')[1];
  // const [isSheet, setSheet] = React.useState('');
  // const [uuid, setNewuuid] = React.useState('');

  const handleButton = (type) => {
    const newUuid = generateUUID();
    const id = pathname.split('/')[3];
    // setNewuuid(newUuid);
    if (type === 'chart') chartSettingsButton(newUuid);
    // if (type === 'sheet') setSheet(newUuid);
    if (type === 'dash') dashSettingsButton(newUuid);
    if (type === 'editDash') dashSettingsButton(id);
    if (type === 'report') reportSettingsButton(newUuid);
  };

  const [assets] = React.useState({
    Chart: (
      <>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('No Chart added to this sheet!')}
        </h4>
        <div style={svgStyle}>
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            onClick={() => handleButton('chart')}
          >
            <span className='indicator-label'>{mls('Add New Chart')}</span>
          </button>
        </div>
      </>
    ),
    ChartInDash: (
      <>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('No Chart added to this Dashboard!')}
        </h4>
        <div style={svgStyle}>
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            onClick={() => handleButton('editDash')}
          >
            <span className='indicator-label'>{mls('Edit Dashboard')}</span>
          </button>
        </div>
      </>
    ),

    Dashboard: (
      <>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('No Dashboard added or Database connected to this Account.')}
        </h4>
        <br />
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-12 col-sm-12'
              style={{
                margin: 'auto',
                background: '#D9D9D94D',
                padding: '20px',
                borderRadius: '12px',
                marginBottom: '16px',
                width: '800px',
              }}
            >
              <h5 className='m-auto text-center'>{mls('Please add Database in')}</h5>
              <br />
              <div style={svgStyle}>
                <Link to={`/${appID}/dataLake/Connections`}>
                  <button type='button' className='btn btn-sm btn-primary me-3'>
                    <span className='indicator-label'>{mls('Data Lake')}</span>
                  </button>
                </Link>
              </div>
              <br />
            </div>
            <div
              className='col-md-12 col-sm-12'
              style={{
                margin: 'auto',
                background: '#D9D9D94D',
                padding: '20px',
                borderRadius: '12px',
                marginBottom: '16px',
                width: '800px',
              }}
            >
              <h5 className='m-auto text-center'>
                {mls('If you have already connected Database')}
              </h5>
              <br />
              <div style={svgStyle}>
                <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  onClick={() => handleButton('dash')}
                >
                  <span className='indicator-label'>{mls('Add New Dashboard')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    ),

    Sheet: (
      <>
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('No Sheet added to this Account!')}
        </h4>
        {/* <div style={svgStyle}>
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            onClick={() => handleButton('sheet')}
          >
            <span className='indicator-label'>{mls('Add New Sheet')}</span>
          </button>
        </div> */}
      </>
    ),

    KpiDashboard: (
      <>
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('Please select Department and Circle to get their KPIs!')}
        </h4>
      </>
    ),

    Reports: (
      <>
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('No Reports added to this Account!')}
        </h4>
        <div style={svgStyle}>
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            onClick={() => handleButton('report')}
          >
            <span className='indicator-label'>{mls('Add New Report')}</span>
          </button>
        </div>
      </>
    ),

    SupistaAI: (
      <>
        <br />
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls(
            'No data has been connected in this Account. Please connect to start using Supista AI!'
          )}
        </h4>
        <div style={svgStyle}>
          <Link to={`/${appID}/dataLake/Connections`}>
            <button type='button' className='btn btn-sm btn-primary me-3'>
              <span className='indicator-label'>{mls('Data Lake')}</span>
            </button>
          </Link>
        </div>
      </>
    ),
    ConnectionFailed: (
      <>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('Can not connect with the server. Please come back later!')}
        </h4>
        <div style={svgStyle}>
          <button
            type='button'
            className='btn btn-sm btn-primary me-3'
            onClick={() => window.location.reload(false)}
          >
            <span className='indicator-label'>{mls('Refresh Page')}</span>
          </button>
        </div>
      </>
    ),
    AppSchemaLoading: (
      <>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('Give us a moment to load all your data. It will just take a moment!')}
        </h4>
      </>
    ),
  });

  return (
    <>
      {/* {isSheet && (
        <SheetSettings
          appID={getAppID()}
          sheetID={uuid}
          isModalOpen={isSheet}
          setModalOpen={setSheet}
          isNew={true}
          sheetSchema={{}}
        />
      )} */}
      <div
        className={
          fullPageAssets.includes(assetType)
            ? 'h-100 d-flex align-items-center justify-content-center'
            : ''
        }
      >
        <div className='mb-20'>
          <div style={svgStyle}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 100 90'
              width='40%'
              height='40%'
              style={{ minWidth: '250px', maxHeight: '45vh' }}
            >
              <image
                href={toAbsoluteUrl(
                  screenImage[assetType] || '/media/chart-screen/Show_no_chart.svg'
                )} // Replace with the actual path to your image
                width='100'
                height='100'
              />
            </svg>

            {/* <img src={toAbsoluteUrl('/media/chart-screen/Show_no_chart.svg')} alt='NoCharts'></img> */}
          </div>
          {assets[assetType] || ''}
        </div>
      </div>
    </>
  );
};

export default NoAssetScreen;
