import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { targetPointType } from './addTargetPointData';
import { mls } from 'lib/multilanguagesupport';

interface SelectTargetPointProps {
  kpiProjectedTargetPoints: { [key: string]: any };
  selectedKpiTimeFrameRange: string;
  targetPoint: { [key: string]: any };
  setTargetPoint: Function;
  allTargetPointsElem: { [key: string]: any };
  tempDataUpdated?: boolean;
}

const SelectTargetPoint = ({
  kpiProjectedTargetPoints,
  selectedKpiTimeFrameRange,
  targetPoint,
  setTargetPoint,
  allTargetPointsElem,
  tempDataUpdated = false,
}: SelectTargetPointProps) => {
  const setConstant = {
    key: targetPointType.constant,
    label: 'Set Target Point',
    __d3__dropDownStyle: {
      // color: '#009ef7',
      backgroundColor: '#f1faff',
      fontWeight: 500,
    },
  };
  const [isInputError, setIsInputError] = useState(false);
  const updatedTargetPoints = [
    setConstant,
    ...(kpiProjectedTargetPoints[selectedKpiTimeFrameRange] ?? []),
  ];
  const updateTargetPoint = (action: string) => {
    setIsInputError(false);
    if (action !== targetPointType.constant) {
      setTargetPoint({ ...(allTargetPointsElem?.targetValue ?? {}), value: action });
    } else {
      setTargetPoint({
        ...(allTargetPointsElem?.targetValue ?? {}),
        value: null,
        type: action,
      });
    }
  };
  const removeTargetPointInput = () => {
    setIsInputError(false);
    setTargetPoint({
      ...(allTargetPointsElem?.targetValue ?? {}),
      value: null,
      type: targetPointType.column,
    });
  };

  const handleTargetValue = (value: string) => {
    if (value === '') {
      setTargetPoint({
        ...(allTargetPointsElem?.targetValue ?? {}),
        value: null,
      });
      return;
    }
    setTargetPoint({
      ...(allTargetPointsElem?.targetValue ?? {}),
      value: Number(value),
    });
  };
  const checkForTargetValue = () => {
    if (!targetPoint.value) {
      setIsInputError(true);
    } else {
      setIsInputError(false);
    }
  };
  if (targetPoint.type === targetPointType.constant) {
    return (
      <div className='targetPoinCellInputContainer'>
        <input
          type='number'
          className={`primaryInput targetPoinCellInput ${
            isInputError ? 'targetPoinCellInputError' : ''
          }`}
          placeholder={isInputError ? 'Enter Target Point' : 'Enter Target Point'}
          value={targetPoint.value}
          onChange={(e) => {
            setIsInputError(false);
            handleTargetValue(e.target.value);
          }}
          onBlur={() => checkForTargetValue()}
        />
        <div
          className='targetPoinCellInputRemove'
          onClick={() => {
            removeTargetPointInput();
          }}
        >
          <FiX />
        </div>
      </div>
    );
  }
  return (
    <ArrayDropDown
      array={updatedTargetPoints}
      dropDownValue={targetPoint.value}
      setdropDownValue={updateTargetPoint}
      mainKey={'key'}
      nameKey={'label'}
      ObjectInArray
      changeToReset={tempDataUpdated}
      inputPlaceHolder={mls('Select Target Point')}
    />
  );
};

export default SelectTargetPoint;
