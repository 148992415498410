import React from 'react';
import './nonSupportedScreen.scss';
import { mls } from 'lib/multilanguagesupport';
import { FaTimes } from 'react-icons/fa';
interface nonSupportedScreen {
  closeButton: Function;
}
const NonSupportedScreen = ({ closeButton }: nonSupportedScreen) => {
  return (
    <div className='nonSupported'>
      <div className='header MainUpperHeader'>
        <div className='title'>{mls('KPI Chart Settings')}</div>
        <div className='subTitle'>{mls('Edit KPI Chart Settings here')}</div>
        <div className='goleftSide'></div>
        <div className='closeButton' onClick={() => closeButton()}>
          <FaTimes className='arrowIcon' />
        </div>
      </div>
      <div className='nonSupportedMessageSection'>
        {mls('Mobile and Tablet browsers are not supported')}
      </div>
    </div>
  );
};

export default NonSupportedScreen;
