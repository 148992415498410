import { useEffect, useState } from 'react';
// import { Redirect, Switch, Route, useHistory, withRouter } from 'react-router-dom';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
// import getAppID from 'utils/getAppID';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import 'intro.js/introjs.css';
import './innerjs.css';
import useStep1 from './steps/step1.jsx';
import StartTutorial from './StartTutorial';
import EndTutorial from './EndTutorial';
// import useSampleDatabase from 'lib/auth-ui/useSampleDatabase';

const TutorialComponent = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTutorialEnd, setTutorialEnd] = useState(false);
  // const setSampleDatabase = useSampleDatabase();
  // const history = useHistory();
  // useState(() => {setSampleDatabase(getAppID(history.location.pathname))})
  const dispatch = useDispatch();
  const step1 = useStep1();

  const loginData = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.LOGIN);

  const handleCompleteTutorial = () => {
    setTutorialEnd(true);
    dispatch(
      create({
        setPath: reduxConstants.config.LOGIN,
        value: { ...loginData, isNewUser: false },
      })
    );
  };

  useEffect(() => {
    if (loginData?.isNewUser) {
      setModalOpen(true);
    }
  }, [loginData?.isNewUser]);

  const startTutorial = () => {
    setModalOpen(false);
    step1(handleCompleteTutorial);
  };

  if (isTutorialEnd) return EndTutorial(startTutorial, isTutorialEnd, setTutorialEnd);
  return (isModalOpen && StartTutorial(startTutorial, isModalOpen, setModalOpen)) || <></>;
};

export default TutorialComponent;
