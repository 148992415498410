// import { useState } from 'react';
// import { toast } from 'react-toastify';
import { supistaApiPost } from '../../utils/connections';
import { toast } from 'react-toastify';
import { reportSchemaValidation } from '../../utils/helperFunction';
import { mls } from 'lib/multilanguagesupport';
import { FaTimes } from 'react-icons/fa';
import { defaultReportTypeObject, reportPageStageObject } from '../../data/defaultSchema';
import { MdOutlineArrowBackIos } from 'react-icons/md';

interface mainProps {
  appID: string;
  settingsSchema: any;
  handleReportSettingsPopUp: Function;
  handleSave: Function;
  handleDelete: Function;
  isNew: boolean;
  selectedReportType: string;
  reportName: string;
  reportPageStage: string;
  setReportPageStage: Function;
}
const Header = ({
  settingsSchema,
  appID,
  handleReportSettingsPopUp,
  handleSave,
  handleDelete,
  selectedReportType,
  isNew,
  reportName,
  reportPageStage,
  setReportPageStage,
}: mainProps) => {
  // const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);

  const saveFunction = async () => {
    const isValidate = reportSchemaValidation(settingsSchema);
    if (isValidate.skip) {
      toast.warn(mls(isValidate.msg), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    // const finalSchema = removeExtraDataFromSchema(settingsSchema);

    const newReportData = {
      settingsSchema: settingsSchema,
      endPoint: 'schemaSettings/ReportSettings/create',
    };
    const updateReportSchema = {
      query: {
        _id: settingsSchema?._id,
      },
      new_values: settingsSchema,
    };
    const updateChartData = {
      settingsSchema: updateReportSchema,
      endPoint: 'schemaSettings/ReportSettings/update',
    };
    const final = isNew ? newReportData : updateChartData;
    try {
      const res = await supistaApiPost(final.endPoint, final.settingsSchema, appID);
      if (!res.__d3__error) {
        // toast.success('Saved Successfully', {});
        handleSave(settingsSchema, res?.response?._id);
      }
    } catch (err) {
      console.log('error encountered while creating new dashboard', err);
    }
  };
  const removeFunction = async () => {
    const endPoint = 'schemaSettings/ReportSettings/delete';
    const dataForRemove = {
      // reportID: settingsSchema.reportID || '',
      // appID: appID || '',

      query: {
        _id: settingsSchema._id || '',
      },
    };
    const res = await supistaApiPost(endPoint, dataForRemove, appID);
    if (!res.__d3__error) {
      handleDelete(settingsSchema._id);
    }
  };
  const handleBackButton = ({ stage }: { stage: string }) => {
    setReportPageStage(stage);
  };
  if (reportPageStage === reportPageStageObject.selectTime.key) {
    return (
      <div className='MainUpperHeader'>
        <div
          className='actionButton'
          onClick={() => {
            handleBackButton({ stage: reportPageStageObject.selectReport.key });
          }}
        >
          <MdOutlineArrowBackIos />
        </div>
        <div className='title'>{mls('Save Report')}</div>
        <div className='subTitle'>{mls(reportName)}</div>
        <div className='goleftSide'></div>
        {!isNew ? (
          <div className='deleteButtonDiv'>
            <button
              type='button'
              className='btn btn-sm btn-danger'
              onClick={() => removeFunction()}
            >
              <span className='indicator-label'>{mls('Delete')}</span>
            </button>
          </div>
        ) : null}
        <div className='saveButtonDiv'>
          <button className='primaryButton btn btn-sm ' onClick={() => saveFunction()}>
            <span className='indicator-label'>{mls('Save')}</span>
          </button>
        </div>
        <div className='closeButton' onClick={() => handleReportSettingsPopUp(false)}>
          <FaTimes />
        </div>
      </div>
    );
  }
  if (reportPageStage === reportPageStageObject.selectReport.key) {
    const reportTypeObjectElem =
      defaultReportTypeObject[selectedReportType as keyof typeof defaultReportTypeObject];

    const handleNext = () => {
      setReportPageStage(reportPageStageObject.selectTime.key);
    };
    return (
      <div className='MainUpperHeader'>
        <div
          className='actionButton'
          onClick={() => {
            handleBackButton({ stage: reportPageStageObject.selectType.key });
          }}
        >
          <MdOutlineArrowBackIos />
        </div>
        <div className='title'>{mls(reportTypeObjectElem.name)}</div>
        <div className='subTitle'>{mls(reportName)}</div>
        <div className='goleftSide'></div>
        {!isNew ? (
          <div className='deleteButtonDiv'>
            <button
              type='button'
              className='btn btn-sm btn-danger'
              onClick={() => removeFunction()}
            >
              <span className='indicator-label'>{mls('Delete')}</span>
            </button>
          </div>
        ) : null}
        <div className='saveButtonDiv'>
          <button
            className='primaryButton btn btn-sm secondaryButtonColor'
            onClick={() => handleNext()}
          >
            <span className='indicator-label'>{mls('Next')}</span>
          </button>
        </div>
        <div className='closeButton' onClick={() => handleReportSettingsPopUp(false)}>
          <FaTimes />
        </div>
      </div>
    );
  }
  return (
    <div className='MainUpperHeader'>
      <div className='title'>{mls('Report Settings')}</div>
      <div className='subTitle'>{mls('Edit Report Settings here')}</div>
      <div className='goleftSide'></div>
      {/* {!isNew ? (
        <div className='deleteButtonDiv'>
          <button type='button' className='btn btn-sm btn-danger' onClick={() => removeFunction()}>
            <span className='indicator-label'>{mls('Delete')}</span>
          </button>
        </div>
      ) : null}
      <div className='saveButtonDiv'>
        <button className='btn btn-sm btn-primary' onClick={() => saveFunction()}>
          <span className='indicator-label'>{mls('Save')}</span>
        </button>
      </div> */}
      <div className='closeButton' onClick={() => handleReportSettingsPopUp(false)}>
        <FaTimes />
      </div>
      {/* {isConfirmationDiv && (
        <ConfirmationDiv
          handleReportSettingsPopUp={handleReportSettingsPopUp}
          setIsConfirmationDiv={setIsConfirmationDiv}
        />
      )} */}
    </div>
  );
};

export default Header;

// interface ConfirmationDivProps {
//   setIsConfirmationDiv: Function;
//   handleReportSettingsPopUp: Function;
// }
// const ConfirmationDiv = ({
//   handleReportSettingsPopUp,
//   setIsConfirmationDiv,
// }: ConfirmationDivProps) => {
//   const handleAction = (action: boolean) => {
//     if (action) {
//       handleReportSettingsPopUp(!action);
//       setIsConfirmationDiv(!action);
//       return;
//     }
//     setIsConfirmationDiv(action);
//   };

//   return (
//     <>
//       <div className='confirmationContainer'>
//         <div className='confirmationDiv'>
//           <div className='msg'>Are you sure you want to close this windows?</div>
//           <div className='ButtonContainer'>
//             <button className='primaryButtonSmall' onClick={() => handleAction(true)}>
//               yes
//             </button>
//             <button className='primaryButtonSmall' onClick={() => handleAction(false)}>
//               no
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
