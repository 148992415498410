import React, { useEffect, useRef, useState } from 'react';
// import arrowDropDownIcon from '../../assets/arrowDropDownIcon.svg';
import './dropDown.scss';

import useUpdateEffect from '../../hooks/useUpdateEffect';
import { IoIosArrowDown } from 'react-icons/io';
import ReactSelectDropDown from './ReactSelectDropDown';
import { FaCaretDown } from 'react-icons/fa';

interface DropDownProps {
  array: any[];
  disableArray?: any[];
  dropDownValue: any;
  setdropDownValue: any;
  changeToReset?: boolean;
  className?: string;
  search?: boolean;
  inputPlaceHolder?: string;
  nameKey?: string;
  mainKey?: string;
  isChildernInsert?: boolean;
  ObjectInArray?: boolean;
  // if ObjectInArray is true
  // [
  //   { nameKey :"name":mainKey:"key"},
  //   { nameKey :"name":mainKey:"key"}
  // ] else [
  //   "name",
  //   "name2",
  //   "name3",
  // ];
  withIndex?: boolean;
  children?: any;
  outerContainerRef?: React.RefObject<HTMLDivElement> | null;
  dropDownHeightChange?: boolean;
  dropDownHeight?: number;
  isDropDownFixed?: boolean;
}
const ArrayDropDown = ({
  array,
  disableArray = [],
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  mainKey = 'key',
  isChildernInsert = false,
  ObjectInArray = false,
  withIndex = false,
  children = <></>,
  outerContainerRef = null,
  dropDownHeightChange = false,
  dropDownHeight = 200,
  isDropDownFixed = true,
}: DropDownProps) => {
  const isObject = typeof dropDownValue === 'object' ? true : false;

  const [filteredData, setFilteredData] = useState(array);
  const [dropDownData, setdropDownData] = useState({ isOpen: false, top: false, left: false });

  const [selectedValue, setSelectedValue] = useState(() => {
    if (ObjectInArray) {
      const filterValue = array.find((elem: any) => elem[mainKey] === dropDownValue);
      if (filterValue) {
        return filterValue;
      }
    }
    return dropDownValue;
  });

  const [searchInput, setSearchInput] = useState(() => {
    if (isObject) {
      return dropDownValue?.[nameKey];
    }
    if (ObjectInArray) {
      const filterValue = array.filter((elem: any) => elem[mainKey] === dropDownValue);
      if (filterValue[0]) {
        return filterValue[0]?.[nameKey];
      }
    }
    return dropDownValue;
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDropDown = () => {
    setdropDownData((currentData) => {
      if (!currentData.isOpen) {
        if (outerContainerRef && outerContainerRef?.current && wrapperRef.current) {
          const finalBottomSpace =
            outerContainerRef.current.scrollHeight - wrapperRef.current.offsetTop;
          if (dropDownHeight >= finalBottomSpace) {
            return { ...currentData, isOpen: !currentData.isOpen, top: true };
          } else {
            return { ...currentData, isOpen: !currentData.isOpen, top: false };
          }
        }
      }
      return { ...currentData, isOpen: !currentData.isOpen };
    });
  };
  const stopPropgation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setdropDownData((currentData) => ({ ...currentData, isOpen: true }));
  };
  const handleSelection = (elem: any, index: number, isDisable: boolean) => {
    if (isDisable) {
      return;
    }
    setSearchInput(elem?.[nameKey] ?? elem);

    setSelectedValue(elem);
    if (withIndex) {
      setdropDownValue(
        isObject || ObjectInArray ? { data: elem?.[mainKey], index } : { data: elem, index }
      );
    } else {
      setdropDownValue(isObject || ObjectInArray ? elem?.[mainKey] : elem);
    }
    handleDropDown();
  };

  const handleFilter = (value: string) => {
    const filtered = array.filter(function (str: any) {
      let keyword = value.toLowerCase();
      if (str?.[nameKey] !== null) {
        return str?.[nameKey].toLowerCase().includes(keyword);
      }
      return str.toLowerCase().includes(keyword);
    });
    setFilteredData(filtered);
    setSearchInput(value);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        //NEW
        const scrollCheckFunction = () => {
          const path = event.composedPath();
          const e = event;
          let isScrollClick = false;
          let scrollStart = { x: 0, y: 0 };
          let clickedElement = null;
          for (let element of path) {
            if (element instanceof HTMLElement) {
              const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element;
              const rect = element.getBoundingClientRect();

              // Check for vertical scrollbar
              const verticalScrollbar =
                scrollHeight > clientHeight &&
                e.clientX > rect.right - 17 &&
                e.clientX <= rect.right;

              // Check for horizontal scrollbar
              const horizontalScrollbar =
                scrollWidth > clientWidth &&
                e.clientY > rect.bottom - 17 &&
                e.clientY <= rect.bottom;

              if (verticalScrollbar || horizontalScrollbar) {
                isScrollClick = true;
                break;
              }
            }
          }
          return { isScrollClick, scrollStart, clickedElement };
        };
        const { isScrollClick } = scrollCheckFunction();
        if (isScrollClick) {
          return;
        }
        setSearchInput(isObject || ObjectInArray ? dropDownValue?.[nameKey] : dropDownValue);
        handleDropDown();
      }
    }
    if (dropDownData.isOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, dropDownData]);

  const isSelected = (element: any, dropdown: any) => {
    if (isObject || ObjectInArray) {
      const isDisable = disableArray.some((disableKey) => {
        return element?.[mainKey] === disableKey;
      });

      return element?.[mainKey] === (dropdown?.[mainKey] ?? dropdown)
        ? {
            className: `dropDownItems selected ${isDisable ? 'disableDownItems' : ''}`,
            isDisable,
            style: element?.__d3__dropDownStyle ?? {},
          }
        : {
            className: `dropDownItems ${isDisable ? 'disableDownItems' : ''} `,
            isDisable,
            style: element?.__d3__dropDownStyle ?? {},
          };
    }
    return element === dropdown
      ? { className: 'dropDownItems selected', isDisable: false, style: {} }
      : { className: 'dropDownItems', isDisable: false, style: {} };
  };

  useUpdateEffect(() => {
    setSelectedValue(() => {
      if (ObjectInArray) {
        const filterValue = array.find((elem: any) => elem[mainKey] === dropDownValue);
        if (filterValue) {
          return filterValue;
        }
      }
      return dropDownValue;
    });
    setSearchInput(isObject || ObjectInArray ? dropDownValue?.[nameKey] : dropDownValue);
  }, [changeToReset]);
  useUpdateEffect(() => {
    // setSelectedValue(() => {
    //   if (ObjectInArray) {
    //     const filterValue = array.find((elem: any) => elem[mainKey] === dropDownValue);
    //     if (filterValue) {
    //       return filterValue;
    //     }
    //   }
    //   return dropDownValue;
    // });
    setFilteredData(array);
  }, [array]);

  useEffect(() => {}, [array]);

  const titleFunction = () => {
    if (ObjectInArray) {
      const filterValue = (array ?? []).find((elem: any) => elem[mainKey] === dropDownValue);
      if (filterValue) {
        return filterValue[nameKey];
      }
    } else {
      return dropDownValue;
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      let str = searchInput ?? '';
      str = str.substring(0, str.length - 1);
      handleFilter(str);
    }
  };
  const options = [
    {
      value: 'chocolate',
      label: 'Chocolate',
      filteredData,
      isSelected,
      handleSelection,
      nameKey,
      dropDownValue,
      ObjectInArray,
      isObject,
      //New
      handleDropDown,
      isChildernInsert,
      children,
      search,
      inputPlaceHolder,
      searchInput,
      stopPropgation,
      handleFilter,
      titleFunction,
      //Extra
      dropDownData,
      CustomOption,
      ValueContainer,
      handleKeyDown,
    },
  ];
  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      {/* {isDropDownFixed === true ? (
        <div className={`dropDownArray`}>
          <ReactSelectDropDown options={options} />
        </div>
      ) : null} */}
      <ReactSelectDropDown options={options} />
      {/* 
      <div className='mainTitle' onClick={() => handleDropDown()}>
        {isChildernInsert ? (
          <>{children}</>
        ) : (
          <>
            {search ? (
              <input
                type='text'
                placeholder={inputPlaceHolder}
                value={searchInput}
                onClick={(e) => stopPropgation(e)}
                onChange={(e) => handleFilter(e)}
              />
            ) : (
              <>
                <div className='textDiv' title={titleFunction()}>
                  {titleFunction() ?? inputPlaceHolder}
                </div>
              </>
            )}
            <div className={dropDownData.isOpen ? `dropDownarrow open` : 'dropDownarrow'}>
              <IoIosArrowDown />
            </div>
          </>
        )}
      </div> */}
      {isDropDownFixed === false ? (
        <div
          className={`dropDownArray ${dropDownData.isOpen ? 'open' : ''} ${
            dropDownData.top ? 'upperDropDown' : ''
          }`}
        >
          <CustomOption options={options} />
        </div>
      ) : null}
    </div>
  );
};

export default ArrayDropDown;

const CustomOption = (props: any) => {
  const { options } = props;
  const optionData = options[0];
  const {
    filteredData,
    isSelected,
    handleSelection,
    nameKey,
    dropDownValue,
    ObjectInArray,
    isObject,
  } = optionData;
  return (
    <div>
      {/* <div {...innerProps}> */}
      {React.Children.toArray(
        filteredData.map((elem: any, index: number) => {
          const classData = isSelected(elem, dropDownValue);
          return (
            <div
              className={classData.className}
              style={classData.style}
              onClick={() => {
                handleSelection(elem, index, classData.isDisable);
              }}
              title={isObject || ObjectInArray ? elem?.[nameKey] : elem}
            >
              {isObject || ObjectInArray ? elem?.[nameKey] : elem}
            </div>
          );
        })
      )}
      {filteredData.length === 0 ? (
        <div className='dropDownItems' style={{ cursor: 'auto' }}>
          No Options
        </div>
      ) : null}
    </div>
  );
};

const ValueContainer = (props: any) => {
  const { options } = props;
  const optionData = options[0];
  const {
    handleDropDown,
    isChildernInsert,
    children,
    search,
    inputPlaceHolder,
    searchInput,
    stopPropgation,
    handleFilter,
    titleFunction,
    dropDownData,
    handleKeyDown,
  } = optionData;

  return (
    <div style={{ width: '100%' }}>
      <div
        className='mainTitle'
        onClick={() => {
          handleDropDown();
        }}
        onTouchEnd={() => {
          handleDropDown();
        }}
      >
        {isChildernInsert ? (
          <>{children}</>
        ) : (
          <>
            {search ? (
              <input
                type='text'
                placeholder={inputPlaceHolder}
                value={searchInput}
                onClick={(e) => stopPropgation(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleFilter(e.target.value);
                }}
              />
            ) : (
              <>
                <div className='textDiv' title={titleFunction()}>
                  {titleFunction() ?? inputPlaceHolder}
                </div>
              </>
            )}
            <div className={dropDownData.isOpen ? `dropDownarrow open` : 'dropDownarrow'}>
              {/* <IoIosArrowDown /> */}
              <FaCaretDown />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
