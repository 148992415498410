/* eslint import/prefer-default-export: 0 */

/**
 * This function combines data elements
 * Elements having angle less than 0.2 radians are combines
 * @param {Object} data The original data object of pie chart
 * @returns {Object} Contains an Object and an array
 * The returned Object is the updated data object
 * The returned array contains the elements that are combines into a single unit
 */
export const pieDataFormatter = (data) => {
  const { x, y } = data;
  const formattedData = {
    x: [],
    y: [],
  };
  const others = [];
  if (!x || !y) {
    return {
      formattedData,
      others,
    }
  }
  if (x.length === 0 || y?.length === 0) {
    return {
      formattedData,
      others,
    };
  }

  // Find sum of all elements of the data object
  const sum = y.reduce((prev, next) => prev + next);
  let otherSum = 0;

  // Loop on each element and append required elements to others array
  x.forEach((element, index) => {
    // Find the angle to be taken on pie chart ( in degrees )
    const angle = (y[index] / sum) * 360;
    // If angle is less than 0.2 radians (roughly 12 dgrees) push too others array
    if (angle <= 10) {
      others.push({
        x: element,
        y: y[index],
      });
      otherSum += y[index];
    } else {
      // Else push the element in final data
      formattedData.x.push(element);
      formattedData.y.push(y[index]);
    }
  });

  // Append others to data
  if (others.length > 1) {
    formattedData.x.push('Others');
    formattedData.y.push(otherSum);
  } else if (others.length === 1) {
    formattedData.x.push(others[0].x);
    formattedData.y.push(otherSum);
  }

  return {
    formattedData,
    others,
    otherSum,
  };
};
