import { mls } from 'lib/multilanguagesupport';
import ClosableWhiteDiv from '../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import FontSelector from './FontSelector';
import SolidColorSelector from './SolidColorSelector';

const ValueDiv = (props, { show = false }) => {
  const { axisStyle, setAxisStyle } = props;

  const handleColor = (elem) => {
    setAxisStyle({ ...axisStyle, valueStyle: elem });
  };
  const handleFontStyle = (elem) => {
    setAxisStyle({ ...axisStyle, valueStyle: elem });
  };

  return (
    <ClosableWhiteDiv title={mls(`Values`)} show={show} autoCloseOff={true}>
      <FontSelector fontStyle={axisStyle?.valueStyle} setFontStyle={handleFontStyle} />
      <SolidColorSelector color={axisStyle?.valueStyle} setColor={handleColor} />
    </ClosableWhiteDiv>
  );
};

export default ValueDiv;
