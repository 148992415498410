import React, { useState, useRef } from 'react';
// import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
// import ChartSettings from './ChartSettings';
import useChartSettingsButton from '../../lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import Chart from 'lib/visx-lib';
// import { CircularProgress } from '@mui/material';
import useUpdateFilter from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateFilter';
// import useStoreSchema from 'commons/useStoreSchema';
// import reduxConstants from 'redux/reduxConstants';
// import { useDispatch } from 'react-redux';
// import { create } from 'redux/stateSlice';
// import { mls } from 'lib/multilanguagesupport';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
// import useAppState from 'commons/useAppState';
// import NoAssetScreen from '../error/NoAssetScreen';
import { _fetchDataToPlot } from 'lib/server-connection/connections';

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '75vh', // Make the container fill the entire viewport height
//   },
// };

function Charts({
  appID,
  sheetID,
  sheetSchema,
  chartsSchema,
  filterSchema,
  setFilterSchema,
  tableSchema,
  plotData,
  setPlotData,
  chartUpdates,
  setChartUpdates,
  isAccess,
  timeFrameObj,
}) {
  const chartsContainerRef = useRef(null);
  // const chartSettingsState = useAppState('chartSettings');

  const { width: mainContainerWidth } = useDivDimensions(chartsContainerRef);

  const chartHeight =
    mainContainerWidth <= 576
      ? mainContainerWidth // sm
      : mainContainerWidth <= 992
      ? mainContainerWidth / 2 // md
      : mainContainerWidth / 3; // lg
  // const dispatch = useDispatch();
  // const [queryKey, setQueryKey] = useState({ sheetSchema, filterSchema, chartsSchema });
  // const isReadyForQuery = useStoreSchema(
  //   reduxConstants.STORE_NAME,
  //   reduxConstants.config.READY_FOR_QUERY
  // );
  // const payloadData = { appID, sheetID, filters: filterSchema };
  const updateReduxFilter = useUpdateFilter();
  const [isUpdateLoading, setUpdatedLoader] = useState(false);
  // Get chart data from server
  // const { isLoading, error, data } = useQuery(
  //   ['sheetData', queryKey, payloadData],
  //   () => _fetchDataToPlot(payloadData, `${appID}/sheets`),
  //   {
  //     retry: false,
  //   }
  // );
  const chartLists = sheetSchema.charts || [];
  // const [plotData, setPlotData] = useState({});

  const updateFilter = (newFilters) => {
    const newFilterSchema = { ...filterSchema, ...newFilters };
    setFilterSchema(newFilterSchema);
    updateReduxFilter(newFilterSchema);
  };

  const handleChartUpdates = async (updates) => {
    const newUpdates = { ...chartUpdates, ...updates };
    const chartsArr = Object.keys(newUpdates);
    setChartUpdates(newUpdates);
    if (chartsArr.length > 0) {
      const chartsApiPD = {
        charts: chartsArr,
        chartUpdates: newUpdates,
      };
      await _fetchDataToPlot(chartsApiPD, `${appID}/charts`).then((res) => {
        if (!res.__d3__error) {
          const newData = res.response;
          const newPlotData = { ...(plotData || {}) };
          Object.keys(newData).map((key) => {
            newPlotData[key] = newData[key];
            return true;
          });
          setPlotData(newPlotData);
          setUpdatedLoader(true);
        }
      });
    }
    setUpdatedLoader(false);
  };

  // useEffect(() => {
  //   if (isReadyForQuery) {
  //     setQueryKey({ sheetSchema, chartsSchema });
  //     dispatch(
  //       create({
  //         setPath: `${reduxConstants.config.READY_FOR_QUERY}`,
  //         value: false,
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chartsSchema, isReadyForQuery, sheetSchema]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     setPlotData(data?.response?.charts || {});
  //   }
  // }, [data, isLoading]);

  // if (chartSettingsState.chartID) {
  //   return (
  //     <div ref={chartsContainerRef}>
  //       {chartSettingsState.chartID && (
  //         <ChartSettings
  //           key={`${chartSettingsState.chartID}-settings`}
  //           chartsSchema={chartsSchema}
  //           chartID={chartSettingsState.chartID}
  //           chartData={chartsSchema[chartSettingsState.chartID]}
  //           sheetID={sheetID}
  //           appID={appID}
  //           tableSchema={tableSchema}
  //           isNewChart={!(chartSettingsState.chartID in chartsSchema)}
  //         />
  //       )}
  //     </div>
  //   );
  // }

  // // this code section runs when there are 0 charts.
  // if (chartLists.length === 0) {
  //   dispatch(
  //     create({
  //       setPath: reduxConstants.config.APP_STATUS.ERROR_TOAST,
  //       value: {
  //         message: mls('Please add a chart!'),
  //         time: 5,
  //         isActive: true,
  //       },
  //     })
  //   );
  //   return (
  //     <div ref={chartsContainerRef}>
  //       <NoAssetScreen assetType='Chart' />
  //     </div>
  //   );
  // }

  return (
    <div ref={chartsContainerRef}>
      <div className='row '>
        {(chartLists || []).map((uuid) => (
          <React.Fragment key={uuid}>
            {/* <div key={uuid} style={{ display: 'flex' }}> */}
            {chartsSchema[uuid] != null && (
              <Chart
                key={`${uuid}-charts`}
                chartKey={uuid}
                chartData={chartsSchema[uuid]}
                dataToPlot={plotData?.[uuid] || {}}
                cardWidth={chartsSchema[uuid]?.chartWidth || 'HALF'}
                useChartSettingsButton={useChartSettingsButton}
                isProd={isAccess ? 'sheet' : false}
                updateFilter={updateFilter}
                handleChartUpdates={handleChartUpdates}
                isUpdateLoading={isUpdateLoading}
                filterSchema={tableSchema}
                chartHeight={chartHeight}
                timeFrameObj={timeFrameObj}
                filterObj={filterSchema}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

Charts.propTypes = {
  sheetID: PropTypes.string.isRequired,
};

export default Charts;
