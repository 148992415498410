import React, { useState } from 'react';
import './kpiTimeFrame.scss';
import './components/kpiTimeframeGrid.scss';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { getPeriodRange, getTimeFrameYearLoop } from './utils/kpiTimeFrameUtils';
import {
  timeFrameMonthsOfYear,
  timeFrameQuaterOfYear,
  timeFrameRangeOptions,
  timeFramefYear,
} from './kpiTimeFrameData';
import AddKpiCategorysColumn from './components/AddKpiCategorysColumn';
import KpiCategorysColumn, { KpiPeriodRangeEditCellDiv } from './components/KpiCategorysColumn';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { FaTimes } from 'react-icons/fa';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';

let currentYear = new Date().getFullYear();
const { startPeriodRangeObject, endPeriodRangeObject } = getPeriodRange({ currentYear });

interface KpiProjectedTargetPointsProps {
  isKpiProjectedTargetPoints: boolean;
  handleSave: Function;
  selectedKpiTimeFrameRange: string;
  projectedTargetPoints: { [key: string]: any };
  kpiTimePeriodRange: { from: number; to: number };
  setProjectedTargetPoints: Function;
  isSaving: boolean;
}
const KpiProjectedTargetPoints = ({
  isKpiProjectedTargetPoints = false,
  handleSave,
  selectedKpiTimeFrameRange,
  projectedTargetPoints,
  kpiTimePeriodRange,
  isSaving,
}: KpiProjectedTargetPointsProps) => {
  // const [selectedKpiTimeFrameRange, setSelectedKpiTimeFrameRange] = useState(
  //   timeFrameRangeOptions.THISMONTH.key
  // );
  const [kpiProjectedTargetPoints, setKpiProjectedTargetPoints] = useState(projectedTargetPoints);
  const [timePeriodRange, setTimePeriodRange] = useState(
    kpiTimePeriodRange ?? {
      from: currentYear,
      to: currentYear + 1,
    }
  );

  return (
    <div className='KPITimeFrame DefaultScroll'>
      <PopUpDivChild
        initialValue={isKpiProjectedTargetPoints}
        setPopUp={() => handleSave({ isSave: false })}
        header={false}
        className='kpiTimeFrameSettingsPopupClass'
      >
        <div className='popupTitleDiv '>
          <div className='popupTitle'>{mls('Projected Target Points')}</div>
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() =>
              handleSave({
                isSave: true,
                timePeriodRange: timePeriodRange,
                data: kpiProjectedTargetPoints,
              })
            }
          >
            <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
              {mls('Save')}
              {isSaving ? <CircularProgress size={9} color='inherit' /> : null}
            </div>
          </button>
          <div className='closeButton' onClick={() => handleSave({ isSave: false })}>
            <FaTimes />
          </div>
        </div>
        <div className='kpiTimeFrameSettingsContainer'>
          <div className='headerOptions'>
            <div className='timeframeRangeOptionsContainer'></div>

            <div className='timeFramePeriodSelector'>
              <div className='titleDiv'>{mls('Target Point Time Period')}:</div>
              <div className='timeFramePeriodSelectorOption'>
                <ObjectDropDown
                  object={startPeriodRangeObject}
                  dropDownValue={timePeriodRange.from}
                  nameKey={'lable'}
                  setdropDownValue={(action: string) => {
                    setTimePeriodRange((currentRange) => ({
                      ...currentRange,
                      from: Number(action),
                    }));
                  }}
                  ObjectInObject
                />
              </div>
              <div className='titleDiv'>-</div>
              <div className='timeFramePeriodSelectorOption'>
                <ObjectDropDown
                  object={endPeriodRangeObject}
                  dropDownValue={timePeriodRange.to}
                  nameKey={'lable'}
                  setdropDownValue={(action: string) => {
                    setTimePeriodRange((currentRange) => ({ ...currentRange, to: Number(action) }));
                  }}
                  ObjectInObject
                />
              </div>
            </div>
          </div>
          {selectedKpiTimeFrameRange === timeFrameRangeOptions.THISMONTH.key ? (
            <TargetPointsGridEditor
              selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
              timePeriodRange={timePeriodRange}
              timeFrameType={timeFrameMonthsOfYear}
              kpiTargetPoints={kpiProjectedTargetPoints}
              setKpiTargetPoints={setKpiProjectedTargetPoints}
              timeFrameTypeKey={timeFrameRangeOptions.THISMONTH.key}
            />
          ) : null}
          {selectedKpiTimeFrameRange === timeFrameRangeOptions.THISQUARTER.key ? (
            <TargetPointsGridEditor
              selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
              timePeriodRange={timePeriodRange}
              timeFrameType={timeFrameQuaterOfYear}
              kpiTargetPoints={kpiProjectedTargetPoints}
              setKpiTargetPoints={setKpiProjectedTargetPoints}
              timeFrameTypeKey={timeFrameRangeOptions.THISQUARTER.key}
            />
          ) : null}
          {selectedKpiTimeFrameRange === timeFrameRangeOptions.THISYEAR.key ? (
            <TargetPointsGridEditor
              selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
              timePeriodRange={timePeriodRange}
              timeFrameType={timeFramefYear}
              kpiTargetPoints={kpiProjectedTargetPoints}
              setKpiTargetPoints={setKpiProjectedTargetPoints}
              timeFrameTypeKey={timeFrameRangeOptions.THISYEAR.key}
            />
          ) : null}
        </div>
      </PopUpDivChild>
    </div>
  );
};

export default KpiProjectedTargetPoints;

interface targetPointsEditorProps {
  selectedKpiTimeFrameRange: string;
  timePeriodRange: {
    from: number;
    to: number;
  };
  timeFrameTypeKey: string;
  setKpiTargetPoints: Function;
  timeFrameType: { [key: string]: any };
  kpiTargetPoints: { [key: string]: any };
}

const TargetPointsGridEditor = ({
  selectedKpiTimeFrameRange,
  timePeriodRange,
  timeFrameType,
  timeFrameTypeKey,
  kpiTargetPoints,
  setKpiTargetPoints,
}: targetPointsEditorProps) => {
  const yearLoop = getTimeFrameYearLoop(timePeriodRange.from, timePeriodRange.to);
  const setKpiCategorys = (kpiData: { [key: string]: any }) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      [timeFrameTypeKey]: kpiData,
    });
  };
  const kpiCategorys: { [key: string]: any }[] = kpiTargetPoints[timeFrameTypeKey] ?? [];
  const [isAddInput, setIsAddInput] = useState(false);
  const handleAddKpiCategory = ({
    kpiData,
  }: {
    kpiData: { [key: string]: any };
    kpiCategoryKey: string;
  }) => {
    setKpiTargetPoints((currentData: { [key: string]: any }) => {
      let newKpiTargetPoint: { [key: string]: any } = {};
      Object.keys(timeFrameRangeOptions).forEach((timeFramKey: string) => {
        newKpiTargetPoint = {
          ...newKpiTargetPoint,
          [timeFramKey]: [kpiData, ...(currentData[timeFramKey] ?? [])],
        };
      });
      return newKpiTargetPoint;
    });
    // setKpiCategorys([kpiData, ...kpiCategorys]);
  };
  const handleKpiCategory = ({
    kpiData,
    kpiCategorysIndex,
  }: {
    kpiData: { [key: string]: any };
    kpiCategorysIndex: number;
  }) => {
    const copyKpiCategoryArray = Array.from(kpiCategorys);
    copyKpiCategoryArray.splice(kpiCategorysIndex, 1);
    copyKpiCategoryArray.splice(kpiCategorysIndex, 0, kpiData);
    setKpiCategorys(copyKpiCategoryArray);
  };
  const handleKpiCategoryRemove = ({ kpiCategorysIndex }: { kpiCategorysIndex: number }) => {
    setKpiTargetPoints((currentData: { [key: string]: any }) => {
      let newKpiTargetPoint: { [key: string]: any } = {};
      Object.keys(timeFrameRangeOptions).forEach((timeFramKey: string) => {
        const copyKpiCategoryArray = Array.from(currentData[timeFramKey] ?? []);
        copyKpiCategoryArray.splice(kpiCategorysIndex, 1);
        newKpiTargetPoint = {
          ...newKpiTargetPoint,
          [timeFramKey]: copyKpiCategoryArray,
        };
      });
      return newKpiTargetPoint;
    });

    // const copyKpiCategoryArray = Array.from(kpiCategorys);
    // copyKpiCategoryArray.splice(kpiCategorysIndex, 1);
    // setKpiCategorys(copyKpiCategoryArray);
  };
  return (
    <div className='kpiTargetPointsEditorContainer'>
      <div className='kpiTargetPointsEditorTableHeader'>
        <div className='cellDiv kpiTargetPointsKpiTypeTitleOuter'>
          <div className='kpiTargetPointsKpiTypeTitleInner'>
            {mls(
              timeFrameRangeOptions[selectedKpiTimeFrameRange as keyof typeof timeFrameRangeOptions]
                ?.lable
            )}
          </div>
        </div>
        <div className='cellDiv kpiTargetPointsTitleDiv'>{mls('KPI Target Points')}</div>
      </div>
      <div
        className='kpiTimeframeGridContainer'
        style={{
          gridTemplateColumns: `12rem ${isAddInput ? '12rem' : '4rem'} ${
            kpiCategorys.length >= 1 ? `repeat(${kpiCategorys.length},  18rem)` : ''
          }`,
          // gridTemplateColumns: `12rem 4rem ${
          //   kpiCategorys.length >= 1
          //     ? `repeat(${kpiCategorys.length},  minmax(min-content, auto))`
          //     : ''
          // }`,
        }}
      >
        <div className='gridCell sideColumnGridCell firstBlankGridCell'></div>
        <AddKpiCategorysColumn
          kpiCategorys={kpiCategorys}
          handleKpiCategory={handleAddKpiCategory}
          isInputState={isAddInput}
          setIsInputState={setIsAddInput}
        />
        {React.Children.toArray(
          kpiCategorys.map((selectecKpiCategorys, kpiCategorysIndex: number) => {
            return (
              <>
                <KpiCategorysColumn
                  selectecKpiCategorys={selectecKpiCategorys}
                  kpiCategorysIndex={kpiCategorysIndex}
                  kpiCategorys={kpiCategorys}
                  handleKpiCategory={handleKpiCategory}
                  handleKpiCategoryRemove={handleKpiCategoryRemove}
                />
              </>
            );
          })
        )}
        {React.Children.toArray(
          yearLoop.map((currentyear: number) => {
            return timeFrameType.map((timeFrameTypeKey: any) => {
              const dataKey = `${currentyear}${timeFrameTypeKey.key}`;
              return (
                <>
                  <div className='gridCell sideColumnGridCell'>
                    {timeFrameTypeKey.label}&nbsp;
                    {currentyear}
                  </div>
                  <div
                    className='gridCell addColumnGridCell'
                    style={isAddInput ? { width: '100%' } : {}}
                  ></div>
                  {React.Children.toArray(
                    kpiCategorys.map(
                      (selectecKpiCategorys: { [key: string]: any }, kpiCategorysIndex: number) => {
                        const kpiDataUpdate = ({
                          kpiData,
                        }: {
                          kpiData: { [key: string]: any };
                        }) => {
                          handleKpiCategory({
                            kpiData: { ...selectecKpiCategorys, data: kpiData },
                            kpiCategorysIndex: kpiCategorysIndex,
                          });
                        };
                        const handlePasteUpdate = ({
                          currentyear,
                          timeFrameTypeKey,
                          kpiCategoryKey,
                          cellsData,
                        }: {
                          currentyear: number;
                          timeFrameTypeKey: number;
                          kpiCategoryKey: string;
                          cellsData: any[];
                        }) => {
                          const convertToTwoDigitFormat = (number: number | string) => {
                            // Convert number to string
                            let numString = number.toString();

                            // If number has only one digit, prepend '0' to it
                            if (numString.length === 1) {
                              return '0' + numString;
                            } else {
                              // If number has two or more digits, return the original number as string
                              return numString;
                            }
                          };
                          const kpiCategorysKeysArray = Array.from(kpiCategorys);
                          cellsData.forEach((columnData: any, columnIndex: number) => {
                            const currentColumnIndex = columnIndex + kpiCategorysIndex;
                            const currentColumn = kpiCategorys[currentColumnIndex];
                            const currentKpiCategorysKey = currentColumn?.key;
                            if (!currentKpiCategorysKey) {
                              return;
                            }
                            let currentColumnData = currentColumn?.data ?? {};

                            let typeIncrementer = Number(timeFrameTypeKey);
                            let yearIncrementer = currentyear;
                            let stopLoop = false;
                            for (let index = 0; index < columnData.length; index++) {
                              if (stopLoop) {
                                break;
                              }
                              const element = columnData[index];
                              currentColumnData = {
                                ...currentColumnData,
                                [`${yearIncrementer}${convertToTwoDigitFormat(typeIncrementer)}`]:
                                  element,
                              };
                              const isFind = timeFrameType.length >= typeIncrementer + 1;
                              typeIncrementer++;
                              if (!isFind) {
                                typeIncrementer = 1;
                                yearIncrementer++;
                                if (yearIncrementer > timePeriodRange.to) {
                                  stopLoop = true;
                                }
                              }
                            }
                            const finalKpiCategory = {
                              ...currentColumn,
                              data: currentColumnData,
                            };
                            kpiCategorysKeysArray.splice(currentColumnIndex, 1);
                            kpiCategorysKeysArray.splice(currentColumnIndex, 0, finalKpiCategory);
                          });
                          setKpiCategorys(kpiCategorysKeysArray);
                        };

                        return (
                          <>
                            <KpiPeriodRangeEditCellDiv
                              dataKey={dataKey}
                              selectecKpiCategorysData={selectecKpiCategorys?.data ?? {}}
                              kpiDataUpdate={kpiDataUpdate}
                              kpiCategorysIndex={kpiCategorysIndex}
                              currentyear={currentyear}
                              timeFrameType={timeFrameType}
                              timeFrameTypeKey={timeFrameTypeKey.key}
                              handlePasteUpdate={handlePasteUpdate}
                            />
                          </>
                        );
                      }
                    )
                  )}
                </>
              );
            });
          })
        )}
      </div>
    </div>
  );
};
