import React from 'react';
import './dataSourceConnection.scss';
import EditDataSourceConnection from './EditDataSourceConnection';
import { mls } from 'lib/multilanguagesupport';

interface databaseConnectionProps {
  connectionTypes: any;
  appID: string;
  selectedConnectionType: any;
  isNewConnection: boolean;

  setSelectedConnectionType: Function;
  formData: any;
  setIsNewConnection: Function;
  setFormData: Function;
  editConnectionMode: boolean;
  setIsEditConnectionMode: Function;
  tableData: any;
  setTableData: Function;
  tableConfig: any;
  selectedConnection: any;
  setTableConfig: Function;
  saveConnection: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}
const DatabaseConnection = ({
  connectionTypes,
  appID,
  selectedConnectionType,
  setSelectedConnectionType,
  formData,
  setFormData,
  editConnectionMode,
  isNewConnection,
  setIsEditConnectionMode,
  tableData,
  setTableData,
  setIsNewConnection,
  tableConfig,
  setTableConfig,
  selectedConnection,
  saveConnection,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: databaseConnectionProps) => {
  if (selectedConnectionType?.type) {
    return (
      <div className='dataSourceConnectionContainer flexCenter'>
        <EditDataSourceConnection
          connectionType={selectedConnectionType}
          formData={formData}
          setFormData={setFormData}
          appID={appID}
          editConnectionMode={editConnectionMode}
          isNewConnection={isNewConnection}
          setIsEditConnectionMode={setIsEditConnectionMode}
          tableData={tableData}
          setTableData={setTableData}
          setIsNewConnection={setIsNewConnection}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          saveConnection={saveConnection}
          fileToUploadObject={fileToUploadObject}
          setFileToUploadObject={setFileToUploadObject}
          credentialID={credentialID}
        />
      </div>
    );
  }
  return (
    <div className='dataSourceConnectionContainer'>
      <div className='dataSourceConnection'>
        {React.Children.toArray(
          Object.keys(connectionTypes).map((connectionKey) => {
            const connectionType = connectionTypes[connectionKey];
            if (connectionType.isHidden) {
              return <></>;
            }
            return (
              <div
                className='connectionCardContainer'
                onClick={() => setSelectedConnectionType(connectionType)}
              >
                <div className='connectiionCardIcon'>
                  <img src={connectionType.icon} alt='icon' />
                </div>
                <div className='connectiionCardTitle'>{mls(connectionType.name)}</div>
                <div className='connectiionCardDiscription'>
                  {mls('Establish connection with')} {mls(connectionType.name)} {mls('database')}.
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default DatabaseConnection;
