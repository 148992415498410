import arrowIcon from '../../assets/arrowIcon.svg';
import SelectChart from '../../features/SelectChart/SelectChart';
import DropDown from '../../features/DropDown/DropDown';
import { defaultChartWidthTypes } from '../../data/defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';

const WithoutStyleToggle = ({ stateProps, toggleProps, buddleProps, children }) => {
  const { chartType, chartWidth, setChartWidth } = stateProps;
  const { toggleColumnDetails, toggleMinimizeDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;
  const { settingToggleState } = buddleProps;

  return (
    <>
      <div className='staticDiv WithoutStyleToggle'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls("Build Charts")}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>
        <SelectChart stateProps={stateProps} />
        {chartType.chartKey && (
          <>
            {/* <div className='chartWidth'>
              <div className='chartWidthTitle'>Chart Width: </div>
              <div className='chartWidthDropDown'>
                <DropDown
                  array={defaultChartWidthTypes}
                  dropDownValue={chartWidth}
                  setdropDownValue={setChartWidth}
                  isKey={true}
                />
              </div>
            </div> */}
          </>
        )}
      </div>
      {chartType.chartKey && (
        <div className={`scrollableDiv WithoutStyleToggle ${settingToggleState}`}>{children}</div>
      )}
    </>
  );
};

export default WithoutStyleToggle;
