export const sortValues = (x, y, reverse = false) => {
  const l = [];
  for (let i = 0; i < x.length; i++)
    l.push({ x: x[i], y: y[i] });
  if (!reverse) {
    l.sort((a, b) => {
      return (a.x < b.x) ? -1 : (a.x === b.x) ? 0 : 1;
    });
  } else {
    l.sort((a, b) => {
      return (a.x > b.x) ? -1 : (a.x === b.x) ? 0 : 1;
    });
  }

  const sortedX = [];
  const sortedY = [];
  for (let i = 0; i < x.length; i++) {
    sortedX.push(l[i].x);
    sortedY.push(l[i].y);
  }
  return { sortedX: sortedX, sortedY: sortedY };
};