import React, { useMemo } from 'react';
import GetFields from '../../GetFileds/GetFileds';
import { defaultConnectionSchema } from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import { getfileExtensionData } from '../../GetFileds/filedsUtils';
import ConnectionForm from '../../ConnectionForm/ConnectionForm';
interface editFileContentProps {
  tableData: any;
  connectionType: any;
  formData: any;
  appID: string;
  setFormData: Function;
  editConnectionMode: boolean;
  setIsEditConnectionMode: Function;
  setTableData: Function;
  tableConfig: any;
  setTableConfig: Function;
  saveConnection: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}

const EditFileContent = ({
  tableData,
  connectionType,
  formData,
  appID,
  setFormData,
  editConnectionMode,
  setIsEditConnectionMode,
  setTableData,
  tableConfig,
  setTableConfig,
  saveConnection,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: editFileContentProps) => {
  const fileExtensionData: any = useMemo(() => {
    return getfileExtensionData({
      fileName: formData?.data?.fileLable ?? formData?.data?.fileName,
      extensionObject: connectionType.accept,
    });
  }, [formData?.data?.fileName, formData?.data?.fileLable, connectionType]);

  const FormHeader = () => (
    <>
      {/* File name:
            <b>{formData?.fileLable ?? formData?.fileName}</b>
            <span>
              <img src={fileExtensionData?.icon} alt='' />
            </span> */}
      <span>
        <img src={fileExtensionData?.icon} alt='' />
      </span>
      <b>{formData.connectionName}</b>
      <b>({connectionType.name})</b>
    </>
  );
  return (
    <div className='dataSourceConnectionContainer flexCenter'>
      <ConnectionForm
        connectionType={connectionType}
        formData={formData}
        setFormData={setFormData}
        appID={appID}
        editConnectionMode={editConnectionMode}
        isNewConnection={false}
        setIsEditConnectionMode={setIsEditConnectionMode}
        tableData={tableData}
        setTableData={setTableData}
        tableConfig={tableConfig}
        setTableConfig={setTableConfig}
        saveConnection={saveConnection}
        fileToUploadObject={fileToUploadObject}
        setFileToUploadObject={setFileToUploadObject}
        FormHeader={FormHeader}
        credentialID={credentialID}
      />
    </div>
  );
};

export default EditFileContent;
