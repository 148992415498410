import React from 'react';
import { allDropableID, defaultChartSetting } from '../../../data/defaultChartSchema';
import DragableAxis from '../../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
import DragableToolTip from '../../../components/BuildComponents/DragableToolTip';
import EditColors from '../../../components/StyleComponents/ChartDisplay/EditColors';
import SolidColorSelector from 'lib/chart-settings-lib/components/StyleComponents/SolidColorSelector';
import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

interface TreeMapCaseProps {
  stateProps: any;
}
const TreeMapCase = ({ stateProps }: TreeMapCaseProps) => {
  const {
    isInsight,
    setIsInsight,
    chartType,
    yAxisData,
    setYAxisData,
    setChartYAxisStyle,
    xAxisData,
    setXAxisData,
    setChartXAxisStyle,
    handleResetToolTip,
    treeMapColor,
    setTreeMapColor,
    toolTipAxisData,
    setToolTipAxisData,
  } = stateProps;

  const { xAxisId, yAxisId, toolTip, tooltipAxisId } = allDropableID;

  const handleMaxColor = (action: any) => {
    setTreeMapColor((currentColor: any) => ({ ...currentColor, max: action }));
  };
  const handleMinColor = (action: any) => {
    setTreeMapColor((currentColor: any) => ({ ...currentColor, min: action }));
  };
  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('X-Axis')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Tooltips')}</div>

        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      {/* <div className='outerDiv'>
        <div className='MainTitle'>{mls('Extra Tooltip')} *</div>
        <DragableAxis
          dragableDivId={tooltipAxisId}
          axisData={toolTipAxisData}
          setAxisData={setToolTipAxisData}
          stateProps={stateProps}
          setStyle={() => {}}
          defaultLable={defaultChartSetting.yAxisStyle.label}
        />
      </div> */}
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Color')}</div>
        <div className='grayContainers CardHeaderImage'>
          <div className='containerItems'>
            <SolidColorSelector
              color={treeMapColor.max}
              setColor={handleMaxColor}
              isPalette={false}
              selectColorTitle={mls('Select Max Color')}
            />
            <SolidColorSelector
              color={treeMapColor.min}
              setColor={handleMinColor}
              isPalette={false}
              selectColorTitle={mls('Select Min Color')}
            />
          </div>
        </div>
      </div>
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>
      {/* <div className='outerDiv '>
        <div className='MainTitle inFlex'>
          Date hierarchy
          <SlideSwitch isChecked={isDateHierarchy} setIsChecked={setIsDateHierarchy} />
        </div>
      </div> */}
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default TreeMapCase;
