export const charactersNotAllowedInTableAndColumnName = [
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '.',
  '/',
  '@',
  '^',
  '=',
  ';',
  ':',
  '{',
  '}',
  '[',
  ']',
];

export const connectionTypes = {
  __d3__postgres: 'PostgreSQL',
  __d3__mongoDB: 'MongoDB',
  __d3__mysql: 'MySQL',
  __d3__googleSheets: 'Google Sheets',
  __d3__mssql: 'Microsoft SQL (Azure DB)',
  __d3__excelOnedrive: 'Excel file on Onedrive',
  __d3__ExcelCSVUpload: 'Excel file',
};

export const intervals = {
  1: '1 hour',
  3: '3 hours',
  9: '9 hours',
  12: '12 hours',
  18: '18 hours',
  24: '1 day',
};

export const dataTableColumnObjectTypes = {
  text: { name: 'Text', key: 'text' },
  number: { name: 'Number', key: 'number' },
  decimal: { name: 'Decimal', key: 'decimal' },
  boolean: { name: 'Boolean', key: 'boolean' },
  check: { name: 'Checkbox', key: 'check' },
  select: { name: 'Categorical', key: 'select' },
  date: { name: 'Date', key: 'date' },
  id_text: { name: 'Text ID Field', key: 'id_text' },
  id_num: { name: 'Num ID Field', key: 'id_num' },
  // multiple: { name: 'Multiple', key: 'multiple' },
};
export const dataTableColumnTypes = {
  [dataTableColumnObjectTypes.text.key]: [dataTableColumnObjectTypes.text.name],
  [dataTableColumnObjectTypes.number.key]: [dataTableColumnObjectTypes.number.name],
  [dataTableColumnObjectTypes.decimal.key]: [dataTableColumnObjectTypes.decimal.name],
  [dataTableColumnObjectTypes.boolean.key]: [dataTableColumnObjectTypes.boolean.name],
  [dataTableColumnObjectTypes.check.key]: [dataTableColumnObjectTypes.check.name],
  [dataTableColumnObjectTypes.select.key]: [dataTableColumnObjectTypes.select.name],
  [dataTableColumnObjectTypes.date.key]: [dataTableColumnObjectTypes.date.name],
  [dataTableColumnObjectTypes.id_text.key]: [dataTableColumnObjectTypes.id_text.name],
  [dataTableColumnObjectTypes.id_num.key]: [dataTableColumnObjectTypes.id_num.name],
  // [dataTableColumnObjectTypes.multiple.key]: [dataTableColumnObjectTypes.multiple.name],
};
