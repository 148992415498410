import React, { useEffect, useRef, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
import { errorMessage, startingMessage } from '../data/constants';
import ChatInput from './ChatInput/ChatInput';
import Messages from './Messages/Messages';

import { supistaApiPost } from '../utils/connections';
import { convertToTimeFrameObject } from '../utils/chartHelperFunction';
import { defaultSelectedTimeframe } from '../data/defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';
import { addDefaultDataInChart } from 'lib/chart-settings-lib/utils/chartPopulateFunction';
const ChatArea = ({
  appID,
  allTableIDAndNamesMap,
  tableData,
  isTablePopUp,
  setIsTablePopUp,
  handleChartPopUp,
  selectedTable,
  setSelectedTable,
  messages,
  setMessages,
  appDash,
  setDashboards,
  tableSchema,
  handleChartFilter,
  sheetID,
  allTables,
  appSheets,
  setAllTables,
  updateReduxSchema,
  updateReduxSheetSchema,
  updateSheet,
  stateProps,
}) => {
  const [message, setMessage] = useState('');
  const containerRef = useRef(null);
  const bottomRef = useRef(null);
  const { width: mainContainerWidth } = useDivDimensions(containerRef);
  const [isLoading, setIsLoading] = useState(false);
  const chartHeight =
    mainContainerWidth <= 576
      ? mainContainerWidth // sm
      : mainContainerWidth <= 992
      ? mainContainerWidth / 2 // md
      : mainContainerWidth / 3; // lg

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsTablePopUp(false);
    const newObj = { message };
    const isSurprise = message.trim() === '' ? true : false;

    newObj['message'] = isSurprise ? mls('Surprise me') : message;
    setMessage('');
    setMessages((prev) => [...prev, { message: newObj.message, sender: 'user' }]);
    setIsLoading(true);
    if (!selectedTable) {
      newObj['sender'] = 'supistaAI';
      newObj['error'] = true;
      newObj['tableSelectionError'] = true;
      newObj['message'] = mls(errorMessage['noTableSelected']);
      setMessages((prev) => [...prev, newObj]);
      setIsLoading(false);
      return selectedTable;
    }
    let convertedTimeFrame;
    if (tableSchema?.defaultTable?.defaultDatetimeCol) {
      const timeFrameObjectForConvert = {
        defaultDateTimeCol: tableSchema?.defaultTable?.defaultDatetimeCol,
        action: defaultSelectedTimeframe,
        tableData: tableSchema?.data,
      };
      convertedTimeFrame = convertToTimeFrameObject(timeFrameObjectForConvert);
      if (convertedTimeFrame.skip === null) {
        return;
      }
    }
    const data = await supistaApiPost(
      'chatAI',
      {
        appID: appID,
        query: message,
        tableName: selectedTable,
        sheetID,
        isSurprise,
        defaultTable: tableSchema?.defaultTable || {},
        timeFrame: convertedTimeFrame?.timeFrame,
      },
      appID
    );

    setIsLoading(false);
    if (!data.__d3__error) {
      newObj['sender'] = 'supistaAI';
      const newChartData = addDefaultDataInChart({
        chartData: data.response,
        table: selectedTable,
        tableSchema: tableSchema?.data,
        sheetID: sheetID,
      });
      newObj['response'] = newChartData;
      newObj['table'] = selectedTable;
      // TimeFrame
      const dateTimeCols =
        tableSchema?.defaultTable?.defaultDatetimeCol ??
        Object.keys(tableSchema?.defaultTable?.datetimeCols ?? {})?.[0] ??
        null;

      if (dateTimeCols !== null) {
        const tableData = tableSchema?.data;
        const timeFrameObjectForConvert = {
          defaultDateTimeCol: dateTimeCols,
          action: defaultSelectedTimeframe,
          tableData,
        };
        const convertedTimeFrame = convertToTimeFrameObject(timeFrameObjectForConvert);
        if (convertedTimeFrame.skip !== null) {
          newObj['timeFrame'] = convertedTimeFrame.timeFrame;
        }
      }
    } else {
      newObj['sender'] = 'supistaAI';
      newObj['error'] = true;
      newObj['table'] = selectedTable;
      newObj['message'] = mls(errorMessage['noData']);
    }
    setMessages((prev) => [...prev, newObj]);

    return selectedTable;
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [messages]);

  useEffect(() => {
    const timers = [];
    startingMessage.forEach((msg, i) => {
      const timer = setTimeout(() => {
        setMessages((prev) => [...prev, { message: mls(msg), sender: 'bot' }]);
        // setShow((prev) => [...prev, msg]);
      }, 500 * i);
      timers.push(timer);
    });

    return () => {
      timers.forEach((timer) => {
        clearTimeout(timer);
      });
    };
  }, []);
  return (
    <Box ref={containerRef} className='chatArea introjs-chatarea'>
      <Messages
        messages={messages}
        chartHeight={chartHeight}
        tableSchema={tableSchema}
        mainContainerWidth={mainContainerWidth}
        handleChartPopUp={handleChartPopUp}
        appDash={appDash}
        setDashboards={setDashboards}
        appID={appID}
        handleChartFilter={handleChartFilter}
        sheetID={sheetID}
        allTables={allTables}
        setMessages={setMessages}
        updateReduxSchema={updateReduxSchema}
        stateProps={stateProps}
        allTableIDAndNamesMap={allTableIDAndNamesMap}
      />
      {isLoading && <ChartSkeleton height={chartHeight} />}
      <div ref={bottomRef} />
      <ChatInput
        isTablePopUp={isTablePopUp}
        setIsTablePopUp={setIsTablePopUp}
        submitHandler={submitHandler}
        mainContainerWidth={mainContainerWidth}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        tableData={tableData}
        allTableIDAndNamesMap={allTableIDAndNamesMap}
        message={message}
        setMessage={setMessage}
        setMessages={setMessages}
        setIsLoading={setIsLoading}
        appID={appID}
        tableSchema={tableSchema}
        allTables={allTables}
        appSheets={appSheets}
        setAllTables={setAllTables}
        updateSheet={updateSheet}
        stateProps={stateProps}
        updateReduxSheetSchema={updateReduxSheetSchema}
      />
    </Box>
  );
};

export default ChatArea;

const ChartSkeleton = ({ height }) => (
  <Box className='aiMessages' sx={{ width: '50%' }}>
    <div className='logoDiv'>
      <img alt='Logo' className='logo' src='https://www.supista.com/assets/img/logo.png' />
    </div>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Box sx={{ width: '100%' }}>
        <Skeleton width='100%'>
          <Typography variant='h4'>.</Typography>
        </Skeleton>
      </Box>
    </Box>
    <Skeleton variant='rectangular' width='100%'>
      <div style={{ paddingTop: '57%' }} />
    </Skeleton>
  </Box>
);
