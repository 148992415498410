import { allChartKeys, allChartTypesObj } from '../reusableData/chartTypesSchema';
import { defaultLegendColorPalettes } from '../reusableData/defaultChartSchema';

interface getGroupDataByChartProps {
  stateProps: any;
  chartType: any;
}
export const getGroupDataByChart = ({ stateProps, chartType }: getGroupDataByChartProps) => {
  const {
    //PLOT
    plotGroup,
    setPlotGroup,
    setPlotGroupStyle,

    //HEATMAP
    heatMapGroup,
    setHeatMapGroup,

    //COLOR
    colorAxis,
    setColorAxis,
    setColorAxisStyle,
  } = stateProps;

  //PLOT
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return {
      key: 'plotGroup',
      data: plotGroup,
      setData: setPlotGroup,
      setStyleData: setPlotGroupStyle,
    };
  }

  //HEATMAP
  if (chartType.chartKey === allChartKeys.HEATMAP) {
    return {
      key: 'heatMapGroup',
      data: heatMapGroup,
      setData: setHeatMapGroup,
      setStyleData: setPlotGroupStyle,
    };
  }

  //COLOR
  if (
    chartType.chartKey === allChartKeys.BARCOLOR ||
    chartType.chartKey === allChartKeys.LINECOLOR ||
    chartType.chartKey === allChartKeys.AREACOLOR
  ) {
    return {
      key: 'colorAxis',
      data: colorAxis,
      setData: setColorAxis,
      setStyleData: setColorAxisStyle,
    };
  }
  //Default
  return {
    key: 'colorAxis',
    data: colorAxis,
    setData: setColorAxis,
    setStyleData: setColorAxisStyle,
  };
};
export const getUniueValuesFunction = (props: any) => {
  const { uniqueValues, selectedItem, defaultShow = true, axis } = props;
  if (!uniqueValues) {
    return [];
  }
  const newData = uniqueValues.map((uniqueValues: any) => {
    const newUniqueValues = (elem: any) => {
      switch (elem) {
        case null:
          return 'null';
        case undefined:
          return 'undefined';

        case true:
          return 'true';

        case false:
          return 'false';

        default:
          return uniqueValues;
      }
    };
    const newElem = {
      label: newUniqueValues(uniqueValues),
      originalLabel: newUniqueValues(uniqueValues),
      origin: selectedItem.label,
      show: defaultShow,
      column: selectedItem.column,
      uniqueColumnName: selectedItem.uniqueColumnName,
      axis,
    };
    return newElem;
  });
  return newData;
};

export const setAutomaticColor = (props: any) => {
  const { target, defaultOpacity = 100 } = props;
  let lengthNumber = 0;

  const legendColorPalettesLength = defaultLegendColorPalettes.length;
  const newTargetWithColors = target.map((elem: any) => {
    if (lengthNumber >= legendColorPalettesLength - 1) {
      lengthNumber = 0;
    } else {
      lengthNumber++;
    }
    const newElem = {
      ...elem,
      color: defaultLegendColorPalettes[lengthNumber],
      opacity: defaultOpacity,
    };
    return newElem;
  });

  return newTargetWithColors;
};

interface addDefaultDataInChartProps {
  chartData: any;
  tableSchema: any;
  table: string;
  sheetID: string;
}

interface chartFilterFromAnalyticsFunctionProps {
  search: string;
  allAppSheet: { [key: string]: any };
  setProjectSheets: Function;
  chartsData: { [key: string]: any };
}
export const chartFilterFromAnalyticsFunction = (props: chartFilterFromAnalyticsFunctionProps) => {
  const { search, allAppSheet, setProjectSheets, chartsData } = props;
  const lowerCaseValue = (search ?? '').toLowerCase();
  let filteredSheets: { [key: string]: any } = {};
  Object.keys(allAppSheet).forEach((sheetKey) => {
    const sheetElem = allAppSheet[sheetKey];
    const lowerCaseElemName = (sheetElem.name ?? '').toLowerCase();
    if (lowerCaseElemName.includes(lowerCaseValue)) {
      filteredSheets = { ...filteredSheets, [sheetKey]: sheetElem };
    } else {
      const sheetCharts = (sheetElem?.charts ?? []).filter((chartKey: string) => {
        const chartElem: { name: string; chartType: string } = chartsData[chartKey] || {};
        const lowerCaseElemName = (chartElem?.name ?? '').toLowerCase();
        const lowerCaseElemChartTypeKey = (chartElem?.chartType ?? '').toLowerCase();
        const lowerCaseElemChartTypeName = (
          allChartTypesObj[(chartElem?.chartType ?? '') as keyof typeof allChartTypesObj]?.name ??
          ''
        ).toLowerCase();
        return (
          lowerCaseElemName.includes(lowerCaseValue) ||
          lowerCaseElemChartTypeKey.includes(lowerCaseValue) ||
          lowerCaseElemChartTypeName.includes(lowerCaseValue)
        );
      });
      if (sheetCharts.length >= 1) {
        filteredSheets = { ...filteredSheets, [sheetKey]: { ...sheetElem, charts: sheetCharts } };
      }
    }
  });
  setProjectSheets(filteredSheets);
};
