import React, { useState } from 'react';
import './circleWiseTargetPoints.scss';
import KpiProjectedTargetPoints from 'lib/supista-kpi/kpiTimeFrame/KpiProjectedTargetPoints';
import { toast } from 'react-toastify';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import AddAllTargetPoint from './AddAllTargetPoint';
import AllTargetPoint from './AllTargetPoint';
import { mls } from 'lib/multilanguagesupport';

const AllTargetPointsContainer = ({
  selectedKpiTimeFrameRange,
  stateProps,
}: {
  selectedKpiTimeFrameRange: string;
  stateProps: any;
}) => {
  const [isKpiProjectedTargetPoints, setIsKpiProjectedTargetPoints] = useState(false);
  const [filterPopupData, setFilterPopupData] = useState({
    isPopup: false,
    targetFilter: {},
    filterIndex: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const {
    kpiProjectedTargetPoints,
    setKpiProjectedTargetPoints,
    kpiTargetPoints,
    setKpiTargetPoints,
    kpiTimePeriodRange,
    setKpiTimePeriodRange,
    isProjectedTargetPointCreated,
    setIsProjectedTargetPointCreated,
  } = stateProps;

  const { allTableData, appID, isJoinedTable, sheetID, chartID } = stateProps;

  const handleAllTargetPoint = ({
    data,
    key,
    index,
  }: {
    data: any;
    key: string;
    index: number;
  }) => {
    const copyKpiCategory = Array.from(kpiTargetPoints.allTargetPoints ?? []);
    const [selectedItem]: any = copyKpiCategory.splice(index, 1);
    const updatedData = {
      ...(selectedItem ?? {}),
      [key]: data,
    };
    copyKpiCategory.splice(index, 0, updatedData);

    setKpiTargetPoints({
      ...kpiTargetPoints,
      allTargetPoints: copyKpiCategory,
      selectedTargetPointID: updatedData.targetPointID,
    });
  };
  const handleFilterClose = () => {
    setFilterPopupData({
      isPopup: false,
      targetFilter: {},
      filterIndex: 0,
    });
  };
  const handleFilter = (action: { [key: string]: any }) => {
    setFilterPopupData({
      ...filterPopupData,
      targetFilter: action,
    });
    handleAllTargetPoint({
      data: action,
      key: 'targetFilter',
      index: filterPopupData.filterIndex,
    });
  };
  const disableCirclesDataArray = (kpiTargetPoints?.allTargetPoints ?? []).map(
    (allTargetPointsElem: any) => {
      return allTargetPointsElem?.targetPointID?.id;
    }
  );
  const handleKpiProjectedTargetPointsSave = async ({
    isSave,
    data,
    timePeriodRange,
  }: {
    isSave: boolean;
    data: { [key: string]: any };
    timePeriodRange: { [key: string]: any };
  }) => {
    if (isSave) {
      setIsSaving(true);
      const endPoint = isProjectedTargetPointCreated ? 'update' : 'create';
      const payloadData = isProjectedTargetPointCreated
        ? {
            query: { chartID: chartID },
            new_values: { chartID: chartID, timePeriodRange: timePeriodRange, data: data },
          }
        : {
            chartID: chartID,
            timePeriodRange: timePeriodRange,
            data: data,
          };
      const res = await _supistaAnalyticsApi(
        `${appID}/kpiSettings/KpiTargetPoints/${endPoint}`,
        payloadData
      )
        .then(async (response) => {
          if (response.__d3__error) {
            return { skip: true, data: [], msg: response?.response?.msg };
          } else {
            return { skip: false, data: response?.response?.data ?? [], msg: undefined };
          }
        })
        .catch((error) => {
          return { skip: true, data: [], msg: undefined };
        });
      if (res.skip) {
        toast.error('Error encounter while saving!');
        setIsSaving(false);
      } else {
        setKpiProjectedTargetPoints(data);
        setKpiTimePeriodRange(timePeriodRange);
        setIsSaving(false);
        setIsProjectedTargetPointCreated(true);
      }
    }
    setIsKpiProjectedTargetPoints(false);
  };
  return (
    <div className='kpiChartCircleWiseTargetPointTable'>
      {isKpiProjectedTargetPoints ? (
        <KpiProjectedTargetPoints
          isKpiProjectedTargetPoints={isKpiProjectedTargetPoints}
          handleSave={handleKpiProjectedTargetPointsSave}
          selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
          projectedTargetPoints={kpiProjectedTargetPoints}
          kpiTimePeriodRange={kpiTimePeriodRange}
          setProjectedTargetPoints={setKpiProjectedTargetPoints}
          isSaving={isSaving}
        />
      ) : null}
      {filterPopupData.isPopup ? (
        <FilterDiv
          isPopUp={filterPopupData.isPopup}
          setIsPopUp={handleFilterClose}
          chartFilter={filterPopupData.targetFilter}
          setChartFilter={handleFilter}
          allTableData={allTableData}
          appID={appID}
          isJoinedTable={isJoinedTable}
          sheetID={sheetID}
        />
      ) : null}
      <div className='kpiChartCircleWiseTargetPointTableTitleContainer'>
        <div className='titleDiv'>{mls('All Target Points')}</div>
        <button
          className='primaryButton secondaryButtonColor'
          onClick={() => setIsKpiProjectedTargetPoints(true)}
        >
          {mls('Projected Target Points')}
        </button>
      </div>
      <div className='kpiChartCircleWiseTargetPointTableContainer'>
        <div className='kpiChartCircleWiseTargetPointTableInnerContainer'>
          <div className='circleWiseTargetPointTableHeader'>
            <div className='selectCellDiv'></div>
            <div className='circleCellDiv'>&nbsp;{mls('Circle/Employees')}</div>
            <div className='targetPoinCellDiv'>&nbsp;{mls('Target Point')}</div>
            <div className='filterCellDiv'>&nbsp;{mls('Filters')}</div>
            <div className='removeCellDiv'></div>
          </div>
          <div className='circleWiseTargetPointTableElemContainer'>
            <AddAllTargetPoint
              selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
              stateProps={stateProps}
              disableCirclesDataArray={disableCirclesDataArray}
            />
            {React.Children.toArray(
              (kpiTargetPoints?.allTargetPoints ?? []).map(
                (allTargetPointsElem: any, allTargetPointsIndex: number) => {
                  return (
                    <AllTargetPoint
                      stateProps={stateProps}
                      selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
                      allTargetPointsElem={allTargetPointsElem}
                      allTargetPointsIndex={allTargetPointsIndex}
                      setFilterPopupData={setFilterPopupData}
                      handleAllTargetPoint={handleAllTargetPoint}
                      disableCirclesDataArray={disableCirclesDataArray}
                    />
                  );
                }
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTargetPointsContainer;
