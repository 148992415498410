/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '_metronic/helpers';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import { mls } from 'lib/multilanguagesupport';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

const TableWisePermission = ({ appID, newTeamSchema, setTeamSchema }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const dataTable = appSchema?.appDatatable || {};

  const [tableLists, setTableLists] = useState(newTeamSchema?.TABLESLIST || {});
  const [clicked, setClick] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [allTableSchema, setTableSchema] = useState([]);
  const [chartFilter, setChartFilter] = useState({});
  const [selectedTableID, setSelectedTable] = useState(null);

  const toggleModal = (event) => {
    setClick(!clicked);
  };
  const handleCheckboxChange = (tableID, isChecked) => {
    const updatedTablesList = { ...tableLists };
    if (!isChecked) {
      Object.keys(tableLists).forEach((id) => {
        if (id === tableID && !isChecked) {
          delete updatedTablesList[id];
          return;
        }
      });
    } else if (isChecked) {
      updatedTablesList[tableID] = { filers: {} };
    }
    setTableLists(updatedTablesList);
    setTeamSchema({ ...newTeamSchema, TABLESLIST: updatedTablesList });
  };
  const handleFilterButton = async (tableID) => {
    setChartFilter(newTeamSchema?.TABLESLIST?.[tableID]?.filters || {});
    setOpenFilter(true);
    setSelectedTable(tableID);
  };

  useEffect(() => {
    Object.keys(tableLists).forEach(async (tableID) => {
      if (!allTableSchema[tableID]) {
        const res = await _supistaAnalyticsApi(`${appID}/tableHead`, {
          appID: appID,
          tableID: tableID,
          sheetID: '__d3__newRoleSheetID',
          newSheetID: true,
          disableCustomColumns: false,
        });
        if (!res.__d3__error) {
          if (res.status === 200) {
            setTableSchema({
              ...allTableSchema,
              [tableID]: res?.response?.tableSchema?.data || [],
            });
          }
        }
      }
    });
  }, [allTableSchema, appID, tableLists]);

  useEffect(() => {
    if (Object.keys(tableLists || {}).length > 0 && selectedTableID) {
      const newTableLists = { ...tableLists, [selectedTableID]: { filters: chartFilter } };
      setTableLists(newTableLists);
      setTeamSchema({ ...newTeamSchema, TABLESLIST: newTableLists });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartFilter]);

  return (
    <>
      <button className='btn btn-sm btn-light-primary mb-5' onClick={toggleModal}>
        {mls('Add Tables')}
      </button>

      <div className='table-responsive'>
        {/* begin::Table */}
        <table
          className='table table-rounded table-row-bordered align-middle gs-0 gy-4'
          style={{ display: 'block', maxHeight: '400px', overflow: 'auto' }}
        >
          {/* begin::Table head */}
          <thead style={{ position: 'sticky', top: 0 }}>
            <tr className='fw-bolder text-muted bg-light'>
              <th className='ps-4 min-w-325px rounded-start'>{mls('Tables Permitted')}</th>
              <th className='min-w-125px'>{mls('Filters')}</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody style={{ borderTop: '0px' }}>
            {Object.keys(tableLists).map((tableID) => (
              <tr key={tableID}>
                <td>
                  <div className='d-flex ms-4 align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold mb-1 fs-7'>
                        {dataTable[tableID]?.name || tableID}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                    <button
                      className='btn btn-sm btn-light-primary'
                      onClick={() => handleFilterButton(tableID)}
                    >
                      {mls('Apply Filter')}
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>

        {/* end::Table */}
      </div>

      {clicked && (
        <Modal show={clicked} onHide={toggleModal} centered size='lg' id='AllTablessList'>
          <div className='modal-content rounded'>
            <div className='modal-header border-0 pb-0'>
              <div className='modal-title'>
                <h2 className=''>{mls('All Data Tables List')}</h2>
                <div className='text-muted fw-bold fs-5'>
                  {mls(`Select the tables to grant permissions`)}
                </div>
              </div>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                onClick={toggleModal}
                data-bs-dismiss='modal'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>

            <div className='modal-body pt-0 pb-5 px-10'>
              <div className='d-flex flex-column'>
                <div className='row mt-5'>
                  <div className='flex-row-fluid py-5 px-5'>
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-rounded table-row-bordered align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bolder text-muted bg-light'>
                            <th className='ps-4 min-w-100px rounded-start'>{mls('Select')}</th>

                            <th className='min-w-125px'>{mls('Table Name')}</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody style={{ borderTop: '0px' }}>
                          {Object.keys(dataTable).map((tableID) => (
                            <tr key={tableID}>
                              <td>
                                <div className='form-check form-check-custom  mx-5'>
                                  <input
                                    className='form-check-input'
                                    style={{ borderWidth: '2px' }}
                                    type='checkbox'
                                    checked={Object.keys(tableLists).some((id) => id === tableID)}
                                    onChange={(e) =>
                                      handleCheckboxChange(tableID, e.target.checked)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='d-flex ms-4 align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a
                                      href='#'
                                      className='text-dark fw-bold text-hover-primary mb-1 fs-7'
                                    >
                                      {dataTable[tableID]?.name || ''}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* end::Table body */}
                      </table>

                      {/* end::Table */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {openFilter && (
        <FilterDiv
          setChartFilter={setChartFilter}
          allTableData={allTableSchema[selectedTableID] || []}
          chartFilter={chartFilter}
          appID={appID}
          isJoinedTable={false}
          sheetID={''}
          isPopUp={openFilter}
          setIsPopUp={setOpenFilter}
          isDashboard={false}
        />
      )}
    </>
  );
};
export default TableWisePermission;
