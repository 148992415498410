import React, { useEffect, useRef, useState } from 'react';
import { defaultDashboardSchema } from '../../data/defaultSchema';
import FontSelector from '../../features/fontSelector/FontSelector';
import SolidColorSelector from '../../features/solidColorSelector/SolidColorSelector';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { mls } from 'lib/multilanguagesupport';

const SectionHeader = ({
  elem,
  headingIndex,
  stateProps,
  sectionIndex,
  show = false,
  autoCloseOff = false,
}: any) => {
  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const { setSections } = stateProps;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const addHeader = () => {
    setSections((currentData: any) => {
      const duplicateCurrentData: any[] = Array.from(currentData);
      // remove Targeted
      const [targetData] = duplicateCurrentData.splice(sectionIndex, 1);
      const newTargetData = {
        ...targetData,
        headings: [...targetData.headings, defaultDashboardSchema.sections.headings[0]],
      };
      duplicateCurrentData.splice(sectionIndex, 0, newTargetData);
      return duplicateCurrentData;
    });
  };
  const removeHeader = () => {
    setSections((currentData: any) => {
      const duplicateCurrentData: any[] = Array.from(currentData);
      const duplicateDataHeadings: any[] = Array.from(currentData[sectionIndex].headings);

      // remove Targeted
      const [targetData] = duplicateCurrentData.splice(sectionIndex, 1);

      // remove Heading
      duplicateDataHeadings.splice(headingIndex, 1);
      const newTargetData = {
        ...targetData,
        headings: duplicateDataHeadings,
      };
      duplicateCurrentData.splice(sectionIndex, 0, newTargetData);
      return duplicateCurrentData;
    });
  };

  const handleInput = (newElem: any) => {
    setSections((currentData: any) => {
      const duplicateCurrentData: any[] = Array.from(currentData);
      const duplicateDataHeadings: any[] = Array.from(currentData[sectionIndex].headings);

      // remove Targeted
      const [targetData] = duplicateCurrentData.splice(sectionIndex, 1);
      duplicateDataHeadings.splice(headingIndex, 1);

      // update Targeted HeadingArray
      duplicateDataHeadings.splice(headingIndex, 0, newElem);
      const newTargetData = {
        ...targetData,
        headings: duplicateDataHeadings,
      };
      duplicateCurrentData.splice(sectionIndex, 0, newTargetData);

      return duplicateCurrentData;
    });
  };
  const handleText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newElem = {
      ...elem,
      text: e.target.value,
    };
    handleInput(newElem);
  };
  const handleFontStyle = (elem: any) => {
    handleInput(elem);
  };
  const handleColor = (elem: any) => {
    handleInput(elem);
  };

  const handleToggleStyle = () => {
    setisDivOpen((currentData: boolean) => !currentData);
  };

  useUpdateEffect(() => {
    if (autoCloseOff) {
      return;
    }
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // onClose && onClose();
        setTimeout(() => setisDivOpen(false), 10);
      }
    }

    if (isDivOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isDivOpen]);
  useEffect(() => {
    if (isDivOpen) {
      setisContent(isDivOpen);
    } else {
      setTimeout(() => {
        setisContent(isDivOpen);
        //  setAxisStyle(defaultChartSetting.xAxisStyle);
        //  updateAxiesStyleLable(setAxisStyle, axiesData, defaultLable);
      }, 300);
    }
  }, [isDivOpen]);
  return (
    <>
      <div className='headerDiv'>
        {headingIndex === 0 ? (
          <div className='addHeader primaryColor' onClick={() => addHeader()}>
            +
          </div>
        ) : (
          <div className='addHeader secondaryColor' onClick={() => removeHeader()}>
            -
          </div>
        )}
        <div className='  headerTitle'>{mls('Header')} :</div>
        <div className='headerInput'>
          <input
            type='text'
            value={elem.text}
            className='primaryInput'
            onChange={(e) => handleText(e)}
          />
        </div>
        <div className='headerStyle' ref={wrapperRef}>
          <div className='primaryButton' onClick={() => handleToggleStyle()}>
            {mls('Style')}
          </div>
          <div className={isDivOpen ? 'editHeaderStyleOuterDiv open' : 'editHeaderStyleOuterDiv'}>
            {isContent ? (
              <>
                <FontSelector fontStyle={elem} setFontStyle={handleFontStyle} />
                <SolidColorSelector color={elem} setColor={handleColor} />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHeader;
