// import { fetchDataToPlot } from 'app/network/apiConnections';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import useDownloadTableSchema from 'lib/reusable-components/reusableUtils/commons/useDownloadTableSchema';
import { mls } from 'lib/multilanguagesupport';
import { useDispatch } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { v4 as uuid } from 'uuid';
import useAutoGenerateNewCharts from './useAutoGenerateNewCharts';
import { _fetchDataToPlot } from 'lib/server-connection/connections';

const useAddNewSheets = () => {
  const dispatch = useDispatch();
  const updateSchema = useUpdateSchema();
  const autoGenerateCharts = useAutoGenerateNewCharts();
  // default Table is for Join Table
  return async ({
    appID,
    credentialID,
    tableName,
    tableID,
    dbType,
    defaultDatetimeColumnName,
    description,
    successMesage,
    defaultTable,
    datetimeCols,
  }) => {
    const sheetID = uuid();
    const currentTimestamp = Date.now();
    const newdefaultTable = defaultTable || {
      datetimeCols,
      description: description || '',
      tableID,
      name: tableName,
      credentialID,
      dbType,
      defaultDatetimeCol: defaultDatetimeColumnName,
      _id: appID,
      sheetID,
      tableType: 'TABLE',
      tables: [],
    };
    newdefaultTable.sheetID = sheetID;
    const sheetData = {
      defaultTable: newdefaultTable,
      name: tableName,
      appID,
      sheetID,
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };
    try {
      const res = await _fetchDataToPlot(sheetData, `${appID}/SheetSettings/create`);
      if (!res.__d3__error) {
        // adding newly created sheet in redux
        const schemaLocation = getSchemaLocation(schemaConstants.type.SHEET);
        updateSchema(schemaLocation, sheetID, sheetData);

        await autoGenerateCharts({ appID, defaultTable: newdefaultTable, sheetID });
        dispatch(
          create({
            setPath: reduxConstants.config.APP_STATUS.TOAST,
            value: {
              message: `${mls(successMesage)}`,
              time: 5,
              isActive: true,
            },
          })
        );
        return { skip: false, sheetID };
      }
    } catch (err) {}
    return { skip: true, sheetID };
  };
};
export default useAddNewSheets;
