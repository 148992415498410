import React from 'react';
import { defaultChartSetting } from '../../../data/defaultChartSchema';
import EditColors from '../../../components/StyleComponents/ChartDisplay/EditColors';
import RadarAxisStyle from '../../../components/SpacialCasesComponents/Radar/RadarAxisStyle';

const RadarStyleCase = ({ stateProps }) => {
  const { yAxisData, setChartYAxisStyle, chartYAxisStyle } = stateProps;

  return (
    <>
      <RadarAxisStyle
        axisStyle={chartYAxisStyle}
        setAxisStyle={setChartYAxisStyle}
        axisData={yAxisData}
        defaultLable={defaultChartSetting.xAxisStyle.label}
      />
      <EditColors stateProps={stateProps} />
    </>
  );
};

export default RadarStyleCase;
