import React from 'react';
import { IconButton } from '@mui/material';
import { RiTableFill } from 'react-icons/ri';
import SendIcon from '@mui/icons-material/Send';
import './chatInput.scss';
import { errorMessage } from '../../data/constants';
import TablePopUp from '../TablePopUp/TablePopUp';
import { getTableSchema } from '../../utils/chatBotHelperFunction';
import { supistaApiPost } from '../../utils/connections';
import { ImMagicWand } from 'react-icons/im';
import { defaultSelectedTimeframe } from '../../data/defaultChartSchema';
import { convertToTimeFrameObject } from '../../utils/chartHelperFunction';
import { mls } from 'lib/multilanguagesupport';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { addDefaultDataInChart } from 'lib/chart-settings-lib/utils/chartPopulateFunction';
interface mainProps {
  submitHandler: React.FormEventHandler<HTMLFormElement>;
  mainContainerWidth: number;
  selectedTable: string;
  setSelectedTable: Function;
  isTablePopUp: boolean;
  setIsTablePopUp: Function;
  tableData: any;
  message: string;
  setMessage: Function;
  allTableIDAndNamesMap: any;
  tableSchema: any;
  allTables: any;
  setMessages: Function;
  setIsLoading: Function;
  appID: string;
  appSheets: any;
  setAllTables: Function;
  updateSheet: Function;
  stateProps: any;
  updateReduxSheetSchema: Function;
}
// ;
const ChatInput = (mainProps: mainProps) => {
  const {
    submitHandler,
    mainContainerWidth,
    selectedTable,
    setSelectedTable,
    tableData,
    isTablePopUp,
    setIsTablePopUp,
    message,
    setMessage,
    allTableIDAndNamesMap,
    setMessages,
    setIsLoading,
    appID,
    allTables,
    appSheets,
    setAllTables,
    updateSheet,
    stateProps,
    updateReduxSheetSchema,
  } = mainProps;
  const { tableLoading, tablesObject } = stateProps;
  const isSubmitDisabled = message.trim() === '';
  const isSelectedTableDisabled = selectedTable.trim() === '';
  const handleTablePopUp = () => {
    if (isSelectedTableDisabled) {
      return;
    }
    setIsTablePopUp((currentValue: boolean) => {
      return !currentValue;
    });
  };

  const hangleSurpriseMe = async (e: any) => {
    setMessage('');
    setIsTablePopUp(false);
    const newObj: any = { message };
    newObj['message'] = 'Surprise me';
    setMessages((prev: any) => [...prev, { message: newObj.message, sender: 'user' }]);
    setIsLoading(true);
    const randomKeyFromObject = ({ object }: { object: { [key: string]: any } }) => {
      const objectGroupKeys = Object.keys(object);
      const randomGroupKey = objectGroupKeys[Math.floor(Math.random() * objectGroupKeys.length)];
      return randomGroupKey ?? '';
    };
    const randomGroupKey = randomKeyFromObject({ object: tablesObject });
    const randomTableKey = randomKeyFromObject({
      object: tablesObject[randomGroupKey].options ?? {},
    });
    let selectedTable = randomTableKey;

    let tableSchemaData = await getTableSchema({
      allTables,
      selectedTable,
      appID,
      appSheets,
      setAllTables,
      updateSheet,
      updateReduxSheetSchema,
      allTableIDAndNamesMap,
    });
    if (tableSchemaData?.skip) {
      for (let ix = 0; ix < 4; ix++) {
        const randomGroupKey = randomKeyFromObject({ object: tablesObject });
        const randomTableKey = randomKeyFromObject({
          object: tablesObject[randomGroupKey].options ?? {},
        });
        selectedTable = randomTableKey;

        tableSchemaData = await getTableSchema({
          allTables,
          selectedTable,
          appID,
          appSheets,
          setAllTables,
          updateSheet,
          updateReduxSheetSchema,
          allTableIDAndNamesMap,
        });
        if (!tableSchemaData?.skip) {
          break;
        }
      }
    }
    if (tableSchemaData?.skip) {
      newObj['sender'] = 'supistaAI';
      newObj['error'] = true;
      newObj['message'] = errorMessage['surpriseMeNoData'];
      newObj['table'] = selectedTable;
      setIsLoading(false);
      setMessages((prev: any) => [...prev, newObj]);
      return;
    }

    const tableSchema = tableSchemaData?.schema;
    const sheetID = tableSchemaData?.sheetID;
    // setSheetID(sheetID);
    let convertedTimeFrame;
    if (tableSchema?.defaultTable?.defaultDatetimeCol) {
      const timeFrameObjectForConvert = {
        defaultDateTimeCol: tableSchema?.defaultTable?.defaultDatetimeCol,
        action: defaultSelectedTimeframe,
        tableData: tableSchema?.data,
      };
      convertedTimeFrame = convertToTimeFrameObject(timeFrameObjectForConvert);
      if (convertedTimeFrame.skip === null) {
        return;
      }
    }
    const data: any = await supistaApiPost(
      'chatAI',
      {
        appID: appID,
        query: message,
        tableName: selectedTable,
        sheetID,
        isSurprise: true,
        defaultTable: tableSchema?.defaultTable || {},
        timeFrame: convertedTimeFrame?.timeFrame,
      },
      appID
    );

    setIsLoading(false);

    if (!data.__d3__error) {
      newObj['sender'] = 'supistaAI';
      const newChartData = addDefaultDataInChart({
        chartData: data.response,
        table: selectedTable,
        tableSchema: tableSchema?.data,
        sheetID: sheetID,
      });
      newObj['response'] = newChartData;
      newObj['table'] = selectedTable;

      // TimeFrame
      const dateTimeCols = tableSchema?.defaultTable?.datetimeCols[0] ?? null;
      if (dateTimeCols !== null) {
        const tableData = tableSchema?.data;
        const timeFrameObjectForConvert = {
          defaultDateTimeCol: dateTimeCols,
          action: defaultSelectedTimeframe,
          tableData,
        };
        const convertedTimeFrame = convertToTimeFrameObject(timeFrameObjectForConvert);
        if (convertedTimeFrame.skip !== null) {
          newObj['timeFrame'] = convertedTimeFrame.timeFrame;
        }
      }
    } else {
      newObj['sender'] = 'supistaAI';
      newObj['error'] = true;
      newObj['message'] = errorMessage['noData'];
      newObj['table'] = selectedTable;
    }
    setMessages((prev: any) => [...prev, newObj]);
  };

  return (
    <form
      onSubmit={submitHandler}
      style={{
        width: (mainContainerWidth ?? 1) * 1,
      }}
      className='ChatInput'
    >
      <div className='ChatInnerInput'>
        {isTablePopUp ? (
          <TablePopUp
            isTablePopUp={isTablePopUp}
            setIsTablePopUp={setIsTablePopUp}
            tableData={tableData}
            tableLoading={tableLoading}
          />
        ) : null}
        <div className='leftOptionFormOption'>
          <IconButton
            className={isSelectedTableDisabled ? 'openTableButton disable' : 'openTableButton'}
            onClick={() => handleTablePopUp()}
          >
            <RiTableFill />
          </IconButton>
        </div>
        <div className='centerOptionFormOption introjs-chatbot'>
          <div className='tableDropDownDiv'>
            <ObjectDropDown
              object={tablesObject}
              dropDownValue={selectedTable}
              setdropDownValue={setSelectedTable}
              className='tableDropDown introjs-supistaAISelectTable'
              inputPlaceHolder={mls('Search Table')}
              alternateName={'tableID'}
              mainKey={'tableID'}
              ObjectInObject
              search
              groupDropDown
              isDropDownFixed={false}
            />
          </div>

          <div className='chatBotInputDiv'>
            <input
              type='text'
              className='chatBotInput introjs-chatBotInput'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={mls('Type your query here...')}
            />
          </div>
          <IconButton
            type='button'
            className={
              isSubmitDisabled
                ? 'chatbotSubmitButton disable introjs-chatbotSubmitButton'
                : 'chatbotSubmitButton introjs-chatbotSubmitButton'
            }
            disabled={isSubmitDisabled}
          >
            {/* <SendIcon /> */}
          </IconButton>
        </div>
        <div className='rightOptionFormOption'>
          <IconButton
            className={isSubmitDisabled ? 'surpriseButton ' : 'surpriseButton submitEnable'}
            onClick={(e: any) => {
              isSubmitDisabled && hangleSurpriseMe(e);
            }}
            type={isSubmitDisabled ? 'button' : 'submit'}
            title={mls('Surprise Me')}
            disabled={tableLoading}
          >
            <div className='surpriseMeicon'>
              {/* <ImMagicWand />  */}
              {isSubmitDisabled ? <ImMagicWand /> : <SendIcon fontSize={'small'} />}
            </div>
            <div className='surpriseMeText'>
              {isSubmitDisabled ? mls('Surprise Me') : mls('Send')}
            </div>
            {/* <img src={surpriseMeIcon} alt='' /> */}
          </IconButton>
        </div>
      </div>
    </form>
  );
};

export default ChatInput;
