import React, { useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import './kpiChartSettingsGlobal.scss';
import './chartStyle.scss';
import './components/KpiMeter/kpiMeter.scss';
import DragableWrapper from './features/DragableThings/DragableWrapper';
import ColumnToggleWapper from './features/columnToggle/ColumnToggleWapper';
import { defaultChartSetting, defaultDataType, defaultKpiChartSchema } from './data/defaultSchema';
import NonSupportedScreen from './components/NonSupportedScreen/NonSupportedScreen';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { useLocation } from 'react-router-dom';
import { filterByTableName } from 'lib/reusable-components/DisplayTable/tableFunction';
import { populateFunction } from './utils/populateFunction';
import { InfoToolTipDivInitiator } from 'lib/reusable-components/Components/ToolTip/InfoToolTip';
interface mainProps {
  // dashID: string;
  isNewKPIChart: boolean;
  kpiSchema: any;
  // analyticsSchema: any;
  handleKPISettingsPopUp: Function;
  handleChartUpdate: Function;
  chartID: string;
  circlesData: any[];
  employeesData: any[];
  departmentsData: any[];
  selectedTargetID: { [key: string]: any };
}
const KpiChartSettings = ({
  isNewKPIChart,
  handleKPISettingsPopUp,
  chartID,
  kpiSchema,
  handleChartUpdate,
  circlesData,
  employeesData,
  departmentsData,
  selectedTargetID,
}: mainProps) => {
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const [populateState, setPopulateState] = useState(!isNewKPIChart);
  const [columnDropNumber, setColumnDropNumber] = useState(
    defaultChartSetting.chartBuilderHelper.columnDropNumber
  );
  const [populateCount, setPopulateCount] = useState(0);

  const [selectedTable, setSelectedTable] = useState('');

  const [defaultTable, setDefaultTable] = useState({});
  const [isJoinedTable, setIsJoinedTable] = useState(false);
  const [tableSchema, setTableSchema] = useState([]);
  const [DTCols, setDTCols] = useState([]);
  const [sheetID, setSheetID] = useState('');
  const [isTableSelected, setIsTableSelected] = useState(false);

  const [tableFilter, setTableFilter] = useState({ search: '', type: defaultDataType.ALL });
  const [filterdTableSchema, setFilterdTableSchema] = useState(tableSchema);

  const tablesData = useMemo(() => {
    return filterByTableName(filterdTableSchema);
  }, [filterdTableSchema]);

  const [chartType, setChartType] = useState(defaultChartSetting.chartType);

  const [chatName, setChatName] = useState(defaultKpiChartSchema.kpiChartName);
  const [chartDescription, setChartDescription] = useState(defaultKpiChartSchema.kpiChartDesc);
  const [xAxisData, setXAxisData] = useState(defaultChartSetting.xAxis);
  const [yAxisData, setYAxisData] = useState(defaultChartSetting.yAxis);
  const [chartToolTip, setChartToolTip] = useState(defaultChartSetting.tooltip);
  const [chartXAxisStyle, setChartXAxisStyle] = useState(defaultChartSetting.xAxisStyle);
  const [chartYAxisStyle, setChartYAxisStyle] = useState(defaultChartSetting.yAxisStyle);
  const [chartFilter, setChartFilter] = useState(defaultChartSetting.filters);
  const [chartWidth, setChartWidth] = useState(defaultChartSetting.chartWidth);
  const [isInsight, setIsInsight] = useState(defaultChartSetting.isInsight);
  const [isForecast, setIsForecast] = useState(defaultChartSetting.isForecast);
  const [isCrossReport, setIsCrossReport] = useState(defaultChartSetting.isCrossReport);
  const [iskeepAllFilters, setIskeepAllFilters] = useState(defaultChartSetting.iskeepAllFilters);
  const [isZoomBrush, setIsZoomBrush] = useState(defaultChartSetting.isZoomBrush);
  const [isDateHierarchy, setIsDateHierarchy] = useState(defaultChartSetting.isDateHierarchy);
  const [plotAreaBackground, setPlotAreaBackground] = useState(
    defaultChartSetting.plotAreaBackgroundSolid
  );
  const [gridLines, setGridLines] = useState(defaultChartSetting.gridLines);

  // const [timeFrame, setTimeFrame] = useState(
  //   getTimeFrame({ action: defaultChartSetting.timeFrame, tableSchema, defaultDateTimeCol })
  // );

  //KPITRACKING
  const [kpiTargetPoints, setKpiTargetPoints] = useState(defaultChartSetting.targetPoints); //KPITRACKING
  const [selectedDepartment, setSelectedDepartment] = useState(defaultChartSetting.departmentID);
  const [defaultKpiTargets, setDefaultKpiTargets] = useState(defaultChartSetting.defaultKpiTargets); //KPITRACKING
  const [kpiProjectedTargetPoints, setKpiProjectedTargetPoints] = useState({});
  const [isProjectedTargetPointCreated, setIsProjectedTargetPointCreated] = useState(false);
  let currentYear = new Date().getFullYear();
  const [kpiTimePeriodRange, setKpiTimePeriodRange] = useState({
    from: currentYear,
    to: currentYear + 1,
  });

  const chartSchemaMemo = useMemo(() => {
    const updatedChartSchema = {
      chartType: chartType.chartKey,
      name: chatName,
      desc: chartDescription,
      xAxis: xAxisData,
      yAxis: yAxisData,
      xAxisStyle: chartXAxisStyle,
      yAxisStyle: chartYAxisStyle,
      chartWidth: chartWidth.key,
      chartID: chartID,
      appID: appID,
      sheetID: sheetID,
      tableID: selectedTable,
      tooltip: chartToolTip,
      filters: chartFilter,
      isInsight: isInsight,
      isForecast: isForecast,
      isZoomBrush: isZoomBrush,
      isDateHierarchy: isDateHierarchy,
      plotAreaBackground: plotAreaBackground,
      gridLines: gridLines,
      isCrossReport: isCrossReport,
      iskeepAllFilters: iskeepAllFilters,

      // timeFrame: timeFrame,
      chartBuilderHelper: {
        columnDropNumber: columnDropNumber,
      },
      // timeframeColumn: defaultDateTimeCol,

      // Special Case

      //KPITRACKING
      targetPoints: kpiTargetPoints, //KPITRACKING
      departmentID: selectedDepartment,
    };
    return updatedChartSchema;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartID,
    appID,
    sheetID,

    chartType,
    chatName,
    chartDescription,
    xAxisData,
    yAxisData,
    chartXAxisStyle,
    chartYAxisStyle,
    isForecast,
    isInsight,
    isZoomBrush,
    isDateHierarchy,
    plotAreaBackground,
    gridLines,
    chartWidth,
    chartToolTip,
    chartFilter,
    isCrossReport,
    iskeepAllFilters,
    columnDropNumber,
    // Special Case

    //KPITRACKING
    kpiTargetPoints,
    selectedDepartment,
  ]);

  const handleSearch = (value: string) => {
    setTableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: tableSchema,
        setFilterdTableSchema,
        ...currentValue,
        search: value,
      });

      return { ...currentValue, search: value };
    });
  };
  const handleTypeFilter = (e: string) => {
    setTableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: tableSchema,
        setFilterdTableSchema,
        ...currentValue,
        type: e,
      });
      return { ...currentValue, type: e };
    });
  };

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const appSheets = analyticsSchema.appSheets ?? {};
  const tablesObject = useMemo(() => {
    const getGroupTableData = ({
      analyticsSchema,
      tableType,
    }: {
      analyticsSchema: { [key: string]: any };
      tableType: { [key: string]: any };
    }) => {
      let tableInGroup = {};
      Object.keys(tableType).forEach((tableTypeID) => {
        if (analyticsSchema?.[tableTypeID]) {
          tableInGroup = {
            ...tableInGroup,
            [tableTypeID]: {
              groupKey: tableTypeID,
              title: tableType[tableTypeID],
              options: analyticsSchema?.[tableTypeID],
            },
          };
        }
      });
      return tableInGroup;
    };

    const tableType = {
      appJointable: 'Join Table',
      appCustomtable: 'Custom Table',
      appDatatable: 'Data Table',
    };
    const finalGroupTable = getGroupTableData({ analyticsSchema: analyticsSchema, tableType });
    return finalGroupTable;
  }, [analyticsSchema]);
  const stateProps = {
    departmentsData,
    circlesData,
    employeesData,
    appSheets,
    tablesObject,
    chartSchemaMemo,
    chartID,
    selectedTargetID,
    // Handle Table

    tablesData,
    setFilterdTableSchema,
    // mainTableData: filterdTableSchema,
    allTableData: tableSchema,
    mainTableData: tablesData,
    defaultTable,

    kpiProjectedTargetPoints,
    setKpiProjectedTargetPoints,
    kpiTimePeriodRange,
    setKpiTimePeriodRange,

    isProjectedTargetPointCreated,
    setIsProjectedTargetPointCreated,

    tableFilter,
    handleSearch,
    handleTypeFilter,
    appID,
    sheetID,
    isJoinedTable,
    setDefaultTable,
    tableSchema,
    setTableSchema,
    setIsJoinedTable,
    selectedTable,
    setSelectedTable,
    setSheetID,

    DTCols,
    setDTCols,
    isTableSelected,
    setIsTableSelected,

    selectedDepartment,
    setSelectedDepartment,
    // Extra
    populateCount,
    setPopulateCount,

    filterdTableSchema,

    // Normal Chart
    chartType,
    setChartType,
    // chartDescription,
    // setChartDescription,
    // chatName,
    // setChatName,
    chatName,
    setChatName,
    chartDescription,
    setChartDescription,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    chartXAxisStyle,
    setChartXAxisStyle,
    chartYAxisStyle,
    setChartYAxisStyle,
    chartToolTip,
    setChartToolTip,
    chartFilter,
    setChartFilter,
    isZoomBrush,
    setIsZoomBrush,
    isDateHierarchy,
    setIsDateHierarchy,
    plotAreaBackground,
    setPlotAreaBackground,
    gridLines,
    setGridLines,
    isCrossReport,
    setIsCrossReport,
    iskeepAllFilters,
    setIskeepAllFilters,
    chartWidth,
    setChartWidth,
    columnDropNumber,
    setColumnDropNumber,
    setIsInsight,
    setIsForecast,
    // timeFrame,
    // setTimeFrame,
    // defaultDateTimeCol,

    //KPIMETER
    kpiTargetPoints,
    setKpiTargetPoints,
    defaultKpiTargets,
    setDefaultKpiTargets,
  };

  useEffect(() => {
    populateFunction({ stateProps, chartSchema: kpiSchema, populateState, setPopulateState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateState]);
  return (
    <div className='KpiChartSettings DefaultScroll'>
      <NonSupportedScreen closeButton={() => handleKPISettingsPopUp(false)} />
      <InfoToolTipDivInitiator />

      <ToastContainer position='bottom-left' theme='colored' />
      <Header
        // settingsSchema={settingsSchema}
        appID={appID}
        handleKPISettingsPopUp={handleKPISettingsPopUp}
        handleChartUpdate={handleChartUpdate}
        isNew={isNewKPIChart}
        stateProps={stateProps}
      />
      <DragableWrapper stateProps={stateProps}>
        <ColumnToggleWapper stateProps={stateProps} />
      </DragableWrapper>
    </div>
  );
};

export default KpiChartSettings;

const tableFilterFuntion = (props: any) => {
  const { search, type, allTableData, setFilterdTableSchema } = props;
  setFilterdTableSchema(() => {
    const newArray = allTableData.filter((el: { [key: string]: any }) => {
      const lowerCaseElemName = el.name.toLowerCase();
      const lowerCaseElemType = el.dataType;
      const lowerCaseValue = search.toLowerCase();
      if (type === defaultDataType.ALL) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      if (lowerCaseElemType === type) {
        return lowerCaseElemName.includes(lowerCaseValue);
      }
      return false;
    });

    return newArray;
  });
};
