import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React, { useRef, useState } from 'react';
import './timeIntervalField.scss';
import {
  defalutTimeInterval,
  timeIntervalFieldsType,
  timeIntervalType,
  timeIntervalWeekOption,
} from './timeIntervalData';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
interface timeIntervalFieldProps {
  fieldsElem: any;
  formData: any;
  setFormData: Function;
}

const getDefaultValueByKey = (timeIntervalKey: string) => {
  let timeIntervalDefaults: { [key: string]: any } = {};
  (
    timeIntervalType[timeIntervalKey as keyof typeof timeIntervalType]?.timeIntervalOptions ?? []
  ).forEach((connectionTableConfigFieldsElem) => {
    timeIntervalDefaults = {
      ...timeIntervalDefaults,
      [connectionTableConfigFieldsElem.name]: connectionTableConfigFieldsElem.defaultValue,
    };
  });
  timeIntervalDefaults = {
    ...timeIntervalDefaults,
    intervalType: timeIntervalKey,
  };
  return timeIntervalDefaults;
};
const TimeIntervalField = ({ fieldsElem, formData, setFormData }: timeIntervalFieldProps) => {
  const [isTimeIntervalOpen, setIsTimeIntervalOpen] = useState(false);
  const handleTimeInterval = (action: any) => {
    setFormData({ key: fieldsElem.name, value: action });
  };
  const handleTimeIntervalOpen = () => {
    setIsTimeIntervalOpen(!isTimeIntervalOpen);
  };
  const outerContainerRef = useRef(null);

  return (
    <div className='connectionFormOptions connectionFormOptionsInRow'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>

      <div>
        <button
          className='primaryButton secondaryButtonColor'
          type='button'
          onClick={() => handleTimeIntervalOpen()}
        >
          Edit Time Interval
        </button>
      </div>
      {isTimeIntervalOpen ? (
        <PopUpDivChild
          scrollOff={false}
          popupTitle={mls(fieldsElem.label)}
          initialValue={isTimeIntervalOpen}
          setPopUp={handleTimeIntervalOpen}
          innerDivRef={outerContainerRef}
        >
          <TimeIntervalPopUp
            intervalForm={
              formData[fieldsElem.name] ?? getDefaultValueByKey(defalutTimeInterval.intervalType)
            }
            setIntervalForm={handleTimeInterval}
            outerContainerRef={outerContainerRef}
          />
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default TimeIntervalField;

const TimeIntervalPopUp = ({
  intervalForm,
  setIntervalForm,
  outerContainerRef,
}: {
  intervalForm: any;
  setIntervalForm: Function;
  outerContainerRef?: React.RefObject<HTMLDivElement> | null;
}) => {
  const handleIntervalType = (timeIntervalKey: string) => {
    const timeIntervalDefault = getDefaultValueByKey(timeIntervalKey);
    setIntervalForm(timeIntervalDefault);
  };
  const handleIntervalForm = (action: any) => {
    const updatedFormData = {
      ...(intervalForm ?? {}),
      [action.key]: action.value,
    };
    setIntervalForm(updatedFormData);
  };

  return (
    <div className='timeIntervalFieldContainer' ref={outerContainerRef}>
      {React.Children.toArray(
        Object.keys(timeIntervalType).map((timeIntervalKey: string) => {
          const timeIntervalValue =
            timeIntervalType[timeIntervalKey as keyof typeof timeIntervalType];
          return (
            <div className='timeIntervalTypeContainer'>
              <div className='timeIntervalTypeCheckbox'>
                <input
                  type='radio'
                  checked={intervalForm.intervalType === timeIntervalKey}
                  onClick={() => handleIntervalType(timeIntervalKey)}
                />
              </div>
              <div className='timeIntervalType'>
                <div
                  className='timeIntervalTypeTitle'
                  onClick={() => handleIntervalType(timeIntervalKey)}
                >
                  {timeIntervalValue?.title}
                </div>

                {intervalForm.intervalType === timeIntervalKey ? (
                  <div className='timeIntervalOptionsContainer'>
                    {React.Children.toArray(
                      timeIntervalValue?.timeIntervalOptions.map((timeIntervalElem: any) => {
                        if (timeIntervalFieldsType.number === timeIntervalElem.type) {
                          return (
                            <NumberField
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                            />
                          );
                        }
                        if (timeIntervalFieldsType.timeField === timeIntervalElem.type) {
                          return (
                            <TimeField
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                            />
                          );
                        }
                        if (timeIntervalFieldsType.timeDropDown === timeIntervalElem.type) {
                          return (
                            <TimeDropDownField
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                              outerContainerRef={outerContainerRef}
                            />
                          );
                        }
                        if (timeIntervalFieldsType.inputString === timeIntervalElem.type) {
                          return (
                            <InputStringField
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                            />
                          );
                        }

                        if (timeIntervalFieldsType.weekField === timeIntervalElem.type) {
                          return (
                            <WeekContainer
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                            />
                          );
                        }
                        if (timeIntervalFieldsType.monthField === timeIntervalElem.type) {
                          return (
                            <MonthContainer
                              timeIntervalElem={timeIntervalElem}
                              intervalForm={intervalForm}
                              setIntervalForm={handleIntervalForm}
                            />
                          );
                        }
                        return <></>;
                      })
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

interface timeIntervalOptionProps {
  timeIntervalElem: any;
  intervalForm: any;
  setIntervalForm: Function;
  outerContainerRef?: React.RefObject<HTMLDivElement> | null;
}
const WeekContainer = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
}: timeIntervalOptionProps) => {
  const handleSelection = ({
    isSelected,
    selectedKey,
  }: {
    isSelected: boolean;
    selectedKey: number;
  }) => {
    if (isSelected) {
      const filteredData = (
        intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
      ).filter((dataElem: number) => dataElem !== selectedKey);
      setIntervalForm({ key: timeIntervalElem.name, value: filteredData });
    } else {
      setIntervalForm({
        key: timeIntervalElem.name,
        value: [
          ...(intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue),
          selectedKey,
        ],
      });
    }
  };
  return (
    <div className='timeIntervalOption'>
      <div className='timeIntervalTitle'>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <div className='multiSelectBoxContainer'>
        {React.Children.toArray(
          Object.keys(timeIntervalWeekOption).map((weekKey: string) => {
            const isSelected = (
              intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
            ).some((weekElem: number) => weekElem === Number(weekKey));
            return (
              <div
                className={`multiSelectBoxOption ${
                  isSelected ? 'selectedMultiSelectBoxOption' : ''
                }`}
                onClick={() => handleSelection({ isSelected, selectedKey: Number(weekKey) })}
              >
                {
                  timeIntervalWeekOption[Number(weekKey) as keyof typeof timeIntervalWeekOption]
                    .name
                }
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const MonthContainer = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
}: timeIntervalOptionProps) => {
  const handleSelection = ({
    isSelected,
    selectedKey,
  }: {
    isSelected: boolean;
    selectedKey: string | number;
  }) => {
    if (isSelected) {
      const filteredData = (
        intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
      ).filter((dataElem: string) => dataElem !== selectedKey);
      setIntervalForm({ key: timeIntervalElem.name, value: filteredData });
    } else {
      setIntervalForm({
        key: timeIntervalElem.name,
        value: [
          ...(intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue),
          selectedKey,
        ],
      });
    }
  };
  return (
    <div className='timeIntervalOption'>
      <div className='timeIntervalTitle'>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <div className='multiSelectBoxContainer'>
        {[...Array(29)].map((x, i) => {
          if (i === 0) {
            return <></>;
          }
          const isSelected = (
            intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
          ).some((numberElem: number) => numberElem === i);
          return (
            <div
              className={`multiSelectBoxOption ${isSelected ? 'selectedMultiSelectBoxOption' : ''}`}
              onClick={() => handleSelection({ isSelected, selectedKey: i })}
            >
              {i}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NumberField = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
}: timeIntervalOptionProps) => {
  const handleChange = (e: any) => {
    let finalValue: number | string = '';
    if (e.target.value) {
      if (timeIntervalElem?.minValue) {
        if (Number(e.target.value) < timeIntervalElem?.minValue) {
          return;
        }
      }
      if (timeIntervalElem?.maxValue) {
        if (Number(e.target.value) > timeIntervalElem?.maxValue) {
          return;
        }
      }
      finalValue = Number(e.target.value);
    }
    setIntervalForm({ key: timeIntervalElem.name, value: finalValue });
  };
  return (
    <div className='timeIntervalOption timeIntervalOptionInFlex'>
      <div className='timeIntervalTitle'>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <input
        name={timeIntervalElem.name}
        value={intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue}
        type='number'
        onChange={handleChange}
        min={timeIntervalElem?.minValue ?? null}
        max={timeIntervalElem?.maxValue ?? null}
        required={timeIntervalElem?.required}
        placeholder={mls(timeIntervalElem?.label)}
        className='connectionFormInput '
        style={{ width: 'auto' }}
      />
    </div>
  );
};
const TimeField = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
}: timeIntervalOptionProps) => {
  const handleSelection = ({
    isSelected,
    selectedKey,
  }: {
    isSelected: boolean;
    selectedKey: string | number;
  }) => {
    if (isSelected) {
      const filteredData = (
        intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
      ).filter((dataElem: string) => dataElem !== selectedKey);
      setIntervalForm({ key: timeIntervalElem.name, value: filteredData });
    } else {
      setIntervalForm({
        key: timeIntervalElem.name,
        value: [
          ...(intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue),
          selectedKey,
        ],
      });
    }
  };
  return (
    <div className='timeIntervalOption '>
      <div className='timeIntervalTitle'>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <div className='multiSelectBoxContainer'>
        {[...Array(24)].map((x, i) => {
          const isSelected = (
            intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
          ).some((numberElem: number) => numberElem === i);
          return (
            <div
              className={`multiSelectBoxOption ${isSelected ? 'selectedMultiSelectBoxOption' : ''}`}
              onClick={() => handleSelection({ isSelected, selectedKey: i })}
            >
              {i}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const TimeDropDownField = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
  outerContainerRef = null,
}: timeIntervalOptionProps) => {
  const handleDropDown = (action: any) => {
    setIntervalForm({ key: timeIntervalElem.name, value: [action] });
  };

  const timeArray = [
    { name: '00:00', key: 0 },
    { name: '01:00', key: 1 },
    { name: '02:00', key: 2 },
    { name: '03:00', key: 3 },
    { name: '04:00', key: 4 },
    { name: '05:00', key: 5 },
    { name: '06:00', key: 6 },
    { name: '07:00', key: 7 },
    { name: '08:00', key: 8 },
    { name: '09:00', key: 9 },
    { name: '10:00', key: 10 },
    { name: '11:00', key: 11 },
    { name: '12:00', key: 12 },
    { name: '13:00', key: 13 },
    { name: '14:00', key: 14 },
    { name: '15:00', key: 15 },
    { name: '16:00', key: 16 },
    { name: '17:00', key: 17 },
    { name: '18:00', key: 18 },
    { name: '19:00', key: 19 },
    { name: '20:00', key: 20 },
    { name: '21:00', key: 21 },
    { name: '22:00', key: 22 },
    { name: '23:00', key: 23 },
  ];
  return (
    <div className='timeIntervalOption timeIntervalOptionInFlex'>
      <div className='timeIntervalTitle '>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <div>
        <ArrayDropDown
          array={timeArray}
          setdropDownValue={handleDropDown}
          dropDownValue={intervalForm[timeIntervalElem.name][0] ?? timeIntervalElem.defaultValue[0]}
          ObjectInArray
          outerContainerRef={outerContainerRef}
          // dropDownHeightChange={true}
          dropDownHeight={50}
        />
      </div>
      {/* <div className='multiSelectBoxContainer'>
        {[...Array(24)].map((x, i) => {
          const isSelected = (
            intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue
          ).some((numberElem: number) => numberElem === i);
          return (
            <div
              className={`multiSelectBoxOption ${isSelected ? 'selectedMultiSelectBoxOption' : ''}`}
              onClick={() => handleSelection({ isSelected, selectedKey: i })}
            >
              {i}
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
const InputStringField = ({
  timeIntervalElem,
  intervalForm,
  setIntervalForm,
}: timeIntervalOptionProps) => {
  const handleChange = (e: any) => {
    setIntervalForm({ key: timeIntervalElem.name, value: e.target.value });
  };
  return (
    <div className='timeIntervalOption'>
      <div className='timeIntervalTitle'>
        {mls(timeIntervalElem.label)}:{' '}
        {timeIntervalElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <input
        name={timeIntervalElem.name}
        value={intervalForm[timeIntervalElem.name] ?? timeIntervalElem.defaultValue}
        type='string'
        onChange={handleChange}
        min={timeIntervalElem?.minValue ?? null}
        max={timeIntervalElem?.maxValue ?? null}
        required={timeIntervalElem?.required}
        placeholder={mls(timeIntervalElem?.label)}
        style={{ width: 'auto' }}
        className='primaryInput'
      />
    </div>
  );
};
