import { _supistaAnalyticsApi } from 'lib/server-connection/connections';

interface kpiSettingsApiProps {
  appID: string;
  endPoint: string;
  data: { [key: string]: any };
}
export const kpiSettingsApi = async ({ appID, endPoint, data }: kpiSettingsApiProps) => {
  const res = await _supistaAnalyticsApi(`${appID}/kpiSettings/${endPoint}`, data)
    .then(async (response) => {
      if (response.__d3__error) {
        return { skip: true, data: [], msg: response?.response?.msg };
      } else {
        return { skip: false, data: response?.response?.data ?? [], msg: undefined };
      }
    })
    .catch((error) => {
      console.log(error);
      // toast.error(mls('Some issues encountered.'));
      return { skip: true, data: [], msg: undefined };
    });
  return res;
};
