import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import React from 'react';
import { ScatterTopBottomColumn } from './TypeColumn/ScatterTypeColumn';
import { BubbleTopBottomColumn } from './TypeColumn/BubbleTypeColumn';
import { GroupBubbleTopBottomColumn } from './TypeColumn/GroupBubbleTypeColumn';
import { GroupScatterTopBottomColumn } from './TypeColumn/GroupScatterTypeColumn';

interface scatterTopBottomProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
  mainData: any;
}
const ScatterTopBottom = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
  mainData,
}: scatterTopBottomProps) => {
  const topBottomColumnObject = {
    bottom_5: {
      label: 'Bottom 5',
      key: 'bottom_5',
      minMax: false,
    },
    top_5: {
      label: 'Top 5 ',
      key: 'top_5',
      minMax: false,
    },
    bottom5: {
      label: 'Bottom 5',
      key: 'bottom5',
      minMax: false,
    },
    top5: {
      label: 'Top 5 ',
      key: 'top5',
      minMax: false,
    },
    Top10OfColA: {
      label: 'Top 10 Column A',
      key: 'Top10OfColA',
      minMax: false,
    },
    Bottom10OfColA: {
      label: 'Bottom 10 Column A',
      key: 'Bottom10OfColA',
      minMax: false,
    },
    Top10OfColB: {
      label: 'Top 10 Column B',
      key: 'Top10OfColB',
      minMax: false,
    },
    Bottom10OfColB: {
      label: 'Bottom 10 Column B',
      key: 'Bottom10OfColB',
      minMax: false,
    },
  };

  const minMaxArray = [
    { label: 'Max', key: 'max' },
    { label: 'Mean', key: 'mean' },
    { label: 'Min', key: 'min' },
    { label: 'Group Value', key: 'groupValue' },
  ];
  const columnName = {
    ...(mainData?.xcolumnName ?? {}),
    ...(mainData?.ycolumnName ?? {}),
    ...(mainData?.zcolumnName ?? {}),
  };

  if (mainData.chartType === allChartKeys.SCATTERPLOT) {
    const topBottomArray = [
      topBottomColumnObject.Top10OfColA,
      topBottomColumnObject.Bottom10OfColA,
      topBottomColumnObject.Top10OfColB,
      topBottomColumnObject.Bottom10OfColB,
    ];
    const finalElem = elem?.top_bottom ?? {};
    return (
      <ScatterTopBottomColumn
        minMaxArray={minMaxArray}
        finalElem={finalElem}
        columnName={columnName}
        topBottomArray={topBottomArray}
        columnName1={columnName1}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.BUBBLEPLOT) {
    const topBottomArray = [topBottomColumnObject.top_5, topBottomColumnObject.bottom_5];
    const finalElem = elem?.top_bottom ?? {};
    return (
      <BubbleTopBottomColumn
        minMaxArray={minMaxArray}
        finalElem={finalElem}
        columnName={columnName}
        topBottomArray={topBottomArray}
        columnName1={columnName1}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.GROUPSCATTERPLOT) {
    const topBottomArray = [topBottomColumnObject.top5, topBottomColumnObject.bottom5];
    const finalElem = elem ?? {};
    return (
      <GroupScatterTopBottomColumn
        minMaxArray={minMaxArray}
        finalElem={finalElem}
        columnName={columnName}
        topBottomArray={topBottomArray}
        columnName1={columnName1}
        arrow={arrow}
      />
    );
  }
  if (mainData.chartType === allChartKeys.GROUPBUBBLEPLOT) {
    const topBottomArray = [topBottomColumnObject.top_5, topBottomColumnObject.bottom_5];
    const finalElem = elem?.top_bottom ?? {};
    return (
      <GroupBubbleTopBottomColumn
        minMaxArray={minMaxArray}
        finalElem={finalElem}
        columnName={columnName}
        topBottomArray={topBottomArray}
        columnName1={columnName1}
        arrow={arrow}
      />
    );
  }
  return <></>;
};
export default ScatterTopBottom;
