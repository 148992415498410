import { toAbsoluteUrl } from '_metronic/helpers';
import { mls } from 'lib/multilanguagesupport';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const svgStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh',
};

const ErrorPage = () => {
  return (
    <div className='h-100 d-flex align-items-center justify-content-center'>
      <div className='mb-20'>
        <div style={svgStyle}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 100 90'
            width='40%'
            height='40%'
            style={{ minWidth: '250px', maxHeight: '45vh' }}
          >
            <image
              href={toAbsoluteUrl('/media/chart-screen/Show_no_chart.svg')} // Replace with the actual path to your image
              width='100'
              height='100'
            />
          </svg>
        </div>
        <br />
        <h4 style={svgStyle} className='mb-3 text-center'>
          {mls('Some error Occured!')}
        </h4>
        <div style={svgStyle}>
          <Link to='/'>
            <button type='button' className='btn btn-sm btn-primary me-3'>
              <span className='indicator-label'>{mls('Go To Home')}</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
