import { mls } from 'lib/multilanguagesupport';
import React from 'react';
import ValueNumber from './ValueNumber';
interface MaximaMinimaProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
}
const MaximaMinima = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
}: MaximaMinimaProps) => {
  return (
    <div className='container-fluid h-100 mb-2 p-2 maximaMinimaDivContainer'>
      <div className='col p-4 w-100 insight-table-column-header-left'>
        <b>{mls(columnName1)}</b>
      </div>
      <div className='col-3 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName2)}</b>
      </div>
      <div className='col-2 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName3)}</b>
      </div>
      <div className='col-2 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName4)}</b>
      </div>

      {Array.isArray(elem) &&
        elem.map((data: [any, any, any, any], i: number) => (
          <>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>{data[0]}</div>
            <div className='col-3 p-4 g-0 w-100 insight-table-data-cell-right'>{data[1]}</div>
            <div className='col-2 p-4 g-0 w-100 insight-table-data-cell-right'>
              <ValueNumber number={data[2]} arrow={arrow} />
            </div>
            <div className='col-2 p-4 g-0 w-100 insight-table-data-cell-right'>{data[3]}%</div>
          </>
        ))}
    </div>
  );
};

export default MaximaMinima;
