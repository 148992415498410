import React from 'react';
import './dashboardWrapper.scss';
import DroppableDashboardSection from './DroppableDashboardSection';
import SectionHeader from './SectionHeader';
import { mls } from 'lib/multilanguagesupport';

const DashboardWrapper = ({ stateProps }: mainProps) => {
  const { sections } = stateProps;

  return (
    <div className='dashboardWrapper'>
      <div className='dashboardWrapperSection'>
        {React.Children.toArray(
          sections.map((sectionElem, index) => (
            <InnerSections sectionElem={sectionElem} index={index} stateProps={stateProps} />
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardWrapper;

const InnerSections = ({ sectionElem, index, stateProps }: InnerSectionProps) => {
  return (
    <div className='dashboardInnerSections'>
      <div className='sectionName'>
        {mls(sectionElem.title)}&nbsp;( {index + 1} )
      </div>
      <div className='headerOuterDiv'>
        {React.Children.toArray(
          sectionElem.headings.map((elem: any, i: number): any => (
            <SectionHeader
              elem={elem}
              headingIndex={i}
              stateProps={stateProps}
              sectionIndex={index}
            />
          ))
        )}
      </div>
      <DroppableDashboardSection sectionElem={sectionElem} index={index} stateProps={stateProps} />
    </div>
  );
};

interface mainProps {
  stateProps: {
    sections: any[];
    setSections: Function;
  };
}
interface InnerSectionProps {
  stateProps: any;
  sectionElem: {
    title: string;
    charts: any[];
    headings: {
      text: string;
      color: string;
      font: string;
      fontHeight: string;
      opacity: string;
      textStyle: string[];
    }[];
  };
  index: number;
}
