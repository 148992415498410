import { FaTimes } from 'react-icons/fa';
import EditTableList from './EditTableList';
import { useMemo, useState } from 'react';
import SqlCustomCommands from 'lib/data-connectors/dependencies/tableCustomColumn/SqlCustomCommands';
import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import { AllCustomCommandType } from 'lib/data-connectors/dependencies/tableCustomColumn/data/sqlCustomCommandsData';
import CloseConfirmationDiv from 'lib/data-connectors/dependencies/CloseConfirmationDiv/CloseConfirmationDiv';

interface editTableProps {
  appID: string;
  handleClose: Function;
  dataIndex: number;
  tableSchema: any;
  dataTableData: { [key: string]: any }[];
  appDatatable: { [key: string]: any };
}
const EditTable = ({
  appID,
  handleClose,
  dataIndex,
  dataTableData,
  appDatatable,
}: editTableProps) => {
  const [tableSchema, setTableSchema] = useState(dataTableData?.[dataIndex] || {});
  const [updateSqlCustomCommands, setUpdateSqlCustomCommands] = useState(1);

  const [isAnyChangesSchema, setIsAnyChangesSchema] = useState(false);
  const [isAnyChangesSqlCommands, setIsAnyChangesSqlCommands] = useState(false);
  const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);

  const closeTable = () => {
    if (isAnyChangesSchema || isAnyChangesSqlCommands) {
      setIsConfirmationDiv(true);
    } else {
      handleClose();
    }
  };

  return (
    <div className='editTableOuterContainer'>
      <div className='editTableHeader'>
        <div className='crossButton' onClick={() => closeTable()} data-bs-dismiss='modal'>
          <FaTimes />
        </div>
      </div>
      {isConfirmationDiv && (
        <CloseConfirmationDiv
          isConfirmationDiv={isConfirmationDiv}
          setIsConfirmationDiv={setIsConfirmationDiv}
          handleClose={handleClose}
        />
      )}
      <div className='editTableInnerContainer'>
        <EditTableList
          closeModal={handleClose}
          appID={appID}
          appDatatable={appDatatable}
          tableSchema={tableSchema}
          setTableSchema={setTableSchema}
          useUpdateSchema={useUpdateTableSchema}
          setUpdateSqlCustomCommands={setUpdateSqlCustomCommands}
          setIsAnyChanges={setIsAnyChangesSchema}
        />
        <SqlCustomCommands
          appID={appID}
          updateSqlCustomCommands={updateSqlCustomCommands}
          tableData={dataTableData[dataIndex]}
          appDatatable={appDatatable}
          tableSchema={tableSchema}
          setTableSchema={setTableSchema}
          customCommandType={AllCustomCommandType.customColumn}
          useUpdateSchema={useUpdateTableSchema}
          setIsAnyChangesSqlCommands={setIsAnyChangesSqlCommands}
          setIsAnyChangesSchema={setIsAnyChangesSchema}
        />
      </div>
    </div>
  );
};
export default EditTable;
