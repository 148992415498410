import { toast } from 'react-toastify';
import { supistaApiPost } from '../utils/connections';
import { finalSchemaByChart } from '../utils/finalSchemaFunction';
export const updateChartSchemaInServer = async ({
  chartSchemaMemo,
  chartType,
  endPoint,
  normal = false,
  showToast = false,
  updateProjectSchema = () => {},
}) => {
  const stringToBold = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />;
  const errorMsg = '<b>Please complete the required fields to save the chart.</b>';
  const successrMsg = '<b>The chart has been successfully saved.</b>';

  const dataByChart = finalSchemaByChart({ chartSchemaMemo, chartType, normal });

  if (dataByChart?.skip) {
    if (showToast) {
      toast.error(stringToBold(errorMsg), {
        position: 'bottom-left',
      });
    }
    return { skip: true };
  }
  if (!dataByChart?.data) return { skip: true };
  try {
    const res = await supistaApiPost(endPoint, dataByChart?.data, chartSchemaMemo.appID);
    if (!res.__d3__error) {
      if (showToast) {
        toast.success(stringToBold(successrMsg), {
          position: 'bottom-left',
        });
      }
      updateProjectSchema(dataByChart.data);
      if (normal) {
        return {
          skip: false,
        };
      }
      const chartID = Object.keys(dataByChart?.data?.charts)[0];
      return {
        skip: false,
        chartSchema: dataByChart?.data?.charts[chartID],
        plotData: res?.response?.charts[chartID],
      };
    }
  } catch (error) {
    console.log('error while calling server data: ', error);
  }

  return { skip: true };
};
