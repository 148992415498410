import { mls } from 'lib/multilanguagesupport';
import { defaultOthers } from '../reusableData/chartData';
import { defaultDataType } from '../reusableData/defaultChartSchema';

interface getOtherPros {
  array: {
    axis: String;
  }[];
  opacity?: number;
}
export const getOther = ({ array, opacity }: getOtherPros) => {
  const other = array.find((elm) => {
    return elm.axis === defaultOthers.axis;
  });
  if (!other) {
    return defaultOthers;
  }
  if (opacity) {
    return { ...other, opacity };
  }
  return other;
};
const checkForDate = (elem: any) => {
  const timestamp: any = Date.parse(elem);
  const numberCheck = Number(elem);
  if (isNaN(timestamp) !== false) {
    return elem;
  }
  const nowDate: any = new Date(elem);
  const date =
    nowDate.getFullYear() +
    '/' +
    ('0' + (nowDate.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + nowDate.getDate()).slice(-2);
  return date;
};
export const allFiltersString = (filtersSchema: any) => {
  if (!filtersSchema) {
    return [];
  }
  const filterString = Object.keys(filtersSchema).map((filterKey: string) => {
    const filter = filtersSchema[filterKey];
    if (filter.dataType === defaultDataType.CAT) {
      return `<b>${filter.name}:</b> ${mls('values are')} <b>[${filter.value.join(',')}]</b>`;
    }
    if (filter.dataType === defaultDataType.ID_TEXT) {
      return `<b>${filter.name}:</b> ${mls('values are')} <b>[${filter.value.join(',')}]</b>`;
    }
    if (filter.dataType === defaultDataType.NUM) {
      return `<b>${filter.name}:</b> ${mls('range are')} <b>${filter.value.min} to ${
        filter.value.max
      }</b>`;
    }
    if (filter.dataType === defaultDataType.ID_NUM) {
      return `<b>${filter.name}:</b> ${mls('range are')} <b>${filter.value.min} to ${
        filter.value.max
      }</b>`;
    }
    if (filter.dataType === defaultDataType.DATETIME) {
      if (filter.value.period === 'LASTXDAYS') {
        return `<b>${filter.name}:</b> ${mls('selected last')} <b>${filter.value.xDay} days</b>`;
      }
      if (filter.value.period === 'CUSTOM') {
        return `<b>${filter.name}:</b>  ${mls('selected')}  <b>${checkForDate(
          filter.value.startDate
        )} to ${checkForDate(filter.value.endDate)}</b>`;
      }
      return `<b>${filter.name}</b> ${mls('selected date is')} <b>${filter.value.period}</b>`;
    }
    return '';
  });
  return filterString;
};
export const finalFilterStringFunction = ({
  filtersSchema,
  chartName,
}: {
  filtersSchema: any;
  chartName: string;
}) => {
  const filterStringArray = allFiltersString(filtersSchema);
  const filterString = `${mls('Applied  filters on Chart')}: <b>${chartName}</b> ${mls(
    'where'
  )}.<br/>${filterStringArray.join('.<br/>')}`;
  return filterStringArray.length === 0 ? '' : filterString;
};
