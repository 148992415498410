import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import { mls } from 'lib/multilanguagesupport';
import { convertTableData } from 'lib/reusable-components/DisplayTable/tableFunction';
import { _supistaAnalyticsApi, _supistaApiPost } from 'lib/server-connection/connections';
import React from 'react';
import { toast } from 'react-toastify';
import { AllCustomCommandType, allOutputTypes } from './data/sqlCustomCommandsData';

const AutoGeneratedArea = ({ tableColumnProps }: { tableColumnProps: any }) => {
  const {
    handleSqlArea,
    appID,
    tableSchema,
    setIsTablePopUp,
    setSelectedTableData,
    setTableLoading,
    setIsSelectedTableDisabled,
    setTableSchema,
    apiProps,
    useUpdateSchema,
    customCommandType,
    setLastQuery,
    setOutputData,
    setCustomColumnSchema,
    setSqlQuery,
    disableSqlStatements,
    setIsAnyChangesSqlCommands,
    // defaultData
    defaultQuery,
  } = tableColumnProps;

  const AIToGenerateColumnFunction = async () => {
    if (disableSqlStatements) {
      toast.warn(mls('Please save to continue'));
      return;
    }
    setTableLoading(true);

    const AIToGenerateColumn = apiProps.AIToGenerateApi;
    const currentTimestamp = Date.now();

    const AIToGenerateColumnData = {
      appID: appID,
      tableID: tableSchema.tableID,
      tableName:
        customCommandType === AllCustomCommandType.customTable ? tableSchema.name : undefined,
      tableList: tableSchema?.tables ?? [tableSchema.tableID],
      tableSchema,
    };

    const finalEndPoint = `${appID}/customType/${AIToGenerateColumn}/customSql`;
    const responseData = await _supistaAnalyticsApi(finalEndPoint, AIToGenerateColumnData);
    if (responseData.__d3__error) {
      setSqlQuery(defaultQuery);
      handleSqlArea();
    } else {
      const finalTableSchema = {
        ...tableSchema,
        sqlQuery: responseData?.response?.sqlQuery,
        customColumnSchema:
          customCommandType !== AllCustomCommandType.customTable
            ? responseData?.response?.customColumnSchema
            : tableSchema.customColumn,
        columnSchema:
          customCommandType === AllCustomCommandType.customTable
            ? responseData?.response?.columnSchema
            : tableSchema.columnSchema,
        updatedAt: currentTimestamp,
        createdAt: tableSchema.createdAt ?? currentTimestamp,
      };
      setSqlQuery(finalTableSchema.sqlQuery);
      setTableSchema(finalTableSchema);

      const finalTableData = convertTableData({
        tableHead: responseData.response.dataHead,
        tableSchema: { data: responseData.response.tableSchema },
        highlightTableSchema: responseData.response.customColumnSchema,
      });
      setLastQuery(responseData?.response?.sqlQuery);
      setSelectedTableData(finalTableData);
      setIsTablePopUp(true);
      handleSqlArea();
      setOutputData({
        outputMsg: 'Success',
        outputType: allOutputTypes.success,
      });
      setCustomColumnSchema(finalTableSchema.columnSchema);
    }
    setIsAnyChangesSqlCommands(true);
    setTableLoading(false);
  };
  return (
    <div className='autoGeneratedColumnsOuter'>
      <div className='autoGeneratedColumnsInner'>
        <div className='greyText'>
          {mls('Automatically generate columns to analyze your data better using Supista AI.')}
        </div>
        <button
          className={`primaryButton ${disableSqlStatements ? 'disabledButton' : ''}`}
          // disabled={disableSqlStatements}
          onClick={() => AIToGenerateColumnFunction()}
        >
          {mls('Auto Generate Columns')}
        </button>
        <div className='greyText noWrap'>
          -------------------------------- OR --------------------------------
        </div>
        <div className='greyText'>
          {mls('Manually add new columns in your table using SQL querries')}
        </div>
        <div
          className='primaryButton'
          onClick={() => {
            handleSqlArea();
          }}
        >
          {mls('Add SQL querries manually')}
        </div>
        {/* <div className='greyText noWrap'>
          -------------------------------- OR --------------------------------
        </div> */}
      </div>
    </div>
  );
};

export default AutoGeneratedArea;
