// import { useState } from 'react';
// import { toast } from 'react-toastify';
import { supistaApiPost } from '../utils/connections';
import { mls } from 'lib/multilanguagesupport';
import { FaTimes } from 'react-icons/fa';

interface mainProps {
  appID: string;
  settingsSchema: any;
  handleDashSettingsPopUp: Function;
  handleSave: Function;
  isNew: boolean;
}
const Header = ({
  settingsSchema,
  appID,
  handleDashSettingsPopUp,
  handleSave,
  isNew,
}: mainProps) => {
  // const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);

  const saveFunction = async () => {
    const endPoint = 'DashSettings/create';
    try {
      const res = await supistaApiPost(endPoint, settingsSchema, appID);
      if (!res.__d3__error) {
        // toast.success('Saved Successfully', {});
        handleSave(settingsSchema, settingsSchema['dashID']);
      }
    } catch (err) {
      console.log('error encountered while creating new dashboard', err);
    }
  };
  const removeFunction = async () => {
    const endPoint = 'DashSettings/delete';
    const dataForRemove = {
      dashID: settingsSchema.dashID || '',
      appID: appID || '',
    };
    const res = await supistaApiPost(endPoint, dataForRemove, appID);
    if (!res.__d3__error) {
      // toast.success('Saved Successfully', {});
      const deleted = true;
      handleSave(settingsSchema, settingsSchema['dashID'], deleted);
    }
  };
  return (
    <div className='MainUpperHeader'>
      <div className='title'>{mls('Dashboard Settings')}</div>
      <div className='subTitle'>{mls('Edit Dashboard Settings here')}</div>
      <div className='goleftSide'></div>
      {!isNew ? (
        <div className='deleteButtonDiv'>
          <button type='button' className='btn btn-sm btn-danger' onClick={() => removeFunction()}>
            <span className='indicator-label'>{mls('Delete')}</span>
          </button>
        </div>
      ) : null}
      <div className='saveButtonDiv'>
        <button className='btn btn-sm btn-primary' onClick={() => saveFunction()}>
          <span className='indicator-label'>{mls('Save')}</span>
        </button>
      </div>
      <div className='closeButton' onClick={() => handleDashSettingsPopUp(false)}>
        <FaTimes />
      </div>
    </div>
  );
};

export default Header;
