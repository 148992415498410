import get from 'lodash.get';
import { useSelector } from 'react-redux';
import reduxConstants from '../redux/reduxConstants';

function useAppState(path = '') {
  const appState = useSelector(
    (state) => get(state[reduxConstants.STORE_NAME][reduxConstants.config.APP_STATE], path)
    // state[reduxConstants.STORE_NAME][reduxConstants.config.SCHEMA]),
  );
  if (appState) {
    return appState;
  }
  return false;
}

export default useAppState;
