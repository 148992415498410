import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header';
import ColumnToggleWapper from './features/columnToggle/ColumnToggleWapper';
import DragableWrapper from './features/DragableThings/DragableWrapper';
import { defaultDashboardSchema } from './data/defaultSchema';
import { useEffect, useMemo, useState } from 'react';
import { populateFunction } from './utils/populateFunction';
import './dashboardGlobal.scss';
import NonSupportedScreen from './components/NonSupportedScreen/NonSupportedScreen';
import { chartFilterFromAnalyticsFunction } from 'lib/reusable-components/reusableFunction/reusableFunction';

interface mainProps {
  appID: string;
  dashID: string;
  isNewChart: boolean;
  dashBoardSchema: any;
  analyticsSchema: any;
  handleDashSettingsPopUp: Function;
  handleSave: Function;
}
const DashboardSettingsWrapper = ({
  appID,
  dashID,
  isNewChart,
  dashBoardSchema,
  analyticsSchema,
  handleDashSettingsPopUp,
  handleSave,
}: mainProps) => {
  const [populateState, setPopulateState] = useState(!isNewChart);
  const [populateCount, setPopulateCount] = useState(0);

  const [projectSheets, setProjectSheets] = useState(analyticsSchema.appSheets);
  const [chartsData, setChartsData] = useState(analyticsSchema.appCharts);

  const [sections, setSections] = useState([defaultDashboardSchema.sections]);

  const [dashboardName, setDashboardName] = useState(defaultDashboardSchema.dashboardName);
  const [dashboardDescription, setDashboardDescription] = useState(
    defaultDashboardSchema.dashboardDesc
  );
  const [chartSearch, setChartSearch] = useState({ search: '' });
  const handleSearch = (e: any) => {
    setChartSearch((currentValue) => {
      chartFilterFromAnalyticsFunction({
        allAppSheet: analyticsSchema.appSheets,
        chartsData: chartsData,
        setProjectSheets,
        ...currentValue,
        search: e.target.value,
      });
      return { ...currentValue, search: e.target.value };
    });
  };

  const stateProps = {
    appID,
    dashID,

    chartSearch,
    handleSearch,

    chartsData,
    setChartsData,
    projectSheets,
    setProjectSheets,
    sections,
    setSections,
    dashboardName,
    setDashboardName,
    dashboardDescription,
    setDashboardDescription,
    populateCount,
    setPopulateCount,
  };

  const settingsSchema = useMemo(() => {
    const newSechma = {
      // [dashID]: {
      name: dashboardName,
      desc: dashboardDescription,
      sections: sections,
      appID: appID,
      dashID: dashID,
      // },
    };
    return newSechma;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, dashboardName, dashboardDescription]);

  useEffect(() => {
    populateFunction({ stateProps, dashBoardSchema, populateState, setPopulateState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateState]);
  return (
    <div className='DashboardSettings'>
      <NonSupportedScreen closeButton={() => handleDashSettingsPopUp(false)} />

      <ToastContainer />
      <Header
        settingsSchema={settingsSchema}
        appID={appID}
        handleDashSettingsPopUp={handleDashSettingsPopUp}
        handleSave={handleSave}
        isNew={isNewChart}
      />
      <DragableWrapper stateProps={stateProps}>
        <ColumnToggleWapper stateProps={stateProps} />
      </DragableWrapper>
    </div>
  );
};

export default DashboardSettingsWrapper;
