import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from '@visx/shape';
import { scaleOrdinal } from '@visx/scale';

import { sortValues } from './helpers/sortValues';
import { createColorArray, createVariantColorArray } from '../../helpers/colorHelper';
import { defaultColors } from '../../constants/constants';

// Component for building Funnel chart
const FunnelChart = ({
  xScale,
  yScale,
  x,
  y,
  onMouseOver,
  onMouseOut,
  xMax,
  colorType,
  gradientColor,
  chartColor,
}) => {
  const { sortedX, sortedY } = sortValues(x, y, true);

  let color = defaultColors[0];
  if (colorType === 'Monochromatic') {
    color = chartColor;
  } else if (colorType === 'Gradient') {
    color = gradientColor.from;
  }

  // Generate colors for chart
  const [colors, updateColors] = useState(
    createColorArray(sortedY.length, color || defaultColors[0])
  );

  // Scale for colors
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: sortedY,
        range: colors,
      }),
    [sortedY, colors]
  );

  useEffect(() => {
    if (colorType === 'Monochromatic')
      updateColors(createColorArray(y.length, color || defaultColors[0]));
    else updateColors(createVariantColorArray(y.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y]);

  const displayChart = () =>
    sortedY.map((elem, index) => {
      const barHeight = xScale(sortedX[index]);
      const barY = yScale(elem);
      const datum = {
        x: sortedX[index],
        y: elem,
      };

      const xSpace = xMax - barHeight;
      let nextBarHeight = barHeight - 30;
      let nextXSpace = xMax - nextBarHeight;
      const nextBarY = barY + yScale.bandwidth();
      if (index !== sortedY.length - 1) {
        nextBarHeight = xScale(sortedX[index + 1]);
        nextXSpace = xMax - nextBarHeight;
      }
      const point1 = [xSpace / 2, barY];
      const point2 = [xSpace / 2 + barHeight, barY];
      const point3 = [nextXSpace / 2, nextBarY];
      const point4 = [nextXSpace / 2 + nextBarHeight, nextBarY];
      const points = [point1, point2, point4, point3];

      const fill = colorScale(elem);

      return (
        <Polygon
          key={`poly-${x[index]}`}
          points={points}
          fill={fill}
          stroke='#000000'
          onMouseMove={(e) => onMouseOver(e, datum)}
          // onMouseLeave={onMouseOut}
        />
      );
    });

  return <>{displayChart()}</>;
};

FunnelChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  xMax: PropTypes.number.isRequired,
  isMonochromatic: PropTypes.bool.isRequired,
};

export default FunnelChart;
