import React, { ReactNode } from 'react';
import { Tooltip } from '@visx/xychart';
import { mls } from 'lib/multilanguagesupport';
import { getStyling } from '../../helpers/colorHelper';
import { GlyphDot } from '@visx/glyph';
import { numberTickFormat } from '../../helpers/tickFormatHelpers';
import {
  dateTimeFormatter,
  numberFormatter,
} from 'lib/reusable-components/reusableFunction/formatter';
import { allDropableID } from 'lib/reusable-components/reusableData/defaultChartSchema';
export const defaultToolTipBelongTo = {
  XY: 'XY',
  YX: 'YX',
};
// export const xAxisId = 'xAxisId';
// export const yAxisId = 'yAxisId';
const { xAxisId, yAxisId, groupId } = allDropableID;

const boxStyle = {
  width: '1.5rem',
  height: '1.5rem',
  // background: 'aqua',
  // boxShadow: 'inset 0 0 0 1px black, inset 0 1px 4px 1px white',
  // boxShadow: 'inset 0 0 0 1px black',
  borderRadius: '3px',
};
interface ColorBoxProps {
  backgroundColor: string;
  opacity: number;
}
const ColorBox = ({ backgroundColor, opacity }: ColorBoxProps) => (
  <div
    style={{
      ...boxStyle,
      backgroundColor,
      opacity: opacity,
    }}
  ></div>
);
interface ValueOuterDiv {
  children: ReactNode;
  backgroundColor?: string;
  opacity?: number;
  colorBox?: boolean;
  highlight?: boolean;
  style?: any;
}
const ValueOuterDiv = ({
  children,
  colorBox = false,
  highlight = false,
  backgroundColor = '',
  opacity = 1,
  style,
}: ValueOuterDiv) => {
  const highlightedStyle = {
    display: 'flex',
    gap: '.5rem',
    // marginBottom: '.5rem',
    padding: '.25rem .25rem',
    // textAlign: colorBox ? 'left' : 'center',
    backgroundColor: '#E3F4F4',
    ...style,
    borderRadius: '3px',
    alignItems: 'center',
  };
  const normalStyle = {
    alignItems: 'center',
    display: 'flex',
    gap: '.5rem',
    // marginBottom: '.5rem',
    padding: '.25rem',

    // textAlign: colorBox ? 'left' : 'center',
    ...style,
  };
  return (
    <div style={highlight ? { ...highlightedStyle } : { ...normalStyle }}>
      {/* uncommnet this for custom opacity */}
      {/* {colorBox ? <ColorBox backgroundColor={backgroundColor} opacity={opacity} /> : null} */}
      {colorBox ? <ColorBox backgroundColor={backgroundColor} opacity={1} /> : null}
      <div style={{ width: colorBox ? 'calc(100% - 2rem)' : '100%' }}>{children}</div>
    </div>
  );
};

interface XYToolTipProps {
  defaultToolTipStyling: any;
  accessors: any;
  theme: any;
  labels: any;
  xAxisLabels: any;
  yAxisLabels: any;
  belongsTo?: string;
  backgroundColor: string;
  isColor?: boolean;
  toggle: string;
  isDateTime: boolean;
  showSeriesGlyphs?: boolean;
  showVerticalCrosshair?: boolean;
  type: string;
}

const XYToolTip = (XYToolTipProps: XYToolTipProps) => {
  const {
    defaultToolTipStyling,
    accessors,
    theme,
    xAxisLabels,
    yAxisLabels,
    belongsTo = defaultToolTipBelongTo.XY,
    backgroundColor,
    isColor = false,
    isDateTime,
    toggle,
    showSeriesGlyphs = true,
    showVerticalCrosshair = true,
    type,
  } = XYToolTipProps;
  // .reverse()
  return (
    <Tooltip
      snapTooltipToDatumX
      snapTooltipToDatumY
      showVerticalCrosshair={showVerticalCrosshair}
      showSeriesGlyphs={showSeriesGlyphs}
      showHorizontalCrosshair={!showVerticalCrosshair}
      zIndex={9999}
      applyPositionStyle={true}
      renderGlyph={(glyphElem) => {
        const { x, y, size, onPointerMove, onPointerOut, onPointerUp, key } = glyphElem;
        const handlers = { onPointerMove, onPointerOut, onPointerUp };
        return (
          <GlyphDot
            left={x}
            top={y}
            stroke={'white'}
            fill={theme.colors[key]}
            r={size}
            {...handlers}
          />
        );
      }}
      style={{ ...defaultToolTipStyling, backgroundColor }}
      renderTooltip={(renderData: any) => {
        const { tooltipData } = renderData;

        const categoricalTooltipData = theme?.tooltip?.style.find((styleElem: any) => {
          if (belongsTo === defaultToolTipBelongTo.XY) {
            return styleElem.uniqueColumnName === Object.keys(xAxisLabels)[0];
          }
          if (belongsTo === defaultToolTipBelongTo.YX) {
            return styleElem.uniqueColumnName === Object.keys(yAxisLabels)[0];
          }
        });

        const { datum } = tooltipData?.nearestDatum;
        const isStack = Object.keys(tooltipData?.datumByKey ?? {}).length >= 2 ? true : false;
        const numaricalTooltipData = theme?.tooltip?.style.find((styleElem: any) => {
          if (belongsTo === defaultToolTipBelongTo.XY) {
            if (styleElem?.axis === yAxisId) {
              return true;
            }
          }
          if (belongsTo === defaultToolTipBelongTo.YX) {
            if (styleElem?.axis === xAxisId) {
              return true;
            }
          }
        });
        const anyNumTrue = theme?.tooltip?.style.some((styleElem: any) => {
          if (belongsTo === defaultToolTipBelongTo.XY) {
            if (styleElem?.axis === yAxisId) {
              return styleElem?.show;
            }
          }
          if (belongsTo === defaultToolTipBelongTo.YX) {
            if (styleElem?.axis === xAxisId) {
              return styleElem?.show;
            }
          }
        });
        const colorAxisTooltipData = theme?.tooltip?.style.find((styleElem: any) => {
          if (styleElem?.axis === groupId) {
            return true;
          }
        });

        if (!anyNumTrue && !categoricalTooltipData?.show && isColor && !colorAxisTooltipData?.show)
          return null;
        const maxMultipleValue = 10;

        return (
          <div>
            {categoricalTooltipData?.show ? (
              <>
                {belongsTo === defaultToolTipBelongTo.XY
                  ? Object.values(xAxisLabels).map((val: any, i) => {
                      return (
                        <ValueOuterDiv colorBox={false}>
                          {val}:&nbsp;
                          <strong>
                            {isDateTime
                              ? dateTimeFormatter({
                                  type: { ...categoricalTooltipData?.formatter, format: toggle },
                                  value: accessors.xAccessor(datum)?.toString(),
                                })
                              : accessors.xAccessor(datum)?.toString()}
                          </strong>
                        </ValueOuterDiv>
                      );
                    })
                  : null}
                {belongsTo === defaultToolTipBelongTo.YX
                  ? Object.values(yAxisLabels).map((val, i) => {
                      return (
                        <ValueOuterDiv colorBox={false}>
                          {val}:&nbsp;
                          <strong>
                            {isDateTime
                              ? dateTimeFormatter({
                                  type: { ...categoricalTooltipData?.formatter, format: toggle },
                                  value: accessors.yAccessor(datum)?.toString(),
                                })
                              : accessors.yAccessor(datum)?.toString()}
                          </strong>
                        </ValueOuterDiv>
                      );
                    })
                  : null}
              </>
            ) : null}
            {isColor && anyNumTrue ? (
              <>
                {belongsTo === defaultToolTipBelongTo.XY
                  ? Object.values(yAxisLabels).map((val: any, i) => {
                      const total = Object.keys(tooltipData?.datumByKey ?? {}).reduce(
                        (previous, currentKey, i) => {
                          const currentValue = tooltipData?.datumByKey[currentKey]?.datum?.y;
                          if (i <= 1) {
                            const previousValue = tooltipData?.datumByKey[previous]?.datum?.y;
                            return previousValue + currentValue;
                          }
                          return currentValue + previous;
                        }
                      );
                      return (
                        <ValueOuterDiv colorBox={false}>
                          {val}:&nbsp;
                          <strong>
                            {numberFormatter({
                              type: numaricalTooltipData?.formatter,
                              value: Number(total),
                            })}
                          </strong>
                        </ValueOuterDiv>
                      );
                    })
                  : null}
                {belongsTo === defaultToolTipBelongTo.YX
                  ? Object.values(xAxisLabels).map((val, i) => {
                      const total = Object.keys(tooltipData?.datumByKey ?? {}).reduce(
                        (previous, currentKey, i) => {
                          const currentValue = tooltipData?.datumByKey[currentKey]?.datum?.x;
                          if (i <= 1) {
                            const previousValue = tooltipData?.datumByKey[previous]?.datum?.x;
                            return previousValue + currentValue;
                          }
                          return currentValue + previous;
                        }
                      );

                      return (
                        <ValueOuterDiv colorBox={false}>
                          {val}:&nbsp;
                          <strong>
                            {numberFormatter({
                              type: numaricalTooltipData?.formatter,
                              value: Number(total),
                            })}
                          </strong>
                        </ValueOuterDiv>
                      );
                    })
                  : null}
              </>
            ) : null}
            {(
              isColor
                ? (categoricalTooltipData?.show || anyNumTrue) && colorAxisTooltipData?.show
                : categoricalTooltipData?.show && isStack && anyNumTrue
            ) ? (
              <hr />
            ) : null}
            {colorAxisTooltipData?.show ? (
              <>
                <ValueOuterDiv colorBox={false}>
                  <strong>{colorAxisTooltipData?.label}</strong>
                </ValueOuterDiv>
              </>
            ) : null}
            {(type === 'BARGROUP'
              ? Object.keys(tooltipData?.datumByKey ?? {})
              : Object.keys(tooltipData?.datumByKey ?? {}).reverse()
            ).map((key, i) => {
              if (i >= maxMultipleValue) {
                return null;
              }

              // Color Charts
              if (isColor) {
                const valueStyleData = theme?.tooltip?.valueStyle.find(
                  (item: any) =>
                    (item.originalLabel || item.uniqueColumnName || item.column) === key
                );
                if (!colorAxisTooltipData?.show) return null;
                const finalStyle = getStyling({
                  color: theme.colors[key],
                  textStyle: theme.tooltip.textStyle,
                  fontHeight: theme.tooltip.fontHeight,
                  opacity: colorAxisTooltipData?.opacity,
                });

                return (
                  <>
                    <ValueOuterDiv
                      backgroundColor={finalStyle.color}
                      opacity={finalStyle.opacity}
                      colorBox={true}
                      highlight={isStack ? key === tooltipData?.nearestDatum?.key : false}
                    >
                      {mls(valueStyleData?.label)}:&nbsp;
                      {/* {mls(labels[key])}:&nbsp; */}
                      {belongsTo === defaultToolTipBelongTo.XY ? (
                        <strong>
                          {numberFormatter({
                            type: colorAxisTooltipData?.formatter,
                            value: accessors.yAccessor(tooltipData.datumByKey[key].datum),
                          })}
                        </strong>
                      ) : null}
                      {belongsTo === defaultToolTipBelongTo.YX ? (
                        <strong>
                          {numberFormatter({
                            type: colorAxisTooltipData?.formatter,
                            value: accessors.xAccessor(tooltipData.datumByKey[key].datum),
                          })}
                        </strong>
                      ) : null}
                      <br />
                    </ValueOuterDiv>
                  </>
                );
              }

              // Normal XY Axis Charts

              const tStyling = theme.tooltip.style.find(
                (item: any) => (item.uniqueColumnName || item.column) === key
              );
              if (!tStyling?.show) return null;
              const finalStyle = getStyling({
                color: theme.colors[key],
                textStyle: theme.tooltip.textStyle,
                fontHeight: theme.tooltip.fontHeight,
                opacity: tStyling?.opacity,
              });

              if (!isStack) {
                return (
                  <ValueOuterDiv colorBox={false}>
                    {mls(tStyling?.label)}:&nbsp;
                    {belongsTo === defaultToolTipBelongTo.XY ? (
                      <strong>
                        {numberFormatter({
                          type: tStyling?.formatter,
                          value: accessors.yAccessor(tooltipData.datumByKey[key].datum),
                        })}
                      </strong>
                    ) : null}
                    {belongsTo === defaultToolTipBelongTo.YX ? (
                      <strong>
                        {numberFormatter({
                          type: tStyling?.formatter,
                          value: accessors.xAccessor(tooltipData.datumByKey[key].datum),
                        })}
                      </strong>
                    ) : null}
                    {React.Children.toArray(
                      Object.keys(tooltipData?.datumByKey[key]?.datum?.z ?? {}).map((zDataKey) => {
                        const zData = tooltipData?.datumByKey[key]?.datum?.z[zDataKey] ?? {};
                        const tStyling = theme.tooltip.style.find(
                          (item: any) => (item.uniqueColumnName || item.column) === zDataKey
                        );
                        const finalStyle = getStyling({
                          color: theme.colors[zDataKey],
                          textStyle: theme.tooltip.textStyle,
                          fontHeight: theme.tooltip.fontHeight,
                          opacity: tStyling?.opacity,
                        });
                        return (
                          <ValueOuterDiv
                            backgroundColor={finalStyle.color}
                            opacity={finalStyle.opacity}
                            colorBox={true}
                            highlight={
                              isStack ? zDataKey === tooltipData?.nearestDatum?.key : false
                            }
                          >
                            {mls(tStyling?.label)}:&nbsp;
                            <strong>
                              {numberFormatter({
                                type: tStyling?.formatter,
                                value: zData,
                              })}
                            </strong>
                            <br />
                          </ValueOuterDiv>
                        );
                      })
                    )}
                  </ValueOuterDiv>
                );
              }

              return (
                <ValueOuterDiv
                  backgroundColor={finalStyle.color}
                  opacity={finalStyle.opacity}
                  colorBox={true}
                  highlight={isStack ? key === tooltipData?.nearestDatum?.key : false}
                >
                  {/* {mls(labels[key])}:&nbsp; */}
                  {mls(tStyling?.label)}:&nbsp;
                  {belongsTo === defaultToolTipBelongTo.XY ? (
                    <strong>
                      {numberFormatter({
                        type: tStyling?.formatter,
                        value: accessors.yAccessor(tooltipData.datumByKey[key].datum),
                      })}
                    </strong>
                  ) : null}
                  {belongsTo === defaultToolTipBelongTo.YX ? (
                    <strong>
                      {numberFormatter({
                        type: tStyling?.formatter,
                        value: accessors.xAccessor(tooltipData.datumByKey[key].datum),
                      })}
                    </strong>
                  ) : null}
                  <br />
                </ValueOuterDiv>
              );
            })}
            {Object.keys(tooltipData?.datumByKey ?? {}).length > maxMultipleValue ? (
              <ValueOuterDiv colorBox={false}>...</ValueOuterDiv>
            ) : null}
          </div>
        );
      }}
    />
  );
};

interface XYMultiAxisToolTipProps {
  defaultToolTipStyling: any;
  accessors: any;
  theme: any;
  labels: any;
  xAxisLabels: any;
  yAxisLabels: any;
  backgroundColor: string;
}
const XYMultiAxisToolTip = (XYMultiAxisToolTipProps: XYMultiAxisToolTipProps) => {
  const { defaultToolTipStyling, accessors, theme, xAxisLabels, yAxisLabels, backgroundColor } =
    XYMultiAxisToolTipProps;
  const isXAxisShow = theme?.tooltip?.style.some((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return styleElem?.show;
    }
  });
  const isYAxisShow = theme?.tooltip?.style.some((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return styleElem?.show;
    }
  });

  if (!isXAxisShow && !isYAxisShow) {
    return <></>;
  }

  return (
    <Tooltip
      snapTooltipToDatumX
      snapTooltipToDatumY
      showVerticalCrosshair
      // showDatumGlyph
      style={{ ...defaultToolTipStyling, backgroundColor }}
      renderTooltip={({ tooltipData, colorScale }: any) => {
        const { datum } = tooltipData?.nearestDatum;
        return (
          <div>
            <div style={{ marginBottom: 6 }}>
              {Object.keys(xAxisLabels).map((val, i) => {
                const labels = theme?.tooltip?.style.find((styleElem: any) => {
                  return styleElem.uniqueColumnName === val;
                });
                if (!labels?.show) {
                  return null;
                }
                return (
                  <ValueOuterDiv colorBox={false}>
                    <strong>{xAxisLabels[val]}:</strong>&nbsp;
                    {datum.parents[i] || accessors.xAccessor(datum).split('-')[0]}
                  </ValueOuterDiv>
                );
              })}
            </div>
            {isXAxisShow && isYAxisShow ? <hr /> : null}
            {datum.y.map((obj: any, i: number) => {
              const key = Object.keys(obj)[0];
              const tStyling = theme.tooltip.style.find(
                (item: any) => (item.uniqueColumnName || item.column) === key
              );

              const label = yAxisLabels[key];
              const val = obj[key].data[0] ?? '';
              if (!tStyling.show) return null;
              const finalStyle = getStyling({
                color: theme.colors[key],
                textStyle: theme.tooltip.textStyle,
                fontHeight: theme.tooltip.fontHeight,
                opacity: tStyling?.opacity,
              });

              return (
                <ValueOuterDiv
                  backgroundColor={finalStyle.color}
                  opacity={finalStyle.opacity}
                  colorBox={true}
                  highlight={i + 1 === tooltipData?.nearestDatum?.key}
                >
                  <strong>{label}</strong>:&nbsp;
                  <span>{val}</span>
                </ValueOuterDiv>
              );
            })}
          </div>
        );
      }}
    />
  );
};

interface pieToolTipProps {
  defaultToolTipStyling: any;
  TooltipInPortal: any;
  // tooltipHoverHandler: any;
  // handleMouseLeave: any;
  tooltipLeft: any;
  tooltipTop: any;
  tooltipData: any;
  tooltipColorsDict: any;
  xAxisLabel: any;
  yAxisLabel: any;
  others: any;
  theme: any;
  backgroundColor: string;
  otherSum: string;
  maxValue: number;
}

const calculatePercentageInRange = ({
  value,
  min,
  range,
}: {
  value: number;
  min: number;
  range: number;
}) => {
  if (range === 0) {
    return 0;
  }
  const adjustedValue = value - min;
  const numb = (adjustedValue / range) * 100;
  const rounded = Math.round((numb + Number.EPSILON) * 100) / 100;
  return rounded;
};
const PieToolTip = (pieToolTipProps: pieToolTipProps) => {
  const {
    defaultToolTipStyling,
    TooltipInPortal,
    // tooltipHoverHandler,
    // handleMouseLeave,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    tooltipColorsDict,

    others,
    backgroundColor,

    theme,
    maxValue = 0,
  } = pieToolTipProps;
  const xAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });
  const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return true;
    }
    return false;
  });

  if (!xAxisValue?.show && !yAxisValue?.show) {
    return <></>;
  }

  const SimpleTooltip = (
    <>
      {xAxisValue?.show ? (
        <ValueOuterDiv
          // backgroundColor={getStyling(tooltipColorsDict[tooltipData?.x]).color ?? tooltipData.arcFill}
          backgroundColor={tooltipData.arcFill}
          opacity={getStyling(tooltipColorsDict[tooltipData?.x]).opacity}
          colorBox={true}
        >
          {mls(xAxisValue?.label)}:{'\t'}
          <strong>{tooltipData?.x === '' ? 'NULL' : tooltipData?.x}</strong>
        </ValueOuterDiv>
      ) : null}

      {xAxisValue?.show && yAxisValue?.show ? <hr /> : null}
      {yAxisValue?.show ? (
        <ValueOuterDiv colorBox={false}>
          {mls(yAxisValue?.label)}:{'\t'}
          <strong>
            {numberFormatter({
              type: yAxisValue?.formatter,
              value: tooltipData?.y,
            })}
            &nbsp;(
            {calculatePercentageInRange({
              value: Math.abs(tooltipData?.y),
              min: 0,
              range: maxValue,
            })}
            %)
          </strong>
        </ValueOuterDiv>
      ) : null}
    </>
  );

  // Other ToolTip component

  const OthersToolTip = (
    <div>
      {xAxisValue?.show ? (
        <ValueOuterDiv
          backgroundColor={tooltipData.arcFill}
          opacity={getStyling(tooltipColorsDict[tooltipData?.x]).opacity}
          colorBox={true}
        >
          {mls(xAxisValue?.label)}:{'\t'}
          <strong>{tooltipData?.x === '' ? 'NULL' : tooltipData?.x}</strong>
        </ValueOuterDiv>
      ) : null}
      {xAxisValue?.show && yAxisValue?.show ? <hr /> : null}
      {yAxisValue?.show ? (
        <div>
          {React.Children.toArray(
            others.map((e: any, i: number) => {
              if (i >= 10) {
                return null;
              }
              return (
                <div
                  key={`tooltip-${e.x}-${e.y}`}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  {e.x}:{'\t'}
                  <strong>
                    {numberFormatter({
                      type: yAxisValue?.formatter,
                      value: e?.y,
                    })}
                    &nbsp;(
                    {calculatePercentageInRange({
                      value: Math.abs(e?.y),
                      min: 0,
                      range: maxValue,
                    })}
                    %)
                  </strong>
                </div>
              );
            })
          )}
          {others.length > 10 ? <>...</> : null}
        </div>
      ) : null}
    </div>
  );

  return (
    <TooltipInPortal
      // onMouseEnter={tooltipHoverHandler}
      // onMouseLeave={handleMouseLeave}
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData?.x === 'Others' ? OthersToolTip : SimpleTooltip}
    </TooltipInPortal>
  );
};

interface ScatterPlotToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  isGroupType: any;
  xAxisLabel: any;
  yAxisLabel: any;
  colorScale: any;
  backgroundColor: string;
}
const ScatterPlotToolTip = (ScatterPlotToolTipProps: ScatterPlotToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData = '   ',
    theme,
    isGroupType = false,

    colorScale,
    backgroundColor,
  } = ScatterPlotToolTipProps;

  if (typeof tooltipData === 'string') {
    return <></>;
  }
  const xAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });
  const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return true;
    }
    return false;
  });
  const groupData = theme.tooltip.style.find((item: any) => item.axis === 'groupId');

  if (!xAxisValue?.show && !yAxisValue?.show && !groupData?.show) {
    return <></>;
  }

  const tStyling = theme.tooltip.valueStyle.find(
    (item: any) => item.originalLabel === (tooltipData?.[2] ?? '')
  );

  const finalStyle = getStyling({
    color: isGroupType ? colorScale(tooltipData[2]) ?? tStyling?.color : '',
    // color: theme.colors[tooltipData[2]],
    textStyle: theme.tooltip.textStyle,
    fontHeight: theme.tooltip.fontHeight,
    opacity: tStyling?.opacity,
  });

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {isGroupType && (
            <>
              {groupData?.show ? (
                <>
                  <ValueOuterDiv
                    backgroundColor={finalStyle.color}
                    opacity={finalStyle.opacity}
                    colorBox={true}
                  >
                    {groupData?.label}:&nbsp;<strong>{tooltipData[2]}</strong>
                  </ValueOuterDiv>
                </>
              ) : null}
            </>
          )}
          {(xAxisValue?.show || yAxisValue?.show) && groupData?.show ? <hr /> : null}
          {xAxisValue?.show ? (
            <ValueOuterDiv colorBox={false} style={{ textAlign: 'left' }}>
              {mls(xAxisValue?.label)}:{'\t'}
              <strong>
                {numberFormatter({
                  type: xAxisValue?.formatter,
                  value: tooltipData[0],
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
          {yAxisValue?.show ? (
            <ValueOuterDiv colorBox={false} style={{ textAlign: 'left' }}>
              {mls(yAxisValue?.label)}:{'\t'}
              <strong>
                {numberFormatter({
                  type: yAxisValue?.formatter,
                  value: tooltipData[1],
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};
interface BubblePlotToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  xAxisLabel: any;
  yAxisLabels: any[];
  xAxisLabels: any[];
  yAxisLabel: any;
  backgroundColor: string;
  isGroupType: boolean;
  colorScale: Function;
}
const BubblePlotToolTip = (BubblePlotToolTipProps: BubblePlotToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    backgroundColor,
    isGroupType = false,
    colorScale,
    yAxisLabels,
  } = BubblePlotToolTipProps;

  if (typeof tooltipData === 'string' || tooltipData === undefined) {
    return <></>;
  }
  const xAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });
  const yAxisShow = theme?.tooltip?.style.some((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return styleElem?.show;
    }
    return false;
  });
  const groupData = theme.tooltip.style.find((item: any) => item.axis === 'groupId');

  if (!xAxisValue?.show && !yAxisShow && !groupData?.show) {
    return <></>;
  }

  const finalStyle = getStyling({
    color: isGroupType ? colorScale(tooltipData[groupData?.uniqueColumnName]) : '',
    textStyle: theme.tooltip.textStyle,
    fontHeight: theme.tooltip.fontHeight,
  });

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <>
          {xAxisValue?.show && !isGroupType ? (
            <ValueOuterDiv colorBox={false} style={{ textAlign: 'left' }}>
              {mls(xAxisValue?.label)}:{'\t'}
              <strong>
                {numberFormatter({
                  type: xAxisValue?.formatter,
                  value: tooltipData[xAxisValue?.uniqueColumnName],
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}

          {isGroupType && groupData?.show ? (
            <ValueOuterDiv
              backgroundColor={finalStyle.color}
              opacity={finalStyle.opacity}
              colorBox={true}
            >
              {mls(groupData?.label)}:{'\t'}
              <strong>
                {numberFormatter({
                  type: groupData?.formatter,
                  // value: tooltipData[3],
                  value: tooltipData[groupData?.uniqueColumnName],
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
          {(
            isGroupType
              ? (xAxisValue?.show || yAxisShow) && groupData?.show
              : xAxisValue?.show && yAxisShow
          ) ? (
            <hr />
          ) : null}
          {xAxisValue?.show && isGroupType ? (
            <ValueOuterDiv colorBox={false} style={{ textAlign: 'left' }}>
              {mls(xAxisValue?.label)}:{'\t'}
              <strong>
                {numberFormatter({
                  type: xAxisValue?.formatter,
                  value: tooltipData[xAxisValue?.uniqueColumnName],
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
          {yAxisShow
            ? Object.values(yAxisLabels).map((val, i) => {
                const axisStyle = theme?.tooltip?.style.find((styleElem: any) => {
                  if (styleElem?.label === val) {
                    return styleElem?.show;
                  }
                  return false;
                });
                if (!axisStyle?.show) {
                  return <></>;
                }
                //
                return (
                  <ValueOuterDiv colorBox={false}>
                    {val}:&nbsp;
                    <strong>
                      {numberFormatter({
                        type: axisStyle?.formatter,
                        value: tooltipData[axisStyle?.uniqueColumnName],
                      })}
                    </strong>
                  </ValueOuterDiv>
                );
              })
            : null}
        </>
      )}
    </TooltipInPortal>
  );
};
interface GeoToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  labels: any;
  stateData: any;
  backgroundColor: string;
  selectedLabel: string;
}
const GeoToolTip = (GeoToolTipProps: GeoToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    labels,
    backgroundColor,
    selectedLabel,
  } = GeoToolTipProps;
  const xAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });
  const isXAxisShow = xAxisValue?.show;
  const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.uniqueColumnName === selectedLabel) {
      return true;
    }
    return false;
  });

  const isYAxisShow = yAxisValue?.show;

  if (!isXAxisShow && !isYAxisShow) {
    return <></>;
  }

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {isXAxisShow ? (
            <ValueOuterDiv
              backgroundColor={tooltipData?.x?.style?.color}
              opacity={tooltipData?.x?.style?.opacity / 100}
              colorBox={true}
            >
              {xAxisValue?.label}:<strong> {tooltipData?.x?.name}</strong>
            </ValueOuterDiv>
          ) : null}
          {isXAxisShow && isYAxisShow ? <hr /> : null}
          {isYAxisShow ? (
            <ValueOuterDiv colorBox={false}>
              {yAxisValue.label ?? labels[tooltipData?.y?.name]}:
              <strong>
                {numberFormatter({
                  type: yAxisValue?.formatter,
                  value: tooltipData?.y?.value,
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};

interface TreeMapToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  chartData: any;
  stateData: any;
  backgroundColor: string;
  maxValue: number;
}
const TreeMapToolTip = (TreeMapToolTipProps: TreeMapToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    chartData,
    backgroundColor,
    maxValue = 0,
  } = TreeMapToolTipProps;

  const findTooltipByAxis = (array: any, target: string) => {
    const filter = array?.find((elem: any) => elem?.axis === target);
    return filter;
  };
  const xAxisData = findTooltipByAxis(theme?.tooltip?.style, xAxisId);
  const yAxisData = findTooltipByAxis(theme?.tooltip?.style, yAxisId);
  if (!xAxisData?.show && !yAxisData?.show) {
    return <></>;
  }

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {xAxisData?.show ? (
            <ValueOuterDiv colorBox={false}>
              {xAxisData?.label}:<strong> {tooltipData.id}</strong>
            </ValueOuterDiv>
          ) : null}

          {chartData.showParent && (
            <div>
              <strong>Parent:</strong> {tooltipData.parent}
            </div>
          )}
          {yAxisData?.show ? (
            <ValueOuterDiv colorBox={false}>
              {yAxisData?.label}:{' '}
              <strong>
                {numberFormatter({
                  type: yAxisData?.formatter,
                  value: tooltipData.realValue,
                })}
                &nbsp;(
                {calculatePercentageInRange({
                  value: Math.abs(tooltipData?.realValue),
                  min: 0,
                  range: maxValue,
                })}
                %)
              </strong>
            </ValueOuterDiv>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};

interface RadarTypeToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  labels: any;
  stateData: any;
  backgroundColor: string;
}
const RadarTypeToolTip = (RadarTypeToolTipProps: RadarTypeToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    backgroundColor,
  } = RadarTypeToolTipProps;
  const datum = tooltipData?.nearestDatum?.datum;

  const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.uniqueColumnName === datum?.x) {
      return true;
    }
    return false;
  });
  const isYAxisShow = yAxisValue?.show;
  if (!isYAxisShow) {
    return <></>;
  }

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div
          style={{
            fontFamily: theme.tooltip.font,
            fontSize: theme.tooltip.fontHeight,
          }}
        >
          {isYAxisShow ? (
            <ValueOuterDiv colorBox={false}>
              <strong> {yAxisValue?.label}</strong>
            </ValueOuterDiv>
          ) : null}
          {isYAxisShow ? <hr /> : null}

          <>
            {Object.keys(datum?.y)?.map((valuesKey: string, i: number) => {
              const tStyling = theme.tooltip.valueStyle.find(
                (item: any) => (item.originalLabel || item.column) === valuesKey
              );

              const finalStyle = getStyling({
                color: theme.colors[valuesKey],
                textStyle: theme.tooltip.textStyle,
                fontHeight: theme.tooltip.fontHeight,
                opacity: tStyling?.opacity,
              });

              return (
                <ValueOuterDiv
                  backgroundColor={finalStyle.color}
                  opacity={finalStyle.opacity}
                  colorBox={true}
                  highlight={i === tooltipData?.nearestDatum?.key}
                >
                  {valuesKey}:&nbsp;
                  <strong>
                    <span>
                      {numberFormatter({
                        type: yAxisValue?.formatter,
                        value: datum?.y[valuesKey],
                      })}
                    </span>
                  </strong>
                </ValueOuterDiv>
              );
            })}
          </>
        </div>
      )}
    </TooltipInPortal>
  );
};
interface RadialLineTypeToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  labels: any;
  stateData: any;
  backgroundColor: string;
  automaticOpacity: number;
  toggle: string;
}
const RadialLineTypeToolTip = (RadialLineTypeToolTipProps: RadialLineTypeToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    backgroundColor,
    toggle,
  } = RadialLineTypeToolTipProps;

  // const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
  //   if (styleElem?.uniqueColumnName === datum?.x) {
  //     return true;
  //   }
  // });
  // const isYAxisShow = yAxisValue?.show;
  // if (!isYAxisShow) {
  //   return <></>;
  // }
  const isXAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });

  const isXAxisShow = isXAxisValue?.show;
  const isYAxisShow = theme?.tooltip?.style.some((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return styleElem?.show;
    }
    return false;
  });

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div
          style={{
            fontFamily: theme.tooltip.font,
            fontSize: theme.tooltip.fontHeight,
          }}
        >
          {isXAxisShow ? (
            <ValueOuterDiv colorBox={false}>
              {isXAxisValue?.label}:&nbsp;
              <strong>
                {dateTimeFormatter({
                  type: { ...isXAxisValue?.formatter, format: toggle },
                  value: tooltipData.x?.toString(),
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
          {/* {isYAxisShow ? (
            <ValueOuterDiv colorBox={false}>
              <strong> {labels[datum.x]}</strong>
            </ValueOuterDiv>
          ) : null} */}
          {isYAxisShow && isXAxisShow ? <hr /> : null}
          {isYAxisShow ? (
            <>
              {Object.keys(tooltipData?.y)?.map((valuesKey: string, i: number) => {
                const tStyling = theme?.tooltip?.style.find((styleElem: any) => {
                  if (styleElem?.uniqueColumnName === valuesKey) {
                    return true;
                  }
                  return false;
                });

                const finalStyle = getStyling({
                  color: theme.colors[valuesKey],
                  textStyle: theme.tooltip.textStyle,
                  fontHeight: theme.tooltip.fontHeight,
                  opacity: tStyling?.opacity,
                });

                return (
                  <ValueOuterDiv
                    backgroundColor={finalStyle.color}
                    opacity={finalStyle.opacity}
                    colorBox={true}
                    highlight={valuesKey === tooltipData?.key}
                  >
                    {tStyling?.label}:&nbsp;
                    <strong>
                      <span>
                        {numberFormatter({
                          type: tStyling?.formatter,
                          value: tooltipData?.y[valuesKey],
                        })}
                      </span>
                    </strong>
                  </ValueOuterDiv>
                );
              })}
            </>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};

interface FunnelTypeToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  colorScale: any;
  theme: any;
  backgroundColor: string;
}
const FunnelTypeToolTip = (FunnelTypeToolTipProps: FunnelTypeToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    backgroundColor,
    colorScale,
    theme,
  } = FunnelTypeToolTipProps;

  const xAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
    return false;
  });
  const yAxisValue = theme?.tooltip?.style.find((styleElem: any) => {
    if (styleElem?.axis === yAxisId) {
      return true;
    }
    return false;
  });
  if (!xAxisValue?.show && !yAxisValue?.show) {
    return <></>;
  }
  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, padding: tooltipData?.axis ? 6 : 12, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {xAxisValue?.show ? (
            <ValueOuterDiv
              backgroundColor={colorScale(tooltipData.x) ?? tooltipData?.arcFill}
              opacity={colorScale(tooltipData.x)?.opacity}
              colorBox={true}
            >
              {xAxisValue?.label}
              {': '}
              <strong>{tooltipData.x}</strong>
            </ValueOuterDiv>
          ) : null}
          {xAxisValue?.show && yAxisValue?.show ? <hr /> : null}
          {yAxisValue?.show ? (
            <ValueOuterDiv colorBox={false}>
              {yAxisValue?.label}
              {': '}
              <strong>
                {numberFormatter({
                  type: yAxisValue?.formatter,
                  value: tooltipData?.y,
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};
interface KpiMeterToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  kpiProbability: any;
  backgroundColor: string;
}
const KpiMeterToolTip = (KpiMeterToolTipProps: KpiMeterToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    backgroundColor,
    kpiProbability,
  } = KpiMeterToolTipProps;

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, padding: tooltipData?.axis ? 6 : 12, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {kpiProbability?.daysLeft ? (
            <ValueOuterDiv colorBox={false}>
              <b>{kpiProbability?.daysLeft} Days</b> to go
            </ValueOuterDiv>
          ) : null}
          {kpiProbability?.probability?.enoughDays ? (
            <>
              {kpiProbability?.probability?.precentageData?.goalAchieved ? (
                <ValueOuterDiv colorBox={false}>
                  <b>Target Achieved</b>
                </ValueOuterDiv>
              ) : (
                <ValueOuterDiv colorBox={false}>
                  Target Achieving Probability{' '}
                  <b>
                    ({numberTickFormat(kpiProbability?.probability?.precentageData?.precentage)}%)
                  </b>
                </ValueOuterDiv>
              )}
            </>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};

interface HeatMapToolTipProps {
  defaultToolTipStyling: any;
  tooltipLeft: any;
  tooltipTop: any;
  TooltipInPortal: any;
  tooltipData: any;
  theme: any;
  chartData: any;
  binData: any;
  backgroundColor: string;
}
const HeatMapToolTip = (HeatMapToolTipProps: HeatMapToolTipProps) => {
  const {
    defaultToolTipStyling,
    tooltipLeft,
    tooltipTop,
    TooltipInPortal,
    tooltipData,
    theme,
    chartData,
    backgroundColor,
  } = HeatMapToolTipProps;
  const findTooltipByAxis = (array: any, target: string) => {
    const filter = array?.find((elem: any) => elem?.axis === target);
    return filter;
  };
  const xAxisData = findTooltipByAxis(theme?.tooltip?.style, xAxisId);
  const yAxisData = findTooltipByAxis(theme?.tooltip?.style, yAxisId);
  const magnitudeAxisData = findTooltipByAxis(theme?.tooltip?.style, groupId);
  if (!xAxisData?.show && !yAxisData?.show && !magnitudeAxisData?.show) {
    return <></>;
  }

  return (
    <TooltipInPortal
      style={{ ...defaultToolTipStyling, backgroundColor }}
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
    >
      {tooltipData && (
        <div>
          {xAxisData?.show ? (
            <ValueOuterDiv colorBox={false}>
              {xAxisData?.label}:<strong> {tooltipData.xAxisValue}</strong>
            </ValueOuterDiv>
          ) : null}

          {chartData.showParent && (
            <div>
              <strong>Parent:</strong> {tooltipData.parent}
            </div>
          )}
          {yAxisData?.show ? (
            <ValueOuterDiv colorBox={false}>
              {yAxisData?.label}: <strong>{tooltipData.yAxisValue}</strong>
            </ValueOuterDiv>
          ) : null}
          {xAxisData?.show || (yAxisData?.show && magnitudeAxisData?.show) ? <hr /> : null}
          {magnitudeAxisData?.show ? (
            <ValueOuterDiv colorBox={false}>
              {magnitudeAxisData?.label}:{' '}
              <strong>
                {numberFormatter({
                  type: magnitudeAxisData?.formatter,
                  value: tooltipData?.count,
                })}
              </strong>
            </ValueOuterDiv>
          ) : null}
        </div>
      )}
    </TooltipInPortal>
  );
};
export {
  XYToolTip,
  XYMultiAxisToolTip,
  PieToolTip,
  ScatterPlotToolTip,
  BubblePlotToolTip,
  GeoToolTip,
  TreeMapToolTip,
  RadarTypeToolTip,
  RadialLineTypeToolTip,
  FunnelTypeToolTip,
  KpiMeterToolTip,
  HeatMapToolTip,
};
export default XYToolTip;
