export const organiztionStuctureType = {
  inputString: 'inputString',
  email: 'email',
  multiSelect: 'multiSelect',
  circleMultiSelect: 'circleMultiSelect',
  departmentMultiSelect: 'departmentMultiSelect',
  departmentDropdown: 'departmentDropdown',
  circleDropdown: 'circleDropdown',
  employeesDropdown: 'employeesDropdown',
  managerDropdown: 'managerDropdown',
};

export const organizationStructureOptions = {
  departments: {
    key: 'departments',
    lable: 'Departments',
    fields: [
      {
        key: 'name',
        label: 'Name',
        defaultValue: '',
        maxLength: 30,
        required: true,
        type: organiztionStuctureType.inputString,
      },
      {
        key: 'description',
        label: 'Description',
        defaultValue: '',
        required: false,
        maxLength: 100,
        type: organiztionStuctureType.inputString,
      },
    ],
    endPoint: 'KpiDepartments',
    id: 'departmentID',
  },
  circles: {
    key: 'circles',
    lable: 'Circles',
    fields: [
      {
        key: 'name',
        label: 'Circle Name',
        defaultValue: '',
        maxLength: 30,
        required: true,
        type: organiztionStuctureType.inputString,
      },
      {
        key: 'containingCircles',
        label: 'Containing Circles',
        mainKey: 'circleID',
        defaultValue: [],
        required: false,
        type: organiztionStuctureType.circleMultiSelect,
      },
    ],
    endPoint: 'KpiCircles',
    id: 'circleID',
  },
  employees: {
    key: 'employees',
    lable: 'Employees',
    fields: [
      {
        key: 'name',
        label: 'Employee Name',
        defaultValue: '',
        maxLength: 30,
        required: true,
        type: organiztionStuctureType.inputString,
      },
      {
        key: 'email',
        label: 'Email',
        defaultValue: '',
        maxLength: 50,
        required: true,
        type: organiztionStuctureType.email,
      },
      {
        key: 'department',
        label: 'Department',
        mainKey: 'departmentID',
        defaultValue: [],
        required: false,
        type: organiztionStuctureType.departmentMultiSelect,
      },
      {
        key: 'circle',
        label: 'Circle',
        mainKey: 'circleID',
        defaultValue: [],
        required: false,
        type: organiztionStuctureType.circleMultiSelect,
      },
    ],
    endPoint: 'KpiEmployees',
    id: 'employeeID',
  },
  kpiManager: {
    key: 'kpiManager',
    lable: 'KPI Manager',
    fields: [
      {
        key: 'manager',
        label: 'Manager',
        defaultValue: '',
        mainKey: 'userID',
        nameKey: 'emailID',
        required: false,
        type: organiztionStuctureType.managerDropdown,
      },
      {
        key: 'department',
        label: 'Department',
        defaultValue: '',
        mainKey: 'departmentID',
        required: false,
        type: organiztionStuctureType.departmentDropdown,
      },
      {
        key: 'circle',
        label: 'Circle',
        defaultValue: '',
        mainKey: 'circleID',
        required: false,
        type: organiztionStuctureType.circleDropdown,
      },
    ],
    endPoint: 'KpiManagers',
    id: 'managerID',
  },
};

export const defaultPopulatedData = {
  departmentsData: [
    {
      id: 'ff6e6a86-a4c0-4ff8-ad57-201cc68355d8',
      name: 'Management',
      description: 'Management Department',
    },
    {
      id: 'dce52be7-3249-4c8d-93ef-b38c0f73bfa0',
      name: 'Social Media',
      description: 'Social Media Department',
    },
    {
      id: 'a21825da-d78e-48f8-ab5f-f14032d46bf8',
      name: 'IT',
      description: 'Infromation Technology Department',
    },
    {
      id: '32d7f63d-0610-4706-a597-ad5df667f7b3',
      name: 'Sales',
      description: 'Sales Department',
    },
  ],
  circlesData: [
    {
      id: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
      name: 'Faridabad',
      containingCircles: [
        '8f3e0630-3b4f-491c-8181-82eba3ba2a2d',
        '16ca596a-16e7-470d-96f4-b6e41222092d',
      ],
    },
    {
      id: '16ca596a-16e7-470d-96f4-b6e41222092d',
      name: 'Haryana',
      containingCircles: ['8f3e0630-3b4f-491c-8181-82eba3ba2a2d'],
    },
    {
      id: '4116a445-0d32-4f23-b109-925d5b15cb8c',
      name: 'Delhi',
      containingCircles: ['8f3e0630-3b4f-491c-8181-82eba3ba2a2d'],
    },
    {
      id: '8f3e0630-3b4f-491c-8181-82eba3ba2a2d',
      name: 'India',
    },
  ],
  employeesData: [
    {
      id: '37a90d70-053c-4e66-85ba-7b0cac4f6ff3',
      email: 'tarun@emailioTemp.com',
      name: 'Tarun',
      department: ['dce52be7-3249-4c8d-93ef-b38c0f73bfa0'],
      circle: ['4116a445-0d32-4f23-b109-925d5b15cb8c'],
    },
    {
      id: '744a6a2a-1d97-4466-8a2c-9caa1e954663',
      name: 'Ajay Kumar',
      email: 'ajay@emailioTemp.com',
      department: ['32d7f63d-0610-4706-a597-ad5df667f7b3'],
      circle: ['915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a'],
    },
    {
      id: '73bef586-d556-4eb1-963d-79e394fc2a7f',
      email: 'rohit@emailioTemp.com',
      department: ['32d7f63d-0610-4706-a597-ad5df667f7b3'],
      circle: ['4116a445-0d32-4f23-b109-925d5b15cb8c'],
      name: 'Rohit',
    },
    {
      id: 'f2082942-a91d-4c21-bfbb-68b7c0284f26',
      name: 'Sourav',
      email: 'sourav@emailioTemp.com',
      department: ['ff6e6a86-a4c0-4ff8-ad57-201cc68355d8'],
      circle: ['915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a'],
    },
  ],
  kpiManagerData: [
    {
      id: '3dca7de7-b879-4f99-b334-3586adec6651',
      manager: 268,
      department: 'dce52be7-3249-4c8d-93ef-b38c0f73bfa0',
      circle: '16ca596a-16e7-470d-96f4-b6e41222092d',
    },
    {
      id: 'f3f3946d-9279-46ec-90f5-d0cf37f141dd',
      manager: 334,
      department: 'a21825da-d78e-48f8-ab5f-f14032d46bf8',
      circle: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
    },
    {
      id: '8608449b-07b9-4266-9e7c-6e161f6c03fd',
      manager: 269,
      department: 'ff6e6a86-a4c0-4ff8-ad57-201cc68355d8',
      circle: '915fe8d8-0eaa-4e7d-85ef-e3cee3b4cf2a',
    },
  ],
};
