export const sqlQueryState = {
  loading: 'loading',
  close: 'close',
  open: 'open',
};
export const AllCustomCommandType = {
  customColumn: 'customColumn',
  customTable: 'customTable',
  joinTables: 'joinTables',
};
export const customCommandApi = {
  customColumn: {
    tableUpdateApi: 'DataTable',
    testStatementApi: 'testSqlColumn',
    saveStatementApi: 'saveSqlColumn',
    AIToGenerateApi: 'AIToGenerateColumn',
    AIToQueryApi: 'AIToQueryColumn',
  },
  joinTables: {
    tableUpdateApi: 'JoinTable',
    testStatementApi: 'testSqlColumn',
    saveStatementApi: 'saveSqlColumn',
    AIToGenerateApi: 'AIToGenerateColumn',
    AIToQueryApi: 'AIToQueryColumn',
  },
  customTable: {
    tableUpdateApi: 'DataTable',
    testStatementApi: 'testSqlTable',
    saveStatementApi: 'saveSqlTable',
    AIToGenerateApi: 'AIToGenerateTable',
    AIToQueryApi: 'AIToQueryTable',
  },
};
export const sqlCommandTitleByType = {
  customColumn: {
    mainTitle: 'SQL Commands',
    mainDescription: 'Create Custom columns using AI and SQL.',
    display: false,
  },
  joinTables: {
    mainTitle: 'SQL Commands',
    mainDescription: 'Create Custom columns using AI and SQL.',
    display: false,
  },
  customTable: {
    mainTitle: 'SQL Commands',
    mainDescription: 'Create customized table using SQL.',
    display: false,
  },
};

const defaultQueryByTableType = {
  customColumn: `SELECT *,
-- start your new columns here (example)
-- "__D3__TABLE__A.columnA" + "__D3__TABLE__A.columnB"  AS "yourColumnName",

-- End your Custom Columns here
FROM __D3__DATA__;`,
  joinTables: `SELECT *,
-- start your new columns here (example)
-- "__D3__TABLE__A.columnA" + "__D3__TABLE__A.columnB"  AS "yourColumnName",

-- End your Custom Columns here
FROM __D3__DATA__;`,

  customTable: `-- Start your SQL Commands here (example)

-- SELECT
--  A.columnA AS columnA, 
--  B.columnB AS columnB
-- FROM __D3__TABLE__A A 
-- JOIN __D3__TABLE__B B 
-- ON A."primaryKey" = B."primaryKey";

-- End your SQL Commands here.`,
};

export const defaultDataByTableType = {
  customColumn: {
    defaultQuery: defaultQueryByTableType.customColumn,
    sqlCommandTitle: sqlCommandTitleByType.customColumn,
    apiProps: customCommandApi.customColumn,
  },
  joinTables: {
    defaultQuery: defaultQueryByTableType.joinTables,
    sqlCommandTitle: sqlCommandTitleByType.joinTables,
    apiProps: customCommandApi.joinTables,
  },
  customTable: {
    defaultQuery: defaultQueryByTableType.customTable,
    sqlCommandTitle: sqlCommandTitleByType.customTable,
    apiProps: customCommandApi.customTable,
  },
};
export const allOutputTypes = {
  normal: 'normal',
  error: 'error',
  success: 'success',
};
