// import { RxDot } from 'react-icons/rx';
import { TIMESERIES, TIMETYPES, getGrowthRateData, getMaximaMinimaData } from '../../data/data';
import * as React from 'react';
import { useState } from 'react';
import TableData from '../TableData/TableData';
import '../InsightStyle.css';
import { mls } from 'lib/multilanguagesupport';
// import { changeNumFormat } from '../CategoricalType';

interface mainProps {
  mainData: any;
  axisIndex: number;
  catChartAxis: string[];
  chartType: string;
  isReport: boolean;
}

const numberToDate = (number: number) => {
  const numberInDate = new Date(number);
  return ` ( ${numberInDate.toDateString()} ) `;
};

const TimeSeriesType = (props: mainProps) => {
  const { mainData, axisIndex, chartType, isReport } = props;

  const [openIndexes, setOpenIndexes] = useState<number[]>([0]);

  const handleClick = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <>
      <ul className='text-center'>
        {mls(TIMESERIES.DURATION)} {mls('start from')}{' '}
        <b>
          {mainData.dataSections[axisIndex][0].start
            ? numberToDate(mainData.dataSections[axisIndex][0].start)
            : ''}
        </b>
        {mls('to')}
        <b>
          {mainData.dataSections[axisIndex][0].end
            ? numberToDate(mainData.dataSections[axisIndex][0].end)
            : ''}
        </b>
        {mls('duration of')}{' '}
        <span>
          <b>{mainData.dataSections[axisIndex].duration}</b>.
        </span>
      </ul>
      <div
        className={
          isReport
            ? ' insight-accordion-container newPageauto'
            : 'container-fluid insight-accordion-container newPageauto'
        }
      >
        <div className='accordion w-100  accordion-flush' id='accordionFlushExample'>
          {Object.keys(TIMETYPES).map((key, index) => {
            const typeData = mainData.dataSections[axisIndex].filter(
              (elem: any) => elem.type === key
            );
            return (
              <div className='accordion-item ' key={key}>
                {isReport ? (
                  <div className={`inisghtAccordionsHeaderTitle`}>{TIMETYPES[key]}</div>
                ) : (
                  <h2 className='accordion-header'>
                    <button
                      className={`inisght-accordions rounded fs-4 accordion-button ${
                        openIndexes.includes(index) ? '' : 'collapsed'
                      }`}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#flush-collapse-${index}`}
                      aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
                      aria-controls={`flush-collapse-${index}`}
                      onClick={() => handleClick(index)}
                    >
                      {mls(TIMETYPES[key])}
                    </button>
                  </h2>
                )}
                <div
                  id={`flush-collapse-${index}`}
                  className={`accordion-collapse collapse ${
                    openIndexes.includes(index) || isReport ? 'show' : ''
                  }`}
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body p-0 inisght-accordions-body'>
                    {typeData.map((elem: any, index: number) => (
                      <div className='container-fluid p-0 m-0' key={index}>
                        <TimeSeriesByTypes
                          sectionObj={elem}
                          openStates={openIndexes}
                          elemType={elem.type}
                          chartType={chartType}
                          mainData={mainData}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TimeSeriesType;

const TimeSeriesByTypes = ({ sectionObj, chartType, mainData }: any) => {
  if (sectionObj.type === 'GROWTHRATE') {
    return (
      <>
        <div className='container-fluid w-100 p-2 g-0 insight-side-by-side-table-container'>
          <div className='w-100 p-1 g-0'>
            <TableData
              elem={getGrowthRateData(sectionObj, 'Current')}
              type={'LastGrowth'}
              columnName2={mls('Period')}
              columnName3={mls('Growth Rate')}
              chartType={chartType}
              mainData={mainData}
              arrow={true}
            />
          </div>

          <div className='w-100 p-1 g-0'>
            <TableData
              elem={getGrowthRateData(sectionObj, 'Last')}
              columnName2={mls('Period')}
              columnName3={mls('Growth Rate')}
              type={'LastGrowth'}
              chartType={chartType}
              mainData={mainData}
              arrow={true}
            />
          </div>
        </div>
      </>
    );
  }

  if (sectionObj.type === 'MAXIMAMINIMA') {
    return (
      <>
        <div className='p-2'>
          <TableData
            elem={getMaximaMinimaData(sectionObj, 'Maxima')}
            columnName1={mls('Maxmimum Analysis')}
            columnName2={mls('Time Period')}
            columnName3={mls('Performance Rate')}
            columnName4={mls('Percentage Difference')}
            type={'MaximaMinima'}
            chartType={chartType}
            mainData={mainData}
          />
          <TableData
            elem={getMaximaMinimaData(sectionObj, 'Minima')}
            columnName1={mls('Minimum Analysis')}
            columnName2={mls('Time Period')}
            columnName3={mls('Performance Rate')}
            columnName4={mls('Percentage Difference')}
            type={'MaximaMinima'}
            chartType={chartType}
            mainData={mainData}
          />
        </div>
      </>
    );
  }

  if (sectionObj.type === '80-20RULE') {
    return (
      <div className='container-fluid w-100 p-2 g-0 insight-side-by-side-table-container'>
        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col py-4 gx-0 gy-0 m-2 insight-side-by-side-table-heading text-center'>
            <b>{mls('Top Months')}</b>
          </div>
          <p className='text-center'>
            {mls('Impact')} : {Math.round(sectionObj.top20PercentImpact * 100) / 100}%
          </p>

          <TableData
            elem={sectionObj.top20PercentList}
            type={'twoColumns'}
            columnName2={mls('Month')}
            columnName3={mls('Percentage')}
            chartType={chartType}
            mainData={mainData}
          />
        </div>

        <div className='w-100 p-1 g-0 groupBackGround'>
          <div className='col m-2 py-4 gx-0 gy-0 insight-side-by-side-table-heading text-center'>
            <b>{mls('Bottom Months')}</b>
          </div>
          <p className='text-center'>
            {mls('Impact')} : {Math.round(sectionObj.bottom20PercentImpact * 100) / 100}%
          </p>
          <TableData
            elem={sectionObj.bottom20PercentList}
            type={'twoColumns'}
            columnName2={mls('Month')}
            columnName3={mls('Percentage')}
            chartType={chartType}
            mainData={mainData}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='contentDiv'>
      <div className='contentTitle'>{sectionObj.type}</div>
    </div>
  );
};
