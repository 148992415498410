import React from 'react';
import './messages.scss';
import { Box } from '@mui/material';
import { FaUser } from 'react-icons/fa';
import { RiErrorWarningLine } from 'react-icons/ri';
import ChartMessage from './ChartMessage';
import { errorMessage } from '../../data/constants';
import { mls } from 'lib/multilanguagesupport';

interface mainPorps {
  messages: any[];
  chartHeight: number;
  mainContainerWidth: number;
  handleChartPopUp: Function;
  appDash: any;
  tableSchema: any;
  allTables: any;
  appID: string;
  sheetID: string;
  setDashboards: Function;
  handleChartFilter: Function;
  setMessages: Function;
  updateReduxSchema: Function;
  stateProps: any;
  allTableIDAndNamesMap: any;
}

const Messages = (mainPorps: mainPorps) => {
  const {
    messages,
    chartHeight,
    handleChartPopUp,
    mainContainerWidth,
    appDash,
    tableSchema,
    appID,
    setDashboards,
    handleChartFilter,
    sheetID,
    allTables,
    setMessages,
    updateReduxSchema,
    stateProps,
    allTableIDAndNamesMap,
  } = mainPorps;

  return (
    <>
      {messages.map((msg, messageIndex) => {
        if (msg.sender === 'user') {
          return <UserMessage key={messageIndex} msg={msg} />;
        }
        if (msg.error) {
          return <ErrorMessage key={messageIndex} handleChartPopUp={handleChartPopUp} msg={msg} />;
        }
        return (
          <ChartMessage
            key={messageIndex}
            msg={msg}
            handleChartPopUp={handleChartPopUp}
            mainContainerWidth={mainContainerWidth}
            messageIndex={messageIndex}
            chartHeight={chartHeight}
            tableSchema={tableSchema}
            appDash={appDash}
            setDashboards={setDashboards}
            appID={appID}
            sheetID={sheetID}
            handleChartFilter={handleChartFilter}
            allTables={allTables}
            setMessages={setMessages}
            updateReduxSchema={updateReduxSchema}
            stateProps={stateProps}
            allTableIDAndNamesMap={allTableIDAndNamesMap}
          />
        );
      })}
    </>
  );
};

export default Messages;

interface UserMessageProps {
  msg: any;
}
const UserMessage = ({ msg }: UserMessageProps) => {
  return (
    <div className='userMessages'>
      <Box className='mainMessage'>
        <div dangerouslySetInnerHTML={{ __html: msg?.message }} />
      </Box>
      <div className='logoDiv'>
        <FaUser className='logo' />
      </div>
    </div>
  );
};
interface ErrorMessageProps {
  handleChartPopUp: Function;
  msg: any;
}
const ErrorMessage = ({ handleChartPopUp, msg }: ErrorMessageProps) => {
  return (
    <div className='errorMessage'>
      <div className='logoDiv'>
        <img alt='Logo' className='logo' src='https://www.supista.com/assets/img/logo.png' />
      </div>
      <div className='messgesDiv'>
        <div className='warningIcon'>
          <RiErrorWarningLine />
        </div>
        <div className='messages'>
          <div className='mainMessage'>{msg?.message}</div>
          {msg?.tableSelectionError ? null : (
            <div className='openCreatChartButton'>
              {mls('If you want you can')} &nbsp;
              <span
                className='link'
                onClick={() =>
                  handleChartPopUp({
                    isNewChart: true,
                    // chartData: schema,
                    // index: messageIndex,
                    message: msg,
                  })
                }
              >
                {mls('Create new chart')}
              </span>
              {msg?.message === errorMessage.surpriseMeNoData
                ? mls('. After selecting a table from dropdown.')
                : '.'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
