import { Modal } from '@mui/material';
import TBDPreview from 'lib/data-connectors/dependencies/TBDPreview/TBDPreview';
import {
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';

import { mls } from 'lib/multilanguagesupport';

const ConfirmDeleteModal = ({
  isDeleteOpen,
  setIsDeleteOpen,
  handleDeleteTable,
  joinTableData,
  analyticsSchema,
}) => {
  const sheetsSchema = analyticsSchema?.appSheets || {};
  const reportSchema = analyticsSchema.appReports || {};
  const dashSchema = analyticsSchema.appDash || {};

  const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
    [],
    [joinTableData.tableID],
    sheetsSchema
  );
  const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
    chartIDs: chartIDSTBD ?? [],
    dashSchema: dashSchema,
    reportSchema: reportSchema,
  });
  const reportIDsKeys = Object.keys(reportIDsTBD);
  const dashIDsKeys = Object.keys(dashIDsTBD);

  return (
    <Modal open={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
      <div className='modal-dialog modal-dialog-centered mw-600px'>
        <div
          className='modal-content d-flex justify-content-center align-items-center p-5 mx-auto'
          style={{ maxWidth: '90vw' }}
        >
          <p className='p-3 fs-4 fw-bold'>{mls('Are you sure you want to delete this table')}?</p>

          <TBDPreview
            sheetIDsTBD={sheetIDsTBD}
            reportIDsKeys={reportIDsKeys}
            dashIDsKeys={dashIDsKeys}
            reportSchema={reportSchema}
            dashSchema={dashSchema}
            sheetsSchema={sheetsSchema}
          />
          <div className='d-flex pt-10'>
            <button
              data-bs-dismiss='modal'
              onClick={() => {
                handleDeleteTable(joinTableData);
              }}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-danger btn-light-primary mx-4'
            >
              {mls('Delete table')}
            </button>
            <button
              onClick={() => setIsDeleteOpen(false)}
              style={{ marginTop: -15 }}
              className='btn btn-sm mb-2 btn-primary btn-light-primary mx-4'
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
