import { useState, useEffect } from 'react';
import { Modal, Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { mls } from 'lib/multilanguagesupport';
import { KTSVG } from '_metronic/helpers';

import { supistaAnalyticsApiDelete } from 'lib/data-connectors/dependencies/connections';

import store from 'lib/reusable-components/reusableUtils/redux/store';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateConnectionsSchema from 'lib/data-connectors/dependencies/useUpdateConnectionsSchema';
import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';

import {
  getJoinTableIDs,
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
  getTableIDSTBD,
  getTableJoinTableIDs,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import useUpdateJoinTableSchema from 'lib/data-connectors/dependencies/useUpdateJoinTablesSchema';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import { _uploadFileToServer } from 'lib/server-connection/connections';
import { toast } from 'react-toastify';
import TBDPreview from 'lib/data-connectors/dependencies/TBDPreview/TBDPreview';

const DeleteModal = ({ connection, isOpen = true, closeModal, handlePopupClose }) => {
  const updateTableSchema = useUpdateTableSchema();
  const updateJoinTableSchema = useUpdateJoinTableSchema();
  const updateSheetSchema = useUpdateSchema();
  const [connectionNameConfirm, setConnectionNameConfirm] = useState('');

  const [isDeleteLoading, setisDeleteLoading] = useState(false);

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const appDatatable = analyticsSchema?.appDatatable || {};
  const appJointable = analyticsSchema?.appJointable || {};
  const customTableSchema = analyticsSchema?.appCustomtable || {};
  const sheetsSchema = analyticsSchema?.appSheets || {};
  const dashSchema = analyticsSchema.appDash || {};
  const reportSchema = analyticsSchema.appReports || {};

  const { tableIDsTBD, joinTableIDsTBD } = getTableJoinTableIDs(
    connection?.credentialID,
    appDatatable,
    appJointable
  );
  const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
    tableIDsTBD,
    joinTableIDsTBD,
    sheetsSchema
  );
  const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
    chartIDs: chartIDSTBD ?? [],
    dashSchema: dashSchema,
    reportSchema: reportSchema,
  });
  const { customTableIDsTBD } = getTableIDSTBD({
    tableIDs: tableIDsTBD,
    joinTableSchema: appJointable,
    customTableSchema: customTableSchema,
  });
  const reportIDsKeys = Object.keys(reportIDsTBD);
  const dashIDsKeys = Object.keys(dashIDsTBD);
  // Clear confirm connection name field on closing modal
  useEffect(() => {
    setConnectionNameConfirm('');
  }, [isOpen]);

  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  const updateConnectionsSchema = useUpdateConnectionsSchema();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pb: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '50vh',
    overflow: 'auto',
  };

  const deleteConnectionHandler = (e) => {
    e.preventDefault();
    setisDeleteLoading(true);
    if ((connection?.connectionName || connection?.credentialID) !== connectionNameConfirm) {
      setisDeleteLoading(false);
      return;
    }
    const sendToBackend = {
      tableIDList: tableIDsTBD,
      joinTableIDList: joinTableIDsTBD,
      customTableIDList: customTableIDsTBD,
      sheetIDList: sheetIDsTBD,
      dashIDObj: dashIDsTBD,
      reportIDObj: reportIDsTBD,
    };
    const reqFormData = new FormData();
    reqFormData.append('appID', appID);
    reqFormData.append('credentialID', connection?.credentialID);
    reqFormData.append('TBD', JSON.stringify(sendToBackend));
    reqFormData.append('connectorType', JSON.stringify(connection.connectorType));
    reqFormData.append('data', JSON.stringify(connection.data || {}));
    _uploadFileToServer(`${appID}/api/deleteCredential/credentials`, reqFormData)
      .then(async (response) => {
        if (!response.__d3__error) {
          publishToProduction(appID);
          toast.success(mls('Connection deleted successfully'));
          setisDeleteLoading(false);
          closeModal();
        } else {
          toast.error(mls("Issue encountered. Couldn't delete."));
          setisDeleteLoading(false);
        }
      })
      .catch((error) => {
        toast.error(mls("Issue encountered. Couldn't delete."));
        setisDeleteLoading(false);
      });
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={handlePopupClose}
    >
      <Box sx={style}>
        {/* Modal close button */}
        <button
          type='button'
          className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
          // id='kt_explore_close'
          onClick={handlePopupClose}
          style={{ position: 'absolute', top: 20, right: 30 }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
        </button>

        <h1 className='mb-10 mt-10'>{mls('Delete Connection')}?</h1>
        <p className='fs-6 align-self-start'>
          {mls('Are you sure want to delete connection')}{' '}
          <strong>{mls(connection?.connectionName || connection?.credentialID)}</strong>?
        </p>
        <form className='align-self-start w-75' onSubmit={deleteConnectionHandler}>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {mls('Confirm Connection Name')}
          </label>
          <input
            placeholder='Confirm Connection Name'
            className='form-control form-control-lg form-control-solid'
            type='text'
            name='confirmConnectionName'
            autoComplete='off'
            value={connectionNameConfirm}
            onChange={(e) => setConnectionNameConfirm(e.target.value)}
          />
        </form>
        <TBDPreview
          sheetIDsTBD={sheetIDsTBD}
          titleString={
            'This Connection is currently in use, so deleting it will delete the following'
          }
          reportIDsKeys={reportIDsKeys}
          dashIDsKeys={dashIDsKeys}
          joinTableIDsTBD={joinTableIDsTBD}
          customTableIDsTBD={customTableIDsTBD}
          reportSchema={reportSchema}
          dashSchema={dashSchema}
          sheetsSchema={sheetsSchema}
          joinTableSchema={appJointable}
          customTableSchema={customTableSchema}
          tableIDsTBD={tableIDsTBD}
          appDatatable={appDatatable}
        />
        {/* {(tableIDsTBD.length > 0 || joinTableIDsTBD.length > 0) && (
          <div className='alert alert-warning my-2 py-5 w-100' role='alert'>
            <p className='fw-bold mb-2'>
              {mls('This Connection is currently in use, so deleting it will delete the following')}
            </p>
            <ul>
              {tableIDsTBD.length > 0 && (
                <>
                  <p className='mb-2'>
                    {tableIDsTBD.length === 1 ? 'Data Table:' : 'Data Tables:'}
                  </p>

                  {tableIDsTBD.map((tableID) => (
                    <li className='text-danger' key={tableID}>
                      {appDatatable[tableID]?.name || ''}
                    </li>
                  ))}
                </>
              )}
              {joinTableIDsTBD.length > 0 && (
                <>
                  <p className='mb-2'>
                    {joinTableIDsTBD.length === 1 ? 'Joined Table:' : 'Joined Tables:'}
                  </p>

                  {joinTableIDsTBD.map((joinTableID) => (
                    <li className='text-danger' keu={joinTableID}>
                      {appJointable[joinTableID]?.name || ''}
                    </li>
                  ))}
                </>
              )}
              {sheetIDsTBD.length > 0 && (
                <>
                  <p className='mt-2 mb-2'>
                    {sheetIDsTBD.length === 1 ? 'Sheets and its charts:' : 'Sheets and its charts:'}
                  </p>
                  {sheetIDsTBD.map((sheetID) => (
                    <li className='text-danger' key={sheetID}>
                      {sheetsSchema[sheetID]?.name || ''}
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        )} */}

        <button
          className='btn btn-sm btn-danger py-4 mt-10'
          onClick={deleteConnectionHandler}
          disabled={
            (connection?.connectionName || connection?.credentialID) !== connectionNameConfirm
          }
        >
          {mls('Delete Connection')}
          {isDeleteLoading ? (
            <>
              &nbsp;&nbsp;
              <CircularProgress size={12} color='inherit' />
            </>
          ) : null}
        </button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
