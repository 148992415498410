import { mls } from 'lib/multilanguagesupport';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { checkInsightsCapableChart } from 'lib/reusable-components/reusableFunction/chartBasicFuntions';

interface chartInsightProps {
  isInsight: boolean;
  setIsInsight: Function;
  chartKey: string;
}
const ChartInsight = ({ isInsight, setIsInsight, chartKey }: chartInsightProps) => {
  const isInsightCapable = checkInsightsCapableChart({ chartKey: chartKey });
  if (!isInsightCapable) {
    return null;
  }
  return (
    <div className='outerDiv'>
      <div className='MainTitle inFlex'>
        {mls('Insight Summary')}
        <SlideSwitch isChecked={isInsight} setIsChecked={setIsInsight} />
      </div>
    </div>
  );
};

export default ChartInsight;
