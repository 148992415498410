import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import TableWisePermission from './TableWisePermission';

const Permissions = ({ newTeamSchema, setTeamSchema, appID, teamID }) => {
  const [privilege, setPrivilege] = useState(newTeamSchema?.privilege || '');
  const handleCheckboxChange = (event) => {
    const type = event.target.value;
    if (privilege !== type) {
      setPrivilege(type);
      setTeamSchema({ ...newTeamSchema, privilege: type, TABLESLIST: {} });
    } else {
      setPrivilege('');
      setTeamSchema({ ...newTeamSchema, privilege: '', TABLESLIST: {} });
    }
  };
  // const handleCreator = (event) => {
  //   const value = event.target.value;
  //   let prevList = [...(privilege?.creatorAccess || [])];
  //   const isPresent = prevList.includes(value);
  //   if (isPresent) {
  //     prevList = prevList.filter((item) => item !== value);
  //   } else {
  //     prevList.push(value);
  //   }
  //   const newPrivilege = { ...privilege, creatorAccess: prevList };
  //   if (prevList.length === 0) {
  //     setPrivilege({});
  //     setTeamSchema({ ...newTeamSchema, privilege: {}, TABLESLIST: {} });
  //   } else {
  //     setPrivilege(newPrivilege);
  //     setTeamSchema({ ...newTeamSchema, privilege: newPrivilege });
  //   }
  // };
  if (teamID === 'Admin') {
    return (
      <div className='d-flex border border-2 justify-content-center align-items-center rounded-3 p-8'>
        <h5 className='text-muted'>{mls('Grants Admin Access to the users!')}</h5>
      </div>
    );
  }
  return (
    <>
      <form className='mt-5'>
        {/* <div className='form-check form-check-custom mb-4'>
          <input
            className='form-check-input me-4'
            style={{ borderWidth: '2px' }}
            type='checkbox'
            id='Admin_Access'
            value='ADMIN'
            checked={privilege.accessOf === 'ADMIN'}
            onChange={handleCheckboxChange}
          />
          <label className='form-label mb-0' style={{ cursor: 'pointer' }} htmlFor='Admin_Access'>
            {mls('Grant Admin Access')}
          </label>
        </div> */}
        <div className='form-check form-check-custom mb-4'>
          <input
            className='form-check-input me-4'
            style={{ borderWidth: '2px' }}
            type='checkbox'
            id='Creator_Access'
            value='ALL_CREATOR'
            checked={privilege === 'ALL_CREATOR'}
            onChange={handleCheckboxChange}
          />
          <label className='form-label mb-0' style={{ cursor: 'pointer' }} htmlFor='Creator_Access'>
            {mls('Grant Creator Access')}
          </label>
        </div>
        <div className='form-check form-check-custom mb-4'>
          <input
            className='form-check-input me-4'
            style={{ borderWidth: '2px' }}
            type='checkbox'
            id='Chart_Access'
            value='CHART_CREATOR'
            checked={privilege === 'CHART_CREATOR'}
            onChange={handleCheckboxChange}
          />
          <label className='form-label mb-0' style={{ cursor: 'pointer' }} htmlFor='Chart_Access'>
            {mls('Grant Chart Editor Access')}
          </label>
        </div>
      </form>
      {/* {(privilege.creatorAccess || []).length > 0 && (
        <div className='ms-6'>
          <div className='form-check form-check-custom mb-4'>
            <input
              className='form-check-input me-4'
              style={{ borderWidth: '2px' }}
              type='checkbox'
              id='Chart_Access'
              value='CHART_CREATOR'
              checked={privilege?.creatorAccess?.includes('CHART_CREATOR')}
              onChange={handleCreator}
            />
            <label className='form-label mb-0' style={{ cursor: 'pointer' }} htmlFor='Chart_Access'>
              {mls('Grant Chart Editor Access')}
            </label>
          </div>
          <div className='form-check form-check-custom mb-4'>
            <input
              className='form-check-input me-4'
              style={{ borderWidth: '2px' }}
              type='checkbox'
              id='Data_Access'
              value='DATA_CREATOR'
              checked={privilege?.creatorAccess?.includes('DATA_CREATOR')}
              onChange={handleCreator}
            />
            <label
              className='form-label mb-0'
              style={{ cursor: 'pointer' }}
              htmlFor='Creator_Access'
            >
              {mls('Grant Data Lake Editor Access')}
            </label>
          </div>
        </div>
      )} */}
      {privilege !== 'ALL_CREATOR' && privilege !== 'CHART_CREATOR' ? (
        <>
          <hr />
          <h4 className='align-items-start d-flex flex-column'>
            <span className='card-label fw-bolder fs-5 mb-1'>{mls('Table Based Access')}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {mls('Add tables and apply filters to create Team access')}
            </span>
          </h4>
          <TableWisePermission
            appID={appID}
            newTeamSchema={newTeamSchema}
            setTeamSchema={setTeamSchema}
          />
        </>
      ) : (
        <div className='d-flex border border-2 justify-content-center align-items-center rounded-3 p-8'>
          <>
            {privilege === 'ALL_CREATOR' && (
              <h5 className='text-muted'>
                {mls(
                  'Grants access of Data Lake, Dashboard, Reports, Sheets settings to the users!'
                )}
              </h5>
            )}
            {privilege === 'CHART_CREATOR' && (
              <h5 className='text-muted'>
                {mls('Grants only access of Dashboard, Reports, Sheets settings to the users!')}
              </h5>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default Permissions;
