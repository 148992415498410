import { defaultReportTypeObject } from 'lib/reports-analytics/data/defaultSchema';
import React from 'react';
import DataReportType from '../DataReportType/DataReportType';
interface reportTypeContainerProps {
  stateProps: any;
}
const ReportTypeContainer = ({ stateProps }: reportTypeContainerProps) => {
  const { selectedReportType, setReportTypeSelected } = stateProps;
  if (defaultReportTypeObject.DATA_REPORT.key === selectedReportType) {
    return <DataReportType stateProps={stateProps} />;
  }
  return (
    <div>
      Selected Report Type is not Available!{' '}
      <button
        className='linkButton'
        onClick={() => {
          setReportTypeSelected(false);
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default ReportTypeContainer;
