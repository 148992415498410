import React from 'react';
import PropTypes from 'prop-types';
import { AreaClosed, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { defaultMargin } from '../../constants/constants';
import { handleMouseStartLineAndArea } from './helpers/tooltipHelper';
import { RadialGradient, LinearGradient } from '@visx/gradient';
import { defaultColors } from '../../constants/constants';

const AreaChart = ({
  xScale,
  yScale,
  x,
  y,
  xCategory,
  yCategory,
  onMouseOver,
  onMouseOut,
  width,
  height,
  colorType,
  gradientColor,
  chartColor,
}) => {
  // Convert data to required format
  // [{x: x, y: y}, {x: x, y: y},...]
  const data = x.map((e, i) => ({ x: e, y: y[i] }));

  // Accessors for data
  const xAccessor = (d) => d.x;
  const yAccessor = (d) => d.y;

  // Addition coefficient (if bandScale, add bandwidth coefficient)
  const xCoeff = xCategory === 'NA' ? xScale.bandwidth() / 2 : 0;
  const yCoeff = yCategory === 'NA' ? yScale.bandwidth() / 2 : 0;

  const handleMouseStart = (event, d) => {
    handleMouseStartLineAndArea(event, xCategory, onMouseOver, d);
  };

  const barWidths = {};
  data.forEach((d, i) => {
    xScale(xAccessor(d));
    const currX = xScale(xAccessor(d));
    let nextX = 0;
    if (i === 0) nextX = 0;
    else if (i === data.length - 1) nextX = width;
    else nextX = xScale(xAccessor(data[i + 1]));
    const wid = Math.floor(nextX - currX);
    barWidths[wid] = barWidths[wid] ? barWidths[wid] + 1 : 1;
  });

  const barWidth = Object.keys(barWidths).reduce(
    (a, b) => (barWidths[a] > barWidths[b] ? a : b),
    0
  );

  let color = defaultColors[0];
  if (colorType === 'Monochromatic') {
    color = chartColor;
  } else if (colorType === 'Gradient') {
    color = gradientColor.from;
  }
  const gradientID = `Gradient-area`;

  return (
    <>
      {colorType === 'Gradient' && gradientColor.type === 'linear' && (
        <LinearGradient
          id={gradientID}
          from={gradientColor.from}
          to={gradientColor.to}
          rotate={gradientColor.rotate || 0}
          // toOpacity={gradient.toOpacity || 1}
        />
      )}
      {colorType === 'Gradient' && gradientColor.type === 'radial' && (
        <RadialGradient
          id={gradientID}
          from={gradientColor.from}
          to={gradientColor.to}
          r={gradientColor.r || '100%'}
        />
      )}
      <AreaClosed
        data={data}
        curve={curveMonotoneX}
        x={(d) => xScale(xAccessor(d)) + xCoeff}
        y={(d) => yScale(yAccessor(d)) + yCoeff}
        yScale={yScale}
        stroke={colorType === 'Gradient' ? gradientID : color}
        strokeWidth={1}
        fill={colorType === 'Gradient' ? `url(#${gradientID})` : `${color}88`}
      />
      {data.map((d, i) => (
        <Bar
          key={`bar-${d.x}-${i}`}
          x={xScale(xAccessor(d)) - barWidth / 2}
          width={barWidth}
          height={Math.max(height - defaultMargin.top - defaultMargin.bottom, 200)}
          fill='transparent'
          onMouseMove={(e) => handleMouseStart(e, d)}
          // onMouseLeave={() => {
          //   onMouseOut();
          // }}
        />
      ))}

      {/* <Bar
        x={0}
        y={0}
        width={width - defaultMargin.left - defaultMargin.right}
        height={height - defaultMargin.top - defaultMargin.bottom}
        fill='transparent'
        onTouchStart={handleMouseStart}
        onTouchMove={handleMouseStart}
        onMouseMove={handleMouseStart}
        onMouseLeave={onMouseOut}
      /> */}
    </>
  );
};

AreaChart.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  xCategory: PropTypes.string.isRequired,
  yCategory: PropTypes.string.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default AreaChart;
