import { Group } from '@visx/group';
import { Treemap, hierarchy, stratify, treemapSquarify } from '@visx/hierarchy';
import { scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
// import { motion } from 'framer-motion/dist/framer-motion';
import { motion } from 'framer-motion';

import { defaultToolTipStyling } from '../../constants/constants';
import { Text } from '@visx/text';
import { useMemo } from 'react';
import { createColorArray, hexWithOpacity } from '../../helpers/colorHelper';
import { TreeMapToolTip } from '../ChartToolTip/ChartToolTip';

export const background = '#114b5f';

const defaultMargin = { top: 10, left: 10, right: 10, bottom: 10 };

export default function TreeMapChart({
  data: treeMapData,
  width,
  height,
  margin = defaultMargin,
  xAxisLabel,
  yAxisLabel,
  chartData,
  theme,
  handleClick,
  mainData,
}) {
  const colors = createColorArray(
    10,
    theme.colors[chartData.yAxis[0].uniqueColumnName || chartData.yAxis[0].column]
  );
  const background = colors[0];

  const treeMapColorMax = chartData?.treeMapColor?.max;
  const treeMapColorMin = chartData?.treeMapColor?.min;

  const color1 = treeMapColorMax
    ? hexWithOpacity(treeMapColorMax?.color, (treeMapColorMax?.opacity ?? 100) / 100)
    : '#00A3FFb3';
  const color2 = treeMapColorMin
    ? hexWithOpacity(treeMapColorMin?.color, (treeMapColorMin?.opacity ?? 100) / 100)
    : '#d8f3ffb3';
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const data = useMemo(() => {
    try {
      return stratify()
        .id((d) => d.id)
        .parentId((d) => d.parent)(treeMapData)
        .sum((d) => d.size || 0);
    } catch (e) {
      return null;
    }
  }, [treeMapData]);
  const root = hierarchy(data).sort((a, b) => (b.value || 0) - (a.value || 0));

  const colorScale = scaleLinear({
    domain: [0, Math.max(...treeMapData.map((d) => d.size || 0))],
    range: [color2, color1],
  });

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleMouse = (event, d) => {
    const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
    const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
    showTooltip({
      tooltipLeft: containerX,
      tooltipTop: containerY,
      tooltipData: d,
    });
  };
  const getMaxValue = () => {
    const sumValue = (mainData?.y ?? [0, 0]).reduce((previousValue, currentValue) => {
      return (previousValue ?? 0) + (currentValue ?? 0);
    });
    return sumValue;
  };
  const maxValue = getMaxValue();
  return width < 10 ? null : (
    <div>
      <svg ref={containerRef} width={width} height={height}>
        <rect width={width} height={height} rx={14} fill={'transparent'} />
        {/* <rect width={width} height={height} rx={14} fill={background} /> */}
        <Treemap top={margin.top} root={root} size={[xMax, yMax]} tile={treemapSquarify} round>
          {(treemap) => (
            <Group>
              {treemap
                .descendants()
                .reverse()
                .map((node, i) => {
                  const nodeWidth = node.x1 - node.x0;
                  const nodeHeight = node.y1 - node.y0;
                  return (
                    <Group
                      key={`node-${i}`}
                      top={node.y0 + margin.top}
                      left={node.x0 + margin.left}
                    >
                      {node.depth > 0 && (
                        <>
                          <motion.rect
                            width={nodeWidth}
                            height={nodeHeight}
                            stroke={background}
                            fill={colorScale(node.value || 0)}
                            onMouseOver={(event) => handleMouse(event, node.data.data)}
                            onMouseLeave={() => hideTooltip()}
                            onTouchStart={(event) => handleMouse(event, node.data.data)}
                            onTouchEnd={() => hideTooltip()}
                            opacity={0.8}
                            whileHover={{ opacity: 1, scale: 1.05 }}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClick(node.data.data.id)}
                          />
                          <Text
                            // whileHover={{ opacity: 1, scale: 1.05 }}
                            textAnchor='middle'
                            verticalAnchor='middle'
                            color={background}
                            onMouseOver={(event) => handleMouse(event, node.data.data)}
                            onMouseLeave={() => hideTooltip()}
                            x={nodeWidth / 2}
                            width={nodeWidth - 10}
                            height={nodeHeight - 10}
                            // scaleToFit={true}
                            y={nodeHeight / 2}
                            angle={nodeHeight > nodeWidth ? 90 : 0}
                            fontSize={Math.min(
                              16,
                              (Math.max(nodeWidth, nodeHeight) / (node.data.data.id.length / 1.25) <
                              5
                                ? 0
                                : Math.max(nodeWidth, nodeHeight) /
                                  (node.data.data.id.length / 1.25)) || 0
                            )}
                          >
                            {node.data.data.id}
                          </Text>
                          {/* <Text
                            // whileHover={{ opacity: 1, scale: 1.05 }}
                            textAnchor='middle'
                            verticalAnchor='middle'
                            color={background}
                            onMouseOver={(event) => handleMouse(event, node.data.data)}
                            onMouseLeave={() => hideTooltip()}
                            x={nodeWidth / 2}
                            y={nodeHeight / 2}
                            angle={nodeHeight > nodeWidth ? 90 : 0}
                            fontSize={Math.min(
                              16,
                              (Math.max(nodeWidth, nodeHeight) / (node.data.data.id.length / 1.25) <
                              5
                                ? 0
                                : Math.max(nodeWidth, nodeHeight) /
                                  (node.data.data.id.length / 1.25)) || 0
                            )}
                          >
                            {node.data.data.id}
                          </Text> */}
                        </>
                      )}
                    </Group>
                  );
                })}
            </Group>
          )}
        </Treemap>
      </svg>
      {tooltipOpen && (
        <TreeMapToolTip
          defaultToolTipStyling={defaultToolTipStyling}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          TooltipInPortal={TooltipInPortal}
          tooltipData={tooltipData}
          theme={theme}
          chartData={chartData}
          maxValue={maxValue}
          backgroundColor={defaultToolTipStyling.backgroundColor}
        />
      )}

      {/* {tooltipOpen && (
        <TooltipInPortal
          className={styles.tooltip}
          style={defaultToolTipStyling}
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {tooltipData && (
            <div>
              <div>
                <strong>{findTooltipByAxis(theme?.tooltip?.style, 'xAxisId')}:</strong>{' '}
                {tooltipData.id}
              </div>
              {chartData.showParent && (
                <div>
                  <strong>Parent:</strong> {tooltipData.parent}
                </div>
              )}
              <div>
                <strong> {findTooltipByAxis(theme?.tooltip?.style, 'yAxisId')}:</strong>{' '}
                {numberTickFormat(tooltipData.size)}
              </div>
            </div>
          )}
        </TooltipInPortal>
      )} */}
    </div>
  );
}
const findTooltipByAxis = (array, target = 'xAxisId') => {
  const filter = array?.filter((elem) => elem?.axis === target);
  if (filter[0]) {
    return filter[0]?.label;
  }
  return '';
};
