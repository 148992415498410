import {
  ColorfulColorChart,
  allChartKeys,
} from 'lib/reusable-components/reusableData/chartTypesSchema';

import { normalAxisSchema } from '../data/axisSchema';
import {
  defaultLegendColorPalettes,
  allDropableID,
  defaultOperation,
  defaultDataType,
  GEOGroupColors,
  defaultChartSetting,
  defaultOpacity,
} from '../data/defaultChartSchema';
import {
  getAutomaticKpiTargetData,
  getUniueValuesFunction,
  removeToolTip,
  removeValueToolTip,
  setAutomaticColor,
  setDefaultDateTimeOpration,
  setDoubleAxisLabel,
  updateAxisStyleLable,
  updateChartType,
} from './chartHelperFunctions';
const { xAxisId, yAxisId, tableId, toolTip, groupId, tooltipAxisId } = allDropableID;

export const changeDestinationFunction = (props) => {
  const {
    sourceData,
    setSourceData,
    destinationData,
    setDestinationData,
    result,
    destinationID,
    setSourceStyle,
    setDestinationStyle,
    sourceID,
    stateProps,
  } = props;
  const {
    setGeoGroup,
    toast,
    setchartType,
    chartType,
    allTableData,
    setBarStackLineTargetPoints,
    columnDropNumber,
    setChartToolTip,
    setDoubleAxis,
  } = stateProps;

  if (!sourceData || !setSourceData || !destinationData || !setDestinationData) {
    console.log('Error: changeDestinationFunction');
  }

  const sourceitems = Array.from(sourceData);
  const destitems = Array.from(destinationData);
  const [selectedItem] = sourceitems.splice(result.source.index, 1);

  const [selectedItemWithOriginalSchema] = allTableData.filter(
    (e) => e.tableName + '.' + e.columnName === selectedItem.column
  );
  //Opration
  const dataTypeUpdatedItem = oprationAndDataTypeUpdate({ chartType, destinationID }, selectedItem);
  const dataTypeUpdatedItemData = dataTypeUpdatedItem.data;

  // IF ChartType is GEO
  if (
    chartType.chartKey === allChartKeys.GEOINDIA ||
    chartType.chartKey === allChartKeys.GEOUSA ||
    chartType.chartKey === allChartKeys.GEOCANADA ||
    chartType.chartKey === allChartKeys.GEOWORLD
  ) {
    if (destinationID === yAxisId) {
      // update ChartType
      const updateChartResove = updateChartType({
        axisData: destitems,
        secondAxisData: sourceitems,
        destinationID,
        sourceID,
        chartType,
        setchartType,
        toast,
        stateProps,
        currentItem: dataTypeUpdatedItemData,
      });
      if (updateChartResove.skip) {
        return;
      }
      if (dataTypeUpdatedItemData?.column) {
        if (allTableData) {
          if (selectedItemWithOriginalSchema?.filterData?.range) {
            const { min, max } = selectedItemWithOriginalSchema?.filterData?.range;
            const group = getGeoGroup([min, max]);
            setGeoGroup(group);
          }
        }
      }
    } else {
      // update ChartType
      const updateChartResove = updateChartType({
        axisData: destitems,
        destinationID,
        sourceID,
        chartType,
        setchartType,
        toast,
        stateProps,
        currentItem: dataTypeUpdatedItemData,
      });
      if (updateChartResove.skip) {
        return;
      }
      setGeoGroup([]);
    }
  }
  // IF ChartType is BARSTACKLINE
  if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
    if (destinationID === xAxisId) {
      // update ChartType
      const updateChartResove = updateChartType({
        axisData: destitems,
        secondAxisData: sourceitems,
        destinationID,
        sourceID,
        chartType,
        setchartType,
        toast,
        stateProps,
        currentItem: dataTypeUpdatedItemData,
      });
      if (updateChartResove.skip) {
        return;
      }

      let newTargeLine = [];
      if (selectedItemWithOriginalSchema?.filterData?.allValues) {
        newTargeLine = selectedItemWithOriginalSchema?.filterData?.uniqueValues || [];
      } else {
        newTargeLine = selectedItemWithOriginalSchema?.filterData?.uniqueValues || [];
      }
      // const newTargeLine = ['Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9'];

      const colomnTargetPoint = {
        [dataTypeUpdatedItemData.column]: newTargeLine,
      };

      setBarStackLineTargetPoints(() => {
        return { ...defaultChartSetting.barStackLineTargetPoints, ...colomnTargetPoint };
      });
    } else {
      // update ChartType
      const updateChartResove = updateChartType({
        axisData: destitems,
        destinationID,
        sourceID,
        chartType,
        setchartType,
        toast,
        stateProps,
        currentItem: dataTypeUpdatedItemData,
      });
      if (updateChartResove.skip) {
        return;
      }
      setBarStackLineTargetPoints(defaultChartSetting.barStackLineTargetPoints);
    }
  }

  destitems.splice(result.destination.index, 0, dataTypeUpdatedItemData);
  // update ChartType
  const updateChartResove = updateChartType({
    axisData: destitems,
    secondAxisData: sourceitems,
    destinationID,
    sourceID,
    chartType,
    setchartType,
    toast,
    stateProps,
    currentItem: dataTypeUpdatedItemData,
  });
  if (updateChartResove.skip) {
    return;
  }
  const newChartType = updateChartResove.currentChart;
  //updateAxisStyleLable
  updateAxisStyleLable(setSourceStyle, sourceitems);

  //updateAxisStyleLable
  updateAxisStyleLable(setDestinationStyle, destitems);

  //update ToolTip

  //Remove ToolTip
  setChartToolTip((preElem) => removeToolTip(preElem, dataTypeUpdatedItemData.uniqueColumnName));
  //Remove removeValueToolTip
  if (newChartType?.toolTip?.isMultipleValue) {
    setChartToolTip((preElem) =>
      removeValueToolTip(preElem, dataTypeUpdatedItemData.uniqueColumnName)
    );
  }
  console.log(chartType);
  //Add ToolTip
  updateToolTip({
    setChartToolTip,
    uniqueValues: selectedItemWithOriginalSchema?.filterData?.uniqueValues,
    selectedItem: dataTypeUpdatedItemData,
    chartType: newChartType,
    columnDropNumber,
    result,
    stateProps,
  });
  // IF ChartType is DOUBLEAXIS
  if (destinationID === yAxisId) {
    // setting Label of left and Rigth Axis
    setDoubleAxisLabel({ setDoubleAxis, yAxisData: destitems });
  }
  setSourceData(sourceitems);
  setDestinationData(destitems);
};

export const changeDestinationTableFunction = async (props) => {
  const {
    sourceData,
    destinationData,
    setDestinationData,
    result,
    tableIndex,
    destinationID,
    sourceID,
    setDestinationStyle,
    stateProps,
  } = props;

  const {
    setGeoGroup,
    toast,
    setchartType,
    chartType,
    setChartToolTip,
    chartToolTip,
    columnDropNumber,
    setColumnDropNumber,
    setBarStackLineTargetPoints,
    chartSchemaMemo,
    setKpiTargetPoints,
    kpiTargetPoints,
    setDoubleAxis,
  } = stateProps;
  if (!sourceData || !destinationData || !setDestinationData) {
    console.log('Error: changeDestinationFunction');
  }

  const tables = Array.from(sourceData);
  const sourceitems = Array.from(sourceData[tableIndex].items?.enableArray);
  // // Remove Table from tables
  tables.splice(tableIndex, 1);
  const destitems = Array.from(destinationData);
  const [selectedItem] = sourceitems.splice(result.source.index, 1);

  const selectedItemWithOriginalDataType = {
    ...selectedItem,
    originalDataType: selectedItem.dataType,
  };

  //Opration
  const dataTypeUpdatedItem = oprationAndDataTypeUpdate(
    { chartType, destinationID },
    selectedItemWithOriginalDataType
  );

  const dataTypeUpdatedItemData = dataTypeUpdatedItem.data;

  // update ChartType
  const updateChartResove = updateChartType({
    axisData: destitems,
    destinationID,
    sourceID,
    chartType,
    setchartType,
    toast,
    stateProps,
    currentItem: dataTypeUpdatedItemData,
  });

  // console.log(updateChartResove);
  // console.log('yoo');
  // return;
  if (updateChartResove.skip) {
    return;
  }

  // IF ChartType is PIVOTTABLE
  if (updateChartResove.isChartUpdated) {
    if (updateChartResove?.currentChart?.chartKey === allChartKeys.PIVOTTABLE) {
      setColumnDropNumber((currentValue) => currentValue + 1);
      const finalData = normalAxisSchema({ ...dataTypeUpdatedItemData, columnDropNumber });

      const [newFinalData] = setDefaultDateTimeOpration([finalData]);
      const destitemsWithdateTimeOperation = setDefaultDateTimeOpration(destitems);

      destitemsWithdateTimeOperation.splice(result.destination.index, 0, newFinalData);

      //updateAxisStyleLable
      updateAxisStyleLable(setDestinationStyle, destitemsWithdateTimeOperation);

      // setSourceData(sourceitems);
      setDestinationData(destitemsWithdateTimeOperation);

      //Add ToolTip
      updateToolTip({
        chartToolTip,
        setChartToolTip,
        uniqueValues: dataTypeUpdatedItemData?.filterData?.uniqueValues,
        selectedItem: newFinalData,
        chartType,
        columnDropNumber,
        result,
        stateProps,
      });
      return;
    }
  }

  setColumnDropNumber((currentValue) => currentValue + 1);

  const finalData = normalAxisSchema({ ...dataTypeUpdatedItemData, columnDropNumber });
  destitems.splice(result.destination.index, 0, finalData);

  //updateAxisStyleLable
  updateAxisStyleLable(setDestinationStyle, destitems);

  // setSourceData(sourceitems);
  setDestinationData(destitems);

  if (destinationID === yAxisId) {
    // IF ChartType is DoubleAxisChart
    // setting Label of left and Rigth Axis
    setDoubleAxisLabel({ setDoubleAxis, yAxisData: destitems });
  }

  // IF ChartType is KPIMETER
  if (updateChartResove?.currentChart?.chartKey === allChartKeys.KPIMETER) {
    if (destinationID === yAxisId) {
      const targets = await getAutomaticKpiTargetData({
        column: finalData,
        chartSchema: { ...chartSchemaMemo, yAxis: [...chartSchemaMemo?.yAxis, finalData] },
        chartType: updateChartResove?.currentChart,
        kpiTargetPoints,
      });
      if (targets?.skip === false) {
        setKpiTargetPoints((currentTargetPointData) => {
          return {
            ...currentTargetPointData,
            targets: targets?.finalTargets,
          };
        });
      }
    }
  }
  // IF ChartType is GEO
  if (
    updateChartResove?.currentChart?.chartKey === allChartKeys.GEOINDIA ||
    updateChartResove?.currentChart?.chartKey === allChartKeys.GEOUSA ||
    updateChartResove?.currentChart?.chartKey === allChartKeys.GEOCANADA ||
    updateChartResove?.currentChart?.chartKey === allChartKeys.GEOWORLD
  ) {
    if (destinationID === yAxisId) {
      if (dataTypeUpdatedItemData?.filterData?.range) {
        const { min, max } = dataTypeUpdatedItemData?.filterData?.range;
        const group = getGeoGroup([min, max]);
        setGeoGroup(group);
      }
    }
  }
  // IF ChartType is BARSTACKLINE
  if (updateChartResove?.currentChart?.chartKey === allChartKeys.BARSTACKLINE) {
    if (destinationID === xAxisId) {
      let newTargeLine = [];
      if (dataTypeUpdatedItemData?.filterData?.allValues) {
        newTargeLine = dataTypeUpdatedItemData?.filterData?.uniqueValues || [];
      } else {
        newTargeLine = dataTypeUpdatedItemData?.filterData?.uniqueValues || [];
      }
      // const newTargeLine = ['Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9'];
      const colomnTargetPoint = {
        [`${dataTypeUpdatedItemData.tableName}.${dataTypeUpdatedItemData.columnName}`]:
          newTargeLine,
      };

      setBarStackLineTargetPoints(() => {
        return { ...defaultChartSetting.barStackLineTargetPoints, ...colomnTargetPoint };
      });
    }
  }

  //Add ToolTip
  updateToolTip({
    chartToolTip,
    setChartToolTip,
    uniqueValues: dataTypeUpdatedItemData?.filterData?.uniqueValues,
    selectedItem: finalData,
    chartType: updateChartResove?.currentChart ?? chartType,
    columnDropNumber,
    result,
    stateProps,
  });
};

export const sameDestinationFunction = (props) => {
  const { sourceData, setSourceData, result, tableIndex, destinationID, stateProps } = props;
  const { setDoubleAxis } = stateProps;
  if (destinationID === tableId) {
    const tables = Array.from(sourceData);
    const items = Array.from(sourceData[tableIndex].items);
    // Remove Table from tables
    const [reorderedTable] = tables.splice(tableIndex, 1);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // Add Table from Tables
    const newReorderedTable = { tableName: reorderedTable.tableName, items };
    tables.splice(tableIndex, 0, newReorderedTable);
    setSourceData(tables);
  } else if (destinationID === toolTip) {
    const items = Array.from(sourceData.style);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newItem = {
      ...sourceData,
      style: items,
    };
    setSourceData(newItem);
  } else {
    const items = Array.from(sourceData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSourceData(items);
    // IF ChartType is DOUBLEAXIS
    if (destinationID === yAxisId) {
      // setting Label of left and Rigth Axis
      setDoubleAxisLabel({ setDoubleAxis, yAxisData: items });
    }
  }
};
export const chartAxisKey = ({ axisId, chartType }) => {
  if (axisId === xAxisId) {
    return chartType?.axis?.axisType?.xAxis;
  }
  if (axisId === yAxisId) {
    return chartType?.axis?.axisType?.yAxis;
  }
  if (axisId === groupId) {
    return chartType?.axis?.axisType?.group;
  }
  if (axisId === tooltipAxisId) {
    return chartType?.axis?.axisType?.tooltipAxis ?? chartType?.axis?.axisType?.yAxis;
  }
};

const oprationAndDataTypeUpdate = (props, sourceitems) => {
  const { chartType, destinationID } = props;

  //  IF SAME  TYPE
  if (chartAxisKey({ axisId: destinationID, chartType }) === sourceitems.originalDataType) {
    if (chartType?.chartKey === allChartKeys.SCATTERPLOT) {
      return {
        isSkip: false,
        data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
      };
    }
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      return {
        isSkip: false,
        data: {
          ...sourceitems,
          operations: { type: defaultOperation.num.sum.key },
          dataType: sourceitems.originalDataType,
        },
      };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartAxisKey { destinationID, chartType }NUM
  if (chartAxisKey({ axisId: destinationID, chartType }) === defaultDataType.NUM) {
    // if Item is CAT
    if (sourceitems.originalDataType === defaultDataType.CAT) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is DATETIME
    if (sourceitems.originalDataType === defaultDataType.DATETIME) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is GEO
    if (sourceitems.originalDataType === defaultDataType.GEO) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    // if Item is ID
    if (
      sourceitems.originalDataType === defaultDataType.ID_NUM ||
      sourceitems.originalDataType === defaultDataType.ID_TEXT
    ) {
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.NUM,
        operations: { type: defaultOperation.cat.count.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType CAT
  if (chartAxisKey({ axisId: destinationID, chartType }) === defaultDataType.CAT) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    // if Item is DATETIME
    if (sourceitems.originalDataType === defaultDataType.DATETIME) {
      //  PIVOTTABLE || HEATMAP
      // if (
      //   chartType.chartKey === allChartKeys.PIVOTTABLE ||
      //   chartType.chartKey === allChartKeys.HEATMAP
      // ) {
      // }
      const newSourceItem = {
        ...sourceitems,
        dataType: defaultDataType.DATETIME,
        operations: { type: defaultOperation.dateTime.month.key },
      };
      return { isSkip: false, data: newSourceItem };
    }
    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType DATETIME
  if (chartAxisKey({ axisId: destinationID, chartType }) === defaultDataType.DATETIME) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType GEO
  if (chartAxisKey({ axisId: destinationID, chartType }) === defaultDataType.GEO) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }

  // chartType ID
  if (chartAxisKey({ axisId: destinationID, chartType }) === defaultDataType.ID) {
    // if Item is NUM
    if (sourceitems.originalDataType === defaultDataType.NUM) {
      const newSourceItem = { ...sourceitems, dataType: defaultDataType.CAT, operations: {} };
      return { isSkip: false, data: newSourceItem };
    }

    return {
      isSkip: false,
      data: { ...sourceitems, operations: {}, dataType: sourceitems.originalDataType },
    };
  }
};

export const updateToolTip = (props) => {
  const defaultShow = true;
  const {
    setChartToolTip,
    selectedItem,
    columnDropNumber,
    chartType,
    uniqueValues,
    result,
    // stateProps,
  } = props;
  const colorNumber = columnDropNumber % defaultLegendColorPalettes.length;
  // if (chartType?.toolTip?.target) {
  //   const target = chartType.toolTip.target;
  //   if (target.trim() !== '') {
  //     if (result.destination.droppableId !== target) {
  //       return;
  //     }
  //   }
  // }
  const isOpration = selectedItem?.operations?.type;
  const newChartToolTip = {
    label: isOpration ? `(${isOpration}) ${selectedItem.label}` : selectedItem.label,
    originalLabel: selectedItem.label,
    origin: selectedItem.tableName, // Need to update
    show: defaultShow,
    color: defaultLegendColorPalettes[colorNumber],
    opacity: defaultOpacity,
    column: selectedItem.column,
    uniqueColumnName: selectedItem.uniqueColumnName,
    axis: result.destination.droppableId,
  };

  setChartToolTip((preElem) => {
    return { ...preElem, style: [...preElem.style, newChartToolTip] };
  });

  setChartToolTip((currentChartToolTip) => {
    if (chartType?.toolTip?.isMultipleValue) {
      const newValue = getUniueValuesFunction({
        uniqueValues,
        selectedItem,
        defaultShow,
        axis: result.destination.droppableId,
      });
      const isColorfulColor = ColorfulColorChart.some((elem) => {
        return elem.chartKey === chartType.chartKey;
      });
      const newValueWithAutomaticColor = setAutomaticColor({
        target: newValue,
        chartToolTip: currentChartToolTip,
        defaultOpacity,
        targetStyle: currentChartToolTip.valueStyle,
        isColorfulColor,
      });
      return {
        ...currentChartToolTip,
        valueStyle: [...currentChartToolTip.valueStyle, ...newValueWithAutomaticColor],
      };
    } else {
      return { ...currentChartToolTip, valueStyle: [] };
    }
  });
};

export const updateToolTipByValue = (props) => {
  const defaultShow = true;
  const { chartToolTip, setChartToolTip, selectedItem, uniqueValues } = props;
  const newValue = getUniueValuesFunction({ uniqueValues, selectedItem, defaultShow });
  const newValueWithAutomaticColor = setAutomaticColor({
    target: newValue,
    chartToolTip,
    defaultOpacity,
  });
  setChartToolTip((preElem) => {
    return { ...preElem, style: [...preElem.style, ...newValueWithAutomaticColor] };
  });
};

function normalizeNumber(num, digits) {
  if (num < 10) return 0;
  const n = Math.round(num);
  // const digits = n.toString().length - 2;
  return Math.ceil(n / Math.pow(10, digits)) * Math.pow(10, digits);
}
// arr is the array of data// length is the number of parts you want to divide the range into
export function getGeoGroup(arr, length = 5) {
  const defaultOpacity = 100;
  const start = Math.min(...arr);
  const end = Math.max(...arr);
  const step = (end - start) / length;
  if (typeof start !== 'number' || typeof end !== 'number' || typeof step !== 'number') {
    return [];
  } else if (step <= 0) {
    return [];
  } else {
    const result = [];
    let count = 0;
    for (let i = start + step; i <= end; i += step) {
      let groupColorIndex = count % GEOGroupColors.length;
      result.push({
        from: count ? result[count - 1].to : count,
        to: normalizeNumber(i, step.toString().length - 3),
        color: GEOGroupColors[groupColorIndex],
        opacity: defaultOpacity,
      });
      count++;
    }
    return result;
  }
}
