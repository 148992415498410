import { _uploadFileToServer } from 'lib/server-connection/connections';
import { GoogleSheetsInfo, UploadExcelSheetInfo } from './form-info';

import { defalutTimeInterval } from '../screens/data-source/components/EditConnections/GetFileds/timeIntervalData';
interface FormField {
  name: string;
  label: string;
  required: boolean;
  type: 'number' | 'text' | 'password' | 'file';
  initialValue?: string;
  userValueSetter?: Function;
  accept?: string;
}

interface ConnectionFormSchema {
  type: string;
  name: string;
  fields: FormField[];
  info?: Function;
}

function checkFileExtension(file: any) {
  const fileExtensions = ['.xlsx', '.xls', '.csv', '.tsv'];
  const fileExtension = (file?.name || '').split('.').pop();
  if (fileExtensions.includes('.' + fileExtension)) {
    return true;
  }
  return false;
}

export const connectionFieldsType = {
  toggleSwitch: 'toggleSwitch',
  number: 'number',
  tableConfig: 'tableConfig',
  inputString: 'inputString',
  password: 'password',
  textArea: 'textArea',
  hrDivider: 'hrDivider',
  sslConfig: 'sslConfig',
  uploadFile: 'uploadFile',
  dateTimeColumn: 'dateTimeColumn',
  googleSheetInput: 'googleSheetInput',
  idField: 'idField',
  dataFilter: 'dataFilter',
  timeInterval: 'timeInterval',
};
export const uploadFileTableConfigFields = [
  {
    name: 'useHeader',
    label: 'Sheet contains header row?',
    required: false,
    defaultValue: true,
    type: connectionFieldsType.toggleSwitch,
  },
  {
    name: 'defaultDatetimeColumn',
    label: 'Default Datetime Column',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.dateTimeColumn,
  },
  {
    name: 'ignoreEmptyStrings',
    label: 'ignore empty strings?',
    required: false,
    defaultValue: true,
    type: connectionFieldsType.toggleSwitch,
  },
  {
    name: 'startRow',
    label: 'Row Data Start from',
    required: false,
    defaultValue: 0,
    minValue: 0,
    maxValue: 25,
    type: connectionFieldsType.number,
  },

  // {
  //   name: 'stopOnErrors',
  //   label: 'Stop on errors?',
  //   required: false,
  //   defaultValue: true,
  //   type: connectionFieldsType.toggleSwitch,
  // },
  {
    name: 'startCol',
    label: 'Column Data Start from',
    required: false,
    defaultValue: 0,
    minValue: 0,
    // maxValue: 25,
    type: connectionFieldsType.number,
  },
  {
    name: 'fetchDataFilter',
    label: 'Fetch Data Filter',
    required: false,
    defaultValue: {},
    type: connectionFieldsType.dataFilter,
  },
];
export const appTableConfigFields = [
  {
    name: 'useHeader',
    label: 'Sheet contains header row?',
    required: false,
    defaultValue: true,
    type: connectionFieldsType.toggleSwitch,
  },
  {
    name: 'defaultDatetimeColumn',
    label: 'Default Datetime Column',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.dateTimeColumn,
  },
  {
    name: 'ignoreEmptyStrings',
    label: 'ignore empty strings?',
    required: false,
    defaultValue: true,
    type: connectionFieldsType.toggleSwitch,
  },
  {
    name: 'startRow',
    label: 'Row Data Start from',
    required: false,
    defaultValue: 0,
    minValue: 0,
    maxValue: 25,
    type: connectionFieldsType.number,
  },

  // {
  //   name: 'stopOnErrors',
  //   label: 'Stop on errors?',
  //   required: false,
  //   defaultValue: true,
  //   type: connectionFieldsType.toggleSwitch,
  // },
  {
    name: 'startCol',
    label: 'Column Data Start from',
    required: false,
    defaultValue: 0,
    minValue: 0,
    // maxValue: 25,
    type: connectionFieldsType.number,
  },
  {
    name: 'fetchDataFilter',
    label: 'Fetch Data Filter',
    required: false,
    defaultValue: {},
    type: connectionFieldsType.dataFilter,
  },
  {
    name: 'refetchRequestInterval',
    label: 'Data Refetch Interval',
    required: true,
    defaultValue: defalutTimeInterval,
    type: connectionFieldsType.timeInterval,
  },
];
export const databaseTableConfigFields = [
  // {
  //   name: 'stopOnErrors',
  //   label: 'Stop on errors?',
  //   required: false,
  //   defaultValue: true,
  //   type: connectionFieldsType.toggleSwitch,
  // },
  {
    name: 'defaultDatetimeColumn',
    label: 'Default Datetime Column',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.dateTimeColumn,
  },
  {
    name: 'ignoreEmptyStrings',
    label: 'ignore empty strings?',
    required: false,
    defaultValue: true,
    type: connectionFieldsType.toggleSwitch,
  },
  // {
  //   name: 'rowDataStartFrom',
  //   label: 'Row Data Start from',
  //   required: false,
  //   defaultValue: 0,
  //   minValue: 0,
  //   maxValue: 50,
  //   type: connectionFieldsType.number,
  // },

  // {
  //   name: 'columnDataStartFrom',
  //   label: 'Column Data Start from',
  //   required: false,
  //   defaultValue: 0,
  //   minValue: 0,
  //   maxValue: 25,
  //   type: connectionFieldsType.number,
  // },

  {
    name: 'lastUpdatedAtColumn',
    label: 'Last Updated at Column',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.dateTimeColumn,
  },
  {
    name: 'primaryId',
    label: 'Primary ID',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.idField,
  },
  {
    name: 'fetchDataFilter',
    label: 'Fetch Data Filter',
    required: false,
    defaultValue: '',
    type: connectionFieldsType.dataFilter,
  },
  {
    name: 'refetchRequestInterval',
    label: 'Data Refetch Interval',
    required: true,
    defaultValue: defalutTimeInterval,
    type: connectionFieldsType.timeInterval,
  },
];
export const defaultConnectionSchema = [
  {
    name: 'connectionName',
    label: 'Connection Name',
    required: true,
    defaultValue: '',
    type: connectionFieldsType.inputString,
  },
  {
    name: 'description',
    label: 'Description',
    required: false,
    defaultValue: '',
    maxLength: 100,
    type: connectionFieldsType.textArea,
  },
  {
    name: null,
    label: null,
    required: null,
    defaultValue: null,
    type: connectionFieldsType.hrDivider,
  },
];
export const newConnectionFormsSchema = {
  upload: {
    name: 'Upload',
    key: 'upload',
    type: {
      uploadFile: {
        key: 'uploadFile',
        type: '__d3__ExcelCSVUpload',
        name: 'Upload File',
        refreshInterval: 3,
        accept: {
          xlsx: {
            name: 'Excel',
            key: 'xlsx',
            extension: 'xlsx',
            icon: '/media/dataLake/Icons/fileTypeIcons/xlsxIcon.svg',
          },
          xls: {
            name: 'Excel',
            key: 'xls',
            extension: 'xls',
            icon: '/media/dataLake/Icons/fileTypeIcons/xlsIcon.svg',
          },
          tsv: {
            name: 'tsv',
            key: 'tsv',
            extension: 'tsv',
            icon: '/media/dataLake/Icons/fileTypeIcons/tsvIcon.svg',
          },
          json: {
            name: 'Json',
            key: 'json',
            extension: 'json',
            icon: '/media/dataLake/Icons/fileTypeIcons/jsonIcon.svg',
          },
          csv: {
            name: 'csv',
            key: 'csv',
            extension: 'csv',
            icon: '/media/dataLake/Icons/fileTypeIcons/csvIcon.svg',
          },
          txt: {
            name: 'txt',
            key: 'txt',
            extension: 'txt',
            icon: '/media/dataLake/Icons/fileTypeIcons/txtIcon.svg',
          },
        },
        maxSize: 1024 * 1024 * 1024, // 1GB
        fields: [],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: uploadFileTableConfigFields,
        },
      },
    },
  },
  dataBase: {
    name: 'Database',
    key: 'dataBase',
    type: {
      postgreSQL: {
        key: 'postgreSQL',
        type: '__d3__postgres',
        name: 'PostgreSQL',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/postgreSQLIcon.svg',
        fields: [
          {
            name: 'host',
            label: 'Host',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 5432,
            type: connectionFieldsType.number,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'sslrootcert',
                label: 'SSL Rootcert',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslcert',
                label: 'SSL cert ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslprivatekey',
                label: 'SSL Private Key ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
          {
            name: 'authenticationDB',
            label: 'Authentication Database',
            required: true,
            defaultValue: 'admin',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      mongoDB: {
        type: '__d3__mongoDB',
        name: 'Mongo DB',
        key: 'mongoDB',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/mongodbIcon.svg',
        fields: [
          {
            name: 'uri',
            label: 'Connection URI',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'clientcertificate',
                label: 'Client Certificate',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'certificateAuthority',
                label: 'Certificate Authority',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      mySQL: {
        type: '__d3__mysql',
        name: 'MySQL',
        key: 'mySQL',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/mysqlIcon.svg',
        fields: [
          {
            name: 'host',
            label: 'Host',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'sslrootcert',
                label: 'SSL Rootcert',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslcert',
                label: 'SSL cert ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslprivatekey',
                label: 'SSL Private Key ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      microsoftSQL: {
        type: '__d3__mssql',
        name: 'Microsoft SQL (Azure DB)',
        key: 'microsoftSQL',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/microsoftAzureIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },

      snowFlake: {
        type: '__d3__snowFlake',
        name: 'Snowflake',
        key: 'snowFlake',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/snowflakeIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'role',
            label: 'Role',
            required: false,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'warehouse',
            label: 'Warehouse',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      amazonAuroraMysql: {
        type: '__d3__amazonAuroraMysql',
        name: 'Amazon Aurora for MySQl',
        key: 'amazonAuroraMysql',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/amazonAuroraIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      amazonRedShift: {
        type: '__d3__amazonRedShift',
        name: 'Amazon RedShift',
        key: 'amazonRedShift',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/amazonRedshiftIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 5439,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      exasol: {
        type: '__d3__exasol',
        name: 'Exasol',
        key: 'exasol',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/exasolIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 8563,
            type: connectionFieldsType.number,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      googleCloudSQL: {
        type: '__d3__googleCloudSQL',
        name: 'Google Cloud SQL',
        key: 'googleCloudSQL',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/goggleCloudSqlIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      incortaConnector: {
        type: '__d3__incortaConnector',
        name: 'Incorta Connector by Incorta',
        key: 'incortaConnector',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/incortaIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'tenant',
            label: 'Tenant',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      mariaDB: {
        type: '__d3__mariaDB',
        name: 'MariaDB',
        key: 'mariaDB',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/mariadbIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      singleStore: {
        type: '__d3__singleStore',
        name: 'Single Store',
        key: 'singleStore',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/singlestoreIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },

      starburstEnterprise: {
        type: '__d3__starburstEnterprise',
        name: 'Starburst Enterprise by Starburst',
        key: 'starburstEnterprise',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/starburstIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 8080,
            type: connectionFieldsType.number,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      teradata: {
        type: '__d3__teradata',
        name: 'Teradata',
        key: 'teradata',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/teradataIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      vertica: {
        type: '__d3__vertica',
        name: 'Vertica',
        key: 'vertica',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/databaseIcons/verticaIcon.svg',
        fields: [
          {
            name: 'server',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 5433,
            type: connectionFieldsType.number,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
    },
  },
  apps: {
    name: 'Apps',
    key: 'apps',
    type: {
      supista: {
        type: '__d3__supista',
        name: 'Supista',
        key: 'supista',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/supista.svg',
        fields: [
          {
            name: 'serverUrl',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password or Access Key',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
        isHidden: true,
      },
      googleSheets: {
        type: '__d3__googleSheets',
        name: 'Google Sheets',
        key: 'googleSheets',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/googleSheetsIcon.svg',
        fields: [
          {
            name: 'spreadsheetID',
            label: 'Google Sheet URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.googleSheetInput,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },

      googleAds: {
        type: '__d3__googleAds',
        name: 'Google Ads',
        key: 'googleAds',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/googleAdsIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      amazonMarketplace: {
        type: '__d3__amazonparketplace',
        name: 'Amazon Marketplace',
        key: 'amazonMarketplace',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/amazonMarketplaceIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      metaAds: {
        type: '__d3__metaAds',
        name: 'Meta Ads',
        key: 'metaAds',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/metaAdsIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      odoo: {
        type: '__d3__odoo',
        name: 'Odoo',
        key: 'odoo',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/odooIcon.svg',
        fields: [
          {
            name: 'serverUrl',
            label: 'Server URL',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password or Access Key',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      shopify: {
        type: '__d3__shopify',
        name: 'Shopify',
        key: 'shopify',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/shopifyIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      hubspot: {
        type: '__d3__hubspot',
        name: 'Hub Spot',
        key: 'hubspot',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/hubspotIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      salesforce: {
        type: '__d3__salesforce',
        name: 'Salesforce',
        key: 'salesforce',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/salesforceIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },
      freshsales: {
        type: '__d3__Freshsales',
        name: 'Freshsales',
        key: 'freshsales',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/freshsalesIcon.svg',
        fields: [
          {
            name: 'client_id',
            label: 'Client ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'client_secret',
            label: 'Client Secret',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'userID',
            label: 'User ID',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'authorization_code',
            label: 'Authorization Code',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: appTableConfigFields,
        },
      },

      wordpress: {
        type: '__d3__wordpress',
        name: 'Wordpress',
        key: 'wordpress',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/wordpressIcon.svg',
        fields: [
          {
            name: 'host',
            label: 'Host',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'sslrootcert',
                label: 'SSL Rootcert',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslcert',
                label: 'SSL cert ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslprivatekey',
                label: 'SSL Private Key ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      wooCommerce: {
        type: '__d3__wooCommerce',
        name: 'Woo Commerce',
        key: 'wooCommerce',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/wooCommerceIcon.svg',
        fields: [
          {
            name: 'host',
            label: 'Host',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'sslrootcert',
                label: 'SSL Rootcert',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslcert',
                label: 'SSL cert ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslprivatekey',
                label: 'SSL Private Key ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
      wix: {
        type: '__d3__wix',
        name: 'Wix',
        key: 'wix',
        refreshInterval: 3,
        icon: '/media/dataLake/Icons/appIcons/wixIcon.svg',
        fields: [
          {
            name: 'host',
            label: 'Host',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'port',
            label: 'Port',
            required: true,
            defaultValue: 3306,
            type: connectionFieldsType.number,
          },
          {
            name: 'ssl',
            label: 'SSL',
            required: true,
            defaultValue: false,
            sslToggle: {
              name: 'ssl',
              label: 'SSL',
              required: false,
              defaultValue: false,
              type: connectionFieldsType.toggleSwitch,
            },
            sslSchemaKey: 'sslConfigs',
            sslConfigs: [
              {
                name: 'sslrootcert',
                label: 'SSL Rootcert',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslcert',
                label: 'SSL cert ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
              {
                name: 'sslprivatekey',
                label: 'SSL Private Key ',
                required: false,
                defaultValue: '',
                accept: {},
                acceptALL: true,
                maxSize: 1024 * 1024 * 1, // 1MB
                type: connectionFieldsType.uploadFile,
              },
            ],
            type: connectionFieldsType.sslConfig,
          },

          {
            name: 'username',
            label: 'Username',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
          {
            name: 'password',
            label: 'Password',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.password,
          },
          {
            name: 'db_name',
            label: 'Database Name',
            required: true,
            defaultValue: '',
            type: connectionFieldsType.inputString,
          },
        ],
        tableConfig: {
          name: 'tableConfig',
          label: 'Import Tables',
          required: false,
          defaultValue: [],
          type: connectionFieldsType.tableConfig,
          tableConfigFields: databaseTableConfigFields,
        },
      },
    },
  },
};
const connectionFormsSchema: ConnectionFormSchema[] = [
  {
    type: '__d3__ExcelCSVUpload',
    name: 'Upload Excel File',
    info: UploadExcelSheetInfo,
    fields: [
      {
        name: 'fileName',
        label: 'Upload File (xlsx|xls|csv)',
        required: true,
        type: 'file',
        accept: '.xls,.xlsx,.csv,.tsv',
        userValueSetter: async (file: any, appID: string, credentialID: string) => {
          let err = null;
          if (!checkFileExtension(file)) {
            err = 'Please select a valid file (.xlsx|.xls|.csv|.tsv)';
            return { err, newValue: '' };
          }
          const formData = new FormData();
          formData.append('file', file);
          formData.append('appID', appID);
          formData.append('credentialID', credentialID);

          const response = await _uploadFileToServer(`${appID}/uploadExcelFile`, formData);
          if (response?.__d3__error || !response?.response?.fileName)
            return { err: 'Could not upload file on the server!' };
          return {
            err,
            newValue: response?.response?.fileName,
            additionalValues: { folderName: appID },
          };
        },
      },
    ],
  },
  {
    type: '__d3__postgres',
    name: 'PostgreSQL',
    fields: [
      {
        name: 'host',
        label: 'Host',
        required: true,
        type: 'text',
      },
      {
        name: 'port',
        label: 'Port',
        required: true,
        type: 'number',
        initialValue: '5432',
      },
      {
        name: 'username',
        label: 'Username',
        required: true,
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        required: true,
        type: 'password',
      },
      {
        name: 'authenticationDB',
        label: 'Authentication Database',
        required: true,
        type: 'text',
      },
      {
        name: 'db_name',
        label: 'Database Name',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    type: '__d3__googleSheets',
    name: 'Google Sheets',
    info: GoogleSheetsInfo,
    fields: [
      {
        name: 'spreadsheetID',
        label: 'Google Sheet URL',
        required: true,
        type: 'text',
        userValueSetter: (value = '', appID = '', credentialID = '') => {
          // updating the google sheet URL with google Sheet ID.
          if (value.includes('https://docs.google.com/spreadsheets/d/')) {
            const sheetID = value.split('/')[5] || '';
            if (sheetID && sheetID.length > 30) {
              return { err: null, newValue: sheetID };
            }
          }
          return { err: 'Please enter a valid google sheet URL', newValue: null };
        },
      },
    ],
  },
  {
    type: '__d3__mongoDB',
    name: 'Mongo DB',
    fields: [
      {
        name: 'uri',
        label: 'Connection URI',
        required: true,
        type: 'text',
      },
      {
        name: 'db_name',
        label: 'Database Name',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    type: '__d3__mysql',
    name: 'MySQL',
    fields: [
      {
        name: 'host',
        label: 'Host',
        required: true,
        type: 'text',
      },
      {
        name: 'port',
        label: 'Port',
        required: true,
        type: 'number',
        initialValue: '3306',
      },
      {
        name: 'username',
        label: 'Username',
        required: true,
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        required: true,
        type: 'text',
      },
      {
        name: 'db_name',
        label: 'Database Name',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    type: '__d3__mssql',
    name: 'Microsoft SQL (Azure DB)',
    fields: [
      {
        name: 'server',
        label: 'Server URL',
        required: true,
        type: 'text',
      },
      {
        name: 'db_name',
        label: 'Database Name',
        required: true,
        type: 'text',
      },
      {
        name: 'username',
        label: 'Username',
        required: true,
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        required: true,
        type: 'text',
      },
    ],
  },
  // {
  //   type: '__d3__msExcel',
  //   name: 'MS Excel',
  //   info: MSExcelInfo,
  //   fields: [
  //     {
  //       name: 'sheetName',
  //       label: 'File Name (xlsx|xls)',
  //       required: false,
  //       type: 'text',
  //     }
  //   ]
  // }
];

export default connectionFormsSchema;
