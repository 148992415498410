import { mls } from 'lib/multilanguagesupport';

interface TBDPreviewProps {
  sheetIDsTBD: any[];
  reportIDsKeys: any[];
  dashIDsKeys: any[];
  joinTableIDsTBD?: any[];
  customTableIDsTBD?: any[];
  tableIDsTBD?: any[];
  sheetsSchema: { [key: string]: any };
  reportSchema: { [key: string]: any };
  dashSchema: { [key: string]: any };
  joinTableSchema?: { [key: string]: any };
  customTableSchema?: { [key: string]: any };
  appDatatable?: { [key: string]: any };
  titleString?: string;
}
const TBDPreview = (TBDPreviewProps: TBDPreviewProps) => {
  const {
    sheetIDsTBD = [],
    reportIDsKeys = [],
    dashIDsKeys = [],
    joinTableIDsTBD = [],
    customTableIDsTBD = [],
    tableIDsTBD = [],
    sheetsSchema = {},
    reportSchema = {},
    dashSchema = {},
    joinTableSchema = {},
    customTableSchema = {},
    appDatatable = {},
    titleString = 'This table is currently in use, so deleting it will delete the following',
  } = TBDPreviewProps;
  const maxheight = '20vh';
  return (
    <>
      {(sheetIDsTBD.length > 0 ||
        reportIDsKeys.length > 0 ||
        dashIDsKeys.length > 0 ||
        joinTableIDsTBD.length > 0 ||
        customTableIDsTBD.length > 0) && (
        <div
          className='alert alert-warning my-2 py-5'
          style={{ maxHeight: '30vw', overflow: 'auto' }}
          role='alert'
        >
          <p className='fw-bold mb-2' style={{ textAlign: 'center' }}>
            {mls(titleString)}.
          </p>
          {/* <br /> */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `
                  ${sheetIDsTBD.length > 0 ? '1fr' : ''} 
                  ${reportIDsKeys.length > 0 ? '1fr' : ''} 
                  ${dashIDsKeys.length > 0 ? '1fr' : ''} 
                  ${joinTableIDsTBD.length > 0 ? '1fr' : ''} 
                  ${customTableIDsTBD.length > 0 ? '1fr' : ''} 
                  ${tableIDsTBD.length > 0 ? '1fr' : ''} 
                  `,
              gap: '.5rem',
            }}
          >
            {tableIDsTBD.length > 0 && (
              <div>
                <h6 style={{ textAlign: 'center' }} className='fw-bold mb-2'>
                  {tableIDsTBD.length === 1 ? 'Data Table:' : 'Data Tables:'}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {tableIDsTBD.map((tableID) => (
                      <li className='text-danger' key={tableID}>
                        {appDatatable[tableID]?.name || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
            {sheetIDsTBD.length > 0 && (
              <div>
                <h6 style={{ textAlign: 'center' }} className='fw-bold mb-2'>
                  {mls('Sheet List')}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {sheetIDsTBD.map((sheetID: string) => (
                      <li className='text-danger' key={sheetID}>
                        {sheetsSchema[sheetID]?.name || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
            {joinTableIDsTBD.length > 0 && (
              <div>
                <h6 className='fw-bold mb-2' style={{ textAlign: 'center' }}>
                  {mls('Join Table List')}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {joinTableIDsTBD.map((joinTableID: string) => (
                      <li className='text-danger' key={joinTableID}>
                        {joinTableSchema[joinTableID]?.name || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
            {customTableIDsTBD.length > 0 && (
              <div>
                <h6 className='fw-bold mb-2' style={{ textAlign: 'center' }}>
                  {mls('Custom Table List')}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {customTableIDsTBD.map((customTableID: string) => (
                      <li className='text-danger' key={customTableID}>
                        {customTableSchema[customTableID]?.name || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
            {reportIDsKeys.length > 0 && (
              <div>
                <h6 className='fw-bold mb-2' style={{ textAlign: 'center' }}>
                  {mls('Reports List')}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {reportIDsKeys.map((reportID: string) => (
                      <li className='text-danger' key={reportID}>
                        {reportSchema[reportID]?.name || reportSchema[reportID]?.reportID || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
            {dashIDsKeys.length > 0 && (
              <div>
                <h6 className='fw-bold mb-2' style={{ textAlign: 'center' }}>
                  {mls('Dashboard List')}
                </h6>
                <ul style={{ overflow: 'auto', maxHeight: maxheight }}>
                  <>
                    {dashIDsKeys.map((dashID: string) => (
                      <li className='text-danger' key={dashID}>
                        {dashSchema[dashID]?.name || dashSchema[dashID]?.dashID || ''}
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default TBDPreview;
