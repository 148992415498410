import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import {
  dateTimeDefaultOption,
  defaultDateTimeFrame,
  defaultFormattingType,
  defaultFormattingValue,
} from 'lib/reusable-components/reusableData/formatterData';
import React from 'react';
import './formatter.scss';
import { mls } from 'lib/multilanguagesupport';
interface DateTimeFormatterCompProps {
  formatter: any;
  format: string;
  setFormatter: Function;
  short?: boolean;
  divBorder?: boolean;
}
const DateTimeFormatterComp = ({
  formatter,
  setFormatter,
  short = false,
  divBorder = true,
  format = defaultDateTimeFrame,
}: DateTimeFormatterCompProps) => {
  const handleContry = (e: string) => {
    const updatedFormatter = {
      ...formatter,
      contry: e,
      formatterType: 'dateTime',
    };
    setFormatter(updatedFormatter);
  };
  const formatDateTime =
    defaultFormattingType.dateTime[format as keyof typeof defaultFormattingType.dateTime];
  if (!formatDateTime?.isOptions) {
    return <></>;
  }
  const getDateTimeOption = ({ options, key, format }: any) => {
    const option = options[key as keyof typeof options];
    if (!option) {
      return dateTimeDefaultOption[format as keyof typeof dateTimeDefaultOption];
    }
    return option;
  };

  const options = getDateTimeOption({
    options: formatDateTime?.dateTimeOptions,
    key: formatter?.contry,
    format,
  });

  return (
    <div
      className={
        short || !divBorder
          ? 'formatterDivContainer'
          : 'formatterDivContainer formatterDivWithBorder'
      }
    >
      {!short && divBorder ? <div className='titleDiv'>&nbsp;{mls('Date Format')}</div> : null}
      <>
        {formatDateTime.isOptions ? (
          <ObjectDropDown
            object={formatDateTime?.dateTimeOptions ?? {}}
            dropDownValue={options?.key ?? defaultFormattingValue.dateTime.contry}
            setdropDownValue={handleContry}
            ObjectInObject={true}
          />
        ) : null}
      </>
    </div>
  );
};

export default DateTimeFormatterComp;
