import { mls } from 'lib/multilanguagesupport';
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import DataLakeCard from 'lib/data-connectors/dependencies/DataLakeCard/DataLakeCard';
import { connectionTypes } from 'lib/data-connectors/dependencies/constant';
import { useLocation } from 'react-router-dom';
import { getSheetChartsIDsTBD } from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { useEffect, useState } from 'react';

export const Card = ({
  title,
  description,
  numOfColumns,
  cardData,
  index,
  handleShow,
  connectionName,
  connectorType,
  isSqlAdded,
  setDataIndex,
  setDataTableSchema,
}) => {
  const [selectedSheetID, setSelectedSheetID] = useState('');
  const handleCardOpen = () => {
    setDataIndex(index);
    setDataTableSchema(cardData);
    handleShow();
  };
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );

  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  useEffect(() => {
    const { sheetIDsTBD } = getSheetChartsIDsTBD(
      [cardData?.tableID ?? ''],
      [],
      analyticsSchema?.appSheets || {}
    );
    setSelectedSheetID(sheetIDsTBD[0] ?? '');
  }, [cardData?.tableID, analyticsSchema?.appSheets]);
  return (
    <DataLakeCard
      settingButtonFunction={handleCardOpen}
      titleButtonFunction={handleCardOpen}
      title={title}
      bottomButtonFunction={handleCardOpen}
      bottomButtonTitle={mls('Go To Table')}
      description={description}
    >
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
        <strong>{mls('Connection Name')}: </strong>
        {mls(connectionName)}
      </p>
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
        <strong>{mls('Connection Type')}: </strong>
        {mls(connectionTypes?.[connectorType])}
      </p>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <a
          href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
          target='_blank'
          rel='noreferrer'
        >
          <span className='linkButton'>Go to Sheet</span>
        </a>
      </div> */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {selectedSheetID ? (
          <a
            href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
            target='_blank'
            rel='noreferrer'
          >
            <span className='linkButton'>{mls('Go To Sheet')}</span>
          </a>
        ) : (
          <div className='noSheet text-muted'>{mls('No Sheet Connected')}</div>
        )}
        {isSqlAdded ? (
          <p className=' fw-bold fs-5 m-0 mt-4 mb-3'>
            <strong
              style={{
                color: '#155724',
                background: '#d4edda',
                padding: '.25rem .5rem',
                borderRadius: '6px',
              }}
            >
              {mls('SQL Added')}
            </strong>
          </p>
        ) : null}
      </div>
    </DataLakeCard>
  );
};
