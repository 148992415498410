import { chartTypeReport, defaultWidthTypes } from '../data/defaultSchema';
import { supistaApiPost } from './connections';
export const getStyling = (styling: any) => {
  const textSizeAdjustment = 8;
  if (!styling) {
    return {
      color: 'black',
      fill: 'black',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      fontFamily: 'sans-serif',
      fontSize: `${12 - textSizeAdjustment}mm`,
      opacity: 1,
    };
  }

  return {
    color: styling?.color,
    fill: styling?.color,
    fontWeight: styling?.textStyle?.includes('bold') ? '600' : 'normal',
    fontStyle: styling?.textStyle?.includes('italic') ? 'italic' : 'normal',
    textDecoration: styling?.textStyle?.includes('underlined') ? 'underline' : 'none',
    fontFamily: styling?.font || 'sans-serif',
    fontSize: `${(styling?.fontHeight || 12) - textSizeAdjustment}mm`,
    opacity: styling?.opacity / 100 ?? 1,
    textAlign: styling?.textAlign ?? 'left',
    display: styling?.show === false ? 'none' : 'block',
  };
};

export const splitedCharts = (sectionsCharts: any) => {
  const defaults = sectionsCharts || [];
  const newArray: any = Array.from(defaults);
  const gridSize = 4;
  let targetLength = 0;
  const finalArray: any = [];
  let blockArray: any = [];
  for (let index = 0; index < newArray.length; index++) {
    type widthTypes = 'TWOTHIRD' | 'FULL' | 'HALF' | 'ONEFOURTH';
    const width: widthTypes = newArray[index]?.width;
    const widthNumber = defaultWidthTypes[width]?.spaces;
    targetLength = targetLength + widthNumber;
    const newItem = {
      data: newArray[index],
      dataIndex: index,
    };
    if (targetLength > gridSize) {
      targetLength = widthNumber;
      finalArray.push(blockArray);
      blockArray = [];
      blockArray.push(newItem);
    } else {
      blockArray.push(newItem);
    }
  }
  finalArray.push(blockArray);
  // finalArray.push([]);

  return finalArray;
};

export const extractIndexFromDraggableID = (str: any, targetString: string) => {
  const startIndex = str.indexOf(`<${targetString}>`);
  const endIndex = str.indexOf(`</${targetString}>`);
  if (startIndex !== -1 && endIndex !== -1) {
    const value = str.substring(startIndex + `<${targetString}>`.length, endIndex);
    if (/^\d+$/.test(value)) {
      return parseInt(value);
    } else {
      return null; // Return null if value is not a valid integer
    }
  } else {
    return null; // Return null if opening or closing tag is not found
  }
};

let timeoutId: ReturnType<typeof setTimeout>;
export const debounceFunction = (fn: Function, ms = 300) => {
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

let isQueued: boolean = false;
export function throttle(func: Function, waitTime: number) {
  return () => {
    if (!isQueued) {
      isQueued = true;
      setTimeout(() => {
        isQueued = false;
        func();
      }, waitTime);
    }
  };
}

export const updateChartSchemaInServer = async ({
  chartSchemaMemo,
  endPoint,
  isInsights = false,
  filters,
}: any) => {
  try {
    let finalSchema: { [key: string]: any } = {
      charts: {
        [chartSchemaMemo.chartID]: chartSchemaMemo,
      },
      projectID: chartSchemaMemo.projectID,
      sheetID: chartSchemaMemo.sheetID,
      appID: chartSchemaMemo.appID,
      timeFrame: Object.keys(chartSchemaMemo?.timeFrame ?? {})[0] && chartSchemaMemo?.timeFrame,
      filters,
    };
    if (isInsights) {
      finalSchema = {
        chartSchema: {
          [chartSchemaMemo.chartID]: chartSchemaMemo,
        },
        chartID: chartSchemaMemo.chartID,
        sheetID: chartSchemaMemo.sheetID,
        appID: chartSchemaMemo.appID,
        timeFrame: Object.keys(chartSchemaMemo?.timeFrame ?? {})[0] && chartSchemaMemo?.timeFrame,
        filters,
      };
    }
    const res = await supistaApiPost(endPoint, finalSchema, chartSchemaMemo.appID);

    if (!res.__d3__error) {
      const chartID = chartSchemaMemo.chartID;
      if (isInsights) {
        return {
          skip: false,
          chartSchema: chartSchemaMemo,
          plotData: res?.response,
        };
      }
      return {
        skip: false,
        chartSchema: chartSchemaMemo,
        plotData: res?.response?.charts[chartID],
      };
    }
  } catch (error) {
    console.log('error while calling server data: ', error);
  }

  return { skip: true };
};
const reportSchemaValidationMsg = {
  name: 'Please fill Report Name.',
  email: 'Please enter atleast one email.',
  scheduledTime: 'Please select any Report Schedule Frequency',
  reports: 'Please add charts in reports.',
  pages: 'Please add reports.',
};
export const reportSchemaValidation = (settingsSchema: any) => {
  if (!settingsSchema?.name) {
    return { skip: true, msg: reportSchemaValidationMsg.name };
  }
  if (settingsSchema?.scheduledTime?.length < 1) {
    return { skip: true, msg: reportSchemaValidationMsg.scheduledTime };
  }
  if (settingsSchema?.recipients?.mail_list?.length < 1) {
    return { skip: true, msg: reportSchemaValidationMsg.email };
  }
  if (settingsSchema?.pages?.length < 1) {
    return { skip: true, msg: reportSchemaValidationMsg.pages };
  }
  // if (settingsSchema.reports.length < 1) {
  //   return { skip: true, msg: reportSchemaValidationMsg.reports };
  // }
  return { skip: false };
};

export const removeExtraDataFromSchema = (settingsSchema: any) => {
  const finalReport = settingsSchema.reports?.map((report: any) => {
    console.log(chartTypeReport);

    const isChartTypeReport = chartTypeReport.some((elem) => report.type === elem);
    if (isChartTypeReport) {
      const removePlotData = { ...report };
      delete removePlotData.plotData;
      return removePlotData;
    }

    return report;
  });
  return { ...settingsSchema, reports: finalReport };
};
