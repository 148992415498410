import React, { useMemo, useState } from 'react';

//assets
import './editColors.scss';

import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import { resetLegendColor } from '../../../utils/chartHelperFunctions';
import useUpdateEffect from '../../../hooks/useUpdateEffect';

import DropDown from '../../../features/DropDown/DropDown';
import FontSelector from '../FontSelector';
import CustomColor from './CustomColor';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import OpacitySlider from '../OpacitySlider';
import { allDropableID, defaultOpacity } from '../../../data/defaultChartSchema';
import {
  ColorfulColorChart,
  allChartKeys,
  allChartTypesObj,
} from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const EditColors = (props, { show = true }) => {
  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const { stateProps } = props;
  const { chartToolTip, setChartToolTip, chartType } = stateProps;
  const [automaticOpacity, setAutomaticOpacity] = useState({
    opacity: chartToolTip?.automaticOpacity ?? defaultOpacity,
  });
  const targetKey = useMemo(
    () =>
      chartType?.toolTip?.isMultipleValue
        ? { isTrue: true, key: 'valueStyle' }
        : { isTrue: false, key: 'style' },
    [chartType]
  );
  const resetColors = () => {
    setAutomaticOpacity({ opacity: defaultOpacity });
    const isColorfulColor = ColorfulColorChart.some((elem) => {
      return elem.chartKey === chartType.chartKey;
    });
    const newLegendValue = resetLegendColor({
      chartToolTip,
      allRest: true,
      defaultOpacity,
      isColorfulColor,
    });
    setChartToolTip(newLegendValue);
  };
  useUpdateEffect(() => {
    if (isDivOpen) {
      setisContent(isDivOpen);
    } else {
      setTimeout(() => {
        setisContent(isDivOpen);
        resetColors();
      }, 300);
    }
  }, [isDivOpen]);
  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      {/* {
        chartType
      } */}
      {chartType.chartKey === allChartKeys.LINE ||
      chartType.chartKey === allChartKeys.LINEHORIZONTAL ||
      chartType.chartKey === allChartKeys.MULTILINEHORIZONTAL ||
      chartType.chartKey === allChartKeys.MULTILINE ? (
        <div className='MainTitle inFlex'>{mls('Chart Line Colors')}</div>
      ) : (
        <div className='MainTitle inFlex'>{mls('Chart Display')}</div>
      )}
      <div className='grayContainers'>
        {isContent && (
          <>
            <LegendValueDiv
              chartType={chartType}
              chartToolTip={chartToolTip}
              setChartToolTip={setChartToolTip}
              stateProps={stateProps}
              targetKey={targetKey}
              automaticOpacity={automaticOpacity}
              setAutomaticOpacity={setAutomaticOpacity}
            />
          </>
        )}

        <div className='resetDefault' onClick={() => resetColors()}>
          {mls('Reset to defaults')}
        </div>
      </div>
    </div>
  );
};

export default EditColors;

const LegendValueDiv = ({
  stateProps,
  show = false,
  targetKey,
  automaticOpacity,
  setAutomaticOpacity,
}) => {
  return (
    <>
      <ClosableWhiteDiv
        title={mls(`Edit Colors`)}
        show={show}
        autoCloseOff={true}
        className={'legend'}
      >
        <ChartDisplayOptions
          targetKey={targetKey}
          stateProps={stateProps}
          automaticOpacity={automaticOpacity}
          setAutomaticOpacity={setAutomaticOpacity}
        />
      </ClosableWhiteDiv>
    </>
  );
};

const ChartDisplayOptions = ({ targetKey, stateProps, automaticOpacity, setAutomaticOpacity }) => {
  const { chartType, chartToolTip, setChartToolTip } = stateProps;
  const automaticCustomArray = ['automatic', 'custom'];
  const handleAutomaticCustom = (elem) => {
    if (elem === automaticCustomArray[0]) {
      const isColorfulColor = ColorfulColorChart.some((elem) => {
        return elem.chartKey === chartType.chartKey;
      });
      const newChartToolTipValue = resetLegendColor({
        chartToolTip,
        defaultOpacity,
        isColorfulColor,
      });
      setAutomaticOpacity({ opacity: defaultOpacity });
      setChartToolTip({ ...newChartToolTipValue, automaticOpacity: defaultOpacity, type: elem });
    } else {
      setChartToolTip({ ...chartToolTip, type: elem });
    }
  };
  const handleOpacity = (action) => {
    const newColorbyValue = chartToolTip[targetKey.key].map((elem) => {
      return { ...elem, ...action };
    });
    setChartToolTip({
      ...chartToolTip,
      automaticOpacity: action?.opacity ?? defaultOpacity,
      [targetKey.key]: newColorbyValue,
    });
    setAutomaticOpacity(action);
  };
  const chartTooltipData = allChartTypesObj[chartType.chartKey]?.toolTip;

  const newValueStyle = filterStyleByAxis({
    array: chartTooltipData?.isMultipleValue ? chartToolTip.valueStyle : chartToolTip.style,
    axis: chartTooltipData?.target,
  });
  // IF ChartType is PIE || DONUT || FUNNEL || RADAR

  // if (
  //   chartType.chartKey === allChartKeys.PIE ||
  //   chartType.chartKey === allChartKeys.DONUT ||
  //   chartType.chartKey === allChartKeys.FUNNEL ||
  //   chartType.chartKey === allChartKeys.RADAR
  // ) {
  if (newValueStyle.length < 1) {
    return (
      <>
        <div className='titleDiv'>{mls('Colors')}</div>

        <DropDown
          array={[automaticCustomArray[0]]}
          dropDownValue={automaticCustomArray[0]}
          setdropDownValue={() => {}}
        />
      </>
    );
  }
  // }
  if (chartToolTip.style.length >= 1) {
    return (
      <>
        <div className='titleDiv'>{mls('Colors')}</div>
        <DropDown
          array={automaticCustomArray}
          dropDownValue={chartToolTip.type}
          setdropDownValue={handleAutomaticCustom}
        />
        {chartToolTip.type === automaticCustomArray[0] && (
          <div className='opacityOuterDiv'>
            <OpacitySlider color={automaticOpacity} setColor={handleOpacity} />
          </div>
        )}
        {chartToolTip.type === automaticCustomArray[1] && (
          <>
            <CustomColor
              chartToolTip={chartToolTip}
              setChartToolTip={setChartToolTip}
              chartType={chartType}
              className={chartToolTip.isMultipleValue && 'legendPopupColumn'}
              stateProps={stateProps}
              targetKey={targetKey}
            />
            <br />
          </>
        )}
      </>
    );
  }
  return <div className='titleDiv'>{mls('Drop Any Column')}</div>;
};

const filterStyleByAxis = ({ array = [], axis = allDropableID.xAxisId }) => {
  const filteredArray = array.filter((elem) => {
    return elem.axis === axis;
  });
  return filteredArray;
};
