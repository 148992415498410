import { Axis } from '@visx/xychart';
import { getStyling } from '../../helpers/colorHelper';
import AxisLabelComponent from './AxisLabelComponent';
import styles from '../../constants/styles.module.css';
import { memo } from 'react';
import {
  dateTimeFormatter,
  numberFormatter,
} from 'lib/reusable-components/reusableFunction/formatter';

function XYChartAxis({
  xStyling,
  yStyling,
  xAxisLabel,
  yAxisLabel,
  dataNum,
  xAxisProps,
  xAxisTickWidth = 0,
  yAxisFormatter,
  dateTimeFormat,
  isDateTime,
  toggle,
}) {
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        valueStyle={yStyling?.valueStyle}
        x={x}
        y={y}
        formattedValue={formattedValue}
      />
    );
  };
  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = (props) => {
    const { x, y, formattedValue, xAxisTickWidth } = props;
    return (
      <AxisLabelComponent
        valueStyle={xStyling?.valueStyle}
        x={x}
        y={y}
        formattedValue={formattedValue}
        xAxisTickWidth={xAxisTickWidth}
        bottomAxis
      />
    );
  };
  const shortenedData = (x, maxLength = 12) => {
    // const splitedFirstWord = `${x}`.split(' ')[0];
    // if (splitedFirstWord?.length > 8) {
    //   return x.substring(0, 8) + '...';
    // }
    // if (x?.length > maxLength) {
    //   return x.substring(0, maxLength) + '...';
    // }
    return x;
  };
  return (
    <>
      {xStyling?.show ? (
        <Axis
          labelClassName={`${styles.chartLabel} `}
          labelProps={{
            ...getStyling(xStyling?.style),
            textAnchor: 'middle',
          }}
          tickLabelProps={() => ({
            ...getStyling(xStyling?.valueStyle),
            textAnchor: 'middle',
            xAxisTickWidth,
          })}
          // tickFormat={shortenedData}
          hideTicks
          tickLength={10}
          tickLineProps={{
            color: 'red',
          }}
          label={shortenedData(xAxisLabel, 30)}
          orientation='bottom'
          tickComponent={AxisBottomLabelWrapper}
          tickClassName={styles.bottomAxisTicks}
          tickFormat={(e) => {
            if (isDateTime) {
              return dateTimeFormatter({ type: { ...dateTimeFormat, format: toggle }, value: e });
            }
            return e;
          }}
          labelOffset={35}
          numTicks={dataNum}
          stroke={xStyling?.style.color}
          {...xAxisProps}
        />
      ) : null}
      {yStyling?.show ? (
        <Axis
          tickClassName={`${styles.leftAxisTicks} `}
          labelProps={{
            ...getStyling(yStyling?.style),
            textAnchor: 'middle',
          }}
          tickLabelProps={() => ({
            ...getStyling(yStyling?.valueStyle),
            textAnchor: 'middle',
          })}
          labelClassName={styles.chartLabel}
          label={shortenedData(yAxisLabel, 30)}
          labels={false}
          orientation='left'
          labelOffset={45}
          tickFormat={(e) =>
            numberFormatter({ type: { ...yAxisFormatter, compact: true }, value: e })
          }
          tickComponent={AxisleftLabelWrapper}
          stroke={yStyling?.style.color}
        />
      ) : null}
      {/* {yStyling?.show ? (
        <Axis
          tickClassName={`${styles.leftAxisTicks} `}
          labelProps={{
            ...getStyling(yStyling?.style),
            textAnchor: 'middle',
          }}
          tickLabelProps={() => ({
            ...getStyling(yStyling?.valueStyle),
            textAnchor: 'middle',
          })}
          labelClassName={styles.chartLabel}
          label={yAxisLabel}
          labels={false}
          orientation='right'
          labelOffset={45}
          tickFormat={((e) => numberFormatter({ type: { ...yAxisFormatter, compact: true }, value: e }))}
          tickComponent={AxisleftLabelWrapper}
          stroke={yStyling?.style.color}
        />
      ) : null} */}
    </>
  );
}

export default memo(XYChartAxis);
