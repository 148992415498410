import React from 'react';
import { defaultChartSetting } from '../../../data/defaultChartSchema';
import EditColors from '../../../components/StyleComponents/ChartDisplay/EditColors';
import PivotTableXAxis from '../../../components/SpacialCasesComponents/PivotTable/PivotTableXAxis';
import PivotTableYAxis from '../../../components/SpacialCasesComponents/PivotTable/PivotTableYAxis';
import PivotTableTitleStyle from '../../../components/SpacialCasesComponents/PivotTable/PivotTableTitleStyle';

interface PivotTableStyleCaseProps {
  stateProps: any;
}
const PivotTableStyleCase = ({ stateProps }: PivotTableStyleCaseProps) => {
  return (
    <>
      <PivotTableXAxis stateProps={stateProps} />
      <PivotTableYAxis stateProps={stateProps} />
      <PivotTableTitleStyle stateProps={stateProps} />
      <EditColors stateProps={stateProps} />
    </>
  );
};

export default PivotTableStyleCase;
