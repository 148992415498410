import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import { toAbsoluteUrl } from '_metronic/helpers';
import './dataSourceDisplayTable.scss';
import { CircularProgress } from '@mui/material';
import { dataTableColumnTypes } from '../../reusableData/dataTableDependencyConstants';
import ObjectDropDown from '../../Components/DropDowns/ObjectDropDown';
import CheckBox from '../../Components/CheckBox/CheckBox';
import './scroll.scss';
const svgStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50vw',
};

interface dataSourceDisplayTableProps {
  tableData: any[];
  tableLoading?: boolean;
  columnDataStartFrom: number;
  columnSchema: any[];
  updateSchema: Function;
  handleReset: Function;
  tableTitle?: string;
}
const DataSourceDisplayTable = (dataSourceDisplayTableProps: dataSourceDisplayTableProps) => {
  const {
    tableData,
    tableLoading = false,
    columnDataStartFrom = 0,
    columnSchema,
    updateSchema,
    tableTitle,
  } = dataSourceDisplayTableProps;
  if (tableLoading) {
    return (
      <div className='DataSourceDisplayTable'>
        <div className='mb-20'>
          <div style={svgStyle}>
            <div
              style={{
                minWidth: '250px',
                maxHeight: '45vh',
                height: '30vh',
                // width: '100px',
                // height: '100px',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          </div>
          <br />
          <h6 style={svgStyle} className='mb-3 text-center'>
            {mls('Table Loading...')}
          </h6>
        </div>
      </div>
    );
  }
  return (
    <div className='DataSourceDisplayTable'>
      {tableData.length === 0 ? (
        <div>
          <div className='mb-20'>
            <div style={svgStyle}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 100 90'
                width='40%'
                height='40%'
                style={{ minWidth: '250px', maxHeight: '45vh' }}
              >
                <image
                  href={toAbsoluteUrl('/media/errorScreens/NoChart.png')} // Replace with the actual path to your image
                  width='100'
                  height='100'
                />
              </svg>
            </div>
            <>
              <br />
              <h5 style={svgStyle} className='mb-3 text-center'>
                {mls('No data available in the Table to show. Kindly check the connection.')}
              </h5>
            </>
          </div>
        </div>
      ) : (
        <div className='scrollingPart'>
          <div className='tableDivContainer'>
            <>
              {/* <div className='resetTableContainer'>
                <div className='resetIconText'>Reset</div>
                <div className='resetIconContainer'>
                    <RiRestartLine />
                  </div>
              </div> */}
              <div className='tableOuterDiv'>
                {tableTitle ? <div className='tableName'>{tableTitle}</div> : null}
                <div className='tableInnerDiv'>
                  {React.Children.toArray(
                    tableData.map((columnElem: any, i: number) => {
                      const currentColumn = columnDataStartFrom + i;
                      const currentSchema = columnSchema[currentColumn];

                      const handleUpdateColumn = ({ key, value }: { key: string; value: any }) => {
                        updateSchema({
                          columnIndex: i,
                          columnSchema: { ...currentSchema, [key]: value },
                        });
                      };
                      const handleDataType = (action: string) => {
                        handleUpdateColumn({
                          key: 'dataType',
                          value: action,
                        });
                      };

                      return (
                        <div className='columnDiv'>
                          <div className='columnName columnNameDiv'>
                            <div className='columnOptionDiv'>
                              <div className='columnSelected'>
                                <CheckBox
                                  checked={!(currentSchema?.disabledColumn ?? false)}
                                  onClick={(e: any) => {
                                    handleUpdateColumn({
                                      key: 'disabledColumn',
                                      value: !e,
                                    });
                                  }}
                                />
                              </div>
                              <div className='columnNameText'>{columnElem[0]}</div>
                              <div className='columnTypeDiv'>
                                <ObjectDropDown
                                  object={dataTableColumnTypes}
                                  dropDownValue={currentSchema?.dataType ?? ''}
                                  setdropDownValue={handleDataType}
                                  inputPlaceHolder={'Select Type'}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='columnInnerDiv'>
                            {React.Children.toArray(
                              columnElem?.map((elem: any, index: number) => {
                                if (index === 0) {
                                  return <></>;
                                }
                                if (index >= 10) {
                                  return <></>;
                                }

                                return (
                                  <div className='columnValues' title={elem}>
                                    {elem}
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataSourceDisplayTable;
interface configDisplayTableFunctionProps {
  // tableHead: { [key: string]: any }[];
  tableHead: { [key: string]: any }[];
  columnSchema: { [key: string]: any }[];
  rowDataStartFrom: number;
  columnDataStartFrom: number;
  containHeader: boolean;
  tableId: string;
}
export const configDisplayTableFunction = ({
  tableHead = [],
  columnSchema = [],
  rowDataStartFrom = 0,
  columnDataStartFrom = 0,
  containHeader = true,
}: configDisplayTableFunctionProps) => {
  let transformedArray: any[] = [];
  if (!tableHead[0]) {
    return transformedArray;
  }

  let tableNameList: { [key: string]: any } = {};
  // Object.keys(tableHead[0] ?? {}).forEach((tableName: string) => {
  //   tableNameList = { ...tableNameList, [tableName]: tableName };
  // });
  (columnSchema ?? []).forEach((coulumSchemaElem) => {
    tableNameList = {
      ...tableNameList,
      [coulumSchemaElem.columnName]: coulumSchemaElem.columnName,
    };
  });

  let newTableHead: any = [tableNameList, ...tableHead];
  Object.keys(tableNameList).forEach((_: any, colIndex: number) => {
    if (colIndex >= columnDataStartFrom) {
      let rowData: any[] = [];
      if (!containHeader) {
        rowData = [...rowData, `Column ${colIndex + 1}`];
      }

      newTableHead.forEach((row: any[], rowIndex: number) => {
        if (rowIndex >= rowDataStartFrom) {
          rowData = [...rowData, Object.values(row)[colIndex]];
        }
      });

      transformedArray = [...transformedArray, rowData];
    }
  });
  return transformedArray;
};
