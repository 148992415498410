import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import React, { useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import EditSelectedOrganistainElem from './EditSelectedOrganistainElem';
import { v4 as uuidv4 } from 'uuid';
import { organizationStructureOptions, organiztionStuctureType } from '../../data/organizationData';
import WarningPopup from 'lib/reusable-components/Components/WarningPopup/WarningPopup';
import { mls } from 'lib/multilanguagesupport';
import { toast } from 'react-toastify';
import { kpiSettingsApi } from '../../utils/organizationStructureFunctions';
import { useUpdateKpiSchema } from 'lib/reusable-components/reusableFunction/KpiAllSchema';
const optionObject = {
  edit: {
    key: 'edit',
    name: 'Edit',
  },
  remove: {
    key: 'remove',
    name: 'Remove',
  },
};

interface departmentsContainerProps {
  selectedOrganisationField: { [key: string]: any }[];
  selectedOrganisationText: string;
  organizationStructureProps: any;
  selectedOrganisationOptionData: { [key: string]: any }[];
  setSelectedOrganisationOptionData: Function;
  organizationOptionObject: { [key: string]: any };
}
const DepartmentsContainer = ({
  selectedOrganisationField,
  selectedOrganisationText,
  organizationStructureProps,
  selectedOrganisationOptionData,
  setSelectedOrganisationOptionData,
  organizationOptionObject,
}: departmentsContainerProps) => {
  const updateKpiSchema = useUpdateKpiSchema();
  const [isAddPopup, setIsAddPopup] = useState(false);
  const [removePopupData, setRemovePopupData] = useState({ isPopup: false, index: 0 });
  const [loading, setLoading] = useState(false);
  const { selectedorganizationStructure, appID } = organizationStructureProps;
  const [selectedEditElem, setSelectedEditElem] = useState({ isNew: true, data: {}, index: 0 });
  const organizationData =
    organizationStructureOptions[
      selectedorganizationStructure as keyof typeof organizationStructureOptions
    ];

  const handleOpenEdit = ({
    isNew,
    data = {},
    index = 0,
  }: {
    isNew: boolean;
    data?: { [key: string]: any };
    index?: number;
  }) => {
    if (isNew) {
      setSelectedEditElem({ isNew, data: { [organizationData.id]: uuidv4() }, index: index });
    } else {
      setSelectedEditElem({ isNew, data: data, index: index });
    }
    setIsAddPopup(true);
  };
  const handleOptionDropdown = ({
    action,
    data,
    index,
  }: {
    action: any;
    data: { [key: string]: any };
    index: number;
  }) => {
    if (optionObject.remove.key === action) {
      setRemovePopupData({ isPopup: true, index });
    }
    if (optionObject.edit.key === action) {
      handleOpenEdit({ isNew: false, data, index });
    }
  };
  const handleEditSelectedOrganistainElem = (action: { key: string; value: any }) => {
    setSelectedEditElem({
      ...selectedEditElem,
      data: { ...selectedEditElem.data, [action.key]: action.value },
    });
  };
  const handleSaveSelectedOrganistainElem = async (action: {
    isNew: boolean;
    data: any;
    index: number;
  }) => {
    if (organizationData.key === organizationStructureOptions.employees.key) {
      const copyOrganisationOptionArray = Array.from(selectedOrganisationOptionData);
      copyOrganisationOptionArray.splice(action.index, 1);
      const isAlreadyExist = copyOrganisationOptionArray.some(
        (selectedOrganisationOptionDataElem) => {
          return selectedOrganisationOptionDataElem?.email === action?.data?.email;
        }
      );
      if (isAlreadyExist) {
        toast.error(`${mls('Email already exists')}.`);
        return;
      }
    }

    if (organizationData.key === organizationStructureOptions.departments.key) {
      const copyOrganisationOptionArray = Array.from(selectedOrganisationOptionData);
      copyOrganisationOptionArray.splice(action.index, 1);
      const isAlreadyExist = copyOrganisationOptionArray.some(
        (selectedOrganisationOptionDataElem) => {
          return selectedOrganisationOptionDataElem?.name === action?.data?.name;
        }
      );
      if (isAlreadyExist) {
        toast.error(`${mls('Department Name already exists')}.`);
        return;
      }
    }
    if (organizationData.key === organizationStructureOptions.circles.key) {
      const copyOrganisationOptionArray = Array.from(selectedOrganisationOptionData);
      copyOrganisationOptionArray.splice(action.index, 1);
      const isAlreadyExist = copyOrganisationOptionArray.some(
        (selectedOrganisationOptionDataElem) => {
          return selectedOrganisationOptionDataElem?.name === action?.data?.name;
        }
      );
      if (isAlreadyExist) {
        toast.error(`${mls('Circle Name already exists')}.`);
        return;
      }
    }

    setLoading(true);
    if (action.isNew) {
      let defaultData = {};
      (organizationData?.fields ?? []).forEach((organizationFieldElem) => {
        defaultData = {
          ...defaultData,
          [organizationFieldElem.key]: organizationFieldElem.defaultValue,
        };
      });
      const finalData = {
        ...defaultData,
        ...action.data,
      };
      const data = await kpiSettingsApi({
        appID,
        endPoint: `${organizationData.endPoint}/create`,
        data: finalData,
      });
      if (data.skip) {
        toast.error(mls(data?.msg ?? 'Some error encountered! Not able to create.'));
        setLoading(false);
        setIsAddPopup(false);
        return;
      }
      setSelectedOrganisationOptionData([finalData, ...selectedOrganisationOptionData]);
      updateKpiSchema({ appID });
    } else {
      const payLoadData = {
        query: { [organizationData.id]: action?.data?.[organizationData.id] ?? '' },
        new_values: action?.data ?? {},
      };
      const data = await kpiSettingsApi({
        appID,
        endPoint: `${organizationData.endPoint}/update`,
        data: payLoadData,
      });
      if (data.skip) {
        toast.error(mls(data?.msg ?? 'Some error encountered! Not able to update.'));
        setLoading(false);
        setIsAddPopup(false);
        return;
      }
      const copyArray = Array.from(selectedOrganisationOptionData);
      copyArray.splice(action.index, 1);
      copyArray.splice(action.index, 0, action.data);
      setSelectedOrganisationOptionData(copyArray);
      updateKpiSchema({ appID });
    }
    setLoading(false);
    setIsAddPopup(false);
  };
  const handleRemove = async (action: any) => {
    if (action) {
      setLoading(true);
      const payLoadData = {
        query: {
          [organizationData.id]:
            selectedOrganisationOptionData?.[removePopupData?.index]?.[organizationData.id] ?? '',
        },
      };
      const data = await kpiSettingsApi({
        appID,
        endPoint: `${organizationData.endPoint}/delete`,
        data: payLoadData,
      });
      if (data.skip) {
        toast.error(data?.msg ?? 'Some error encountered! Not able to Delete.');
        setLoading(false);
        return;
      }
      setLoading(false);
      const copyArray = Array.from(selectedOrganisationOptionData);
      copyArray.splice(removePopupData.index, 1);
      setSelectedOrganisationOptionData(copyArray);
      updateKpiSchema({ appID });
    }
    setRemovePopupData({ isPopup: false, index: 0 });
  };

  return (
    <div className='organisationOptionsContainer departmentsContainer'>
      <div className='headerSelectedOrganisationOptionDiv'>
        <div className='headerSelectedOrganisationTitleContainer'>
          <div className='headerSelectedOrganisationTitle'>
            {mls('Manage')} {mls(organizationData.lable)}
          </div>
          <div className='headerSelectedOrganisationDescription'>
            {mls('Add and update')} {mls(organizationData.key)}
          </div>
        </div>
        <button
          className='addSelectedOrganisationOptionDiv primaryButton secondaryButtonColor'
          onClick={() => {
            handleOpenEdit({ isNew: true });
          }}
        >
          + {mls('Add')} {selectedOrganisationText}
        </button>
      </div>
      <div>
        <hr className='organisationOptionsHorizontalLine' />
        <EditSelectedOrganistainElem
          selectedOrganisationText={selectedOrganisationText}
          isNew={selectedEditElem.isNew}
          data={selectedEditElem.data}
          index={selectedEditElem.index}
          setData={handleEditSelectedOrganistainElem}
          handleSave={handleSaveSelectedOrganistainElem}
          isPopup={isAddPopup}
          loading={loading}
          selectedOrganisationField={selectedOrganisationField}
          setPopup={setIsAddPopup}
          organizationStructureProps={organizationStructureProps}
        />
        {removePopupData.isPopup ? (
          <WarningPopup
            initialValue={removePopupData.isPopup}
            setConfirmation={handleRemove}
            titleText={`${mls('Are you sure you want to remove this')} ${mls(
              selectedOrganisationText
            )}?`}
            discText={mls(selectedOrganisationOptionData?.[removePopupData.index]?.name)}
            loading={loading}
          />
        ) : null}
      </div>
      <div
        className='selectedOrganistionOptionTableConitaner'
        style={{
          gridTemplateColumns: `repeat(${selectedOrganisationField.length},  minmax(min-content, auto)) 3rem`,
        }}
      >
        {React.Children.toArray(
          selectedOrganisationField.map((selectedOrganisationFieldElem: { [key: string]: any }) => {
            return (
              <div className='selectedOrganistionOptionCell selectedOrganistionOptionCellTitle'>
                {selectedOrganisationFieldElem?.label}
              </div>
            );
          })
        )}
        <div className='selectedOrganistionOptionCell optionCell selectedOrganistionOptionCellTitle'></div>
        {React.Children.toArray(
          selectedOrganisationOptionData.map(
            (selectedOrganisationOptionElem: { [key: string]: any }, index: number) => {
              return (
                <>
                  {React.Children.toArray(
                    selectedOrganisationField.map(
                      (selectedOrganisationFieldElem: { [key: string]: any }) => {
                        if (
                          selectedOrganisationFieldElem.type === organiztionStuctureType.inputString
                        ) {
                          return (
                            <div className='selectedOrganistionOptionCell '>
                              {selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue}
                            </div>
                          );
                        }
                        if (selectedOrganisationFieldElem.type === organiztionStuctureType.email) {
                          return (
                            <div className='selectedOrganistionOptionCell '>
                              {selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue}
                            </div>
                          );
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.circleMultiSelect
                        ) {
                          return (
                            <div className='selectedOrganistionOptionCell mutiSelectCircleContainer'>
                              {(
                                selectedOrganisationOptionElem[
                                  selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                                ] ?? selectedOrganisationFieldElem?.defaultValue
                              ).map((key: string) => {
                                const name = getDataUsingKey({
                                  key,
                                  options: organizationStructureProps?.circlesData,
                                  mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                                  nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                                });
                                if (name) {
                                  return (
                                    <>
                                      <div className='mutiSelectCircle'>{name}</div>
                                    </>
                                  );
                                }
                                return <></>;
                              })}
                            </div>
                          );
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.departmentMultiSelect
                        ) {
                          return (
                            <div className='selectedOrganistionOptionCell mutiSelectCircleContainer'>
                              {(
                                selectedOrganisationOptionElem[
                                  selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                                ] ?? selectedOrganisationFieldElem?.defaultValue
                              ).map((key: string) => {
                                const name = getDataUsingKey({
                                  key,
                                  options: organizationStructureProps?.departmentsData,
                                  mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                                  nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                                });
                                if (name) {
                                  return (
                                    <>
                                      <div className='mutiSelectCircle'>{name}</div>
                                    </>
                                  );
                                }
                                return <></>;
                              })}
                            </div>
                          );
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.departmentDropdown
                        ) {
                          const name = getDataUsingKey({
                            key:
                              selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue,
                            options: organizationStructureProps?.departmentsData,
                            mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                            nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                          });
                          return <div className='selectedOrganistionOptionCell'>{name}</div>;
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.circleDropdown
                        ) {
                          const name = getDataUsingKey({
                            key:
                              selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue,
                            options: organizationStructureProps?.circlesData,
                            mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                            nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                          });
                          return <div className='selectedOrganistionOptionCell'>{name}</div>;
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.employeesDropdown
                        ) {
                          const name = getDataUsingKey({
                            key:
                              selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue,
                            options: organizationStructureProps?.employeesData,
                            mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                            nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                          });
                          return <div className='selectedOrganistionOptionCell'>{name}</div>;
                        }
                        if (
                          selectedOrganisationFieldElem.type ===
                          organiztionStuctureType.managerDropdown
                        ) {
                          const name = getDataUsingKey({
                            key:
                              selectedOrganisationOptionElem[
                                selectedOrganisationFieldElem?.key as keyof typeof selectedOrganisationOptionElem
                              ] ?? selectedOrganisationFieldElem?.defaultValue,
                            options: organizationStructureProps?.supistaUsers,
                            mainKey: selectedOrganisationFieldElem.mainKey ?? 'id',
                            nameKey: selectedOrganisationFieldElem.nameKey ?? 'name',
                          });
                          return <div className='selectedOrganistionOptionCell'>{name}</div>;
                        }

                        return <div className='selectedOrganistionOptionCell '>Unknown Type</div>;
                      }
                    )
                  )}
                  <div className='selectedOrganistionOptionCell optionCell'>
                    <ObjectDropDown
                      object={organizationOptionObject}
                      dropDownValue={''}
                      setdropDownValue={(action: any) =>
                        handleOptionDropdown({
                          action,
                          data: selectedOrganisationOptionElem,
                          index,
                        })
                      }
                      ObjectInObject
                      isChildernInsert
                      className='titleGridCellOptionDiv'
                    >
                      <HiOutlineDotsVertical />
                    </ObjectDropDown>
                  </div>
                </>
              );
            }
          )
        )}
      </div>
    </div>
  );
};

export default DepartmentsContainer;

const getDataUsingKey = ({
  key,
  options,
  mainKey = 'id',
  nameKey = 'name',
}: {
  key: string;
  options: any;
  mainKey?: string;
  nameKey?: string;
}) => {
  const finalElem = options.find(
    (optionsElem: { [key: string]: any }) => optionsElem?.[mainKey] === key
  );
  return finalElem?.[nameKey];
};
