import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function useD3Brush({
  brushed,
  height,
  data,
  width,
  brushScale,
  dataNum,
  isVertical,
  isBrush,
  brushPosition = null,
}) {
  const miniRef = useRef(null);
  useEffect(() => {
    if (!isBrush) return;

    let svg = d3.select(miniRef.current);
    const brush = (isVertical ? d3.brushY() : d3.brushX())
      .extent([
        [0, 0],
        [width, height],
      ])
      .on('brush', brushed)
      .on('end', brushed);

    const xdefaultSelection = [
      brushScale(brushPosition?.[0] || data[data.length - dataNum]),
      brushScale(brushPosition?.[1] || data[data.length - 1]) +
        (typeof brushScale.bandwidth === 'function' ? brushScale?.bandwidth() : 0),
    ];

    const ydefaultSelection = [
      brushScale(brushPosition?.[0] || data[data.length - 1]),
      brushScale(brushPosition?.[1] || data[data.length - dataNum]) +
        (typeof brushScale.bandwidth === 'function' ? brushScale?.bandwidth() : 0),
    ];

    const gb = svg
      .append('g')
      .call(brush)
      .call(brush.move, isVertical ? ydefaultSelection : xdefaultSelection);
    gb.selectAll('.selection')
      .attr('fill', 'steelblue')
      .attr('stroke', '#009ef7')
      .attr('fill-opacity', '20%')
      .attr('stroke-width', 1);

    return () => {
      gb.remove();
    };
  }, [width, brushScale, brushed, height, dataNum, isVertical, isBrush, data, brushPosition]);

  return { ref: miniRef };
}

export default useD3Brush;
