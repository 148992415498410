import { mls } from 'lib/multilanguagesupport';
import { FaTimes } from 'react-icons/fa';
import { updateChartSchemaInServer } from '../utils/connectionFunction';
import { toast } from 'react-toastify';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import { useUpdateKpiSchema } from 'lib/reusable-components/reusableFunction/KpiAllSchema';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

interface mainProps {
  appID: string;
  // settingsSchema: any;
  handleKPISettingsPopUp: Function;
  handleChartUpdate: Function;
  isNew: boolean;
  stateProps: any;
}
const Header = ({ handleKPISettingsPopUp, handleChartUpdate, isNew, stateProps }: mainProps) => {
  // const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);
  const { chartSchemaMemo, chartType, selectedDepartment, kpiTargetPoints } = stateProps;
  const updateKpiSchema = useUpdateKpiSchema();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const saveFunction = async () => {
    setIsSaveLoading(true);
    const endPoint = isNew
      ? `${chartSchemaMemo.appID}/kpiSettings/KpiCharts/create`
      : `${chartSchemaMemo.appID}/kpiSettings/KpiCharts/update`;
    const schemaData = await updateChartSchemaInServer({
      chartSchemaMemo,
      chartType,
      endPoint: endPoint,
      normal: true,
      showToast: true,
      isNew,
    });
    if (schemaData.skip) {
    } else {
      await updateKpiSchema({ appID: chartSchemaMemo.appID });
      handleChartUpdate({
        selectedDepartment,
        isDelete: false,
        selectedTargetPointID: kpiTargetPoints.selectedTargetPointID,
      });
    }
    setIsSaveLoading(false);
  };
  const removeFunction = async () => {
    setIsDeleteLoading(true);
    if (isNew) {
      setIsDeleteLoading(false);
      return;
    }
    const endPoint = `${chartSchemaMemo.appID}/kpiSettings/KpiCharts/delete`;
    const payloadData = { query: { chartID: chartSchemaMemo.chartID } };
    const res = await _supistaAnalyticsApi(endPoint, payloadData);
    if (!res.__d3__error) {
      await updateKpiSchema({ appID: chartSchemaMemo.appID });
      toast.success('Deleted successfully!');
      handleChartUpdate({
        selectedDepartment,
        isDelete: true,
        selectedTargetPointID: kpiTargetPoints.selectedTargetPointID,
      });
    } else {
      toast.success(res.response.msg ?? 'Some error occurred! Not able to Delete!');
    }
  };

  return (
    <div className='MainUpperHeader'>
      <div className='title'>{mls('KPI Chart Settings')}</div>
      <div className='subTitle'>{mls('Edit KPI Chart Settings here')}</div>
      <div className='goleftSide'></div>
      {!isNew ? (
        <div className='deleteButtonDiv'>
          <button type='button' className='btn btn-sm btn-danger' onClick={() => removeFunction()}>
            <span className='indicator-label'>
              {mls('Delete')}
              {isDeleteLoading ? (
                <>
                  &nbsp;
                  <CircularProgress color='inherit' size={10} />
                </>
              ) : null}
            </span>
          </button>
        </div>
      ) : null}
      <div className='saveButtonDiv'>
        <button className='btn btn-sm btn-primary' onClick={() => saveFunction()}>
          <span className='indicator-label'>
            {mls('Save')}
            {isSaveLoading ? (
              <>
                &nbsp;
                <CircularProgress color='inherit' size={10} />
              </>
            ) : null}
          </span>
        </button>
      </div>
      <div className='closeButton' onClick={() => handleKPISettingsPopUp(false)}>
        <FaTimes />
      </div>
    </div>
  );
};

export default Header;
