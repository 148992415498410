import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { defaultToolTipStyling } from '../../constants/constants';
import { defaultMargin } from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { AxisBottom, AxisLeft } from '@visx/axis';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { voronoi } from '@visx/voronoi';
import { getStyling, hexWithOpacity } from '../../helpers/colorHelper';
import { BubblePlotToolTip } from '../ChartToolTip/ChartToolTip';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { GridColumns, GridRows } from '@visx/grid';
import { getStrokDashArray } from 'lib/visx-lib/helpers/gridHelper';
import { widthHelper } from '../XYType/XYType';

function normalize(min, max) {
  let delta = max - min;
  return function (val) {
    return (12 * (val - min)) / delta;
  };
}

const x = (d) => d[0];
const y = (d) => d[1];
let tooltipTimeout;
const margin = { ...defaultMargin, right: 20, top: 20 };
const BubblePlotChart = ({
  width,
  height,
  data,
  xAxisLabel,
  yAxisLabel,
  xCategory,
  yCategory,
  // margin,
  type,
  colorScale,
  theme,
  group = [],
  chartData,
  isGroupType,
}) => {
  const points = useMemo(() => {
    const p = [];
    for (let i = 0; i < data.x.length; i++) {
      p.push([data?.x[i], data?.y[i], data?.radius?.[i], group[i]]);
    }
    return p;
  }, [data, group]);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [Math.min(...points.map((x) => x[0])), Math.max(...points.map((x) => x[0]))],
        range: [0, xMax],
        clamp: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [Math.min(...points.map((x) => x[1])), Math.max(...points.map((x) => x[1]))],
        range: [yMax, 0],
        clamp: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yMax]
  );
  const voronoiLayout = useMemo(
    () =>
      voronoi({
        x: (d) => Math.round((xScale(x(d)) ?? 0) * 100) / 100,
        y: (d) => Math.round((yScale(y(d)) ?? 0) * 100) / 100,
        width,
        height,
      })(points),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, xScale, yScale]
  );

  const normRadius = useMemo(() => {
    const min = Math.min(...(data?.radius ?? []));
    const max = Math.max(...(data?.radius ?? []));
    return (data?.radius ?? []).map(normalize(min, max));
  }, [data]);
  /// TooolTip
  const capitalizeOnlyFirst = (s) => {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const xAxis = useMemo(() => chartData?.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData?.yAxis, [chartData.yAxis]);

  const actualWidth = widthHelper(data.x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * data.x.length);

  const xAxisLabels = useMemo(() => {
    const axisLabels = xAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, xAxis]);
  const yAxisLabels = useMemo(() => {
    const axisLabels = yAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, yAxis]);
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  // event handlers
  const handleMouseOver = useCallback(
    (event, datum) => {
      if (tooltipTimeout) clearTimeout(tooltipTimeout);
      const coords = localPoint(event.target.ownerSVGElement, event);
      const closest = voronoiLayout.find(coords.x - margin.left, coords.y - margin.top, 100);
      const toolTipData = datum ?? closest?.data;
      let toolTipFinalData = {};
      let i = 0;
      if (toolTipData) {
        xAxis.forEach((elem) => {
          toolTipFinalData = { ...toolTipFinalData, [elem.uniqueColumnName]: toolTipData[i++] };
        });
        yAxis.forEach((elem) => {
          toolTipFinalData = { ...toolTipFinalData, [elem.uniqueColumnName]: toolTipData[i++] };
        });
        if (isGroupType) {
          const groupData = theme.tooltip.style.find((item) => item.axis === 'groupId');
          toolTipFinalData = {
            ...toolTipFinalData,
            [groupData.uniqueColumnName]: toolTipData[i++],
          };
        }
      }
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: toolTipFinalData,
      });
    },
    [showTooltip, voronoiLayout]
  );
  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
        valueStyle={theme.xAxisStyle.valueStyle}
      />
    );
  };
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        valueStyle={theme.yAxisStyle.valueStyle}
      />
    );
  };
  const yAxisFormatter = theme?.yAxisStyle?.formatter;
  const xAxisFormatter = theme?.xAxisStyle?.formatter;
  const horizontalGridLines = chartData?.gridLines?.horizontal || {};
  const verticalGridLines = chartData?.gridLines?.vertical || {};

  // fill = { theme.tooltip.style[0].color }
  // fill={
  //   isGroupType
  //     ? colorScale(group?.[i]?.toString())
  //     : theme?.colors[yAxisValue?.uniqueColumnName] ?? '#f6c431'
  // }

  const yAxisValue = theme?.tooltip?.style.find((styleElem) => {
    if (styleElem?.uniqueColumnName === chartData?.yAxis[0]?.uniqueColumnName) {
      return true;
    }
    return null;
  });
  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        {/* <GradientTealBlue id='dots-pink' /> */}
        <LinearGradient id='dots-pink' from='#351CAB' to='#621A61' rotate='-45' />
        {theme.yAxisStyle?.show ? (
          <AxisBottom
            top={yMax + margin.top}
            left={margin.left}
            scale={xScale}
            stroke={'#777d84'}
            tickLength={5}
            // label={yAxisLabel}
            label={xAxisLabel}
            tickClassName={styles.bottomAxisTicks}
            tickFormat={(e) =>
              numberFormatter({ type: { ...yAxisFormatter, compact: true }, value: e })
            }
            numTicks={dataNum}
            tickComponent={AxisBottomLabelWrapper}
            strokeWidth={2.5}
            tickStroke={'#adb5bd'}
            tickLineProps={{
              strokeWidth: 1.5,
            }}
            labelProps={{
              ...getStyling(theme.xAxisStyle?.style),
              textAnchor: 'middle',
            }}
            labelOffset={25}
          />
        ) : null}
        {theme.xAxisStyle?.show ? (
          <AxisLeft
            // label={xAxisLabel}
            label={yAxisLabel}
            stroke={'#adb5bd'}
            strokeWidth={1.5}
            top={margin.top}
            left={margin.left}
            scale={yScale}
            tickFormat={(e) =>
              numberFormatter({ type: { ...xAxisFormatter, compact: true }, value: e })
            }
            tickComponent={AxisleftLabelWrapper}
            tickClassName={styles.leftAxisTicks}
            tickStroke={'#adb5bd'}
            tickLineProps={{
              strokeWidth: 1.5,
            }}
            labelProps={{
              ...getStyling(theme.yAxisStyle?.style),
              textAnchor: 'middle',
            }}
          />
        ) : null}

        <Group left={margin.left} top={margin.top}>
          <rect
            width={Math.max(xMax, 0)}
            height={yMax}
            rx={4}
            // fill='url(#dots-pink)'
            fill='white'
            onMouseMove={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onTouchMove={handleMouseOver}
            onTouchEnd={handleMouseLeave}
          />
          {horizontalGridLines?.show && (
            <GridRows
              scale={yScale}
              width={xMax}
              numTicks={horizontalGridLines?.count ?? 5}
              strokeWidth={horizontalGridLines?.width ?? 1}
              stroke={hexWithOpacity(
                horizontalGridLines?.color,
                horizontalGridLines?.opacity / 100
              )}
              strokeDasharray={getStrokDashArray(horizontalGridLines?.type)}
              // lineStyle={{}}
            />
          )}
          {verticalGridLines?.show && (
            <GridColumns
              scale={xScale}
              height={yMax}
              numTicks={verticalGridLines?.count ?? 5}
              strokeWidth={verticalGridLines.width ?? 1}
              stroke={hexWithOpacity(verticalGridLines?.color, verticalGridLines?.opacity / 100)}
              strokeDasharray={getStrokDashArray(verticalGridLines?.type)}
              // lineStyle={{}}
            />
          )}
          <Group pointerEvents='none'>
            {points.map((point, i) => {
              return (
                <Circle
                  key={`point-${point[0]}-${i}`}
                  className='dot'
                  cx={xScale(x(point))}
                  cy={yScale(y(point))}
                  r={
                    tooltipData
                      ? tooltipData[0] === point[0] && tooltipData[1] === point[1]
                        ? normRadius[i] + 3
                        : normRadius[i]
                      : normRadius[i]
                  }
                  // fill={theme.tooltip.style[0].color}
                  // fill={
                  //   isGroupType
                  //     ? colorScale(group?.[i]?.toString())
                  //     : theme.tooltip.style[0].color ?? '#f6c431'
                  // }
                  fill={
                    isGroupType
                      ? colorScale(group?.[i]?.toString())
                      : theme?.colors[yAxisValue?.uniqueColumnName] ?? '#f6c431'
                  }
                  opacity={tooltipData === point ? 1 : 0.8}
                  // stroke={'#000'}
                />
              );
            })}
          </Group>
        </Group>
      </svg>
      {tooltipOpen && (
        <BubblePlotToolTip
          defaultToolTipStyling={defaultToolTipStyling}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          TooltipInPortal={TooltipInPortal}
          tooltipData={tooltipData}
          theme={theme}
          xAxisLabel={xAxisLabel}
          yAxisLabel={yAxisLabel}
          backgroundColor={defaultToolTipStyling.backgroundColor}
          xAxisLabels={xAxisLabels}
          yAxisLabels={yAxisLabels}
          isGroupType={isGroupType}
          colorScale={colorScale}
        />
      )}
    </div>
  );
};

export default BubblePlotChart;

const test = {
  charts: {
    'c2fb57c6-fd3e-4859-9cfd-1986600a9cc5': {
      chartType: 'PIVOTTABLE',
      name: 'Summary of Sales Data',
      desc: '',
      xAxis: [
        {
          column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Date Sold',
          dataType: 'DATETIME',
          label: 'Date Sold',
          uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Date Sold-0',
          operations: {},
          originalDataType: 'DATETIME',
          axis: 'xAxisId',
        },
      ],
      yAxis: [
        {
          column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Amount',
          dataType: 'NUM',
          label: 'Amount',
          uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Amount-1',
          operations: { type: 'sum' },
          originalDataType: 'NUM',
          axis: 'yAxisId',
        },
        {
          column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Units Sold',
          dataType: 'NUM',
          label: 'Units Sold',
          uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Units Sold-2',
          operations: { type: 'sum' },
          originalDataType: 'NUM',
          axis: 'yAxisId',
        },
        {
          column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Profit',
          dataType: 'NUM',
          label: 'Profit',
          uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Profit-3',
          operations: { type: 'sum' },
          originalDataType: 'NUM',
          axis: 'yAxisId',
        },
      ],
      xAxisStyle: {
        label: 'Date Sold',
        isLabelEdited: false,
        show: true,
        style: {
          color: '#181823',
          textStyle: [],
          font: 'Poppins',
          fontHeight: '12',
          opacity: 100,
        },
        valueStyle: {
          color: '#181823',
          textStyle: [],
          font: 'Poppins',
          fontHeight: '12',
          opacity: 100,
          rotate: 0,
        },
      },
      yAxisStyle: {
        label: 'Amount,Units Sold,Profit',
        isLabelEdited: false,
        show: true,
        style: { color: '#181823', textStyle: [], font: 'Poppins', fontHeight: '12', opacity: 100 },
        valueStyle: {
          color: '#181823',
          textStyle: [],
          font: 'Poppins',
          fontHeight: '12',
          opacity: 100,
          rotate: 0,
        },
      },
      chartWidth: 'HALF',
      chartID: 'c2fb57c6-fd3e-4859-9cfd-1986600a9cc5',
      appID: 'telecomoemrsqwhuflphatl5',
      sheetID: 'f1c93050-772a-11ee-96c6-73bfcd6f3488',
      projectID: '',
      tooltip: {
        textStyle: [],
        font: 'Poppins',
        fontHeight: 12,
        type: 'automatic',
        style: [
          {
            label: 'Date Sold',
            originalLabel: 'Date Sold',
            show: true,
            color: '#00A3FF',
            opacity: 100,
            column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Date Sold',
            uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Date Sold-0',
            axis: 'xAxisId',
          },
          {
            label: 'Amount',
            originalLabel: 'Amount',
            show: true,
            color: '#00C1FF',
            opacity: 100,
            column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Amount',
            uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Amount-1',
            axis: 'yAxisId',
          },
          {
            label: 'Units Sold',
            originalLabel: 'Units Sold',
            show: true,
            color: '#00D9E6',
            opacity: 100,
            column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Units Sold',
            uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Units Sold-2',
            axis: 'yAxisId',
          },
          {
            label: 'Profit',
            originalLabel: 'Profit',
            show: true,
            color: '#46f0f0',
            opacity: 100,
            column: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Profit',
            uniqueColumnName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455.Profit-3',
            axis: 'yAxisId',
          },
        ],
        valueStyle: [],
      },
      filters: {},
      isInsight: true,
      isForecast: false,
      isZoomBrush: true,
      chartBuilderHelper: { columnDropNumber: 0 },
      pivotTableTitleStyle: { fontHeight: '12', textStyle: ['bold'] },
    },
  },
  projectID: '',
  sheetID: 'f1c93050-772a-11ee-96c6-73bfcd6f3488',
  appID: 'telecomoemrsqwhuflphatl5',
  timeFrame: {
    'd1f812c7-a757-4a03-a4f4-c65ef2228455.Date Sold': {
      column: 'Date Sold',
      dataType: 'DATETIME',
      tableName: 'd1f812c7-a757-4a03-a4f4-c65ef2228455',
      name: 'Date Sold',
      sheetID: 'f1c93050-772a-11ee-96c6-73bfcd6f3488',
      value: { period: 'LASTXDAYS', xDay: 180 },
    },
  },
};
