import { FaTimes } from 'react-icons/fa';
import EditTableList from './EditTableList';
import TableCustomColumn from 'lib/data-connectors/dependencies/tableCustomColumn/SqlCustomCommands';
import { mls } from 'lib/multilanguagesupport';
import useUpdateJoinTableSchema from 'lib/data-connectors/dependencies/useUpdateJoinTablesSchema';
import { AllCustomCommandType } from 'lib/data-connectors/dependencies/tableCustomColumn/data/sqlCustomCommandsData';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CloseConfirmationDiv from 'lib/data-connectors/dependencies/CloseConfirmationDiv/CloseConfirmationDiv';

interface editTableProps {
  appID: string;
  handleClose: Function;
  isAlreadyTable: boolean;
  setIsAlreadyTable: Function;
  selectedTable: any;
  setSelectedTable: Function;
  tableOriginalName: any;
  setTableOriginalName: Function;
  appJointable: any;
  joinedTables: any;
  setJoinedTables: Function;
  appDatatable: { [key: string]: any };
}
const initialTableToJoinValues = {
  joinTableName: '',
  primaryKey: '',
  secondaryKey: '',
  columnSchema: [],
  primaryTable: '',
};
const initialValues = {
  name: '',
  primaryTable: '',
  joinTableSchema: [initialTableToJoinValues],
  columnSchema: [],
};
const joinTableObjectsSchema = Yup.object().shape({
  joinTableName: Yup.string().required('Select the table to join'),
  primaryKey: Yup.string().required('Select primary key'),
  secondaryKey: Yup.string().required('Select secondary key'),
});

const joinTableFormSchema = Yup.object().shape({
  name: Yup.string().trim().required('Enter the name of the new joined table'),
  primaryTable: Yup.string().required('Select the primary table'),
  joinTableSchema: Yup.array().of(joinTableObjectsSchema),
});
const EditTable = ({
  appID,
  handleClose,
  isAlreadyTable,
  setIsAlreadyTable,
  selectedTable,
  setSelectedTable,
  tableOriginalName,
  setTableOriginalName,
  appJointable,
  joinedTables,
  setJoinedTables,
  appDatatable,
}: editTableProps) => {
  const [updateSqlCustomCommands, setUpdateSqlCustomCommands] = useState(1);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isAlreadyTable && selectedTable ? selectedTable : initialValues,
    validationSchema: joinTableFormSchema,
    onSubmit: async () => {
      return await true;
    },
  });
  // const isTableDataSame = useMemo(() => {
  //   if (
  //     savedTableData?.joinTableSchema === formik?.values?.joinTableSchema &&
  //     savedTableData?.tables === formik?.values?.tables &&
  //     savedTableData?.columnSchema === formik?.values?.columnSchema
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [
  //   savedTableData,
  //   formik?.values?.joinTableSchema,
  //   formik?.values?.tables,
  //   formik?.values?.columnSchema,
  // ]);

  const [isAnyChangesSchema, setIsAnyChangesSchema] = useState(false);
  const [isAnyChangesSqlCommands, setIsAnyChangesSqlCommands] = useState(false);
  const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);

  const closeTable = () => {
    if (isAnyChangesSchema || isAnyChangesSqlCommands) {
      setIsConfirmationDiv(true);
    } else {
      handleClose();
    }
  };
  return (
    <div className='editTableOuterContainer'>
      <div className='editTableHeader'>
        <div className='crossButton' onClick={() => closeTable()} data-bs-dismiss='modal'>
          <FaTimes />
        </div>
      </div>

      {isConfirmationDiv && (
        <CloseConfirmationDiv
          isConfirmationDiv={isConfirmationDiv}
          setIsConfirmationDiv={setIsConfirmationDiv}
          handleClose={handleClose}
        />
      )}
      <div className='editTableInnerContainer'>
        <EditTableList
          appID={appID}
          handleClose={handleClose}
          isAlreadyTable={isAlreadyTable}
          setIsAlreadyTable={setIsAlreadyTable}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          appDatatable={appDatatable}
          tableOriginalName={tableOriginalName}
          setTableOriginalName={setTableOriginalName}
          appJointable={appJointable}
          joinedTables={joinedTables}
          setJoinedTables={setJoinedTables}
          useUpdateSchema={useUpdateJoinTableSchema}
          setUpdateSqlCustomCommands={setUpdateSqlCustomCommands}
          formik={formik}
          setIsAnyChanges={setIsAnyChangesSchema}
        />
        <TableCustomColumn
          appID={appID}
          tableData={selectedTable}
          tableSchema={selectedTable}
          appDatatable={appDatatable}
          setTableSchema={setSelectedTable}
          useUpdateSchema={useUpdateJoinTableSchema}
          setIsAnyChangesSqlCommands={setIsAnyChangesSqlCommands}
          setIsAnyChangesSchema={setIsAnyChangesSchema}
          updateSqlCustomCommands={updateSqlCustomCommands}
          displayOff={!isAlreadyTable}
          // disableSqlStatements={!isTableDataSame}
          customCommandType={AllCustomCommandType.joinTables}
          displayOffMsg={mls('Save this join table to create custom column')}
        />
      </div>
    </div>
  );
};
export default EditTable;
