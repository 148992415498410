import { useEffect, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { Card } from './components/Card';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Sorting from '../../dependencies/Sorting';
import EditTable from './components/editTableList/EditTable';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CustomTablePage = () => {
  const { appID } = useParams();
  const appDatatable = useSelector((state) => state.config_store.analyticsSchema.appDatatable);
  const appCustomDatatable = useSelector(
    (state) => state.config_store.analyticsSchema.appCustomtable
  );
  const [dataTableData, setDataTableData] = useState(appCustomDatatable || {});
  const location = useLocation();

  const [dataTableSchema, setDataTableSchema] = useState(
    location?.state?.tableID ? appCustomDatatable[location.state.tableID] : null
  );
  const [openEditTable, setOpenEditTable] = useState(false);
  const [isAlreadyTable, setIsAlreadyTable] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [tableSchema, setTableSchema] = useState({});

  const [searchKeyWord, setSearchKeyWord] = useState('');
  useEffect(() => {
    dataTableSchema && handleShow();
    // Clears the location.state on reload
    window.history.replaceState({}, document.title);
  }, [dataTableSchema]);

  const handleShow = () => {
    document.body.style.overflow = 'hidden';
    setOpenEditTable(true);
  };
  const handleClose = () => {
    document.body.style.overflow = 'visible';
    setOpenEditTable(false);
  };

  const defaultCustomTableData = {
    name: '',
    description: '',
    sqlQuery: '',
    tables: [],
    columnSchema: [],
    tableType: 'CUSTOM_TABLE',
    _id: appID,
    appID,
  };
  const customTableMainProps = {
    appID,
    handleClose,
    appCustomDatatable,
    isAlreadyTable,
    setIsAlreadyTable,
    tableSchema,
    setTableSchema,
    isNew,
    setIsNew,

    appDatatable,
  };
  return (
    <div className='DataConnectors dataTable introjs-datatablePage'>
      <ToastContainer position={'bottom-left'} theme={'colored'} />
      {openEditTable ? (
        <PopUpDivChild
          className={'editTablePopup'}
          initialValue={openEditTable}
          setPopUp={handleClose}
          popupTitle={''}
          header={false}
        >
          <EditTable customTableMainProps={customTableMainProps} />
        </PopUpDivChild>
      ) : null}

      <div
        className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white rounded'
        style={{
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        <div className='p-2 fw-bold' style={{ marginRight: 'auto' }}>
          {mls('Create your fully customisable table using sql command powerd by AI.')}
        </div>
        <Sorting
          arrayOfObjects={dataTableData}
          setObjectOfObjects={setDataTableData}
          dataInRedux={appCustomDatatable}
          updateSearchKey={setSearchKeyWord}
        />
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            handleShow();
            setIsAlreadyTable(false);
            setIsNew(true);
            setTableSchema(defaultCustomTableData);
          }}
        >
          {mls('Create New Tables')}
        </button>
      </div>
      <div className='row'>
        {!_.isEmpty(Object.values(appCustomDatatable || {})) ? (
          _.isEmpty(Object.values(dataTableData || {})) ? (
            <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
              {mls(`No Custom Tables Found for this "${searchKeyWord}" keyword.`)}
            </div>
          ) : (
            Object.values(dataTableData || {}).map((data, index) => {
              return (
                <div
                  className='col-md-4 col-sm-6 mb-6'
                  key={index}
                  onClick={() => {
                    setIsAlreadyTable(true);
                    setTableSchema(
                      Object.values(dataTableData || {})?.[index] ?? defaultCustomTableData
                    );
                    setDataTableSchema(data);
                  }}
                >
                  <Card
                    handleShow={handleShow}
                    index={index}
                    title={data.name}
                    numOfColumns={data.columnSchema?.length}
                    description={data.description}
                    tableID={data.tableID ?? ''}
                    tableNumber={(data?.tables ?? []).length}
                  />
                </div>
              );
            })
          )
        ) : (
          <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
            {mls('No Custom Tables to display. Kindly create new tables to analyse them.')}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTablePage;
