import { allChartKeys } from './chartTypesSchema';

export const chartCapableOfHavingInsights = [
  allChartKeys.BAR,
  allChartKeys.BARSTACK, // IMPLEMENTED

  allChartKeys.LINE,
  allChartKeys.MULTILINE, // IMPLEMENTED

  allChartKeys.AREA,
  allChartKeys.AREASTACK, // IMPLEMENTED
  allChartKeys.BARGROUP, // IMPLEMENTED

  allChartKeys.BARHORIZONTAL, // IMPLEMENTED
  allChartKeys.BARSTACKHORIZONTAL, // IMPLEMENTED

  // allChartKeys.LINEHORIZONTAL,                 //NIC
  // allChartKeys.MULTILINEHORIZONTAL,            //NIC

  // allChartKeys.AREAHORIZONTAL,                 //NIC
  // allChartKeys.AREASTACKHORIZONTAL,            //NIC

  // allChartKeys.MULTIAXISMULTIAREAHORIZONTAL,   //NIC

  // allChartKeys.CARD, // NOT IMPLEMENTED
  allChartKeys.FUNNEL,

  allChartKeys.PIE,
  allChartKeys.DONUT,

  allChartKeys.GEOINDIA, // IMPLEMENTED
  allChartKeys.GEOUSA, // IMPLEMENTED
  allChartKeys.GEOCANADA, // IMPLEMENTED
  allChartKeys.GEOWORLD, // IMPLEMENTED

  allChartKeys.WORDCLOUD, // IMPLEMENTED

  allChartKeys.BUBBLEPLOT, // NOT IMPLEMENTED
  allChartKeys.GROUPBUBBLEPLOT, // NOT IMPLEMENTED
  allChartKeys.SCATTERPLOT, // NOT IMPLEMENTED
  allChartKeys.GROUPSCATTERPLOT, // NOT IMPLEMENTED

  allChartKeys.TREEMAP,
  allChartKeys.RADAR, // NOT IMPLEMENTED
  allChartKeys.RADIALLINE, // NOT IMPLEMENTED
  // allChartKeys.BARSTACKLINE,  // NOT IMPLEMENTED
  // allChartKeys.KPIMETER,  // NOT IMPLEMENTED
  allChartKeys.PIVOTTABLE, // IMPLEMENTED
  allChartKeys.HEATMAP, // NOT IMPLEMENTED

  // allChartKeys.KPITRACKING, // NOT IMPLEMENTED

  allChartKeys.BARCOLOR, // NOT IMPLEMENTED
  allChartKeys.LINECOLOR, // NOT IMPLEMENTED
  allChartKeys.AREACOLOR, // NOT IMPLEMENTED
  allChartKeys.BARHORIZONTALCOLOR, // NOT IMPLEMENTED
];

export const chartCapableOfHavingInsightsNot = [
  allChartKeys.HEATMAP,
  allChartKeys.RADIALLINE,
  allChartKeys.RADAR,
  allChartKeys.WORDCLOUD, // IMPLEMENTED
  allChartKeys.BARSTACKHORIZONTAL, // IMPLEMENTED

  allChartKeys.BARCOLOR, // NOT IMPLEMENTED
  allChartKeys.LINECOLOR, // NOT IMPLEMENTED
  allChartKeys.AREACOLOR, // NOT IMPLEMENTED
  allChartKeys.BARHORIZONTALCOLOR, // NOT IMPLEMENTED

  allChartKeys.BUBBLEPLOT, // NOT IMPLEMENTED
  allChartKeys.GROUPBUBBLEPLOT, // NOT IMPLEMENTED
  allChartKeys.SCATTERPLOT, // NOT IMPLEMENTED
  allChartKeys.GROUPSCATTERPLOT, // NOT IMPLEMENTED

  // allChartKeys.KPITRACKING, // NOT IMPLEMENTED

  // allChartKeys.CARD, // NOT IMPLEMENTED

  // allChartKeys.BARSTACKLINE,
  // allChartKeys.KPIMETER,
];
export const defaultOthers = {
  label: 'Others',
  originalLabel: '_d3_others',
  origin: '_d3_other',
  show: true,
  color: '#dff7ff',
  opacity: 70,
  column: 'b27aafa4-5318-4895-b714-d94d3f9bd587.Business Unit',
  uniqueColumnName: 'b27aafa4-5318-4895-b714-d94d3f9bd587.Business Unit-2',
  axis: 'others',
};
