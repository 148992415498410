/* eslint import/prefer-default-export: 0 */
import { variantColors } from '../constants/constants';

function adjust(color, amount) {
  return (
    '#' +
    (color || '00C1FF')
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      )
  );
}

/**
 * This method creates an array of colors.
 * Length of array depends on the number of inputs
 * @param {Number} num Number of array elements to be created
 * @param {String} chartColor Oroginal color, used to generate monochromatic scheme
 * @returns {Array} returns an array with required number of colors
 */
export const createColorArray = (num, chartColor) => {
  // Number of default Colors
  // const colorNum = defaultColors.length;
  const colorArr = [];
  // let colorIndex = 0;

  for (let i = 0; i < num; i += 1) {
    colorArr[i] = adjust(chartColor, -20 + 40 * i);
    // colorIndex = (colorIndex === colorNum - 1) ? 0 : colorIndex + 1;
  }
  return colorArr;
};

/**
 * This method creates an array of colors.
 * Length of array depends on the number of inputs
 * @param {Number} num Number of array elements to be created
 * @returns {Array} returns an array with required number of colors
 */
export const createVariantColorArray = (num) => {
  // Number of default Colors
  const colorNum = variantColors.length;
  const colorArr = [];
  let colorIndex = 0;

  for (let i = 0; i < num; i += 1) {
    colorArr[i] = variantColors[colorIndex];
    colorIndex = colorIndex === colorNum - 1 ? 0 : colorIndex + 1;
  }

  return colorArr;
};

export const getStyling = (styling) => {
  if (!styling) {
    return {
      color: 'black',
      fill: 'black',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      fontFamily: 'sans-serif',
      fontSize: 12,
      opacity: 1,
    };
  }

  return {
    color: styling?.color,
    fill: styling?.color,
    fontWeight: styling?.textStyle?.includes('bold') ? 'bold' : 'normal',
    fontStyle: styling?.textStyle?.includes('italic') ? 'italic' : 'normal',
    textDecoration: styling?.textStyle?.includes('underlined') ? 'underline' : 'none',
    fontFamily: styling?.font || 'sans-serif',
    fontSize: styling?.fontHeight || 12,
    opacity: styling?.opacity / 100 ?? 1,
    display: styling?.show === false ? 'none' : 'block',
  };
};

export const darkenColor = (hex, percent) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Decrease each value by the percentage
  r = Math.max(0, r - Math.round((r * percent) / 100));
  g = Math.max(0, g - Math.round((g * percent) / 100));
  b = Math.max(0, b - Math.round((b * percent) / 100));

  // Convert r, g, b back to hex and concatenate them
  let newHex =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0');

  return newHex;
};
// // Helper function to convert a color hash code to RGB values
// function hexToRgb(colorHash) {
//   const hex = colorHash.replace(/^#/, '');
//   const bigint = parseInt(hex, 16);
//   const r = (bigint >> 16) & 255;
//   const g = (bigint >> 8) & 255;
//   const b = bigint & 255;
//   return { r, g, b };
// }

// // Helper function to convert a color hash code with opacity to RGBA values
// function hexWithOpacityToRgba(hexWithOpacity) {
//   const hex = hexWithOpacity.slice(0, 7);
//   const opacity = parseFloat(hexWithOpacity.slice(7), 16) / 255;
//   const rgb = hexToRgb(hex);
//   return { r: rgb.r, g: rgb.g, b: rgb.b, a: opacity };
// }

// // Function to calculate the relative luminance of an RGBA color
// function calculateRelativeLuminanceRGBA(rgba) {
//   let r = rgba.r / 255;
//   let g = rgba.g / 255;
//   let b = rgba.b / 255;

//   r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
//   g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
//   b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

//   // Adjust the luminance calculation with opacity
//   return (0.2126 * r + 0.7152 * g + 0.0722 * b) * rgba.a + (1 - rgba.a);
// }

// // Function to compare the brightness of two colors
// export const compareBrightness = (color1, color2 = '#878787ff') => {
//   let rgba1 = hexWithOpacityToRgba(color1);
//   let rgba2 = hexWithOpacityToRgba(color2);

//   let luminance1 = calculateRelativeLuminanceRGBA(rgba1);
//   let luminance2 = calculateRelativeLuminanceRGBA(rgba2);
//   if (luminance1 > luminance2) {
//     // return true;
//     return 'Darker';
//   } else if (luminance1 < luminance2) {
//     // return false;
//     return 'Lighter';
//   } else {
//     // return false;
//     return 'Same';
//   }
// };
// Function to compare the brightness of two colors, accounting for opacity
// Helper function to convert a color hash code to RGBA values
function hexToRgba(colorHash) {
  const hex = colorHash.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  const a = hex.length > 6 ? parseInt(hex.slice(6, 8), 16) / 255 : 1;
  return { r, g, b, a };
}

// Function to calculate perceived brightness
function calculatePerceivedBrightness(rgba) {
  return 0.299 * rgba.r + 0.587 * rgba.g + 0.114 * rgba.b;
}

// Function to compare the perceived brightness of two colors, accounting for opacity
export const compareBrightness = (color1, color2 = '#969696ff') => {
  const rgba1 = hexToRgba(color1);
  const rgba2 = hexToRgba(color2);
  const brightness1 = calculatePerceivedBrightness(rgba1) / (rgba1.a * 1);
  const brightness2 = calculatePerceivedBrightness(rgba2) / (rgba2.a * 1);

  if (brightness1 > brightness2) {
    return true;
  } else if (brightness1 < brightness2) {
    return false;
  } else {
    return true;
  }
};

export const getDefaultColorByIndex = (index) => {
  const normalizedValue = index % variantColors.length;
  let defalutColor =
    variantColors[normalizedValue >= 0 ? normalizedValue : normalizedValue + variantColors.length];
  return defalutColor;
};

const expandShortHex = (hex) => {
  // Remove the "#" symbol from the beginning of the hex color, if present
  hex = hex.replace('#', '');

  // If the hex value is short (e.g., #FFF), expand it to full format (e.g., #FFFFFF)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    return hex;
  }
  if (hex.length > 6) {
    return `${'0'.repeat(6 - hex.length)}${hex}`;
  }
  return hex;
};
export const hexWithOpacity = (hex = '#FFFFFF', opacity = 1) => {
  hex = expandShortHex(hex);
  // Parse the hex values for red, green, and blue
  // Ensure opacity is within the valid range (0.0 to 1.0)
  const validOpacity = Math.min(1, Math.max(0, opacity));

  // Convert the opacity to hexadecimal
  const alphaHex = Math.round(validOpacity * 255)
    .toString(16)
    .padStart(2, '0');

  // Create and return the hex color with opacity
  return `#${hex}${alphaHex}`;
};
