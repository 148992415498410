import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { Box } from '@mui/material';
import { Modal } from 'react-bootstrap-v5';

import { KTSVG } from '_metronic/helpers';

import { mls } from 'lib/multilanguagesupport';

import TableSettings from './TableSettings';

import { _supistaAnalyticsApi } from 'lib/server-connection/connections';

const statusesApiCall = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};
const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: '90vw',
  // bgcolor: 'background.paper',
  // boxShadow: 24,
  // p: 4,
  // pb: 0,
  // pl: 0,
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // maxHeight: '80vh',
  // minHeight: '70vh',
  height: '80vh',
  overflow: 'auto',
};

const ImportTableModal = ({
  open,
  setOpen,
  openedConnectionID,
  setOpenedConnectionID,
  credSchema,
}) => {
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(tableNames?.[0] || null);
  const [apiCallStatus, setApiCallStatus] = useState(statusesApiCall.loading); // loading | success | error
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  // Table list API call
  useEffect(() => {
    const loadTables = async () => {
      try {
        setApiCallStatus(statusesApiCall.loading);
        const response = await _supistaAnalyticsApi(`${appID}/api/getConnectorTable/fetch`, {
          appID: appID,
          credentialID: openedConnectionID,
        });
        if (response.__d3__error) {
          // Display error toast here with the message
          setApiErrorMessage(response.message);
          setApiCallStatus(statusesApiCall.error);
        } else {
          setTableNames(response.response);
          setSelectedTable(response.response?.[0] || null);
          setApiCallStatus(statusesApiCall.success);
        }
      } catch (err) {
        // Display error toast here with the message
        setApiErrorMessage(
          'Could not fetch table list from the server! Kindly check your credentials or try again later'
        );
        setApiCallStatus(statusesApiCall.error);
        console.error(err, 'loadTableError');
      }

      // Temporary code for demonstration
      // try {
      //   const response = await fetch('http://localhost:5000/gettables',
      //   {
      //     method: 'POST',
      //     headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //       credentialsID: openedConnectionID,
      //     })
      //   });
      //   const tables = await response.json();
      //   setTableNames(tables);
      //   setApiCallStatus(statusesApiCall.success);
      // } catch (err) {
      //   console.error(err, 'loadTableError');
      // }
    };
    openedConnectionID && loadTables();
  }, [appID, openedConnectionID]);

  // Table schema API call
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSelectedTableSchema(sampleTableSchema?.[selectedTable]);
  //     setSelectedCols(sampleTableSchema?.[selectedTable]?.columnSchema);
  //   }, 1000);
  // }, [selectedTable]);

  const closeModal = () => {
    setTableNames([]);
    setSelectedTable(null);
    setOpen(false);
    setOpenedConnectionID(null);
  };

  return (
    <>
      <Modal show={open} onHide={closeModal} size='lg' centered>
        <div style={style} className='px-10'>
          <div className='mb-10 mt-10 row'>
            <h1 className='mt-2 text-center col-11'>
              {mls('Import Table From External Database')}
            </h1>

            {/* Modal close button */}
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5 col-1'
              onClick={closeModal}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>

          <div className='row w-100' style={{ height: '80%' }}>
            {/* Left sidebar - to select the table */}
            <div className='col-3' style={{ overflow: 'scroll', height: '100%' }}>
              <div className='bg-white' style={{ position: 'sticky', top: 0 }}>
                <h3 className='my-0 ml-2'>{mls('Table List')}</h3>
                <hr className='mt-1 mb-3' />
              </div>
              {apiCallStatus === 'loading' && (
                <>
                  <div className='d-flex mt-20 justify-content-center'>
                    <div className='spinner-border text-primary' role='status'></div>
                  </div>
                </>
              )}

              {apiCallStatus === 'success' && (
                <div>
                  <ul className='nav nav-pills flex-column'>
                    {tableNames?.map((tableName, index) => {
                      return (
                        <li className='nav-item mx-1' key={index}>
                          <span
                            role='button'
                            className={`nav-link user-select-none d-flex flex-row justify-content-between align-items-center ${
                              selectedTable === tableName ? 'active' : 'link-dark'
                            }`}
                            onClick={setSelectedTable.bind(null, tableName)}
                          >
                            <span>{tableName}</span>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>

            {/* Table import settings area */}
            <div className='col-9 ps-5' style={{ overflow: 'scroll', height: '100%' }}>
              <div className='bg-white' style={{ position: 'sticky', top: 0, zIndex: 50 }}>
                <h3 className='my-0 ml-2'>{mls('Table import settings')}</h3>
                <hr className='mt-1' />
              </div>
              {apiCallStatus === statusesApiCall.error && (
                <div className='d-flex align-items-center pe-0' style={{ height: '80%' }}>
                  <div className='d-flex flex-column text-primary pe-0 sm-10 w-100'>
                    <div className='alert alert-danger d-flex flex-column flex-sm-row p-5 text-center'>
                      <span className='w-100'>
                        {mls(
                          apiErrorMessage ||
                            'Some error occured while fetching the connection details.'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {apiCallStatus === statusesApiCall.success && !selectedTable && (
                <div className='d-flex align-items-center pe-0' style={{ height: '80%' }}>
                  <div className='d-flex flex-column text-primary pe-0 sm-10 w-100'>
                    <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 text-center'>
                      <span className='w-100'>
                        {mls('Please select a table from the left bar to import it to Supista.')}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {selectedTable && (
                <TableSettings
                  key={selectedTable}
                  openedConnectionID={openedConnectionID}
                  selectedTable={selectedTable}
                  setOpen={setOpen}
                  dbType={credSchema.connectorType}
                  credentialID={credSchema.credentialID}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportTableModal;
