export const startingMessage = [
  "Hello there! I'm Supista's chat AI bot.",
  `Ready to create a chart based on your query. Please select a table from the dropdown and write your query to get started.`,
];

export const errorMessage = {
  noTableSelected: 'Please select a table from the dropdown.',
  noQuery: 'Please write a query to get started.',
  noData: 'No chart found for the given query. Please try again with a different query.',
  surpriseMeNoData: 'No Charts can be displayed! Please try again.',
};
