import React, { useEffect, useRef, useState } from 'react';
import './pinningOptions.scss';
import { BsChevronDown } from 'react-icons/bs';
import { mls } from 'lib/multilanguagesupport';

interface mainPorps {
  object: any;
  dropDownValue: string;
  className?: string;
  nameKey?: string;
  mainKey?: string;
  inputPlaceHolder?: string;
  search?: boolean;
  changeToReset?: boolean;
  isDropDownOpen: boolean;
  setisDropDownOpen: Function;
  setdropDownValue: Function;
}
const PinningOptions = ({
  object,
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  mainKey = 'key',
  isDropDownOpen,
  setisDropDownOpen,
}: mainPorps) => {
  const isObject = typeof dropDownValue === 'object' ? true : false;
  //   const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isNewDashBoard, setisNewDashBoard] = useState(false);
  const [dashboardName, setDashboardName] = useState('');
  const handleDropDown = () => {
    setisDropDownOpen((currentData: boolean) => !currentData);
    setisNewDashBoard(false);
  };

  const handleSelection = (elem: any) => {
    setdropDownValue(elem, false);
    handleDropDown();
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef?.current.contains(event.target)) {
        handleDropDown();
      }
    }
    if (isDropDownOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isDropDownOpen]);

  const isSelected = (element: any, dropdown: any) => {
    if (isObject) {
      return element?.[mainKey] === dropdown?.[mainKey]
        ? 'dropDownItems selected'
        : 'dropDownItems';
    }
    return element === dropdown ? 'dropDownItems selected' : 'dropDownItems';
  };

  const handleNewDashBoardSubmit = (e: any) => {
    e.preventDefault();
    handleDropDown();
    setDashboardName((currenName: string) => {
      setdropDownValue(currenName, true);
      return '';
    });
  };
  return (
    <div className='PinningOptions' ref={wrapperRef}>
      <div className={isDropDownOpen ? 'dropDownArray open' : 'dropDownArray'}>
        <div
          className={isNewDashBoard ? 'dropDownItems dashboard open' : 'dropDownItems dashboard'}
          onClick={() => {
            setisNewDashBoard(true);
          }}
        >
          <div className='createNewDashboardTitle'>{mls('Create New DashBoard')}</div>
          {isNewDashBoard ? (
            <form
              className='creactNewDashboardContainer'
              onClick={(e) => e.stopPropagation()}
              onSubmit={handleNewDashBoardSubmit}
            >
              <input
                type='text'
                placeholder={mls('Dashboard Name')}
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
                className='primaryInput'
                required
              />
              <button className='primaryButton' type='submit'>
                {mls('Save')}
              </button>
            </form>
          ) : null}
        </div>
        {React.Children.toArray(
          Object.keys(object).map((key) => {
            return (
              <div
                className={isSelected(key, dropDownValue)}
                onClick={() => handleSelection(key)}
                title={object[key as keyof typeof object]?.name}
              >
                {object[key as keyof typeof object]?.name}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PinningOptions;
