import React, { useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import './selectReportType.scss';
import {
  defaultReportTypeObject,
  reportPageStageObject,
} from 'lib/reports-analytics/data/defaultSchema';
import { toast } from 'react-toastify';
interface selectReportTypeProps {
  stateProps: any;
}
const SelectReportType = ({ stateProps }: selectReportTypeProps) => {
  const {
    reportName,
    setReportName,
    reportDescription,
    setReportDescription,
    selectedReportType,
    setSelectedReportType,
    setReportPageStage,
    scheduledTime,
    setScheduledTime,
    sendTo,
    setSendTo,
    sendToOptions,
    settingsSchema,
    // handleReportConfigToggle,
    appID,
  } = stateProps;

  const handleNext = () => {
    if (!reportName) {
      toast.warn(mls('Enter report name!'));
      return;
    }
    if (!selectedReportType) {
      toast.warn(mls('Select any one report type'));
      return;
    }
    setReportPageStage(reportPageStageObject.selectReport.key);
  };
  return (
    <div className='selectReportType'>
      <div className='innerSelectReportType'>
        <div className='selectReportTypeLabel'>{mls('Create New Report')}</div>
        <div className='reportNameDiv'>
          <div className='titleDiv'>{mls('Report Name')}:*</div>
          <input
            type='text'
            placeholder='Name of Report'
            value={reportName}
            maxLength={50}
            onChange={(e) => setReportName(e.target.value)}
          />
        </div>
        <div className='reportDescriptionDiv'>
          <div className='titleDiv'>{mls('Report Description')}:</div>
          <textarea
            placeholder='Description of Report'
            value={reportDescription}
            rows={3}
            maxLength={200}
            onChange={(e) => setReportDescription(e.target.value)}
          />
        </div>
        {/* <div className='reportSelectionDiv'>
          <div className='reportSelectionUpperDiv'>
            <div className='titleDiv'>{mls('Report Type')}:</div>
            <div className='subTitleDiv'>{mls('Select any one report type')}</div>
          </div>
          <div className='reportSelectionLoweDiv'>
            {React.Children.toArray(
              Object.keys(defaultReportTypeObject).map((reportTypeObjectElemKey) => {
                const reportTypeObjectElem =
                  defaultReportTypeObject[
                    reportTypeObjectElemKey as keyof typeof defaultReportTypeObject
                  ];
                return (
                  <div
                    className='reportSelectionItem'
                    onClick={() => setSelectedReportType(reportTypeObjectElemKey)}
                  >
                    <div className='reportSelectionItemRadioButton'>
                      <input
                        type='radio'
                        name={reportTypeObjectElem.name}
                        checked={reportTypeObjectElemKey === selectedReportType}
                        onChange={() => {}}
                      />
                    </div>
                    <div className='reportSelectionItemTitle'>{mls(reportTypeObjectElem.name)}</div>
                  </div>
                );
              })
            )}
          </div>
        </div> */}
        <div>
          <button className='primaryButton secondaryButtonColor' onClick={() => handleNext()}>
            {mls('Next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectReportType;
