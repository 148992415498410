import React, { useEffect, useRef, useState } from 'react';
import {
  defaultColorfulColorPalettes,
  defaultKpiTargetData,
} from '../../../data/defaultChartSchema';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdDoneOutline } from 'react-icons/md';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { mls } from 'lib/multilanguagesupport';

const defaultStartingPoint = 0;
const KpiTargetPoints = ({ stateProps }) => {
  const { kpiTargetPoints, setKpiTargetPoints } = stateProps;
  const [isTargetPointInputVisible, setIsTargetPointInputVisible] = useState(false);
  const [defaultColorfulColorPalettesState, setdefaultColorfulColorPalettesState] = useState(
    defaultColorfulColorPalettes
  );

  const handleTargetPointInputVisibility = () =>
    setIsTargetPointInputVisible((currentData) => !currentData);
  useUpdateEffect(() => {
    if (kpiTargetPoints.targets[0]) {
      if (kpiTargetPoints.targets[0].targetPoint <= 0) {
        setKpiTargetPoints({
          ...kpiTargetPoints,
          startPoint: kpiTargetPoints.targets[0].targetPoint,
        });
        return;
      }
    }
    setKpiTargetPoints({ ...kpiTargetPoints, startPoint: defaultStartingPoint });
  }, [kpiTargetPoints.targets]);
  return (
    <div className='grayContainers'>
      {React.Children.toArray(
        kpiTargetPoints.targets.map((elm, i) => (
          <TargetPoints
            elm={elm}
            index={i}
            targetSetState={setKpiTargetPoints}
            kpiTargetPoints={kpiTargetPoints}
          />
        ))
      )}
      {isTargetPointInputVisible && (
        <KpiTargetInput
          setKpiTargetPoints={setKpiTargetPoints}
          isTargetPointInputVisible={isTargetPointInputVisible}
          handleTargetPointInputVisibility={handleTargetPointInputVisibility}
          defaultColorfulColorPalettesState={defaultColorfulColorPalettesState}
          setdefaultColorfulColorPalettesState={setdefaultColorfulColorPalettesState}
        />
      )}
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButtonSmall' onClick={() => handleTargetPointInputVisibility()}>
          + {mls('Add Target Point')}
        </button>
      </div>
    </div>
  );
};

export default KpiTargetPoints;

const KpiTargetInput = ({
  setKpiTargetPoints,
  isTargetPointInputVisible,
  handleTargetPointInputVisibility,
  defaultColorfulColorPalettesState,
  setdefaultColorfulColorPalettesState,
}) => {
  const [targetPointInput, setTargetPointInput] = useState('');
  const wrapperRef = useRef(null);

  const addTargetPoints = () => {
    setTargetPointInput((currenGoalData) => {
      if (!currenGoalData) {
        handleTargetPointInputVisibility();
        return currenGoalData;
      }
      setKpiTargetPoints((currentTargetPointData) => {
        const sourceitems = Array.from(currentTargetPointData.targets);
        const randomColorIndex = Math.floor(
          Math.random() * defaultColorfulColorPalettesState.length
        );
        const randomColor = defaultColorfulColorPalettesState[randomColorIndex];
        if (defaultColorfulColorPalettesState.length <= 1) {
          setdefaultColorfulColorPalettesState(defaultColorfulColorPalettes);
        } else {
          const copyColorPallettes = Array.from(defaultColorfulColorPalettesState);
          copyColorPallettes.splice(randomColorIndex, 1);
          setdefaultColorfulColorPalettesState(copyColorPallettes);
        }
        let newItem = {
          ...defaultKpiTargetData,
          targetPoint: currenGoalData,
          color: randomColor,
          // label: numberTickFormat(currenGoalData),
          label: numberFormatter({
            type: currentTargetPointData?.formatter,
            value: currenGoalData,
            short: true,
          }),
        };
        const index = sourceitems.findIndex((data) => {
          const goalInNumber = Number(data.targetPoint);
          const currenGoalDataInNumber = Number(currenGoalData);
          if (typeof goalInNumber !== 'number' || typeof currenGoalDataInNumber !== 'number') {
            return -1;
          }
          return goalInNumber >= currenGoalDataInNumber;
        });

        if (index === -1) {
          return {
            ...currentTargetPointData,
            targets: [...sourceitems, newItem],
          };
        }
        sourceitems.splice(index, 0, newItem);

        return { ...currentTargetPointData, targets: sourceitems };
      });
      handleTargetPointInputVisibility();
      return '';
    });
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      addTargetPoints();
    }
  };

  useUpdateEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        addTargetPoints();
      }
    }
    if (isTargetPointInputVisible) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isTargetPointInputVisible]);
  return (
    <>
      <input
        className='primaryInput'
        type='number'
        placeholder={mls('Group Input')}
        value={targetPointInput}
        style={{ margin: '.5rem .5rem', width: 'calc(100% - 1rem)' }}
        onBlur={() => addTargetPoints()}
        onKeyPress={(e) => handleEnterKey(e)}
        onChange={(e) => setTargetPointInput(e.target.value)}
        ref={wrapperRef}
      />
    </>
  );
};

const TargetPoints = (props) => {
  const { elm, index, targetSetState, kpiTargetPoints } = props;
  const [targetPointInput, setTargetPointInput] = useState(elm.targetPoint);
  const [labelInput, setLabelInput] = useState(elm.label);
  const [toggleClose, setToggleClose] = useState(false);
  const handleChange = (e, target) => {
    targetSetState((current) => {
      const sourceitems = Array.from(current.targets);
      const [selectedItem] = sourceitems.splice(index, 1);
      let newSelectedItem = {};
      switch (target) {
        case 'targetPoint':
          const index = sourceitems.findIndex((data) => {
            const goalInNumber = Number(data.targetPoint);
            const currenGoalDataInNumber = Number(e);
            if (typeof goalInNumber !== 'number' || typeof currenGoalDataInNumber !== 'number') {
              return -1;
            }
            return goalInNumber >= currenGoalDataInNumber;
          });
          const newItem = { ...selectedItem, targetPoint: e, label: labelInput };
          setToggleClose(!toggleClose);
          if (index === -1) {
            return {
              ...current,
              targets: [...sourceitems, newItem],
            };
          }
          sourceitems.splice(index, 0, newItem);
          return { ...current, targets: sourceitems };
        case 'label':
          newSelectedItem = { ...selectedItem, label: e.target.value };
          break;
        case 'danger':
          newSelectedItem = { ...selectedItem, color: { ...selectedItem.color, danger: e } };
          break;
        case 'safe':
          newSelectedItem = { ...selectedItem, color: { ...selectedItem.color, safe: e } };
          break;

        default:
          return current;
      }
      sourceitems.splice(index, 0, newSelectedItem);
      return { ...current, targets: sourceitems };
    });
  };

  const deleteTarget = () => {
    targetSetState((current) => {
      const sourceitems = Array.from(current.targets);
      sourceitems.splice(index, 1);
      return { ...current, targets: sourceitems };
    });
  };
  const handleTargetPoint = (e) => {
    setTargetPointInput(e.target.value);
    setLabelInput(
      numberFormatter({ type: kpiTargetPoints?.formatter, value: e.target.value, short: true })
    );
  };
  const handleLabel = (e) => {
    setLabelInput(e.target.value);
  };
  useEffect(() => {
    setTargetPointInput(elm.targetPoint);
    setLabelInput(elm.label);
  }, [elm]);

  return (
    <ClosableWhiteDiv title={elm.targetPoint} show={false} toggleClose={toggleClose}>
      <div>
        <div className='titleDiv'>{mls('Target Point')}</div>

        <input
          // type='text'
          type='number'
          className='primaryInput'
          value={targetPointInput}
          onChange={(e) => handleTargetPoint(e)}
        />
      </div>
      <div className='titleDiv'>{mls('Label')}</div>

      <input
        type='text'
        className='primaryInput'
        value={labelInput}
        // value={elm.label}
        // onChange={(e) => handleChange(e, 'label')}
        onChange={(e) => handleLabel(e)}
      />
      <div className='primaryButtonFlexDiv'>
        <button
          onClick={() => handleChange(targetPointInput, 'targetPoint')}
          className='kpiTargetPointsRemove primaryButtonSmall saveButtonDiv'
        >
          <span>
            <MdDoneOutline />
          </span>
          &nbsp;{mls('Done')}
        </button>
        <button
          onClick={() => deleteTarget()}
          className='kpiTargetPointsRemove primaryButtonSmall removeButton'
        >
          <span>
            <RiDeleteBin5Line />
          </span>
          &nbsp;{mls('Remove')}
        </button>
      </div>
    </ClosableWhiteDiv>
  );
};
