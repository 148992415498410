import introJs from 'intro.js';
import { selectQuery } from './stepTemplate';

const useStep4 = () => {
  // const step2 = useStep2();

  return (handleCompleteTutorial) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: selectQuery('.introjs-AIFilter', 0),
          title: 'Filters',
          intro: 'Dig deep with advanced filters of chart.',
          position: 'right',
        },
        {
          element: selectQuery('.introjs-AIPin', 0),
          title: 'Add to Dashboard',
          intro: 'Add chart to dashboard to view it again.',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-AIChartSettings', 0),
          title: 'Chart Settings',
          intro: 'Change the behavious of charts using our easy to use Charts settings.',
          position: 'left',
        },
        {
          element: selectQuery('.openTableButton', 0),
          title: 'Table Description',
          intro: 'Get insights of your table to make your queries better.',
          position: 'left',
        },
        {
          element: selectQuery('.surpriseButton', 0),
          title: 'Surprise me',
          intro:
            'Ask our AI to surprisingly give you a chart that could help your business in a big way.',
          position: 'left',
        },
        {
          element: selectQuery('.introjs-dashboard', 0),
          title: 'Dashboard',
          intro: 'Club all your similar charts in a single place to view it again.',
          position: 'right',
        },
        {
          element: selectQuery('.introjs-sheets', 0),
          title: 'Sheets',
          intro: 'Create charts from scratch or edit the charts of your dashboard.',
          position: 'right',
        },
      ],
      nextLabel: 'Next',
      prevLabel: 'Previous',
      showStepNumbers: false,
      exitOnOverlayClick: false,
      showBullets: false,
      disableInteraction: true,
    });
    // intro.onafterchange(async (targetElement) => {
    //   const step = intro._currentStep;
    // });
    intro.oncomplete(() => {
      handleCompleteTutorial();
    });

    intro.start();
  };
};

export default useStep4;
