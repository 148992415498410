import introJs from 'intro.js';
import { useHistory } from 'react-router-dom';
import getAppID from 'utils/getAppID';
import { clickOnElement, selectOption, selectQuery } from './stepTemplate';
import { useState } from 'react';
import useStep4 from './step4';


const useStep3 = () => {
  const step4 = useStep4();
  const history = useHistory();
  let appID = getAppID(history.location.pathname);
  
  return (handleCompleteTutorial) => {
    history.push(`/${appID}/supistaAI`);
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: selectQuery('.introjs-supistaAI',0),
          title: 'Supista AI',
          intro: "Talk to your data and ask queries.",
          position: 'right'
        },
        {
          element: selectQuery('.introjs-supistaAISelectTable',0),
          title: 'Select Your Table first.',
          intro: "Select which table do you want to ask queries from.",
          position: 'right'
        },

        {
          element: selectQuery('.introjs-chatbot',0),
          title: 'Ask Supista AI',
          intro: "Write the queries of your data and press enter.",
          position: 'right'
        },
        {
          element: selectQuery('.introjs-chatarea',0),
          title: 'Supista AI Magic',
          intro: "Get realtime charts and insights of your questions.",
          position: 'left'
        },
        {
          title: 'Finish',
        }
      ],
      nextLabel: 'Next',
      prevLabel: 'Previous',
      showStepNumbers: false,
      exitOnOverlayClick: false,
      showBullets: false,
      disableInteraction: true,
    });

    intro.onafterchange(() => {
      const step = intro._currentStep;
      if (step === 1) {
        // document.querySelectorAll('.introjs-supistaAISelectTable .mainTitle')[0]?.click();
        setTimeout(() => {
          document.querySelectorAll('.introjs-supistaAISelectTable .mainTitle')?.[0]?.click();
          document.querySelectorAll('.introjs-supistaAISelectTable .dropDownArray.open .dropDownItems')?.[2]?.click()
        }, 1000);
      }

      if (step === 2) {
        const input = document.querySelector('.introjs-chatBotInput');
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(input, 'How each of my products are impacting my profit?');
        const ev2 = new Event('input', { bubbles: true});
        input.dispatchEvent(ev2);
      }

      if (step === 3) {
        document.querySelectorAll('.introjs-chatbotSubmitButton')[0].click()        
      }
      
      
      if (step === 4) {
        intro.exit();
        step4(handleCompleteTutorial);
      }
    });

    intro.start();
  }
}

export default useStep3;
