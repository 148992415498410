import React, { useEffect, useState } from 'react';
import arrowDropDownIcon from '../assets/arrowDropDownIcon.svg';
import arrowIcon from '../assets/arrowIcon.svg';
import DraggableWithTitleItem from '../features/DragableThings/DraggableWithTitleItem';
// import SearchDiv from './SearchDiv/SearchDiv';
import searchIcon from '../assets/searchIcon.svg';
import { toggleColumnDetails, allDropableID } from '../data/defaultSchema';
import { columnToggleFunc } from '../utils/coloumnToggleHelper';
import { useHistory } from 'react-router-dom';
import { mls } from 'lib/multilanguagesupport';
interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
    toggleMinimizeDiv: Function;
  };
  mainPorps: any;
}
const FirstColumn = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;
  const { minimizedDiv, toggleMinimizeDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.firstColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName: toggleKeyName,
      setIsMainDivOn: setIsMainDivOn,
      setIsToggle: setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);

  if (!isToggle) {
    return <></>;
  }

  const { stateProps } = mainPorps;
  const { projectSheets, chartSearch, handleSearch } = stateProps;

  return (
    <div className={isMainDivOn ? 'firstColumn ' : 'firstColumn close'}>
      <div className='staticDiv'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls(toggleKeyName)}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>
      </div>
      <div className='serachBoxOuter'>
        <div className='searchBox'>
          <input
            type='text'
            placeholder={mls('Search')}
            value={chartSearch.search}
            onChange={handleSearch}
          />
          <img src={searchIcon} alt='' className='searchIcon' />
        </div>
        {/* <NestedDropDownObject
          object={defaultDataTypeStyles}
          dropDownValue={chartSearch.type}
          setdropDownValue={handleTypeFilter}
          swithToIcon={true}
        /> */}
      </div>
      <div className='scrollableDiv'>
        <div className='draggableWithTitleContainerDiv'>
          {React.Children.toArray(
            Object.keys(projectSheets).map((sheetKey, i) => {
              return (
                <DraggableWithTitleDivContainer
                  elem={projectSheets[sheetKey]}
                  sheetKey={sheetKey}
                  stateProps={stateProps}
                  i={i}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

interface draggableWithTitleContainerProps {
  elem: any;
  i: number;
  stateProps: any;
  sheetKey: string;
}

const DraggableWithTitleDivContainer = (
  draggableWithTitleContainerProps: draggableWithTitleContainerProps
) => {
  const { elem, i, stateProps, sheetKey } = draggableWithTitleContainerProps;
  const [isDivOpen, setisDivOpen] = useState(true);
  const { chartId } = allDropableID;
  const history = useHistory();
  const { appID } = stateProps;
  const goToSheets = (path: string) => {
    const newPath = `/${appID}/sheets/${path}`;
    history.push(newPath);
  };

  return (
    <div
      className={isDivOpen ? 'draggableWithTitleItems open' : 'draggableWithTitleItems'}
      title={mls(elem.name)}
    >
      <div className='draggableWithTitleMainTitle' onClick={() => setisDivOpen(!isDivOpen)}>
        <span onClick={() => goToSheets(elem.sheetID)}>{mls(elem.name)}</span>
        <img src={arrowDropDownIcon} alt='' />
      </div>
      <div className='draggableWithTitleItemList'>
        <DraggableWithTitleItem
          dragableDivId={chartId}
          sheetKey={sheetKey}
          stateProps={stateProps}
          itemNumber={i}
          // mainTableData={mainTableData}
        />
      </div>
    </div>
  );
};

export default FirstColumn;
