import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import MessageHandling from './messageHandling/MessageHandling';
// import AuthInit from './modules/auth/redux/AuthInit'
import Routes from './routing/Routes';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { toAbsoluteUrl } from '_metronic/helpers';

type Props = {
  basename?: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appData = {
    ...appSchema.app,
    logo: appSchema.app?.logo || toAbsoluteUrl('/media/logos/supista-logo.png'),
  };

  useEffect(() => {
    const appIcon = document.getElementById('__d3__app-logo-id') as HTMLLinkElement;
    document.title = appData.htmlTitle ? appData.htmlTitle : document.title;
    if (appIcon) {
      appIcon.href = appData.logo;
    }
  }, [appData.htmlTitle, appData.logo]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <Routes />
          <MessageHandling />
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };
