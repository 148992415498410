import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { Group } from '@visx/group';
import { Arc, Line } from '@visx/shape';
import { defaultToolTipStyling } from '../../constants/constants.js';
import { Text } from '@visx/text';
import { GlyphTriangle } from '@visx/glyph';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { compareBrightness, createColorArray, getStyling } from '../../helpers/colorHelper';
import { useThrottle } from 'lib/visx-lib/helpers/commenHelperFunction';
import { mls } from 'lib/multilanguagesupport';
import { numberTickFormat } from 'lib/visx-lib/helpers/tickFormatHelpers';
import { defaultChartWidthTypesObj } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { KpiMeterToolTip } from '../ChartToolTip/ChartToolTip';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import './partyPopper.scss';
const timeFrameKeys = {
  THISWEEK: 'THISWEEK',
  THISMONTH: 'THISMONTH',
  THISQUARTER: 'THISQUARTER',
  THISYEAR: 'THISYEAR',
};

const timeFrameCheckLimit = {
  [timeFrameKeys.THISWEEK]: 2,
  [timeFrameKeys.THISMONTH]: 3,
  [timeFrameKeys.THISQUARTER]: 10,
  [timeFrameKeys.THISYEAR]: 15,
};
const defaultData = {
  label: 'default',
  targetPoint: 1000,
  opacity: 100,
  color: '#d1d5db',
};
const darker = '#333';
const ligher = '#ddd';

interface KPIMeterTypeProps {
  chartKey: any;
  chartData: any;
  dataToPlot?: any;
  cardWidth: any;
  useChartSettingsButton: any;
  isProd: any;
  updateFilter: any;
  handleChartUpdates: any;
  isUpdateLoading: any;
  cardRef: any;
  filterSchema: any;
  chartHeight: any;
  desc: any;
}
const defaultKpiStartingPoint = 0;
const KPIMeterType = ({
  chartData,
  dataToPlot: plotData,
  cardWidth,
  cardRef,
}: KPIMeterTypeProps) => {
  const { newTargets, minPoint, total } = useMemo(
    () =>
      getGroups({
        targets: chartData?.targetPoints?.targets,
        kpiStartingPoint: chartData?.targetPoints?.kpiStartingPoint ?? defaultKpiStartingPoint,
        maxGoalAchievePercentage: chartData?.targetPoints?.maxGoalAchievePercentage,
      }),
    [chartData?.targetPoints]
  );
  const targets = chartData?.targetPoints?.targets;
  const kpiStartingPoint = chartData?.targetPoints?.kpiStartingPoint ?? defaultKpiStartingPoint;

  const isOnFourth = cardWidth === defaultChartWidthTypesObj.ONEFOURTH.key ? true : false;

  const title = chartData?.targetPoints?.title;
  const targetFontStyle = chartData?.targetPoints?.targetFontStyle;
  const kpiFormatter = chartData?.targetPoints?.formatter;

  const divDimensions = useDivDimensions(cardRef);
  const width: number = isOnFourth
    ? (divDimensions.width || 0) <= 250
      ? divDimensions.width || 0
      : 250
    : (divDimensions.width || 0) <= 700
    ? divDimensions.width || 0
    : 700;

  const dataToPlot: any[] = useMemo(() => plotData?.plotData || [], [plotData]);
  const meter = targets[targets.length - 1] ?? {};

  const getNewGoal = ({ goal, kpiStartingPoint }: { goal: any; kpiStartingPoint: number }) => {
    const numberGoal = Number(goal);
    const numberKpiStartingPoint = Number(kpiStartingPoint);
    if (isNaN(numberGoal)) return goal;
    // New
    if (numberGoal <= numberKpiStartingPoint) {
      return numberKpiStartingPoint;
    }
    // New
    return numberGoal;
  };
  const newGoal = getNewGoal({ goal: meter?.targetPoint, kpiStartingPoint });

  const valueData: any = Object.values(dataToPlot?.[0]?.data[0].y[0] || {})[0];
  const value = valueData?.data;

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const [showPartyPopper, setshowPartyPopper] = useState(false);

  const [toolTipTargetPoint, setToolTipTargetPoint] = useState({ label: '', targetPoint: '' });
  const toolTipTargetPointThrottle = useThrottle(setToolTipTargetPoint, 500);

  const needleAngleCheck = () => {
    const max = 175;
    if (value >= total) {
      return max;
    }
    if (value <= minPoint) {
      return 0;
    }
    if ((180 * (value - minPoint)) / total >= max) {
      return max;
    }
    return (180 * (value - minPoint)) / total;
  };
  const needleAngle = needleAngleCheck();
  const getValueAngle = () => {
    const valueAngle = (180 * (Number(newGoal) - minPoint)) / total;
    const maxGoalAchievePercentage = chartData?.targetPoints?.maxGoalAchievePercentage ?? 9;
    const defaultValue = 180 - (180 / 100) * maxGoalAchievePercentage;
    const newValueAngle = isNaN(valueAngle) ? defaultValue : valueAngle;
    return newValueAngle;
  };
  const valueAngle = getValueAngle();

  const kpiStartingPointAngle = (180 * (Number(kpiStartingPoint) - minPoint)) / total;

  const outerRadius = width / 3;
  const arcWidth = Math.max(outerRadius / 5, 20);
  const innerRadius = outerRadius - arcWidth;
  const bottomTitleWidth = isOnFourth ? width - 150 : width - 50;

  // const bottomTitleWidth = outerRadius >= 100 ? (outerRadius * 3) / 4 : (outerRadius * 5) / 4;
  const bottomTitleFontSizeMulti = bottomTitleWidth >= 124 ? 2 : 1.5;

  const handleMouseOver = useCallback(
    (event, targetPoint) => {
      // const { x, y }: any = localPoint(event);
      const x = 5;
      const y = 5;
      if (targetPoint) {
        toolTipTargetPointThrottle(targetPoint);
      }
      showTooltip({
        tooltipData: true,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip]
  );

  const defaultColor = meter?.color ?? defaultData.color;
  const extraSemiArcColor = createColorArray(5, defaultColor)[4];
  const isDarker = compareBrightness(extraSemiArcColor);

  const getInnerCircleColor = useMemo(() => {
    let finalTarget = targets[0] ?? {};

    targets?.map((targetElem: any, index: number) => {
      if (Number(targetElem?.targetPoint) >= value) {
      } else {
        finalTarget = targets[index + 1] ?? {
          ...defaultData,
          color: extraSemiArcColor,
          opacity: meter.opacity ?? 100,
        };
      }
    });
    return finalTarget;
  }, [targets]);
  const timeframeColumn = chartData?.timeframeColumn;

  const kpiProbability = useMemo(() => {
    return kpiProbabilityFunction({
      type: chartData?.targetPoints?.kpiTimeframe,
      goal: Number(newGoal),
      currentValue: Number(value),
      timeframeColumn,
    });
  }, [chartData?.targetPoints?.kpiTimeframe, newGoal, value]);
  useEffect(() => {
    if (kpiProbability?.probability?.precentageData?.goalAchieved) {
      setshowPartyPopper(true);
    }
    const timer = setTimeout(() => {
      setshowPartyPopper(false);
    }, 4700);
    return () => clearTimeout(timer);
  }, [kpiProbability?.probability?.precentageData?.goalAchieved]);
  if (targets.length === 0) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('No data to show with the current settings. Please use another settings!')}
      </div>
    );
  }

  const kpiChartheight = isOnFourth ? 40 + width / 3 : 60 + width / 3;

  //  if (cardWidth === defaultChartWidthTypesObj.ONEFOURTH.key) {
  //    return true;
  //  }
  const styless: any = {
    textAlign: 'center',
    width: width,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <svg
          ref={containerRef}
          width={width}
          height={kpiChartheight}
          transform={isOnFourth ? ' scale(1.25)' : ''}
          onMouseMove={(e) => handleMouseOver(e, { label: '', targetPoint: '' })}
          onMouseLeave={hideTooltip}
        >
          <Group
            transform={`translate(${width / 2},${
              isOnFourth ? outerRadius + 15 : outerRadius + 30
            })`}
          >
            {/* ------------- ------------- Sub Arcs ------------- ------------- */}
            {newTargets?.map((targetElem: any, index: number) => {
              const isDarker = compareBrightness(targetElem.color);
              const { startAngle, endAngle, startTargetPointAngle, endTargetPointAngle } =
                getTargetToAngle({
                  startTarget: targetElem.startTarget,
                  endTarget: targetElem.endTarget,
                  minPoint,
                  total,
                  maxGoalAchievePercentage: chartData?.targetPoints?.maxGoalAchievePercentage,
                });
              const titleFormStart = targetElem.titleFormStart;
              const textAngle = titleFormStart ? startTargetPointAngle : endTargetPointAngle;
              return (
                <React.Fragment key={index}>
                  <Arc
                    startAngle={startAngle}
                    endAngle={endAngle}
                    outerRadius={outerRadius}
                    innerRadius={innerRadius}
                    cornerRadius={0}
                    opacity={targetElem.opacity / 100}
                    fill={targetElem.color}
                    // onMouseMove={(e) => handleMouseOver(e, targetElem)}
                    // onMouseLeave={hideTooltip}
                    strokeWidth={2}
                    stroke={'#d1d5db'}
                  />
                  {[...Array(30)].map((x, i) => {
                    const angle = i * 6;
                    const lineLength = 4;
                    if (startTargetPointAngle >= angle) {
                      return <></>;
                    }
                    if (endTargetPointAngle <= angle) {
                      return <></>;
                    }
                    return (
                      <>
                        <Line
                          from={{
                            x:
                              -Math.cos(angle * (Math.PI / 180)) *
                              (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                            y:
                              -Math.sin(angle * (Math.PI / 180)) *
                              (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                          }}
                          to={{
                            x:
                              -Math.cos(angle * (Math.PI / 180)) *
                              (outerRadius - (outerRadius / 100) * 0),
                            y:
                              -Math.sin(angle * (Math.PI / 180)) *
                              (outerRadius - (outerRadius / 100) * 0),
                          }}
                          // stroke={defaultData.color}
                          // stroke={targetElem.color}
                          stroke={isDarker ? darker : ligher}
                          opacity={0.5}
                          strokeWidth={2}
                          pointerEvents='none'
                          // strokeDasharray='5,2'
                        />
                      </>
                    );
                  })}
                  <Text
                    x={
                      -Math.cos(textAngle * (Math.PI / 180)) *
                      (outerRadius - (outerRadius / 100) * -3)
                    }
                    y={
                      -Math.sin(textAngle * (Math.PI / 180)) *
                      (outerRadius - (outerRadius / 100) * -3)
                    }
                    textAnchor={textAngle >= 90 ? 'start' : 'end'}
                    fill='#9ca3af'
                    style={{
                      ...getStyling({
                        ...targetFontStyle,
                      }),
                      fontSize: isOnFourth ? `${0.7}rem` : ``,
                    }}
                    verticalAnchor='end'
                  >
                    {targetElem.label}
                  </Text>
                </React.Fragment>
              );
            })}

            <Arc
              endAngle={90 * (Math.PI / 180)}
              startAngle={-90 * (Math.PI / 180) + valueAngle * (Math.PI / 180)}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              cornerRadius={0}
              fill={extraSemiArcColor}
              opacity={(meter?.opacity ?? 100) / 100}
              // onMouseMove={(e) => handleMouseOver(e, { label: '', targetPoint: '' })}
              // onMouseLeave={hideTooltip}
              strokeWidth={2}
              stroke={'#d1d5db'}
            />
            <Line
              from={{
                x:
                  -Math.cos(valueAngle * (Math.PI / 180)) *
                  (outerRadius - arcWidth - outerRadius / 100),
                y:
                  -Math.sin(valueAngle * (Math.PI / 180)) *
                  (outerRadius - arcWidth - outerRadius / 100),
              }}
              to={{
                x:
                  -Math.cos(valueAngle * (Math.PI / 180)) * (outerRadius - (outerRadius / 100) * 0),
                y:
                  -Math.sin(valueAngle * (Math.PI / 180)) * (outerRadius - (outerRadius / 100) * 0),
              }}
              stroke={'#000000'}
              // stroke={'#000000'}
              strokeWidth={3}
              pointerEvents='none'
              // strokeDasharray='5,2'
            />
            <Text
              x={
                -Math.cos(kpiStartingPointAngle * (Math.PI / 180)) *
                (outerRadius - (outerRadius / 100) * -3)
              }
              y={
                -Math.sin(kpiStartingPointAngle * (Math.PI / 180)) *
                (outerRadius - (outerRadius / 100) * -3)
              }
              textAnchor={kpiStartingPointAngle >= 90 ? 'start' : 'end'}
              fill='#9ca3af'
              // fontSize={true ? `${60}rem` : `${(1 * targetElem.fontHeight) / 10}rem`}
              // fontSize={true ? `${60}rem` : `${(1 * targetElem.fontHeight) / 10}rem`}
              style={{
                ...getStyling({
                  ...targetFontStyle,
                }),
                fontSize: isOnFourth ? `${0.7}rem` : ``,
              }}
              verticalAnchor='end'
            >
              {kpiStartingPoint}
            </Text>
            {[...Array(30)].map((x, i) => {
              const angle = i * 6;
              const lineLength = 4;
              if (valueAngle >= angle) {
                return <></>;
              }
              if (180 <= angle) {
                return <></>;
              }

              return (
                <>
                  <Line
                    from={{
                      x:
                        -Math.cos(angle * (Math.PI / 180)) *
                        (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                      y:
                        -Math.sin(angle * (Math.PI / 180)) *
                        (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                    }}
                    to={{
                      x:
                        -Math.cos(angle * (Math.PI / 180)) *
                        (outerRadius - (outerRadius / 100) * 0),
                      y:
                        -Math.sin(angle * (Math.PI / 180)) *
                        (outerRadius - (outerRadius / 100) * 0),
                    }}
                    stroke={isDarker ? darker : ligher}
                    opacity={0.5}
                    strokeWidth={2}
                    pointerEvents='none'
                    // strokeDasharray='5,2'
                  />
                </>
              );
            })}

            {/* ------------- ------------- Needle ------------- ------------- */}

            <GlyphTriangle
              // style={{ transform: 'scale(5)' }}
              left={
                -Math.cos(needleAngle * (Math.PI / 180)) *
                (outerRadius - 5 - (outerRadius / 100) * 70)
              }
              top={
                -Math.sin(needleAngle * (Math.PI / 180)) *
                (outerRadius - 5 - (outerRadius / 100) * 70)
              }
              stroke={'#000000'}
              fill={'#000000'}
              transform={`rotate(${needleAngle - 90}) scale(${outerRadius / 150}, ${
                outerRadius / 12
              })`}
            />

            {/* ------------- ------------- Center Circle ------------- ------------- */}

            <Arc
              // startAngle={-90 * (Math.PI / 180)}
              startAngle={0}
              // endAngle={90 * (Math.PI / 180)}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 2.5}
              innerRadius={0}
              cornerRadius={0}
              fill={'#000000'}
            />

            <Arc
              startAngle={0}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 3}
              innerRadius={0}
              cornerRadius={0}
              fill={'#ffffff'}
            />
            <Arc
              startAngle={0}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 3}
              innerRadius={0}
              cornerRadius={0}
              fill={getInnerCircleColor.color}
              opacity={getInnerCircleColor.opacity / 100}
            />
            {/* {minPoint >= 0 ? (
              <Text
                x={outerRadius >= 115 ? -outerRadius + 20 : -outerRadius + 10}
                textAnchor='middle'
                y={10 * bottomTitleFontSizeMulti - 6}
                fill='#9ca3af'
                // fontSize={10 * bottomTitleFontSizeMulti}
                style={{
                  ...getStyling(targetFontStyle),
                  fontSize: isOnFourth ? `${0.7}rem` : ``,
                  color: '#9ca3af',
                }}
              >
                {minPoint}
              </Text>
            ) : null} */}
          </Group>
        </svg>
        {tooltipOpen && tooltipData && timeframeColumn && isOnFourth && (
          <KpiMeterToolTip
            defaultToolTipStyling={defaultToolTipStyling}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            TooltipInPortal={TooltipInPortal}
            tooltipData={tooltipData}
            kpiProbability={kpiProbability}
            backgroundColor={defaultToolTipStyling.backgroundColor}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            ...getStyling({
              ...title,
              fontHeight: Number(title.fontHeight) * bottomTitleFontSizeMulti,
            }),
            ...styless,
          }}
        >
          {/* <b>Target Achieved</b> */}
          {`${title.valuePrefix} ${numberFormatter({ type: kpiFormatter, value: value })} ${
            title.valuePostfix
          } `}
        </div>
      </div>
      {timeframeColumn && !isOnFourth ? (
        <>
          {kpiProbability?.daysLeft ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center', width: width }}>
                <b>
                  {kpiProbability?.daysLeft} {mls('Days')}
                </b>{' '}
                {mls('to go')}
              </div>
            </div>
          ) : null}
          {kpiProbability?.probability?.enoughDays ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {kpiProbability?.probability?.precentageData?.goalAchieved ? (
                <div style={{ textAlign: 'center', width: width, color: '#05e105' }}>
                  <b>{mls('Target Achieved')}</b>
                </div>
              ) : (
                <div style={{ textAlign: 'center', width: width }}>
                  {mls('Target Achieving Probability')}{' '}
                  <b>
                    ({numberTickFormat(kpiProbability?.probability?.precentageData?.precentage)}%)
                  </b>
                </div>
              )}
            </div>
          ) : null}
        </>
      ) : null}

      <div
        className='partyPopperDiv'
        style={{ display: 'flex', justifyContent: 'space-evenly', position: 'relative' }}
      >
        <PartyPoppper showPartyPopper={showPartyPopper} />
        <PartyPoppper showPartyPopper={showPartyPopper} />
      </div>
    </>
  );
};

export default KPIMeterType;

const PartyPoppper = ({ showPartyPopper }: { showPartyPopper: boolean }) => {
  return (
    <div className={showPartyPopper ? 'PartyPopperContainer' : 'PartyPopperContainer hide'}>
      <div className='PartyPoppers'>
        {React.Children.toArray(
          [...Array(100)].map((elm) => {
            return (
              <>
                <i />
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
interface kpiProbabilityFunctionProps {
  type: string;
  startValue?: number;
  goal: number;
  currentValue: number;
  timeframeColumn: string;
}
const kpiProbabilityFunction = ({
  type,
  startValue = 0,
  goal,
  currentValue,
  timeframeColumn,
}: kpiProbabilityFunctionProps) => {
  if (!timeframeColumn) {
    return;
  }
  const daysData = daysLeftInWeekMonthQuarterYear();
  const checkProbablity = ({ goal, currentValue }: calculateProbabilityAsPercentageProps) => {
    if (timeFrameCheckLimit[type] <= daysData[type].daysPassed) {
      const probability: {
        goalAchieved: boolean;
        precentage: number;
      } = calculateProbabilityAsPercentage({
        startValue,
        goal,
        currentValue,
        daysLeft: daysData[type].daysLeft,
        daysPassed: daysData[type].daysPassed,
      });
      return { enoughDays: true, precentageData: probability };
    }
    return { enoughDays: false };
  };
  const finalProbability = checkProbablity({
    goal,
    currentValue,
    daysLeft: daysData[type].daysLeft,
    daysPassed: daysData[type].daysPassed,
    startValue,
  });
  return { daysLeft: daysData[type].daysLeft, probability: finalProbability };
};

const daysLeftInWeekMonthQuarterYear = () => {
  const today = new Date();

  // add a day for test
  // today.setDate(today.getDate() + 100);

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  // Calculate the last day of the current quarter
  const currentQuarter = Math.floor(currentMonth / 3);
  const firstMonthOfQuarter = currentQuarter * 3;
  const lastMonthOfQuarter = (currentQuarter + 1) * 3 - 1;
  const lastDayOfQuarter = new Date(currentYear, lastMonthOfQuarter + 1, 0);

  // Calculate the first day of the current week (resetting to Monday)
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)); // Reset to Monday

  // Calculate the number of days passed in the current week
  const daysPassedInWeek = today.getDay() - 1; // Days from Sunday to today

  // Calculate the number of days left in the current week
  const daysLeftInWeek = 7 - ((today.getDay() + 6) % 7); // Reset to Monday

  // Calculate the number of days passed in the current month
  const daysPassedInMonth = today.getDate() - 1; // Days from 1st to yesterday

  // Calculate the number of days left in the month
  const daysLeftInMonth = lastDayOfMonth.getDate() - today.getDate() + 1;

  // Calculate the number of days passed in the current quarter
  const daysPassedInQuarter = today.getDate() - 1 - (firstMonthOfQuarter - currentMonth) * 30; // Approximation of days in a quarter

  // Calculate the number of days left in the quarter including the current day
  const daysLeftInQuarter = Math.ceil((+lastDayOfQuarter - +today) / (1000 * 60 * 60 * 24)) + 1;

  // Calculate the days passed in the year, considering the current date
  const firstDayOfYear = new Date(currentYear, 0, 1);
  const daysPassedInYear = Math.floor((+today - +firstDayOfYear) / (1000 * 60 * 60 * 24));

  // Calculate the days left in the year, considering the current date
  const endOfYear = new Date(currentYear, 11, 31, 23, 59, 59, 999); // Set to the last millisecond of the year
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysLeftInYear = Math.ceil((endOfYear.getTime() - today.getTime()) / millisecondsInADay);

  return {
    [timeFrameKeys.THISWEEK]: {
      daysLeft: daysLeftInWeek,
      daysPassed: daysPassedInWeek,
    },
    [timeFrameKeys.THISMONTH]: {
      daysLeft: daysLeftInMonth,
      daysPassed: daysPassedInMonth,
    },
    [timeFrameKeys.THISQUARTER]: {
      daysLeft: daysLeftInQuarter,
      daysPassed: daysPassedInQuarter,
    },
    [timeFrameKeys.THISYEAR]: {
      daysLeft: daysLeftInYear,
      daysPassed: daysPassedInYear,
    },
  };
};
interface calculateProbabilityAsPercentageProps {
  startValue: number;
  goal: number;
  currentValue: number;
  daysLeft: number;
  daysPassed: number;
}
const calculateProbabilityAsPercentage = ({
  startValue,
  goal,
  currentValue,
  daysLeft,
  daysPassed,
}: calculateProbabilityAsPercentageProps) => {
  // Ensure that currentValue is not greater than the goal
  if (currentValue >= goal) {
    return { precentage: 100, goalAchieved: true }; // Goal already reached
  }

  // Calculate the rate of progress per day
  const progressPerDay: number = currentValue / daysPassed;

  const progressInLeftDays = daysLeft * progressPerDay + currentValue;

  const probability: number = calculatePercentage(startValue, goal, progressInLeftDays);
  return { precentage: probability, goalAchieved: false };
};

function calculatePercentage(startValue: number, maxValue: number, currentValue: number): number {
  if (currentValue <= startValue) {
    return 0; // Below or equal to the start value
  } else if (currentValue >= maxValue) {
    return 95; // Equal to or above the max value
  } else {
    const percentage: number = ((currentValue - startValue) / (maxValue - startValue)) * 95;
    return percentage;
  }
}
interface getGroupsProps {
  kpiStartingPoint: number;
  targets: { [key: string]: any }[];
  maxGoalAchievePercentage: number;
}
const getGroups = ({ targets, kpiStartingPoint, maxGoalAchievePercentage }: getGroupsProps) => {
  const maxValue = () => {
    const targetArray = targets.map((e: any) => Number(e?.targetPoint));
    const targetMin = Math.min(...targetArray);
    const targetMax = Math.max(...targetArray);
    return {
      minPoint: kpiStartingPoint >= targetMin ? targetMin : kpiStartingPoint,
      maxPoint: kpiStartingPoint <= targetMax ? targetMax : kpiStartingPoint,
    };
  };
  const { minPoint, maxPoint } = maxValue();
  interface getStartAndEndTargetProps {
    targetPoint: number;
    index: number;
    targets: { [key: string]: any }[];
  }
  const getStartAndEndTarget = ({ targetPoint, index, targets }: getStartAndEndTargetProps) => {
    const currentTargetPoint = Number(targetPoint);

    switch (true) {
      case kpiStartingPoint >= currentTargetPoint: {
        let startTarget;
        let endTarget;
        let titleFormStart;
        startTarget = currentTargetPoint;
        titleFormStart = true;
        if (targets[index + 1]) {
          const nextTargetPoint = Number(targets[index + 1].targetPoint);
          endTarget = kpiStartingPoint >= nextTargetPoint ? nextTargetPoint : kpiStartingPoint;
        } else {
          endTarget = kpiStartingPoint;
        }
        return { startTarget, endTarget, titleFormStart };
      }

      case currentTargetPoint >= kpiStartingPoint: {
        let startTarget;
        let endTarget;
        let titleFormStart;
        titleFormStart = false;

        endTarget = currentTargetPoint;

        if (targets[index - 1]) {
          const prevTargetPoint = Number(targets[index - 1].targetPoint);
          startTarget = prevTargetPoint <= kpiStartingPoint ? kpiStartingPoint : prevTargetPoint;
        } else {
          startTarget = kpiStartingPoint;
        }
        return { startTarget, endTarget, titleFormStart };
      }
      default:
        return { currentTargetPoint: 0, endTarget: currentTargetPoint };
    }
  };

  const finalTarget = targets.map((targetElem, index) => {
    const { startTarget, endTarget, titleFormStart } = getStartAndEndTarget({
      targetPoint: targetElem.targetPoint,
      index,
      targets,
    });
    return { ...targetElem, startTarget, endTarget, titleFormStart };
  });
  const normaltotal = maxPoint - minPoint;
  const finalTotal = normaltotal * (1 + maxGoalAchievePercentage / 100);
  return { newTargets: finalTarget, minPoint, maxPoint, total: finalTotal };
};

const getTargetToAngle = ({
  startTarget,
  endTarget,
  minPoint,
  total,
  maxGoalAchievePercentage,
}: {
  startTarget: number;
  endTarget: number;
  minPoint: number;
  total: number;
  maxGoalAchievePercentage: number;
}) => {
  const calculatePercentageInRange = ({
    value,
    min,
    range,
    start,
    maxGoalAchievePercentage,
  }: {
    value: number;
    min: number;
    range: number;
    maxGoalAchievePercentage: number;
    start: boolean;
  }) => {
    if (range === 0) {
      if (start) {
        return 0;
      }
      return 100 - maxGoalAchievePercentage;
    }
    const adjustedValue = value - min;
    return (adjustedValue / range) * 100;
  };
  if (total === 0) {
  }
  const startTargetPercentage = calculatePercentageInRange({
    value: startTarget,
    min: minPoint,
    range: total,
    start: true,
    maxGoalAchievePercentage,
  });

  const endTargetPercentage = calculatePercentageInRange({
    value: endTarget,
    min: minPoint,
    range: total,
    start: false,
    maxGoalAchievePercentage,
  });
  const startTargetPointAngle = (180 * startTargetPercentage) / 100;
  const endTargetPointAngle = (180 * endTargetPercentage) / 100;

  const startAngle = -90 * (Math.PI / 180) + startTargetPointAngle * (Math.PI / 180);

  const endAngle = -90 * (Math.PI / 180) + endTargetPointAngle * (Math.PI / 180);
  return { startAngle, endAngle, startTargetPointAngle, endTargetPointAngle };
};
