import React from 'react';
import './copyColumnToClipBoard.scss';
import { MdContentCopy } from 'react-icons/md';
import {
  copyColumnToClipBoard,
  copyToClipBoard,
} from 'lib/data-connectors/dependencies/utilFunctions';
interface CopyColumnToClipBoardProps {
  children: React.ReactNode;
  columnName: string;
  tableIndex?: number;
}

const CopyColumnToClipBoard = ({
  children,
  columnName,
  tableIndex = 0,
}: CopyColumnToClipBoardProps) => {
  return (
    <div
      className='copyColumnToClipBoard'
      onClick={() => copyColumnToClipBoard({ copyText: columnName, tableIndex })}
    >
      {children}
      <span className='copyColumnToClipBoardIcon'>
        <MdContentCopy />
      </span>
    </div>
  );
};
interface CopyToClipBoardProps {
  children: React.ReactNode;
  text: string;
}
export const CopyToClipBoard = ({ children, text }: CopyToClipBoardProps) => {
  return (
    <div className='copyColumnToClipBoard' onClick={() => copyToClipBoard({ text })}>
      {children}
      <span className='copyColumnToClipBoardIcon'>
        <MdContentCopy />
      </span>
    </div>
  );
};

export default CopyColumnToClipBoard;
