import arrowIcon from '../assets/arrowIcon.svg';

// import SelectChart from '../../features/SelectChart/SelectChart';
// import DropDown from '../../features/DropDown/DropDown';
// import { defaultChartWidthTypes } from '../../data/defaultChartSchema';
// import ToggleButtonDiv from '../../components/ToggleButtonDiv';
import { useEffect, useRef } from 'react';
import { mls } from 'lib/multilanguagesupport';
import { toggleColumnDetails } from '../data/defaultSchema';
import ToggleButtonDiv from './ToggleButtonDiv';

const WithStyleToggle = ({ toggleProps, buddleProps, children }) => {
  const { toggleMinimizeDiv } = toggleProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.secondColumn;
  const { settingToggleState, setSettingToggleState } = buddleProps;
  const scrollableDivRef = useRef(null);

  useEffect(() => {
    scrollableDivRef?.current?.scrollTo(0, 0);
  }, [settingToggleState]);
  return (
    <>
      <div className='staticDiv'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls('Build Charts')}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>

        <div className='toggleButtonContainer'>
          <ToggleButtonDiv
            toggleState={settingToggleState}
            setToggleState={setSettingToggleState}
          />
        </div>
      </div>

      <div className={`scrollableDiv ${settingToggleState}`} ref={scrollableDivRef}>
        {children}
      </div>
    </>
  );
};

export default WithStyleToggle;
