import { useDashSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
import useAppState from 'lib/reusable-components/reusableUtils/commons/useAppState';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { KTSVG } from '_metronic/helpers';
import DashSettings from './DashSettings';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import Sorting from 'lib/data-connectors/dependencies/Sorting';

function Dash(params) {
  const dashSettingsState = useAppState('dashSettings');
  const dashSettingsButton = useDashSettingsButton();
  const history = useHistory();
  const { appID } = useParams();
  const toastMsg = useMessageHandling();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];
  const isAccess =
    accessType.includes('ALLOWED_ALL') ||
    accessType.includes('ALL_CREATOR') ||
    accessType.includes('CHART_CREATOR');

  // const dashSchema = analyticsSchema?.appDash || {};
  const [dashSchema, setDashSchema] = useState(analyticsSchema?.appDash || {});
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const handleButton = (dashID) => {
    const dashSch = dashSchema[dashID];
    if (!dashSch || dashSch.sections.length === 0) {
      toastMsg(reduxConstants.config.APP_STATUS.ERROR_TOAST, mls('Please add sections!'), 5, true);
    } else history.push(`/${appID}/dashboard/${dashID}/`);
  };

  const getNoOfCharts = (dashID) => {
    const dash = dashSchema[dashID] || {};
    const sections = dash.sections || [];
    let count = 0;
    sections.forEach((sec) => {
      count += sec?.charts?.length || 0;
    });
    return count;
  };
  if (dashSchema[dashSettingsState.dashID] == null && dashSettingsState.dashID) {
    return (
      <>
        {isAccess && dashSettingsState.dashID && (
          <DashSettings
            appID={appID}
            dashID={dashSettingsState.dashID}
            isNew={dashSchema[dashSettingsState.dashID] == null}
            dashSchema={dashSchema[dashSettingsState.dashID] || {}}
          />
        )}
      </>
    );
  }

  // showing an error message if there is no dashboard added.
  if (Object.keys(analyticsSchema?.appDash || {}).length === 0)
    return <NoAssetScreen assetType='Dashboard' />;

  return (
    <>
      <div className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white rounded'>
        <Sorting
          setObjectOfObjects={setDashSchema}
          dataInRedux={analyticsSchema?.appDash}
          keyProperty={'dashID'}
          searchBy={'name'}
          updateSearchKey={setSearchKeyWord}
        />
      </div>
      <div className='row g-6 g-xl-9'>
        {Object.keys(dashSchema).length === 0 ? (
          <div class='alert alert-warning m-auto mt-10' role='alert' style={{ width: '98%' }}>
            {`${mls('No Dashboard found for the keyword:')} ${searchKeyWord}.`}
          </div>
        ) : (
          Object.keys(dashSchema).map((dashID, dashIndex) => (
            <div className='col-lg-6 col-xxl-4' key={`${dashID}-${dashIndex}`}>
              <div className='card card-supista'>
                <div className='card-body'>
                  {/* Project Title */}
                  <div className='d-flex justify-content-between align-items-center'>
                    <Link to={'#'} onClick={(e) => e.preventDefault()}>
                      <div className='fs-4 fw-bolder' onClick={() => handleButton(dashID)}>
                        {mls(dashSchema[dashID].name)}
                      </div>
                    </Link>
                    <button
                      type='button'
                      className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                      onClick={() => dashSettingsButton(dashID)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen024.svg'
                        className='svg-icon-3 svg-icon-primary'
                      />
                    </button>
                  </div>
                  <div
                    className={
                      'fs-5 fw-bold text-gray-400 ' +
                      (dashSchema[dashID].desc ? 'text-truncate mb-2' : 'mb-9')
                    }
                    onClick={() => handleButton(dashID)}
                    style={{ cursor: 'pointer' }}
                  >
                    {mls(dashSchema[dashID].desc)}
                  </div>
                  {/* </Link> */}
                  {/* Project Icon */}
                  <div className='d-flex flex-wrap'>
                    <div className='d-flex flex-center h-80px w-80px me-15'>
                      <div className='symbol symbol-75px symbol-circle'>
                        <span
                          className={`symbol-label bg-light-primary text-primary fs-2hx fw-bolder`}
                        >
                          {dashSchema[dashID]?.name?.charAt(0) || ''}
                        </span>
                      </div>
                    </div>
                    {/* Project Details */}
                    <div className='d-flex flex-column justify-content-end flex-row-fluid pe-2 mb-0'>
                      <div className='d-flex fs-6 fw-bold align-items-center mb-3'>
                        <div className='bullet bg-primary me-3'></div>
                        <div className='text-gray-400'>{mls('Total Sections')}</div>
                        <div className='ms-auto fw-bolder text-gray-700'>
                          {(dashSchema[dashID]?.sections || []).length}
                        </div>
                      </div>
                      <div className='d-flex fs-6 fw-bold align-items-center  mb-3'>
                        <div className='bullet bg-success me-3'></div>
                        <div className='text-gray-400'>{mls('Total Charts')}</div>
                        <div className='ms-auto fw-bolder text-gray-700'>
                          {getNoOfCharts(dashID)}
                        </div>
                      </div>
                      <div className='d-flex fs-6 fw-bold align-items-center mb-5 justify-content-end'>
                        {/* <Link to={getLink(project)}> */}
                        <button
                          type='button'
                          className='btn btn-light-primary fw-bolder btn-sm me-n3'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          onClick={() => handleButton(dashID)}
                        >
                          {mls('Go To Dashboard')}
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {isAccess && dashSettingsState.dashID && (
        <DashSettings
          appID={appID}
          dashID={dashSettingsState.dashID}
          isNew={false}
          dashSchema={dashSchema[dashSettingsState.dashID || '']}
        />
      )}
    </>
  );
}

export default Dash;
