import React, { useEffect, useState } from 'react';
import ValueDiv from '../../StyleComponents/ValueDiv';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import { updateAxisStyleLable } from '../../../utils/chartHelperFunctions';
import { defaultChartSetting } from '../../../data/defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';

// import RangeDiv from './RangeDiv';
const RadialLineAxisStyle = (props: any) => {
  const { axisStyle, setAxisStyle, defaultLable, axisData } = props;
  const [isDivOpen, setisDivOpen] = useState(axisStyle.show);
  const [isContent, setisContent] = useState(axisStyle.show);

  useEffect(() => {
    if (isDivOpen) {
      setisContent(isDivOpen);
      setisContent(isDivOpen);
      setAxisStyle({ ...axisStyle, show: true });
    } else {
      setTimeout(() => {
        setisContent(isDivOpen);
        setAxisStyle({ ...defaultChartSetting.yAxisStyle, show: false });
        updateAxisStyleLable(setAxisStyle, axisData, defaultLable);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDivOpen]);
  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      <div className='MainTitle inFlex'>
        {mls('Y-Axis')}
        <SlideSwitch isChecked={isDivOpen} setIsChecked={setisDivOpen} />
      </div>
      <div className='grayContainers'>
        {isContent && (
          <>
            <ValueDiv axisStyle={axisStyle} setAxisStyle={setAxisStyle} showLable={false} />
          </>
        )}
      </div>
    </div>
  );
};

export default RadialLineAxisStyle;
