import React from 'react';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import { allDropableID, defaultChartSetting } from '../../../data/defaultChartSchema';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import DragableAxis from '../../../components/BuildComponents/DragableAxis';
import DragableToolTip from '../../../components/BuildComponents/DragableToolTip';

import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
import BarStackLineTargetPoints from '../../../components/SpacialCasesComponents/BarStackLine/BarStackLineTargetPoints';
import BarStackLineToolTipDiv from '../../../components/SpacialCasesComponents/BarStackLine/BarStackLineToolTipDiv';
import InfoToolTip from 'lib/reusable-components/Components/ToolTip/InfoToolTip';
import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const PlotChartBuildCase = ({ stateProps }: any) => {
  const {
    isInsight,
    setIsInsight,
    setChartToolTip,
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setPlotGroupStyle,
    plotGroup,
    setPlotGroup,
    chartType,
    handleResetToolTip,
    colorAxis,
    setColorAxis,
    setColorAxisStyle,
  } = stateProps;
  const { xAxisId, yAxisId, toolTip, groupId } = allDropableID;

  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>
          <div className='MainTitle'>{mls('X-Axis')} *</div>
        </div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>

        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Group')}</div>
        <DragableAxis
          dragableDivId={groupId}
          axisData={plotGroup}
          setAxisData={setPlotGroup}
          stateProps={stateProps}
          setStyle={setPlotGroupStyle}
          defaultLable={defaultChartSetting.plotGroupStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      {chartType.chartKey === allChartKeys.BARSTACKLINE && (
        <>
          <div className='outerDiv open'>
            <div className='MainTitle'>{mls('Target Points')}</div>
            <BarStackLineTargetPoints stateProps={stateProps} />
          </div>
        </>
      )}

      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Tooltips')}</div>

        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default PlotChartBuildCase;
