import React from 'react';
import { KTSVG } from '_metronic/helpers';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import { Link, useParams } from 'react-router-dom';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import ReportSettings from './ReportSettings';
import useAppState from 'lib/reusable-components/reusableUtils/commons/useAppState';
import { useReportSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';

const ReportsUI = () => {
  const { appID } = useParams();
  const reportSettingsState = useAppState('reportSettings');
  const reportSettingsButton = useReportSettingsButton();

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const reportsSchema = analyticsSchema?.appReports || {};

  if (reportSettingsState.reportID) {
    return (
      <>
        {reportSettingsState.reportID && (
          <ReportSettings
            appID={appID}
            reportID={reportSettingsState.reportID}
            isNew={reportsSchema[reportSettingsState.reportID] == null}
            reportSchema={
              reportsSchema[reportSettingsState.reportID]
                ? reportsSchema[reportSettingsState.reportID]
                : {}
            }
          />
        )}
      </>
    );
  }

  if (Object.keys(reportsSchema).length === 0) return <NoAssetScreen assetType='Reports' />;

  const getNoOfReports = (reportID) => {
    const pages = reportsSchema[reportID]?.pages || [];
    return pages.length;
  };
  return (
    <div className='row g-6 g-xl-9'>
      {Object.keys(reportsSchema || {})
        .reverse()
        .map((reportID, reportIndex) => (
          <div className='col-lg-6 col-xxl-4' key={`${reportID}-${reportIndex}`}>
            <div className='card card-supista'>
              <div className='card-body'>
                {/* Project Title */}
                <div className='d-flex justify-content-between align-items-center'>
                  <Link to={'#'} onClick={(e) => e.preventDefault()}>
                    <div className='fs-4 fw-bolder' onClick={() => reportSettingsButton(reportID)}>
                      {mls(reportsSchema[reportID].name)}
                    </div>
                  </Link>
                  <button
                    type='button'
                    className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                    onClick={() => reportSettingsButton(reportID)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen024.svg'
                      className='svg-icon-3 svg-icon-primary'
                    />
                  </button>
                </div>
                {reportSettingsState.reportID && (
                  <ReportSettings
                    appID={appID}
                    reportID={reportSettingsState.reportID}
                    isNew={false}
                    reportSchema={reportsSchema[reportSettingsState.reportID] || {}}
                    reportsSchema={reportsSchema}
                  />
                )}
                {/* Project Description */}
                {/* <Link to={getLink(project)}> */}
                <div
                  className={
                    'fs-5 fw-bold text-gray-400 ' +
                    (reportsSchema[reportID].desc ? 'text-truncate mb-2' : 'mb-9')
                  }
                  onClick={() => reportSettingsButton(reportID)}
                  style={{ cursor: 'pointer' }}
                >
                  {mls(reportsSchema[reportID].desc)}
                </div>
                {/* </Link> */}
                {/* Project Icon */}
                <div className='d-flex flex-wrap'>
                  <div className='d-flex flex-center h-80px w-80px me-15'>
                    <div className='symbol symbol-75px symbol-circle'>
                      <span
                        className={`symbol-label bg-light-primary text-primary fs-2hx fw-bolder`}
                      >
                        {reportsSchema[reportID]?.name?.charAt(0) || ''}
                      </span>
                    </div>
                  </div>
                  {/* Project Details */}
                  <div className='d-flex flex-column justify-content-end flex-row-fluid pe-2 mb-0'>
                    {/* <div className='d-flex fs-6 fw-bold align-items-center mb-3'>
                      <div className='bullet bg-primary me-3'></div>
                      <div className='text-gray-400'>{mls('Total Sections')}</div>
                      <div className='ms-auto fw-bolder text-gray-700'>
                        {(reportsSchema[reportID]?.sections || []).length}
                      </div>
                    </div> */}
                    <div className='d-flex fs-6 fw-bold align-items-center  mb-3'>
                      <div className='bullet bg-success me-3'></div>
                      <div className='text-gray-400'>{mls('Total Reports')}</div>
                      <div className='ms-auto fw-bolder text-gray-700'>
                        {getNoOfReports(reportID)}
                      </div>
                    </div>
                    <div className='d-flex fs-6 fw-bold align-items-center mb-5 justify-content-end'>
                      {/* <Link to={getLink(project)}> */}
                      <button
                        type='button'
                        className='btn btn-light-primary fw-bolder btn-sm me-n3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => reportSettingsButton(reportID)}
                      >
                        {mls('Go To Report')}
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ReportsUI;
