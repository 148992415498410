import React from 'react';
import { allDropableID, defaultChartSetting } from '../../../data/defaultChartSchema';
import DragableAxis from '../../../components/BuildComponents/DragableAxis';
import FilterIndex from '../../../components/BuildComponents/FilterDiv/FilterIndex';
import DragableToolTip from '../../../components/BuildComponents/DragableToolTip';
import ChartInsight from '../../../components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const RadialLineBuildCase = ({ stateProps }) => {
  const {
    yAxisData,
    setYAxisData,

    setChartYAxisStyle,
    xAxisData,
    setXAxisData,
    setChartXAxisStyle,

    handleResetToolTip,
    isInsight,
    setIsInsight,
    chartType,
    toolTipAxisData,
    setToolTipAxisData,
  } = stateProps;

  const { xAxisId, yAxisId, toolTip, tooltipAxisId } = allDropableID;

  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('X-Axis')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Tooltips')}</div>

        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          allDropableID={allDropableID}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      {/* <div className='outerDiv'>
        <div className='MainTitle'>{mls('Extra Tooltip')} *</div>
        <DragableAxis
          dragableDivId={tooltipAxisId}
          axisData={toolTipAxisData}
          setAxisData={setToolTipAxisData}
          stateProps={stateProps}
          setStyle={() => {}}
          defaultLable={defaultChartSetting.yAxisStyle.label}
        />
      </div> */}
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>

        <FilterIndex stateProps={stateProps} />
      </div>
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
    </>
  );
};

export default RadialLineBuildCase;
