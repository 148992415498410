import React from 'react';
import { allDropableID, defaultChartSetting } from '../../data/defaultChartSchema';
import resetArrow from '../../assets/resetArrow.svg';
import DragableAxis from '../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../components/BuildComponents/FilterDiv/FilterIndex';
import DragableToolTip from '../../components/BuildComponents/DragableToolTip';
import EditColors from '../../components/StyleComponents/ChartDisplay/EditColors';
import ChartInsight from 'lib/chart-settings-lib/components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const PieCase = ({ stateProps }) => {
  const {
    isInsight,
    setIsInsight,
    yAxisData,
    setYAxisData,
    setChartYAxisStyle,
    xAxisData,
    setXAxisData,
    setChartXAxisStyle,
    handleResetToolTip,
    chartType,
    toolTipAxisData,
    setToolTipAxisData,
  } = stateProps;

  const { xAxisId, yAxisId, toolTip, tooltipAxisId } = allDropableID;

  return (
    <>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('X-Axis')} *</div>
        <DragableAxis
          dragableDivId={xAxisId}
          axisData={xAxisData}
          setAxisData={setXAxisData}
          stateProps={stateProps}
          setStyle={setChartXAxisStyle}
          defaultLable={defaultChartSetting.xAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls('Y-Axis')} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle inFlex'>{mls('Tooltips')}</div>
        <DragableToolTip
          dragableDivId={toolTip}
          stateProps={stateProps}
          allDropableID={allDropableID}
          handleResetToolTip={handleResetToolTip}
        />
      </div>
      {/* <div className='outerDiv'>
        <div className='MainTitle'>{mls('Extra Tooltip')} *</div>
        <DragableAxis
          dragableDivId={tooltipAxisId}
          axisData={toolTipAxisData}
          setAxisData={setToolTipAxisData}
          stateProps={stateProps}
          setStyle={() => {}}
          defaultLable={defaultChartSetting.yAxisStyle.label}
        />
      </div> */}
      <EditColors stateProps={stateProps} />
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls('Default Filters')}</div>
        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}
      </div>
      <ChartInsight
        isInsight={isInsight}
        setIsInsight={setIsInsight}
        chartKey={chartType.chartKey}
      />
      {/* <div className='outerDiv '>
        <div className='MainTitle inFlex'>
          Date hierarchy
          <SlideSwitch isChecked={isDateHierarchy} setIsChecked={setIsDateHierarchy} />
        </div>
      </div> */}
    </>
  );
};

export default PieCase;
