import React from 'react';
import ValueNumber from './ValueNumber';
import { dateTimeFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { BsArrowDownShort } from 'react-icons/bs';
import { changeNumFormat } from '../../../utils/converterFunction';
interface TwoColumnsProps {
  columnName1?: string;
  columnName2?: string;
  columnName3?: string;
  columnName4?: string;
  type?: string;
  elem: any;
  chartType: string;
  mainData: any;
  arrow?: boolean;
}
const TwoColumns = ({
  columnName2,
  columnName3,
  type,
  elem,
  chartType,
  mainData,
  arrow = false,
}: TwoColumnsProps) => {
  return (
    <>
      <div className='container-fluid  w-100 twoColumnDivContainer'>
        <div className='col-8  p-4 g-0  w-100  insight-table-column-header-left'>
          <b>{columnName2}</b>
        </div>
        <div className='col  p-4 g-0   w-100 insight-table-column-header-right '>
          <b>{columnName3}</b>
        </div>
        {Array.isArray(elem) &&
          elem.map((data: [any, any], i: number) => {
            const columnName = {
              ...(mainData?.xcolumnName ?? {}),
              ...(mainData?.ycolumnName ?? {}),
              ...(mainData?.zcolumnName ?? {}),
            };
            return (
              <>
                {type === 'LastGrowth' ? (
                  <div className='col p-4 g-0  w-100 insight-table-data-cell-left'>{data[0]}</div>
                ) : chartType === allChartKeys.PIVOTTABLE ? (
                  <div className='col-8  p-4 g-0  w-100 insight-table-data-cell-left'>
                    {Object.keys(data[0]).map((columnKey) => {
                      const dateTimeFormat = mainData?.datetimeperiodType?.[columnKey] ?? null;
                      return (
                        <>
                          <b>{mainData?.xcolumnName?.[columnKey]}:</b>{' '}
                          {dateTimeFormat
                            ? dateTimeFormatter({
                                type: { dateTimeFormat },
                                value: data[0][columnKey],
                              })
                            : data[0][columnKey]}
                          <br />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className='col-8  p-4 g-0  w-100 insight-table-data-cell-left'>
                    {mainData.chartType === allChartKeys.BARCOLOR ||
                    mainData.chartType === allChartKeys.LINECOLOR ||
                    mainData.chartType === allChartKeys.AREACOLOR ||
                    mainData.chartType === allChartKeys.HEATMAP ||
                    mainData.chartType === allChartKeys.BARHORIZONTALCOLOR ? (
                      <>
                        {React.Children.toArray(
                          Object.keys(data[0] ?? {}).map((columnKey) => {
                            const dateTimeFormat =
                              mainData?.datetimeperiodType?.[columnKey] ?? null;
                            return (
                              <>
                                <div className='col-8  p-4 g-0  w-100 insight-table-data-cell-left'>
                                  <b>{columnName?.[columnKey]}:</b>
                                  {dateTimeFormat
                                    ? dateTimeFormatter({
                                        type: { dateTimeFormat },
                                        value: data[0][columnKey],
                                      })
                                    : data[0][columnKey]}
                                </div>
                              </>
                            );
                          })
                        )}
                      </>
                    ) : (
                      <>{data[0]}</>
                    )}
                  </div>
                )}
                {type === 'LastGrowth' ? (
                  <LastGrowthDiv data={data} arrow={arrow} />
                ) : (
                  <div className='col p-4 g-0  w-100 insight-table-data-cell-right'>
                    <ValueNumber number={data[1]} arrow={arrow} />
                  </div>
                )}
              </>
            );
          })}
      </div>
    </>
  );
};

export default TwoColumns;

const LastGrowthDiv = ({ data, arrow }: { data: [any, any]; arrow: boolean }) => {
  const numberData = changeNumFormat(data[1].growth_rate, { maxDecimal: 0 });
  return (
    <div
      className='col p-4 g-0  w-100 insight-table-data-cell-right'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: !numberData?.isNull ? 'space-between' : 'center',
        flexWrap: 'wrap',
        gap: '.25rem',
      }}
    >
      <ValueNumber number={data[1].current_value} arrow={false} />
      {!numberData?.isNull ? (
        <span
          style={{
            fontSize: '8px',
            // color: '#721c24',
            color: numberData?.isNegative ? '#721c24' : '#155724',
            background: numberData?.isNegative ? '#f8d7da' : '#d4edda',
            borderRadius: '6px',
            padding: '4px',
          }}
        >
          {arrow ? (
            <>
              {numberData?.isNegative ? (
                <BsArrowDownShort style={{}} />
              ) : (
                <BsArrowDownShort style={{ rotate: '180deg' }} />
              )}
            </>
          ) : null}
          {numberData.value}%
        </span>
      ) : null}
    </div>
  );
};
