/* eslint import/prefer-default-export: 0 */
import { timeFormat } from 'd3-time-format';
import { isValidDate } from './isValidDate';
import { format } from 'd3';

export const defaultDateTickFormat = timeFormat('%m-%d');

export const categoryDateTickFormat = (e) => {
  const temp = new Date(e?.replace(/\s/g, ''));
  if (isValidDate(temp)) return `${temp.getDate()}-${temp.getMonth() + 1}-${temp.getFullYear()}`;
  return e;
};

export const numberTickFormat = (e) => {
  const normalize = (number) => {
    return number.toFixed(2);
  };
  if (e >= 1000000) {
    return e < 1 ? e : `${format('~s')(normalize(e)).replace(/G/, 'B')}n`;
  }
  return e < 1 ? e : `${format('~s')(normalize(e)).replace(/G/, 'B')}`;
};
