import React, { useEffect, useState } from 'react';
import ValueDiv from './ValueDiv';
import TitleDiv from './TitleDiv';
import Formatter from './Formatter';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import { updateAxisStyleLable } from '../../utils/chartHelperFunctions';

// import RangeDiv from './RangeDiv';
import { defaultChartSetting } from '../../data/defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';

const YAxisStyle = (props) => {
  const { axisStyle, setAxisStyle, defaultLable, axisData, chartID, chartType } = props;
  const [isDivOpen, setisDivOpen] = useState(axisStyle.show);
  const [isContent, setisContent] = useState(axisStyle.show);

  useEffect(() => {
    if (isDivOpen) {
      setisContent(isDivOpen);
      setAxisStyle({ ...axisStyle, show: true });
    } else {
      setTimeout(() => {
        setisContent(isDivOpen);
        setAxisStyle({ ...defaultChartSetting.yAxisStyle, show: false });
        updateAxisStyleLable(setAxisStyle, axisData, defaultLable);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDivOpen]);
  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      <div className='MainTitle inFlex'>
        {mls("Y-Axis")}
        <SlideSwitch isChecked={isDivOpen} setIsChecked={setisDivOpen} />
      </div>
      <div className='grayContainers'>
        {isContent && (
          <>
            <ValueDiv axisStyle={axisStyle} setAxisStyle={setAxisStyle} />
            <TitleDiv axisStyle={axisStyle} setAxisStyle={setAxisStyle} />
            <Formatter axisStyle={axisStyle} setAxisStyle={setAxisStyle} axisData={axisData} chartID={chartID} chartType={chartType} />
          </>
        )}
      </div>
    </div>
  );
};

export default YAxisStyle;
