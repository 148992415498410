export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
};
export const defaultOpacity = 70;

const mainColors = {
    primaryBlue: '#00A3FF',
  };
export const defaultLegendColorPalettes = [
    mainColors.primaryBlue,
    '#00C1FF',
    '#00D9E6',
    '#46f0f0',
    '#FFA800',
    '#50CD89',
    '#00A3FF',
    '#8950FC',
    '#1BC5BD',
    '#FFE456',
    '#0A572D',
    '#394157',
    '#FCB6BD',
    '#5F5CF1',
    '#D0B9FF',
  ];
 export const defaultTimeframe = {
    '30': {
      name: '1 Months',
      xDay: 30,
    },
    '90': {
      name: '3 Months',
      xDay: 90,
    },
    '180': {
      name: '6 Months',
      xDay: 180,
    },
    '365': {
      name: '1 Year',
      xDay: 365,
    },
    '1095': {
      name: '3 Year',
      xDay: 1095,
    },
    null: {
      name: 'ALL',
      xDay: null,
    },
  };
  export const defaultSelectedTimeframe = '180'
export const defaultFontFamily = ['Poppins', 'Times new roman'];
export const defaultFontSize = [
  {
    name: 'S',
    key: '10',
  },
  {
    name: 'M',
    key: '12',
  },
  {
    name: 'L',
    key: '14',
  },
  {
    name: 'XL',
    key: '16',
  },
];
export const defaultSelectedFontSize = defaultFontSize[1].key;

export const defaultDateKey = {
  CUSTOM: 'CUSTOM',
  LAST7DAYS: 'LAST7DAYS',
  LASTXDAYS: 'LASTXDAYS',
  defalutLastDay: 30,
  mainKey: 'period',
};
export const defaultDateArray = [
  {
    name: 'Today',
    [defaultDateKey.mainKey]: 'TODAY',
  },
  {
    name: 'Yesterday',
    [defaultDateKey.mainKey]: 'YESTERDAY',
  },
  {
    name: 'This week',
    [defaultDateKey.mainKey]: 'THISWEEK',
  },
  {
    name: 'Last 7 days',
    [defaultDateKey.mainKey]: 'LAST7DAYS',
  },
  {
    name: 'This month',
    [defaultDateKey.mainKey]: 'THISMONTH',
  },
  {
    name: 'Last 30 days',
    [defaultDateKey.mainKey]: 'LAST30Days',
  },
  {
    name: 'This quarter',
    [defaultDateKey.mainKey]: 'THISQUARTER',
  },
  {
    name: 'Last X day',
    [defaultDateKey.mainKey]: defaultDateKey.LASTXDAYS,
  },
  {
    name: 'This year',
    [defaultDateKey.mainKey]: 'THISYEAR',
  },
  {
    name: 'Custom Date',
    [defaultDateKey.mainKey]: defaultDateKey.CUSTOM,
  },
];
export const defaultGridLineTypes = ['dashed', 'solid', 'dotted'];

export const  defaultGridLines= {
  vertical: {
    show: true,
    type: defaultGridLineTypes[1],
    width: 1,
    color: '#bcbec8',
    opacity: defaultOpacity,
    count: 5,
  },
  horizontal: {
    show: true,
    type: defaultGridLineTypes[1],
    width: 1,
    color: '#bcbec8',
    opacity: defaultOpacity,
    count: 5,
  },
}