import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import { toast } from 'react-toastify';
import reduxConstants from '../reusableUtils/redux/reduxConstants';
import { create } from '../reusableUtils/redux/stateSlice';
import { useDispatch } from 'react-redux';

export const getKpiAllSchema = async ({ appID }: { appID: string }) => {
  const res = await _supistaAnalyticsApi(`${appID}/kpiAllSchema`, {})
    .then(async (response) => {
      if (response.__d3__error) {
        return { skip: true, data: [], msg: response?.response?.msg };
      } else {
        return { skip: false, data: response?.response ?? {}, msg: undefined };
      }
    })
    .catch((error) => {
      console.log(error);
      // toast.error(mls('Some issues encountered.'));
      return { skip: true, data: [], msg: undefined };
    });
  return res;
};

export const useUpdateKpiSchema = () => {
  const dispatch = useDispatch();
  return async (response: { appID: string }) => {
    if (response != null && response?.appID) {
      const kpiChartData = await getKpiAllSchema({ appID: response.appID });
      if (kpiChartData.skip) {
        toast.error(kpiChartData?.msg ?? 'No KPIs Schema present!');
      } else {
        dispatch(
          create({
            setPath: reduxConstants.config.KPI_ALL_SCHEMA,
            value: kpiChartData?.data ?? {},
          })
        );
      }
    }
  };
};
