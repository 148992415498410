import React, { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import Sheets from 'app/sheets/Sheets';
// import Projects from 'app/projects/Projects';
import { MasterLayout } from '_metronic/layout/MasterLayout';
import Dash from 'app/dash/Dash';
import Dashboard from 'app/dash/Dashboard';
import { QueryClient, QueryClientProvider } from 'react-query';
import AllSheets from 'app/sheets/AllSheets';
import ChatAI from 'app/chatbot/ChatAI';
import TutorialComponent from 'app/tutorial/tutorialComponent';
import ReportsUI from 'app/reports/ReportsUI';
import { CustomTablePage, DataSource, DataTable, JoinTables } from 'lib/data-connectors';
import Teams from 'app/roleAccess/Teams';
import Users from 'app/roleAccess/Users';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import OrganizationStructure from 'lib/supista-kpi/OrganizationStructure/OrganizationStructure';
import KpiDashboard from 'lib/supista-kpi/KpiDashboard/KpiDashboard';

type Props = {
  appName: string;
};
export const PrivateRoutes: FC<Props> = ({ appName }) => {
  // const history = useHistory();
  const queryClient = new QueryClient();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const accessType = analyticsSchema?.appAccess?.accessType || [];

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/:appID/sheets/:sheetID'>
          <MasterLayout appName={appName}>
            <Sheets />
          </MasterLayout>
        </Route>

        <Route path='/:appID/dashboard/:dashID'>
          <MasterLayout appName={appName}>
            <QueryClientProvider client={queryClient}>
              <Dashboard />
            </QueryClientProvider>
          </MasterLayout>
        </Route>

        <Route key='sheets' path='/:appID/sheets'>
          <MasterLayout appName={appName}>
            <AllSheets />
          </MasterLayout>
        </Route>
        <Route key='supistai' path='/:appID/supistaAI'>
          <MasterLayout appName={appName}>
            <ChatAI />
          </MasterLayout>
        </Route>
        {(accessType.includes('ALLOWED_ALL') || accessType.includes('ALL_CREATOR')) && (
          <Route key='connections' path='/:appID/dataLake/Connections'>
            <MasterLayout appName={appName}>
              <DataSource />
            </MasterLayout>
          </Route>
        )}
        {(accessType.includes('ALLOWED_ALL') || accessType.includes('ALL_CREATOR')) && (
          <Route key='DataTable' path='/:appID/dataLake/DataTable'>
            <MasterLayout appName={appName}>
              <DataTable />
            </MasterLayout>
          </Route>
        )}
        {(accessType.includes('ALLOWED_ALL') || accessType.includes('ALL_CREATOR')) && (
          <Route key='JoinTables' path='/:appID/dataLake/JoinTables'>
            <MasterLayout appName={appName}>
              <JoinTables />
            </MasterLayout>
          </Route>
        )}
        {(accessType.includes('ALLOWED_ALL') || accessType.includes('ALL_CREATOR')) && (
          <Route key='CustomTable' path='/:appID/dataLake/CustomTable'>
            <MasterLayout appName={appName}>
              <CustomTablePage />
            </MasterLayout>
          </Route>
        )}

        <Route key='dash' path='/:appID/dashboard'>
          <MasterLayout appName={appName}>
            <Dash />
          </MasterLayout>
        </Route>
        {(accessType.includes('ALLOWED_ALL') ||
          accessType.includes('ALL_CREATOR') ||
          accessType.includes('CHART_CREATOR')) && (
          <Route key='reports' path='/:appID/reports'>
            <MasterLayout appName={appName}>
              <ReportsUI />
            </MasterLayout>
          </Route>
        )}
        {accessType.includes('ALLOWED_ALL') && (
          <Route key='teams' path='/:appID/roleAccess/Teams'>
            <MasterLayout appName={appName}>
              <Teams />
            </MasterLayout>
          </Route>
        )}
        {accessType.includes('ALLOWED_ALL') && (
          <Route key='users' path='/:appID/roleAccess/Users'>
            <MasterLayout appName={appName}>
              <Users />
            </MasterLayout>
          </Route>
        )}
        {accessType.includes('ALLOWED_ALL') && (
          <Route key='orgStructure' path='/:appID/roleAccess/orgStructure'>
            <MasterLayout appName={appName}>
              <OrganizationStructure />
            </MasterLayout>
          </Route>
        )}
        <Route key='kpiDashboard' path='/:appID/kpiDashboard'>
          <MasterLayout appName={appName}>
            <KpiDashboard isDevlopment={false} />
          </MasterLayout>
        </Route>

        <Redirect from='/:appID' to='/:appID/dashboard' />
        {/* <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' /> */}
      </Switch>
      <TutorialComponent />
    </Suspense>
  );
};
