import { useDispatch } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';

export default function useUpdateFilter() {
  const dispatch = useDispatch();
  return (filterSchema) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.FILTERS}`,
        value: filterSchema,
      })
    );
    return null;
  };
}

export function useUpdateDashFilter() {
  const dispatch = useDispatch();
  return (filterSchema) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.DASH_FILTERS}`,
        value: filterSchema,
      })
    );
    return null;
  };
}
