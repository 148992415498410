import { mls } from 'lib/multilanguagesupport';
import React, { useMemo } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import BubblePlotChart from './BubblePlotChart';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';

const BubblePlotType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  theme,
  labels,
  group,
  chartHeight,
  chartData,
  desc,
  isReport,
}) => {
  const groupColorMap = useMemo(() => {
    const colorMap = {};

    theme.tooltip.valueStyle.forEach((l) => {
      colorMap[l.label] = l.color;
      const color = l?.color;
      const opacity = (l?.opacity ?? 100) / 100;
      colorMap[l.label] = hexWithOpacity(color, opacity);
    });
    return colorMap;
  }, [theme.tooltip.valueStyle]);
  const { Legend, colorScale } = useOrdinalLegend({
    legendGlyphSize: 20,
    shape: 'circle',
    colorScale: groupColorMap,
    isReport,
    // labels: labels,
  });
  if (chartData?.yAxis?.length < 2) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('Please insert another column in Y-Axis to get the radius of bubbles')}
      </div>
    );
  }
  return (
    <>
      {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
        <>
          <div style={{ height: chartHeight }}>
            <ParentSize>
              {({ width, height }) => (
                <>
                  <BubblePlotChart
                    xAxisLabel={xAxisLabel}
                    yAxisLabel={yAxisLabel}
                    isGroupType={type === 'GROUPBUBBLEPLOT'}
                    data={data}
                    width={width}
                    margin={margin}
                    height={height}
                    // height={Math.max(height - 304, 400)}
                    colorScale={colorScale}
                    theme={theme}
                    group={group}
                    chartData={chartData}
                  />
                </>
              )}
            </ParentSize>
          </div>
          <div style={{ marginTop: 0 }}>
            <Legend />
          </div>
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default BubblePlotType;
