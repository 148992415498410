import React from 'react';
import {
  allDropableID,
  defaultChartSetting,
  defaultCardTypes,
} from '../../data/defaultChartSchema';
import DragableAxis from '../../components/BuildComponents/DragableAxis';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import FilterIndex from '../../components/BuildComponents/FilterDiv/FilterIndex';
import DropDown from '../../features/DropDown/DropDown';
import CardHeaderImage from '../../components/SpacialCasesComponents/Card/CardHeaderImage';
import CardAdvanceOptions from '../../components/SpacialCasesComponents/Card/CardAdvanceOptions';
import ChartInsight from 'lib/chart-settings-lib/components/BuildComponents/ChartInsight';
import { mls } from 'lib/multilanguagesupport';

const CardCase = ({ stateProps }) => {
  const {
    isInsight,
    setIsInsight,
    yAxisData,
    setYAxisData,
    isCrossReport,
    setIsCrossReport,
    iskeepAllFilters,
    setIskeepAllFilters,
    setChartYAxisStyle,
    chartCardType,
    setChartCardType,
    chartHeaderImage,
    setChartHeaderImage,
    chartType
  } = stateProps;
  const { yAxisId } = allDropableID;

  return (
    <>
      {/* <div className='outerDiv'>
        <div className='MainTitle'>Card Type</div>
        <DropDown
          array={defaultCardTypes}
          dropDownValue={chartCardType}
          setdropDownValue={setChartCardType}
          isKey={true}
        />
      </div> */}
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls("Card Image")}</div>
        <CardHeaderImage sourceImage={chartHeaderImage} setSourceImage={setChartHeaderImage} />
      </div>
      <div className='outerDiv'>
        <div className='MainTitle'>{mls("Axis")} *</div>
        <DragableAxis
          dragableDivId={yAxisId}
          axisData={yAxisData}
          setAxisData={setYAxisData}
          stateProps={stateProps}
          setStyle={setChartYAxisStyle}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          allDropableID={allDropableID}
        />
      </div>
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls("Advance Options")}</div>
        <CardAdvanceOptions stateProps={stateProps} />
      </div>
      <div className='outerDiv open filterDiv'>
        <div className='MainTitle'>{mls("Default Filters")}</div>

        <FilterIndex stateProps={stateProps} />
        {/* <div className='textAndToggle'>
          <div className='text'>Cross-report</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={isCrossReport} setIsChecked={setIsCrossReport} />{' '}
          </div>
        </div>
        <div className='textAndToggle'>
          <div className='text'>Keep all filters</div>
          <div className='toggleDiv'>
            <SlideSwitch isChecked={iskeepAllFilters} setIsChecked={setIskeepAllFilters} />
          </div>
        </div> */}

        <ChartInsight
          isInsight={isInsight}
          setIsInsight={setIsInsight}
          chartKey={chartType.chartKey}
        />
      </div>
    </>
  );
};

export default CardCase;
