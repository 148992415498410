import {
  defaultDateArray,
    defaultLegendColorPalettes,
    defaultDataType,
    defaultDateKey,
  } from '../data/defaultChartSchema';



export const getUniueValuesFunction = (props: any) => {
  const { uniqueValues, selectedItem, defaultShow = true, axis } = props;
  if (!uniqueValues) {
    return [];
  }
  const newData = uniqueValues.map((uniqueValues: any) => {
    const newUniqueValues = (elem: any) => {
      switch (elem) {
        case null:
          return 'null';
        case undefined:
          return 'undefined';

        case true:
          return 'true';

        case false:
          return 'false';

        default:
          return uniqueValues;
      }
    };
    const newElem = {
      label: newUniqueValues(uniqueValues),
      originalLabel: newUniqueValues(uniqueValues),
      origin: selectedItem.label,
      show: defaultShow,
      column: selectedItem.column,
      uniqueColumnName: selectedItem.uniqueColumnName,
      axis,
    };
    return newElem;
  });
  return newData;
};

export const setAutomaticColor = (props: any) => {
  const { target, defaultOpacity = 100 } = props;
  // const { target, chartToolTip, targetStyle, defaultOpacity = 100 } = props;
  // const findLengthNumber = (chartStyles:any, chartLength:number) => {
  //     if (chartStyles[chartLength]) {
  //     const indexOfColor = defaultLegendColorPalettes.findIndex((color) => {
  //         return color === chartStyles[chartLength].color;
  //     });
  //     if (indexOfColor === -1) {
  //         return 0;
  //     }
  //     return indexOfColor;
  //     } else {
  //     return 0;
  //     }
  // };
  // const chartStyles = targetStyle && chartToolTip?.style;
  // const chartLength = chartStyles?.length - 1;

  // let lengthNumber = findLengthNumber(chartStyles, chartLength);
  let lengthNumber = 0;

  const legendColorPalettesLength = defaultLegendColorPalettes.length;
  const newTargetWithColors = target.map((elem: any) => {
    if (lengthNumber >= legendColorPalettesLength - 1) {
      lengthNumber = 0;
    } else {
      lengthNumber++;
    }
    const newElem = {
      ...elem,
      color: defaultLegendColorPalettes[lengthNumber],
      opacity: defaultOpacity,
    };
    return newElem;
  });

  return newTargetWithColors;
};

export const filtersSchema = (filterArray: any, sheetID: string) => {
  const defaultValue = {
    valueCAT: [],
    valueNUMmin: 0,
    valueNUMmax: Infinity,
    valueDATE: defaultDateArray[0],
  };
  const newFilterObject: any = {};
  filterArray.map(({ dataType, tableName, columnName, value }: any, i: number) => {
    const newValue = () => {
      if (dataType === defaultDataType?.CAT) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.NUM) {
        return {
          min: value?.min || defaultValue.valueNUMmin,
          max: value?.max || defaultValue.valueNUMmax,
        };
      }
      if (dataType === defaultDataType.DATETIME) {
        if (value?.key === defaultDateKey.CUSTOM) {
          const newValue = { ...value };
          delete newValue.dateInISO;
          return newValue;
        }
        return value ? value : defaultValue.valueDATE;
      }
      if (dataType === defaultDataType.ID) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      if (dataType === defaultDataType.GEO) {
        return value && value[0] ? value : defaultValue.valueCAT;
      }
      return {};
    };
    const newObj = {
      column: columnName,
      dataType: dataType,
      tableName: tableName,
      sheetID: sheetID,
      value: newValue(),
    };

    // Add object with Keynames
    const keyName = `${tableName}.${columnName}`;
    newFilterObject[keyName] = newObj;
    return newObj;
  });

  return newFilterObject;
};



interface converToTimeFrame {
  defaultDateTimeCol: string;
  action: string;
  tableData: any[];
}
export const convertToTimeFrameObject = ({ defaultDateTimeCol, action, tableData }: converToTimeFrame) => {
  if (defaultDateTimeCol === null || defaultDateTimeCol === undefined) {
    return { skip: true };
  }
  const selectedTable = tableData?.find((elem: any) => {
    return `${elem.tableName}.${elem.columnName}` === defaultDateTimeCol;
  });
  const timeFrameObject = {
    [defaultDateTimeCol]: {
      column: selectedTable?.columnName,
      dataType: selectedTable?.dataType,
      tableName: selectedTable?.tableName,
      name: selectedTable?.name,
      sheetID: selectedTable?.sheetID,
      value: {
        period: 'LASTXDAYS',
        xDay: Number(action),
      },
    },
  };
  const finalTimeFrame = action === 'null' ? {} : timeFrameObject;
  return { skip: false, timeFrame: finalTimeFrame };
};
