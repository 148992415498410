import React, { useState } from 'react';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../helpers';
import { mls } from 'lib/multilanguagesupport';

const languageDict = {
  English: {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  Mandarin: {
    lang: 'zh',
    name: 'Mandarin',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  Spanish: {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  Japanese: {
    lang: 'ja',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  German: {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  French: {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
  Hindi: {
    lang: 'hi',
    name: 'Hindi',
    flag: 'https://flagcdn.com/w20/in.png',
  },
  Italian: {
    lang: 'it',
    name: 'Italian',
    flag: 'https://flagcdn.com/w20/it.png',
  },
};

function SupistaLanguages() {
  const [currentLanguage, setLanguage] = useState(
    localStorage.getItem('__d3__language') ||
      localStorage.getItem('__d3__defaultLanguage') ||
      'English'
  );
  const [open, setOpen] = useState(false);
  const [languages] = useState(() => {
    const allLanguages = localStorage.getItem('__d3__allLanguages');

    if (allLanguages && typeof allLanguages === 'string') {
      const newArray = JSON.parse(allLanguages);
      if (Array.isArray(newArray) && newArray?.length > 0) newArray.splice(0, 0, 'English');
      return newArray;
    } else return ['English'];
  });
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='menu-link px-5' onClick={() => setOpen(!open)}>
        <span className='menu-title position-relative'>
          {mls('Language')}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage}
            {languageDict[currentLanguage]?.flag && (
              <img
                className='w-15px h-15px rounded-1 ms-2'
                src={languageDict[currentLanguage]?.flag}
                alt='Country Flag'
              />
            )}
          </span>
        </span>
      </a>

      {open && (
        <div className='w-250px py-4'>
          {languages.map((l) => (
            <div
              className='menu-item px-3'
              key={l}
              onClick={() => {
                setLanguage(l);
                localStorage.setItem('__d3__language', l);
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={clsx('menu-link d-flex px-5', {
                  active: l === currentLanguage,
                })}
              >
                <span className='symbol symbol-20px me-4'>
                  {languageDict[l]?.flag && (
                    <img className='rounded-1' src={languageDict[l]?.flag} alt='Flag' />
                  )}
                </span>
                {l}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SupistaLanguages;
