import React from 'react';
import './TableData.scss';
import MaximaMinima from './Columns/MaximaMinima';
import Statistical from './Columns/Statistical';
import TwoColumns from './Columns/TwoColumns';
import DescribeColumn from './Columns/ChartWiseColumn/ScatterPlotTypeChart/DescribeColumn';
import DensityColumn from './Columns/ChartWiseColumn/ScatterPlotTypeChart/DensityColumn';
import ScatterTopBottom from './Columns/ChartWiseColumn/ScatterPlotTypeChart/ScatterTopBottom';

interface TableDataProps {
  elem: any;
  columnName1?: string;
  columnName2: string | undefined;
  columnName3: string;
  columnName4?: string;
  type?: string;
  chartType: string;
  mainData: any;
  arrow?: boolean;
}

const TableData = ({
  elem,
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  chartType,
  mainData,
  arrow = false,
}: TableDataProps) => {
  if (type === 'Statistical') {
    return (
      <Statistical
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
      />
    );
  }
  if (type === 'DESCRIBE') {
    return (
      <DescribeColumn
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
        mainData={mainData}
      />
    );
  }

  if (type === 'DENSITY') {
    return (
      <DensityColumn
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
        mainData={mainData}
      />
    );
  }
  if (type === 'SCATTERTOPBOTTOM') {
    return (
      <ScatterTopBottom
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
        mainData={mainData}
      />
    );
  }
  if (type === 'MaximaMinima') {
    return (
      <MaximaMinima
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
      />
    );
  }
  if (type === 'twoColumns') {
    return (
      <TwoColumns
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        chartType={chartType}
        mainData={mainData}
        arrow={arrow}
      />
    );
  }
  if (type === 'LastGrowth') {
    return (
      <TwoColumns
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        chartType={chartType}
        mainData={mainData}
        arrow={arrow}
      />
    );
  }

  return (
    <TwoColumns
      columnName1={columnName1}
      columnName2={columnName2}
      columnName3={columnName3}
      columnName4={columnName4}
      type={type}
      elem={elem}
      chartType={chartType}
      mainData={mainData}
      arrow={arrow}
    />
  );
};

export default TableData;
