import {
  getSheetID,
  getConvertedTableData,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import { supistaApiPost } from './connections';

interface getPlotDataProps {
  chartSchema: any;
  appID: string;
  timeFrame?: any;
  tableID?: string;
}

export const getPlotData = async ({ chartSchema, appID, timeFrame, tableID }: getPlotDataProps) => {
  const body = {
    charts: {
      [chartSchema.chartID]: chartSchema,
    },
    projectID: chartSchema.projectID,
    sheetID: chartSchema.sheetID,
    appID: chartSchema.appID,
    timeFrame,
    tableID: tableID,
  };

  const res: any = await supistaApiPost('createCharts', body, appID);
  if (!res.__d3__error) {
    return { skip: false, plotData: res.response.charts };
  }
  return { skip: true };
};

const getAIGeneratedSection = (dashSchema: any, defaultSection: any, schema: any) => {
  const existingSections = dashSchema?.sections || [];

  if (existingSections.length === 0) {
    return [defaultSection];
  }
  const AIsectionIndex = (existingSections || []).findIndex(
    (secObj: any) => secObj.AIGeneratedSection === true
  );

  if (AIsectionIndex === -1) {
    return [defaultSection, ...existingSections];
  }
  const dataToReturn = [...existingSections];
  const isChartPresent = dataToReturn[AIsectionIndex].charts.findIndex(
    (chartObj: any) => chartObj['id'] === schema?.chartID
  );
  if (isChartPresent === -1) {
    const newCharts = [
      {
        width: schema?.chartWidth,
        sheetID: schema?.sheetID,
        id: schema?.chartID,
      },
      ...(dataToReturn[AIsectionIndex].charts || []),
    ];
    dataToReturn[AIsectionIndex] = {
      ...dataToReturn[AIsectionIndex],
      charts: newCharts,
    };
  }
  return dataToReturn;
};

interface saveToDashboardProps {
  appDash: any;
  setDashboards: Function;
  updateReduxSchema: Function;
  schema: any;
  dashID: string;
  appID: string;
  sheetID: string;
  dashName?: string;
  isNewDashBoard: boolean;
  selectedTable: string;
  sheetSchema: any;
  updateSheet: Function;
  tableSchema: any;
  allTableIDAndNamesMap: any;
}
export const saveToDashboard = (saveToDashboardProps: saveToDashboardProps) => {
  const {
    appDash,
    setDashboards,
    dashID,
    schema,
    appID,
    sheetID,
    isNewDashBoard,
    dashName = '',
    updateReduxSchema,
    selectedTable,
    sheetSchema,
    updateSheet,
    tableSchema,
    allTableIDAndNamesMap,
  } = saveToDashboardProps;

  const getDashboardschema = (isNewDashBoard: boolean) => {
    const defaultSection = {
      title: 'AI Dashboard Section',
      AIGeneratedSection: true,
      headings: [
        {
          color: '#ccc',
          font: 'Poppins',
          fontHeight: '12',
          opacity: '100',
          text: 'AI Created Section',
          textStyle: [],
        },
      ],
      charts: [
        {
          width: schema?.chartWidth,
          sheetID: schema?.sheetID,
          id: schema?.chartID,
        },
      ],
    };
    if (isNewDashBoard) {
      const defaultDashboardSchema = {
        name: dashName,
        desc: 'AI Dashboard',
        sections: [defaultSection],
        appID: appID,
        dashID: dashID,
      };
      return defaultDashboardSchema;
    }

    const selectedDashboardData = appDash[dashID as keyof typeof appDash];

    const newSections = getAIGeneratedSection(selectedDashboardData, defaultSection, schema);
    const newDashboardSchema = {
      name: selectedDashboardData?.name,
      desc: selectedDashboardData?.desc,
      sections: newSections,
      appID: selectedDashboardData?.appID,
      dashID: selectedDashboardData?.dashID,
    };
    return newDashboardSchema;
  };
  const dashboardSchema = getDashboardschema(isNewDashBoard);

  saveDahboardApi({
    dashboardSchema: dashboardSchema,
    setDashboards,
    appID,
    sheetID,
    selectedTable,
    chartSchema: schema,
    isNewDashBoard,
    sheetSchema,
    updateSheet,
    updateReduxSchema,
    tableSchema,
    allTableIDAndNamesMap,
  });
};
interface saveDahboardApiProps {
  appID: string;
  sheetID: string;
  selectedTable: string;
  chartSchema: any;
  dashboardSchema: any;
  setDashboards: Function;
  isNewDashBoard: boolean;
  sheetSchema: any;
  updateSheet: Function;
  updateReduxSchema: Function;
  tableSchema: any;
  allTableIDAndNamesMap: any;
}
const saveDahboardApi = async ({
  dashboardSchema,
  sheetID,
  selectedTable,
  setDashboards,
  appID,
  chartSchema,
  isNewDashBoard,
  sheetSchema,
  updateSheet,
  updateReduxSchema,
  tableSchema,
  allTableIDAndNamesMap,
}: saveDahboardApiProps) => {
  // First CreateChart
  const isChartSaved = await saveChartApi({ chartSchema, appID: appID });
  if (isChartSaved) {
    // Second updateSheet CreateChart
    let selectedDashboardData = sheetSchema[sheetID as keyof typeof sheetSchema];
    if (!selectedDashboardData) {
      selectedDashboardData = {
        appID,
        charts: [],
        defaultTable: tableSchema?.defaultTable,
        name: `AI ${allTableIDAndNamesMap[selectedTable]}`,
        sheetID: sheetID,
      };
    }

    const newChartLists = (selectedDashboardData?.charts || []).includes(chartSchema?.chartID)
      ? selectedDashboardData?.charts || []
      : [chartSchema?.chartID, ...(selectedDashboardData?.charts || [])];

    const updatedSheet = {
      ...selectedDashboardData,
      charts: newChartLists,
    };
    const sheetUpdateRes: any = await updateSheetAPI(updatedSheet, appID);
    if (!sheetUpdateRes.skip) {
      const DashboardCreateEndPoint = 'DashSettings/create';
      const res = await supistaApiPost(DashboardCreateEndPoint, dashboardSchema, appID);
      if (!res.__d3__error) {
        setDashboards((currenDashBoard: any) => {
          const updatedDashboardSchema = {
            ...currenDashBoard,
            [dashboardSchema.dashID]: dashboardSchema,
          };

          //updating Sheet in State
          const updatedSheetSchema = {
            ...sheetSchema,
            [sheetID]: updatedSheet,
          };
          updateSheet(updatedSheetSchema);
          if (updateReduxSchema) {
            updateReduxSchema(
              chartSchema,
              updatedSheetSchema[sheetID],
              updatedDashboardSchema[dashboardSchema.dashID],
              chartSchema?.chartID,
              sheetID,
              dashboardSchema.dashID
            );
          }
          return updatedDashboardSchema;
        });
        // }
      }
    }
  }
};

const saveChartApi = async ({ chartSchema, appID }: any) => {
  const chartAddEndPoint = 'ChartSettings/create';
  const res = await supistaApiPost(chartAddEndPoint, chartSchema, appID);
  if (!res.__d3__error) {
    return true;
  }
  return false;
};

interface addgetTableData {
  allTables?: any;
  tableID: any;
  defaultData: string;
  target: string;
}
export const getTableData = ({ allTables, tableID, defaultData, target }: addgetTableData) => {
  if (!allTables) {
    return defaultData;
  }
  if (allTables[tableID]) {
    if (target === 'sheetID') {
      return allTables[tableID]?.tableSchema?.defaultTable?.sheetID;
    }
    if (target === 'tableData') {
      return allTables[tableID]?.tableData;
    }
    if (target === 'tableSchema') {
      return allTables[tableID]?.tableSchema;
    }
    // const finalData = {
    //   sheetID: allTables[tableID]?.tableSchema?.defaultTable?.sheetID,
    //   tableData: allTables[tableID]?.tableData,
    //   tableSchema: allTables[tableID]?.tableSchema,
    // };
    // return { skip: false, data: finalData };
  }
  return defaultData;
};

interface getTableSchemaInterFace {
  allTables: any;
  selectedTable: string;
  appID: string;
  appSheets: any;
  updateSheet: Function;
  setAllTables: Function;
  updateReduxSheetSchema: Function;
  allTableIDAndNamesMap: any;
}
export const getTableSchema = async (props: getTableSchemaInterFace) => {
  const { allTables, selectedTable, appID, appSheets, setAllTables } = props;
  if (allTables[selectedTable]) {
    return {
      skip: false,
      schema: allTables[selectedTable]?.tableSchema,
      tableData: allTables[selectedTable]?.tableData,
      sheetID: allTables[selectedTable]?.tableSchema?.defaultTable?.sheetID,
    };
  }

  const sheetData: {
    sheetID: string;
    skip: boolean;
    newCreatedSheetID?: boolean;
  } = await getSheetID({
    appSheets,
    selectedTable,
    appID,
  });
  if (sheetData?.skip) {
    return { skip: true, skipFrom: 'sheetID' };
  }

  const tableData = await getConvertedTableData({
    appID: appID,
    selectedTable: selectedTable,
    sheetID: sheetData?.sheetID,
    newSheetID: sheetData?.newCreatedSheetID ?? false,
  });
  if (tableData?.skip) {
    return { skip: true, skipFrom: 'tableData' };
  }

  const newTableSchema = tableData?.data?.tableSchema?.data?.map((tableElem: any) => {
    return { ...tableElem, sheetID: sheetData?.sheetID };
  });
  const allTableSchema = {
    [selectedTable]: {
      tableSchema: {
        ...tableData?.data?.tableSchema,
        data: newTableSchema,
        defaultTable: {
          ...tableData?.data?.tableSchema?.defaultTable,
          sheetID: sheetData?.sheetID,
        },
      },
      tableData: tableData?.groupedTableData,
    },
  };
  setAllTables((currentTables: any) => {
    return { ...currentTables, ...allTableSchema };
  });
  const tableSchema = {
    ...tableData?.data?.tableSchema,
    data: newTableSchema,
    defaultTable: {
      ...tableData?.data?.tableSchema?.defaultTable,
      sheetID: sheetData?.sheetID,
    },
  };

  return {
    skip: false,
    schema: tableSchema,
    sheetID: sheetData?.sheetID,
    tableData: tableData?.groupedTableData,
  };
};
const updateSheetAPI = async (body: any, appID: string) => {
  const SheetCreateEndPoint = 'SheetSettings/create';
  const res: any = await supistaApiPost(SheetCreateEndPoint, body, appID);
  if (!res.__d3__error) {
    return { skip: false, res: res };
  }
  return { skip: true };
};
