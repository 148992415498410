import React, { useState, useMemo, useEffect, useCallback } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import {
  // STARTING_DATA_LENGTH,
  defaultToolTipStyling,
} from '../../constants/constants';
import { mls } from 'lib/multilanguagesupport';
import { XYChart, Grid, lightTheme, LineSeries, TooltipProvider } from '@visx/xychart';
import { scaleBand } from '@visx/scale';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import XYChartAxis from '../common/XYChartAxis';
import { hexWithOpacity } from '../../helpers/colorHelper';
import GetXYChart from '../XYType/GetXYChart';
import useD3Brush from '../../helpers/hooks/useD3Brush';
import XYToolTip, { defaultToolTipBelongTo } from '../ChartToolTip/ChartToolTip';
import DateTimeFilter from '../common/DateTimeFilter';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import DoubleYAxisChart from '../DoubleYAxisChart/DoubleYAxisChart';
// const sdfsdf= {
//       color: rgb(63, 66, 84);
//   fill: rgb(63, 66, 84);
//   font-weight: bold;
//   font-style: normal;
//   text-decoration: none;
//   font-family: Poppins;
//   font-size: 6mm;
//   opacity: 1;
//   text-align: left;
//   display: block;
// }

export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen width
  const lineAreaWidth = 40;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

const accessors = {
  xAccessor: (d) => d?.x,
  yAccessor: (d) => d?.y,
};
const brushMargin = {
  top: 5,
  bottom: 0,
  left: 70,
  right: 20,
};
const chartSeparation = 40;

const margin = { left: 60, top: 15, bottom: 60, right: 20 };
// const margin = { left: 0, top: 0, bottom: 0, right: 0 };
function getRequiredFormat(xValues, yValues, zValues = []) {
  const reqData = [];
  for (let i = 0; i < xValues.length; i++) {
    const getZValues = ({ index }) => {
      let zValueData = {};
      Object.keys(zValues ?? {}).forEach((zValuesKey) => {
        const valuesData = zValues[zValuesKey]?.data ?? [];
        zValueData = { ...zValueData, [zValuesKey]: valuesData[index] };
      });
      return zValueData;
    };
    const finalZvalueData = getZValues({ index: i });
    reqData.push({ x: xValues[i], y: yValues[i], z: finalZvalueData });
  }
  return reqData;
}
const XYTypeCheck = (xyProps) => {
  const { chartData } = xyProps;
  if (
    chartData?.yAxis?.length === 2 &&
    chartData?.doubleAxis?.isDoubleAxis &&
    (chartData?.chartType === allChartKeys.BARGROUP ||
      chartData?.chartType === allChartKeys.MULTILINE)
  ) {
    // if (chartData?.yAxis?.length === 2 && chartData?.doubleAxis?.isDoubleAxis) {
    return <DoubleYAxisChart xyProps={xyProps} />;
  }
  return <XYType xyProps={xyProps} />;
};
export default XYTypeCheck;

const XYType = ({ xyProps }) => {
  const {
    data,
    targetData,
    xAxisLabel,
    yAxisLabel,
    type,
    yCategory,
    xCategory,
    title,
    xGrid,
    yGrid,
    margin: marginProp,
    cardRef,

    handleClick,
    labels,
    toggle,
    handleToggle,
    isToggleClicked,
    chartHeight,
    theme,

    chartData,
    isReport,
  } = xyProps;
  const x = useMemo(() => data.x, [data]);
  const y = useMemo(() => data.y, [data]);
  const z = useMemo(() => data.z, [data]);
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 70) / 100;
  const isBrush = chartData.isZoomBrush === undefined ? true : chartData.isZoomBrush;
  // const x = useMemo(() => (isBrush ? allX.slice(-STARTING_DATA_LENGTH) : allX), [allX]);
  // const y = useMemo(() => (isBrush ? sliceYData(allY, -STARTING_DATA_LENGTH) : allY), [allY]);
  const { width } = useDivDimensions(cardRef);
  const [filteredXData, setFilteredXData] = useState(x);
  const [filteredYData, setFilteredYData] = useState(y);
  const [filteredZData, setFilteredZData] = useState(z);
  const [filteredTargetYData, setFilteredTargetYData] = useState(targetData?.y);
  const height = chartHeight + margin.top + margin.bottom;
  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = isBrush ? chartSeparation + 10 : 0;
  const topChartHeight = isBrush ? 0.92 * innerHeight - topChartBottomMargin : innerHeight;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;
  const xBrushMax = Math.max(width - margin.left - margin.right, 0);
  const brushXScale = useMemo(() => {
    return scaleBand({
      domain: x,
      range: [0, xBrushMax],
      padding: 0,
    });
  }, [x, xBrushMax]);

  const actualWidth = widthHelper(x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * x.length);
  // const [brushPosition, setBrushPosition] = useState([x[x.length - dataNum], x[x.length - 1]]);
  useEffect(() => {
    setFilteredXData(x);
    setFilteredYData(y);
  }, [data, x, y]);
  const isColor =
    chartData.chartType === allChartKeys.BARCOLOR ||
    chartData.chartType === allChartKeys.LINECOLOR ||
    chartData.chartType === allChartKeys.AREACOLOR ||
    chartData.chartType === allChartKeys.BARHORIZONTALCOLOR;
  const getLegendObject = () => {
    if (isColor) {
      const legendColorScale = {};
      (theme?.tooltip?.valueStyle || []).forEach((vs) => {
        if (Object.keys(data?.y)?.includes(vs?.label)) {
          const color = vs?.color;
          const opacity = (vs?.opacity ?? 100) / 100;
          legendColorScale[vs.label || 'Null'] = hexWithOpacity(color, opacity);
        }
      });
      return {
        legendGlyphSize: 20,
        colorScale: legendColorScale,
        isReport,
      };
    }

    // let newLable = {};
    // Object.keys(labels).forEach((labelKey) => {
    //   const findLabel = theme?.tooltip?.style.find((elm) => {
    //     return elm?.uniqueColumnName === labelKey;
    //   });
    //   newLable = { ...newLable, [labelKey]: findLabel.label ?? labels[labelKey] };
    // });
    return {
      legendGlyphSize: 20,
      colorScale: theme.colors,
      labels: labels,
      // labels: newLable,
      isReport,
    };
  };
  const { Legend, colorScale } = useOrdinalLegend(getLegendObject());
  const brushed = useCallback(
    ({ selection }) => {
      if (selection) {
        const [x0, x1] = selection;
        let start = -1,
          end = y.length - 1;
        setFilteredXData(
          x.filter((d, i) => {
            if (x0 <= brushXScale(d) && brushXScale(d) <= x1) {
              if (start === -1) start = i;
              end = i;
              return true;
            }
            return false;
          })
        );
        // if (start <= x.length * 0.1 && x.length !== allX.length) {
        //   const n = Math.min(STARTING_DATA_LENGTH, allX.length - x.length);
        //   const newX = allX.slice(-x.length - n);
        //   // setX(newX);
        //   // setY(sliceYData(allY, -x.length - n));
        //   setBrushPosition([x[start], x[end]]);
        // }

        const filteredY = {};
        Object.keys(y).forEach((key) => {
          filteredY[key] = {
            data: y[key].data.slice(start, end + 1),
          };
        });
        setFilteredYData(filteredY);
        const filteredZ = {};
        Object.keys(z || {}).forEach((key) => {
          filteredZ[key] = {
            data: z[key].data.slice(start, end + 1),
          };
        });
        setFilteredZData(filteredZ);
        if (!targetData) {
          return;
        }
        const targetfilteredY = {};
        Object.keys(targetData.y).forEach((key) => {
          targetfilteredY[key] = {
            data: targetData.y[key].data.slice(start, end + 1),
          };
        });
        setFilteredTargetYData(targetfilteredY);
      }
    },
    [x, y, brushXScale, targetData]
  );
  /// TooolTip
  const capitalizeOnlyFirst = (s) => {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const xAxis = useMemo(() => chartData.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData.yAxis, [chartData.yAxis]);

  const xAxisLabels = useMemo(() => {
    const axisLabels = xAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, xAxis]);
  const yAxisLabels = useMemo(() => {
    const axisLabels = yAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, yAxis]);

  const { ref: miniRef } = useD3Brush({
    brushed,
    height: bottomChartHeight,
    data: x,
    brushScale: brushXScale,
    dataNum,
    width: xBrushMax,
    isBrush,
  });
  const horizontalGridLines = chartData?.gridLines?.horizontal || {};
  const verticalGridLines = chartData?.gridLines?.vertical || {};
  const getStrokDashArray = (type) => {
    if (type === 'dashed') {
      return '10,10';
    }
    if (type === 'dotted') {
      return '2,4';
    }
    return '';
  };
  const xAxisTickWidth = (width ?? 0) / filteredXData?.length;
  const yAxisFormatter = theme?.yAxisStyle?.formatter;
  const isDateTime = xCategory === 'DATETIME';
  useEffect(() => {
    if (!isBrush) {
      setFilteredXData(x);
      setFilteredYData(y);
    }
  }, [isBrush]);

  return (
    <>
      {/* <ExportCharts /> */}
      <>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            {xCategory === 'DATETIME' && (
              <DateTimeFilter
                width={width}
                toggle={toggle}
                handleToggle={handleToggle}
                isToggleClicked={isToggleClicked}
                margin={margin}
              />
            )}
            <XYChart
              theme={{ ...lightTheme, colors: colorScale.range() }}
              height={topChartHeight}
              width={width ?? 0}
              xScale={{
                type: 'band',
                paddingInner:
                  type === 'BARSTACK' ||
                  type === 'BAR' ||
                  type === 'BARGROUP' ||
                  type === 'BARSTACKLINE' ||
                  type === 'BARCOLOR'
                    ? 0.2
                    : 1,
                paddingOuter: 0.1,
              }}
              yScale={{ type: 'linear' }}
              margin={margin}
            >
              {horizontalGridLines?.show && (
                <Grid
                  columns={false}
                  numTicks={horizontalGridLines?.count ?? 5}
                  strokeWidth={horizontalGridLines?.width ?? 1}
                  stroke={hexWithOpacity(
                    horizontalGridLines?.color,
                    horizontalGridLines?.opacity / 100
                  )}
                  strokeDasharray={getStrokDashArray(horizontalGridLines?.type)}
                  lineStyle={{}}
                />
              )}
              {verticalGridLines?.show && (
                <Grid
                  rows={false}
                  numTicks={verticalGridLines?.count ?? 5}
                  strokeWidth={verticalGridLines?.width ?? 1}
                  stroke={hexWithOpacity(
                    verticalGridLines?.color,
                    verticalGridLines?.opacity / 100
                  )}
                  strokeDasharray={getStrokDashArray(verticalGridLines?.type)}
                  lineStyle={{}}
                />
              )}

              <XYChartAxis
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                xStyling={theme.xAxisStyle}
                yStyling={theme.yAxisStyle}
                xAxisTickWidth={xAxisTickWidth}
                yAxisFormatter={yAxisFormatter}
                toggle={toggle}
                dataNum={dataNum}
                isDateTime={isDateTime}
                dateTimeFormat={theme.xAxisStyle.formatter}
              />

              <>
                <GetXYChart
                  xData={filteredXData}
                  yData={filteredYData}
                  zData={filteredZData}
                  getRequiredFormat={getRequiredFormat}
                  type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
                  accessors={accessors}
                  colorScale={colorScale}
                  automaticOpacity={automaticOpacity}
                  handleClick={handleClick}
                />
                {filteredTargetYData &&
                  Object.keys(filteredTargetYData).map((key) => {
                    return (
                      <LineSeries
                        key={key}
                        dataKey={key}
                        data={getRequiredFormat(filteredXData, filteredTargetYData[key].data)}
                        {...accessors}
                        colorAccessor={(d, i) => colorScale(key)}
                      />
                    );
                  })}
              </>
              <TooltipProvider hideTooltipDebounceMs={50000} />
              <XYToolTip
                defaultToolTipStyling={defaultToolTipStyling}
                accessors={accessors}
                theme={theme}
                colorScale={colorScale}
                xAxisLabels={xAxisLabels}
                yAxisLabels={yAxisLabels}
                labels={labels}
                belongsTo={defaultToolTipBelongTo.XY}
                backgroundColor={defaultToolTipStyling.backgroundColor}
                isColor={isColor}
                toggle={toggle}
                isDateTime={isDateTime}
                type={type}
              />
            </XYChart>
            {isBrush && !isReport && (
              <svg
                height={bottomChartHeight}
                width={width - margin.left - margin.right}
                style={{
                  marginLeft: margin.left,
                  marginRight: margin.right,
                  // marginTop: topChartHeight + topChartBottomMargin,
                }}
                ref={miniRef}
              >
                <MiniXYChart
                  bottomChartHeight={bottomChartHeight}
                  width={width}
                  type={type}
                  x={x}
                  y={y}
                  targetYData={targetData?.y}
                  colorScale={colorScale}
                  accessors={accessors}
                  automaticOpacity={automaticOpacity}
                />
              </svg>
            )}

            <div style={{ marginTop: 20 }}>
              <Legend />
            </div>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </>
    </>
  );
};

const MiniXYChart = React.memo(
  ({ bottomChartHeight, width, type, x, y, targetYData, colorScale, automaticOpacity }) => {
    return (
      <XYChart
        theme={{ ...lightTheme, colors: colorScale.range() }}
        // theme={{ ...lightTheme, colors: variantColors }}
        margin={{ ...brushMargin, left: 0, right: 0 }}
        width={width - margin.left - margin.right}
        height={bottomChartHeight}
        xScale={{
          type: 'band',
          paddingInner:
            type === 'BARSTACK' ||
            type === 'BAR' ||
            type === 'BARGROUP' ||
            type === 'BARSTACKLINE' ||
            type === 'BARCOLOR'
              ? 0.2
              : 1,
          paddingOuter: 0.1,
        }}
        yScale={{ type: 'linear' }}
      >
        <GetXYChart
          xData={x}
          yData={y}
          getRequiredFormat={getRequiredFormat}
          type={type === 'BARSTACKLINE' ? 'BARSTACK' : type}
          colorScale={colorScale}
          accessors={accessors}
          automaticOpacity={automaticOpacity}
        />
        {targetYData &&
          Object.keys(targetYData).map((key) => {
            return (
              <LineSeries
                key={key}
                dataKey={key}
                data={getRequiredFormat(x, targetYData[key].data)}
                {...accessors}
                colorAccessor={(d, i) => colorScale(key)}
              />
            );
          })}
      </XYChart>
    );
  }
);
