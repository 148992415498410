import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';

interface closeConfirmationDivProps {
  isConfirmationDiv: boolean;
  setIsConfirmationDiv: Function;
  handleClose: Function;
}
const CloseConfirmationDiv = ({
  isConfirmationDiv,
  setIsConfirmationDiv,
  handleClose,
}: closeConfirmationDivProps) => {
  return (
    <PopUpDivChild
      initialValue={isConfirmationDiv}
      setPopUp={setIsConfirmationDiv}
      scrollOff={false}
      className={'ConfirmationDiv'}
      popupTitle={`${mls('Are you sure you want to leave this page the changes will be lost')} ?`}
    >
      <div className='primaryButtonFlexDiv'>
        <button className='primaryButton secondaryButtonColor' onClick={() => handleClose()}>
          {mls('Yes')}
        </button>
        <button
          className='primaryButton secondaryButtonColor'
          onClick={() => setIsConfirmationDiv(false)}
        >
          {mls('No')}
        </button>
      </div>
    </PopUpDivChild>
  );
};
export default CloseConfirmationDiv;
