export const baseAPI = process.env.REACT_APP_IS_LOCALHOST === 'true' ? 'http://localhost:8000' : 'https://analytics-api.supista.com';

export const supistaApiGet = (endpoint) => {
  return fetch(`${baseAPI}/${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((response) => response.json());
};

export const supistaApiPost = async (endpoint, data, appID) => {
  return fetch(`${baseAPI}/${appID}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  }).then((response) => response.json());
};
