import React, { useState } from 'react';
import './saveReportContainer.scss';
import TimeIntervalField from './TimeIntervalField/TimeIntervalField';
import { mls } from 'lib/multilanguagesupport';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import { toast } from 'react-toastify';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { defaultReportTimeFrame } from 'lib/reports-analytics/data/defaultSchema';
import { CircularProgress } from '@mui/material';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import DatePicker from 'lib/filters-lib/DatePicker';
import { defaultDateArray } from 'lib/filters-lib/data/defaultFilterSchema';
import { supistaApiPost } from 'lib/reports-analytics/utils/connections';
import {
  removeExtraDataFromSchema,
  reportSchemaValidation,
} from 'lib/reports-analytics/utils/helperFunction';
interface saveReportContainerProps {
  stateProps: any;
}
const SaveReportContainer = ({ stateProps }: saveReportContainerProps) => {
  const {
    sendTo,
    setSendTo,
    setScheduledTime,
    scheduledTime,
    sendToOptions,
    settingsSchema,
    appID,
    dateTimeCol,
  } = stateProps;
  const [isReportSendNowVisible, setisReportSendNowVisible] = useState(false);
  const handleSendToEmail = (action: any) => {
    setSendTo({ ...sendTo, mail_list: action });
  };

  const handleReportTimeFrame = ({ key, action }: { key: string; action: boolean }) => {
    if (action) {
      setScheduledTime((currentData: string[]) => {
        return [...currentData, key];
      });
    } else {
      setScheduledTime((currentData: string[]) => {
        const removeScheduleTime = currentData.filter((elem) => elem !== key);
        return removeScheduleTime;
      });
    }
  };
  return (
    <div className='saveReportContainer'>
      <div className='innerSaveReportContainer'>
        {/* <div className='saveReportTitleDiv'>{mls('When to Send')}</div>
        <div className='saveReportDiscriptionDiv'>
          {mls(
            'The report will be published every 2 Months on 01st, 09th, 17th, 25th, day of the month.'
          )}
        </div>
        <TimeIntervalField stateProps={stateProps} /> */}
        <div className='reportTimeFrame'>
          <div className='titleDiv'>{mls('Report Schedule Frequency')}:*</div>
          <div className='reportTimeFrameOptionsContainer'>
            {React.Children.toArray(
              Object.keys(defaultReportTimeFrame).map((reportTimeFrameKey: string) => {
                const checked = scheduledTime.some((elem: string) => elem === reportTimeFrameKey);
                return (
                  <div
                    className='reportTimeFrameOption'
                    onClick={() =>
                      handleReportTimeFrame({ key: reportTimeFrameKey, action: !checked })
                    }
                  >
                    <CheckBox checked={checked} />
                    <div className='checkBoxText'>
                      {
                        defaultReportTimeFrame[
                          reportTimeFrameKey as keyof typeof defaultReportTimeFrame
                        ]
                      }
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div>
          <div className='saveReportTitleDiv'>{mls('Email Id List')}:</div>
          <MultiSelect
            options={sendToOptions.email}
            selectedValues={sendTo.mail_list}
            onSelect={handleSendToEmail}
            onRemove={handleSendToEmail}
            validation={isValidEmail}
            chipStyle={'borderChip'}
            placeholder={'Select or add emails'}
          />
        </div>
        <div className='reportSendNowButtonContainer'>
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => setisReportSendNowVisible(true)}
          >
            {mls('Send Now')}
          </button>
        </div>
        {isReportSendNowVisible ? (
          <ReportSendNow
            isReportSendNowVisible={isReportSendNowVisible}
            setisReportSendNowVisible={setisReportSendNowVisible}
            sendToOptions={sendToOptions}
            initialSendTo={sendTo}
            initialScheduledTime={scheduledTime}
            settingsSchema={settingsSchema}
            appID={appID}
            dateTimeCol={dateTimeCol}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SaveReportContainer;
const isValidEmail = (email: string) => {
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    toast.warn(mls("It's not a valid Email"), {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
  return { skip: !emailRegex.test(email) };
};

interface reportSendNowProps {
  isReportSendNowVisible: boolean;
  setisReportSendNowVisible: Function;
  sendToOptions: any;
  initialSendTo: any;
  initialScheduledTime: any;
  settingsSchema: any;
  appID: string;
  dateTimeCol: string;
}
const ReportSendNow = ({
  isReportSendNowVisible,
  setisReportSendNowVisible,
  sendToOptions,
  initialSendTo,
  initialScheduledTime,
  settingsSchema,
  appID,
  dateTimeCol,
}: reportSendNowProps) => {
  const [sendTo, setSendTo] = useState(initialSendTo);
  const [selectedData, setSelectedData] = useState(defaultDateArray[4]);
  const [isLoading, setisLoading] = useState(false);

  const handleSendToEmail = (action: any) => {
    setSendTo({ ...sendTo, mail_list: action });
  };
  // const handleSendToUser = (action: any) => {
  //   setSendTo({ ...sendTo, user: action });
  // };
  // const handleSendToTeams = (action: any) => {
  //   setSendTo({ ...sendTo, teams: action });
  // };

  const sendEmailNow = async () => {
    setisLoading(true);
    const finalSchema = removeExtraDataFromSchema(settingsSchema);
    const isValidate = reportSchemaValidation({ ...finalSchema, sendTo });
    if (isValidate.skip) {
      toast.warn(mls(isValidate.msg), {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setisLoading(false);
      return;
    }

    const payloadData = {
      appID: settingsSchema.appID,
      reportSchema: { ...settingsSchema, recipients: sendTo, reportTimeframe: selectedData },
      isTemp: true,
      reportTimeframe: selectedData,
      datetimeColumn: dateTimeCol,
    };
    const endPoint = 'apiType/sendReport/reports';
    try {
      const res = await supistaApiPost(endPoint, payloadData, appID);
      if (res.__d3__error) {
        toast.warn(mls('Report is not able to send. Please try again later'), {});
      } else {
        toast.success(mls('Report Send Successfully'), {});
        setisReportSendNowVisible(false);
      }
    } catch (err) {
      console.log('error encountered while creating new dashboard', err);
    }
    setisLoading(false);
  };

  return (
    <PopUpDivChild
      className='reportSendNow'
      popupTitle={mls('Send Now')}
      initialValue={isReportSendNowVisible}
      setPopUp={() => setisReportSendNowVisible(false)}
    >
      <div className='saveReportDiscriptionDiv'>
        {mls('Get your report for the selected timeframe to the selected email accounts.')}
      </div>
      <div className='PopUpDiv FilterDiv'>
        <div className='reportTimeFrame calendarWrap'>
          <div className='titleDiv'>{mls('Report TimeFrame')}:</div>
          <div style={{ display: 'flex', padding: '.75rem 1rem .5rem 1rem' }}>
            <DatePicker dateData={selectedData} setDateData={setSelectedData} />
          </div>
        </div>
      </div>
      <div className='sendTo'>
        <div className='titleDiv'>{mls('Emails Id List')}*</div>
        <div style={{ padding: '.75rem 1rem .5rem 1rem' }}>
          <MultiSelect
            options={[]}
            selectedValues={sendTo.mail_list}
            onSelect={handleSendToEmail}
            onRemove={handleSendToEmail}
            validation={isValidEmail}
            chipStyle={'borderChip'}
            placeholder={'Select or add emails'}
          />
        </div>
      </div>
      <div className='reportSendNowButtonContainer'>
        <button
          className='primaryButton secondaryButtonColor'
          disabled={isLoading}
          onClick={() => sendEmailNow()}
          style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}
        >
          {isLoading ? <CircularProgress style={{ width: '15px', height: '15px' }} /> : null}
          {mls('Send Email Now')}
        </button>
      </div>
    </PopUpDivChild>
  );
};
