// import { mls } from '../../multilanguagesupport/analytics';
import React, { useMemo } from 'react';
// import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers';
// import styles from '../../constants/styles.module.css';
import Card2 from './Card2';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';

const CardChart = ({
  chartData,
  dataToPlot: plotData,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  cardRef,
  isReport,
}) => {
  const dataToPlot = useMemo(() => plotData?.plotData || [], [plotData]);
  const dataToPlotObject = Object.values(dataToPlot[0]?.data[0].y[0] || {})[0] || {};
  const { percentage } = dataToPlotObject;
  const data = dataToPlotObject?.data;
  const { dataPrefix, textSuffix, dataSuffix, isCompare, formatter } = chartData.yAxis[0];
  const formattedData = numberFormatter({ type: formatter, value: data ?? 'NAN' });
  const title = (dataPrefix || '') + formattedData + (dataSuffix || '');
  return (
    <>
      <Card2
        title={title}
        text={textSuffix || ''}
        percentage={percentage}
        targetPoints={chartData.targetPoints}
        data={data}
        isCompare={isCompare}
        isReport={isReport}
        headerImage={chartData?.headerImage ?? ''}
      />
    </>
  );
};

export default CardChart;
