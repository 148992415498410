import React, { useState } from 'react';
import Slider from '@mui/material/Slider';

import { defaultSolidColorPalettes } from '../../../data/defaultSchema';
import { mls } from 'lib/multilanguagesupport';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
const SolidColorSelector = (solidColorProps) => {
  const {
    color,
    setColor,
    isPalette = true,
    selectColorTitle = isPalette ? mls('Select Manually') : mls('Select Color'),
  } = solidColorProps;
  const [selectedColor, setSelectedColor] = useState(color?.color ?? defaultSolidColorPalettes[0]);
  const debounceSelectedColor = useDebounce(selectedColor);
  const [selectedOpacity, setSelectedOpacity] = useState(color?.opacity ?? 100);
  const debounceSelectedOpacity = useDebounce(selectedOpacity);
  const handleColor = (e) => {
    if (e.target.value.length >= 8) {
      return;
    }
    if (e.target.value.length === 0) {
      return;
    }
    setSelectedColor(e.target.value.toUpperCase());
  };
  const handleOpacity = (e) => {
    if (e.target.value > 100) {
      return;
    }
    setSelectedOpacity(e.target.value);
  };

  const paletteOnClick = (elem) => {
    setSelectedColor(elem);
    setSelectedOpacity(100);
  };
  useUpdateEffect(() => {
    if (!color) return;
    setColor({ ...color, color: selectedColor, opacity: selectedOpacity });
  }, [debounceSelectedColor, debounceSelectedOpacity]);

  return (
    <div className='solidColorSelctor colorSelector'>
      {isPalette ? (
        <>
          <div className='titleDiv'>{mls('Colour Palette')}</div>
          <div className='colorPalettesOuterContainer'>
            <div className='colorPalettesInnerContainer'>
              {React.Children.toArray(
                defaultSolidColorPalettes.map((elem) => {
                  return (
                    <div
                      className='palette'
                      style={{ backgroundColor: elem }}
                      onClick={() => paletteOnClick(elem)}
                    ></div>
                  );
                })
              )}
            </div>
          </div>
        </>
      ) : null}
      <div className='titleDiv'>{selectColorTitle}</div>
      <div className='colorInputDiv'>
        <input
          type='text'
          className='colorHashInput'
          value={selectedColor}
          onChange={(e) => handleColor(e)}
        />
        <div
          className='colorDropperInput'
          style={{ backgroundColor: selectedColor, opacity: selectedOpacity / 100 }}
        >
          <input type='color' value={selectedColor} onChange={(e) => handleColor(e)} />
        </div>
        {/* <hr /> */}
        {/* <div className="colorOpacityInput">
            <input type="text" value={selectedOpacity} onChange={(e)=>handleOpacity(e)}/>
            <b>%</b>
            </div> */}
      </div>
      <div className='titleDiv'>
        {mls('Opacity')}
        <span>{selectedOpacity}%</span>
      </div>
      <Slider
        size='small'
        className='opacityRange'
        value={selectedOpacity}
        onChange={(e) => handleOpacity(e)}
        defaultValue={selectedOpacity}
        aria-label='Small'
        valueLabelDisplay='auto'
      />
    </div>
  );
};

export default SolidColorSelector;

// function opacitySchema(opacity ,selectedColor) {
//     if (opacity == 100){
//         return selectedColor
//     }
//     var newOpacity = (opacity < 10) ? '0' + opacity.toString() : opacity.toString();
//     return selectedColor+newOpacity
// }
