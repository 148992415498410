export const getAppID=(pathName:string)=> {
  return pathName.split('/')?.[1];
}

export default getAppID;

export const getDefaultAppID=(userData:{appsConnected:string[]})=> {
  return userData?.appsConnected?.[0];
}

