import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { highlight, languages } from 'prismjs/components/prism-core';
import { MdContentCopy } from 'react-icons/md';
import Editor from 'react-simple-code-editor';
import { toast } from 'react-toastify';

interface AIToQueryColumnProps {
  sqlQueryData: any;
  sqlQueryState: any;
  setsqlQueryData: Function;
  setIsSqlArea: Function;
  setSqlQuery: Function;
  setIsAnyChangesSqlCommands: Function;
}
const AIToQueryColumn = ({
  sqlQueryData,
  sqlQueryState,
  setsqlQueryData,
  setIsSqlArea,
  setSqlQuery,
  setIsAnyChangesSqlCommands,
}: AIToQueryColumnProps) => {
  const copyQueryToClipBoard = () => {
    const finalText = `${sqlQueryData.query}`;
    // Copy the text inside the text field
    navigator.clipboard.writeText(finalText);
    // Alert the copied text
    toast.success(mls('Copied the Query '));
  };
  const closeSqlAIQueryPopup = () => {
    setsqlQueryData((currentData: any) => ({
      ...currentData,
      queryState: sqlQueryState.close,
      query: '',
    }));
  };
  const handleUseButton = () => {
    setsqlQueryData((currentData: any) => ({
      ...currentData,
      queryState: sqlQueryState.close,
      query: '',
    }));
    setIsSqlArea((currentData: any) => {
      return true;
    });
    setSqlQuery((currentData: any) => {
      return `
      ${currentData}
  
      ${sqlQueryData.query}`;
      // return `
      // ${currentData}

      // -- New SQL statement Starts here
      // ${sqlQueryData.query}`
    });
    setIsAnyChangesSqlCommands(true);
  };
  return (
    <PopUpDivChild
      initialValue={sqlQueryData.queryState === sqlQueryState.open}
      setPopUp={closeSqlAIQueryPopup}
      className={'sqlAIQuery'}
      popupTitle={mls('Result From your Query')}
    >
      <div className='sqlInnerQueryArea'>
        <div className='editorArea'>
          <div className='editorTitle' onClick={() => copyQueryToClipBoard()}>
            {mls('Commands')}
            <span className='copyQueryToClipBoardIcon'>
              <MdContentCopy />
            </span>
          </div>
          <div className='mainEditorArea'>
            <Editor
              value={sqlQueryData.query || ''}
              onValueChange={() => {}}
              highlight={(code) => highlight(code, languages.sql)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                minHeight: '100%',
              }}
              placeholder='Write your Sql Query here'
              disabled
            />
          </div>
        </div>
        <div className='useButtonDiv' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button
            className='btn btn-sm btn-primary'
            style={{ marginLeft: 'auto' }}
            onClick={() => handleUseButton()}
          >
            {mls('Use')}
          </button>
        </div>
      </div>
    </PopUpDivChild>
  );
};

export default AIToQueryColumn;
