import React from 'react';

import BarType from './BarType/BarType';
import PieType from './PieType/PieType';
import { defaultMargin } from '../constants/constants';
import convertToGraphFormat from '../helpers/convertToGraphFormat';
import { dateFormatHelper } from '../helpers/dateFormatHelper';
import BarHorizontalType from './BarHorizontalType/BarHorizontalType';

const SingleAxisCharts = ({
  chartData,
  dataToPlot: plotData,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  cardRef,
  optionData,
  filterSchema,
  theme,
  chartHeight,
  isReport
}) => {
  const dataToPlot = plotData?.plotData || [];
  const chartID = chartData.chartID;
  const sheetID = chartData.sheetID;

  // Title of Chart
  const title = chartData.name;
  const desc = chartData.desc;
  // Type of Chart
  const type = chartData.chartType;
  // xAxis and yAxis
  const { xAxis, yAxis } = chartData;

  // color of chart
  const chartColor = chartData.chartColor;

  const gradientColor = chartData.gradientColor;
  // If the colors used for chart are monochromatic
  const colorType = chartData.colorType;

  // Method to only capitalize first letter
  const capitalizeOnlyFirst = (s) => {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const xOperations = capitalizeOnlyFirst(xAxis[0]?.operations?.type ?? '');
  const xAxisLabel = xOperations === '' ? xAxis[0].label : `${xOperations}[ ${xAxis[0].label} ]`;
  const xCategory = xAxis[0].dataType;
  const xColumn = xAxis[0].column;

  const yOperations = capitalizeOnlyFirst(yAxis[0]?.operations?.type ?? '');
  // const yAxisLabel = yOperations === '' ? yAxis[0].label : `${yOperations}[ ${yAxis[0].label} ]`;

  const yAxisLabel =
    theme.yAxisStyle?.label ||
    (chartData.yAxisLabel === ''
      ? yOperations === ''
        ? yAxis[0].label
        : `${yOperations}[ ${yAxis[0].label} ]`
      : chartData.yAxisLabel);

  const yCategory = yAxis[0].dataType;
  const yColumn = yAxis[0].column;

  // Constant for Grid Lines
  const xGrid = true;
  const yGrid = true;
  // Constant for margin
  const margin = defaultMargin;

  let data = convertToGraphFormat(type, dataToPlot, xAxis, yAxis);
  // Check if category is datetime, then update the values
  if (yCategory === 'DATETIME') {
    data = {
      ...data,
      y: [...dateFormatHelper(data.y)],
    };
  }
  if (xCategory === 'DATETIME') {
    data = {
      ...data,
      x: [...dateFormatHelper(data.x)],
    };
  }

  //  This Function enables onclicking bar, pie, bargroup filter will be applied
  const handleClick = (value) => {
    let col;
    if (xCategory === 'CAT') {
      col = xColumn.split('.').slice(1).join('.');
    } else if (yCategory === 'CAT') {
      col = yColumn.split('.').slice(1).join('.');
    }
    const schema = filterSchema.filter(
      (obj) => obj.columnName === col && obj.sheetID === sheetID
    )[0];
    // let label, column, tableName, dataType;
    // if (xCategory === "CAT") {

    //   label = xAxis[0].label;
    //   tableName = xColumn.split(".")[0];
    //   column = xColumn.split(".").slice(1).join(".");
    //   dataType = xCategory;
    // } else if (yCategory === "CAT") {
    //   label = yAxis[0].label;
    //   tableName = yColumn.split(".")[0];
    //   column = yColumn.split(".").slice(1).join(".");
    //   dataType = yCategory;
    // } else updateFilter({});

    if (schema) {
      const newFilters = {
        [schema?.tableName + '.' + schema?.columnName]: {
          column: schema.columnName,
          dataType: schema.dataType,
          tableName: schema.tableName,
          sheetID: schema.sheetID,
          value: [value],
        },
      };
      updateFilter(newFilters);
    }
  };

  // Function to determine the type of chart
  // and what component to return
  const selectChartType = () => {
    switch (type) {
      case 'BAR':
      case 'LINE':
      case 'AREA':
      case 'FUNNEL':
        return (
          <BarType
            data={data}
            title={title}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            yCategory={yCategory}
            xCategory={xCategory}
            xGrid={xGrid}
            yGrid={yGrid}
            margin={margin}
            type={type}
            cardRef={cardRef}
            chartID={chartID}
            useChartSettingsButton={useChartSettingsButton}
            isProd={isProd}
            chartColor={chartColor}
            cardWidth={cardWidth}
            colorType={colorType}
            handleClick={handleClick}
            gradientColor={gradientColor}
          />
        );
      case 'BARHORIZONTAL':
        return (
          <BarHorizontalType
            data={data}
            title={title}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            yCategory={yCategory}
            xCategory={xCategory}
            xGrid={xGrid}
            yGrid={yGrid}
            margin={margin}
            type={type}
            cardRef={cardRef}
            chartID={chartID}
            useChartSettingsButton={useChartSettingsButton}
            isProd={isProd}
            chartColor={chartColor}
            cardWidth={cardWidth}
            colorType={colorType}
            handleClick={handleClick}
            gradientColor={gradientColor}
            optionData={optionData}
          />
        );
      case 'PIE':
      case 'DONUT':
        return (
          <PieType
            type={type}
            desc={desc}
            title={title}
            data={data}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            chartID={chartID}
            cardRef={cardRef}
            useChartSettingsButton={useChartSettingsButton}
            isProd={isProd}
            chartColor={chartColor}
            cardWidth={cardWidth}
            colorType={colorType}
            gradientColor={gradientColor}
            handleClick={handleClick}
            theme={theme}
            chartHeight={chartHeight}
            optionData={optionData}
            chartData={chartData}
            isReport={isReport}
          />
        );

      default:
        throw new Error('Invalid chart type');
    }
  };

  return <>{selectChartType()}</>;
};

export default SingleAxisCharts;
