import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import './chatBot.scss';
import './scroll.scss';
import ChatArea from './components/chatArea';

import ChartSettingWrapper from '../chart-settings-lib';
import { getPlotData, getTableData, getTableSchema } from './utils/chatBotHelperFunction';
import useUpdateEffect from './hooks/useUpdateEffect';
import { v4 as uuidv4 } from 'uuid';
import NoAssetScreen from 'lib/reusable-components/noAssetScreens/NoAssetScreen';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';

// passed by props to the settings component
/**
 * Funcion: updateReduxSchema
 * params: schema, type, sheetID, dashID
 * type: 'sheet' | 'dash'
 * schema: schema of sheet | dash
 */
const ChatBotIndex = ({
  projectID,
  appID,
  updateReduxSchema,
  isPinningAllowed = true,
  allTableIDAndNamesMap = {},
  updateReduxSheetSchema = () => { },
}) => {
  const [isTablePopUp, setIsTablePopUp] = useState(false);
  const [isChartSettingWrapper, setIsChartSettingWrapper] = useState(false);
  const [chartSchema, setChartSchema] = useState({});
  const [isNewChart, setIsNewChart] = useState(true);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableSchema, setTableSchema] = useState({});
  const [allTables, setAllTables] = useState({});
  const [messages, setMessages] = useState([]);
  const [sheetID, setSheetID] = useState('');
  const [tableLoading, settableLoading] = useState(false);

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );

  const [dashboards, setDashboards] = useState(analyticsSchema.appDash);
  const [sheetSchema, setSheetSchema] = useState(analyticsSchema.appSheets ?? {});
  const updateSheet = (sheetSchema) => {
    setSheetSchema(sheetSchema);
  };

  const tablesObject = useMemo(() => {
    const getGroupTableData = ({ analyticsSchema, tableType }) => {
      let tableInGroup = {};
      Object.keys(tableType).forEach((tableTypeID) => {
        if (analyticsSchema?.[tableTypeID]) {
          tableInGroup = {
            ...tableInGroup,
            [tableTypeID]: {
              groupKey: tableTypeID,
              title: tableType[tableTypeID],
              options: analyticsSchema?.[tableTypeID],
            },
          };
        }
      });
      return tableInGroup;
    };
    const tableType = {
      appJointable: 'Join Table',
      appCustomtable: 'Custom Table',
      appDatatable: 'Data Table',
    };
    const finalGroupTable = getGroupTableData({ analyticsSchema: analyticsSchema, tableType });
    return finalGroupTable;
  }, [analyticsSchema]);

  const handleChartPopUp = (props) => {
    setIsChartSettingWrapper((currentValue) => {
      if (!currentValue) {
        if (props?.isNewChart === false) {
          let columnDropNumber = 0;

          if (props?.chartData?.xAxis) {
            columnDropNumber = columnDropNumber + props?.chartData?.xAxis.length;
          }
          if (props?.chartData?.yAxis) {
            columnDropNumber = columnDropNumber + props?.chartData?.yAxis.length;
          }
          const chartData = {
            ...props?.chartData,
            chartBuilderHelper: {
              columnDropNumber: columnDropNumber,
            },
          };
          setIsNewChart(false);
          setChartSchema({
            chartData,
            index: props?.index,
            table: props?.message?.table,
            message: props?.message,
          });
          return !currentValue;
        }
      }
      setIsNewChart(true);
      setChartSchema({ table: props?.message?.table });
      return !currentValue;
    });
  };

  const timeFrame = chartSchema?.message?.timeFrame ?? null;
  const onChartSave = async (onSaveProps) => {
    if (onSaveProps?.chartSchema?.skip !== false) {
      return;
    }
    const newChartSchema = onSaveProps?.chartSchema?.data;
    const plotDataResponse = await getPlotData({
      chartSchema: newChartSchema,
      appID,
      timeFrame,
      tableID: chartSchema?.table,
    });
    if (plotDataResponse.skip) {
      return;
    }
    const finalSchema = {
      schema: {
        [newChartSchema.chartID]: newChartSchema,
      },
      plotData: plotDataResponse.plotData,
    };
    const chartUpdateString = `Update ${newChartSchema.name}.`;
    setMessages((prev) => {
      return [...prev, { message: chartUpdateString, sender: 'user' }];
    });
    const newMessage = {
      ...chartSchema.message,
      response: finalSchema,
    };
    setIsChartSettingWrapper(false);
    setMessages((currentMessages) => [...currentMessages, newMessage]);
  };

  const handleChartFilter = async ({ response, index, isSkip, message, tableID }) => {
    if (isSkip !== false) {
      return;
    }
    const timeFrame = message?.timeFrame ?? undefined;
    const plotDataResponse = await getPlotData({
      chartSchema: response,
      appID,
      timeFrame,
      tableID: message.table,
    });

    if (plotDataResponse.skip) {
      return;
    }

    const finalSchema = {
      schema: {
        [response.chartID]: response,
      },
      plotData: plotDataResponse.plotData,
    };
    const newMassage = {
      ...message,
      response: finalSchema,
    };
    setMessages((currentMessages) => [...currentMessages, newMassage]);
  };

  const onChartDelete = () => {
    setIsChartSettingWrapper(false);
  };
  useUpdateEffect(async () => {
    settableLoading(true);
    const tableSchemaData = await getTableSchema({
      allTables,
      selectedTable,
      appID,
      appSheets: sheetSchema,
      setAllTables,
      updateSheet,
      updateReduxSheetSchema,
      allTableIDAndNamesMap,
    });
    if (tableSchemaData?.skip) {
      if (tableSchemaData?.skipFrom === 'sheetID') {
        setSheetID('');
      }
      if (tableSchemaData?.skipFrom === 'tableData') {
        setTableData([]);
      }
      settableLoading(false);
      return;
    }
    setIsTablePopUp(true);
    setSheetID(tableSchemaData.sheetID);
    setTableData(tableSchemaData.tableData);
    setTableSchema(tableSchemaData.schema);
    settableLoading(false);
  }, [selectedTable]);
  const stateProps = {
    isTablePopUp,
    setIsTablePopUp,
    isChartSettingWrapper,
    setIsChartSettingWrapper,
    chartSchema,
    setChartSchema,
    isNewChart,
    setIsNewChart,
    selectedTable,
    setSelectedTable,
    tableData,
    setTableData,
    tableSchema,
    setTableSchema,
    allTables,
    setAllTables,
    messages,
    setMessages,
    sheetID,
    setSheetID,
    dashboards,
    setDashboards,
    sheetSchema,
    updateSheet,
    tableLoading,
    tablesObject,
    isPinningAllowed,
  };
  useEffect(() => {
    const id = document.getElementById('kt_scrolltop');
    if (id?.style?.display) {
      id.style.display = 'none';
    }
    return () => (id.style.display = 'flex');
  }, []);
  const tablesIds = [
    ...Object.keys(analyticsSchema?.appDatatable || {}),
    ...Object.keys(analyticsSchema?.appJointable || {}),
    ...Object.keys(analyticsSchema?.appCustomtable || {}),
  ];
  // showing an error message if there is no dashboard added.
  if (Object.keys(tablesIds).length === 0) return <NoAssetScreen assetType='SupistaAI' />;
  return (
    <Box className='ChatBot Scroll'>
      {isChartSettingWrapper ? (
        <ChartSettingWrapper
          handleChartPopUp={handleChartPopUp}
          appID={appID}
          chartID={chartSchema?.chartData?.chartID ?? uuidv4()}
          sheetID={getTableData({
            allTables,
            tableID: chartSchema?.table,
            defaultData: sheetID,
            target: 'sheetID',
          })}
          projectID={projectID}
          tableSchema={
            getTableData({
              allTables,
              defaultData: tableSchema,
              tableID: chartSchema?.table,
              target: 'tableSchema',
            })?.data
          }
          defaultDateTimeCol={
            getTableData({
              allTables,
              defaultData: tableSchema,
              tableID: chartSchema?.table,
              target: 'tableSchema',
            })?.defaultTable?.defaultDatetimeCol || ''
          }
          chartSchema={chartSchema.chartData}
          isNewChart={isNewChart}
          updateProjectSchema={onChartDelete}
          isJoinedTable={tableSchema?.isJoinedTable}
          isChatBot={true}
          onSave={onChartSave}
          timeFrameObj={timeFrame ?? {}}
          tableID={chartSchema?.table}
        />
      ) : null}
      <ChatArea
        isTablePopUp={isTablePopUp}
        setIsTablePopUp={setIsTablePopUp}
        tableData={tableData}
        sheetID={sheetID}
        allTables={allTables}
        handleChartPopUp={handleChartPopUp}
        tableSchema={tableSchema}
        appID={appID}
        allTableIDAndNamesMap={allTableIDAndNamesMap}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        messages={messages}
        setMessages={setMessages}
        appDash={dashboards}
        setDashboards={setDashboards}
        handleChartFilter={handleChartFilter}
        appSheets={sheetSchema}
        setAllTables={setAllTables}
        updateReduxSchema={updateReduxSchema}
        updateReduxSheetSchema={updateReduxSheetSchema}
        updateSheet={updateSheet}
        stateProps={stateProps}
      />
    </Box>
  );
};

export default ChatBotIndex;
