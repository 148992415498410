/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import _ from 'lodash';
// import {useIntl} from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import './accordianStyle.scss';

type Props = {
  appID: string;
};

const AsideMenuMain: React.FC<Props> = ({ appID }) => {
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const dashSchema = analyticsSchema?.appDash || {};
  const sheetsSchema = analyticsSchema?.appSheets || {};
  const sortedSheetsSchema = _.sortBy(Object.values(sheetsSchema), (obj: any) => {
    return -obj.updatedAt;
  });
  const sortedDashSchema = _.sortBy(Object.values(dashSchema), (obj: any) => {
    return -obj.updatedAt;
  });

  // const intl = useIntl()
  const accessType = analyticsSchema?.appAccess?.accessType || [];

  return (
    <>
      {/* <AsideMenuItemWithSub
        to={`/${appID}/favourites`}
        title={mls('Favourites')}
        fontIcon='bi-person'
        key={'Favourites-nav01'}
      ></AsideMenuItemWithSub> */}
      <AsideMenuItem
        to={`/${appID}/supistaAI`}
        title={mls('Supista AI')}
        fontIcon='bi-person'
        key={'Chat-nav01'}
      />
      <AsideMenuItem
        key={'kpiDashboard'}
        to={`/${appID}/kpiDashboard`}
        title={mls('KPI Dashboard')}
        fontIcon='bi-person'
      />
      <AsideMenuItemWithSub
        to={`/${appID}/dashboard`}
        title={mls('Dashboard')}
        fontIcon='bi-person'
        key={'Dashboard-nav02'}
        className={'menuSubAccordionMaxHeight hover-scroll-overlay-y'}
      >
        {(sortedDashSchema || []).map((sch: any) => (
          <AsideMenuItem
            to={`/${appID}/dashboard/${sch?.dashID}`}
            title={mls(sch?.name || '')}
            fontIcon='bi-person'
            key={`${sch?.dashID}-nav02`}
          />
        ))}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to={`/${appID}/sheets`}
        title={mls('Sheets')}
        fontIcon='bi-person'
        key={'Sheets-nav03'}
        className={'menuSubAccordionMaxHeight hover-scroll-overlay-y'}
      >
        {(sortedSheetsSchema || []).map((sch: any) => (
          <AsideMenuItem
            to={`/${appID}/sheets/${sch?.sheetID}`}
            title={mls(sch?.name)}
            fontIcon='bi-person'
            key={`${sch?.sheetID}-nav03`}
            hasBullet
          />
        ))}
      </AsideMenuItemWithSub>
      {(accessType.includes('ALLOWED_ALL') ||
        accessType.includes('ALL_CREATOR') ||
        accessType.includes('CHART_CREATOR')) && (
        <AsideMenuItem
          to={`/${appID}/reports`}
          title={mls('Reports')}
          fontIcon='bi-person'
          key={'Reports-nav04'}
        />
      )}
      {(accessType.includes('ALLOWED_ALL') || accessType.includes('ALL_CREATOR')) && (
        <AsideMenuItemWithSub
          to={`#`}
          title={mls('Data Lake')}
          fontIcon='bi-layers'
          className={'menuSubAccordionMaxHeight hover-scroll-overlay-y'}
        >
          <AsideMenuItem
            key={'Connections'}
            to={`/${appID}/datalake/Connections`}
            title={mls('Connections')}
            hasBullet={true}
          />
          <AsideMenuItem
            key={'datatable'}
            to={`/${appID}/datalake/DataTable`}
            title={mls('Data Tables')}
            hasBullet={true}
          />
          <AsideMenuItem
            key={'jointable'}
            to={`/${appID}/datalake/JoinTables`}
            title={mls('Join Tables')}
            hasBullet={true}
          />
          <AsideMenuItem
            key={'customtable'}
            to={`/${appID}/datalake/CustomTable`}
            title={mls('Custom Tables')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {accessType.includes('ALLOWED_ALL') && (
        <AsideMenuItemWithSub
          to={`#`}
          title={mls('Users & Roles')}
          fontIcon='bi-layers'
          className={'menuSubAccordionMaxHeight hover-scroll-overlay-y'}
        >
          <AsideMenuItem
            key={'Teams'}
            to={`/${appID}/roleAccess/Teams`}
            title={mls('Teams')}
            hasBullet={true}
          />
          <AsideMenuItem
            key={'Users'}
            to={`/${appID}/roleAccess/Users`}
            title={mls('Users')}
            hasBullet={true}
          />
          <AsideMenuItem
            key={'orgStructure'}
            to={`/${appID}/roleAccess/orgStructure`}
            title={mls('Organisation Structure')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
    </>
  );
};

export { AsideMenuMain };
