export const defaultDataType = {
  ALL: 'ALL',
  NUM: 'NUM',
  CAT: 'CAT',
  DATETIME: 'DATETIME',
  GEO: 'GEO',
  ID: 'ID',
  ID_TEXT: 'ID_TEXT',
  ID_NUM: 'ID_NUM',
};
export const defaultDataTypeStyles = {
  [defaultDataType.ALL]: {
    key: defaultDataType.ALL,
    name: 'All',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.NUM]: {
    key: defaultDataType.NUM,
    name: 'Numerical',
    icon: '#123',
    color: 'green',
  },
  [defaultDataType.CAT]: {
    key: defaultDataType.CAT,
    name: 'Categorical',
    icon: 'Abc',
    color: 'black',
  },
  [defaultDataType.DATETIME]: {
    key: defaultDataType.DATETIME,
    name: 'Date Time',
    icon: 'DATE',
    color: 'orange',
  },
  [defaultDataType.GEO]: {
    key: defaultDataType.GEO,
    name: 'Geographical',
    icon: 'GEO',
    color: 'black',
  },
  // [defaultDataType.ID]: {
  //   key: defaultDataType.ID,
  //   name: 'Identifier',
  //   icon: 'ID',
  //   color: 'black',
  // },
  [defaultDataType.ID_TEXT]: {
    key: defaultDataType.ID_TEXT,
    name: 'Text Identifier',
    icon: 'ID',
    color: 'black',
  },
  [defaultDataType.ID_NUM]: {
    key: defaultDataType.ID_NUM,
    name: 'Num Identifier',
    icon: 'ID',
    color: 'black',
  },
};
export const defaultDateKey = {
  CUSTOM: 'CUSTOM',
  LAST7DAYS: 'LAST7DAYS',
  LASTXDAYS: 'LASTXDAYS',
  defalutLastDay: 30,
  mainKey: 'period',
};
export const defaultFilterByType = [
  defaultDataType.ALL,
  defaultDataType.CAT,
  defaultDataType.NUM,
  defaultDataType.DATETIME,
  defaultDataType.GEO,
  defaultDataType.ID,
];
export const defaultDateObject = {
  [`TODAY`]: 'Today',
  [`YESTERDAY`]: 'Yesterday',
  [`THISWEEK`]: 'This week',
  [`LAST7DAYS`]: 'Last 7 days',
  [`THISMONTH`]: 'This month',
  [`LAST30DAYS`]: 'Last 30 days',
  [`THISQUARTER`]: 'This quarter',
  [`THISYEAR`]: 'This year',
  [`CUSTOM`]: 'Custom Date',
  [`LASTXDAYS`]: 'Last X day',
};
export const defaultDateArray = [
  {
    name: 'Today',
    [defaultDateKey.mainKey]: 'TODAY',
  },
  {
    name: 'Yesterday',
    [defaultDateKey.mainKey]: 'YESTERDAY',
  },
  {
    name: 'This week',
    [defaultDateKey.mainKey]: 'THISWEEK',
  },
  {
    name: 'Last 7 days',
    [defaultDateKey.mainKey]: 'LAST7DAYS',
  },
  {
    name: 'This month',
    [defaultDateKey.mainKey]: 'THISMONTH',
  },
  {
    name: 'Last 30 days',
    [defaultDateKey.mainKey]: 'LAST30Days',
  },
  {
    name: 'This quarter',
    [defaultDateKey.mainKey]: 'THISQUARTER',
  },
  {
    name: 'Last X day',
    [defaultDateKey.mainKey]: defaultDateKey.LASTXDAYS,
  },
  {
    name: 'This year',
    [defaultDateKey.mainKey]: 'THISYEAR',
  },
  {
    name: 'Custom Date',
    [defaultDateKey.mainKey]: defaultDateKey.CUSTOM,
  },
];
