import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React from 'react';
import { organiztionStuctureType } from '../../data/organizationData';
import MultiSelect from 'lib/reusable-components/Components/Multiselect/MultiSelect';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import { CircularProgress } from '@mui/material';

interface editSelectedOrganistainElemProps {
  isNew: boolean;
  data: { [key: string]: any };
  selectedOrganisationField: { [key: string]: any }[];
  setData: Function;
  isPopup: boolean;
  setPopup: Function;
  handleSave: Function;
  index: number;
  selectedOrganisationText: string;
  organizationStructureProps: any;
  loading: boolean;
}
const EditSelectedOrganistainElem = ({
  isNew,
  data,
  index,
  setData,
  isPopup,
  setPopup,
  selectedOrganisationField,
  handleSave,
  selectedOrganisationText,
  organizationStructureProps,
  loading,
}: editSelectedOrganistainElemProps) => {
  const handleFormSave = (e: React.FormEvent) => {
    e.preventDefault();
    handleSave({ isNew, data: data, index });
  };
  return (
    <PopUpDivChild
      initialValue={isPopup}
      setPopUp={setPopup}
      popupTitle={isNew ? `Add ${selectedOrganisationText}` : `Edit ${selectedOrganisationText}`}
      className='editSelectedOrganistainElem'
    >
      <form onSubmit={(e) => handleFormSave(e)} className='editSelectedOrganistainElemForm'>
        {React.Children.toArray(
          selectedOrganisationField.map((selectedOrganisationFieldElem: { [key: string]: any }) => {
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.inputString) {
              return (
                <>
                  <div className='titileDiv'>
                    {selectedOrganisationFieldElem?.label}:{' '}
                    {selectedOrganisationFieldElem.required ? (
                      <span style={{ color: 'red' }}>*</span>
                    ) : null}
                  </div>
                  <input
                    className='primaryInput'
                    type='text'
                    value={
                      data[selectedOrganisationFieldElem?.key] ??
                      selectedOrganisationFieldElem?.defaultValue
                    }
                    maxLength={selectedOrganisationFieldElem?.maxLength}
                    required={selectedOrganisationFieldElem.required}
                    placeholder={selectedOrganisationFieldElem?.label}
                    onChange={(e) =>
                      setData({ key: selectedOrganisationFieldElem?.key, value: e.target.value })
                    }
                  />
                </>
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.email) {
              return (
                <>
                  <div className='titileDiv'>
                    {selectedOrganisationFieldElem?.label}:{' '}
                    {selectedOrganisationFieldElem.required ? (
                      <span style={{ color: 'red' }}>*</span>
                    ) : null}
                  </div>
                  <input
                    className='primaryInput'
                    type='email'
                    value={
                      data[selectedOrganisationFieldElem?.key] ??
                      selectedOrganisationFieldElem?.defaultValue
                    }
                    maxLength={selectedOrganisationFieldElem?.maxLength}
                    required={selectedOrganisationFieldElem.required}
                    placeholder={selectedOrganisationFieldElem?.label}
                    onChange={(e) =>
                      setData({ key: selectedOrganisationFieldElem?.key, value: e.target.value })
                    }
                  />
                </>
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.circleMultiSelect) {
              return (
                <MultiSelectElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.circlesData}
                  mainKey={'circleID'}
                />
              );
            }
            if (
              selectedOrganisationFieldElem.type === organiztionStuctureType.departmentMultiSelect
            ) {
              return (
                <MultiSelectElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.departmentsData}
                  mainKey={'departmentID'}
                />
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.departmentDropdown) {
              return (
                <DropDownElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.departmentsData}
                />
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.circleDropdown) {
              return (
                <DropDownElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.circlesData}
                />
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.employeesDropdown) {
              return (
                <DropDownElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.employeesData}
                />
              );
            }
            if (selectedOrganisationFieldElem.type === organiztionStuctureType.managerDropdown) {
              return (
                <DropDownElem
                  selectedOrganisationFieldElem={selectedOrganisationFieldElem}
                  data={data}
                  setData={setData}
                  options={organizationStructureProps?.supistaUsers}
                />
              );
            }
            return <div className='selectedOrganistionOptionCell '>Unknown Type</div>;
          })
        )}
        <div className='primaryButtonContainer'>
          <button className='primaryButton' type='submit'>
            {isNew ? 'Add' : 'Update'}
            {loading ? (
              <>
                &nbsp;&nbsp;
                <CircularProgress color='inherit' size={10} />
              </>
            ) : null}
          </button>
        </div>
      </form>
    </PopUpDivChild>
  );
};

export default EditSelectedOrganistainElem;

interface multiSelectElemProps {
  selectedOrganisationFieldElem: { [key: string]: any };
  data: { [key: string]: any };
  options: { [key: string]: any }[];
  setData: Function;
  mainKey?: string;
}
const MultiSelectElem = ({
  selectedOrganisationFieldElem,
  data,
  setData,
  options,
  mainKey = 'id',
}: multiSelectElemProps) => {
  return (
    <>
      <div className='titileDiv'>
        {selectedOrganisationFieldElem?.label}:{' '}
        {selectedOrganisationFieldElem.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <MultiSelect
        options={options}
        onSelect={(action: any) =>
          setData({ key: selectedOrganisationFieldElem?.key, value: action })
        }
        onRemove={(action: any) =>
          setData({ key: selectedOrganisationFieldElem?.key, value: action })
        }
        selectedValues={
          data[selectedOrganisationFieldElem?.key] ?? selectedOrganisationFieldElem?.defaultValue
        }
        mainKey={mainKey}
        optionType={'objectInArray'}
        chipStyle={'borderChip'}
      />
    </>
  );
};
interface dropDownElemProps {
  selectedOrganisationFieldElem: { [key: string]: any };
  data: { [key: string]: any };
  options: { [key: string]: any }[];
  setData: Function;
}
const DropDownElem = ({
  selectedOrganisationFieldElem,
  data,
  setData,
  options,
}: dropDownElemProps) => {
  return (
    <>
      <div className='titileDiv'>
        {selectedOrganisationFieldElem?.label}:{' '}
        {selectedOrganisationFieldElem.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <ArrayDropDown
        array={options}
        dropDownValue={
          data[selectedOrganisationFieldElem?.key] ?? selectedOrganisationFieldElem?.defaultValue
        }
        setdropDownValue={(action: any) =>
          setData({ key: selectedOrganisationFieldElem?.key, value: action })
        }
        mainKey={selectedOrganisationFieldElem.mainKey ?? 'id'}
        nameKey={selectedOrganisationFieldElem.nameKey ?? 'name'}
        ObjectInArray
      />
    </>
  );
};
