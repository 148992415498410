import React from 'react';
import { FiX } from 'react-icons/fi';
import SelectTargetPoint from './SelectTargetPoint';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { getCircleAndEmployeesGroupedData } from 'lib/supista-kpi/utils/supistaKpiHelperFunction';
import { defaultChartSetting } from '../../data/defaultSchema';
import { mls } from 'lib/multilanguagesupport';

interface allTargetPointProps {
  stateProps: { [key: string]: any };
  selectedKpiTimeFrameRange: string;
  allTargetPointsElem: { [key: string]: any };
  allTargetPointsIndex: number;
  setFilterPopupData: Function;
  handleAllTargetPoint: Function;
  disableCirclesDataArray: string[];
}
const AllTargetPoint = ({
  stateProps,
  selectedKpiTimeFrameRange,
  allTargetPointsElem,
  allTargetPointsIndex,
  setFilterPopupData,
  handleAllTargetPoint,
  disableCirclesDataArray,
}: allTargetPointProps) => {
  const {
    circlesData,
    employeesData,
    kpiProjectedTargetPoints,
    setKpiTargetPoints,
    kpiTargetPoints,
  } = stateProps;
  const removeTargetPointsInAllTargetPoints = ({ index }: { index: number }) => {
    const copyKpiCategory: { [key: string]: any } = Array.from(
      kpiTargetPoints.allTargetPoints ?? []
    );
    const [selectedKpiCategory] = copyKpiCategory.splice(index, 1);
    let selectedTargetPointID = kpiTargetPoints.selectedTargetPointID;
    if (kpiTargetPoints.selectedTargetPointID.id === selectedKpiCategory.circleID) {
      selectedTargetPointID = defaultChartSetting.targetPoints.selectedTargetPointID;
    }
    setKpiTargetPoints({
      ...kpiTargetPoints,
      allTargetPoints: copyKpiCategory,
      selectedTargetPointID: selectedTargetPointID,
    });
  };
  const selectTargetPoint = (action: string) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      selectedTargetPointID: action,
    });
  };
  const filterNumber = Object.keys(allTargetPointsElem.targetFilter ?? {}).length;

  const circleAndEmployeesGroupedData = getCircleAndEmployeesGroupedData({
    circlesData,
    employeesData,
  });
  return (
    <div className='circleWiseTargetPointTableElem'>
      <div
        className='selectCellDiv'
        onClick={() => selectTargetPoint(allTargetPointsElem?.targetPointID)}
      >
        <input
          type='radio'
          checked={
            kpiTargetPoints?.selectedTargetPointID?.id === allTargetPointsElem?.targetPointID?.id
          }
          defaultChecked={
            kpiTargetPoints?.selectedTargetPointID?.id === allTargetPointsElem?.targetPointID?.id
          }
          onChange={() => {}}
        />
      </div>
      <div className='circleCellDiv'>
        <ObjectDropDown
          object={circleAndEmployeesGroupedData}
          dropDownValue={allTargetPointsElem?.targetPointID?.id ?? ''}
          disableArray={disableCirclesDataArray}
          setdropDownValue={(action: { [key: string]: any }) => {
            handleAllTargetPoint({
              data: {
                id: action.data,
                type: action.groupKey,
              },
              key: 'targetPointID',
              index: allTargetPointsIndex,
            });
          }}
          inputPlaceHolder={mls('Select')}
          groupDropDown
          returnGroupKeyWithValue
        />
      </div>
      <div className='targetPoinCellDiv'>
        <SelectTargetPoint
          kpiProjectedTargetPoints={kpiProjectedTargetPoints}
          selectedKpiTimeFrameRange={selectedKpiTimeFrameRange}
          targetPoint={allTargetPointsElem.targetValue}
          allTargetPointsElem={allTargetPointsElem}
          setTargetPoint={(action: string) =>
            handleAllTargetPoint({
              data: action,
              key: 'targetValue',
              index: allTargetPointsIndex,
            })
          }
        />
      </div>
      <div className='filterCellDiv'>
        <button
          className='primaryButton secondaryButtonColor'
          onClick={() => {
            setFilterPopupData({
              isPopup: true,
              targetFilter: allTargetPointsElem.targetFilter ?? {},
              filterIndex: allTargetPointsIndex,
            });
          }}
        >
          {mls('Filters')}
          {filterNumber >= 1 ? (
            <div className='filterButtonNumber'>{filterNumber >= 100 ? '99+' : filterNumber}</div>
          ) : null}
        </button>
      </div>
      <div
        className='removeCellDiv'
        onClick={() => {
          removeTargetPointsInAllTargetPoints({
            index: allTargetPointsIndex,
          });
        }}
      >
        <FiX />
      </div>
    </div>
  );
};

export default AllTargetPoint;
