import { mls } from 'lib/multilanguagesupport';
import { toAbsoluteUrl } from '_metronic/helpers';
import DataLakeCard from 'lib/data-connectors/dependencies/DataLakeCard/DataLakeCard';
import { getSheetChartsIDsTBD } from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import { useEffect, useState } from 'react';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { useLocation } from 'react-router-dom';

export const Card = ({
  title,
  description,
  numOfColumns,
  index,
  handleShow,
  tableNumber,
  tableID,
}) => {
  const [selectedSheetID, setSelectedSheetID] = useState('');

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  useEffect(() => {
    const { sheetIDsTBD } = getSheetChartsIDsTBD(
      [tableID ?? ''],
      [],
      analyticsSchema?.appSheets || {}
    );
    setSelectedSheetID(sheetIDsTBD[0] ?? '');
  }, [tableID, analyticsSchema?.appSheets]);
  return (
    <DataLakeCard
      index={index}
      settingButtonFunction={handleShow}
      titleButtonFunction={handleShow}
      title={title}
      backGroundImage
      bottomButtonFunction={handleShow}
      bottomButtonTitle={mls('Go To Table')}
      description={description}
    >
      <p className=' fw-bold fs-5 m-0 text-muted mt-4 mb-3'>
        <strong>{mls('No. of table')}: </strong>
        {mls(tableNumber)}
      </p>
      {selectedSheetID ? (
        <a
          href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
          target='_blank'
          rel='noreferrer'
        >
          <span className='linkButton'>{mls('Go To Sheet')}</span>
        </a>
      ) : (
        <div className='noSheet text-muted'>{mls('No Sheet Connected')}</div>
      )}
    </DataLakeCard>
  );
};
