import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import {
  dateTimeDefaultOption,
  defaultFormattingType,
  defaultFormattingValue,
} from '../reusableData/formatterData';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';

interface formatterProps {
  type: any;
  value: number | string;
  short?: boolean;
}
interface dateFormatterProps {
  format: string;
  option: any;
  date: Date;
}
const dateFormatter = ({ format, option, date }: dateFormatterProps) => {
  const formatter = new Intl.DateTimeFormat(format, option).format(date);
  return formatter;
};
export const formatter = ({ type, value }: formatterProps) => {
  try {
    // Checking for NAN value
    if (type.formatterType === 'number') {
      const numberValue = Number(value);

      if (isNaN(numberValue)) {
        throw new Error('Value are NAN');
      }

      const formatatOptions =
        defaultFormattingType.num[type.format as keyof typeof defaultFormattingType.num];
      if (formatatOptions === undefined || formatatOptions === null) {
        throw new Error('Format Error');
      }

      const formatter = new Intl.NumberFormat(formatatOptions.formatKey, {
        notation: type?.compact ? 'compact' : 'standard',
        style: type?.currency ? 'currency' : 'decimal',
        // currency: formatatOptions.currency,
        currency: type.currencyType,
        minimumFractionDigits: 0,
        maximumFractionDigits: type?.maxDecimal ?? 2,
      });

      return formatter.format(numberValue);
    }

    // DATETIME

    if (type.formatterType === 'dateTime') {
      const stringValue = value?.toString();
      const compact = type?.compact;
      const formatatOptions: any =
        defaultFormattingType.dateTime[type.format as keyof typeof defaultFormattingType.dateTime];
      if (formatatOptions === undefined || formatatOptions === null) {
        throw new Error('Format Error');
      }
      const getDateTimeOption = ({ options, key, format }: any) => {
        const option = options[key as keyof typeof options];
        if (!option) {
          return dateTimeDefaultOption[format as keyof typeof dateTimeDefaultOption];
        }
        return option;
      };

      if (type.format === defaultFormattingType.dateTime.DAY.key) {
        const dateTimeOption = getDateTimeOption({
          options: defaultFormattingType.dateTime.DAY.dateTimeOptions,
          key: type?.contry,
          format: type.format,
        });
        let date = new Date(stringValue);
        const formatter = dateFormatter({
          format: dateTimeOption?.formatKey,
          option: dateTimeOption?.option,
          date,
        });
        return formatter;
      }
      if (type.format === defaultFormattingType.dateTime.WEEK.key) {
        const year = stringValue.substring(0, 4);

        // getting day by weekNumber
        const weekNum = parseInt(stringValue.substring(4, stringValue.length)).toString();
        if (isNaN(Number(weekNum)) || isNaN(Number(year))) {
          return stringValue;
        }

        let startingOfTheWeek = new Date(Number(year), 0, 1 + (Number(weekNum) - 1) * 7); // Sunday
        while (startingOfTheWeek.getDay() !== 0) {
          startingOfTheWeek.setDate(startingOfTheWeek.getDate() - 1);
        }
        let endOfTheWeek = new Date(startingOfTheWeek);
        endOfTheWeek.setDate(endOfTheWeek.getDate() + 6);
        // getting day by weekNumber

        const dateTimeOption = getDateTimeOption({
          options: defaultFormattingType.dateTime.WEEK.dateTimeOptions,
          key: type?.contry,
          format: type.format,
        });

        const startFormated = dateFormatter({
          format: dateTimeOption?.formatKey,
          option: dateTimeOption?.option,
          date: startingOfTheWeek,
        });
        if (dateTimeOption?.spacialCase?.case) {
          switch (dateTimeOption?.spacialCase?.caseType) {
            case defaultFormattingType.dateTime.WEEK.dateTimeOptions.W40.spacialCase.caseType:
              return `W${weekNum}, ${year}`;
            case defaultFormattingType.dateTime.WEEK.dateTimeOptions.WEEK40.spacialCase.caseType:
              return `WEEK ${weekNum}, ${year}`;
            case defaultFormattingType.dateTime.WEEK.dateTimeOptions.W_IN_S_SOLO.spacialCase
              .caseType:
              return `${startFormated}`;

            default:
              break;
          }
        }
        const endFormated = dateFormatter({
          format: dateTimeOption?.formatKey,
          option: dateTimeOption?.option,
          date: endOfTheWeek,
        });
        return `${startFormated} - ${endFormated}`;
      }
      if (type.format === defaultFormattingType.dateTime.MONTH.key) {
        const year = stringValue.substring(0, 4);
        const monthNo = stringValue.substring(4, stringValue.length);

        const dateTimeOption = getDateTimeOption({
          options: defaultFormattingType.dateTime.MONTH.dateTimeOptions,
          key: type?.contry,
          format: type.format,
        });
        let date = new Date(`${year}-${monthNo}`);
        if (!dateTimeOption?.spacialCase?.case) {
          const formater = dateFormatter({
            format: dateTimeOption.formatKey,
            option: dateTimeOption.option,
            date,
          });
          return `${formater}`;
        }
        const formatedYear = dateFormatter({
          format: 'en-US',
          option: { year: dateTimeOption?.option?.year },
          date,
        });
        const formatedMonth = dateFormatter({
          format: 'en-US',
          option: { month: dateTimeOption?.option?.month },
          date,
        });
        return `${formatedMonth}'${formatedYear}`;
      }
      if (type.format === defaultFormattingType.dateTime.QUARTER.key) {
        const year = stringValue.substring(0, 4);
        let date = new Date(`${year}`);

        const dateTimeOption = getDateTimeOption({
          options: defaultFormattingType.dateTime.QUARTER.dateTimeOptions,
          key: type?.contry,
          format: type.format,
        });
        const formatter = dateFormatter({
          format: dateTimeOption?.formatKey,
          option: { year: dateTimeOption?.option?.year },
          date,
        });
        const quatNo = stringValue.substring(4, stringValue.length);
        if (dateTimeOption?.spacialCase?.case) {
          return `${dateTimeOption?.prefix} ${Number(quatNo)}'${formatter}`;
        }
        return `${dateTimeOption?.prefix} ${Number(quatNo)}, ${formatter}`;
      }
      if (type.format === defaultFormattingType.dateTime.YEAR.key) {
        return `${stringValue}`;
      }
      const defaultDateFormatter = singleDateFormatter(stringValue, type.format);
      return defaultDateFormatter;
    }
    throw new Error('Format Type Not Found');
  } catch (error) {
    console.error(error);
    return value;
  }
};

export const numberFormatter = ({ type, value, short = false }: formatterProps) => {
  const addDefaultFormatter = {
    ...type,
    format: type?.format ?? defaultFormattingValue.num.key,
    compact: short ? true : type?.compact ?? defaultFormattingValue.num.defaultCompact,
    currency: type?.currency ?? defaultFormattingValue.num.defaultCurrency,
    currencyType: type?.currencyType ?? defaultFormattingValue.num.currencyType,
    formatterType: type?.formatterType ?? defaultFormattingValue.num.formatterType,
  };
  return formatter({ type: addDefaultFormatter, value });
};
export const dateTimeFormatter = ({ type, value, short = false }: formatterProps) => {
  const addDefaultFormatter = {
    ...type,
    format: type?.format ?? defaultFormattingValue.dateTime.key,
    compact: short ? true : type?.compact ?? defaultFormattingValue.dateTime.defaultCompact,
    contry: type?.contry ?? defaultFormattingValue.dateTime.contry,
    formatterType: type?.formatterType ?? defaultFormattingValue.dateTime.formatterType,
  };
  return formatter({ type: addDefaultFormatter, value });
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const singleDateFormatter = (e: any, toggle: string) => {
  const eStr = e.toString();
  if (toggle === 'DAY') {
    const x = new Date(e).getTime();
    const dateFormat = new Date(x).toLocaleDateString('en-us', {
      day: 'numeric',
      month: 'short',
      year: '2-digit',
    });
    return dateFormat;
  } else if (toggle === 'WEEK') {
    const year = eStr.substring(0, 4);
    const weekNo = parseInt(eStr.substring(4, eStr.length)).toString();
    return 'Week ' + weekNo + ', ' + year;
  } else if (toggle === 'MONTH') {
    const year = eStr.substring(0, 4);
    const monthNo = eStr.substring(4, eStr.length);
    return months[monthNo - 1] + ' ' + year;
  } else if (toggle === 'QUARTER') {
    const year = eStr.substring(0, 4);
    const quatNo = eStr.substring(4, eStr.length);
    return 'Quarter ' + quatNo + ', ' + year;
  } else if (toggle === 'YEAR') {
    return e;
  } else {
    const x = new Date(e).getTime();
    const dateFormat = new Date(x).toLocaleDateString('en-us', {
      day: 'numeric',
      month: 'short',
      // year: '2-digit',
    });
    return dateFormat;
  }
};

interface deleteDateTimeToggle {
  dispatch: Function;
  toggle: any;
  chartID: string;
}
export const deleteDateTimeToggle = ({ dispatch, toggle, chartID }: deleteDateTimeToggle) => {
  const newTimeFrameData = { ...toggle };
  delete newTimeFrameData[chartID];
  dispatch(
    create({
      setPath: reduxConstants.config.DATE_TIME_TOGGLE,
      value: { toggle: newTimeFrameData },
    })
  );
};
