import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import React, { useMemo, useRef, useState } from 'react';
import { ordinalColorScale } from '../legendHelper';
import { getDefaultColorByIndex } from '../colorHelper';
import { GoX } from 'react-icons/go';
import './ordinalLegend.scss';
import { mls } from 'lib/multilanguagesupport';
const useOrdinalLegend = (lengendProps) => {
  const {
    legendGlyphSize = 20,
    colorScale,
    labels: labelObject,
    shape,
    isReport = false,
    // handleShowMore = () => {},
  } = lengendProps;
  const { maxlableShow = isReport ? 10 : 5 } = lengendProps;
  const [legendIndex, setClick] = useState(null);
  const [isShowMore, setisShowMore] = useState(false);
  const legendDivRef = useRef(null);
  // static scale
  const variantScale = useMemo(
    () => ordinalColorScale(Object.keys(colorScale), Object.values(colorScale)),
    [colorScale]
  );
  // dynamic scale
  const scale = useMemo(() => {
    if (legendIndex !== null) {
      if (legendIndex === -1)
        return ordinalColorScale(Object.keys(colorScale), Object.values(colorScale));
      const prev = Object.values(colorScale);
      const newColors = Array(prev.length).fill('#ccc');
      newColors[legendIndex] = prev[legendIndex];
      return ordinalColorScale(Object.keys(colorScale), newColors);
    } else return ordinalColorScale(Object.keys(colorScale), Object.values(colorScale));
  }, [colorScale, legendIndex]);
  const handleShowMore = () => {
    setisShowMore((currenetState) => !currenetState);
  };
  const width = legendDivRef?.current?.clientWidth ?? 100;

  const Legend = () => (
    <div className='OrdinalLegend'>
      {isShowMore ? (
        <div
          style={{
            width: width - 50,
          }}
          className={isShowMore ? 'legendShowMoreDiv show' : 'legendShowMoreDiv'}
        >
          <div
            className='remove'
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
              fontSize: '1.2rem',
              padding: '1rem 0',
            }}
            onClick={() => handleShowMore()}
          >
            <GoX />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 8,
              flexWrap: 'wrap',
            }}
          >
            <LegendOrdinal scale={variantScale}>
              {(labels, i) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: 8,
                      flexWrap: 'wrap',
                      overflowY: 'auto',
                      maxHeight: 200,
                    }}
                  >
                    {labels.map((label, i) => {
                      if (labelObject && !labelObject[label.text]) return null;
                      return (
                        <LegendItem
                          key={`legend-quantile-${i}`}
                          margin='0 5px'
                          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          onClick={() => {
                            if (i === legendIndex) setClick(-1);
                            else setClick(i);
                            handleShowMore();
                          }}
                        >
                          <svg width={legendGlyphSize} height={legendGlyphSize}>
                            {shape === 'circle' ? (
                              <circle
                                fill={
                                  label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)
                                }
                                opacity={(label?.value?.opacity ?? 100) / 100}
                                r={legendGlyphSize / 2.5}
                                cx={legendGlyphSize / 2.5}
                                cy={legendGlyphSize / 2.5}
                              />
                            ) : (
                              <rect
                                fill={
                                  label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)
                                }
                                opacity={(label?.value?.opacity ?? 100) / 100}
                                width={legendGlyphSize}
                                height={legendGlyphSize}
                              />
                            )}
                          </svg>
                          <LegendLabel align='left' margin='0 0 0 4px'>
                            {labelObject ? labelObject[label.text] : label.text}
                          </LegendLabel>
                        </LegendItem>
                      );
                    })}
                  </div>
                );
              }}
            </LegendOrdinal>
          </div>
        </div>
      ) : null}
      <LegendOrdinal scale={variantScale}>
        {(labels, i) => {
          const filteredLabels = (labels ?? []).filter((label) => {
            if (labelObject) {
              if (labelObject[label?.text] !== null && labelObject[label?.text] !== undefined) {
                return true;
              }
            } else {
              return true;
            }
            return false;
          });

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                // paddingLeft: '3rem',
                gap: 8,
                flexWrap: 'wrap',
              }}
              ref={legendDivRef}
            >
              {filteredLabels.map((label, i) => {
                if (maxlableShow <= i) {
                  return <></>;
                }
                const maxChar = 50;
                const maxCharFuntion = (text = '') => {
                  if (text.length >= maxChar) {
                    const newText = text.substring(0, maxChar) + '...';
                    return newText;
                  }
                  return text;
                };
                const lableText = labelObject ? labelObject[label.text] : label.text;
                return (
                  <LegendItem
                    key={`legend-quantile-${i}`}
                    margin='0 5px'
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      if (i === legendIndex) setClick(-1);
                      else setClick(i);
                    }}
                  >
                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                      {shape === 'circle' ? (
                        <circle
                          fill={label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)}
                          opacity={(label?.value?.opacity ?? 100) / 100}
                          r={legendGlyphSize / 2.5}
                          cx={legendGlyphSize / 2.5}
                          cy={legendGlyphSize / 2.5}
                        />
                      ) : (
                        <rect
                          fill={label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)}
                          opacity={(label?.value?.opacity ?? 100) / 100}
                          width={legendGlyphSize}
                          height={legendGlyphSize}
                        />
                      )}
                    </svg>
                    <LegendLabel align='left' margin='0 0 0 4px'>
                      {/* {labelObject ? labelObject[label.text] : label.text} */}
                      {maxCharFuntion(lableText)}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
              {maxlableShow < filteredLabels.length && !isReport ? (
                <LegendItem
                  // key={`legend-quantile-${i}`}
                  margin='0 5px'
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    handleShowMore();
                  }}
                >
                  {/* <svg width={legendGlyphSize} height={legendGlyphSize}>
                  {shape === 'circle' ? (
                    <circle
                      // fill={label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)}
                      // opacity={(label?.value?.opacity ?? 100) / 100}
                      r={legendGlyphSize / 2.5}
                      cx={legendGlyphSize / 2.5}
                      cy={legendGlyphSize / 2.5}
                    />
                  ) : (
                    <rect
                      // fill={label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)}
                      // opacity={(label?.value?.opacity ?? 100) / 100}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  )}
                </svg> */}
                  <LegendLabel
                    align='left'
                    margin='0 0 0 4px'
                    style={{ fontWeight: 'bolder', textDecoration: 'underline', color: '#009ef7' }}
                  >
                    {mls('Show All')}
                    {/* {labelObject ? labelObject[label.text] : label.text} */}
                  </LegendLabel>
                </LegendItem>
              ) : null}
            </div>
          );
        }}
      </LegendOrdinal>
    </div>
  );

  return { Legend, colorScale: scale, handleLegendClick: setClick, legendIndex, variantScale };
};

export default useOrdinalLegend;
