import { useEffect, useRef } from 'react';

export function debounce<T extends any[]>(callback: (...args: T) => void, delay: number): (...args: T) => void {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
  
    const debouncedFn = (...args: T) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(() => {
        callback(...args);
        timeoutId = undefined;
      }, delay);
    };
  
    return debouncedFn;
  }





  type ThrottleFunction<T extends any[]> = (...args: T) => void;
  
  export  function useThrottle<T extends any[]>(callback: (...args: T) => void, delay: number): ThrottleFunction<T> {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const shouldCallRef = useRef<boolean>(false);
  
    const throttledFn: ThrottleFunction<T> = (...args: T) => {
      if (!shouldCallRef.current) {
        shouldCallRef.current = true;
        callback(...args);
  
        timeoutRef.current = setTimeout(() => {
          shouldCallRef.current = false;
        }, delay);
      }
    };
  
    // Clear timeout on unmount
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
  
    return throttledFn;
  }
