import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import { v4 as uuidv4 } from 'uuid';

interface getSheetIDProps {
  defaultTable?: any;
  appSheets: any;
  selectedTable: string;
  appID: string;
}
export const getSheetID = async ({ appSheets, selectedTable, appID }: getSheetIDProps) => {
  // check for appSheets
  const finalSheetKey = Object.keys(appSheets).filter((sheetID) => {
    if (appSheets[sheetID]?.defaultTable?.tableID === selectedTable) {
      return true;
    }
    return false;
  });
  if (finalSheetKey[0]) {
    return { skip: false, sheetID: finalSheetKey[0] };
  }
  const newSheetID = `AISheet${uuidv4()}`;
  return { skip: false, sheetID: newSheetID, newCreatedSheetID: true };
};
interface getTableDataProps {
  appID: string;
  selectedTable: string;
  sheetID: string;
  newSheetID?: boolean;
  disableCustomColumns?: boolean;
}
export const getConvertedTableData = async ({
  appID,
  selectedTable,
  sheetID,
  newSheetID = false,
  disableCustomColumns = false,
}: getTableDataProps) => {
  const res: any = await _supistaAnalyticsApi(`${appID}/tableHead`, {
    appID: appID,
    tableID: selectedTable,
    sheetID,
    newSheetID,
    disableCustomColumns,
  });

  if (!res.__d3__error) {
    if (res.status === 200) {
      const groupedTableData = convertTableData(res.response);
      return { skip: false, groupedTableData, data: res.response };
    }
  }
  return { skip: true };
};

export const convertTableData = (tableData: any) => {
  const { tableHead, tableSchema, highlightTableSchema = [] } = tableData || {};
  let normalData: { [key: string]: any }[] = [];
  let highlightedData: { [key: string]: any }[] = [];

  (tableSchema?.data || []).forEach((tableDataElem: any) => {
    const uniqueTableNameWithColumnName = `${tableDataElem.tableName}.${tableDataElem.columnName}`;
    const isHighlighted = highlightTableSchema.some((highlightElem: any) => {
      const highlightUniqueTableName = `${highlightElem.tableID ?? highlightElem.tableName}.${
        highlightElem.columnName
      }`;
      return highlightUniqueTableName === uniqueTableNameWithColumnName;
    });

    const tableDataWithValue = (tableHead || []).map((tableHeadElem: any) => {
      const value = tableHeadElem[uniqueTableNameWithColumnName as typeof tableHeadElem];
      return value;
    });
    const checkForUndefined = tableDataWithValue.some((element: any) => {
      return element === undefined;
    });

    if (checkForUndefined) {
      return undefined;
    }
    const finalSchema = {
      tableName: tableDataElem.tableName,
      columnName: tableDataElem.columnName,
      nameOfTable: tableDataElem?.nameOfTable,
      name: tableDataElem.name,
      dataType: tableDataElem.dataType,
      values: tableDataWithValue,
      isHighlighted,
    };
    if (finalSchema.isHighlighted) {
      highlightedData = [...highlightedData, finalSchema];
    } else {
      normalData = [...normalData, finalSchema];
    }
    return finalSchema;
  });

  const combineData = [...highlightedData, ...normalData];
  const finalData = filterByTableName(combineData);
  return finalData;
};

export const filterByTableName = (data: any) => {
  if (!data) return [];

  // Grouped By TableName
  let o: { [key: string]: any } = {};
  let groupedArray = data.reduce(function (r: any, el: any) {
    let e = el.tableName;
    if (!o[e as keyof typeof o]) {
      o[e as keyof typeof o] = {
        tableName: el.tableName,
        nameOfTable: el.nameOfTable ?? el.tableName,
        name: el.tableName,
        items: [],
      };
      r.push(o[e as keyof typeof o]);
    }
    o[e as keyof typeof o]?.items.push(el);
    return r;
  }, []);
  return groupedArray;
};
