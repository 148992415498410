import { allDropableID } from '../../../data/defaultChartSchema';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import React, { useMemo, useState } from 'react';
import FontSelector from '../../StyleComponents/FontSelector';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import SolidColorSelector from '../../StyleComponents/SolidColorSelector';
import { defaultIndicatorTypes, defaultSelectedFontSize } from '../../../data/defaultChartSchema';
import DropDown from 'lib/visx-lib/helpers/components/DropDown/ObjectDropDown';
import NumberFormatterComp from 'lib/chart-settings-lib/features/Formatter/NumberFormatterComp';
import { mls } from 'lib/multilanguagesupport';
const defautText = {
  textStyle: [],
  fontHeight: defaultSelectedFontSize,
};
interface PivotTableXAxisProps {
  stateProps: any;
}

const PivotTableXAxis = ({ stateProps }: PivotTableXAxisProps) => {
  const [isDivOpen, setisDivOpen] = useState(true);
  const [isContent, setisContent] = useState(true);

  const { chartToolTip, setChartToolTip, xAxisData, yAxisData } = stateProps;

  const updateStyle = (data: any, index: number) => {
    setChartToolTip((currentValue: any) => {
      const sourceitems = Array.from(currentValue.style);
      const [selectedItem]: any = sourceitems.splice(index, 1);
      const newSelectedItem = { ...selectedItem, ...data };
      sourceitems.splice(index, 0, newSelectedItem);
      const newSourceItem = {
        ...currentValue,
        style: sourceitems,
      };
      return newSourceItem;
    });
  };

  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      <div className='MainTitle inFlex'>
        {mls('Y-Axis')}
        {/* <SlideSwitch isChecked={isDivOpen} setIsChecked={setisDivOpen} /> */}
      </div>
      <div className='grayContainers'>
        {/* <div className={`containerItems open`}>
          <FontSelector fontStyle={chartToolTip} setFontStyle={setChartToolTip} />
        </div> */}
        {isContent && (
          <>
            <AxisStyles
              styles={chartToolTip?.style}
              updateStyle={updateStyle}
              xAxisData={xAxisData}
              yAxisData={yAxisData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PivotTableXAxis;
interface axisStyleProps {
  styles: any[];
  updateStyle: Function;
  xAxisData: any[];
  yAxisData: any[];
}
const AxisStyles = ({ styles, updateStyle, xAxisData, yAxisData }: axisStyleProps) => {
  return (
    <>
      {React.Children.toArray(
        styles.map((elem, index) => {
          if (elem.axis !== allDropableID.yAxisId) {
            return <></>;
          }
          return (
            <Axis
              target={elem}
              updateStyle={updateStyle}
              index={index}
              xAxisData={xAxisData}
              yAxisData={yAxisData}
            />
          );
        })
      )}
    </>
  );
};
interface axisProps {
  target: any;
  show?: boolean;
  index: Number;
  updateStyle: Function;
  xAxisData: any[];
  yAxisData: any[];
}
const Axis = ({
  // stateProps,
  target,
  show = false,
  updateStyle,
  index,
  xAxisData,
  yAxisData,
}: // targetKey,
// automaticOpacity,
// setAutomaticOpacity,
axisProps) => {
  const [indicatorType, setIndicatorType] = useState(
    target?.indicatorType ?? defaultIndicatorTypes?.BAR
  );
  const handleLabel = (e: any) => {
    updateStyle({ label: e.target.value }, index);
  };
  const handleShow = (action: boolean) => {
    updateStyle({ show: action }, index);
  };
  const handleFont = (action: boolean) => {
    updateStyle(action, index);
  };
  const handleColor = (action: boolean) => {
    updateStyle(action, index);
  };
  const handleIndicatorType = (action: boolean) => {
    setIndicatorType(action);
    updateStyle({ indicatorType: action }, index);
  };
  const handleFormatter = (action: any) => {
    updateStyle({ formatter: action }, index);

    //  setAxisStyle((currentAxisStyle) => {
    //    return { ...currentAxisStyle, formatter: action };
    //  });
  };
  const columnData = returnOpration({ xAxisData, yAxisData, elm: target });
  return (
    <>
      <ClosableWhiteDiv
        // title={target?.originalLabel}
        title={`${columnData?.opration} ${target.originalLabel}`}
        show={show}
        autoCloseOff={true}
        className={'legend'}
      >
        <input
          type='text'
          className='primaryInput'
          value={target.label}
          onChange={(e) => handleLabel(e)}
        />
        <DropDown
          object={defaultIndicatorTypes}
          dropDownValue={indicatorType}
          setdropDownValue={handleIndicatorType}
          ObjectInArray={true}
        />
        <FontSelector fontStyle={{ ...defautText, ...target }} setFontStyle={handleFont} />
        {/* <SolidColorSelector color={target} setColor={handleColor} isPalette={false} /> */}
        <NumberFormatterComp formatter={target?.formatter} setFormatter={handleFormatter} />
      </ClosableWhiteDiv>
    </>
  );
};

const returnOpration = ({ xAxisData, yAxisData, elm }: any) => {
  const getOpration = () => {
    const findInxAxisData = xAxisData.find((e: any) => {
      return e.uniqueColumnName === elm.uniqueColumnName;
    });
    if (findInxAxisData) return findInxAxisData;

    const findInyAxisData = yAxisData.find((e: any) => {
      return e.uniqueColumnName === elm.uniqueColumnName;
    });

    if (findInyAxisData) return findInyAxisData;
    return false;
  };
  const data = getOpration();
  if (data?.operations?.type) {
    return { column: data, opration: `(${data.operations.type})` };
  } else {
    return { column: data, opration: '' };
  }
};
