import React, { useState } from 'react';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import { mls } from 'lib/multilanguagesupport';

interface mainProps {
  stateProps: any;
}
const FilterIndex = ({ stateProps }: mainProps) => {
  const { setChartFilter, allTableData, chartFilter, appID, isJoinedTable, sheetID } = stateProps;
  const [isPopUp, setIsPopUp] = useState(false);

  const filterNumber = Object.keys(chartFilter ?? {}).length;
  return (
    <div className='filterButton '>
      <button
        className={`primaryButton secondaryButtonColor`}
        onClick={() => {
          setIsPopUp((currentData: boolean) => !currentData);
        }}
      >
        {mls('Open Filters')}
        {filterNumber >= 1 ? (
          <div className='filterButtonNumber'>{filterNumber >= 100 ? '99+' : filterNumber}</div>
        ) : null}
      </button>
      {isPopUp ? (
        <>
          <FilterDiv
            isPopUp={isPopUp}
            setIsPopUp={setIsPopUp}
            allTableData={allTableData}
            setChartFilter={setChartFilter}
            chartFilter={chartFilter}
            appID={appID}
            isJoinedTable={isJoinedTable}
            sheetID={sheetID}
          />
        </>
      ) : null}
    </div>
  );
};

export default FilterIndex;
