import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import {
  normalSchema,
  radarOrRadialSchema,
  pieSchema,
  cardSchema,
  kpiMetersSchema,
  wordCloudSchema,
  barStackLineSchema,
  plotSchema,
  geoSchema,
  pivotTableSchema,
  heatMapSchema,
  treeMapSchema,
  colorSchema,
  kpiTrackingSchema,
  geoMapSchema,
  geoMapColorSchema,
} from '../data/finalSchema';

export const finalSchemaByChart = (prop) => {
  const { chartSchemaMemo, chartType, normal } = prop;
  if (!dataUpdateConditions({ chartSchemaMemo, chartType })) {
    const finalData = extractDataByChart({ chartSchemaMemo, chartType, normal });
    return { skip: true, data: finalData };
  }

  const finalData = extractDataByChart({ chartSchemaMemo, chartType, normal });
  return { skip: false, data: finalData };
};
export const getChartSchema = (prop) => {
  const { chartSchemaMemo, chartType, normal } = prop;
  const finalData = extractDataByChart({ chartSchemaMemo, chartType, normal });
  return { skip: false, data: finalData };
};
//dataUpdate
const dataUpdateConditions = (props) => {
  const { chartSchemaMemo, chartType } = props;
  switch (chartType.chartKey) {
    // Special Case
    //CARD
    case allChartKeys.CARD:
      if (chartSchemaMemo.yAxis[0]) return true;
      break;

    //KPIMETER
    case allChartKeys.KPIMETER:
      if (chartSchemaMemo.yAxis[0] && chartSchemaMemo?.targetPoints?.targets[0]) return true;
      break;

    //KPITRACKING
    case allChartKeys.KPITRACKING:
      if (chartSchemaMemo.yAxis[0] && chartSchemaMemo?.targetPoints?.sectionColors[0]) return true;
      break;

    // //GEOINDIA
    // case allChartKeys.GEOINDIA:
    //   if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0]) return true;
    //   break;

    // //GEOUSA
    // case allChartKeys.GEOUSA:
    //   if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0]) return true;
    //   break;

    // //GEOWORLD
    // case allChartKeys.GEOWORLD:
    //   if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0]) return true;
    //   break;

    //GEOMAP
    case allChartKeys.GEOMAP:
      if (chartSchemaMemo.xAxis[1] && chartSchemaMemo.yAxis[0]) return true;
      break;
    //GEOMAPCOLOR
    case allChartKeys.GEOMAPCOLOR:
      if (chartSchemaMemo.xAxis[1] && chartSchemaMemo.yAxis[0]) return true;
      break;

    //WORDCLOUD
    case allChartKeys.WORDCLOUD:
      if (chartSchemaMemo.xAxis[0]) return true;
      break;
    //HEATMAP
    case allChartKeys.HEATMAP:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0] && chartSchemaMemo.magnitude[0])
        return true;
      break;
    //COLOR
    case allChartKeys.BARCOLOR:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0] && chartSchemaMemo.colorAxis[0])
        return true;
      break;
    case allChartKeys.LINECOLOR:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0] && chartSchemaMemo.colorAxis[0])
        return true;
      break;
    case allChartKeys.AREACOLOR:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0] && chartSchemaMemo.colorAxis[0])
        return true;
      break;
    case allChartKeys.BARHORIZONTALCOLOR:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0] && chartSchemaMemo.colorAxis[0])
        return true;
      break;

    //NORMAL
    default:
      if (chartSchemaMemo.xAxis[0] && chartSchemaMemo.yAxis[0]) return true;
  }
  return false;
};

//dataUpdate
const extractDataByChart = (props) => {
  const { chartSchemaMemo, chartType, normal } = props;
  //PIE | DONUT | FUNNEL
  if (
    chartType.chartKey === allChartKeys.PIE ||
    chartType.chartKey === allChartKeys.DONUT ||
    chartType.chartKey === allChartKeys.FUNNEL
  ) {
    return dataExtractor(chartSchemaMemo, pieSchema, normal);
  }

  //CARD
  if (chartType.chartKey === allChartKeys.CARD) {
    return dataExtractor(chartSchemaMemo, cardSchema, normal);
  }
  //KPIMETER
  if (chartType.chartKey === allChartKeys.KPIMETER) {
    return dataExtractor(chartSchemaMemo, kpiMetersSchema, normal);
  }
  //KPITRACKING
  if (chartType.chartKey === allChartKeys.KPITRACKING) {
    return dataExtractor(chartSchemaMemo, kpiTrackingSchema, normal);
  }
  //WORDCLOUD
  if (chartType.chartKey === allChartKeys.WORDCLOUD) {
    return dataExtractor(chartSchemaMemo, wordCloudSchema, normal);
  }
  //BARSTACKLINE
  if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
    return dataExtractor(chartSchemaMemo, barStackLineSchema, normal);
  }
  //GEO
  if (
    chartType.chartKey === allChartKeys.GEOINDIA ||
    chartType.chartKey === allChartKeys.GEOUSA ||
    chartType.chartKey === allChartKeys.GEOCANADA ||
    chartType.chartKey === allChartKeys.GEOWORLD
  ) {
    return dataExtractor(chartSchemaMemo, geoSchema, normal);
  }
  //GEOMAP
  if (chartType.chartKey === allChartKeys.GEOMAP) {
    return dataExtractor(chartSchemaMemo, geoMapSchema, normal);
  }
  //GEOMAPCOLOR
  if (chartType.chartKey === allChartKeys.GEOMAPCOLOR) {
    return dataExtractor(chartSchemaMemo, geoMapColorSchema, normal);
  }
  //PLOT
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return dataExtractor(chartSchemaMemo, plotSchema, normal);
  }
  //RADIALLINE ||RADIALLINE
  if (chartType.chartKey === allChartKeys.RADIALLINE || chartType.chartKey === allChartKeys.RADAR) {
    return dataExtractor(chartSchemaMemo, radarOrRadialSchema, normal);
  }
  //PIVOTTABLE
  if (chartType.chartKey === allChartKeys.PIVOTTABLE) {
    return dataExtractor(chartSchemaMemo, pivotTableSchema, normal);
  }
  //HEATMAP
  if (chartType.chartKey === allChartKeys.HEATMAP) {
    return dataExtractor(chartSchemaMemo, heatMapSchema, normal);
  }
  //TREEMAP
  if (chartType.chartKey === allChartKeys.TREEMAP) {
    return dataExtractor(chartSchemaMemo, treeMapSchema, normal);
  }
  //COLOR
  if (
    chartType.chartKey === allChartKeys.BARCOLOR ||
    chartType.chartKey === allChartKeys.LINECOLOR ||
    chartType.chartKey === allChartKeys.AREACOLOR ||
    chartType.chartKey === allChartKeys.BARHORIZONTALCOLOR
  ) {
    return dataExtractor(chartSchemaMemo, colorSchema, normal);
  }
  return dataExtractor(chartSchemaMemo, normalSchema, normal);
};

const dataExtractor = (data, list, normal) => {
  let newData = {};
  list.forEach((element) => {
    newData[element] = data[element];
  });

  // Special Case
  if (newData.chartType === allChartKeys.BARSTACKLINE) {
    newData.targetPoints = data.barStackLineTargetPoints;
  }

  if (normal) {
    return newData;
  }
  const finalSchema = {
    charts: {
      [newData.chartID]: newData,
    },
    projectID: newData.projectID,
    sheetID: newData.sheetID,
    appID: newData.appID,
    tableID: newData.tableID,
    timeFrame: Object.keys(data?.timeFrame ?? {})[0] && data?.timeFrame,
  };
  return finalSchema;
  // return newData
};
