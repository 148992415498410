import { useMemo } from 'react';
import DashboardWrapper from '../components/dashboardArea/DashboardWrapper';
import { mls } from 'lib/multilanguagesupport';

interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
  };
  mainPorps: any;
}
const ChartArea = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;

  const { minimizedDiv } = toggleProps;

  const presentActiveColumn = useMemo(() => {
    let filtered = minimizedDiv.filter(Boolean);
    if (filtered?.length === 0) {
      return 'twoColumnOpen';
    }
    if (filtered?.length === 1) {
      return 'oneColumnOpen';
    }
    return '';
  }, [minimizedDiv]);
  const { dashboardName, setDashboardName, dashboardDescription, setDashboardDescription } =
    mainPorps.stateProps;
  return (
    <div className={`thirdColumn ${presentActiveColumn}`}>
      <div className='uppperMainTitle'>{mls('Dashboard')}</div>
      <div className='chartNameDiv'>
        <div className='titleDiv'>{mls('Dashboard Name')}:</div>
        <input
          type='text'
          placeholder={mls('Name of Dashboard')}
          value={dashboardName}
          onChange={(e) => setDashboardName(e.target.value)}
        />
      </div>
      <div className='chartDescriptionDiv'>
        <div className='titleDiv'>{mls('Dashboard Description')}:</div>

        <textarea
          placeholder={mls('Description of Dashboard')}
          value={dashboardDescription}
          rows={3}
          onChange={(e) => setDashboardDescription(e.target.value)}
        />
      </div>
      <DashboardWrapper stateProps={mainPorps.stateProps} />
    </div>
  );
};

export default ChartArea;
