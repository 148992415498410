import React, { useState, useEffect, useRef } from 'react';
import './selectChart.scss';
import boxDropDownIcon from '../../assets/boxDropDownIcon.svg';
import listIcon from '../../assets/listIcon.svg';
import {
  allChartTypesObj,
  chartForSelection,
} from 'lib/reusable-components/reusableData/chartTypesSchema';

import {
  getGroupDataByChart,
  resetChartFuntion,
  setDefaultStyle,
} from '../../utils/chartHelperFunctions';
import { FiX } from 'react-icons/fi';
import { ChartTitleDiscriptionToolTip } from 'lib/reusable-components/Components/ToolTip/ChartOptionToolTip';
import { useDispatch } from 'react-redux';
import { deleteDateTimeToggle } from 'lib/reusable-components/reusableFunction/formatter';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { RiErrorWarningLine } from 'react-icons/ri';
import WarningPopup from 'lib/reusable-components/Components/WarningPopup/WarningPopup';

const SelectChart = ({ stateProps }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { chartType } = stateProps;

  return (
    <>
      <div className='selectChartOuterDiv'>
        <ChooseChartPopup
          isPopupOpen={isPopupOpen || !chartType?.name}
          setIsPopupOpen={setIsPopupOpen}
          stateProps={stateProps}
        />
        {chartType?.name ? (
          <div className='selectChangeChart'>
            <div className='selectChartIcon' onClick={() => setIsPopupOpen(true)}>
              {chartType.icon ? (
                <img src={chartType.icon} alt='' />
              ) : (
                <div className='nameIcon'>
                  {getFirstLetter(chartType?.relatedTo?.name ?? chartType.name)}
                </div>
              )}
            </div>
            <div className='selectChartTitle' onClick={() => setIsPopupOpen(true)}>
              {mls(chartType?.relatedTo?.name || chartType.name)}
            </div>
          </div>
        ) : null}
      </div>
      {chartType?.name ? (
        <div className='primaryButtonFlexDiv' style={{ padding: '0.5rem 1rem' }}>
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => setIsPopupOpen(true)}
            style={{ width: '100%' }}
          >
            {mls('Change Chart')}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default SelectChart;

function ChooseChartPopup({ isPopupOpen, setIsPopupOpen, stateProps }) {
  const [isListOn, setisListOn] = useState(false);
  const [restConfirmation, setRestConfirmation] = useState(false);
  const [tempKey, setTempKey] = useState({});
  const {
    chartType,
    setchartType,
    xAxisData,
    yAxisData,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setChartWidth,
    chartID,
    timeFrameData,
  } = stateProps;
  const dispatch = useDispatch();
  const handlePopUp = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const updateChart = (updateChartProps) => {
    const {
      chart,
      xAxisData,
      yAxisData,
      colorAxis,
      groupData,
      setchartType,
      reset = false,
      currentChart,
    } = updateChartProps;
    deleteDateTimeToggle({
      toggle: timeFrameData?.toggle,
      chartID,
      dispatch,
    });
    if (reset) {
      setchartType(chart);
      return;
    }
    const checkForUpdate = (array, chartKey, axis) => {
      const changeTo = allChartTypesObj[chartKey]?.multipleAxis?.[axis]?.add?.changeTo;
      const triggerColoumnValue =
        allChartTypesObj[chartKey]?.multipleAxis?.[axis]?.add?.triggerColoumnValue;

      if (triggerColoumnValue === null || triggerColoumnValue === undefined) {
        return { isChangable: false, changeTo: changeTo };
      }
      const check = triggerColoumnValue <= array.length;
      return { isChangable: check, changeTo: changeTo };
    };
    const recursiveCheck = (array, chartKey, axis) => {
      const { isChangable, changeTo } = checkForUpdate(array, chartKey, axis);
      if (isChangable) {
        return recursiveCheck(array, changeTo, axis);
      } else {
        return { isChangable, chartKey };
      }
    };
    const xAxisUpdate = recursiveCheck(xAxisData, chart.chartKey, 'xAxis');
    const yAxisUpdate = recursiveCheck(yAxisData, xAxisUpdate.chartKey, 'yAxis');
    const groupUpdate = recursiveCheck(groupData, yAxisUpdate.chartKey, 'group');
    // restStyles
    // setDefaultStyle({
    //   chart: allChartTypesObj[yAxisUpdate.chartKey],
    //   setChartXAxisStyle,
    //   setChartYAxisStyle,
    //   chartXAxisStyle,
    //   chartYAxisStyle,
    // });
    if (!currentChart) {
      // restStyles
      setDefaultStyle({
        chart: allChartTypesObj[groupUpdate.chartKey],
        setChartXAxisStyle,
        setChartYAxisStyle,
      });
    }
    setchartType(allChartTypesObj[groupUpdate.chartKey]);
    setChartWidth(allChartTypesObj[groupUpdate.chartKey]?.defaultChartWidth);
  };
  const groupData = getGroupDataByChart({ stateProps, chartType })?.data;
  const setChart = (key) => {
    if (key?.chartKey === chartType?.chartKey) {
      handlePopUp();
      return;
    }
    const isRelatable = isRelatableFunc(chartType, key, true);
    if (!isRelatable) {
      setTempKey(key);
      setRestConfirmation((currentData) => !currentData);
      return;
    }
    handlePopUp();

    updateChart({
      chart: key,
      xAxisData,
      yAxisData,
      setchartType,
      currentChart: chartType?.chartKey,
      groupData,
    });
  };

  const handleReset = (action) => {
    if (action) {
      resetChartFuntion({ stateProps, chart: tempKey });
      updateChart({
        chart: tempKey,
        xAxisData,
        yAxisData,
        setchartType,
        reset: true,
        currentChart: chartType?.chartKey,
        groupData,
      });
      handlePopUp();
    }
    setRestConfirmation((currentData) => !currentData);
  };
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handlePopUp();
      }
    }
    if (isPopupOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isPopupOpen]);
  if (isPopupOpen) {
    return (
      <>
        <div className='ChartChoose'>
          <div
            className='chartChooseContainer'
            style={chartType?.name ? {} : { marginTop: 0 }}
            onClick={(e) => e.stopPropagation()}
            ref={wrapperRef}
          >
            <WarningPopup
              initialValue={restConfirmation}
              setConfirmation={handleReset}
              titleText={`${mls('Are you sure you want to change chart')} ?`}
              discText={mls('*Chart will be reset')}
            />
            <div className='titleDiv'>
              <div className='sourceNameDiv'>{mls('Select Chart Type')}</div>
              <div className='rightOptions'>
                <div
                  className={isListOn ? 'listToggle ' : 'listToggle listOn'}
                  onClick={() => setisListOn(!isListOn)}
                >
                  <span></span>

                  <div className='toggleOptions'>
                    <img src={boxDropDownIcon} alt='' className='toggleOptionIcon' />
                  </div>
                  <div className='toggleOptions'>
                    <img src={listIcon} alt='' className='toggleOptionIcon' />
                  </div>
                </div>
                <div className='crossButton' onClick={() => handlePopUp()}>
                  <FiX />
                </div>
              </div>
            </div>

            <div className={isListOn ? 'chartSelectionDiv list' : 'chartSelectionDiv'}>
              {React.Children.toArray(
                chartForSelection.map((elem) => {
                  return (
                    <ChartSelect
                      setChart={setChart}
                      elem={elem}
                      chartType={chartType}
                      isListOn={isListOn}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}

const ChartSelect = ({ setChart, elem, chartType, isListOn }) => {
  const className = chartForSelectClassName(elem, chartType);
  const [showToolTip, setShowToolTip] = useState(false);

  const handleShowToolTip = () => {
    setShowToolTip(true);
  };
  const removeToolTip = () => {
    setShowToolTip(false);
  };
  const tooltipData = {
    title: mls(elem?.relatedTo?.name ?? elem.name),
    description:
      mls(elem?.tooltipDiscription) ??
      `${mls('This is a')} ${mls(elem?.relatedTo?.name)} ${mls('chart')}`,
    warning: className === 'unChangable' ? mls(`Your chart configuration may alter!`) : ' ',
  };

  return (
    <div
      className={`chartForSelect ${className}`}
      onClick={() => {
        setChart(elem);
      }}
      onMouseOver={() => handleShowToolTip()}
      onMouseOut={() => removeToolTip()}
    >
      <div className='icon'>
        {elem.icon ? (
          <img src={elem.icon} alt='' />
        ) : (
          <div className='nameIcon'>{getFirstLetter(tooltipData.title)}</div>
        )}
      </div>
      {isListOn && (
        <div className='textDiv'>
          <div className='title'>{tooltipData.title}</div>
          <div className='subtitle'>{tooltipData.description}</div>
        </div>
      )}
      {showToolTip ? <ChartTitleDiscriptionToolTip data={tooltipData} /> : null}
    </div>
  );
};

const isRelatableFunc = (chartType, elem, test) => {
  if (chartType.relatedChartTypes) {
    // const isChangable = chartType.relatedChartTypes.findIndex((chartTypes) => {
    //   return chartTypes === elem.relatedTo.key ?? elem.chartKey;
    // });
    const isChangable2 = chartType.relatedChartTypes.some((chartTypes) => {
      return chartTypes === elem.relatedTo.key ?? elem.chartKey;
    });

    return isChangable2;
    // if (isChangable < 0) {
    //   return false;
    // }
  }
  return true;
};
const getFirstLetter = (target) => {
  const myArray = target.split(' ');
  const newArray = myArray.map((elem) => {
    return elem[0].toUpperCase();
  });
  return newArray.join('');
};

const chartForSelectClassName = (elem, chartType) => {
  if (
    (elem?.relatedTo?.key ?? elem.chartKey) === (chartType?.relatedTo?.key ?? chartType?.chartKey)
  ) {
    return ' selected';
  } else {
    const isRelatable = isRelatableFunc(chartType, elem);
    if (isRelatable) {
      return '';
    }
    return 'unChangable';
  }
};
