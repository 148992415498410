import React, { useEffect, useMemo, useRef, useState } from 'react';
import { mls } from 'lib/multilanguagesupport';
import './getFileds.scss';
import { toast } from 'react-toastify';
import DataSourceDisplayTable, {
  configDisplayTableFunction,
} from 'lib/reusable-components/DisplayTable/DataSourceDisplayTable/DataSourceDisplayTable';
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { _supistaAnalyticsApi, _supistaApiPost } from 'lib/server-connection/connections';
import useAddNewSheets from '../../useAddNewSheets';
import useUpdateTableSchema from 'lib/data-connectors/dependencies/useUpdateTableSchema';
import { v4 as uuid } from 'uuid';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';

import { publishToProduction } from 'lib/data-connectors/dependencies/publishToProductions';
import {
  getJoinTableIDs,
  getReportDashIDsTBD,
  getSheetChartsIDsTBD,
  getTableIDSTBD,
} from 'lib/reusable-components/reusableFunction/deleteDataDependencies';
import { useLocation } from 'react-router-dom';
import GetFields from './GetFileds';
import { getDTCols } from './filedsUtils';
import { CircularProgress } from '@mui/material';
import { RiRestartLine } from 'react-icons/ri';
import WarningPopup from 'lib/reusable-components/Components/WarningPopup/WarningPopup';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { LuImport } from 'react-icons/lu';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { DeleteTable } from 'lib/data-connectors/screens/data-table/modal/add-database/DeleteTable';
interface tableConfigFieldProps {
  fieldsElem: any;
  formData: any;
  tableData?: any;
  setFormData: Function;
  setTableData: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}

const optionObject = {
  // edit: {
  //   key: 'edit',
  //   name: 'Edit',
  // },
  remove: {
    key: 'remove',
    name: 'Delete Table',
  },
};
const TableConfigField = ({
  fieldsElem,
  formData,
  setFormData,
  tableData,
  setTableData,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: tableConfigFieldProps) => {
  const [selectedTableName, setSelectedTableName] = useState('');
  const [tableConfigFields, setTableConfigFields] = useState<{ [key: string]: any }>({});
  const [selectedTable, setSelectedTable] = useState<{ [key: string]: any }>({});
  const [confirmTableCreateOpen, setConfirmTableCreateOpen] = useState(false);
  const [tableImported, setTableImported] = useState(false);
  const [reTableImported, setReTableImported] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tableDataLoading, settableDataLoading] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const joinTableSchema = analyticsSchema?.appJointable || {};
  const customTableSchema = analyticsSchema?.appCustomtable || {};
  const appDatatable = analyticsSchema?.appDatatable || {};
  const appSheets = analyticsSchema?.appSheets || {};
  const currentTableID: any = useRef();
  const addNewSheets: any = useAddNewSheets();
  const updateTableSchema = useUpdateTableSchema();
  const location = useLocation();

  const handleTableUpdate = ({
    columnIndex,
    columnSchema,
  }: {
    columnIndex: number;
    columnSchema: any;
  }) => {
    const copyOfColumnSchema = Array.from(selectedTable.columnSchema);
    // const copyOfColumnSchema = Array.from(tableData[selectedTableName].columnSchema);
    copyOfColumnSchema.splice(columnIndex, 1);
    copyOfColumnSchema.splice(columnIndex, 0, columnSchema);
    setSelectedTable({ ...selectedTable, columnSchema: copyOfColumnSchema });
  };
  const appID = location.pathname.split('/')[1];
  // const handleTableConfigFields = (action: any) => {
  //   const tableConfig = formData[fieldsElem.name] ?? {};
  //   const updatedTableConfig = {
  //     ...tableConfig,
  //     [selectedTableName]: { ...(tableConfig[selectedTableName] ?? {}), [action.key]: action.value },
  //   };
  //   setFormData({ key: fieldsElem.name, value: updatedTableConfig });
  // };

  const handleTableConfigFields = (action: any) => {
    const newTableConfigFields = { ...tableConfigFields, [action.key]: action.value };

    setTableConfigFields(newTableConfigFields);
    // const tableConfig = formData[fieldsElem.name] ?? {};
    // const updatedTableConfig = {
    //   ...tableConfig,
    //   [selectedTableName]: { ...(tableConfig[selectedTableName] ?? {}), [action.key]: action.value },
    // };
    // setFormData({ key: fieldsElem.name, value: updatedTableConfig });
  };

  const finalTableData = useMemo(
    () =>
      configDisplayTableFunction({
        tableHead: selectedTable?.dfHead ?? [],
        columnSchema: selectedTable?.columnSchema ?? [],
        tableId: currentTableID.current,
        rowDataStartFrom: tableConfigFields?.startRow,
        columnDataStartFrom: tableConfigFields?.startCol,
        containHeader: tableConfigFields?.useHeader,
      }),
    [selectedTable, tableConfigFields]
  );

  const [selectedSheetID, setSelectedSheetID] = useState('');
  const [moreConfigField, setMoreConfigField] = useState(false);

  const getSelectedTableFromAPI = async ({ tableName }: { tableName: string }) => {
    const tableReqData = {
      appID,
      credentialID: formData.credentialID ?? '',
      tableID: tableName,
    };

    const supistaAnalyticsRes = await _supistaAnalyticsApi(
      `${appID}/api/createTableSchema/credentials`,
      tableReqData
    )
      .then(async (tableResponse) => {
        if (tableResponse.status === 201) {
          return {
            skip: false,
            tableData: tableResponse?.response?.tableSchema?.[tableName] ?? {},
            msg: tableResponse,
          };
        }
        return { skip: true, tableData: {}, msg: tableResponse };
      })
      .catch((err) => {
        return { skip: true, tableData: {}, msg: err };
      });
    return supistaAnalyticsRes;
  };
  const getSelectedTable = async ({ tableName }: { tableName: string }) => {
    const selectedTable = tableData[tableName];
    if (selectedTable) {
      return { skip: false, tableData: selectedTable, msg: 'Get the Table' };
    }

    const finalTableData = getSelectedTableFromAPI({ tableName: tableName });
    return finalTableData;
  };

  const changeTableID = async ({ tableName }: { tableName: string }) => {
    if (selectedTableName === tableName) {
      return;
    }
    setReTableImported(false);
    settableDataLoading(true);

    const selectedTable = await getSelectedTable({ tableName });
    settableDataLoading(false);
    if (selectedTable?.skip) {
      toast.error(mls(selectedTable?.msg?.response?.errorMsg ?? 'Not able to get Table'));
      return;
    }
    currentTableID.current = uuid();
    setTableImported(false);
    setConfirmTableCreateOpen(false);
    let columnSchema = selectedTable?.tableData?.columnSchema ?? [];
    let allTableSchema = {};
    const isTableFound = Object.keys(appDatatable || {}).some((tableIDElem) => {
      if (
        appDatatable[tableIDElem]?.name === tableName &&
        appDatatable[tableIDElem]?.credentialID === formData?.credentialID
      ) {
        setConfirmTableCreateOpen(true);
        currentTableID.current = tableIDElem;
        columnSchema = appDatatable[tableIDElem]?.columnSchema ?? columnSchema;
        allTableSchema = appDatatable[tableIDElem];
        setTableConfigFields(appDatatable[tableIDElem]?.fetchSettings ?? {});
        const { sheetIDsTBD } = getSheetChartsIDsTBD([tableIDElem], [], appSheets);
        setSelectedSheetID(sheetIDsTBD[0] ?? '');
        return true;
      }
      return false;
    });
    setSelectedTable({
      ...selectedTable.tableData,
      columnSchema,
      allTableSchema,
      tableID: currentTableID.current,
      defaultColumnSchema: selectedTable?.tableData?.columnSchema ?? [],
    });
    setSelectedTableName(tableName);
    if (!isTableFound) {
      const tempTableConfigFields = formData?.[fieldsElem.name]?.[tableName] ?? {};
      setTableConfigFields(tempTableConfigFields);
    }
    settableDataLoading(false);
  };

  const [isReimportPopup, setIsReImportPopup] = useState(false);
  const [isResetPopup, setIsResetPopup] = useState(false);
  const importAndSaveTable = async ({
    isDefaultColumnSchema = false,
    defaultColumnSchemaObject = {},
  }: {
    isDefaultColumnSchema?: boolean;
    defaultColumnSchemaObject?: { [key: string]: any };
  }) => {
    const tableName = selectedTableName;

    if (submitting) return;
    settableDataLoading(true);
    setSubmitting(true);
    // Building the final column schema by including ONLY the selected columns
    const finalColumnSchema = isDefaultColumnSchema
      ? defaultColumnSchemaObject
      : selectedTable.columnSchema;

    let DTCols = getDTCols({
      columns: finalColumnSchema ?? [],
      columnDataStartFrom: tableConfigFields.startCol,
      tableID: selectedTable.tableID,
      keyArray: true,
    });
    const dbType = formData.connectorType ?? '';
    const credentialID = formData.credentialID ?? '';
    const description = formData.description ?? '';
    const defaultDatetimeColumnName = tableConfigFields?.defaultDatetimeColumn ?? '';
    const datetimeCols = DTCols;

    const currentTimestamp = Date.now();
    const tableID = currentTableID.current;
    const defaultColumnSchema = finalColumnSchema.map((currentColumn: any, index: number) => {
      return {
        ...currentColumn,
        name: `Column ${index + 1}`,
        columnName: `Column ${index + 1}`,
      };
    });
    let defaultTableConfig: { [key: string]: any } = {};

    (fieldsElem?.tableConfigFields ?? []).forEach((tableConfigElem: { [key: string]: any }) => {
      defaultTableConfig = {
        ...defaultTableConfig,
        [tableConfigElem?.name]: tableConfigElem?.defaultValue,
      };
    });

    const tableData = {
      ...(selectedTable?.allTableSchema ?? {}),
      customColumnSchema: isReseted ? {} : selectedTable?.allTableSchema?.customColumnSchema,
      name: tableName,
      columnSchema:
        tableConfigFields?.useHeader === false ? defaultColumnSchema : finalColumnSchema,
      description: '',
      tableID,
      ConnecteionTableID: `${credentialID}.${tableName}`,
      appID: appID,
      dbType: dbType,
      credentialID: credentialID,
      defaultDatetimeCol: defaultDatetimeColumnName,
      datetimeCols: datetimeCols,
      fetchSettings: { ...defaultTableConfig, ...tableConfigFields },
      updates: {
        addColumns: [],
        updateColumns: [],
        deleteColumns: [],
        renameColumns: {},
        dropTable: false,
        createTable: true,
      },
      createdAt: currentTimestamp,
      updatedAt: currentTimestamp,
    };

    const getSheetIDList = (tableID: string) => {
      const { sheetIDsTBD } = getSheetChartsIDsTBD([tableID], [], appSheets);
      return sheetIDsTBD ?? [];
    };
    const isNew = !(tableImported || confirmTableCreateOpen);
    const finalTable = {
      appID: tableData.appID,
      isNew: isNew,
      sheetIDLists: isNew ? [] : getSheetIDList(tableID),
      sqlPresent: false,
      tableID: tableData.tableID,
      tableSchema: tableData,
    };
    // _supistaApiPost(`settings/${appID}/DataTable`, {
    //   data: tableData,
    // })
    _supistaAnalyticsApi(`${appID}/opType/saveDataTable/tableOperations`, finalTable)
      .then(async (response) => {
        if (response?.__d3__error) {
          toast.error(
            mls(response?.response?.errorMsg ?? 'Some error occered while importing the table')
          );
          settableDataLoading(false);
          setSubmitting(false);
          return;
        }
        // Update the local redux
        updateTableSchema(false, null, tableData, tableName);

        // reset the table cache from backend if the tableID is being reimported
        if (confirmTableCreateOpen) {
          const { customTableIDsTBD, joinTableIDsTBD } = getTableIDSTBD({
            joinTableSchema: joinTableSchema,
            customTableSchema: customTableSchema,
            tableIDs: [tableID],
          });
          const res = await _supistaAnalyticsApi(`${appID}/cacheType/tableCache/resetCache`, {
            appID,
            tableID,
            cacheType: 'tableCache',
            relatedIDs: {
              customTableIDs: customTableIDsTBD,
              joinTableIDs: joinTableIDsTBD,
            },
          });
          if (res) {
            await publishToProduction(appID);
            toast.success(mls('Table Imported and saved successfully'));
            setSubmitting(false);
            setConfirmTableCreateOpen(true);
            setTableImported(true);
            if (confirmTableCreateOpen) {
              setReTableImported(true);
            }
            settableDataLoading(false);
          } else {
            await publishToProduction(appID);
          }
        } else {
          // adding new sheet only when the table does not exit already.
          const sheetData = await addNewSheets({
            appID,
            credentialID: credentialID,
            tableName,
            tableID,
            dbType: dbType,
            defaultDatetimeColumnName,
            datetimeCols,
            description,
            successMesage:
              'Charts Auto generated by Supista AI and added to the Sheet successfully!',
          });
          if (!sheetData.skip) {
            setSelectedSheetID(sheetData.sheetID);
          }
          await publishToProduction(appID);
          setSubmitting(false);
          setConfirmTableCreateOpen(true);
          setTableImported(true);
          settableDataLoading(false);
        }

        // Close the modal after successfully importing table
        // setOpen(false);
      })
      .catch((err) => {
        toast.error(mls("Issues encountered. Couldn't import and save the table."));
        setSubmitting(false);
        settableDataLoading(false);
      });
  };
  const handleImport = () => {
    const tableConfig = formData[fieldsElem.name] ?? {};
    const updatedTableConfig = {
      ...tableConfig,
      [selectedTableName]: tableConfigFields,
    };
    importAndSaveTable({});
  };
  const handleRestPopup = () => {
    setIsResetPopup(true);
  };
  const reImportTable = (action: boolean) => {
    if (action) {
      importAndSaveTable({});
    }
    setIsReImportPopup(false);
  };
  const handleReset = async (action: boolean) => {
    if (action) {
      setSubmitting(true);
      const selectedTableRes = await getSelectedTableFromAPI({ tableName: selectedTableName });
      if (selectedTableRes?.skip) {
        toast.error(mls(selectedTableRes?.msg?.response?.errorMsg ?? 'Not able to get Table'));
        setSubmitting(false);
        return;
      }
      setSelectedTable({
        ...selectedTable,
        ...(selectedTableRes?.tableData ?? {}),
      });
      setTableConfigFields({});
      setIsReseted(false);
      if (tableImported || confirmTableCreateOpen) {
        importAndSaveTable({
          isDefaultColumnSchema: true,
          defaultColumnSchemaObject: selectedTableRes?.tableData?.columnSchema ?? {},
        });
      }
    }
    setIsResetPopup(false);
  };
  const handleDeleteTable = async (tableToBeDeleted: any) => {
    const deleteTableData = async ({ finalTable }: { finalTable: any }) => {
      const deleteRes = await _supistaAnalyticsApi(
        `${appID}/opType/deleteDataTable/tableOperations`,
        finalTable
      )
        .then(async (response) => {
          return { skip: false, msg: response };
        })
        .catch((error) => {
          return { skip: true, msg: error };
        });

      return deleteRes;
    };
    // const tableID = selectedTable.tableID ?? '';
    const tableID = tableToBeDeleted.tableID ?? '';

    const sheetsSchema = analyticsSchema?.appSheets || {};
    const reportSchema = analyticsSchema.appReports || {};
    const dashSchema = analyticsSchema.appDash || {};

    if (submitting) return;
    const joinTableIDsTBD = getJoinTableIDs(tableID, joinTableSchema, []);
    // setSubmitting(true);
    const { sheetIDsTBD, chartIDSTBD } = getSheetChartsIDsTBD(
      [tableID],
      joinTableIDsTBD,
      sheetsSchema
    );
    const { reportIDsTBD, dashIDsTBD } = getReportDashIDsTBD({
      chartIDs: chartIDSTBD ?? [],
      dashSchema: dashSchema,
      reportSchema: reportSchema,
    });

    const { customTableIDsTBD } = getTableIDSTBD({
      tableIDs: [tableToBeDeleted.tableID],
      joinTableSchema: joinTableSchema,
      customTableSchema: customTableSchema,
    });
    const sendToBackend = {
      appID,
      name: tableToBeDeleted.name,
      tableID: tableToBeDeleted.tableID,
      joinTableIDList: joinTableIDsTBD,
      customTableIDList: customTableIDsTBD,
      sheetIDList: sheetIDsTBD,
      dashIDObj: dashIDsTBD,
      reportIDObj: reportIDsTBD,
    };

    const deleteDataRes = await deleteTableData({ finalTable: sendToBackend });
    if (deleteDataRes?.skip || deleteDataRes.msg.__d3__error) {
      toast.error(
        mls(
          deleteDataRes?.msg?.response?.errorMsg ??
            "Some issues encountered. Couldn't able to Delete Table. Please try again later."
        )
      );
      setSubmitting(false);
      return;
    }
    updateTableSchema(true, tableID);
    publishToProduction(appID);
    setDeleteOpen(false);
    setSubmitting(false);
    setTableImported(false);
    setReTableImported(false);
    setConfirmTableCreateOpen(false);
  };

  const handleTableOptions = (action: string) => {
    if (action === optionObject.remove.key) {
      setDeleteOpen(true);
    }
  };
  useEffect(() => {
    if (Object.keys(formData[fieldsElem.name] ?? {})?.[0]) {
      changeTableID({ tableName: Object.keys(formData[fieldsElem.name] ?? {})?.[0] ?? '' });
    }
  }, []);
  const tableList = useMemo(() => {
    const getImportedTable = ({
      credentialID,
      appDatatable,
    }: {
      credentialID: string;
      appDatatable: { [key: string]: any };
    }) => {
      let importedTable: string[] = [];
      Object.values(appDatatable || {}).forEach((appDataTableElem) => {
        if (appDataTableElem.credentialID === credentialID) {
          importedTable = [...importedTable, appDataTableElem?.name ?? ''];
        }
      });
      return importedTable;
    };
    const importedTables = getImportedTable({
      credentialID: credentialID,
      appDatatable: analyticsSchema.appDatatable,
    });
    let importedTable: any[] = [];
    let nonImportedTable: any[] = [];

    const allTableNames = Object.keys(formData?.[fieldsElem?.name] ?? {});
    allTableNames.forEach((tableNameElem) => {
      const isImported = importedTables.some(
        (importedTableName) => importedTableName === tableNameElem
      );
      if ((tableNameElem ?? '')?.toLowerCase()?.includes((searchKey ?? '')?.toLowerCase())) {
        if (isImported) {
          importedTable = [...importedTable, { tableName: tableNameElem, isImported: true }];
        } else {
          nonImportedTable = [...nonImportedTable, { tableName: tableNameElem, isImported: false }];
        }
      }
    });
    const finalTableList = [...importedTable, ...nonImportedTable];
    return finalTableList;
  }, [analyticsSchema, credentialID, formData, fieldsElem.name, searchKey]);

  return (
    <div className='connectionFormOptions'>
      {/* <div className='connectionFormTitle importTableTitle'>
        {mls(fieldsElem.label)}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div> */}
      {deleteOpen && (
        <DeleteTable
          appID={appID}
          setDeleteOpen={setDeleteOpen}
          deleteOpen={deleteOpen}
          handleDeleteTable={handleDeleteTable}
          tableID={selectedTable.tableID}
          tableSchema={selectedTable?.allTableSchema}
        />
      )}
      <WarningPopup
        initialValue={isResetPopup}
        loading={submitting}
        setConfirmation={handleReset}
        titleText={`${mls('Are you sure you want to Reset Table')} ?`}
        discText={mls(
          '*Resetting the table will remove all the changes you have done and it wil use the orignal schema of the table. Your sql will remain intact but column created by sql will be removed.'
        )}
      />
      <WarningPopup
        initialValue={isReimportPopup}
        setConfirmation={reImportTable}
        titleText={`${mls('Are you sure you want to ReImport Table')} ?`}
        discText={mls('*The table will be reimported using the settings that you have provided.')}
      />
      <div className='connectionDataTable'>
        {tableDataLoading ? (
          <div className='connectionDataTableLoading'>
            <CircularProgress color='inherit' />
          </div>
        ) : null}
        <div className='listOfSheetsContainer'>
          <div className='searchInputDivContainer'>
            <SearchInputDiv value={searchKey} onChange={setSearchKey} border />
          </div>
          <div className='listOfSheetsScrollContainer'>
            {React.Children.toArray(
              tableList.map(({ tableName, isImported }) => {
                return (
                  <div
                    className={`listOfSheets ${
                      tableName === selectedTableName ? 'selectedSheet' : ''
                    }`}
                    onClick={() => changeTableID({ tableName })}
                  >
                    <span
                      className={`importIcon ${
                        isImported ? 'importedTableIcon' : 'nonImportedTableIcon'
                      }`}
                    >
                      <LuImport />
                    </span>
                    <div className='listOfSheetsTableNameDiv'>{tableName}</div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className='displayTableArea'>
          <div className='tableConfigFieldsContainer'>
            {tableImported || confirmTableCreateOpen ? (
              <div className='tableConfigFieldsImportButtonDiv reImportButton'>
                {tableImported || reTableImported ? (
                  <>
                    {reTableImported ? (
                      <>
                        <div className='reImportTextDiv text-success'>
                          {mls('The table') +
                            ': ' +
                            selectedTableName +
                            ' ' +
                            mls(
                              'has been successfully re-imported. Please check your charts in the sheets (Some charts may have been effected with this import)'
                            )}
                          . &nbsp;
                          <a
                            href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <span className='linkButton'>{mls('Go To Sheet')}</span>
                          </a>
                        </div>
                        <button
                          className='linkButton'
                          style={{ marginLeft: 'auto' }}
                          type='button'
                          onClick={() => handleRestPopup()}
                        >
                          {mls('Reset')}
                        </button>
                        <button
                          className='primaryButton'
                          type='button'
                          onClick={() => setIsReImportPopup(true)}
                        >
                          {mls('Re-Import Table')}
                          {submitting ? (
                            <>
                              &nbsp;&nbsp;
                              <CircularProgress size={12} color='inherit' />
                            </>
                          ) : null}
                        </button>
                        <div className='reImportRemove'>
                          <ObjectDropDown
                            object={optionObject}
                            dropDownValue={''}
                            setdropDownValue={handleTableOptions}
                            ObjectInObject
                            isChildernInsert
                            className='reImportRemoveDropdown'
                            isDropDownFixed={false}
                          >
                            <HiOutlineDotsVertical />
                          </ObjectDropDown>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='reImportTextDiv text-success'>
                          {mls('The table') +
                            ': ' +
                            selectedTableName +
                            ' ' +
                            mls(
                              'has been successfully imported and Charts have been added in its sheet. You can now use the charts from Sheets and import them in Dashboard'
                            )}
                          . &nbsp;
                          <a
                            href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <span className='linkButton'>{mls('Go to Generated Sheet')}</span>
                          </a>
                        </div>
                        <button
                          className='linkButton'
                          style={{ marginLeft: 'auto' }}
                          type='button'
                          onClick={() => handleRestPopup()}
                        >
                          {mls('Reset')}
                        </button>
                        <button
                          className='primaryButton'
                          type='button'
                          onClick={() => setIsReImportPopup(true)}
                        >
                          {mls('Re-Import Table')}
                          {submitting ? (
                            <>
                              &nbsp;&nbsp;
                              <CircularProgress size={12} color='inherit' />
                            </>
                          ) : null}
                        </button>
                        <div className='reImportRemove'>
                          <ObjectDropDown
                            object={optionObject}
                            dropDownValue={''}
                            setdropDownValue={handleTableOptions}
                            ObjectInObject
                            isChildernInsert
                            className='reImportRemoveDropdown'
                            isDropDownFixed={false}
                          >
                            <HiOutlineDotsVertical />
                          </ObjectDropDown>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className='reImportTextDiv'>
                      {mls('The table') +
                        ': ' +
                        selectedTableName +
                        ' ' +
                        mls(
                          'already exist in our database! Importing table may delete the older data related charts may not work'
                        )}
                      . &nbsp;
                      <a
                        href={`https://analytics.supista.com/${appID}/sheets/${selectedSheetID}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='linkButton'>{mls('Go To Sheet')}</span>
                      </a>
                    </div>
                    <button
                      className='linkButton'
                      style={{ marginLeft: 'auto' }}
                      type='button'
                      onClick={() => handleRestPopup()}
                    >
                      {mls('Reset')}
                    </button>
                    <button
                      className='primaryButton'
                      type='button'
                      onClick={() => setIsReImportPopup(true)}
                    >
                      {mls('Re-Import Table')}
                      {submitting ? (
                        <>
                          &nbsp;&nbsp;
                          <CircularProgress size={12} color='inherit' />
                        </>
                      ) : null}
                    </button>
                    <div className='reImportRemove'>
                      <ObjectDropDown
                        object={optionObject}
                        dropDownValue={''}
                        setdropDownValue={handleTableOptions}
                        ObjectInObject
                        isChildernInsert
                        className='reImportRemoveDropdown'
                        isDropDownFixed={false}
                      >
                        <HiOutlineDotsVertical />
                      </ObjectDropDown>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className='tableConfigFieldsImportButtonDiv'>
                <button
                  className='linkButton'
                  style={{ marginLeft: 'auto' }}
                  type='button'
                  onClick={() => handleRestPopup()}
                >
                  {mls('Reset')}
                </button>
                &nbsp; &nbsp;
                <button className='primaryButton' type='button' onClick={() => handleImport()}>
                  {mls('Import')}
                  {submitting ? (
                    <>
                      &nbsp;&nbsp;
                      <CircularProgress size={12} color='inherit' />
                    </>
                  ) : null}
                </button>
              </div>
            )}

            <div className='tableConfigFields'>
              {React.Children.toArray(
                fieldsElem.tableConfigFields.map((tableConfigElem: any, i: number) => {
                  if (!moreConfigField && i >= 2) {
                    return <></>;
                  }
                  return (
                    <GetFields
                      fieldsElem={tableConfigElem}
                      formData={tableConfigFields}
                      tableData={selectedTable}
                      setTableData={setTableData}
                      setFormData={handleTableConfigFields}
                      fileToUploadObject={fileToUploadObject}
                      setFileToUploadObject={setFileToUploadObject}
                      credentialID={credentialID}
                    />
                  );
                })
              )}
            </div>
            <div
              className={` tableShowMorButtonDiv ${
                moreConfigField ? 'moreConfigFieldButtonDiv' : ''
              }`}
            >
              <button
                className='linkButton'
                type='button'
                onClick={() => {
                  setMoreConfigField((currentValue) => !currentValue);
                }}
              >
                {moreConfigField ? 'Less settings' : 'More settings'}&nbsp;
                <MdOutlineKeyboardDoubleArrowDown className='showMoreDropDownIcon' />
              </button>
            </div>
          </div>
          <DataSourceDisplayTable
            tableTitle={selectedTableName}
            tableData={finalTableData ?? []}
            columnDataStartFrom={tableConfigFields?.startCol}
            columnSchema={selectedTable?.columnSchema ?? []}
            updateSchema={handleTableUpdate}
            handleReset={handleRestPopup}
          />
        </div>
      </div>
    </div>
  );
};

export default TableConfigField;
