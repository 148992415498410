import React, { useMemo, useState } from 'react';

//assets
import './editColors.scss';

import useUpdateEffect from '../../../hooks/useUpdateEffect';

import DropDown from '../../../features/DropDown/DropDown';
import FontSelector from '../../StyleComponents/FontSelector';
import CustomColor from './CustomColor';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import OpacitySlider from '../../StyleComponents/OpacitySlider';
import SolidColorSelector from '../../StyleComponents/SolidColorSelector';
import {
  defaultChartSetting,
  defaultLegendColorPalettes,
  defaultOpacity,
} from '../../../data/defaultChartSchema';

const KpiEditColors = (props, { show = true }) => {
  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);
  const { stateProps } = props;
  const { chartType } = stateProps;
  const { kpiTargetPoints, setKpiTargetPoints } = stateProps;
  const [automaticOpacity, setAutomaticOpacity] = useState({ opacity: defaultOpacity });

  const targetKey = useMemo(
    () =>
      chartType?.toolTip?.isMultipleValue
        ? { isTrue: true, key: 'valueStyle' }
        : { isTrue: false, key: 'style' },
    [chartType]
  );
  const resetKpiColors = () => {
    setAutomaticOpacity({ opacity: defaultOpacity });
    const newkpiTargetPoints = resetColor({ kpiTargetPoints, defaultOpacity });
    setKpiTargetPoints(newkpiTargetPoints);
  };
  useUpdateEffect(() => {
    if (isDivOpen) {
      setisContent(isDivOpen);
    } else {
      setTimeout(() => {
        setisContent(isDivOpen);
        resetKpiColors();
      }, 300);
    }
  }, [isDivOpen]);

  return (
    <div className={isDivOpen ? 'outerDiv open' : 'outerDiv'}>
      <div className='MainTitle inFlex'>
        Chart Display
        {/* <SlideSwitch isChecked={isDivOpen} setIsChecked={setisDivOpen} /> */}
      </div>
      <div className='grayContainers'>
        {isContent && (
          <>
            <LegendValueDiv
              chartType={chartType}
              kpiTargetPoints={kpiTargetPoints}
              setKpiTargetPoints={setKpiTargetPoints}
              stateProps={stateProps}
              targetKey={targetKey}
              automaticOpacity={automaticOpacity}
              setAutomaticOpacity={setAutomaticOpacity}
            />
          </>
        )}
        <div className='resetDefault' onClick={() => resetKpiColors()}>
          Reset to defaults
        </div>
      </div>
    </div>
  );
};

export default KpiEditColors;

const LegendValueDiv = ({
  stateProps,
  show = false,
  targetKey,
  automaticOpacity,
  setAutomaticOpacity,
}) => {
  const { chartType, kpiTargetPoints, setKpiTargetPoints } = stateProps;
  const automaticCustomArray = ['automatic', 'custom'];
  const handleAutomaticCustom = (elem) => {
    if (elem === automaticCustomArray[0]) {
      const newkpiTargetPoints = resetColor({ kpiTargetPoints, defaultOpacity });
      //add reset
      setAutomaticOpacity({ opacity: defaultOpacity });

      setKpiTargetPoints({ ...newkpiTargetPoints, type: elem });
    } else {
      setKpiTargetPoints({ ...kpiTargetPoints, type: elem });
    }
  };
  const handleOpacity = (action) => {
    const newColorbyValue = kpiTargetPoints.targets.map((elem) => {
      return { ...elem, ...action };
    });
    // kpiTargetPoints.targets;

    setKpiTargetPoints({ ...kpiTargetPoints, targets: newColorbyValue });
    setAutomaticOpacity(action);
  };

  const handleTargetFontStyle = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, targetFontStyle: e });
  };

  return (
    <>
      <ClosableWhiteDiv title={`Edit color`} show={show} autoCloseOff={true} className={'legend'}>
        {/* <FontSelector
          fontStyle={kpiTargetPoints.targetFontStyle}
          setFontStyle={handleTargetFontStyle}
        />
        <SolidColorSelector
          color={kpiTargetPoints.targetFontStyle}
          setColor={handleTargetFontStyle}
        /> */}

        {kpiTargetPoints.targets.length >= 1 ? (
          <>
            <div className='titleDiv'>Target Colors</div>
            <DropDown
              array={automaticCustomArray}
              dropDownValue={kpiTargetPoints.type}
              setdropDownValue={handleAutomaticCustom}
            />
            {kpiTargetPoints.type === automaticCustomArray[0] && (
              <div className='opacityOuterDiv'>
                <OpacitySlider
                  color={automaticOpacity}
                  setColor={handleOpacity}
                  addWithTitle={'Target'}
                />
              </div>
            )}
            {kpiTargetPoints.type === automaticCustomArray[1] && (
              <>
                <CustomColor
                  chartType={chartType}
                  stateProps={stateProps}
                  targetKey={targetKey}
                  kpiTargetPoints={kpiTargetPoints}
                  setKpiTargetPoints={setKpiTargetPoints}
                />
                <br />
              </>
            )}
          </>
        ) : (
          <>
            <div className='titleDiv'>Add a Target Point</div>
          </>
        )}
      </ClosableWhiteDiv>
    </>
  );
};
const resetColor = (props) => {
  const { kpiTargetPoints, defaultOpacity, allRest } = props;

  const legendColorPalettesLength = defaultLegendColorPalettes.length;
  let lengthNumber = 0;
  const newTargets = kpiTargetPoints.targets.map((elem) => {
    if (lengthNumber >= legendColorPalettesLength - 1) {
      lengthNumber = 0;
    } else {
      lengthNumber++;
    }

    const newElem = {
      ...elem,
      color: defaultLegendColorPalettes[lengthNumber],
      opacity: defaultOpacity,
    };

    return newElem;
  });
  if (allRest) {
    return {
      ...kpiTargetPoints,
      targets: newTargets,
      targetFontStyle: defaultChartSetting.targetPoints.targetFontStyle,
    };
  }
  return { ...kpiTargetPoints, targets: newTargets };
};
