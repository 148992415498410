import { LegendItem, LegendLabel, LegendThreshold } from '@visx/legend';
import { scaleThreshold } from '@visx/scale';
import React, { useState } from 'react';
import { numberTickFormat } from '../tickFormatHelpers';
import { getDefaultColorByIndex } from '../colorHelper';

function compareTwoArrays(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}

const useGeoLegend = ({
  legendGlyphSize = 20,
  steps = [200, 400, 600, 800, 1000],
  colors = ['#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'],
  shape,
  opacity = 1,
}) => {
  const [selectedLegendExtent, setSelectedLegendExtent] = useState(null);
  const thresholdScale = scaleThreshold({
    domain: steps,
    range: colors,
  });

  const GeoLegend = () => {
    let stop = false;
    return (
      <LegendThreshold
        labelDelimiter='to'
        // labelFormat={(d) => format('.2s')(d)}
        labelFormat={numberTickFormat}
        scale={thresholdScale}
      >
        {(labels) => {
          return (
            <>
              {labels.reverse().map((label, i) => {
                if (!label?.value || stop) {
                  return <></>;
                }

                if (!steps[1]) {
                  stop = true;
                }

                return (
                  <LegendItem
                    key={`legend-quantile-${i}`}
                    margin='0 5px'
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedLegendExtent((prev) => {
                        if (Array.isArray(prev) && compareTwoArrays(prev, label.extent)) {
                          return null;
                        }
                        return label.extent;
                      });
                    }}
                  >
                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                      {shape === 'circle' ? (
                        <circle
                          fill={label?.value?.color ?? label?.value ?? getDefaultColorByIndex(i)}
                          opacity={(label?.value?.opacity ?? 100) / 100}
                          r={legendGlyphSize / 2.5}
                          cx={legendGlyphSize / 2.5}
                          cy={legendGlyphSize / 2.5}
                        />
                      ) : (
                        <rect
                          opacity={opacity}
                          fill={label.value}
                          width={legendGlyphSize || 20}
                          height={legendGlyphSize || 20}
                        />
                      )}
                    </svg>
                    <LegendLabel align='left' margin='0 0 0 4px'>
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            </>
          );
        }}
      </LegendThreshold>
    );
  };

  return { thresholdScale, GeoLegend, selectedLegendExtent, setSelectedLegendExtent };
};

export default useGeoLegend;
