import React, { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// import { getGroupDataByChart, updateChartType } from '../../utils/chartHelperFunctions';
import {
  allDropableID,
  defaultChartSetting,
  defaultDataTypeStyles,
} from '../../data/defaultSchema';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import { toast } from 'react-toastify';

import { RxDragHandleDots2 } from 'react-icons/rx';

const DragableTable = ({ dragableDivId, stateProps, tableNumber }) => {
  const { tablesData } = stateProps;
  const tableDragableDivId = dragableDivId + tableNumber;
  return (
    <>
      <Droppable droppableId={tableDragableDivId} key={tableDragableDivId} isDropDisabled={true}>
        {(provided, snapshot) => (
          <div className='dragableContainer' ref={provided.innerRef}>
            {React.Children.toArray(
              tablesData[tableNumber].items.map((tableElem, i) => {
                return (
                  <TablesItem
                    i={i}
                    tableElem={tableElem}
                    tableDragableDivId={tableDragableDivId}
                    stateProps={stateProps}
                  />
                );
              })
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

function TablesItem({ i, tableElem, tableDragableDivId, stateProps }) {
  const {
    xAxisData,
    setXAxisData,
    yAxisData,
    setYAxisData,
    chartType,
    setChartToolTip,
    tablesData,
    setChartXAxisStyle,
    setChartYAxisStyle,
    setGeoGroup,
    setBarStackLineTargetPoints,
    plotGroup,
    setPlotGroup,
    setPlotGroupStyle,
    setchartType,
    setChartWidth,
  } = stateProps;

  const { id } = tableElem;
  const column = `${tableElem.tableName}.${tableElem.columnName}`;
  const [checked, setChecked] = useState(() => {
    if (xAxisData.some((e) => e.column === column)) {
      return true;
    } else if (yAxisData.some((e) => e.column === column)) {
      return true;
    }
    return false;
  });

  const removeAxisItem = () => {
    if (checked) {
      const removeObjectWithColumnId = (arr, column, setStyle, defaultLable) => {
        const filteredColumn = arr.filter((item) => item.column !== column);

        // // Removing from axis Style
        // updateAxisStyleLable(setStyle, filteredColumn, defaultLable);

        //Remove ToolTip
        const removeToolTip = (target) => {
          const filteredToolTip = target.style.filter((item) => item.column !== column);
          const finalToolTip = { ...target, style: filteredToolTip };
          return finalToolTip;
        };

        //Remove removeValueToolTip
        const removeValueToolTip = (target) => {
          const filteredToolTip = target.valueStyle.filter((item) => item.column !== column);
          const finalToolTip = { ...target, valueStyle: filteredToolTip };
          return finalToolTip;
        };

        //Remove ToolTip
        setChartToolTip((preElem) => removeToolTip(preElem));
        //Remove removeValueToolTip
        if (chartType?.toolTip?.isMultipleValue) {
          setChartToolTip((preElem) => removeValueToolTip(preElem));
        }

        return filteredColumn;
      };

      if (xAxisData.some((e) => e.column === column)) {
        const newArr = removeObjectWithColumnId(
          xAxisData,
          column,
          setChartXAxisStyle,
          defaultChartSetting.xAxisStyle.label
        );
        setXAxisData(newArr);
        // update ChartType
        // updateChartType({
        //   axisData: newArr,
        //   destinationID: allDropableID.xAxisId,
        //   chartType,
        //   setchartType,
        //   toast,
        //   isAdd: false,
        //   stateProps,
        // });

        //Special Case
        //BARSTACKLINE
        if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
          setBarStackLineTargetPoints(defaultChartSetting.barStackLineTargetPoints);
        }
      }
      if (yAxisData.some((e) => e.column === column)) {
        const newArr = removeObjectWithColumnId(
          yAxisData,
          column,
          setChartYAxisStyle,
          defaultChartSetting.yAxisStyle.label
        );
        setYAxisData(newArr);

        // update ChartType
        // updateChartType({
        //   axisData: newArr,
        //   destinationID: allDropableID.yAxisId,
        //   chartType,
        //   setchartType,
        //   toast,
        //   isAdd: false,
        //   stateProps,
        // });

        //GEO
        if (
          chartType.chartKey === allChartKeys.GEOINDIA ||
          chartType.chartKey === allChartKeys.GEOUSA ||
          chartType.chartKey === allChartKeys.GEOCANADA ||
          chartType.chartKey === allChartKeys.GEOWORLD
        ) {
          setGeoGroup([]);
        }
      }
      if (plotGroup.some((e) => e.column === column)) {
        const newArr = removeObjectWithColumnId(
          plotGroup,
          column,
          setPlotGroupStyle,
          defaultChartSetting.plotGroupStyle.label
        );
        setPlotGroup(newArr);

        //GEO
        if (
          chartType.chartKey === allChartKeys.GEOINDIA ||
          chartType.chartKey === allChartKeys.GEOUSA ||
          chartType.chartKey === allChartKeys.GEOCANADA ||
          chartType.chartKey === allChartKeys.GEOWORLD
        ) {
          setGeoGroup([]);
        }
      }
      setChecked(!checked);
    }
  };

  useEffect(() => {
    // const groupData = getGroupDataByChart({ stateProps, chartType });
    if (xAxisData.some((e) => e.column === column)) {
      setChecked(true);
    } else if (yAxisData.some((e) => e.column === column)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxisData, yAxisData, tablesData, plotGroup]);

  return (
    <>
      <Draggable
        key={id}
        draggableId={`${tableDragableDivId + i}`}
        index={i}
        //   isDragDisabled={checked}
      >
        {(provided, snapshot) => (
          <>
            <div
              className='dragableItem'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
              title={tableElem.name}
            >
              <div className={'tablesItem'}>
                {checked ? (
                  <input
                    type='checkbox'
                    className='form-check-input'
                    name={tableElem.name}
                    value={tableElem.name}
                    checked={checked}
                    onChange={(e) => removeAxisItem(e)}
                  />
                ) : (
                  <div className='draggingSvg'>
                    <RxDragHandleDots2 />
                  </div>
                )}
                <div className='tablesElemTitle'>{tableElem.name}</div>
                <div
                  className='tablesElemDataType'
                  style={{ color: defaultDataTypeStyles[tableElem.dataType].color }}
                >
                  {defaultDataTypeStyles[tableElem.dataType].icon}
                </div>
              </div>
            </div>
            {snapshot.isDragging && (
              <>
                <div className={'tablesItem'}>
                  {checked ? (
                    <input
                      type='checkbox'
                      className='form-check-input'
                      name={tableElem.name}
                      value={tableElem.name}
                      checked={checked}
                      onChange={(e) => removeAxisItem(e)}
                    />
                  ) : (
                    <div className='draggingSvg'>
                      <RxDragHandleDots2 />
                    </div>
                  )}

                  {tableElem.name}
                </div>
              </>
            )}
          </>
        )}
      </Draggable>
    </>
  );
}

export default DragableTable;

// const removeAxisItem = () => {
//   if (checked) {
//     const removeObjectWithColumnId = (arr, column, setStyle, defaultLable) => {
//       const filteredColumn = arr.filter((item) => item.column !== column);

//       // // Removing from axis Style
//       // updateAxisStyleLable(setStyle, filteredColumn, defaultLable);

//       //Remove ToolTip
//       const removeToolTip = (target) => {
//         const filteredToolTip = target.style.filter((item) => item.column !== column);
//         const finalToolTip = { ...target, style: filteredToolTip };
//         return finalToolTip;
//       };

//       //Remove removeValueToolTip
//       const removeValueToolTip = (target) => {
//         const filteredToolTip = target.valueStyle.filter((item) => item.column !== column);
//         const finalToolTip = { ...target, valueStyle: filteredToolTip };
//         return finalToolTip;
//       };

//       //Remove ToolTip
//       setChartToolTip((preElem) => removeToolTip(preElem));
//       //Remove removeValueToolTip
//       if (chartType?.toolTip?.isMultipleValue) {
//         setChartToolTip((preElem) => removeValueToolTip(preElem));
//       }

//       return filteredColumn;
//     };

//     if (xAxisData.some((e) => e.column === column)) {
//       const newArr = removeObjectWithColumnId(
//         xAxisData,
//         column,
//         setChartXAxisStyle,
//         defaultChartSetting.xAxisStyle.label
//       );
//       setXAxisData(newArr);
//       // update ChartType
//       updateChartType({
//         axisData: newArr,
//         destinationID: allDropableID.xAxisId,
//         chartType,
//         setchartType,
//         toast,
//         isAdd: false,
//         stateProps,
//       });

//       //Special Case
//       //BARSTACKLINE
//       if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
//         setBarStackLineTargetPoints(defaultChartSetting.barStackLineTargetPoints);
//       }
//     }
//     if (yAxisData.some((e) => e.column === column)) {
//       const newArr = removeObjectWithColumnId(
//         yAxisData,
//         column,
//         setChartYAxisStyle,
//         defaultChartSetting.yAxisStyle.label
//       );
//       setYAxisData(newArr);

//       // update ChartType
//       updateChartType({
//         axisData: newArr,
//         destinationID: allDropableID.yAxisId,
//         chartType,
//         setchartType,
//         toast,
//         isAdd: false,
//         stateProps,
//       });

//       //GEO
//       if (
//         chartType.chartKey === allChartKeys.GEOINDIA ||
//         chartType.chartKey === allChartKeys.GEOUSA ||
//         chartType.chartKey === allChartKeys.GEOCANADA ||
//         chartType.chartKey === allChartKeys.GEOWORLD
//       ) {
//         setGeoGroup([]);
//       }
//     }
//     if (plotGroup.some((e) => e.column === column)) {
//       const newArr = removeObjectWithColumnId(
//         plotGroup,
//         column,
//         setPlotGroupStyle,
//         defaultChartSetting.plotGroupStyle.label
//       );
//       setPlotGroup(newArr);

//       //GEO
//       if (
//         chartType.chartKey === allChartKeys.GEOINDIA ||
//         chartType.chartKey === allChartKeys.GEOUSA ||
//         chartType.chartKey === allChartKeys.GEOCANADA ||
//         chartType.chartKey === allChartKeys.GEOWORLD
//       ) {
//         setGeoGroup([]);
//       }
//     }
//     setChecked(!checked);
//   }
// };
