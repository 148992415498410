import React, { useMemo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import './geoMap.css';
import { mls } from 'lib/multilanguagesupport';
import {
  allChartKeys,
  allChartTypesObj,
} from 'lib/reusable-components/reusableData/chartTypesSchema';
import { GeoMapFitBounds, GeoMapWithResize } from './GeoMapHeplerComponents';
import useOrdinalLegend from 'lib/visx-lib/helpers/hooks/userOrdinalLegend';
import { hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';
import GeoMapMarkers from './GeoMapMarkers';

const GeoMap = (visxProps: any) => {
  const { theme, chartData, dataToPlot, type, isReport } = visxProps;

  const valueStyle = useMemo(() => {
    const filterdValueStyle = (theme?.tooltip?.valueStyle || []).filter((styleData: any) => {
      if (styleData.axis === 'others') {
        return true;
      }
      return styleData.axis === allChartTypesObj?.[type]?.toolTip?.target;
    });
    return filterdValueStyle;
  }, [theme?.tooltip?.valueStyle]);

  const dataValue = dataToPlot[0]?.data?.[0]?.values || [];

  const legendColorScale: { [key: string]: any } = {};

  (valueStyle || []).forEach((vs: any) => {
    const isInclude = (dataValue ?? []).some((dataValueElem: any[]) => {
      return dataValueElem[0] === vs.label;
    });
    if (isInclude) {
      const color = vs?.color;
      const opacity = (vs?.opacity ?? 100) / 100;
      legendColorScale[vs.label || 'Null'] = hexWithOpacity(color, opacity);
    }
  });
  const { colorScale, Legend } = useOrdinalLegend({
    colorScale: legendColorScale,
    isReport,
  });
  if (!dataToPlot[0]) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('No data to show with the current settings. Please use another settings!')}
      </div>
    );
  }
  const columnNames = dataToPlot[0]?.data?.[0]?.column_names || [];
  const allAxisData = chartData?.tooltip?.style ?? [];
  const isColor = chartData?.chartType === allChartKeys.GEOMAPCOLOR;

  return (
    <>
      <div style={{ width: '100%', height: '30rem', overflow: 'auto', borderRadius: '6px' }}>
        <MapContainer
          center={{ lat: 51.505, lng: -0.09 }}
          zoom={13}
          minZoom={1}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            // url='https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png'
            // attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under ODbL.'
          />
          <MarkerClusterGroup chunkedLoading>
            {React.Children.toArray(
              dataValue.map((geoMarkerElem: any) => {
                return (
                  <GeoMapMarkers
                    geoMarkerElem={geoMarkerElem}
                    isColor={isColor}
                    theme={theme}
                    colorScale={colorScale}
                    geoMapColor={chartData.geoMapColor ?? {}}
                    columnNames={columnNames}
                    allAxisData={allAxisData}
                    yAxis={chartData?.yAxis ?? []}
                  />
                );
              })
            )}
          </MarkerClusterGroup>
          <GeoMapFitBounds markers={dataValue} isColor={isColor} />
          <GeoMapWithResize chartWidth={chartData.chartWidth} />
        </MapContainer>
      </div>
      <div style={{ marginTop: 20 }}>
        <Legend />
      </div>
    </>
  );
};

export default GeoMap;
