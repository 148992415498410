import { useState } from 'react';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { Modal } from 'react-bootstrap-v5';
import { mls } from 'lib/multilanguagesupport';
import { KTSVG } from '_metronic/helpers';
import Select from 'react-select';
import { _supistaAnalyticsApi, _supistaApiPost } from 'lib/server-connection/connections';
import useMessageHandling from 'lib/reusable-components/reusableUtils/commons/useMessageHandling';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';

const UpdateUser = ({
  appID,
  isNew,
  userSchema,
  isEdit,
  setEdit,
  setAllUsersList,
  allUsersList,
  userIndex,
}) => {
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const appTeams = analyticsSchema?.appTeams || {};

  const updateSchema = useUpdateSchema();
  const toastMsg = useMessageHandling();

  const [userData, setUserData] = useState(userSchema || {});
  const [selectedOptions, setSelectedOptions] = useState(
    (userData.role || []).map((id) => ({
      value: id,
      label: appTeams[id]?.name,
    }))
  );
  const [isSwitch, setSwitch] = useState(false);

  const allOptions = Object.keys(appTeams).map((teamID) => ({
    value: teamID,
    label: appTeams[teamID]?.name || '',
  }));
  const handleChange = (e) => {
    setUserData({ ...userData, emailID: e.target.value });
  };
  const handlePassword = (e) => {
    setUserData({ ...userData, setPassKey: { isPassKey: true, passKey: e.target.value } });
  };
  const handleBlur = (value) => {
    setUserData({ ...userData, role: selectedOptions.map((ele) => ele.value) });
  };
  const handleSwitch = () => {
    setSwitch(!isSwitch);
  };
  const handleSave = async () => {
    if (isSwitch && !userData?.setPassKey?.passKey) {
      toastMsg(
        reduxConstants.config.APP_STATUS.ERROR_TOAST,
        `${mls('Password is Required')}`,
        5,
        true
      );
      return;
    }
    if (!userData.emailID || (userData.role || []).length <= 0) {
      toastMsg(
        reduxConstants.config.APP_STATUS.ERROR_TOAST,
        `${mls('Email Id and Team is Required')}`,
        5,
        true
      );
      return;
    }
    const newAllUserList = [...allUsersList];
    if (isNew) {
      const dataToSend = {
        emailID: userData.emailID,
        role: userData.role,
        ITStakeholder: [],
        setPassKey: isSwitch ? userData.setPassKey : {} || {},
      };
      const endPoint = `auth/${appID}/inviteUser`;
      const res = await _supistaApiPost(endPoint, { data: dataToSend });
      if (res && res.__d3__success) {
        newAllUserList[userIndex] = {
          ...dataToSend,
          userID: res.response.userID,
          addedByEmailID: res.response.addedByEmailID,
        };
        setAllUsersList(newAllUserList);
        setEdit(null);
        toastMsg(
          reduxConstants.config.APP_STATUS.TOAST,
          `${mls('User created successfully')}`,
          5,
          true
        );
      } else {
        toastMsg(
          reduxConstants.config.APP_STATUS.ERROR_TOAST,
          `${mls(
            res?.error?.emailID?.[0] ||
              res?.error?.['setPassKey.passKey']?.[0] ||
              'Some error occured'
          )}`,
          5,
          true
        );
      }
    } else {
      const dataToSend = {
        userID: userData.userID,
        emailID: userData.emailID,
        role: userData.role,
        ITStakeholder: [],
        setPassKey: isSwitch ? userData.setPassKey : {} || {},
      };
      const endPoint = `auth/${appID}/updateUserOfApp`;
      const res = await _supistaApiPost(endPoint, { data: dataToSend });
      if (res && res.__d3__success) {
        newAllUserList[userIndex] = {
          ...userData,
          ...dataToSend,
        };
        setAllUsersList(newAllUserList);
        setEdit(null);
        toastMsg(
          reduxConstants.config.APP_STATUS.TOAST,
          `${mls('User updated successfully')}`,
          5,
          true
        );
      } else {
        toastMsg(
          reduxConstants.config.APP_STATUS.ERROR_TOAST,
          `${mls(
            res?.error?.emailID?.[0] ||
              res?.error?.['setPassKey.passKey']?.[0] ||
              'Some error occured'
          )}`,
          5,
          true
        );
      }
    }
    const getNewTeamSchema = await _supistaAnalyticsApi(
      `${appID}/cacheType/teamCache/resetCache`,
      {}
    );
    if (!getNewTeamSchema?.__d3__error && getNewTeamSchema?.response?.appTeams) {
      updateSchema(null, 'appTeams', getNewTeamSchema?.response?.appTeams);
    }
  };
  const handleDelete = async () => {
    const dataToSend = { emailID: userData.emailID };
    const newAllUserList = [...allUsersList];
    const endPoint = `auth/${appID}/deleteUserOfApp`;
    const res = await _supistaApiPost(endPoint, { data: dataToSend });
    if (res && res.__d3__success) {
      newAllUserList.splice(userIndex, 1);
      setAllUsersList(newAllUserList);
      const getNewTeamSchema = await _supistaAnalyticsApi(
        `${appID}/cacheType/teamCache/resetCache`,
        {}
      );
      if (!getNewTeamSchema?.__d3__error && getNewTeamSchema?.response?.appTeams) {
        updateSchema(null, 'appTeams', getNewTeamSchema?.response?.appTeams);
      }
      setEdit(null);
      toastMsg(
        reduxConstants.config.APP_STATUS.TOAST,
        `${mls('User deleted successfully')}`,
        5,
        true
      );
    } else {
      toastMsg(
        reduxConstants.config.APP_STATUS.ERROR_TOAST,
        `${mls('Some error occured')}`,
        5,
        true
      );
    }
  };
  return (
    <>
      <Modal show={!!isEdit} onHide={() => setEdit(null)} centered size='md' id='AllUsersList'>
        <div className='modal-content rounded'>
          <div className='modal-header border-0 pb-0'>
            <div className='modal-title'>
              <h4 className=''>{isNew ? mls('Add New User') : mls('Update User')}</h4>
              <div className='text-muted fw-bold fs-5'>
                {isNew ? mls(`Add a new user to this App`) : mls('Update the team for this user')}
              </div>
            </div>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setEdit(null)}
              data-bs-dismiss='modal'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-5 px-10'>
            <div className='d-flex flex-column'>
              <div className='row mt-5'>
                <div className='flex-row-fluid py-5 px-5'>
                  <div className='w-100'>
                    <div className='mb-5'>
                      <label className='form-label required'>{mls('Email ID')}</label>

                      <input
                        type='email'
                        name='Email ID'
                        disabled={!isNew}
                        value={userData.emailID || ''}
                        onChange={(e) => handleChange(e)}
                        className='form-control form-control-sm form-control-solid'
                      />
                    </div>

                    <div className='mb-5'>
                      <label className='form-label required'>
                        <span>{mls('Add to Team')}</span>
                      </label>

                      {/* <select
                        name='Add To Team'
                        value={userData.role || []}
                        onChange={(e) => {}}
                        className='form-control form-control-sm form-control-solid'
                      >
                        {Object.keys(appTeams).map((teamID) => (
                          <option key={teamID} value={teamID}>
                            {appTeams[teamID]?.name || teamID}
                          </option>
                        ))}
                      </select> */}

                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: '#f5f8fa',
                            borderColor: '#f5f8fa',
                            boxShadow: 'none',
                            border: 0,
                            borderRadius: '6px',
                          }),
                          multiValue: (styles, { data }) => ({
                            ...styles,
                            backgroundColor: '#009ef7',
                            borderRadius: '4px',
                          }),
                          multiValueLabel: (styles) => ({
                            ...styles,
                            color: 'white',
                          }),
                        }}
                        isMulti
                        options={allOptions}
                        // getOptionLabel={(option) => option.name}
                        value={selectedOptions || []}
                        onChange={(options) => setSelectedOptions(options)}
                        onBlur={handleBlur}
                        isSearchable
                        menuPlacement={'auto'}
                      />
                    </div>
                  </div>
                  <div className='mb-5'>
                    <label className='form-label form-switch ps-0 d-flex align-items-center'>
                      <span>{mls('Set Password?')}</span>
                      <input
                        className='form-check-input w-35px h-20px ms-4'
                        type='checkbox'
                        value={true}
                        checked={isSwitch}
                        onChange={handleSwitch}
                        name='notifications'
                      />
                    </label>
                  </div>
                  {isSwitch && (
                    <div className='mb-5'>
                      <label className='form-label required'>{mls('Password')}</label>

                      <input
                        type='password'
                        name='Password'
                        value={userData?.setPassKey?.passKey || ''}
                        onChange={(e) => handlePassword(e)}
                        className='form-control form-control-sm form-control-solid'
                      />
                    </div>
                  )}

                  <div className='d-flex justify-content-end mt-5 '>
                    {!isNew && (
                      <button className='btn btn-sm btn-light-danger me-5' onClick={handleDelete}>
                        {mls('Delete')}
                      </button>
                    )}
                    <button className='btn btn-sm btn-light-primary' onClick={handleSave}>
                      {mls('Save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UpdateUser;
