import React, { useState } from 'react';

import { allDropableID, defaultSolidColorPalettes } from '../../../data/defaultChartSchema';
import xWhite from '../../../assets/x.svg';

import useDebounce from '../../../hooks/useDebounce';

import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import OpacitySlider from '../OpacitySlider';
import { defaultOthers } from 'lib/reusable-components/reusableData/chartData';
import { getOther } from 'lib/reusable-components/reusableFunction/chartHealperReusableFunction';
import { mls } from 'lib/multilanguagesupport';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';

const CustomColor = (props) => {
  const { stateProps, targetKey, className = '' } = props;
  const {
    chartType,
    chartToolTip,
    setChartToolTip,
    barStackLineTargetPoints,
    setBarStackLineTargetPoints,
  } = stateProps;

  const [isPopUpOn, setIsPopUpOn] = useState(true);
  const handlePopUp = () => {
    setIsPopUpOn(!isPopUpOn);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const othersData = getOther({ array: chartToolTip[targetKey.key] });
  const isMultipleValue = chartType?.toolTip?.isMultipleValue;
  return (
    <>
      <div className='legendButton'>
        <button className='primaryButton secondaryButtonColor' onClick={() => handlePopUp()}>
          {mls('Customize Legends')}
        </button>
      </div>
      <PopUpDivChild initialValue={isPopUpOn} setPopUp={handlePopUp}>
        <div
          className={
            targetKey?.isTrue
              ? `editLegend ${className}`
              : `editLegend legendPopupColumn ${className}`
          }
        >
          <div className='legendPopupItems upper'>
            <div className='legendPopupItem serialNumber'></div>
            <div className='legendPopupItem title'>{mls('Name')}</div>

            {isMultipleValue && <div className='legendPopupItem dataTitle'>{mls('Columns')}</div>}
            <div className='legendPopupItem title'>{mls('Select color')}</div>
            <div className='legendPopupItem title'>{mls('Opacity')}</div>
          </div>
          <div className='legendPopupScrollableDiv'>
            {React.Children.toArray(
              chartToolTip[targetKey.key].map((elem, i) => {
                if (elem.axis) {
                  if (chartType?.toolTip?.target) {
                    const target = chartType.toolTip.target;
                    if (target.trim() !== '') {
                      if (elem.axis !== target) {
                        if (elem.axis !== allDropableID.tooltipAxisId) {
                          return null;
                        }
                        // return null;
                      }
                    }
                  }
                }
                return (
                  <LegendPopupItem
                    elem={elem}
                    target={chartToolTip}
                    setTarget={setChartToolTip}
                    index={i}
                    isMultipleValue={isMultipleValue}
                    targetKey={targetKey}
                  />
                );
              })
            )}
            {isMultipleValue && (
              <OtherPopupItem
                elem={othersData}
                target={chartToolTip}
                setTarget={setChartToolTip}
                isMultipleValue={isMultipleValue}
                targetKey={targetKey}
              />
            )}
            {chartType.chartKey === allChartKeys.BARSTACKLINE &&
              React.Children.toArray(
                barStackLineTargetPoints.targetLines.map((elem, i) => {
                  return (
                    <BarStackLegendItem
                      elem={elem}
                      target={barStackLineTargetPoints}
                      setTarget={setBarStackLineTargetPoints}
                      index={i}
                      chartType={chartType}
                      isMultipleValue={isMultipleValue}
                    />
                  );
                })
              )}
          </div>
        </div>
      </PopUpDivChild>
    </>
  );
};

export default CustomColor;

const LegendPopupItem = (props) => {
  const { elem, target, setTarget, index, targetKey, isMultipleValue } = props;

  const handleColor = (newElem) => {
    const sourceitems = Array.from(target[targetKey.key]);
    const [selectedItem] = sourceitems.splice(index, 1);
    const newSelectedItem = { ...selectedItem, ...newElem };
    sourceitems.splice(index, 0, newSelectedItem);
    setTarget({ ...target, [targetKey.key]: sourceitems });
  };
  return (
    <div className='legendPopupItems'>
      <div className='legendPopupItem serialNumber'></div>
      <div className='legendPopupItem title'>{elem.label}</div>
      {isMultipleValue && <div className='legendPopupItem dataTitle'>{elem.origin}</div>}
      <div className='legendPopupItem selectColor'>
        <SolidColorSelector color={elem} setColor={handleColor} />
      </div>
      <div className='legendPopupItem Opacity'>
        <OpacitySlider color={elem} setColor={handleColor} />
      </div>
    </div>
  );
};

const OtherPopupItem = (props) => {
  const { elem, target, setTarget, targetKey, isMultipleValue } = props;

  const handleColor = (newElem) => {
    const other = target[targetKey.key].some((elm) => {
      return elm.axis === defaultOthers.axis;
    });
    if (other) {
      const newTarget = target[targetKey.key].map((targets) => {
        if (targets.axis === defaultOthers.axis) {
          return newElem;
        }
        return targets;
      });
      setTarget({ ...target, [targetKey.key]: newTarget });
      return;
    }
    setTarget({ ...target, [targetKey.key]: [...target[targetKey.key], newElem] });
  };
  return (
    <div className='legendPopupItems'>
      <div className='legendPopupItem serialNumber'></div>
      <div className='legendPopupItem title'>{elem.label}</div>
      {isMultipleValue && <div className='legendPopupItem dataTitle'>{elem.origin}</div>}
      <div className='legendPopupItem selectColor'>
        <SolidColorSelector color={elem} setColor={handleColor} />
      </div>
      <div className='legendPopupItem Opacity'>
        <OpacitySlider color={elem} setColor={handleColor} />
      </div>
    </div>
  );
};

const BarStackLegendItem = (props) => {
  const { elem, target, setTarget, index } = props;

  const handleColor = (newElem) => {
    const sourceitems = Array.from(target.targetLines);
    const [selectedItem] = sourceitems.splice(index, 1);
    const newSelectedItem = { ...selectedItem, ...newElem };
    sourceitems.splice(index, 0, newSelectedItem);
    setTarget({ ...target, targetLines: sourceitems });
  };
  return (
    <div className='legendPopupItems'>
      <div className='legendPopupItem serialNumber'></div>
      <div className='legendPopupItem title'>{elem.label}</div>
      <div className='legendPopupItem selectColor'>
        <SolidColorSelector color={elem} setColor={handleColor} />
      </div>
      <div className='legendPopupItem Opacity'>
        <OpacitySlider color={elem} setColor={handleColor} />
      </div>
    </div>
  );
};

const SolidColorSelector = ({ color, setColor }) => {
  const [selectedColor, setSelectedColor] = useState(color?.color || defaultSolidColorPalettes[0]);
  const debounceSelectedColor = useDebounce(selectedColor);
  const handleColor = (e) => {
    if (e.target.value.length >= 8) {
      return;
    }
    if (e.target.value.length === 0) {
      return;
    }
    setSelectedColor(e.target.value.toUpperCase());
  };

  useUpdateEffect(() => {
    if (!color) return;
    if (!debounceSelectedColor) return;

    setColor({ ...color, color: selectedColor });
  }, [debounceSelectedColor]);

  return (
    <div className='solidColorSelctor colorSelector'>
      <div className='colorInputDiv'>
        <input
          type='text'
          className='colorHashInput'
          value={selectedColor}
          onChange={(e) => handleColor(e)}
        />
        <input
          type='color'
          value={selectedColor}
          className='inputTypeColorPicker'
          onChange={(e) => handleColor(e)}
          style={{
            padding: 0,
            width: '100%',
          }}
        />
        <div
          className='colorDropperInput'
          style={{
            backgroundColor: selectedColor,
            opacity: color.opacity / 100,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <svg
            viewBox='0 0 24 24'
            xmlns='<http://www.w3.org/2000/svg>'
            fill={selectedColor}
            style={{ position: 'absolute' }}
          >
            <rect width='50' height='50' fill='inherit' />
          </svg>
          <input type='color' value={selectedColor} onChange={(e) => handleColor(e)} />
        </div>
      </div>
    </div>
  );
};
