import { allDropableID } from '../../../data/defaultChartSchema';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import React, { useMemo, useState } from 'react';
import FontSelector from '../../../components/StyleComponents/FontSelector';
import { defaultSelectedFontSize } from '../../../data/defaultChartSchema';
import DateTimeFormatterComp from 'lib/chart-settings-lib/features/Formatter/DateTimeFormatterComp';
import { mls } from 'lib/multilanguagesupport';
const defautText = {
  textStyle: [],
  fontHeight: defaultSelectedFontSize,
};

interface PivotTableXAxisProps {
  stateProps: any;
}

const PivotTableXAxis = ({ stateProps }: PivotTableXAxisProps) => {
  const { chartToolTip, setChartToolTip, xAxisData } = stateProps;

  const updateStyle = (data: any, index: number) => {
    setChartToolTip((currentValue: any) => {
      const sourceitems = Array.from(currentValue.style);
      const [selectedItem]: any = sourceitems.splice(index, 1);
      const newSelectedItem = { ...selectedItem, ...data };
      sourceitems.splice(index, 0, newSelectedItem);
      const newSourceItem = {
        ...currentValue,
        style: sourceitems,
      };
      return newSourceItem;
    });
  };

  return (
    <div className='outerDiv open'>
      <div className='MainTitle inFlex'>{mls('X-Axis')}</div>

      <div className='grayContainers'>
        <AxisStyles styles={chartToolTip?.style} updateStyle={updateStyle} axisData={xAxisData} />
      </div>
    </div>
  );
};

export default PivotTableXAxis;
interface axisStyleProps {
  axisData: any[];
  styles: any[];
  updateStyle: Function;
}
const AxisStyles = ({ styles, updateStyle, axisData }: axisStyleProps) => {
  return (
    <>
      {React.Children.toArray(
        styles.map((elem, index) => {
          if (elem.axis !== allDropableID.xAxisId) {
            return <></>;
          }
          const targetAxisData = axisData.find(
            (targetElem) => targetElem?.uniqueColumnName === elem?.uniqueColumnName
          );
          return (
            <Axis
              target={elem}
              updateStyle={updateStyle}
              index={index}
              targetAxisData={targetAxisData}
            />
          );
        })
      )}
    </>
  );
};
interface axisProps {
  target: any;
  show?: boolean;
  index: Number;
  updateStyle: Function;
  targetAxisData: any;
}
const Axis = ({
  // stateProps,
  target,
  show = false,
  updateStyle,
  index,
  targetAxisData,
}: // targetKey,
// automaticOpacity,
// setAutomaticOpacity,
axisProps) => {
  const handleLabel = (e: any) => {
    updateStyle({ label: e.target.value }, index);
  };

  const handleFont = (action: boolean) => {
    updateStyle(action, index);
  };

  const handleFormatter = (action: any) => {
    updateStyle({ formatter: action }, index);
  };

  return (
    <>
      <ClosableWhiteDiv
        title={target?.originalLabel}
        show={show}
        autoCloseOff={false}
        className={'legend'}
      >
        <input
          type='text'
          className='primaryInput'
          value={target.label}
          onChange={(e) => handleLabel(e)}
        />
        <FontSelector fontStyle={{ ...defautText, ...target }} setFontStyle={handleFont} />
        <DateTimeFormatterComp
          formatter={{ ...target?.formatter }}
          format={targetAxisData?.operations?.type}
          setFormatter={handleFormatter}
        />
      </ClosableWhiteDiv>
    </>
  );
};
