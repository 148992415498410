import SolidColorSelector from '../SolidColorSelector';
import {
  defaultChartSetting,
  defaultGridLineTypes,
  defaultGridLineWidth,
} from '../../../data/defaultChartSchema';
import ClosableWhiteDiv from '../../../features/ClosableWhiteDiv/ClosableWhiteDiv';
import SlideSwitch from '../../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';

const GridLinesDiv = ({ gridLines, setGridLines, show = false, title }) => {
  const handleToggle = (action) => {
    setGridLines({ ...gridLines, show: action });
  };
  const handleWidth = (e) => {
    const action = e;
    const value = Math.abs(action) >= 0 ? Math.abs(action) : null;
    if (action > 100) {
      return;
    } else {
      setGridLines({ ...gridLines, width: Math.abs(value) });
    }
  };
  const handleCount = (e) => {
    const action = e.target.value;
    const value = Math.abs(action) >= 0 ? Math.abs(action) : null;
    if (action > 100) {
      return;
    } else {
      setGridLines({ ...gridLines, count: Math.abs(value) });
    }
  };
  const handleGridLines = (gridLinesType) => {
    setGridLines({ ...gridLines, type: gridLinesType });
  };

  return (
    <ClosableWhiteDiv title={title} show={show} className='horizontalDiv' autoCloseOff={true}>
      <div className='inputDivContainer'>
        <div className='titleDiv inFlex'>
          Show
          <SlideSwitch isChecked={gridLines?.show} setIsChecked={handleToggle} />
        </div>
        <div className='gridLinesInnerDiv'>
          <div className='titleDiv'>Style:</div>
          <ArrayDropDown
            array={defaultGridLineTypes}
            dropDownValue={gridLines.type}
            setdropDownValue={handleGridLines}
          />
        </div>
        <div className='gridLinesInnerDiv'>
          <div className='titleDiv'>Width:</div>
          <ArrayDropDown
            array={defaultGridLineWidth}
            dropDownValue={gridLines.width}
            ObjectInArray={true}
            setdropDownValue={handleWidth}
          />
        </div>

        <SolidColorSelector color={gridLines} setColor={setGridLines} />
      </div>
    </ClosableWhiteDiv>
  );
};

export default GridLinesDiv;
