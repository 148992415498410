import React, { useEffect, useState } from 'react';
import arrowDropDownIcon from '../assets/arrowDropDownIcon.svg';
import arrowIcon from '../assets/arrowIcon.svg';
import DragableTable from '../features/DragableThings/DragableTable';
import { defaultDataTypeStyles } from '../data/defaultChartSchema';
import '../components/TableComponents/chartTable.scss';
import NestedDropDownObject from '../features/DropDownObject/NestedDropDownObject';
import { mls } from 'lib/multilanguagesupport';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
const DataSection = ({ allDropableID, stateProps, mainTableData, toggleProps }) => {
  const { toggleColumnDetails, minimizedDiv, toggleMinimizeDiv } = toggleProps;
  const { tableId } = allDropableID;
  const { handleSearch, tableFilter, handleTypeFilter } = stateProps;
  const { toggleKeyName, toggleKeyId } = toggleColumnDetails.firstColumn;

  const [isToggle, setIsToggle] = useState(true);
  const [isMainDivOn, setIsMainDivOn] = useState(true);

  useEffect(() => {
    columnToggleFunc({
      minimizedDiv: minimizedDiv[toggleKeyId],
      toggleKeyName: toggleKeyName,
      setIsMainDivOn: setIsMainDivOn,
      setIsToggle: setIsToggle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimizedDiv]);
  if (!isToggle) {
    return <></>;
  }
  return (
    <div className={isMainDivOn ? 'firstColumn ' : 'firstColumn close'}>
      <div className='staticDiv'>
        <div className='mainTitleDiv'>
          <div className='mainTitle'>{mls('Data Section')}</div>
          <img
            src={arrowIcon}
            alt=''
            className='arrowIcon'
            onClick={() => toggleMinimizeDiv(toggleKeyName, toggleKeyId)}
          />
        </div>
        <div className='serachBoxOuter'>
          <div className='searchBox'>
            <SearchInputDiv value={tableFilter.search} onChange={handleSearch} />
          </div>
          <NestedDropDownObject
            object={defaultDataTypeStyles}
            dropDownValue={tableFilter.type}
            setdropDownValue={handleTypeFilter}
            swithToIcon={true}
          />
        </div>
      </div>
      <div className='scrollableDiv'>
        <div className='tablesContainerDiv'>
          {React.Children.toArray(
            mainTableData.map((elem, i) => {
              return (
                <TableDiv
                  elem={elem}
                  tableId={tableId}
                  i={i}
                  stateProps={stateProps}
                  allDropableID={allDropableID}
                  mainTableData={elem}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

function TableDiv({ elem, tableId, i, stateProps, allDropableID, mainTableData }) {
  const [isDivOpen, setisDivOpen] = useState(true);
  return (
    <div className={isDivOpen ? 'tablesItems open' : 'tablesItems'}>
      <div className='tablesItemTitle' onClick={() => setisDivOpen(!isDivOpen)}>
        <div className='text'>{elem.name}</div>
        <img src={arrowDropDownIcon} alt='' />
      </div>
      <div className='tablesItemList'>
        <DragableTable
          dragableDivId={tableId}
          stateProps={stateProps}
          allDropableID={allDropableID}
          tableNumber={i}
          mainTableData={mainTableData}
        />
      </div>
    </div>
  );
}

export default DataSection;

function columnToggleFunc(props) {
  const { minimizedDiv, toggleKeyName, setIsMainDivOn, setIsToggle } = props;
  if (minimizedDiv === toggleKeyName) {
    setIsMainDivOn(false);
    setTimeout(() => {
      setIsToggle(false);
    }, 300);
  } else {
    setIsMainDivOn(true);
    setIsToggle(true);
    setTimeout(() => {
      setIsToggle(true);
    }, 300);
  }
}
