import { FaTimes } from 'react-icons/fa';
import EditTableList from './EditTableList';
// import TableCustomColumn from '../../components/tableCustomColumn/TableCustomColumn';
import { useEffect, useState } from 'react';
import TableCustomColumn from 'lib/data-connectors/dependencies/tableCustomColumn/SqlCustomCommands';
import { mls } from 'lib/multilanguagesupport';
import useUpdateCustomTableSchema from 'lib/data-connectors/dependencies/useUpdateCustomTableSchema';
import { AllCustomCommandType } from 'lib/data-connectors/dependencies/tableCustomColumn/data/sqlCustomCommandsData';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import DisplayTable from 'lib/reusable-components/DisplayTable/DisplayTable';
import {
  getSheetID,
  getConvertedTableData,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import { useSelector } from 'react-redux';
import CloseConfirmationDiv from 'lib/data-connectors/dependencies/CloseConfirmationDiv/CloseConfirmationDiv';

interface editTableProps {
  customTableMainProps: any;
}
const EditTable = ({ customTableMainProps }: editTableProps) => {
  const {
    appID,
    handleClose,
    appCustomDatatable,
    isAlreadyTable,
    setIsAlreadyTable,
    tableSchema,
    setTableSchema,
    appDatatable,
    isNew,
    setIsNew,
  } = customTableMainProps;
  const appSheets = useSelector(
    (state: any) => state?.config_store?.analyticsSchema?.appSheets || {}
  );
  const [tableHeadDisplayTableData, setTableHeadDisplayTableData] = useState({
    status: false,
    tableID: '',
  });
  const [allTableHeadData, setAllTableHeadData] = useState<{ [key: string]: any }>({});
  const [updateSqlCustomCommands, setUpdateSqlCustomCommands] = useState(1);
  const [tableHeadLoading, setTableHeadLoading] = useState(false);
  const handleTableHead = (action: boolean) => {
    setTableHeadDisplayTableData({ status: action, tableID: '' });
  };
  const getTableData = async ({ tableID }: { tableID: string }) => {
    if (allTableHeadData[tableID as keyof typeof allTableHeadData]) {
      return { skip: true };
    }
    const sheetData: {
      sheetID: string;
      skip: boolean;
      newCreatedSheetID?: boolean;
    } = await getSheetID({
      appSheets: appSheets,
      selectedTable: tableID,
      appID,
    });
    if (sheetData?.skip) {
      return { skip: true };
    }
    const tableDataProps = {
      appID,
      tableID: tableID,
      sheetID: sheetData.sheetID,
      disableCustomColumns: true,
    };

    const tableDataRes = await getConvertedTableData({
      appID: tableDataProps.appID,
      selectedTable: tableDataProps.tableID,
      sheetID: tableDataProps.sheetID,
      disableCustomColumns: tableDataProps.disableCustomColumns,
    });
    if (tableDataRes.skip) {
      return { skip: true };
    }
    return { tableData: tableDataRes.groupedTableData, skip: false };
  };
  const getAllTableData = async ({ tables = [] }: { tables: any[] }) => {
    setTableHeadLoading(true);
    let newAllTableData: { [key: string]: any } = {};
    for (let index = 0; index < tables.length; index++) {
      const tableID = tables[index];
      const tableDataRes = await getTableData({ tableID: tableID });

      if (!tableDataRes.skip) {
        if (index === tables.length - 1) {
          setAllTableHeadData((currentTableData) => ({
            ...currentTableData,
            ...newAllTableData,
            [tableID]: tableDataRes.tableData,
          }));
        } else {
          newAllTableData = { ...newAllTableData, [tableID]: tableDataRes.tableData };
        }
      }
    }
    setTableHeadLoading(false);
  };
  useEffect(() => {
    if (isAlreadyTable) {
      getAllTableData({ tables: tableSchema.tables });
    }
  }, []);
  const [isAnyChangesSchema, setIsAnyChangesSchema] = useState(false);
  const [isAnyChangesSqlCommands, setIsAnyChangesSqlCommands] = useState(false);
  const [isConfirmationDiv, setIsConfirmationDiv] = useState(false);

  const closeTable = () => {
    if (isAnyChangesSchema || isAnyChangesSqlCommands) {
      setIsConfirmationDiv(true);
    } else {
      handleClose();
    }
  };
  return (
    <div className='editTableOuterContainer'>
      <PopUpDivChild
        setPopUp={handleTableHead}
        initialValue={tableHeadDisplayTableData.status}
        className='tableDisplayPopup'
        popupTitle='Table Head'
      >
        <DisplayTable
          tableData={
            allTableHeadData[tableHeadDisplayTableData.tableID as keyof typeof allTableHeadData] ??
            []
          }
          tableLoading={tableHeadLoading}
        />
      </PopUpDivChild>
      {isConfirmationDiv && (
        <CloseConfirmationDiv
          isConfirmationDiv={isConfirmationDiv}
          setIsConfirmationDiv={setIsConfirmationDiv}
          handleClose={handleClose}
        />
      )}
      <div className='editTableHeader'>
        <div className='crossButton' onClick={() => closeTable()} data-bs-dismiss='modal'>
          <FaTimes />
        </div>
      </div>
      <div className='editTableInnerContainer'>
        <EditTableList
          appID={appID}
          closeModal={handleClose}
          appDatatable={appDatatable}
          appCustomDatatable={appCustomDatatable}
          isAlreadyTable={isAlreadyTable}
          setIsAlreadyTable={setIsAlreadyTable}
          tableSchema={tableSchema}
          useUpdateSchema={useUpdateCustomTableSchema}
          setTableSchema={setTableSchema}
          setTableHeadDisplayTableData={setTableHeadDisplayTableData}
          setAllTableHeadData={setAllTableHeadData}
          setTableHeadLoading={setTableHeadLoading}
          getTableData={getTableData}
          setUpdateSqlCustomCommands={setUpdateSqlCustomCommands}
          setIsAnyChanges={setIsAnyChangesSchema}
          isNew={isNew}
          setIsNew={setIsNew}
        />
        <TableCustomColumn
          appID={appID}
          tableData={tableSchema}
          appDatatable={appDatatable}
          tableSchema={tableSchema}
          setTableSchema={setTableSchema}
          useUpdateSchema={useUpdateCustomTableSchema}
          customCommandType={AllCustomCommandType.customTable}
          defaultSqlArea={true}
          displayOff={!isAlreadyTable}
          updateSqlCustomCommands={updateSqlCustomCommands}
          setIsAnyChangesSqlCommands={setIsAnyChangesSqlCommands}
          setIsAnyChangesSchema={setIsAnyChangesSchema}
          displayOffMsg={mls('Click on done to this create custom Table.')}
        />
      </div>
    </div>
  );
};
export default EditTable;
