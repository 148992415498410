import clsx from 'clsx';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { mls } from 'lib/multilanguagesupport';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { useLayout } from '../../core';
// import {MenuInner} from './MenuInner'

const capFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type Props = {
  appName: string;
};

const Header: FC<Props> = ({ appName }) => {
  const { classes } = useLayout();
  const { pathname } = useLocation();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const dashSchema = analyticsSchema?.appDash || {};
  const sheetSchema = analyticsSchema?.appSheets || {};
  const typeOfPage = pathname.split('/')[2];
  const id = pathname.split('/')[3];

  const title =
    sheetSchema?.[id]?.name || dashSchema?.[id]?.name || mls(capFirstLetter(typeOfPage));

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
        {mls(appName)} - {mls(title)}
      </h1>
    </div>
  );
};

export { Header };
