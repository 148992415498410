import React from 'react';
import Chart from '../components/Chart';
import './exportDivWrapper.scss';
const ExportDivWrapper = (props: any) => {
  return (
    <div className={`ExportDivWrapper ${props.className}`}>
      <Chart {...props} />
    </div>
  );
};

export default ExportDivWrapper;
