import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../_metronic/helpers';
import { toAbsoluteUrl } from 'lib/visx-lib/_metronic/helpers';

const StartTutorial = (startTutorial, isModalOpen, setModalOpen) => {
  return (
    <Modal show={isModalOpen} onHide={() => setModalOpen(false)} size='xl' centered>
      <div className='modal-content rounded'>
        <div className='modal-header justify-content-end border-0 pb-0'>
          {/* <div
        className='btn btn-sm btn-icon btn-active-color-primary'
        onClick={() => setModalOpen(false)}
        data-bs-dismiss='modal'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div> */}
        </div>

        <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
          <div className='mb-8 text-center'>
            <h1 className='mb-3'>Thank you for Choosing Supista</h1>
            <div className='fw-bold fs-5'>AI-Powered Self-Service Business Intelligence</div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-250px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
          <div className='d-flex flex-column text-center'>
            <div className='row mt-10'>
              <div
                className='flex-row-fluid py-lg-5 px-lg-15 fs-5 m-auto'
                style={{ maxWidth: '800px' }}
              >
                <div>
                  We're here to help! Contact us for any queries or{' '}
                  <a
                    href='https://calendly.com/durgeshsatyam/supista-strategic-session'
                    rel='noreferrer'
                    target='_blank'
                  >
                    schedule a meeting
                  </a>{' '}
                  to discuss your specific needs and how Supista can benefit your organization.
                </div>
                <div>We prioritize the security of your data and offer on-premise services.</div>
                <br />
                <div>Enjoy exploring your data and generating insights with Supista.</div>

                <button
                  type='submit'
                  className='btn btn-lg btn-primary me-3 mt-5'
                  onClick={() => setModalOpen(false)}
                >
                  <div className='indicator-label'>
                    {'Start using Supista'}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StartTutorial;
