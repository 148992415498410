import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import domainHelper from './domainHelper';

/**
 * This selects the correct scale based on the category of data
 *  Category is one of the following: CAT|NUM|GEO|DATETIME|PERCENTAGE
 *  @param category
 *  @return {Function} Scale to use based on the category of data
 */
const getScale = (category) => {
  switch (category) {
    case 'NUM':
      return scaleLinear;

    case 'DATETIME':
      return scaleTime;

    case 'PERCENTAGE':
      return scaleLinear;

    case 'CAT':
      return scaleBand;

    // For default considering categorical data
    default:
      return scaleBand;
  }
};

/**
 * This function returns the padding for the graph type
 * @param {string} type Type of chart
 * @return {number}
 */
export const getPadding = (type) => {
  switch (type) {
    case 'BAR':
      return 0.4;
    case 'BARHORIZONTAL':
      return 0.4;
    case 'MULTIAXISBAR':
      return 0.4;
    case 'LINE':
      return 0;

    case 'AREA':
      return 0;

    case 'FUNNEL':
      return 0;

    default:
      throw new Error('Invalid chart type');
  }
};

/**
 * This function provides Scale for the chart
 * @param {Array} data Data for plotting chart
 * @param {string} category Category for the type of data
 * @param {string} type Type of chart to be created
 * @param {Number} xMax Maximum width for xAxis, after deducting margins
 * @param {Number} yMax Maximum height for yAxis, after deducting margins
 * @param {boolean} xAxis True if for xAxis, and false for yAxis
 * @param {boolean} nice True if axis should be made nice
 * @return {Function} scale function for the type of data
 */
const scaleHelper = (data, category, type, xMax, yMax, xAxis = true, nice) => {
  // Get Function for generating scale based on category of data
  const scaleFunction = getScale(category);
  // Specifying range for x-axis and y-axis
  const range = xAxis ? [0, xMax] : [yMax, 0];
  // Getting domain for the scale
  const domain = domainHelper(category, type, xAxis, data);
  const padding = getPadding(type);
  return scaleFunction({
    range, // Shorthand for range: range
    domain,
    padding,
    nice,
  });
};

export default scaleHelper;
