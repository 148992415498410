import React from 'react';
import './sqlInstructionArea.scss';
import { copyToClipBoard, numberToChar } from 'lib/data-connectors/dependencies/utilFunctions';
import { MdContentCopy } from 'react-icons/md';
import { AllCustomCommandType } from '../data/sqlCustomCommandsData';
import { mls } from 'lib/multilanguagesupport';
interface sqlInstructionAreaProps {
  tableSchema: any;
  appDatatable: any;
  customCommandType: string;
  tableDataArray: { [key: string]: any }[];
}
const SqlInstructionArea = ({
  tableSchema,
  appDatatable,
  customCommandType,
  tableDataArray,
}: sqlInstructionAreaProps) => {
  const tableList = tableSchema?.tables ?? [tableSchema.name];

  return (
    <>
      <div className='sqlInstructionArea'>
        <div className='sqlInstructionTitle form-label'>{mls('SQL Instructions')}:</div>
        <div className='sqlInstructionlist'>
          <div className='sqlInstructionElem'>
            {mls('Use double quotes')}
            <br />( " ) {mls('for selecting columns')}.
          </div>
          <div className='sqlInstructionElem'>
            {mls('Use single quotes')} ( ' ) {mls('for values')}.
          </div>
          {customCommandType !== AllCustomCommandType.customTable ? (
            <>
              <div
                className='sqlInstructionElem sqlInstructionElemWithCopy'
                onClick={() => copyToClipBoard({ text: `__D3__DATA__` })}
              >
                {mls('Denote Table Data as ')}
                <b>__D3__DATA__</b>.
                <span className='sqlInstructionCopyToClipBoardIcon'>
                  <MdContentCopy />
                </span>
              </div>
              <div className='sqlInstructionElemContainer'>
                <div className='InstructionTitle'>{mls('Denote Column Name')}</div>
                <div className='InstructionTitle'>{mls('For Example')} :-</div>
                <div className='sqlInstructionElemInnerOptions'>
                  {/* {tableList?.map((col: any, index: number) => {
                    const Column1 =
                      appDatatable[col]?.columnSchema?.[0]?.name ??
                      appDatatable[tableSchema.tableID]?.columnSchema?.[0]?.name ??
                      'Column1';
                    return (
                      <div
                        className='sqlInstructionElem sqlInstructionElemWithCopy'
                        onClick={() =>
                          copyToClipBoard({
                            text: `__D3__TABLE__${numberToChar({ number: index })}`,
                          })
                        }
                      >
                        {mls('Denote')}{' '}
                        <b>
                          {appDatatable[col]?.name ?? col}.{Column1}
                        </b>{' '}
                        {mls('as')}{' '}
                        <b>
                          __D3__TABLE__{numberToChar({ number: index })}.{Column1}
                        </b>{' '}
                        . <br />
                        <span className='sqlInstructionCopyToClipBoardIcon'>
                          <MdContentCopy className='sqlInstructionCopyIcon' />
                        </span>
                      </div>
                    );
                  })} */}
                  {tableDataArray?.map((col: any, index: number) => {
                    const Column1 = col?.items?.[0]?.name ?? 'Column1';
                    return (
                      <div
                        className='sqlInstructionElem sqlInstructionElemWithCopy'
                        onClick={() =>
                          copyToClipBoard({
                            text: `__D3__TABLE__${numberToChar({ number: index })}`,
                          })
                        }
                      >
                        {mls('Denote')}{' '}
                        <b>
                          {col?.nameOfTable ?? col?.tableName}.{Column1}
                        </b>{' '}
                        {mls('as')}{' '}
                        <b>
                          __D3__TABLE__{numberToChar({ number: index })}.{Column1}
                        </b>{' '}
                        . <br />
                        <span className='sqlInstructionCopyToClipBoardIcon'>
                          <MdContentCopy className='sqlInstructionCopyIcon' />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='sqlInstructionElemContainer'>
                <div className='InstructionTitle'>{mls('Denote Table Name')}</div>

                <div className='sqlInstructionElemInnerOptions'>
                  {tableList?.map((col: any, index: number) => {
                    return (
                      <div
                        className='sqlInstructionElem sqlInstructionElemWithCopy'
                        onClick={() =>
                          copyToClipBoard({
                            text: `__D3__TABLE__${numberToChar({ number: index })}`,
                          })
                        }
                      >
                        {mls('Denote')} <b>{appDatatable[col]?.name ?? col}</b> {mls('as')}{' '}
                        <b>__D3__TABLE__{numberToChar({ number: index })}</b>
                        <span className='sqlInstructionCopyToClipBoardIcon'>
                          <MdContentCopy className='sqlInstructionCopyIcon' />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='sqlInstructionSaprator'></div>
    </>
  );
};

export default SqlInstructionArea;
