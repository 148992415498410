import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { mls } from 'lib/multilanguagesupport';

import ColumnChip from './ColumnChip';
import { TableCard } from './editTableList/TableListAssests';

const columnBoxStyle = {
  maxHeight: '25vh',
  overflow: 'auto',
};

const TableToJoin = ({
  index,
  masterFormik,
  appDatatable,
  deleteTableToJoin,
  isAlreadyTable,
  allTableIDAndNamesMap,
  setIsAnyChanges,
}) => {
  const [primaryKeyOptionsFromPreviousTablesJSX, setPrimaryKeyOptionsFromPreviousTablesJSX] =
    useState([]);
  const selectColumn = (col) => {
    const currentlySelectedColumns = [
      ...(masterFormik.values.joinTableSchema?.[index]?.columnSchema || []),
    ];
    currentlySelectedColumns.push(col);
    setIsAnyChanges(true);
    masterFormik.setFieldValue(`joinTableSchema.${index}.columnSchema`, currentlySelectedColumns);
  };
  const deselectColumn = (colToBeRemoved) => {
    const currentlySelectedColumns = [
      ...(masterFormik.values.joinTableSchema?.[index]?.columnSchema || []),
    ];
    const columnSchemaAfterRemovingColumn = currentlySelectedColumns.filter(
      (selectedCol) => selectedCol.columnName !== colToBeRemoved.columnName
    );
    masterFormik.setFieldValue(
      `joinTableSchema.${index}.columnSchema`,
      columnSchemaAfterRemovingColumn
    );
    setIsAnyChanges(true);
  };

  // Overwrite Formik field props onChange method
  //  Selects the column as the primary key + sets the table from where primary key is as primary table
  const handleSelectPrimaryKey = (e, selectedCol, selectedFromTable) => {
    masterFormik.setFieldValue(`joinTableSchema.${index}.primaryKey`, selectedCol);
    masterFormik.setFieldValue(`joinTableSchema.${index}.primaryTable`, selectedFromTable);
    setIsAnyChanges(true);
  };

  // Overwrite Formik field props onChange method
  // Selected the table + select all the columns of it + clear other fields which were wrt previously selected table
  const handleTableChange = (e) => {
    const changedTableNameWithClearOtherData = {
      joinTableName: e.target.value,
      // Select all columns of the selected table by default
      columnSchema: [...(appDatatable?.[e.target.value]?.columnSchema || [])],
      primaryKey: '',
      primaryTable: '',
      secondaryKey: '',
    };
    masterFormik.setFieldValue(`joinTableSchema.${index}`, changedTableNameWithClearOtherData);
    setIsAnyChanges(true);
  };

  useEffect(() => {
    const tableOptionGroups = [];

    // Adding selected columns of primary table in options
    if (masterFormik.values.columnSchema?.length > 0) {
      const optionJSX = (
        <optgroup
          label={
            allTableIDAndNamesMap[masterFormik.values.primaryTable] ||
            masterFormik.values.primaryTable
          }
        >
          {masterFormik.values.columnSchema?.map((primaryTableCol, colIndex) => {
            return (
              <option
                value={primaryTableCol.columnName}
                // Custom prop to hold the table name of the column
                data-from-table={masterFormik.values.primaryTable}
                key={`${masterFormik.values.primaryTable}-${colIndex}`}
              >
                {primaryTableCol.name}
              </option>
            );
          })}
        </optgroup>
      );
      tableOptionGroups.push(optionJSX);
    }

    // Iterate upto before the current table to build the JSX of options list
    for (let i = 0; i < index; i++) {
      const joinedTable = masterFormik.values.joinTableSchema[i];
      if (joinedTable.columnSchema?.length > 0) {
        // Options JSX for one table
        const optionJSX = (
          <optgroup
            label={allTableIDAndNamesMap[joinedTable.joinTableName] || joinedTable.joinTableName}
          >
            {joinedTable.columnSchema.map((joinedTableCol, colIndex) => {
              return (
                <option
                  value={joinedTableCol.columnName}
                  // Custom prop to hold the table name of the column
                  data-from-table={joinedTable.joinTableName}
                  key={`${joinedTable.joinTableName}-${colIndex}`}
                >
                  {joinedTableCol.name}
                </option>
              );
            })}
          </optgroup>
        );
        tableOptionGroups.push(optionJSX);
      }
    }
    setPrimaryKeyOptionsFromPreviousTablesJSX(tableOptionGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterFormik.values, index]);

  const currentTable = masterFormik.values.joinTableSchema[index].joinTableName;
  // Select all columns of the table by default
  useEffect(() => {
    if (isAlreadyTable) {
      return;
    }
    if (masterFormik.values.joinTableSchema?.[index]?.joinTableName) {
      const allColumnsSelectedColumnSchema =
        appDatatable?.[masterFormik.values?.joinTableSchema?.[index]?.joinTableName]?.columnSchema;
      masterFormik.setFieldValue(
        `joinTableSchema.${index}.columnSchema`,
        allColumnsSelectedColumnSchema
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDatatable, currentTable, index]);
  return (
    <>
      <TableCard
        columnSchema={
          appDatatable?.[masterFormik.values.joinTableSchema?.[index]?.joinTableName]
            ?.columnSchema || []
        }
        tableIndex={index + 1}
        // subTitle={mls('Table To Join')}
        mainTitle={mls('Join Table') + ' ' + (index + 1)}
        formik={masterFormik}
        handleTableChange={handleTableChange}
        appDatatable={appDatatable}
        selectColumn={selectColumn}
        deselectColumn={deselectColumn}
        formikKey={`joinTableSchema.${index}.joinTableName`}
        selectionSchema={masterFormik.values.joinTableSchema?.[index]?.columnSchema ?? []}
      >
        {/* 'Primary Key' field */}
        <label className='form-label mt-5'>{mls('Primary Key')}</label>
        <select
          {...masterFormik.getFieldProps(`joinTableSchema.${index}.primaryKey`)}
          className={clsx('form-select form-control form-control-solid w-100')}
          // Disabled until table is selected
          disabled={masterFormik.values.joinTableSchema?.[index]?.joinTableName === ''}
          // 'data-from-table' is the custom prop to hold the table name of the column. It is stored in camel case
          onChange={(e) =>
            handleSelectPrimaryKey(e, e.target.value, e.target.selectedOptions[0].dataset.fromTable)
          }
        >
          <option value='' hidden>
            {mls('Select the primary key')}
          </option>
          {/* .flat() is used to convert [[<options JSX 1>], [<options JSX 2>]] to [<options JSX 1>, <options JSX 2>]*/}
          {primaryKeyOptionsFromPreviousTablesJSX.flat()}
        </select>
        {masterFormik.touched.joinTableSchema?.[index]?.primaryKey &&
          masterFormik.errors.joinTableSchema?.[index]?.primaryKey && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {mls(masterFormik.errors.joinTableSchema?.[index]?.primaryKey)}
                </span>
              </div>
            </div>
          )}

        {/* 'Secondary Key' field */}
        <label className='form-label mt-5'>{mls('Secondary Key')}</label>
        <select
          {...masterFormik.getFieldProps(`joinTableSchema.${index}.secondaryKey`)}
          className={clsx('form-select form-control form-control-solid w-100')}
          // Disabled until table is selected
          disabled={masterFormik.values.joinTableSchema?.[index]?.joinTableName === ''}
        >
          <option value='' hidden selected>
            {mls('Select the secondary key')}
          </option>
          {masterFormik.values.joinTableSchema?.[index]?.columnSchema?.map((col) => {
            return <option value={col?.columnName}>{col?.name}</option>;
          })}
        </select>
        {masterFormik.touched.joinTableSchema?.[index]?.secondaryKey &&
          masterFormik.errors.joinTableSchema?.[index]?.secondaryKey && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-danger' role='alert'>
                  {mls(masterFormik.errors.joinTableSchema?.[index]?.secondaryKey)}
                </span>
              </div>
            </div>
          )}

        {/* Delete button */}
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-sm btn-hover-danger m-0 mt-3'
            onClick={deleteTableToJoin.bind(null, index)}
          >
            {mls('Remove Join Table')} {index + 1}
            {/* <i class='fa fa-trash'></i> */}
          </button>
        </div>
      </TableCard>
    </>
  );
};

export default TableToJoin;
