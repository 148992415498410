import { useDispatch } from 'react-redux';
import reduxConstants from '../redux/reduxConstants';
import { create } from '../redux/stateSlice';

export default function useChartSettingsButton() {
  const dispatch = useDispatch();
  return (chartID) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.APP_STATE}.${reduxConstants.config.CHART_SETTINGS}`,
        value: { chartID },
      })
    );
    return null;
  };
}

export function useDashSettingsButton() {
  const dispatch = useDispatch();
  return (dashID) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.APP_STATE}.${reduxConstants.config.DASH_SETTINGS}`,
        value: { dashID },
      })
    );
    return null;
  };
}

export function useReportSettingsButton() {
  const dispatch = useDispatch();
  return (reportID) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.APP_STATE}.${reduxConstants.config.REPORT_SETTINGS}`,
        value: { reportID },
      })
    );
    return null;
  };
}

export function useKpiSettingsButton() {
  const dispatch = useDispatch();
  return (kpiChartID) => {
    dispatch(
      create({
        setPath: `${reduxConstants.config.APP_STATE}.${reduxConstants.config.KPI_SETTINGS}`,
        value: { kpiChartID },
      })
    );
    return null;
  };
}
