export const getStyling = (styling) => {
  if (!styling) {
    return {
      color: "black",
      fill: "black",
      fontWeight: "normal",
      fontStyle: "normal",
      textDecoration: "none",
      fontFamily: "sans-serif",
      fontSize: 12,
      opacity: 1,
    };
  }

  return {
    color: styling.color,
    fill: styling.color,
    fontWeight: styling.textStyle.includes("bold") ? "bold" : "normal",
    fontStyle: styling.textStyle.includes("italic") ? "italic" : "normal",
    textDecoration: styling.textStyle.includes("underlined") ? "underline" : "none",
    fontFamily: styling.font || "sans-serif",
    fontSize: styling.fontHeight,
    opacity: styling.opacity / 100 || 1,
    display: styling?.show===false ? "none" : "block",
  }
}