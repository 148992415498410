import ReportSettingsWrapper from 'lib/reports-analytics/ReportSettingsWrapper';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';

import { useDispatch } from 'react-redux';

import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { mls } from 'lib/multilanguagesupport';
import { useReportSettingsButton } from 'lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';

const ReportSettings = ({ appID, reportID, isNew, reportSchema, reportsSchema }) => {
  const reportSettingsButton = useReportSettingsButton();
  const updateSchema = useUpdateSchema();
  const dispatch = useDispatch();

  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const handleClose = () => {
    reportSettingsButton(false);
  };
  const location = getSchemaLocation(schemaConstants.type.REPORT);

  const handleSave = (reportSchema, _id) => {
    const reportID = _id || reportSchema._id;
    updateSchema(location, reportID, reportSchema);
    dispatch(
      create({
        setPath: reduxConstants.config.APP_STATUS.TOAST,
        value: {
          message: `${mls(`${'Updated Report '}successfully!`)}`,
          time: 5,
          isActive: true,
        },
      })
    );
    reportSettingsButton(false);
  };
  const handleDelete = (reportID) => {
    const prevReportsSchema = { ...(analyticsSchema?.appReports || {}) };
    const isDeleted = delete prevReportsSchema[reportID];
    if (isDeleted) {
      updateSchema(null, location, prevReportsSchema);
      dispatch(
        create({
          setPath: reduxConstants.config.APP_STATUS.TOAST,
          value: {
            message: `${mls('Deleted report successfully!')}`,
            time: 5,
            isActive: true,
          },
        })
      );
    }
    reportSettingsButton(false);
  };

  return (
    <>
      <ReportSettingsWrapper
        appID={appID}
        reportID={reportID}
        isNewChart={isNew}
        reportSchema={reportSchema}
        analyticsSchema={analyticsSchema}
        handleReportSettingsPopUp={handleClose}
        handleSave={handleSave}
        handleDelete={handleDelete}
        sendToOptions={reportSchema?.sendTo || {}}
      />
    </>
  );
};

export default ReportSettings;
