import React from 'react';
import SlideSwitch from '../../../reusable-components/Components/SlideSwitch/SlideSwitch';
import HorizontalDiv from './GridLines/GridLinesDiv';
// import VerticalDiv from './VerticalDiv';
import { defaultChartSetting } from '../../data/defaultChartSchema';

import './chartStyle.scss';
import EditColors from './ChartDisplay/EditColors';
import XAxisStyle from './XAxisStyle';
import YAxisStyle from './YAxisStyle';
import AxisStyle from './AxisStyle';
import PlotAreaBackground from './PlotAreaBackground';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import GridLines from './GridLines/GridLines';
import PlotColors from '../SpacialCasesComponents/PlotChart/PlotColors';
import { mls } from 'lib/multilanguagesupport';
const ChartStyle = ({ stateProps }) => {
  const {
    isZoomBrush,
    setIsZoomBrush,
    chartXAxisStyle,
    setChartXAxisStyle,
    chartYAxisStyle,
    setChartYAxisStyle,
    chartType,
    xAxisData,
    yAxisData,
    chartID,
    chartToolTip,
    setChartToolTip,
    doubleAxisMemo,
    setDoubleAxis
  } = stateProps;
  const handleY0AxisStyle = (action) => {
    if (typeof action === "function") {
      setDoubleAxis(currentData => {
        return { ...currentData, y0AxisStyle: action(currentData?.y0AxisStyle) }
      })
    } else {
      setDoubleAxis(currentData => {
        return { ...currentData, y0AxisStyle: action }
      })
    }
  }
  const handleY1AxisStyle = (action) => {
    if (typeof action === "function") {
      setDoubleAxis(currentData => {
        return { ...currentData, y1AxisStyle: action(currentData?.y1AxisStyle) }
      })
    } else {
      setDoubleAxis(currentData => {
        return { ...currentData, y1AxisStyle: action }
      })
    }
  }
  return (
    <>
      <XAxisStyle
        axisStyle={chartXAxisStyle}
        setAxisStyle={setChartXAxisStyle}
        axisData={xAxisData}
        defaultLable={defaultChartSetting.xAxisStyle.label}
        chartID={chartID}
        chartType={chartType}
      />
      {doubleAxisMemo?.doubleAxisData?.isDoubleAxis ?
        <>
          <AxisStyle
            title={mls("Y-Axis (Left)")}
            axisStyle={doubleAxisMemo?.doubleAxisData?.y0AxisStyle}
            setAxisStyle={handleY0AxisStyle}
            axisData={[yAxisData[0] ?? []]}
            defaultLable={defaultChartSetting.doubleAxis.y0AxisStyle.label}
            chartID={chartID}
            chartType={chartType}
            defaultStyleData={defaultChartSetting.doubleAxis.y0AxisStyle}
          />
          <AxisStyle
            title={mls("Y-Axis (Right)")}
            axisStyle={doubleAxisMemo?.doubleAxisData?.y1AxisStyle}
            setAxisStyle={handleY1AxisStyle}
            axisData={[yAxisData[1] ?? []]}
            defaultLable={defaultChartSetting.doubleAxis.y0AxisStyle.label}
            chartID={chartID}
            chartType={chartType}
            defaultStyleData={defaultChartSetting.doubleAxis.y1AxisStyle}
          />
        </>
        :
        <AxisStyle
          title={mls("Y-Axis")}
          axisStyle={chartYAxisStyle}
          setAxisStyle={setChartYAxisStyle}
          axisData={yAxisData}
          defaultLable={defaultChartSetting.yAxisStyle.label}
          chartID={chartID}
          chartType={chartType}
          defaultStyleData={defaultChartSetting.yAxisStyle}
        />
      }

      {/* <YAxisStyle
        axisStyle={chartYAxisStyle}
        setAxisStyle={setChartYAxisStyle}
        axisData={yAxisData}
        defaultLable={defaultChartSetting.yAxisStyle.label}
        chartID={chartID}
        chartType={chartType}
      /> */}
      {
        chartType.chartKey === allChartKeys.BUBBLEPLOT ||
          chartType.chartKey === allChartKeys.SCATTERPLOT ? (
          <PlotColors chartToolTip={chartToolTip} setChartToolTip={setChartToolTip} yAxisData={yAxisData} />
        ) : <EditColors stateProps={stateProps} />}

      {chartType.chartKey !== allChartKeys.RADAR &&
        chartType.chartKey !== allChartKeys.RADIALLINE ? (
        <GridLines stateProps={stateProps} />
      ) : null}

      {chartType.chartKey !== allChartKeys.RADAR &&
        chartType.chartKey !== allChartKeys.RADIALLINE &&
        chartType.chartKey !== allChartKeys.BUBBLEPLOT &&
        chartType.chartKey !== allChartKeys.GROUPBUBBLEPLOT &&
        chartType.chartKey !== allChartKeys.SCATTERPLOT &&
        chartType.chartKey !== allChartKeys.GROUPSCATTERPLOT ? (
        <div className='outerDiv'>
          <div className='MainTitle inFlex'>
            {mls("Zoom Brush")}
            <SlideSwitch isChecked={isZoomBrush} setIsChecked={setIsZoomBrush} />
          </div>
        </div>
      ) : null}
      {/* <div className='outerDiv '>
        <div className='MainTitle inFlex'>
          Date hierarchy
          <SlideSwitch isChecked={isDateHierarchy} setIsChecked={setIsDateHierarchy} />
        </div>
      </div> */}
      {/* <div className='outerDiv open'>
        <div className='MainTitle'>Plot area background</div>
        <PlotAreaBackground
          plotAreaBackground={plotAreaBackground}
          setPlotAreaBackground={setPlotAreaBackground}
        />
      </div> */}
    </>
  );
};

export default ChartStyle;
