import React, { useEffect, useMemo, useRef, useState } from 'react';
import './uploadConnection.scss';
import { toast } from 'react-toastify';
import { mls } from 'lib/multilanguagesupport';
import { CircularProgress } from '@mui/material';
import EditFileContent from './EditFileContent';
import {
  defaultConnectionSchema,
  uploadFileTableConfigFields,
} from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import GetFields from '../../GetFileds/GetFileds';
import { getfileExtensionData } from '../../GetFileds/filedsUtils';
import { v4 as uuidv4 } from 'uuid';
import { _uploadFileToServer } from 'lib/server-connection/connections';

interface uploadConnectionProps {
  connectionType: any;
  appID: string;
  formData: any;
  selectedConnection: any;
  setFormData: Function;
  isNewConnection: boolean;
  editConnectionMode: boolean;
  setIsEditConnectionMode: Function;
  tableData: any;
  setTableData: Function;
  tableConfig: any;
  setTableConfig: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  saveConnection: Function;
  credentialID: string;
}
const uploadFile = {
  name: 'file1',
  label: 'Upload Files',
  required: true,
  defaultValue: {},
  // type: connectionFieldsType.dateTimeColumn,
};
const UploadConnection = ({
  connectionType,
  appID,
  formData,
  selectedConnection,
  setFormData,
  isNewConnection,
  editConnectionMode,
  setIsEditConnectionMode,
  tableData,
  setTableData,
  tableConfig,
  setTableConfig,
  fileToUploadObject,
  setFileToUploadObject,
  saveConnection,
  credentialID,
}: uploadConnectionProps) => {
  const acceptedExtensionWithDot = useRef(
    Object.values(connectionType.accept)
      .map((extendtionElem: any) => {
        return `.${extendtionElem.extension}`;
      })
      .join(', ')
  );
  const [isFileDropping, setIsFileDropping] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleDragIn = () => {
    setIsFileDropping(true);
  };
  const handleDragOut = () => {
    setIsFileDropping(false);
  };
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleFileDrop = async (e: any) => {
    setSaveLoading(true);
    setIsFileDropping(false);
    let files = e.target.files;
    if (!files || !files[0]) return setSaveLoading(false);
    const finalFile = files[0];

    let sFileName = finalFile?.name ?? '';

    let sFileExtension = sFileName.split('.')[1].toLowerCase();

    const isValidFile = Object.values(connectionType.accept).some((extendtionElem: any) => {
      return extendtionElem.extension.toLowerCase() === sFileExtension;
    });

    if (!isValidFile || finalFile?.size > connectionType.maxSize) {
      toast.error(mls('This is not a valid File'));
      await sleep(500);
      setSaveLoading(false);
      return;
    }
    setFileToUploadObject((currentFiles: { [key: string]: any }) => {
      return { ...currentFiles, [uploadFile.name]: finalFile };
    });
    // setUploadedFile(finalFile);
    setSaveLoading(false);
    const credentialID = formData?.credentialID ?? uuidv4();
    // sFileName;
    setFormData((currentFormData: any) => ({
      ...currentFormData,
      connectionName: currentFormData.connectionName || sFileName,
      credentialID,
      data: {
        ...(currentFormData.data ?? {}),
        fileName: `${credentialID}.${sFileExtension}`,
        fileLable: sFileName,
        folderName: appID,
      },
    }));
    // uploadFileForFirstTime({ file: finalFile });
  };
  const fileExtensionData: any = useMemo(() => {
    return getfileExtensionData({
      fileName: formData?.data?.fileLable ?? '',
      extensionObject: connectionType.accept,
    });
  }, [formData?.data?.fileLable, connectionType]);

  const handleFormData = (action: any) => {
    const updatedFormData = {
      ...(formData ?? {}),
      [action.key]: action.value,
    };
    setFormData(updatedFormData);
  };
  const handleSave = async (e: any) => {
    e.preventDefault();
    if (fileToUploadObject[uploadFile.name]?.name) {
      setSaveLoading(true);
      const saveConnectionRes = await saveConnection({ connectionType });
      if (saveConnectionRes.skip) {
        setSaveLoading(false);
      } else {
        setSaveLoading(false);
      }
    } else {
      toast.error(mls('Please select any File'));
    }
  };

  if (!isNewConnection) {
    return (
      <>
        <EditFileContent
          appID={appID}
          tableData={tableData}
          setTableData={setTableData}
          formData={formData}
          setFormData={setFormData}
          connectionType={connectionType}
          editConnectionMode={editConnectionMode}
          setIsEditConnectionMode={setIsEditConnectionMode}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          saveConnection={saveConnection}
          fileToUploadObject={fileToUploadObject}
          setFileToUploadObject={setFileToUploadObject}
          credentialID={credentialID}
        />
      </>
    );
  }
  return (
    <div className='uploadConnection'>
      <div className='updloadConnectionheader'>
        <div className='updloadConnectionTitle'>{mls('Upload your files for Analysis')}</div>
        {/* <div className='updloadConnectionDescription'>
          Accept Files: ( {acceptedExtensionWithDot.current} )
        </div> */}
      </div>
      <div className='editFileContentLoaded'>
        <div className='editConnectionFormContainer noHeading'>
          <form onSubmit={(e) => handleSave(e)} className='editConnectionForm'>
            <div className='fieldAreaOuterContainer'>
              {React.Children.toArray(
                defaultConnectionSchema.map((fieldsElem: any) => (
                  <GetFields
                    fieldsElem={fieldsElem}
                    formData={formData}
                    setFormData={handleFormData}
                    fileToUploadObject={fileToUploadObject}
                    setFileToUploadObject={setFileToUploadObject}
                    credentialID={credentialID}
                  />
                ))
              )}
              <div className='uploadInputOuterContainer'>
                <div
                  className={`uploadInputInnerContainer ${isFileDropping ? 'fileDropping' : ''}`}
                >
                  {saveLoading ? (
                    <div className='inputFileLoading'>
                      <CircularProgress />
                    </div>
                  ) : (
                    <input
                      type='file'
                      name='mainUploadInputFile'
                      className='mainUploadInputFile'
                      onDragEnter={() => handleDragIn()}
                      onDragLeave={() => handleDragOut()}
                      onMouseLeave={() => handleDragOut()}
                      onChange={(e) => handleFileDrop(e)}
                      accept={acceptedExtensionWithDot.current}
                    />
                  )}
                  {fileToUploadObject[uploadFile.name]?.name ? (
                    <>
                      <div className='updloadConnectionTitle'>
                        <div className='fileExtenstionIconImgDiv'>
                          <img
                            src={fileExtensionData?.icon}
                            alt=''
                            className='fileExtenstionIconImg'
                          />
                        </div>
                      </div>
                      <div className='updloadConnectionDescription'>
                        {mls('File name')}:<b>{fileToUploadObject[uploadFile.name]?.name}</b>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='updloadConnectionTitle'>{mls('Upload Files')}</div>
                      <div className='updloadConnectionDescription'>
                        {mls('Click here to Upload/Drag & Drop')}
                      </div>
                      <div className='updloadConnectionDescription'>
                        {mls('Accept Files')}: {acceptedExtensionWithDot.current}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='editConnectionFormButtonsContainer'>
              <button
                className='primaryButton primaryButtonFlexDiv'
                style={{ gap: '.5rem' }}
                disabled={saveLoading}
              >
                {mls('Save')}
                {saveLoading ? (
                  <>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress style={{ width: '1rem', height: '1rem' }} color='inherit' />
                    </span>
                  </>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadConnection;
