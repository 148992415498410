/**
 * This method helps in determining the domain for a scale
 * @param {string} category Category of the Data
 * @param {string} type Type of chart
 * @param {boolean} xAxis True if this is for xAxis and false for yAxis
 * @param {array} data Data for which domain is to be calculated
 */
const domainHelper = (category, type, xAxis, data) => {
  const isHorizontal = (type === 'BARHORIZONTAL') || (type === 'FUNNEL');
  switch (category) {
    case 'NUM':
    case 'DATETIME':
      // If for xAxis and chart is horizontal
      if (xAxis && !isHorizontal) {
        return [Math.min(...data), Math.max(...data)];
      }
      // If for yAxis
      return [0, Math.max(...data)];

    // For categorical Data
    case 'CAT':
      return data;

    // For default considering categorical data
    default:
      return data;
  }
};

export default domainHelper;
