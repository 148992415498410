import React from 'react';
import ChartStyle from '../../components/StyleComponents/ChartStyle';
import ChartBuild from '../../components/BuildComponents/ChartBuild';

const BarStackLineChart = ({ stateProps, buddleProps, toggleProps }) => {
  const { settingToggleState } = buddleProps;

  return (
    <>
      {settingToggleState === 'build' && <ChartBuild stateProps={stateProps} />}
      {settingToggleState === 'style' && <ChartStyle stateProps={stateProps} />}
    </>
  );
};

export default BarStackLineChart;
