import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  allDropableID,
  defaultNestedArrayDraggableID,
  defaultWidthTypesArray,
} from '../../data/defaultSchema';
import { splitedCharts } from '../../utils/helperFunction';
import x from '../../assets/x.svg';
import boxDropDownIcon from '../../assets/boxDropDownIcon.svg';
import DropDown from '../../features/DropDown/DropDown';
import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { mls } from 'lib/multilanguagesupport';

const { dashboardSheetsId } = allDropableID;
const DroppableDashboardSection = ({ sectionElem, index, stateProps }: InnerSectionProps) => {
  const droppableID = `${dashboardSheetsId}-<${
    defaultNestedArrayDraggableID.first
  }>${index.toString()}</${defaultNestedArrayDraggableID.first}>`;

  const newSplitedCharts = splitedCharts(sectionElem.charts);
  const newSplitedChartdroppableID = `${droppableID}-<${
    defaultNestedArrayDraggableID.second
  }>${newSplitedCharts.length.toString()}</${defaultNestedArrayDraggableID.second}>`;
  //  const newSplitedChartdroppableID = `${droppableID}-<${
  //    defaultNestedArrayDraggableID.second
  //  }>${splitedChartsIndex.toString()}</${defaultNestedArrayDraggableID.second}>`;

  const draggableId = `${newSplitedChartdroppableID.toString()}-${sectionElem.charts.length.toString()}`;

  return (
    <div className='dashboardDraggableSectionMainContainer'>
      {sectionElem.charts.length === 0 ? (
        <div className='blankSection'>
          <div className='blankSectionInner'>
            {mls('Drag And Drop')}
            <br /> {mls('Charts From Sheet')}
            <br /> {mls('Lists')}
          </div>
        </div>
      ) : null}
      {React.Children.toArray(
        newSplitedCharts.map((splitedChartsArray: any[], splitedChartsIndex: number) => {
          const newSplitedChartdroppableID = `${droppableID}-<${
            defaultNestedArrayDraggableID.second
          }>${splitedChartsIndex.toString()}</${defaultNestedArrayDraggableID.second}>`;
          return (
            <Droppable
              droppableId={newSplitedChartdroppableID}
              key={newSplitedChartdroppableID}
              direction='horizontal'
            >
              {(provided, snapshot) => (
                <div
                  className='dashboardDraggableSectionContainer'
                  ref={provided.innerRef}
                  style={getListWrapperStyle(snapshot.isDraggingOver)}
                  //   style={getListStyle(snapshot.isDraggingOver)}
                >
                  {React.Children.toArray(
                    splitedChartsArray.map((sectionChartElem, sectionChartIndex) => {
                      const draggableId = `${newSplitedChartdroppableID.toString()}-${sectionChartElem.dataIndex.toString()}`;
                      return (
                        <>
                          <Draggable
                            key={`${sectionChartIndex}-${sectionChartElem.dataIndex}`}
                            draggableId={draggableId}
                            index={sectionChartElem.dataIndex}
                            // isDragDisabled={false}
                          >
                            {(provided, snapshot) => (
                              <>
                                <SectionCharts
                                  provided={provided}
                                  snapshot={snapshot}
                                  sectionChartElem={sectionChartElem}
                                  sectionIndex={index}
                                  chartIndex={sectionChartElem.dataIndex}
                                  stateProps={stateProps}
                                />
                              </>
                            )}
                          </Draggable>
                        </>
                      );
                    })
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })
      )}
      {sectionElem.charts.length !== 0 ? (
        <Droppable
          droppableId={newSplitedChartdroppableID}
          key={droppableID}
          direction='horizontal'
        >
          {(provided, snapshot) => (
            <div
              className='dashboardDraggableSectionContainer addChartInBottom'
              ref={provided.innerRef}
              style={getListWrapperStyle(snapshot.isDraggingOver)}
              //   style={getListStyle(snapshot.isDraggingOver)}
            >
              <Draggable
                key={`${newSplitedCharts.length}-${sectionElem.charts.length}`}
                draggableId={draggableId}
                index={sectionElem.charts.length}
                isDragDisabled={true}
              >
                {(provided, snapshot) => (
                  <>
                    <div
                      // className={`sectionCharts ${'width'} removeWhenProduction`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      //   style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      {...provided.dragHandleProps}
                    ></div>
                  </>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : null}
    </div>
  );
};

export default DroppableDashboardSection;

const SectionCharts = ({
  sectionChartElem,
  provided,
  stateProps,
  sectionIndex,
  chartIndex,
}: sectionChartsPorps) => {
  const { width, id } = sectionChartElem?.data;
  const { chartsData, setSections } = stateProps;
  const removeCharts = () => {
    setSections((currentData: any[]) => {
      const duplicateSelectedSection: any[] = Array.from(currentData);
      const duplicateSelectedChart: any[] = Array.from(currentData[sectionIndex].charts);

      // remove Targeted
      const [removedDuplicateSelectedSection] = duplicateSelectedSection.splice(sectionIndex, 1);
      duplicateSelectedChart.splice(chartIndex, 1);

      const newItem = {
        ...removedDuplicateSelectedSection,
        charts: duplicateSelectedChart,
      };
      // update Targeted ChartsArray
      duplicateSelectedSection.splice(sectionIndex, 0, newItem);

      return duplicateSelectedSection;
    });
  };

  const handleChartWidth = (e: any) => {
    setSections((currentData: any[]) => {
      const duplicateSelectedSection: any[] = Array.from(currentData);
      const duplicateSelectedChart: any[] = Array.from(currentData[sectionIndex].charts);

      // remove Targeted
      const [removedDuplicateSelectedSection] = duplicateSelectedSection.splice(sectionIndex, 1);
      const [targetedSelectedChart] = duplicateSelectedChart.splice(chartIndex, 1);

      const newTargetSelectedChart = {
        ...targetedSelectedChart,
        width: e.key,
      };
      duplicateSelectedChart.splice(chartIndex, 0, newTargetSelectedChart);

      const newItem = {
        ...removedDuplicateSelectedSection,
        charts: duplicateSelectedChart,
      };
      // update Targeted ChartsArray
      duplicateSelectedSection.splice(sectionIndex, 0, newItem);

      return duplicateSelectedSection;
    });
  };
  const chartTypeData = allChartTypesObj[chartsData[id]?.chartType] ?? {};
  return (
    <div
      className={`sectionCharts ${width} removeWhenProduction`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      //   style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      {...provided.dragHandleProps}
    >
      <div className='removeSectionCharts secondaryColor' onClick={() => removeCharts()}>
        <img src={x} alt='' />
      </div>
      <div className='selectChartWidth'>
        <DropDown
          array={defaultWidthTypesArray}
          dropDownValue={{ key: width }}
          setdropDownValue={handleChartWidth}
          isChildernInsert={true}
          className={'chartWidthDropDown'}
        >
          <img src={boxDropDownIcon} alt='' />
        </DropDown>
      </div>
      <div className='ChartType'>
        <img src={chartTypeData?.icon} alt='' className='chartIcon' />
        <div className='chartTypeName'>{mls(chartTypeData?.name)}</div>
      </div>
      <div className='chartName'>{mls(chartsData[id]?.name)}</div>
    </div>
  );
};

const getListWrapperStyle = (isDraggingOver: any, isOpen?: any) => ({
  background: isDraggingOver ? 'lightblue' : '',
  borderRadius: '6px',
  Visibility: isOpen | isDraggingOver ? 'visible' : 'hidden',
  OverflowX: 'hidden',
});

interface InnerSectionProps {
  stateProps: any;
  sectionElem: {
    title: string;
    charts: any[];
    headings: {
      text: string;
      color: string;
      font: string;
      fontHeight: string;
      opacity: string;
      textStyle: string[];
    }[];
  };
  index: number;
}
interface sectionChartsPorps {
  sectionChartElem: any;
  stateProps: any;
  snapshot: any;
  provided: any;
  sectionIndex: number;
  chartIndex: number;
}
