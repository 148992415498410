import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';

function TableChart({
  chartData,
  dataToPlot: plotData,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  cardRef,
  chartHeight,
}) {
  const { width } = useDivDimensions(cardRef);
  const dataToPlot = useMemo(() => plotData?.plotData || [], [plotData]);
  const columns = useMemo(() => {
    const cols = [{ field: 'id', headerName: 'ID', width: 70 }];
    [...chartData.xAxis, ...chartData.yAxis].forEach((columnSchema) => {
      cols.push({
        field: columnSchema.uniqueColumnName || columnSchema.column,
        headerName: columnSchema.label,
        width: (width - 150) / [...chartData.xAxis, ...chartData.yAxis].length || 100,
        color: 'red',
      });
    });
    return cols;
  }, [chartData, width]);

  const rows = useMemo(() => {
    const rs = [];
    const cols = [...chartData.xAxis, ...chartData.yAxis].map((columnSchema) => {
      return columnSchema.uniqueColumnName || columnSchema.column;
    });
    const len = Object.values(dataToPlot[0].data[0])[0].length;

    for (let i = 0; i < len; i++) {
      const row = {};
      row['id'] = i + 1;
      cols.forEach((col) => {
        row[col] = dataToPlot[0].data[0][col][i];
      });
      rs.push(row);
    }

    // dataToPlot[0].data[0]
    return rs;
  }, [dataToPlot, chartData]);

  return (
    <>
      <div style={{ height: chartHeight, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // pageSize={5}
          autoPageSize
          rowsPerPageOptions={[5]}
          // checkboxSelection
        />
      </div>
    </>
  );
}

export default TableChart;
