import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import './dataReportType.scss';
interface dataReportTypeProps {
  stateProps: any;
}
const DataReportType = ({ stateProps }: dataReportTypeProps) => {
  return (
    <div className='dataReportTypeContainer'>
      <PageWrapper stateProps={stateProps} />
    </div>
  );
};

export default DataReportType;
