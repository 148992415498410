import React from 'react';
import './tablePopUp.scss';
import { BsChevronDoubleDown } from 'react-icons/bs';
import { mls } from 'lib/multilanguagesupport';
import DisplayTable from 'lib/reusable-components/DisplayTable/DisplayTable';

interface mainProps {
  isTablePopUp: boolean;
  setIsTablePopUp: Function;
  tableData: any[];
  mainContainerWidth?: number;
  tableLoading: boolean;
}
const TablePopUp = (props: mainProps) => {
  const { setIsTablePopUp, mainContainerWidth = null, tableData, tableLoading } = props;

  return (
    <div
      className='tableOuterContainer'
      style={{
        maxWidth: mainContainerWidth ? mainContainerWidth * 1 : 'auto',
        minWidth: mainContainerWidth ? mainContainerWidth * 0.7 : 'auto',
        minHeight: '300px',
      }}
    >
      <div className='tableHeaderDiv'>
        <div className='tableHeaderTitle'>{mls("Table's Head Rows")}</div>

        <div className='closeDiv' onClick={() => setIsTablePopUp(false)}>
          <BsChevronDoubleDown />
        </div>
      </div>
      <DisplayTable tableData={tableData} tableLoading={tableLoading} />
    </div>
  );
};

export default TablePopUp;
