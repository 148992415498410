import { newConnectionFormsSchema } from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import ConnectionForm from '../../ConnectionForm/ConnectionForm';
import { mls } from 'lib/multilanguagesupport';
interface editDataSourceConnectionProps {
  connectionType: any;
  formData: any;
  appID: string;
  setFormData: Function;
  editConnectionMode: boolean;
  isNewConnection: boolean;
  setIsNewConnection: Function;
  setIsEditConnectionMode: Function;
  tableData: any;
  setTableData: Function;
  tableConfig: any;
  setTableConfig: Function;
  saveConnection: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}

const EditDataSourceConnection = ({
  connectionType,
  formData,
  appID,
  setFormData,
  editConnectionMode,
  setIsEditConnectionMode,
  isNewConnection,
  tableData,
  setTableData,
  setIsNewConnection,
  tableConfig,
  setTableConfig,
  saveConnection,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: editDataSourceConnectionProps) => {
  const FormHeader = () => (
    <>
      <span>
        <img src={connectionType?.icon} alt='' />
      </span>
      {!isNewConnection ? (
        <>
          <b>{formData.connectionName}</b>
          {formData.connectorType === newConnectionFormsSchema.apps.type.googleSheets.type ? (
            <>
              <b>({mls(connectionType.name)})</b>
              <a
                className='linkButton'
                href={`https://docs.google.com/spreadsheets/d/${formData?.data?.spreadsheetID}/edit?pli=1#gid=0`}
                target='_blank'
                rel='noreferrer'
              >
                {mls('Go to Google Spreadsheet')}
              </a>
            </>
          ) : (
            <b>({mls(connectionType.name)})</b>
          )}
        </>
      ) : (
        <b>{mls(connectionType.name)}</b>
      )}
    </>
  );
  return (
    <ConnectionForm
      connectionType={connectionType}
      formData={formData}
      setFormData={setFormData}
      appID={appID}
      editConnectionMode={editConnectionMode}
      isNewConnection={isNewConnection}
      setIsEditConnectionMode={setIsEditConnectionMode}
      tableData={tableData}
      setTableData={setTableData}
      tableConfig={tableConfig}
      setTableConfig={setTableConfig}
      saveConnection={saveConnection}
      FormHeader={FormHeader}
      fileToUploadObject={fileToUploadObject}
      setFileToUploadObject={setFileToUploadObject}
      credentialID={credentialID}
    />
  );
};

export default EditDataSourceConnection;
