import { useMemo, useRef, useState } from 'react';
// import DashboardWrapper from '../components/dashboardArea/DashboardWrapper';
import { mls } from 'lib/multilanguagesupport';
import Chart from 'lib/visx-lib';
import { CircularProgress } from '@mui/material';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import { getChartSchema } from '../utils/finalSchemaFunction';
import useDivDimensions from 'lib/visx-lib/helpers/divDimensionHelper';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import AllTargetPointsContainer from '../components/AllTargetPointsContainer/AllTargetPointsContainer';
import { updateChartSchemaInServer } from '../utils/connectionFunction';
import { defaultChartWidthTypesObj } from '../data/defaultSchema';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';

interface mainPorps {
  toggleProps: {
    minimizedDiv: string[];
    setMinimizedDiv: Function;
  };
  mainPorps: any;
}
const ChartArea = (props: mainPorps) => {
  const { toggleProps, mainPorps } = props;

  const { minimizedDiv, setMinimizedDiv } = toggleProps;

  const presentActiveColumn = useMemo(() => {
    let filtered = minimizedDiv.filter(Boolean);
    if (filtered?.length === 0) {
      return 'twoColumnOpen';
    }
    if (filtered?.length === 1) {
      return 'oneColumnOpen';
    }
    return '';
  }, [minimizedDiv]);
  const { chartSchemaMemo } = mainPorps.stateProps;

  const {
    chartType,
    chatName,
    setChatName,
    chartDescription,
    setChartDescription,
    setChartWidth,
    allTableData,
    setTimeFrame,
    setDefaultKpiTargets,
    setKpiTargetPoints,
    defaultDateTimeCol,
    tableDetails,
    tableID,
    //Need for udpate Chart Plot Data
    isForecast,
    isCrossReport,
    isDateHierarchy,
    isInsight,
    iskeepAllFilters,
    xAxisData,
    yAxisData,
    chartFilter,
    timeFrame,
    //Need for udpate Chart Schmea Data

    // Normal Chart
    chartXAxisStyle,
    chartYAxisStyle,
    chartToolTip,
    isZoomBrush,
    plotAreaBackground,
    gridLines,
    chartWidth,
    columnDropNumber,
    // Special Case Chart
    //KPIMETER
    kpiTargetPoints,
    kpiProjectedTargetPoints,
  } = mainPorps.stateProps;

  const chartUpdateByAPIDebounceArray = useMemo(() => {
    return [
      // Normal Chart
      chartType,
      isForecast,
      isCrossReport,
      isDateHierarchy,
      isInsight,
      iskeepAllFilters,
      xAxisData,
      yAxisData,
      chartFilter,
      timeFrame,
      //KPIMETER
      kpiTargetPoints,
      kpiProjectedTargetPoints,
    ];
  }, [
    // Normal Chart
    chartType,
    isForecast,
    isCrossReport,
    isDateHierarchy,
    isInsight,
    iskeepAllFilters,
    xAxisData,
    yAxisData,
    chartFilter,
    timeFrame,
    //KPIMETER
    kpiTargetPoints,
    kpiProjectedTargetPoints,
  ]);
  const chartSchemaUpdateDebounceArray = useMemo(() => {
    return [
      // Normal Chart
      chatName,
      chartDescription,
      chartXAxisStyle,
      chartYAxisStyle,
      chartToolTip,
      isZoomBrush,
      plotAreaBackground,
      gridLines,
      chartWidth,
      columnDropNumber,
      // Special Case Chart
      kpiProjectedTargetPoints,
    ];
  }, [
    // Normal Chart
    chatName,
    chartDescription,
    chartXAxisStyle,
    chartYAxisStyle,
    chartToolTip,
    isZoomBrush,
    plotAreaBackground,
    gridLines,
    chartWidth,
    columnDropNumber,
    // Special Case Chart

    kpiProjectedTargetPoints,
  ]);

  const chartUpdateDebounce = useDebounce(chartUpdateByAPIDebounceArray, 300);
  const chartSchemaUpdateDebounce = useDebounce(chartSchemaUpdateDebounceArray, 300);

  return (
    <div className={`thirdColumn ${presentActiveColumn}`}>
      {/* <div className='uppperMainTitle'>{mls('KPI Chart')}</div> */}
      <div className='kpiChartAreaUpperSection'>
        <div className='kpiChartAreaUpperLeftSection'>
          <div className='chartNameDiv'>
            <div className='titleDiv'>{mls('KPI Name')}:</div>
            <input
              type='text'
              placeholder={mls('Name of KPI')}
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
            />
          </div>
          <div className='chartDescriptionDiv'>
            <div className='titleDiv'>{mls('KPI Description')}:</div>

            <textarea
              placeholder={mls('Description of KPI')}
              value={chartDescription}
              rows={3}
              maxLength={50}
              onChange={(e) => setChartDescription(e.target.value)}
            />
          </div>
          <div className='chartWidthDiv'>
            <div className='titleDiv'>
              {mls('Chart Width')}
              <span>:</span>
            </div>
            <div className='chartWidthDropDown'>
              {/* <ArrayDropDown
                array={defaultChartWidthTypes}
                dropDownValue={chartWidth}
                setdropDownValue={setChartWidth}
              /> */}
              <ObjectDropDown
                object={defaultChartWidthTypesObj}
                dropDownValue={chartWidth.key}
                setdropDownValue={(action: string) =>
                  setChartWidth(
                    defaultChartWidthTypesObj[action as keyof typeof defaultChartWidthTypesObj]
                  )
                }
                ObjectInObject
              />
            </div>
          </div>
        </div>
        <div className='kpiChartAreaUpperRightSection'>
          <div className='chartPlotAreaOuter'>
            <ChartDiv
              chartUpdateDebounce={chartUpdateDebounce}
              chartSchemaUpdateDebounce={chartSchemaUpdateDebounce}
              chartSchemaMemo={chartSchemaMemo}
              chartType={chartType}
              setMinimizedDiv={setMinimizedDiv}
              toggleProps={toggleProps}
              chartWidth={chartWidth}
              setChartWidth={setChartWidth}
              allTableData={allTableData}
              yAxisData={yAxisData}
              setDefaultKpiTargets={setDefaultKpiTargets}
              setKpiTargetPoints={setKpiTargetPoints}
              chartFilter={chartFilter}
              timeFrame={timeFrame}
              tableID={tableID}
            />
          </div>
        </div>
      </div>
      <div className='kpiChartAreaLowerSection'>
        <AllTargetPointsContainer
          selectedKpiTimeFrameRange={kpiTargetPoints.kpiTimeframe}
          stateProps={mainPorps.stateProps}
        />
      </div>
    </div>
  );
};

export default ChartArea;

const ChartDiv = (chartDivProps: any) => {
  const {
    chartSchemaMemo,
    chartUpdateDebounce,
    chartSchemaUpdateDebounce,
    chartType,
    allTableData,
    chartFilter,
    timeFrame,
    tableID,
  } = chartDivProps;
  const [isChartDisplayable, setisChartDisplayable] = useState(false);
  const [plotingInProgress, setPlotingInProgress] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  // const [plotData2, setPlotData] = useState({});
  // const [chartSchema2, setChartSchema] = useState({});
  // const plotData = allPlottingData.charts['a0aaf080-53b4-11ee-8f64-4bfa82b0440c']
  // const chartSchema = chartSchemaTest['a0aaf080-53b4-11ee-8f64-4bfa82b0440c']
  const [plotData, setPlotData] = useState({});
  const [chartSchema, setChartSchema] = useState<{ [key: string]: any }>({});
  const containerRef = useRef(null);
  const { width } = useDivDimensions(containerRef);
  const mainContainerWidth = width ?? 0;
  useUpdateEffect(async () => {
    setPlotingInProgress(true);
    setisChartDisplayable(false);
    setLoadingScreen(true);
    const schemaData = await updateChartSchemaInServer({
      chartSchemaMemo,
      chartType,
      endPoint: `${chartSchemaMemo.appID}/createKpi`,
    });
    if (schemaData.skip) {
      // setPlotingInProgress(false);
      // setisChartDisplayable(false);
      setLoadingScreen(false);
      return;
    }
    setChartSchema(schemaData.chartSchema);
    setPlotData(schemaData.plotData);
    setisChartDisplayable(true);
    setPlotingInProgress(false);
    setLoadingScreen(false);
  }, chartUpdateDebounce);

  useUpdateEffect(async () => {
    if (plotingInProgress) {
      return;
    }
    setLoadingScreen(true);
    setisChartDisplayable(false);
    const dataByChart: any = getChartSchema({ chartSchemaMemo, chartType, normal: undefined });
    const chartID = Object.keys(dataByChart?.data?.charts)[0];
    const finalSchema = dataByChart?.data?.charts?.[chartID];
    setChartSchema(finalSchema);

    setisChartDisplayable(true);
    setLoadingScreen(false);
  }, chartSchemaUpdateDebounce);

  const chartHeight = useMemo(() => {
    // if (minimizedDiv[0] === '' || minimizedDiv[1] === '') {
    //   return mainContainerWidth;
    // }
    const finalHeight =
      mainContainerWidth <= 576
        ? mainContainerWidth // sm
        : mainContainerWidth <= 992
        ? // ? mainContainerWidth / 3 // md
          mainContainerWidth / 2 // md
        : mainContainerWidth / 3; // lg
    return finalHeight;
  }, [mainContainerWidth]);
  // allPlottingData
  // chartSchema
  const handleChartUpdates = async (updatedValue: any) => {
    const endPoint = 'createCharts';
    const dataByChart: any = getChartSchema({ chartSchemaMemo, chartType, normal: undefined });
    if (dataByChart.skip) {
      return;
    }
    const appID = chartSchemaMemo?.appID ?? '';
    const newData = { ...dataByChart.data, chartUpdates: updatedValue };
    // const res = await supistaApiPost(endPoint, newData, appID);
    // if (!res.__d3__error) {
    //   const chartID = Object.keys(dataByChart?.data?.charts)[0];
    //   const schemaData = {
    //     skip: false,
    //     chartSchema: dataByChart?.data?.charts[chartID],
    //     plotData: res?.response?.charts[chartID],
    //   };
    //   if (schemaData.skip) {
    //     return;
    //   }
    //   setChartSchema(schemaData.chartSchema);
    //   setPlotData(schemaData.plotData);
    // }
  };
  if (loadingScreen) {
    return (
      <div className='chartPlotArea' ref={containerRef}>
        <div className='chartLoadingArea'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className='chartPlotArea' ref={containerRef}>
      {isChartDisplayable ? (
        <Chart
          chartKey={chartSchema?.chartID}
          chartData={chartSchema ?? {}}
          dataToPlot={plotData ?? {}}
          cardWidth={'Full'}
          // useChartSettingsButton={chartSettingButtonFunction}
          // useChartSettingsButton={() => {
          //   return () => chartSettingButtonFunction;
          // }}
          useChartSettingsButton={() => {
            return () => {};
          }}
          handleChartUpdates={handleChartUpdates}
          // handleChartUpdates={() => {
          //   return () => chartSettingButtonFunction;
          // }}
          isInsightChartSchema={true}
          updateFilter={() => {}}
          isProd={false}
          isUpdateLoading={false}
          filterSchema={allTableData}
          chartHeight={chartHeight}
          timeFrameObj={timeFrame}
          filterObj={chartFilter}
          tableID={tableID}
          outerClassName={'mb-0'}
        />
      ) : (
        <>
          <div className='col-12 mb-0'>
            <div
              className='card'
              style={{
                padding: '0.5rem 1rem',
                height: '100%',
                minHeight: '100%',
                backgroundColor: 'rgb(255, 255, 255)',
              }}
            >
              <div>
                <div
                  className='fw-bolder my-1 fs-4 d-flex justify-content-center'
                  style={{ color: 'rgb(126, 130, 153);' }}
                >
                  {mls('Put a column in KPI tracking column and A target point to display KPI')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
