import React, { useEffect, useState } from 'react';
import ChatBoxArea from './ChatBoxArea';
import AutoGeneratedArea from './AutoGeneratedArea';
import SqlQueryArea from './SqlQueryArea';
import './sqlCustomCommands.scss';
import LoadingSpinner from 'lib/visx-lib/helpers/components/LoadingSpinner';
import AIToQueryColumn from './AIToQueryColumn';
import SqlInstructionArea from './sqlInstructions/SqlInstructionArea';
import {
  AllCustomCommandType,
  allOutputTypes,
  defaultDataByTableType,
  sqlQueryState,
} from './data/sqlCustomCommandsData';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import {
  convertTableData,
  getSheetID,
  getConvertedTableData,
} from 'lib/reusable-components/DisplayTable/tableFunction';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import { mls } from 'lib/multilanguagesupport';
interface sqlCustomCommandsProps {
  appID: string;
  setTableSchema: Function;
  useUpdateSchema: Function;

  tableSchema: any;
  tableData: { [key: string]: any };

  appDatatable: any;
  displayOff?: boolean;
  displayOffMsg?: string;
  customCommandType: string;
  defaultSqlArea?: boolean;
  disableSqlStatements?: boolean;
  updateSqlCustomCommands?: number | false;

  setIsAnyChangesSqlCommands?: Function;
  setIsAnyChangesSchema?: Function;
  //NEW
  timeFrame: string;
  dateTimeCol: string;
  mainSqlQuery: string;
  tableDataArray: { [key: string]: any }[];
}

const SqlCustomCommands = ({
  appID,
  tableData,
  setTableSchema,
  tableSchema,
  appDatatable,
  displayOff = false,
  displayOffMsg = '',
  useUpdateSchema,
  customCommandType,
  defaultSqlArea = false,
  updateSqlCustomCommands = false,
  disableSqlStatements = false,
  setIsAnyChangesSchema = (e: boolean) => {},
  setIsAnyChangesSqlCommands = (e: boolean) => {},
  //NEW
  timeFrame,
  dateTimeCol,
  tableDataArray,
  mainSqlQuery,
}: sqlCustomCommandsProps) => {
  const [isSqlArea, setIsSqlArea] = useState(defaultSqlArea || (mainSqlQuery ? true : false));
  const appSheets = useSelector(
    (state: any) => state?.config_store?.analyticsSchema?.appSheets || {}
  );

  const { apiProps, sqlCommandTitle, defaultQuery } =
    defaultDataByTableType[customCommandType as keyof typeof defaultDataByTableType];

  const [sqlQueryData, setsqlQueryData] = useState({
    queryState: sqlQueryState.close,
    query: '',
  });

  const handleSqlArea = () => {
    setIsSqlArea((currentValue) => !currentValue);
  };
  const [sqlQuery, setSqlQuery] = useState(mainSqlQuery ? mainSqlQuery : defaultQuery);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableHeadLoading, setTableHeadLoading] = useState(false);
  const [isTablePopUp, setIsTablePopUp] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [isSelectedTableDisabled, setIsSelectedTableDisabled] = useState(true);
  const [lastQuery, setLastQuery] = useState(mainSqlQuery ? mainSqlQuery : defaultQuery);
  const [customColumnSchema, setCustomColumnSchema] = useState([]);
  const [outputData, setOutputData] = useState({
    outputMsg: '',
    outputType: allOutputTypes.normal,
  });

  const tableColumnProps = {
    appID,
    tableData,
    tableSchema,
    setTableSchema,
    selectedTableData,
    setSelectedTableData,
    isTablePopUp,
    setIsTablePopUp,
    tableLoading,
    setTableLoading,
    isSelectedTableDisabled,
    setIsSelectedTableDisabled,
    handleSqlArea,
    sqlQueryData,
    setsqlQueryData,
    customCommandType,
    apiProps,
    useUpdateSchema,
    lastQuery,
    setLastQuery,
    outputData,
    setOutputData,
    customColumnSchema,
    setCustomColumnSchema,
    sqlQuery,
    setSqlQuery,
    tableHeadLoading,
    disableSqlStatements,
    setIsAnyChangesSqlCommands,
    setIsAnyChangesSchema,
    setTableHeadLoading,
    // defaultData
    defaultQuery,

    //NEW
    timeFrame,
    dateTimeCol,
    tableDataArray,
  };

  useEffect(() => {
    if (defaultSqlArea) {
      return;
    }
    if (!mainSqlQuery) {
      if (!displayOff) {
        setIsSqlArea(false);
      }
    }
  }, [displayOff, mainSqlQuery, defaultSqlArea]);
  const getTableDataWhenOpen = async (isPopup = false) => {
    setTableHeadLoading(true);
    //getTableDataUsingQuery
    if (mainSqlQuery) {
      const tableList = tableSchema?.tables ?? [tableSchema.tableID];
      const finalEndPoint = `${appID}/apiType/dataReport/reports`;
      const testData = {
        appID: appID,
        tableID: tableSchema.tableID,
        tableList: tableList,
        sqlQuery: sqlQuery,
        reportTimeframe: {
          period: timeFrame,
        },
        datetimeColumn: dateTimeCol,
      };
      const responseData = await _supistaAnalyticsApi(finalEndPoint, testData);
      if (responseData.__d3__error) {
        console.log('Error: while getting tabledata In getTableDataUsingQuery');
      } else {
        const finalTableData = convertTableData({
          tableHead: responseData?.response?.dataHead,
          tableSchema: {
            data: responseData?.response?.tableSchema,
          },
          highlightTableSchema:
            customCommandType === AllCustomCommandType.customTable
              ? responseData?.response?.tableSchema
              : responseData?.response?.customColumnSchema,
        });

        setSelectedTableData(finalTableData);
        setIsSelectedTableDisabled(false);
      }
    } else {
      //getTableDataUsingTableData
      if (customCommandType === AllCustomCommandType.customTable) {
        return;
      }
      const sheetData: {
        sheetID: string;
        skip: boolean;
        newCreatedSheetID?: boolean;
      } = await getSheetID({
        appSheets: appSheets,
        selectedTable: tableSchema.tableID,
        appID,
      });
      if (sheetData?.skip) {
        console.log('Error: while getting sheetdata in getTableDataUsingTableData');
        return;
      }
      const tableDataProps = {
        appID,
        tableID: tableSchema.tableID,
        sheetID: sheetData.sheetID,
      };
      const tableDataRes = await getConvertedTableData({
        appID: tableDataProps.appID,
        selectedTable: tableDataProps.tableID,
        sheetID: tableDataProps.sheetID,
      });
      if (tableDataRes.skip) {
        console.log('Error: while getting tabledata In getTableDataUsingTableData');
        return;
      }

      setSelectedTableData(tableDataRes.groupedTableData);
      setIsSelectedTableDisabled(false);
    }
    setTableHeadLoading(false);
    setIsTablePopUp(isPopup);
  };
  useEffect(() => {
    getTableDataWhenOpen();
  }, []);
  useEffect(() => {
    setSqlQuery(mainSqlQuery ? mainSqlQuery : defaultQuery);
    setLastQuery(mainSqlQuery ? mainSqlQuery : defaultQuery);
  }, [mainSqlQuery]);

  useUpdateEffect(() => {
    if (updateSqlCustomCommands !== false) {
      setSqlQuery(mainSqlQuery ? mainSqlQuery : defaultQuery);
      getTableDataWhenOpen(true);
    }
  }, [updateSqlCustomCommands]);

  return (
    <div className='tableCustomColumn'>
      {sqlQueryData.queryState === sqlQueryState.open ? (
        <AIToQueryColumn
          sqlQueryData={sqlQueryData}
          sqlQueryState={sqlQueryState}
          setsqlQueryData={setsqlQueryData}
          setIsSqlArea={setIsSqlArea}
          setSqlQuery={setSqlQuery}
          setIsAnyChangesSqlCommands={setIsAnyChangesSqlCommands}
        />
      ) : null}
      <div className='modal-body pt-0 pb-0 px-xl-20 editTableTitle'>
        {sqlCommandTitle.display ? (
          <div className='text-center'>
            <h1 className='mb-3'>{mls(sqlCommandTitle.mainTitle)}</h1>
            <div className='text-muted fw-bold fs-5'>{mls(sqlCommandTitle.mainDescription)}</div>
          </div>
        ) : null}
      </div>
      <div className='customColumnOuterArea'>
        {tableLoading ? (
          <div className='loadingContainer'>
            <LoadingSpinner />
          </div>
        ) : null}
        {displayOff ? (
          <div className='customColumnsDisplayOffMsg'>{mls(displayOffMsg)}</div>
        ) : (
          <>
            {isSqlArea ? (
              <>
                <div className='sqlMainArea'>
                  <div className='sqlMainCodingArea'>
                    <SqlInstructionArea
                      tableSchema={tableSchema}
                      tableDataArray={tableDataArray}
                      appDatatable={appDatatable}
                      customCommandType={customCommandType}
                    />
                    <div className='customColumnsInnerArea'>
                      <div className='sqlOuterArea sqlOuterAreaFullHeight'>
                        <SqlQueryArea tableColumnProps={tableColumnProps} />
                      </div>
                    </div>
                  </div>
                  <ChatBoxArea tableColumnProps={tableColumnProps} />
                </div>
              </>
            ) : (
              <div className='customColumnsInnerArea' style={{ width: '100%' }}>
                <div className='sqlOuterArea'>
                  <AutoGeneratedArea tableColumnProps={tableColumnProps} />
                </div>
                <ChatBoxArea tableColumnProps={tableColumnProps} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SqlCustomCommands;
