import React from 'react';
import PropTypes from 'prop-types';
import { Group } from '@visx/group';

import BarChart from './BarChart';
import LineChart from './LineChart';
import AreaChart from './AreaChart';

import BarHorizontalChart from './BarHorizontalChart';
import FunnelChart from './FunnelChart';

const ChartBuilder = ({
  chartID,
  x,
  y,
  type,
  yCategory,
  xCategory,
  height,
  width,
  xScale,
  yScale,
  yMax,
  xMax,
  handleMouseOver,
  hideTooltip,
  children,
  top,
  left,
  handleClick,
  colorType,
  gradientColor,
  chartColor,
}) => {
  // Function to select chart component based on chart type
  const selectChart = () => {
    if (type === 'BAR') {
      return (
        <BarChart
          xScale={xScale}
          yScale={yScale}
          yMax={yMax}
          x={x}
          y={y}
          onMouseOver={handleMouseOver}
          onMouseOut={hideTooltip}
          colorType={colorType}
          gradientColor={gradientColor}
          chartColor={chartColor}
          handleClick={handleClick}
          height={height}
        />
      );
    }
    if (type === 'BARHORIZONTAL') {
      return (
        <BarHorizontalChart
          xScale={xScale}
          yScale={yScale}
          xMax={xMax}
          x={x}
          y={y}
          colorType={colorType}
          gradientColor={gradientColor}
          chartColor={chartColor}
          onMouseOver={handleMouseOver}
          onMouseOut={hideTooltip}
          handleClick={handleClick}
        />
      );
    }
    if (type === 'LINE') {
      return (
        <LineChart
          chartID={chartID}
          yScale={yScale}
          y={y}
          xScale={xScale}
          x={x}
          width={width}
          height={height}
          xCategory={xCategory}
          yCategory={yCategory}
          onMouseOver={handleMouseOver}
          onMouseOut={hideTooltip}
          colorType={colorType}
          gradientColor={gradientColor}
          chartColor={chartColor}
        />
      );
    }
    if (type === 'AREA') {
      return (
        <AreaChart
          yScale={yScale}
          y={y}
          xScale={xScale}
          x={x}
          width={width}
          height={height}
          xCategory={xCategory}
          yCategory={yCategory}
          onMouseOver={handleMouseOver}
          onMouseOut={hideTooltip}
          colorType={colorType}
          gradientColor={gradientColor}
          chartColor={chartColor}
        />
      );
    }
    if (type === 'FUNNEL') {
      return (
        <FunnelChart
          xScale={xScale}
          yScale={yScale}
          xMax={xMax}
          x={x}
          y={y}
          onMouseOver={handleMouseOver}
          onMouseOut={hideTooltip}
          colorType={colorType}
          gradientColor={gradientColor}
          chartColor={chartColor}
        />
      );
    }
    throw Error('Invalid Chart Type'); // Invalid BarType Type
  };

  return (
    <Group top={top} left={left}>
      {selectChart()}
      {children}
    </Group>
  );
};

ChartBuilder.defaultProps = {
  children: <></>,
};

ChartBuilder.propTypes = {
  chartID: PropTypes.string.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  yCategory: PropTypes.string.isRequired,
  xCategory: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  yMax: PropTypes.number.isRequired,
  xMax: PropTypes.number.isRequired,
  handleMouseOver: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  children: PropTypes.shape(),
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

export default ChartBuilder;
