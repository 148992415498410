import React, { ReactNode, useState } from 'react';
import GetFields from '../GetFileds/GetFileds';
import { defaultConnectionSchema } from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';

interface connectionFormProps {
  connectionType: any;
  formData: any;
  appID: string;
  setFormData: Function;
  editConnectionMode: boolean;
  isNewConnection: boolean;
  setIsEditConnectionMode: Function;
  tableData: any;
  setTableData: Function;
  tableConfig: any;
  setTableConfig: Function;
  saveConnection: Function;
  FormHeader: any;

  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}

const ConnectionForm = ({
  connectionType,
  formData,
  appID,
  setFormData,
  editConnectionMode,
  setIsEditConnectionMode,
  isNewConnection,
  tableData,
  setTableData,
  tableConfig,
  setTableConfig,
  saveConnection,
  fileToUploadObject,
  setFileToUploadObject,
  FormHeader = <></>,
  credentialID,
}: connectionFormProps) => {
  const [saveLoading, setSaveLoading] = useState(false);

  const handleFormMainData = (action: any) => {
    const updatedFormData = {
      ...(formData ?? {}),
      [action.key]: action.value,
    };
    setFormData(updatedFormData);
  };

  const handleFormData = (action: any) => {
    const updatedFormData = {
      ...(formData ?? {}),
      data: {
        ...(formData.data ?? {}),
        [action.key]: action.value,
      },
    };
    setFormData(updatedFormData);
  };
  const handleTableConfig = (action: any) => {
    setTableConfig(action.value);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    setSaveLoading(true);
    const saveConnectionRes = await saveConnection({ connectionType });
    if (saveConnectionRes.skip) {
      setSaveLoading(false);
    } else {
      setSaveLoading(false);
    }
  };

  if (editConnectionMode || isNewConnection) {
    return (
      <div className='dataSourceConnectionInnerContainer '>
        <div className='updloadConnectionDescription'>{FormHeader ? <FormHeader /> : null}</div>
        <div className='editConnectionFormContainer'>
          <form onSubmit={(e) => handleSave(e)} className='editConnectionForm'>
            <div className='fieldAreaOuterContainer'>
              {React.Children.toArray(
                defaultConnectionSchema.map((fieldsElem: any) => (
                  <GetFields
                    fieldsElem={fieldsElem}
                    formData={formData}
                    setFormData={handleFormMainData}
                    fileToUploadObject={fileToUploadObject}
                    setFileToUploadObject={setFileToUploadObject}
                    credentialID={credentialID}
                  />
                ))
              )}
              {React.Children.toArray(
                (connectionType.fields ?? []).map((fieldsElem: any) => (
                  <GetFields
                    fieldsElem={fieldsElem}
                    formData={formData?.data ?? {}}
                    setFormData={handleFormData}
                    fileToUploadObject={fileToUploadObject}
                    setFileToUploadObject={setFileToUploadObject}
                    credentialID={credentialID}
                  />
                ))
              )}
            </div>
            <div className='editConnectionFormButtonsContainer'>
              <button
                className='primaryButton primaryButtonFlexDiv'
                style={{ gap: '.5rem' }}
                disabled={saveLoading}
              >
                {mls('Save')}
                {saveLoading ? (
                  <>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress style={{ width: '1rem', height: '1rem' }} color='inherit' />
                    </span>
                  </>
                ) : null}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return (
    <div className='dataSourceConnectionInnerContainer tableConfigWidthContainer'>
      <div className='updloadConnectionDescription'>{FormHeader ? <FormHeader /> : null}</div>
      <div className='editConnectionButtonDiv'>
        <button
          className='linkButton'
          onClick={() => setIsEditConnectionMode((currentValue: boolean) => !currentValue)}
        >
          {mls('Edit Connection')}
        </button>
      </div>
      <div className='editConnectionFormContainer'>
        <form onSubmit={(e) => handleSave(e)} className='editConnectionForm'>
          <div className='fieldAreaOuterContainer'>
            <GetFields
              fieldsElem={connectionType.tableConfig ?? {}}
              formData={{ ...formData, tableConfig }}
              tableData={tableData}
              setTableData={setTableData}
              setFormData={handleTableConfig}
              fileToUploadObject={fileToUploadObject}
              setFileToUploadObject={setFileToUploadObject}
              credentialID={credentialID}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConnectionForm;
