import React, { useState, useMemo, useCallback, memo } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import {
  // STARTING_DATA_LENGTH,
  defaultToolTipStyling,
  toggleObj,
} from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { mls } from 'lib/multilanguagesupport';

import { scaleLinear, scaleTime } from '@visx/scale';
import { Box, CircularProgress } from '@mui/material';
import { Line, SplitLinePath } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { GridRows } from '@visx/grid';
import { localPoint } from '@visx/event';
import { extent, bisector } from 'd3-array';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { getStyling } from '../../helpers/colorHelper';
import useD3Brush from '../../helpers/hooks/useD3Brush';

let tooltipTimeout;
export const widthHelper = (x, type, chartWidth, margin) => {
  const lineAreaWidth = 40;
  const effectiveWidth = chartWidth;

  const plotNum = x.length;
  const padding = 0.1;
  const nodeWidth = lineAreaWidth;

  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  return Math.max(reqWidth, effectiveWidth);
};

const brushMargin = {
  top: 5,
  bottom: 20,
  left: 70,
  right: 20,
};
const chartSeparation = 50;

const margin = { left: 60, top: 15, bottom: 60, right: 20 };
function getRequiredFormat(data, xLabel, yLabel, labels) {
  const { x, y } = data;
  const requiredData = {};
  Object.keys(labels).forEach((l) => {
    requiredData[l] = [];
  });
  Object.keys(y).forEach((key) => {
    requiredData[key] = y[key].map((d, i) => {
      return {
        [yLabel]: d,
        [xLabel]: x[i],
      };
    });
  });
  return requiredData;
}

const getData = (data, xLabel, yLabel) => {
  const reqData = [];
  const key = Object.keys(data)[0];
  data[key].forEach((d) => {
    reqData.push({
      [xLabel]: d[xLabel],
    });
  });

  Object.keys(data).forEach((key) => {
    data[key].forEach((d, i) => {
      reqData[i][key] = d[yLabel];
    });
  });

  return reqData;
};

// function sliceData(data, n) {
//   const slicedData = {};
//   slicedData.x = data.x.slice(n);
//   slicedData.y = {};
//   Object.keys(data.y).forEach((key) => {
//     slicedData.y[key] = data.y[key].slice(n);
//   });
//   return slicedData;
// }

const ForecastLineType = ({
  data,
  // data: allData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin: marginProp,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  labels,
  toggle,
  handleToggle,
  isToggleClicked,
  chartHeight,
  predictionData,
  isForecast,
  theme,
  desc,
  isInsight,
  chartData,
}) => {
  const { width } = useDivDimensions(cardRef);
  const height = chartHeight;
  // const [data, setData] = useState(sliceData(allData, -STARTING_DATA_LENGTH));

  const x = useMemo(() => data.x, [data.x]);
  const bisectDate = useMemo(() => bisector((d) => new Date(d[xAxisLabel])).left, [xAxisLabel]);
  const isBrush = chartData.isBrush === false ? false : true;

  const requiredData = useMemo(
    () => getRequiredFormat(data, xAxisLabel, yAxisLabel, labels),
    [data, labels, xAxisLabel, yAxisLabel]
  );

  const predictionRequiredData = useMemo(
    () => (isForecast ? getRequiredFormat(predictionData, xAxisLabel, yAxisLabel, labels) : []),
    [predictionData, isForecast, labels, xAxisLabel, yAxisLabel]
  );
  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = chartSeparation + 10;
  const topChartHeight = isBrush ? 0.95 * innerHeight - topChartBottomMargin : innerHeight;
  const bottomChartHeight = innerHeight - topChartHeight;
  const xMax = Math.max(width, 0) - margin.left - margin.right;

  const [filteredData, setFilteredData] = useState(requiredData);
  const [forecaseFilteredData, setForecastFilteredData] = useState(predictionRequiredData);

  const brushXScale = useMemo(() => {
    return scaleTime({
      domain: extent(
        [
          ...Object.values(requiredData)[0].map((d) => new Date(d[xAxisLabel])),
          ...Object.values(predictionRequiredData)[0].map((d) => new Date(d[xAxisLabel])),
        ],
        (d) => d
      ),
      range: [0, xMax],
      padding: 0,
    });
  }, [requiredData, predictionRequiredData, xAxisLabel, xMax]);

  const mainXScale = useMemo(() => {
    return scaleTime({
      domain: extent(
        [
          ...Object.values(filteredData)[0].map((d) => new Date(d[xAxisLabel])),
          ...Object.values(forecaseFilteredData)[0].map((d) => new Date(d[xAxisLabel])),
        ],
        (d) => d
      ),
      range: [margin.left, xMax + margin.left],
      padding: 0,
    });
  }, [filteredData, forecaseFilteredData, xAxisLabel, xMax]);

  const { Legend, colorScale } = useOrdinalLegend({
    legendGlyphSize: 20,
    colorScale: theme.colors,
    labels: labels,
  });

  const mainYScale = useMemo(() => {
    return scaleLinear({
      domain: [
        0,
        Math.max(
          ...Object.values(filteredData).map((d) => {
            return Math.max(...d.map((d) => d[yAxisLabel]));
          }),
          ...Object.values(forecaseFilteredData).map((d) => {
            return Math.max(...d.map((d) => d[yAxisLabel]));
          })
        ),
      ],
      range: [topChartHeight, 0],
    });
  }, [filteredData, topChartHeight, yAxisLabel, forecaseFilteredData]);

  const brushYScale = useMemo(() => {
    return scaleLinear({
      domain: [
        0,
        Math.max(
          ...Object.values(requiredData).map((d) => {
            return Math.max(...d.map((d) => d[yAxisLabel]));
          }),
          ...Object.values(predictionRequiredData).map((d) => {
            return Math.max(...d.map((d) => d[yAxisLabel]));
          })
        ),
      ],
      range: [bottomChartHeight - brushMargin.top - brushMargin.bottom, 0],
    });
  }, [requiredData, predictionRequiredData, bottomChartHeight, yAxisLabel]);

  const actualWidth = widthHelper(x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * x.length);

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleMouseOver = (event, datum) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const { x, y } = localPoint(event);

    if (datum) {
      showTooltip({
        tooltipData: datum,
        tooltipLeft: x,
        tooltipTop: y,
      });
      return;
    }

    const x0 = mainXScale.invert(x);
    const data = [
      ...getData(filteredData, xAxisLabel, yAxisLabel),
      ...getData(forecaseFilteredData, xAxisLabel, yAxisLabel),
    ];
    const index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    let d = d0;
    if (d1 && d1[xAxisLabel]) {
      d =
        x0.valueOf() - new Date(d0[xAxisLabel]).valueOf() >
        new Date(d1[xAxisLabel]).valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    showTooltip({
      tooltipData: d,
      tooltipLeft: x,
      tooltipTop: mainYScale(d[Object.values(labels)[0]]),
    });
  };
  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
        valueStyle={theme?.yAxisStyle?.valueStyle}
      />
    );
  };
  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
        valueStyle={theme?.xAxisStyle?.valueStyle}
      />
    );
  };

  const brushed = useCallback(
    function ({ selection }) {
      if (!selection) return;
      // const x0 = selection[0];
      const [x0Date, x1Date] = selection.map(brushXScale.invert);
      const newData = {};
      Object.keys(requiredData).forEach((key) => {
        newData[key] = requiredData[key].filter(
          (d) => new Date(d[xAxisLabel]) >= x0Date && new Date(d[xAxisLabel]) <= x1Date
        );
      });
      // if (x0 - margin.left <= width * 0.1 && data.x.length !== allData.x.length) {
      //   const n = Math.min(STARTING_DATA_LENGTH, allData.x.length - data.x.length);
      //   const newData = sliceData(allData, -x.length - n);
      //   setData(newData);
      //   setBrushPosition([x0Date, x1Date]);
      // }
      const forecastNewData = {};
      Object.keys(predictionRequiredData).forEach((key) => {
        forecastNewData[key] = predictionRequiredData[key].filter(
          (d) => new Date(d[xAxisLabel]) >= x0Date && new Date(d[xAxisLabel]) <= x1Date
        );
      });

      setFilteredData(newData);
      setForecastFilteredData(forecastNewData);
    },
    [brushXScale.invert, predictionRequiredData, requiredData, xAxisLabel]
  );
  const allXPoints = useMemo(
    () => [
      ...Object.values(requiredData)[0].map((d) => new Date(d[xAxisLabel])),
      ...Object.values(predictionRequiredData)[0].map((d) => new Date(d[xAxisLabel])),
    ],
    [requiredData, predictionRequiredData, xAxisLabel]
  );
  // const [brushPosition, setBrushPosition] = useState([
  //   allXPoints[allXPoints.length - dataNum],
  //   allXPoints[allXPoints.length - 1],
  // ]);

  const { ref: miniRef } = useD3Brush({
    brushed,
    height: bottomChartHeight - brushMargin.top - brushMargin.bottom,
    width: xMax,
    data: allXPoints,
    brushScale: brushXScale,
    dataNum,
    isBrush,
    // brushPosition,
  });

  const mainStyles = useCallback(
    (key) =>
      filteredData[key].length >= 0 && forecaseFilteredData[key].length > 0
        ? [
            { stroke: colorScale(key), strokeWidth: 2 },
            { stroke: colorScale(key), strokeDasharray: '5,5' },
          ]
        : [
            { stroke: colorScale(key), strokeDasharray: '5,5' },
            { stroke: colorScale(key), strokeWidth: 2 },
          ],
    [filteredData, forecaseFilteredData, colorScale]
  );

  const miniStyles = useCallback(
    (key) => [
      { stroke: colorScale(key), strokeWidth: 2 },
      { stroke: colorScale(key), strokeDasharray: '5,5' },
    ],
    [colorScale]
  );
  return (
    <>
      <>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            {xCategory === 'DATETIME' && (
              <div
                style={{
                  marginLeft: width < 500 ? 0 : margin.left,
                }}
                className='d-flex mb-4 ml-sm-0'
              >
                <div
                  style={{ zIndex: 0 }}
                  className={styles.toogleBtnContianer}
                  data-toggle='buttons'
                >
                  {Object.keys(toggleObj).map((item, index) => {
                    return (
                      <label
                        key={index}
                        className={` ${styles.toogleBtn} ${toggle === item ? styles.active : ''} `}
                      >
                        <input
                          type='radio'
                          name='options'
                          id={item}
                          autoComplete='off'
                          defaultChecked={index === 0}
                          hidden
                          onClick={() => handleToggle(item)}
                        />{' '}
                        {toggleObj[item]}
                      </label>
                    );
                  })}
                </div>
                {isToggleClicked && (
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
              </div>
            )}
            <svg height={height} width={width} ref={containerRef}>
              <AxisBottom
                top={topChartHeight + margin.top + 1}
                left={0}
                scale={mainXScale}
                stroke={'#777d84'}
                tickLength={3}
                tickClassName={styles.bottomAxisTicks}
                tickStroke={'#adb5bd'}
                label={xAxisLabel}
                labelClassName={styles.chartLabel}
                labelOffset={25}
                numTicks={dataNum}
                tickComponent={AxisBottomLabelWrapper}
                strokeWidth={2}
                labelProps={{
                  ...getStyling(theme?.xAxisStyle?.style),
                  textAnchor: 'middle',
                }}
              />
              <AxisLeft
                stroke={'#adb5bd'}
                strokeWidth={1.5}
                top={margin.top}
                left={margin.left}
                scale={mainYScale}
                labelClassName={styles.chartLabel}
                tickClassName={styles.leftAxisTicks}
                tickStroke={'#adb5bd'}
                tickLineProps={{
                  strokeWidth: 1.5,
                }}
                label={yAxisLabel}
                tickLength={3}
                tickComponent={AxisleftLabelWrapper}
                labelProps={{
                  ...getStyling(theme.yAxisStyle?.style),
                  textAnchor: 'middle',
                }}
              />
              <Group top={margin.top} height={topChartHeight}>
                <GridRows
                  left={margin.left}
                  scale={mainYScale}
                  numTicks={3}
                  width={xMax}
                  height={topChartHeight}
                />

                <MiniChart
                  requiredData={filteredData}
                  predictionRequiredData={forecaseFilteredData}
                  colorScale={colorScale}
                  xScale={mainXScale}
                  yScale={mainYScale}
                  xAxisLabel={xAxisLabel}
                  yAxisLabel={yAxisLabel}
                  styles={mainStyles}
                />
                <rect
                  width={xMax}
                  height={topChartHeight}
                  rx={4}
                  x={margin.left}
                  // fill='url(#dots-pink)'
                  fill='transparent'
                  onMouseMove={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                  onTouchMove={handleMouseOver}
                  onTouchEnd={handleMouseLeave}
                />
              </Group>
              {isBrush && (
                <Group left={margin.left} top={margin.top + topChartHeight + topChartBottomMargin}>
                  <svg ref={miniRef}>
                    <MiniChart
                      requiredData={requiredData}
                      predictionRequiredData={predictionRequiredData}
                      colorScale={colorScale}
                      xScale={brushXScale}
                      yScale={brushYScale}
                      xAxisLabel={xAxisLabel}
                      yAxisLabel={yAxisLabel}
                      styles={miniStyles}
                    />
                  </svg>
                </Group>
              )}

              {tooltipData && !tooltipData?.axis && (
                <g>
                  <Line
                    from={{ x: tooltipLeft, y: margin.top }}
                    to={{ x: tooltipLeft, y: topChartHeight + margin.top }}
                    stroke={'#aaa'}
                    strokeWidth={2}
                    pointerEvents='none'
                    strokeDasharray='5,2'
                  />
                  {/* <circle
                    cx={tooltipLeft}
                    cy={tooltipTop + 1}
                    r={4}
                    fill='black'
                    fillOpacity={0.1}
                    stroke='black'
                    strokeOpacity={0.1}
                    strokeWidth={2}
                    pointerEvents='none'
                  />
                  <circle
                    cx={tooltipLeft}
                    cy={tooltipTop}
                    r={4}
                    fill={'#ff0'}
                    stroke='white'
                    strokeWidth={2}
                    pointerEvents='none'
                  /> */}
                </g>
              )}
            </svg>
            {tooltipOpen && (
              <TooltipInPortal
                style={{ ...defaultToolTipStyling, padding: tooltipData?.axis ? 6 : 12 }}
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
              >
                {tooltipData && (
                  <>
                    <div>
                      {/* {mls(xAxisLabel)}:{'\t'} */}
                      <strong>{tooltipData[xAxisLabel]}</strong>
                    </div>
                    <br />
                    <div>
                      {/* {mls(yAxisLabel)}:{'\t'} */}
                      <div>
                        {Object.keys(tooltipData).map((key, index) => {
                          if (key !== xAxisLabel) {
                            const tStyling = theme.tooltip.style.find(
                              (item) => (item.uniqueColumnName || item.column) === key
                            );
                            if (!tStyling?.show) {
                              return null;
                            }
                            return (
                              <div
                                style={{
                                  paddingBottom: '3px',
                                  ...getStyling({
                                    color: colorScale(key),
                                    textStyle: theme.tooltip.textStyle,
                                    fontHeight: theme.tooltip.fontHeight,
                                    opacity: tStyling?.opacity,
                                  }),
                                }}
                                key={index}
                              >
                                {key}:{'\t'}
                                <strong>{tooltipData[key]}</strong>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </>
                )}
              </TooltipInPortal>
            )}
            <Legend />
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </>
    </>
  );
};

export default ForecastLineType;

const MiniChart = memo(
  ({ requiredData, predictionRequiredData, xScale, yScale, xAxisLabel, yAxisLabel, styles }) => {
    return (
      <>
        {Object.keys(requiredData).map((key, index) => {
          return (
            <SplitLinePath
              key={index}
              segmentation='x'
              segments={[requiredData[key], predictionRequiredData[key] || []]}
              x={(d) => xScale(new Date(d[xAxisLabel]))}
              y={(d) => yScale(d[yAxisLabel])}
              strokeWidth={2}
              styles={styles(key)}
            />
          );
        })}
      </>
    );
  }
);
