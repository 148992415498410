import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import { KTSVG } from '../../_metronic/helpers';
import useChartSettingsButton from '../../lib/reusable-components/reusableUtils/updateSchema/useChartSettingsButton';
// import { Modal } from 'react-bootstrap-v5';
import useAppState from '../../lib/reusable-components/reusableUtils/commons/useAppState';
// import { mls } from 'lib/multilanguagesupport';
import ChartPopUp from 'lib/chart-settings-lib';
import useUpdateSchema from 'lib/reusable-components/reusableUtils/updateSchema/useUpdateSchema';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import getSchemaLocation from 'lib/reusable-components/reusableUtils/updateSchema/getSchemaLocation';
import schemaConstants from 'lib/reusable-components/reusableUtils/updateSchema/schemaConstants';
// import { fetchDataToPlot } from 'app/network/apiConnections';
import { useDispatch } from 'react-redux';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';
import { mls } from 'lib/multilanguagesupport';
import { _fetchDataToPlot } from 'lib/server-connection/connections';

const actionNames = {
  add: 'Chart added successfully',
  update: 'Chart updated successfully',
  delete: 'Chart deleted successfully',
};

function ChartSettings({
  chartsSchema,
  appID,
  chartID,
  chartData,
  sheetID,
  tableSchema,
  isNewChart,
  defaultDatetimeCol,
  tableID,
}) {
  const chartSettingsState = useAppState('chartSettings');
  const chartSettingsButton = useChartSettingsButton();
  const dispatch = useDispatch();
  const [isUpdated, setUpdate] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [newSheetSchema, setNewSheetSchema] = useState(null);

  const updateSchema = useUpdateSchema();
  const analyticsSchema = useStoreSchema(
    reduxConstants.STORE_NAME,
    reduxConstants.config.ANALYTICS_SCHEMA
  );
  const isReadyForQuery =
    useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.READY_FOR_QUERY) || 0;

  const sheetSchema = analyticsSchema?.appSheets || {};

  const sheetLocation = getSchemaLocation(schemaConstants.type.SHEET);
  const chartLocation = getSchemaLocation(schemaConstants.type.CHART, {});

  const handleChartPopUp = (action, type = '') => {
    if (type === 'cancel') chartSettingsButton(false);
  };

  const chartSchema = {
    ...chartData,
    chartBuilderHelper: {
      columnDropNumber: 0,
    },
  };
  const updateProjectSchema = (schema, isDeleted, removedChartID) => {
    const prevSheet = { ...sheetSchema[sheetID] };
    const prevChartLists = [...(sheetSchema[sheetID]?.charts || [])];
    if (!isDeleted) {
      if (prevChartLists.indexOf(chartID) === -1) {
        prevChartLists.unshift(chartID);
        const newData = { ...prevSheet, charts: prevChartLists };
        // updating appProject in appSchema in redux store
        setNewSheetSchema(newData);
        setActionType('add');
        updateSchema(sheetLocation, sheetID, newData);
      }
      // updating appCharts in appSchema in redux store
      updateSchema(chartLocation, chartID, schema);
    } else if (removedChartID) {
      const index = prevChartLists.indexOf(removedChartID);
      prevChartLists.splice(index, 1);
      const newData = { ...prevSheet, charts: prevChartLists };
      // updating appProject in appSchema in redux store
      setNewSheetSchema(newData);
      setActionType('delete');
      updateSchema(sheetLocation, sheetID, newData);
    }
    setUpdate(true);
  };

  useEffect(() => {
    if (isUpdated || newSheetSchema != null) {
      const dataToSend = newSheetSchema || analyticsSchema.appSheets[sheetID];
      _fetchDataToPlot(dataToSend, `${appID}/SheetSettings/create`).then((res) => {
        if (!res.__d3__error) {
          dispatch(
            create({
              setPath: reduxConstants.config.APP_STATUS.TOAST,
              value: {
                message: `${mls(actionNames[actionType] || actionNames['update'])}`,
                time: 5,
                isActive: true,
              },
            })
          );
          dispatch(
            create({
              setPath: `${reduxConstants.config.READY_FOR_QUERY}`,
              value: isReadyForQuery + 1,
            })
          );
          chartSettingsButton(false);
        }
      });
      return () => {
        setUpdate(false);
        setNewSheetSchema(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appID, actionType, isUpdated, newSheetSchema, sheetID]);

  return (
    <>
      {chartSettingsState.chartID === chartID && (
        <ChartPopUp
          chartID={chartID}
          handleChartPopUp={handleChartPopUp}
          appID={appID}
          sheetID={sheetID}
          tableSchema={tableSchema}
          chartSchema={chartSchema}
          isNewChart={isNewChart}
          updateProjectSchema={updateProjectSchema}
          defaultDateTimeCol={defaultDatetimeCol}
          tableID={tableID}
        />
      )}
      {/* <Modal
        show={chartSettingsState.chartID === chartID}
        onHide={handleHide}
        size='lg'
        id={projectID}
      >
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={handleHide}
              data-bs-dismiss='modal'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-8 text-center'>
              <h1 className='mb-3'>{mls('Charts Settings')}</h1>
              <div className='text-muted fw-bold fs-5'>{mls('Edit charts settings here.')}</div>
            </div>
            <div className='d-flex flex-column'>
              <div className='row mt-10'>
                <ChartSettingsFields
                  chartData={chartData}
                  chartID={chartID}
                  projectID={projectID}
                  sheetID={sheetID}
                  appID={appID}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

ChartSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  chartsSchema: PropTypes.object.isRequired,
  chartID: PropTypes.string.isRequired,
  sheetID: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  chartData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

export default ChartSettings;
