import React from 'react';
import { mls } from 'lib/multilanguagesupport';
import SolidColorSelector from './StyleComponent/SolidColorSelector';
import FontSelector from './StyleComponent/FontSelector';


const KpiMeterStyleCase = ({ stateProps }) => {
  const { kpiTargetPoints, setKpiTargetPoints } = stateProps;

  const handlePostfix = (e) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      title: { ...kpiTargetPoints.title, valuePostfix: e.target.value },
    });
  };
  const handlePrefix = (e) => {
    setKpiTargetPoints({
      ...kpiTargetPoints,
      title: { ...kpiTargetPoints.title, valuePrefix: e.target.value },
    });
  };
  const handleTitleStyle = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, title: e });
  };
  const handleTargetFontStyle = (e) => {
    setKpiTargetPoints({ ...kpiTargetPoints, targetFontStyle: e });
  };

  return (
    <>
      {/* <KpiEditColors stateProps={stateProps} /> */}
      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Arc Point')}</div>

        <div className='grayContainers noOverflowGrayContainer'>
          <div className={`containerItems open`}>
            <FontSelector
              fontStyle={kpiTargetPoints.targetFontStyle}
              setFontStyle={handleTargetFontStyle}
            />
            <SolidColorSelector
              color={kpiTargetPoints.targetFontStyle}
              setColor={handleTargetFontStyle}
            />
          </div>
        </div>
      </div>

      <div className='outerDiv open'>
        <div className='MainTitle'>{mls('Advance Option')}</div>

        <div className='grayContainers noOverflowGrayContainer'>
          <div className={`containerItems open`}>
            <div className='titleDiv'>{mls('Title Prefix')}</div>
            <input
              type='text'
              className='primaryInput'
              placeholder={mls('Title Prefix')}
              value={kpiTargetPoints.title.valuePrefix}
              onChange={handlePrefix}
            />
            <div className='titleDiv'>{mls('Title Postfix')}</div>
            <input
              type='text'
              className='primaryInput'
              placeholder={mls('Title Postfix')}
              value={kpiTargetPoints.title.valuePostfix}
              onChange={handlePostfix}
            />

            {/* <div className='titleDiv'>{mls('Kpi Start Point')}</div>
            <input
              type='number'
              className='primaryInput'
              placeholder={mls('Kpi Start Point')}
              value={kpiTargetPoints.kpiStartingPoint}
              onChange={handleKpiStartingPoint}
            /> */}
            <FontSelector fontStyle={kpiTargetPoints.title} setFontStyle={handleTitleStyle} />
            <SolidColorSelector color={kpiTargetPoints.title} setColor={handleTitleStyle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default KpiMeterStyleCase;
