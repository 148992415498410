import React, { useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
const ClosableWhiteDiv = ({
  children,
  title,
  span = '',
  show = true,
  onClose = () => {},
  autoCloseOff = false,
  className = '',
  toggleClose = false,
}) => {
  const [isDivOpen, setisDivOpen] = useState(show);
  const [isContent, setisContent] = useState(show);

  const handleDiv = () => {
    setisDivOpen(!isDivOpen);
  };
  const wrapperRef = useRef(null);

  useUpdateEffect(() => {
    if (!isDivOpen) {
      const timer = setTimeout(() => setisContent(isDivOpen), 300);
      return () => {
        clearTimeout(timer);
      };
    }
    setisContent(isDivOpen);
  }, [isDivOpen]);

  useUpdateEffect(() => {
    handleDiv(show);
  }, [show]);

  useUpdateEffect(() => {
    handleDiv(show);
  }, [toggleClose]);

  useUpdateEffect(() => {
    if (autoCloseOff) {
      return;
    }
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose && onClose();
        setTimeout(() => handleDiv(), 10);
      }
    }

    if (isDivOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, isDivOpen]);

  return (
    <div
      className={isDivOpen ? `containerItems open ${className}` : `containerItems ${className}`}
      ref={wrapperRef}
    >
      <div className='titleDiv cursorPointer' onClick={() => handleDiv()}>
        <div className='mainContainerTitle'>
          {title} {span && <span>( {span} )</span>}
        </div>
        <div className='containerArrow'>
          <IoIosArrowDown />
        </div>
        {/* <img src={arrowDropDownIcon} alt='' onClick={() => handleDiv()} /> */}
      </div>
      <div className='contaierClosableDiv'>
        {isContent && <div className='inputDivContainer'> {children} </div>}
      </div>
    </div>
  );
};

export default ClosableWhiteDiv;
