import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bar, LinePath } from '@visx/shape';
import { curveLinear } from '@visx/curve';
import { GlyphCircle } from '@visx/glyph';

import { handleMouseStartLineAndArea } from './helpers/tooltipHelper';
import { defaultColors, defaultMargin } from '../../constants/constants';

const LineChart = ({
  chartID,
  xScale,
  yScale,
  x,
  y,
  xCategory,
  yCategory,
  onMouseOver,
  onMouseOut,
  width,
  height,
  colorType,
  gradientColor,
  chartColor,
}) => {
  const [enlarge, setEnlarge] = useState(false);

  // Convert data to required format
  // [{x: x, y: y}, {x: x, y: y},...]
  const data = x.map((e, i) => ({ x: e, y: y[i] }));

  // Accessors for data
  const xAccessor = (d) => d.x;
  const yAccessor = (d) => d.y;

  // Addition coefficient (if bandScale, add bandwidth coefficient)
  const xCoeff = xCategory === 'CAT' ? xScale.bandwidth() / 2 : 0;
  const yCoeff = yCategory === 'CAT' ? yScale.bandwidth() / 2 : 0;

  const handleMouseStart = (event, d) => {
    const dx = d.x;
    handleMouseStartLineAndArea(event, xCategory, onMouseOver, d);
    setEnlarge(dx);
  };
  let color = defaultColors[0];
  if (colorType === 'Monochromatic') {
    color = chartColor;
  } else if (colorType === 'Gradient') {
    color = gradientColor.from;
  }
  const barWidths = {};
  data.forEach((d, i) => {
    xScale(xAccessor(d));
    const currX = xScale(xAccessor(d));
    let nextX = 0;
    if (i === 0) nextX = 0;
    else if (i === data.length - 1) nextX = width;
    else nextX = xScale(xAccessor(data[i + 1]));
    const wid = Math.floor(nextX - currX);
    barWidths[wid] = barWidths[wid] ? barWidths[wid] + 1 : 1;
  });

  const barWidth = Object.keys(barWidths).reduce(
    (a, b) => (barWidths[a] > barWidths[b] ? a : b),
    0
  );
  return (
    <>
      {/* <MarkerCircle
        id={`marker-circle-${chartID}`}
        fill={color}
        size={2}
        refX={2}
        onMouseOver={handleMouseStart}
        onMouseLeave={onMouseOut}
      /> */}
      <LinePath
        data={data}
        curve={curveLinear}
        x={(d) => xScale(xAccessor(d)) + xCoeff}
        y={(d) => yScale(yAccessor(d)) + yCoeff}
        // markerMid={`url(#marker-circle-${chartID})`}
        // markerStart={`url(#marker-circle-${chartID})`}
        // markerEnd={`url(#marker-circle-${chartID})`}
        stroke={color}
        strokeWidth={1.5}
      />
      {data.map((d, i) => {
        const left = xScale(xAccessor(d)) + xCoeff;
        const top = yScale(yAccessor(d)) + yCoeff;
        return (
          <React.Fragment key={`${d.x}-${i}`}>
            <GlyphCircle
              key={`Glyph-${d.x}-${i}`}
              left={left}
              top={top}
              size={enlarge === d.x ? 200 : 90}
              fill={color}
              stroke={'#ffffff'}
              strokeWidth={2}
              onMouseMove={(e) => handleMouseStart(e, d)}
              onMouseLeave={() => {
                onMouseOut();
                setEnlarge(false);
              }}
            />
            <Bar
              key={`bar-${d.x}-${i}`}
              x={xScale(xAccessor(d)) - barWidth / 2}
              width={barWidth}
              height={Math.max(height - defaultMargin.top - defaultMargin.bottom, 150)}
              fill='transparent'
              onMouseMove={(e) => handleMouseStart(e, d)}
              // onMouseLeave={() => {
              //   onMouseOut();
              //   setEnlarge(false);
              // }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

LineChart.propTypes = {
  chartID: PropTypes.string.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  x: PropTypes.arrayOf(PropTypes.any).isRequired,
  y: PropTypes.arrayOf(PropTypes.any).isRequired,
  xCategory: PropTypes.string.isRequired,
  yCategory: PropTypes.string.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  chartColor: PropTypes.string.isRequired,
};

export default LineChart;
