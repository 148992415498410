import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { mls } from 'lib/multilanguagesupport';

import JoinTableCard from './components/JoinTableCard';
import Sorting from '../../dependencies/Sorting';

import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import EditTable from './components/editTableList/EditTable';
import { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';

const JoinTables = () => {
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const [isAlreadyTable, setIsAlreadyTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableOriginalName, setTableOriginalName] = useState(null);
  const appJointable = useSelector(
    (state) => state.config_store.analyticsSchema?.appJointable || {}
  );
  const appDatatable = useSelector((state) => state.config_store.analyticsSchema.appDatatable);

  const [joinedTables, setJoinedTables] = useState(appJointable ? Object.values(appJointable) : []);
  const [openEditTable, setOpenEditTable] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const handleShow = () => {
    document.body.style.overflow = 'hidden';
    setOpenEditTable(true);
  };
  const handleClose = () => {
    document.body.style.overflow = 'visible';
    setOpenEditTable(false);
  };

  // map of tableID and its name to display in the frontend.
  const allTableIDAndNamesMap = useRef({});
  useEffect(() => {
    Object.keys(appDatatable || {}).forEach((tableID) => {
      allTableIDAndNamesMap.current[tableID] = appDatatable[tableID].name;
    });
    Object.keys(joinedTables || {}).forEach((tableID) => {
      allTableIDAndNamesMap.current[tableID] = joinedTables[tableID]
        ? joinedTables[tableID].name
        : '';
    });
  }, [appDatatable, joinedTables]);
  const openEditTableInEditableMode = ({ editableMode, data }) => {
    // setIsEditable(editableMode);
    setSelectedTable(data);
    handleShow();
    setIsAlreadyTable(true);
    setTableOriginalName(data.name);
  };
  return (
    <div className='DataConnectors introjs-jointablePage '>
      <ToastContainer position={'bottom-left'} theme={'colored'} />

      <div className='d-flex justify-content-end align-items-center mb-3 p-5 bg-white rounded'>
        {!_.isEmpty(Object.values(appJointable || {})) && (
          <Sorting
            setObjectOfObjects={setJoinedTables}
            dataInRedux={appJointable}
            updateSearchKey={setSearchKeyWord}
          />
        )}
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            handleShow();
            setIsAlreadyTable(false);
            // setIsEditable(false);
            setSelectedTable(null);
            setSelectedTable('');
          }}
        >
          {mls('Join New Tables')}
        </button>
      </div>
      {/* Join Tables modal - also used for edit modal */}

      {openEditTable ? (
        <PopUpDivChild
          className={'editTablePopup'}
          initialValue={openEditTable}
          setPopUp={handleClose}
          popupTitle={''}
          header={false}
        >
          <EditTable
            handleClose={handleClose}
            appID={appID}
            isAlreadyTable={isAlreadyTable}
            appDatatable={appDatatable}
            setIsAlreadyTable={setIsAlreadyTable}
            selectedTable={selectedTable}
            setSelectedTable={setSelectedTable}
            tableOriginalName={tableOriginalName}
            setTableOriginalName={setTableOriginalName}
            appJointable={appJointable}
            joinedTables={Object.values(joinedTables || {})}
            setJoinedTables={setJoinedTables}
          />
        </PopUpDivChild>
      ) : null}
      {/* Display the joined tables as cards */}
      <div className='row'>
        {!_.isEmpty(Object.values(appJointable || {})) ? (
          _.isEmpty(Object.values(joinedTables || {})) ? (
            <div class='alert alert-warning m-auto' role='alert' style={{ width: '98%' }}>
              {mls(`No Joined Tables Found for this "${searchKeyWord}" keyword.`)}
            </div>
          ) : (
            Object.values(joinedTables || {}).map((data, key) => (
              <div
                className='col-md-4 col-sm-6 mb-6'
                key={key}
                // onClick={() => {
                //   setSelectedTable(data);
                //   handleShow();
                //   setIsAlreadyTable(true);
                //   setTableOriginalName(data.name);
                // }}
              >
                <JoinTableCard
                  openEditTableInEditableMode={openEditTableInEditableMode}
                  index={key}
                  joinedTableData={data}
                  allTableIDAndNamesMap={allTableIDAndNamesMap.current}
                />
              </div>
            ))
          )
        ) : (
          <div className='alert alert-warning mx-auto' style={{ width: '98%' }} role='alert'>
            {mls('No Joined Tables to display. Kindly Join tables to analyse them.')}
          </div>
        )}
      </div>
    </div>
  );
};

export default JoinTables;
