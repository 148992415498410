import { mls } from 'lib/multilanguagesupport';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import React from 'react';
import ValueNumber from '../../ValueNumber';

interface DescribeProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
  mainData: any;
}
const DescribeColumn = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
  mainData,
}: DescribeProps) => {
  const describeArray = [
    { label: '25 Percentile', key: '25%' },
    { label: '50 Percentile', key: '50%' },
    { label: '75 Percentile', key: '75%' },
    { label: 'Count', key: 'count' },
    { label: 'Max', key: 'max' },
    { label: 'Mean', key: 'mean' },
    { label: 'Min', key: 'min' },
    { label: 'Std', key: 'std' },
  ];
  if (
    mainData.chartType === allChartKeys.BUBBLEPLOT ||
    mainData.chartType === allChartKeys.GROUPBUBBLEPLOT
  ) {
    return (
      <div className='container-fluid h-100 mb-2 p-2 maximaMinimaDivContainer'>
        <div className='col p-4 w-100 insight-table-column-header-left'>
          <b>{mls(columnName1)}</b>
        </div>
        {Object.keys(elem).map((elemKey: string) => {
          const columnName = {
            ...(mainData?.xcolumnName ?? {}),
            ...(mainData?.ycolumnName ?? {}),
          };
          return (
            <div className='col-3 p-4 w-100 insight-table-column-header-right'>
              <b>{columnName?.[elemKey] ?? ''}</b>
            </div>
          );
        })}

        {React.Children.toArray(
          describeArray.map((describeArrayElem: any) => {
            return (
              <>
                <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                  {describeArrayElem?.label ?? ''}
                </div>
                {Object.keys(elem).map((elemKey: string) => {
                  const elemData = elem[elemKey as keyof typeof elem];
                  return (
                    <>
                      <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>
                        {
                          <ValueNumber
                            number={elemData?.[describeArrayElem.key] ?? ''}
                            arrow={arrow}
                          />
                        }
                      </div>
                    </>
                  );
                })}
              </>
            );
          })
        )}
      </div>
    );
  }
  return (
    <div className='container-fluid h-100 w-100 mb-2 statisticalDivContainer'>
      <div className='col-8 p-4 w-100  insight-table-column-header-left'>
        <b>{mls(columnName1)}</b>
      </div>
      {Object.keys(elem).map((elemKey: string) => {
        const columnName = { ...(mainData?.xcolumnName ?? {}), ...(mainData?.ycolumnName ?? {}) };
        return (
          <div className='col p-4 w-100 insight-table-column-header-right'>
            <b>{columnName?.[elemKey] ?? ''}</b>
          </div>
        );
      })}
      {React.Children.toArray(
        describeArray.map((describeArrayElem: any) => {
          return (
            <>
              <div className='col-8 p-4 g-0 w-100 insight-table-data-cell-left'>
                {describeArrayElem?.label ?? ''}
              </div>
              {Object.keys(elem).map((elemKey: string) => {
                const elemData = elem[elemKey as keyof typeof elem];
                return (
                  <>
                    <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>
                      {
                        <ValueNumber
                          number={elemData?.[describeArrayElem.key] ?? ''}
                          arrow={arrow}
                        />
                      }
                    </div>
                  </>
                );
              })}
            </>
          );
        })
      )}
    </div>
  );
};

export default DescribeColumn;
