import React, { useEffect, useMemo, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import {
  defaultDataType,
  defaultDataTypeStyles,
  defaultDateArray,
} from './data/defaultFilterSchema';
import useDebounce from './hooks/useDebounce';
import useUpdateEffect from './hooks/useUpdateEffect';

import PopUpDiv from './features/PopUpDiv/PopUpDiv';
import MultiselectWithApi from './features/MultiselectWithApi/MultiselectWithApi';

import boxDropDownIcon from './assets/boxDropDownIcon.svg';

import {
  chartFilterPopulation,
  filterByTableName,
  mergeDashboardSchema,
  tableFilterFuntion,
} from './utils/filterHelperFunctions';

import FilterSelection, { AllTables } from './FilterSelection';
import DatePicker from './DatePicker';
import { RiFilter3Fill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';

import './filterDiv.scss';
import './scroll.scss';
import NestedDropDownObject from './features/DropDownObject/NestedDropDownObject';
import { filtersSchema } from './utils/filterHelperFunctions';
import { AiFillInfoCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { mls } from 'lib/multilanguagesupport';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';

const FilterDiv = (props) => {
  const {
    setChartFilter,
    allTableData,
    chartFilter,
    appID,
    isJoinedTable = false,
    sheetID = '',
    isPopUp,
    setIsPopUp,
    isDashboard = false,
    isDataSource = false,
  } = props;
  const finalTableData = useMemo(() => {
    if (isDashboard) {
      return mergeDashboardSchema(allTableData);
    }
    return allTableData;
  }, [allTableData]);
  const [selectedColumns, setSelectedColumns] = useState(
    chartFilterPopulation({
      chartWidthObject: chartFilter,
      allTableData: finalTableData,
      isDashboard,
    })
  );

  if (isDashboard) {
  }
  const [tableFilter, settableFilter] = useState({ search: '', type: defaultDataType.ALL });

  const [filterdTableSchema, setFilterdTableSchema] = useState(finalTableData);
  const toggleDiv = useRef(null);
  const filterselection = useRef(null);
  const handleToggle = () => {
    toggleDiv?.current.classList.toggle('open');
    filterselection?.current.classList.toggle('open');
  };
  const handlePopUp = (action) => {
    setFilterdTableSchema(finalTableData);
    settableFilter({ search: '', type: defaultDataType.ALL });
    setIsPopUp(action);
  };

  const handleSearch = (value) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: finalTableData,
        setFilterdTableSchema,
        ...currentValue,
        search: value,
      });

      return { ...currentValue, search: value };
    });
  };
  const handleTypeFilter = (e) => {
    settableFilter((currentValue) => {
      tableFilterFuntion({
        allTableData: finalTableData,
        setFilterdTableSchema,
        ...currentValue,
        type: e,
      });
      return { ...currentValue, type: e };
    });
  };

  const newAllTableData = useMemo(() => {
    return filterByTableName(filterdTableSchema, isDashboard);
  }, [filterdTableSchema]);

  const handleColumnSelection = (selectedItems) => {
    const newArray = [...selectedColumns, selectedItems];
    setSelectedColumns(newArray);
  };

  const handleRemoveColumnSelection = (item) => {
    const newArray = selectedColumns.filter((obj) => {
      if (isDashboard) {
        return obj.uniqueDashboardID !== item.uniqueDashboardID;
      }
      return `${obj.tableName},${obj.columnName}` !== `${item.tableName},${item.columnName}`;
    });
    setSelectedColumns(newArray);
  };
  const clearFilter = () => {
    setChartFilter({});
    setSelectedColumns([]);
  };
  useUpdateEffect(() => {
    const filters = filtersSchema({
      filterArray: selectedColumns,
      sheetID,
      isDashboard,
      isDataSource,
    });
    setChartFilter(filters);
  }, [selectedColumns]);
  useEffect(() => {
    setFilterdTableSchema(finalTableData);
  }, [finalTableData]);
  return (
    <>
      <PopUpDiv initialValue={isPopUp} setPopUp={handlePopUp} className={'FilterDiv'}>
        <div className='toggleDiv'>
          <div className='toggleIcon' onClick={() => handleToggle()} ref={toggleDiv}>
            <img src={boxDropDownIcon} alt='' />
          </div>
          <div className='toggleFilterName'>{mls('Filters')}</div>
        </div>
        <div className='filterDivContainer'>
          <div className='filterselection' ref={filterselection}>
            <div className='staticSection'>
              <div className='mainTitleDiv'>
                <div className='toggleFilterName'>{mls('Filters')}</div>
                <span onClick={() => clearFilter()}>{mls('Clear Filters')}</span>
              </div>
              <div className='serachBoxOuter'>
                <div className='searchBox'>
                  <SearchInputDiv value={tableFilter.search} onChange={handleSearch} />
                </div>
                <NestedDropDownObject
                  object={defaultDataTypeStyles}
                  dropDownValue={tableFilter.type}
                  setdropDownValue={handleTypeFilter}
                  swithToIcon={true}
                />
              </div>
            </div>
            <div className='allTable'>
              <AllTables
                isDashboard={isDashboard}
                newAllTableData={newAllTableData}
                tableFilter={tableFilter}
                selectedColumns={selectedColumns}
                handleColumnSelection={handleColumnSelection}
                handleRemoveColumnSelection={handleRemoveColumnSelection}
              />
            </div>
          </div>
          <div className='filterEditing'>
            {selectedColumns[0] == null ? (
              <div className='selectAnyFilter'>
                <div className='innerBox'>{mls('Select Any Filter')}</div>
              </div>
            ) : null}
            {React.Children.toArray(
              selectedColumns.map((elem, i) => {
                if (elem.dataType === defaultDataType.NUM) {
                  return (
                    <FilterItemsNUM
                      // elem={{
                      //   ...elem,
                      //   value: { ...elem.value, min: 50, max: 50 },
                      //   filterData: {
                      //     ...elem.filterData,
                      //     range: { ...elem.filterData.range, min: 50, max: 50 },
                      //   },
                      // }}
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.CAT) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.DATETIME) {
                  return (
                    <FilterItemsDATE
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.ID_TEXT) {
                  return (
                    <FilterItemsCAT
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                      appID={appID}
                      sheetID={sheetID}
                      isJoinedTable={isJoinedTable}
                    />
                  );
                }
                if (elem.dataType === defaultDataType.ID_NUM) {
                  return (
                    <FilterItemsNUM
                      elem={elem}
                      selectedColumns={selectedColumns}
                      setSelectedColumns={setSelectedColumns}
                      handleRemoveColumnSelection={handleRemoveColumnSelection}
                      index={i}
                    />
                  );
                }
                return null;
              })
            )}
          </div>
        </div>
      </PopUpDiv>
    </>
  );
};
export default FilterDiv;

const FilterItemsNUM = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  handleRemoveColumnSelection,
}) => {
  const [filterValueNUM, setFilterValueNUM] = React.useState([
    elem?.value?.min ?? elem.filterData.range.min,
    elem?.value?.max ?? elem.filterData.range.max,
  ]);
  const debounceValue = useDebounce(filterValueNUM);

  const handleChange = (event, newValue) => {
    setFilterValueNUM(newValue);
  };
  useUpdateEffect(() => {
    const value = {
      min: filterValueNUM[0],
      max: filterValueNUM[1],
    };
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
  }, [debounceValue]);

  useEffect(() => {
    setFilterValueNUM([
      elem?.value?.min ?? elem?.filterData?.range?.min,
      elem?.value?.max ?? elem?.filterData?.range?.max,
    ]);
  }, [`${elem?.columnName}.${elem?.tableName}`]);
  const isNotCorrect = filterValueNUM[0] > filterValueNUM[1];
  const errorMsg = mls('Max must be  greater than Min');
  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <div className='sliderDiv'>
        {elem?.filterData?.range?.min === elem?.filterData?.range?.max ? (
          <Slider
            value={filterValueNUM}
            sx={{
              color: isNotCorrect ? 'red' : '',
            }}
            min={elem?.filterData?.range?.min}
            max={elem?.filterData?.range?.max}
            onChange={() => {}}
            valueLabelDisplay='off'
          />
        ) : (
          <>
            <Slider
              value={filterValueNUM}
              sx={{
                color: isNotCorrect ? 'red' : '',
              }}
              min={elem?.filterData?.range?.min ?? 0}
              max={elem?.filterData?.range?.max ?? 0}
              onChange={handleChange}
              valueLabelDisplay='off'
            />
          </>
        )}
      </div>
      <div className='numberInputDivContainer'>
        <div className='leftNumberInputDiv innerNumberInputDiv'>
          <div className='titleDiv'>{mls('Min')}:</div>
          <input
            type='number'
            className='primaryInput'
            value={filterValueNUM[0]}
            onChange={(e) => handleChange(e, [Number(e.target.value), filterValueNUM[1]])}
          />
        </div>
        <div className='rightNumberInputDiv innerNumberInputDiv'>
          <div className='titleDiv'>{mls('Max')}:</div>
          <input
            type='number'
            className='primaryInput'
            style={isNotCorrect ? { background: '#ffc1c1' } : {}}
            value={filterValueNUM[1]}
            onChange={(e) => handleChange(e, [filterValueNUM[0], Number(e.target.value)])}
            title={isNotCorrect ? errorMsg : ''}
          />
          {isNotCorrect ? (
            <div className='errorInfo' title={errorMsg}>
              <AiFillInfoCircle />
            </div>
          ) : null}
        </div>
      </div>
      {isNotCorrect ? <div className='errorDisplay'></div> : null}
    </div>
  );
};

const FilterItemsCAT = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  appID,
  sheetID,
  isJoinedTable,
  handleRemoveColumnSelection,
}) => {
  const [selectedData, setSelectedData] = useState(elem.value || []);
  const debounceValue = useDebounce(selectedData);
  const handleColumnSelection = (selectedItems) => {
    const newArray = [...selectedItems];
    setSelectedData(newArray);
  };
  const newUniqueValues = useMemo(() => {
    return (elem?.filterData?.uniqueValues || []).map((data) => {
      return `${data}`;
    });
  }, [elem]);
  useUpdateEffect(() => {
    const value = selectedData;
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setSelectedData(elem?.value ?? []);
  }, [`${elem?.columnName}.${elem?.tableName}`]);

  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <div className='multiSelectColumn'>
        <MultiselectWithApi
          targetElem={elem}
          appID={appID}
          sheetID={sheetID}
          isJoinedTable={isJoinedTable}
          options={newUniqueValues} // Options to display in the dropdown
          onSelect={handleColumnSelection} // Function will trigger on select event
          onRemove={handleColumnSelection} // Function will trigger on remove event
          selectedValues={selectedData}
          // displayValue="name" // Property name to display in the dropdown options
          showCheckbox
          isObject={false}
        />
      </div>
    </div>
  );
};

const FilterItemsDATE = ({
  elem,
  selectedColumns,
  index,
  setSelectedColumns,
  handleRemoveColumnSelection,
}) => {
  const [selectedData, setSelectedData] = useState(elem?.value || defaultDateArray[4]);
  const debounceValue = useDebounce(selectedData);

  useUpdateEffect(() => {
    const value = selectedData;
    const getData = updateData(selectedColumns, index, value);
    setSelectedColumns(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    setSelectedData(elem?.value ?? defaultDateArray[4]);
  }, [`${elem?.columnName}.${elem?.tableName}`]);

  return (
    <div className='containerItems'>
      <div className='Title'>
        <div className='filterIcon'>
          <RiFilter3Fill />
        </div>
        <div className='titleName'>{elem.name}</div>
        <div className='removeFilter' onClick={() => handleRemoveColumnSelection(elem)}>
          <RxCross2 />
        </div>
      </div>
      <DatePicker dateData={selectedData} setDateData={setSelectedData} />
    </div>
  );
};

const updateData = (sourceData, index, value) => {
  const items = Array.from(sourceData);
  const [selectedItem] = items.splice(index, 1);
  const newSelectedItem = {
    ...selectedItem,
    value,
  };
  items.splice(index, 0, newSelectedItem);
  return items;
};
