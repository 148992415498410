import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import React, { useState } from 'react';
import TimeSeriesType from './containers/TimeSeriesType/TimeSeriesType';
import CategoricalType from './containers/CategoricalType/CategoricalType';
import { mls } from 'lib/multilanguagesupport';
import { toAbsoluteUrl } from '_metronic/helpers';

interface AnalysisIndexProps {
  mainData: any;
  isReport?: boolean;
}
const AnalysisIndex = ({ mainData, isReport = false }: AnalysisIndexProps) => {
  const horizontalCharts = [
    allChartKeys.BARHORIZONTAL,
    allChartKeys.BARHORIZONTALCOLOR,
    allChartKeys.BARSTACKHORIZONTAL,
    allChartKeys.LINEHORIZONTAL,
    allChartKeys.MULTILINEHORIZONTAL,
    allChartKeys.AREAHORIZONTAL,
    allChartKeys.AREASTACKHORIZONTAL,
    allChartKeys.WORDCLOUD,
  ];
  const isHorizotal = horizontalCharts.some((chartKey) => chartKey === mainData?.chartType);
  const numChartAxis = (isHorizotal ? mainData?.xcolumnName : mainData?.ycolumnName) ?? {};
  const catChartAxis = (isHorizotal ? mainData?.ycolumnName : mainData?.xcolumnName) ?? {};
  const [axisIndex, setAxisIndex] = useState(0);
  const [axisData, setAxisData] = useState(Object.keys(numChartAxis)[0] ?? '');
  const selectColumn = (e: any) => {
    setAxisData(e.data);
    setAxisIndex(e.index);
  };

  const svgStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50vw',
  };
  if (!axisData) {
    return (
      <div className='AnalysisIndex'>
        <div className='mb-20'>
          <div style={svgStyle}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 100 90'
              width='40%'
              height='40%'
              style={{ minWidth: '250px', maxHeight: '45vh' }}
            >
              <image
                href={toAbsoluteUrl('/media/errorScreens/NoChart.png')} // Replace with the actual path to your image
                width='100'
                height='100'
              />
            </svg>
          </div>
          <br />
          <h6 style={svgStyle} className='mb-3 text-center'>
            {mls('No data available in the Insight to show.')}
          </h6>
        </div>
      </div>
    );
  }

  return (
    <div className='AnalysisIndex'>
      {mainData?.dataSections?.length >= 2 ? (
        <div className='columnSelectionDiv'>
          <div className='selectionDivTitle'>{mls('Select Column')}:</div>
          <div className='selectDiv'>
            <ObjectDropDown
              object={numChartAxis}
              dropDownValue={axisData}
              setdropDownValue={selectColumn}
              withIndex
            />
          </div>
        </div>
      ) : null}

      {mainData.type === 'TIMESERIES' && (
        <TimeSeriesType
          mainData={mainData}
          axisIndex={axisIndex}
          catChartAxis={catChartAxis}
          isReport={isReport}
          chartType={mainData?.chartType}
        />
      )}
      {mainData.type === 'CATEGORY' && (
        <CategoricalType
          mainData={mainData}
          axisIndex={axisIndex}
          catChartAxis={catChartAxis}
          isReport={isReport}
          chartType={mainData?.chartType}
        />
      )}
    </div>
  );
};

export default AnalysisIndex;
