import React from 'react';
import { KTSVG, toAbsoluteUrl } from '_metronic/helpers';
import { mls } from 'lib/multilanguagesupport';
import { RiTableFill } from 'react-icons/ri';
import './dataLakeCard.scss';

interface dataLakeCardProps {
  children: React.ReactNode;
  settingButtonFunction: Function;
  title: string;
  titleButtonFunction: Function;
  bottomButtonFunction: Function;
  bottomButtonTitle: string;
  description: string;
}
const DataLakeCard = (dataLakeCardProps: dataLakeCardProps) => {
  const {
    children,

    settingButtonFunction,
    titleButtonFunction,
    title,
    bottomButtonFunction,
    bottomButtonTitle,
    description,
  } = dataLakeCardProps;
  return (
    <div className='dataLakeCard card'>
      <div className='card-body'>
        {settingButtonFunction ? (
          <div className='supistaCardSettingButton'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary '
              onClick={() => {
                settingButtonFunction && settingButtonFunction();
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
          </div>
        ) : null}

        <span
          className='card-title fw-bolder text-dark-75 text-primary fs-4 supistaCardTitle'
          style={{
            cursor: titleButtonFunction ? 'pointer' : 'auto',
          }}
          onClick={() => {
            titleButtonFunction && titleButtonFunction();
          }}
        >
          {title}
        </span>
        {description ? (
          <p
            className=' fw-bold fs-5 m-0 text-muted'
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        ) : null}

        {children}
      </div>
      {bottomButtonTitle ? (
        <div
          style={{
            marginTop: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div
            className='importTableButton'
            role='button'
            onClick={() => bottomButtonFunction && bottomButtonFunction()}
          >
            <button
              className='primaryButton secondaryButtonColor'
              style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}
            >
              <span>{mls(bottomButtonTitle)}</span>
              <RiTableFill fontSize={16} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DataLakeCard;
