import React, { useMemo, useRef, useState } from 'react';
import { connectionFieldsType } from 'lib/data-connectors/dependencies/connectionsFormsSchema';
import { mls } from 'lib/multilanguagesupport';
import SlideSwitch from 'lib/reusable-components/Components/SlideSwitch/SlideSwitch';
import './getFileds.scss';
import { toast } from 'react-toastify';
import { getDateTimeColumnsObject, getIDColumnsObject } from './filedsUtils';

import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { copyToClipBoard } from 'lib/data-connectors/dependencies/utilFunctions';
import DataSourceFilterDiv from 'lib/filters-lib/DataSourceFilterDiv';

import TableConfigField from './TableConfigField';
import TimeIntervalField from './TimeIntervalField';
import { FaTimes } from 'react-icons/fa';
import FilterDiv from 'lib/filters-lib/FilterDiv';

interface getFieldsProps {
  fieldsElem: any;
  formData: any;
  tableData?: any;
  setFormData: Function;
  setTableData?: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}
const GetFields = ({
  fieldsElem,
  formData,
  setFormData,
  tableData = {},
  setTableData = () => {},
  fileToUploadObject = {},
  setFileToUploadObject = () => {},
  credentialID,
}: getFieldsProps) => {
  if (fieldsElem.type === connectionFieldsType.tableConfig) {
    return (
      <TableConfigField
        fieldsElem={fieldsElem}
        formData={formData}
        tableData={tableData}
        setFormData={setFormData}
        setTableData={setTableData}
        fileToUploadObject={fileToUploadObject}
        setFileToUploadObject={setFileToUploadObject}
        credentialID={credentialID}
      />
    );
  }
  if (fieldsElem.type === connectionFieldsType.dateTimeColumn) {
    return (
      <DataTimeColumnField
        fieldsElem={fieldsElem}
        formData={formData}
        tableData={tableData}
        setFormData={setFormData}
      />
    );
  }
  if (fieldsElem.type === connectionFieldsType.idField) {
    return (
      <IdField
        fieldsElem={fieldsElem}
        formData={formData}
        tableData={tableData}
        setFormData={setFormData}
      />
    );
  }
  if (fieldsElem.type === connectionFieldsType.toggleSwitch) {
    return (
      <div className='connectionFormOptions connectionFormOptionsInRow'>
        <div className='connectionFormTitle'>
          {mls(fieldsElem.label)}:{' '}
          {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
        <SlideSwitch
          isChecked={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
          setIsChecked={(e: any) => {
            setFormData({ key: fieldsElem.name, value: e });
          }}
        />
      </div>
    );
  }
  if (fieldsElem.type === connectionFieldsType.number) {
    return (
      <div className='connectionFormOptions connectionFormOptionsInRow'>
        <div className='connectionFormTitle'>
          {mls(fieldsElem.label)}:{' '}
          {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
        <div className='connectionFormNumberInputContainer'>
          <input
            name={fieldsElem.name}
            value={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
            type='number'
            onChange={(e) => {
              setFormData({ key: fieldsElem.name, value: Number(e.target.value) });
            }}
            min={fieldsElem?.minValue ?? null}
            max={fieldsElem?.maxValue ?? null}
            required={fieldsElem?.required}
            placeholder={mls(fieldsElem.label)}
            className='connectionFormInput '
          />
        </div>
      </div>
    );
  }
  if (fieldsElem.type === connectionFieldsType.textArea) {
    return (
      <div className='connectionFormOptions'>
        <div className='connectionFormTitle'>
          {mls(fieldsElem.label)}:{' '}
          {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
        <textarea
          name={fieldsElem.name}
          rows={2}
          value={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
          onChange={(e) => {
            setFormData({ key: fieldsElem.name, value: e.target.value });
          }}
          maxLength={fieldsElem?.maxLength}
          minLength={fieldsElem?.minLength}
          required={fieldsElem?.required}
          placeholder={mls(fieldsElem.label)}
          className={'connectionFormTextArea'}
        />
      </div>
    );
  }

  if (fieldsElem.type === connectionFieldsType.inputString) {
    return (
      <div className='connectionFormOptions'>
        <div className='connectionFormTitle'>
          {mls(fieldsElem.label)}:{' '}
          {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
        <input
          name={fieldsElem.name}
          value={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
          onChange={(e) => {
            setFormData({ key: fieldsElem.name, value: e.target.value });
          }}
          maxLength={fieldsElem?.maxLength}
          minLength={fieldsElem?.minLength}
          required={fieldsElem?.required}
          placeholder={mls(fieldsElem.label)}
          className={'connectionFormInput'}
        />
      </div>
    );
  }

  if (fieldsElem.type === connectionFieldsType.password) {
    return (
      <div className='connectionFormOptions'>
        <div className='connectionFormTitle'>
          {mls(fieldsElem.label)}:{' '}
          {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
        <input
          name={fieldsElem.name}
          value={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
          onChange={(e) => {
            setFormData({ key: fieldsElem.name, value: e.target.value });
          }}
          type='password'
          required={fieldsElem?.required}
          placeholder={mls(fieldsElem.label)}
          className={'connectionFormInput'}
        />
      </div>
    );
  }
  if (fieldsElem.type === connectionFieldsType.hrDivider) {
    return (
      <div className='connectionFormOptions'>
        <hr />
      </div>
    );
  }
  if (fieldsElem.type === connectionFieldsType.googleSheetInput) {
    return (
      <GoogleSheetInputField
        fieldsElem={fieldsElem}
        formData={formData}
        setFormData={setFormData}
      />
    );
  }

  if (fieldsElem.type === connectionFieldsType.uploadFile) {
    return (
      <UploadFile
        fieldsElem={fieldsElem}
        formData={formData}
        setFormData={setFormData}
        fileToUploadObject={fileToUploadObject}
        setFileToUploadObject={setFileToUploadObject}
        credentialID={credentialID}
      />
    );
  }
  if (fieldsElem.type === connectionFieldsType.sslConfig) {
    return (
      <SSLConfigField
        fieldsElem={fieldsElem}
        formData={formData}
        setFormData={setFormData}
        fileToUploadObject={fileToUploadObject}
        setFileToUploadObject={setFileToUploadObject}
        credentialID={credentialID}
      />
    );
  }
  if (fieldsElem.type === connectionFieldsType.timeInterval) {
    return (
      <TimeIntervalField fieldsElem={fieldsElem} formData={formData} setFormData={setFormData} />
    );
  }
  if (fieldsElem.type === connectionFieldsType.dataFilter) {
    return (
      <DataFilterField
        fieldsElem={fieldsElem}
        formData={formData}
        setFormData={setFormData}
        tableData={tableData}
      />
    );
  }
  return (
    <div className='connectionFormOptions'>
      <div className='connectionFormTitle'>Field type not found</div>
    </div>
  );
};
export default GetFields;

interface sslConfigFieldProps {
  fieldsElem: any;
  formData: any;
  setFormData: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}

const SSLConfigField = ({
  fieldsElem,
  formData,
  setFormData,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: sslConfigFieldProps) => {
  const handleIsSSL = (action: any) => {
    // const tableConfig = formData[fieldsElem.name] ?? {};
    if (!action.value) {
      setFileToUploadObject({});
    }
    // const updatedTableConfig = {
    //   ...tableConfig,
    //   [selectedSheet]: { ...(tableConfig[selectedSheet] ?? {}), [action.key]: action.value },
    // };
    setFormData(action);
  };
  const handleSslConfigFields = (action: any) => {
    if (!action.value) {
      setFileToUploadObject({});
    }
    const sslSchema = formData?.[fieldsElem.sslSchemaKey] ?? {};
    const finalSSLSchema = {
      ...sslSchema,
      [action.key]: action.value,
    };
    setFormData({ key: fieldsElem.sslSchemaKey, value: finalSSLSchema });
  };

  return (
    <>
      {/* <div className='connectionFormOptions'> */}
      <GetFields
        fieldsElem={fieldsElem.sslToggle}
        formData={formData}
        setFormData={handleIsSSL}
        fileToUploadObject={fileToUploadObject}
        setFileToUploadObject={setFileToUploadObject}
        credentialID={credentialID}
      />
      {formData[fieldsElem.name] ?? fieldsElem.defaultValue ? (
        <div className='connectionFormOptions SSLConfigFieldContainer'>
          {React.Children.toArray(
            (fieldsElem?.[fieldsElem.sslSchemaKey] ?? []).map((sslConfigElem: any) => {
              return (
                <GetFields
                  fieldsElem={sslConfigElem}
                  formData={formData?.[fieldsElem.sslSchemaKey] ?? {}}
                  setFormData={handleSslConfigFields}
                  fileToUploadObject={fileToUploadObject}
                  setFileToUploadObject={setFileToUploadObject}
                  credentialID={credentialID}
                />
              );
            })
          )}
        </div>
      ) : null}
      {/* </div> */}
    </>
  );
};
interface uploadFileFieldProps {
  fieldsElem: any;
  formData: any;
  setFormData: Function;
  fileToUploadObject: any;
  setFileToUploadObject: Function;
  credentialID: string;
}
const UploadFile = ({
  fieldsElem,
  formData,
  setFormData,
  fileToUploadObject,
  setFileToUploadObject,
  credentialID,
}: uploadFileFieldProps) => {
  const [fileName, setFileName] = useState(
    formData[fieldsElem.name]?.lableName ?? fieldsElem.defaultValue
  );

  const acceptExtension = fieldsElem.accept;
  const acceptedExtensionWithDot = useRef(
    Object.values(acceptExtension)
      .map((extendtionElem: any) => {
        return `.${extendtionElem.extension}`;
      })
      .join(', ')
  );
  const handleFileDrop = async (e: any) => {
    let files = e.target.files;
    if (!files[0]) return;
    const finalFile = files[0];
    let sFileName = finalFile?.name ?? '';

    let sFileExtension = sFileName.split('.')[1].toLowerCase();

    const isValidFile = Object.values(fieldsElem.accept).some((extendtionElem: any) => {
      return extendtionElem.extension.toLowerCase() === sFileExtension;
    });
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    if ((!fieldsElem.acceptALL && !isValidFile) || finalFile?.size > fieldsElem.maxSize) {
      toast.error(mls('This is not a valid File'));
      await sleep(500);
      return;
    }

    const fileData = {
      // file: finalFile,
      lableName: finalFile.name,
      credentialID: finalFile.name,
    };
    setFileName(finalFile.name);
    setFileToUploadObject((currentFiles: { [key: string]: any }) => {
      return { ...currentFiles, [fieldsElem.name]: finalFile };
    });
    setFormData({ key: fieldsElem.name, value: `${credentialID}.${finalFile.name}` });
  };
  const removeFile = () => {
    setFileName(fieldsElem.defaultValue);
    setFormData({ key: fieldsElem.name, value: fieldsElem.defaultValue });
  };
  // const fileExtensionData: any = getfileExtensionData({
  //   fileName: fileName,
  //   extensionObject: fieldsElem.accept,
  // });

  return (
    <div className='connectionFormOptions'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      {fileName ? (
        <div className='connectionFileUploadContainer connectionFileUploadContainerSuccess'>
          {/* <div className='connectionFileUploadContainerIcon'>
            <img src={fileExtensionData?.icon} alt='icon' />
          </div> */}
          <div className='connectionFileUploadContainerLable'>{fileName}</div>
          <div className='connectionFileUploadContainerRemoveButton' onClick={() => removeFile()}>
            <FaTimes />
          </div>
        </div>
      ) : (
        <input
          type='file'
          name={fieldsElem.name}
          value={formData[fieldsElem.name]?.file ?? fieldsElem.defaultValue}
          onChange={(e) => {
            handleFileDrop(e);
          }}
          required={fieldsElem?.required}
          placeholder={mls(fieldsElem.label)}
          className={'connectionFormInput connectionFormFileInput'}
          accept={acceptedExtensionWithDot.current}
        />
      )}
    </div>
  );
};
interface dataTimeColumnFieldProps {
  fieldsElem: any;
  formData: any;
  tableData: any;
  setFormData: Function;
}

const DataTimeColumnField = ({
  fieldsElem,
  formData,
  setFormData,
  tableData,
}: dataTimeColumnFieldProps) => {
  const handleDateTime = (action: any) => {
    setFormData({ key: fieldsElem.name, value: action });
  };
  const DTCols = useMemo(() => {
    const finalDTCols = getDateTimeColumnsObject({
      tableID: tableData?.tableID,
      columns: tableData?.columnSchema ?? [],
      columnDataStartFrom: formData?.startCol ?? 0,
      dateTimeColumn: formData[fieldsElem.name] ?? fieldsElem.defaultValue,
      handleDateTime: handleDateTime,
    });
    const addedNone = { None: { uniqueColumnNames: 'None', name: 'None' }, ...finalDTCols };
    const DTColKeys = Object.keys(addedNone ?? {});
    if (DTColKeys[0]) {
      const isDateValid = DTColKeys.some((DTColsKey) => {
        return DTColsKey === formData[fieldsElem.name];
      });
      if (!isDateValid) {
        handleDateTime(DTColKeys[1]);
      }
    }
    // else {
    //   handleDateTime(fieldsElem.defaultValue);
    // }

    return addedNone;
  }, [tableData, formData[fieldsElem.name]]);
  // console.log(DTCols);

  if (Object.keys(DTCols).length <= 0) {
    return <></>;
  }

  return (
    <div className='connectionFormOptions connectionFormOptionsInRow'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <ObjectDropDown
        object={DTCols}
        dropDownValue={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
        setdropDownValue={handleDateTime}
        key={'uniqueColumnNames'}
        ObjectInObject
        inputPlaceHolder={mls('Select Date')}
      />
    </div>
  );
};
interface idFieldProps {
  fieldsElem: any;
  formData: any;
  tableData: any;
  setFormData: Function;
}

const IdField = ({ fieldsElem, formData, setFormData, tableData }: idFieldProps) => {
  const handleDateTime = (action: any) => {
    setFormData({ key: fieldsElem.name, value: action });
  };

  const DTCols = useMemo(() => {
    return getIDColumnsObject({
      tableID: tableData?.tableID,
      columns: tableData?.columnSchema ?? [],
      columnDataStartFrom: formData?.columnDataStartFrom ?? 0,
      idColumn: formData[fieldsElem.name] ?? fieldsElem.defaultValue,
      handleDateTime: handleDateTime,
    });
  }, [tableData, formData]);

  if (Object.keys(DTCols).length <= 0) {
    return <></>;
  }

  return (
    <div className='connectionFormOptions connectionFormOptionsInRow'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <ObjectDropDown
        object={DTCols}
        dropDownValue={formData[fieldsElem.name] ?? fieldsElem.defaultValue}
        setdropDownValue={handleDateTime}
        key={'uniqueColumnNames'}
        ObjectInObject
        inputPlaceHolder={mls('Select Date')}
      />
    </div>
  );
};
interface googleSheetInputFieldProps {
  fieldsElem: any;
  formData: any;
  setFormData: Function;
}

const GoogleSheetInputField = ({
  fieldsElem,
  formData,
  setFormData,
}: googleSheetInputFieldProps) => {
  const [spreadSheetUrl, setSpreadSheetUrl] = useState(
    formData[fieldsElem.name] ?? fieldsElem.defaultValue
  );

  const SUPISTA_GOOGLE_SERVICE_ACCOUNT = 'supista@supista.iam.gserviceaccount.com';
  const setSpreadSheet = () => {
    const getSpreadSheetId = ({ spreadSheetUrl }: { spreadSheetUrl: string }) => {
      // updating the google sheet URL with google Sheet ID.
      if (spreadSheetUrl.includes('https://docs.google.com/spreadsheets/d/')) {
        const sheetID = spreadSheetUrl.split('/')[5] || '';
        if (sheetID && sheetID.length > 30) {
          return { err: false, newValue: sheetID };
        }
      }
      return { err: true, newValue: '' };
    };
    const spreadSheetId = getSpreadSheetId({ spreadSheetUrl: spreadSheetUrl });
    if (spreadSheetId.err) {
      setFormData({ key: fieldsElem.name, value: spreadSheetUrl });
      return;
    }
    setFormData({ key: fieldsElem.name, value: spreadSheetId.newValue });
  };
  return (
    <div className='connectionFormOptions'>
      <div className='alert alert-warning my-2 py-5' role='alert'>
        <h6>{mls('Important')}!</h6>
        <p className='mb-5'>
          {mls(
            "To allow Supista to read data from your spreadsheet, add the following account as a 'Viewer' in Google Sheet connection"
          )}
          .
        </p>
        <span className='my-5 fw-bold p-2 border border-warning rounded'>
          {SUPISTA_GOOGLE_SERVICE_ACCOUNT}
        </span>
        <span
          onClick={() => copyToClipBoard({ text: SUPISTA_GOOGLE_SERVICE_ACCOUNT })}
          style={{ cursor: 'pointer', userSelect: 'none' }}
          className='ms-4 badge bg-warning text-dark'
        >
          {mls('Copy')}
        </span>
      </div>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <input
        name={fieldsElem.name}
        value={spreadSheetUrl}
        onChange={(e) => {
          setSpreadSheetUrl(e.target.value);
        }}
        required={fieldsElem?.required}
        placeholder={mls(fieldsElem.label)}
        className={'connectionFormInput'}
        onBlur={() => setSpreadSheet()}
      />
    </div>
  );
};

interface dataFilterFieldProps {
  fieldsElem: any;
  formData: any;
  tableData: any;
  setFormData: Function;
}

const DataFilterField = ({
  fieldsElem,
  tableData,
  formData,
  setFormData,
}: dataFilterFieldProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleChartFilter = (action: any) => {
    setFormData({ key: fieldsElem.name, value: action });
  };

  return (
    <div className='connectionFormOptions connectionFormOptionsInRow'>
      <div className='connectionFormTitle'>
        {mls(fieldsElem.label)}:{' '}
        {fieldsElem?.required ? <span style={{ color: 'red' }}>*</span> : null}
      </div>
      <div>
        <button
          className='primaryButton secondaryButtonColor'
          type='button'
          onClick={() => handleFilterOpen()}
        >
          {mls('Open Filters')}
        </button>
      </div>
      {/* {isFilterOpen ? (
        <DataSourceFilterDiv
          chartFilter={formData?.[fieldsElem.name] ?? {}}
          setChartFilter={handleChartFilter}
          allTableData={tableData?.columnData ?? []}
          appID={''}
          sheetID={''}
          isPopUp={isFilterOpen}
          isDataSource={true}
          setIsPopUp={handleFilterOpen}
        />
      ) : null} */}
      {isFilterOpen ? (
        <FilterDiv
          chartFilter={formData?.[fieldsElem.name] ?? {}}
          setChartFilter={handleChartFilter}
          allTableData={tableData?.columnData ?? []}
          appID={''}
          sheetID={''}
          isPopUp={isFilterOpen}
          isDataSource={true}
          setIsPopUp={handleFilterOpen}
        />
      ) : null}
    </div>
  );
};
