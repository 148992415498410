import React from 'react';
import WithStyleToggle from './WithStyleToggle';
import WithoutStyleToggle from './WithoutStyleToggle';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

import CardCase from './CardCase';
import './specialCase.scss';
import KpiMeterStyleCase from './KpiMeter/KpiMeterStyleCase';
import KpiMeterBuildCase from './KpiMeter/KpiMeterBuildCase';
import WordCloudCase from './WordCloudCase';
import NormalCase from './NormalCase';
import GeoChart from './GeoChart';
import BarStackLineChart from './BarStackLineChart';
import PieCase from './PieCase';
import RadarStyleCase from './Radar/RadarStyleCase';
import RadarBuildCase from './Radar/RadarBuildCase';
import PivotTableBuildCase from './PivotTable/PivotTableBuildCase';
import PivotTableStyleCase from './PivotTable/PivotTableStyleCase.tsx';
import PlotChart from './PlotChart';
import HeatMapBuildCase from './HeatMap/HeatMapBuildCase';
import HeatMapStyleCase from './HeatMap/HeatMapStyleCase';
import TreeMapCase from './TreeMap/TreeMapCase';
import RadialLineBuildCase from './RadialLine/RadialLineBuildCase';
import RadialLineStyleCase from './RadialLine/RadialLineStyleCase';
import GeoMapChart from './GeoMapChart';

const SpecialCase = ({ stateProps, buddleProps, toggleProps }) => {
  const { chartType } = stateProps;
  const { settingToggleState } = buddleProps;

  //PIE | DONUT | FUNNEL
  if (
    chartType.chartKey === allChartKeys.PIE ||
    chartType.chartKey === allChartKeys.DONUT ||
    chartType.chartKey === allChartKeys.FUNNEL
  ) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <PieCase stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }
  // CARD
  if (chartType.chartKey === allChartKeys.CARD) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <CardCase stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }
  // WORDCLOUD
  if (chartType.chartKey === allChartKeys.WORDCLOUD) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <WordCloudCase stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }
  // KPIMETER
  if (chartType.chartKey === allChartKeys.KPIMETER) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <KpiMeterBuildCase stateProps={stateProps} />}
        {settingToggleState === 'style' && <KpiMeterStyleCase stateProps={stateProps} />}
      </WithStyleToggle>
    );
  }
  // GEO
  if (
    chartType.chartKey === allChartKeys.GEOINDIA ||
    chartType.chartKey === allChartKeys.GEOUSA ||
    chartType.chartKey === allChartKeys.GEOCANADA ||
    chartType.chartKey === allChartKeys.GEOWORLD
  ) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <GeoChart stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }
  // GEO
  if (
    chartType.chartKey === allChartKeys.GEOMAP ||
    chartType.chartKey === allChartKeys.GEOMAPCOLOR
  ) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <GeoMapChart stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }

  // BARSTACKLINE
  if (chartType.chartKey === allChartKeys.BARSTACKLINE) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        <BarStackLineChart
          stateProps={stateProps}
          toggleProps={toggleProps}
          buddleProps={buddleProps}
        />
      </WithStyleToggle>
    );
  }
  // PlotCharts
  if (
    chartType.chartKey === allChartKeys.BUBBLEPLOT ||
    chartType.chartKey === allChartKeys.GROUPBUBBLEPLOT ||
    chartType.chartKey === allChartKeys.SCATTERPLOT ||
    chartType.chartKey === allChartKeys.GROUPSCATTERPLOT
  ) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        <PlotChart stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps} />
      </WithStyleToggle>
    );
  }
  // RADAR
  if (chartType.chartKey === allChartKeys.RADAR) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <RadarBuildCase stateProps={stateProps} />}
        {settingToggleState === 'style' && <RadarStyleCase stateProps={stateProps} />}
      </WithStyleToggle>
    );
  }
  // RADIALLINE
  if (chartType.chartKey === allChartKeys.RADIALLINE) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <RadialLineBuildCase stateProps={stateProps} />}
        {settingToggleState === 'style' && <RadialLineStyleCase stateProps={stateProps} />}
      </WithStyleToggle>
    );
  }
  // PIVOTTABLE
  if (chartType.chartKey === allChartKeys.PIVOTTABLE) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <PivotTableBuildCase stateProps={stateProps} />}
        {settingToggleState === 'style' && <PivotTableStyleCase stateProps={stateProps} />}
      </WithStyleToggle>
    );
  }
  // HEATMAP
  if (chartType.chartKey === allChartKeys.HEATMAP) {
    return (
      <WithStyleToggle stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps}>
        {settingToggleState === 'build' && <HeatMapBuildCase stateProps={stateProps} />}
        {settingToggleState === 'style' && <HeatMapStyleCase stateProps={stateProps} />}
      </WithStyleToggle>
    );
  }
  // TREEMAP
  if (chartType.chartKey === allChartKeys.TREEMAP) {
    return (
      <WithoutStyleToggle
        stateProps={stateProps}
        toggleProps={toggleProps}
        buddleProps={buddleProps}
      >
        <TreeMapCase stateProps={stateProps} />
      </WithoutStyleToggle>
    );
  }

  // Default
  return <NormalCase stateProps={stateProps} toggleProps={toggleProps} buddleProps={buddleProps} />;
};

export default SpecialCase;
