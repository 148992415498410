const reduxConstants = {
  STORE_NAME: 'config_store',
  actions: {
    CREATE: 'create',
    UPDATE: 'update',
  },
  config: {
    LOGIN: 'login',
    APP_STATE: 'appState',
    TABLE_SCHEMA: 'table-schema',
    CHART_SETTINGS: 'chartSettings',
    APP_SCHEMA: 'appSchema',
    ANALYTICS_SCHEMA: 'analyticsSchema',
    USER_SCHEMA: 'userSchema',
    APP_MLS: 'appMLS',
    APP_STATUS: {
      TOAST: 'appStatus.toast',
      ERROR_TOAST: 'appStatus.errorToast',
      LOADING: 'appStatus.loading',
    },
    ALL_TABLES: 'allTables',
    FILTERS: 'filters',
  },
};

export default reduxConstants;
